<!--
 * @Author: dongjia
 * @Date: 2021-08-10 15:26:09
 * @LastEditTime: 2021-09-22 16:53:19
 * @LastEditors: aleaner
 * @Description: 企业库系统页
 * @FilePath: \app-design\components\Preview\StCompanyLibrary.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="st-contacts">
    <div class="header">
      <template v-if="platform !== 'h5'">
        <img
          class="start-top-img"
          :src="statusBar[myConfig.normal.statusBarColor]"
        />
        <div class="start-top" style="text-align: end">
          <img
            v-if="myConfig.normal.showHeaderJump"
            class="header-jump"
            :class="[
              headerJump.showConfig.showType !== 'template1'
                ? 'template-back-icon'
                : 'default-back-icon',
            ]"
            :src="
              backIcon[myConfig.normal.statusBarColor][
                headerJump.showConfig.showType
              ]
            "
          />
          <img
            class="start-top-handler"
            :src="handleIcon[myConfig.normal.statusBarColor]"
          />
        </div>
      </template>
      <st-page-set v-else :config="myConfig"></st-page-set>
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div
          class="type-item"
          v-if="myConfig.normal.titleConfig.isShow"
          :style="{
            color: myConfig.normal.fontColor,
            fontWeight: myConfig.normal.fontWeight,
          }"
        >
          {{ myConfig.normal.titleConfig.name }}
        </div>
      </div>
      <div class="header-search">
        <p class="search-icon el-icon-search"></p>
        <p class="search-tip">搜索企业</p>
      </div>
    </div>
    <div class="tabs">
      <div class="tab-list" :class="[platform === 'h5' ? 'simple' : '']">
        <div class="active">
          <p class="item-name">地区 <i class="el-icon-caret-bottom"></i></p>
        </div>
        <div class="tab-item">
          <p class="item-name">行业 <i class="el-icon-caret-bottom"></i></p>
        </div>
        <div class="tab-item">
          <p class="item-name">更多筛选 <i class="el-icon-caret-bottom"></i></p>
        </div>
      </div>
      <div class="content-list">
        <div class="company-num">
          共 <span style="color: #db1010">{{ defaultList.length }}</span> 家企业
        </div>
        <div
          class="list-item"
          v-for="(item, index) in defaultList"
          :key="index"
        >
          <div class="item-top">
            <img
              class="item-img"
              src="../../assets/images/st-company-library/company-icon.png"
            />
            <div class="item-desc">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-status">
                <img
                  class="status-icon"
                  v-if="item.isList"
                  src="../../assets/images/st-company-library/company-list.png"
                />
                <img
                  class="status-icon"
                  v-if="item.isAuth"
                  src="../../assets/images/st-company-library/company-auth.png"
                />
              </div>
            </div>
          </div>
          <div
            class="item-middle"
            v-if="myConfig.contentConfig.showInformation.length"
          >
            <div class="item-data">
              <template v-for="key in myConfig.contentConfig.showInformation">
                <div class="data-item">
                  <template v-for="item in selectOpt">
                    <template v-if="item.value === key">
                      <div class="data-top">
                        {{ item.label }}
                      </div>

                      <div class="data-bottom">{{ item.sample }}</div>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <!-- <div class="item-btn" v-if="item.isAdmin">
            <div class="btn-box">管理</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
// 获取企业库信息展示

import { getCompanyLibOptions } from "@/modules/app-design/api/components";
export default {
  name: "StContacts",
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultList: require("../../assets/jsons/default-list.json").companyList,
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: "企业库",
            isShow: 1,
          },
          fontWeight: "normal",
          fontColor: "#000000",
          bgColor: "",
          statusBarColor: "black",
          showHeaderJump: 1,
        },
        contentConfig: {
          // 默认全选
          showInformation: ["city", "worker_sum", "start_date"],
        },
      },

      selectOpt: [],
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {
    console.log(this.myConfig);
    this.getOptions();
  },
  methods: {
    // 获取企业库信息展示
    getOptions() {
      getCompanyLibOptions().then((res) => {
        this.selectOpt = res.data;
        console.log(this.selectOpt);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.st-contacts {
  width: 375px;
  min-height: 667px;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
  background-color: #f4f5f6;

  .header-nav {
    background-color: white;
  }

  .start-top-img {
    width: 375px;
    height: 20px;
  }
  .start-top {
    padding-right: 7px;
    .header-jump {
      position: absolute;

      &.default-back-icon {
        top: 32px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      &.template-back-icon {
        top: 25px;
        left: 5px;
        width: 78px;
        height: 32px;
      }
    }
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }
  .header {
    position: absolute;
    top: 0;
    width: 100%;
    background: var(--main-color);
    .type-select {
      position: absolute;
      top: 23px;
      width: 100%;
      padding: 0 18px;
      display: flex;
      justify-content: center;

      &.simple {
        position: static;
        display: none;
      }

      .type-item + .type-item {
        margin-left: 18px;
      }
      .type-item {
        display: flex;
        align-items: center;
        height: 34px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        line-height: 34px;
        .active {
          border-bottom: 2px #fff solid;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
        }
        .select-icon {
          width: 16px;
          height: 16px;
          margin-left: 2px;
        }
      }
    }
    .header-search {
      margin: 16px auto 26px;
      width: 335px;
      height: 32px;
      background: #f8fbff;
      border-radius: 18px;
      opacity: 0.89;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      .search-icon {
        color: #94989f;
        font-size: 14px;
      }
      .search-tip {
        font-size: 14px;
        color: #94989f;
        line-height: 14px;
        margin-left: 4px;
      }
    }
  }
  .tabs {
    padding-top: 134px;
    height: 100%;
    .tab-list {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 44px;
      box-shadow: 0px 1px 0px 0px #dddddd;
      .tab-item {
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #808080;
      }
      .active {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        // font-weight: bold;
        font-size: 14px;
        line-height: 21px;

        font-weight: 500;
        color: var(--main-color);
      }
    }
    .content-list {
      padding: 10px 18px 50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .company-num {
        width: 100%;
        padding-bottom: 12px;
        font-size: 14px;
        color: #979da6;
        line-height: 20px;
      }
      .list-item + .list-item {
        margin-top: 10px;
      }
      .list-item {
        width: 100%;
        border-radius: 4px;
        background-color: #fff;
        padding: 16px 12px 16px;
        .item-top {
          display: flex;
          .item-img {
            width: 68px;
            height: 68px;
            margin-right: 14px;
          }
          .item-desc {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .item-name {
              width: 225px;
              height: 40px;
              @include clamp(2);
              font-size: 15px;
              font-weight: bold;
              color: #191e29;
              line-height: 20px;
            }
            .item-status {
              display: flex;

              .status-icon + .status-icon {
                margin-left: 6px;
              }
              .status-icon {
                width: 44px;
                height: 16px;
              }
            }
          }
        }
        .item-middle {
          padding: 0;
          overflow: hidden;
          margin-top: 20px;

          .item-data {
            display: flex;
            align-items: center;

            .data-item {
              padding: 10px 15px 10px 10px;
              flex: 1;
              flex-shrink: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-right: 6px;
              background-color: #f6f7f8;
              position: relative;

              &::before {
                content: "";
                display: block;
                width: 2px;
                height: 30px;
                // border-width: 2px;
                // border-style: solid;
                // /* border-color: 上 右 下 左; */
                // border-color: transparent transparent transparent gold;

                // background-color: #f1b51d;

                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
              .data-top {
                font-size: 12px;
                color: #808080;
                line-height: 11px;
              }
              .data-bottom {
                margin-top: 8px;
                font-size: 12px;
                color: #1a1a1a;
                line-height: 11px;
                @include clamp(1);
              }
              &:nth-child(1) {
                &::before {
                  content: "";
                  width: 2px;
                  height: 30px;
                  background-image: url("../../assets/images/st-company-library/line1.png");
                  background-size: 100%;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
              &:nth-child(2) {
                &::before {
                  content: "";
                  width: 2px;
                  height: 30px;
                  background-image: url("../../assets/images/st-company-library/line2.png");
                  background-size: 100%;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
              &:nth-child(3) {
                &::before {
                  content: "";
                  width: 2px;
                  height: 30px;
                  background-image: url("../../assets/images/st-company-library/line3.png");
                  background-size: 100%;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
        .item-btn {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 21px;
          .btn-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 72px;
            height: 28px;
            background: var(--main-color);
            border-radius: 16px;
            font-size: 12px;
            color: #ffffff;
            line-height: 12px;
          }
        }
      }
    }
  }
}
</style>
