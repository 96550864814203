var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-container",style:(_vm.myRootStyle)},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"brand-list",style:([
      
      {
        minHeight: _vm.loading ? '100px' : '0px',
        '--data-spacing': '8px',
        '--swiper-color': _vm.myConfig.templateData.swiperColor,
        borderRadius: _vm.myConfig.normal.borderRadius + 'px',
        marginLeft: _vm.myConfig.normal.rowMargin + 'px',
        marginRight: _vm.myConfig.normal.rowMargin + 'px',
      } ])},[_c('div',{staticClass:"list-title",style:({
        backgroundImage:
          _vm.myConfig.listTitle.isTitleDecoration && _vm.myConfig.listTitle.isAddIcon
            ? ("url(" + (require('@/modules/app-design/assets/images/st-community-talent/bg-icon.png')) + ")")
            : ("url(" + (require('@/modules/app-design/assets/images/st-community-talent/bg.png')) + ")"),
      })},[(_vm.myConfig.listTitle.titleFamily === 'template2')?_c('div',{staticClass:"text",style:({
          'font-size': _vm.myConfig.listTitle.fontSize + 'px',
          fontWeight: _vm.myConfig.listTitle.fontWeight,
          color: _vm.myConfig.listTitle.titleColor,
        })},[_vm._v(" "+_vm._s(_vm.myConfig.listTitle.title)+" ")]):_vm._e(),(
          _vm.myConfig.listTitle.titleFamily === 'template1' &&
          _vm.myConfig.listTitle.artFontPhoto
        )?_c('div',{staticClass:"art-text"},[_c('img',{attrs:{"src":_vm.myConfig.listTitle.artFontPhoto}})]):_vm._e()]),(_vm.myStyleType === 'template1')?_c('div',{staticClass:"template1 content-box",style:({})},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item-box",style:({
          borderRadius: '4px',
          border:
            _vm.myConfig.normal.interfaceStyle == 'card'
              ? 'rgba(26, 117, 255, 0.1)'
              : 'none',
          backgroundColor:
            _vm.myConfig.normal.interfaceStyle == 'card' ? '#fff' : 'transparent',
        })},[_c('div',{staticClass:"list-item",style:({
            padding:
              _vm.myConfig.normal.interfaceStyle == 'card' ? '12px' : '0px',
            background:
              _vm.myConfig.normal.interfaceStyle == 'card'
                ? "linear-gradient(180deg,  rgba(253, 190, 32,0.05) 0%, rgba(253, 190, 32, 0) 100%)"
                : 'none',
            border:
              _vm.myConfig.normal.interfaceStyle == 'card'
                ? '0.5px solid #fdbe2040'
                : 'none',
          })},[_c('div',{staticClass:"cover"},[_c('img',{attrs:{"src":item.avatar || _vm.defaultAvatar}})]),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.nickname))]),_c('div',{staticClass:"title-name"},[_vm._v(_vm._s(item.title_name))])]),(item.work_place && item.work_role)?[_c('div',{staticClass:"detail"},[_vm._v(" "+_vm._s(item.work_place)+" "+_vm._s(item.work_role)+" ")])]:(item.category_names)?[_c('div',{staticClass:"detail"},[_vm._v(" "+_vm._s(item.category_names)+" ")])]:_vm._e()],2),_vm._m(0,true)])])}),(_vm.myConfig.templateData.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.templateData.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.templateData.handleText)+" ")])]):_vm._e()],2):_vm._e(),(_vm.myStyleType === 'template2')?_c('div',{staticClass:"template2 content-box",style:({
        padding:
          _vm.myConfig.normal.interfaceStyle == 'card'
            ? '10px 12px'
            : '10px 12px',
      })},[_c('div',{staticClass:"item-content"},_vm._l((_vm.list.slice(0, 2)),function(item,index){return _c('div',{key:index,staticClass:"item-box"},[_c('div',{staticClass:"list-item",style:({
              borderRadius: '6px',
              padding:
                _vm.myConfig.normal.interfaceStyle == 'card' ? '12px' : '0px',
              background:
                _vm.myConfig.normal.interfaceStyle == 'card'
                  ? "linear-gradient(180deg,  rgba(253, 190, 32,0.05) 0%, rgba(253, 190, 32, 0) 100%)"
                  : 'none',
              border:
                _vm.myConfig.normal.interfaceStyle == 'card'
                  ? '0.5px solid #fdbe2040'
                  : 'none',
            })},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"cover"},[_c('img',{attrs:{"src":item.avatar || _vm.defaultAvatar}})]),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.nickname))]),_c('div',{staticClass:"title-name"},[_vm._v(_vm._s(item.title_name))])])]),(item.work_place && item.work_role)?[_c('div',{staticClass:"bottom"},[_vm._v(" "+_vm._s(item.work_place)+" "+_vm._s(item.work_role)+" ")])]:(item.category_names)?[_c('div',{staticClass:"bottom"},[_vm._v(" "+_vm._s(item.category_names)+" ")])]:_vm._e(),_vm._m(1,true)],2)])}),0),_vm._m(2)]):_vm._e(),(_vm.myStyleType === 'template3')?_c('div',{staticClass:"template3 content-box",style:({})},[_c('div',{staticClass:"item-content"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item-box",style:({
            border:
              _vm.myConfig.normal.interfaceStyle == 'card'
                ? '0.5px solid #fdbe2040'
                : 'none',
            background:
              _vm.myConfig.normal.interfaceStyle == 'card'
                ? "linear-gradient(180deg,  rgba(253, 190, 32,0.05) 0%, rgba(253, 190, 32, 0) 100%)"
                : 'transparent',
            minWidth:
              _vm.myConfig.normal.interfaceStyle == 'card' ? '140px' : '108px',
          })},[_c('div',{staticClass:"list-item",style:({
              padding:
                _vm.myConfig.normal.interfaceStyle == 'card' ? '12px 0px' : '0px',
            })},[_c('img',{attrs:{"src":item.avatar || _vm.defaultAvatar}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.nickname))]),_c('div',{staticClass:"member"},[_vm._v(_vm._s(item.title_name))])])])}),0)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[_c('img',{attrs:{"src":require("@/modules/app-design/assets/images/st-community-talent/box-bg.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[_c('img',{attrs:{"src":require("@/modules/app-design/assets/images/st-community-talent/box-bg.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"doi"},[_c('div',{staticClass:"item active"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"})])}]

export { render, staticRenderFns }