var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-contacts",staticStyle:{"user-select":"none"},style:({ paddingBottom: _vm.myConfig.normal.paddingBottom + 'px' })},[_c('div',{staticClass:"header",class:_vm.platform !== 'h5' && _vm.myConfig.normal.bgConfig === 'theme' ? 'theme-color' : '',style:({
      backgroundColor:
        _vm.platform === 'h5'
          ? ''
          : _vm.myConfig.normal.bgConfig === 'custom' &&
            (_vm.myConfig.normal.bgType === 'color' || _vm.myConfig.normal.bgType === 'theme')
          ? _vm.myConfig.normal.bgColor
          : '',
      backgroundImage:
        _vm.myConfig.normal.bgType === 'image' ? ("url(" + (_vm.fixImageUrl(_vm.myConfig.normal.bgImage, { h: 400 }, true)) + ")") : '',
      backgroundSize: 'cover',
    })},[(_vm.platform !== 'h5')?[_c('img',{staticClass:"start-top-img",attrs:{"src":_vm.statusBar[_vm.myConfig.normal.statusBarColor]}}),_c('div',{staticClass:"start-top",staticStyle:{"text-align":"end"}},[(_vm.myConfig.normal.showHeaderJump)?_c('img',{staticClass:"header-jump",class:[_vm.headerJump.showConfig.showType !== 'template1' ? 'template-back-icon' : 'default-back-icon'],attrs:{"src":_vm.backIcon[_vm.myConfig.normal.statusBarColor][_vm.headerJump.showConfig.showType]}}):_vm._e(),_c('img',{staticClass:"start-top-handler",attrs:{"src":_vm.handleIcon[_vm.myConfig.normal.statusBarColor]}})])]:_c('st-page-set',{attrs:{"config":_vm.myConfig}}),_c('div',{staticClass:"type-select",class:[_vm.platform === 'h5' ? 'simple' : '']},[(_vm.myConfig.normal.titleConfig.isShow)?_c('div',{staticClass:"type-item",style:({
          color: _vm.myConfig.normal.fontColor,
          fontWeight: _vm.myConfig.normal.fontWeight,
          fontSize: _vm.myConfig.normal.fontSize + 'px',
        })},[_vm._v(" "+_vm._s(_vm.myConfig.normal.titleConfig.name)+" ")]):_vm._e()]),(_vm.myConfig.searchConfig.isShow)?_c('div',{staticClass:"search",class:_vm.platform !== 'h5' && _vm.myConfig.normal.bgConfig === 'theme' ? 'theme-color' : '',staticStyle:{"display":"block"},style:({
        backgroundColor:
          _vm.platform === 'h5'
            ? ''
            : _vm.myConfig.normal.bgConfig === 'custom' &&
              (_vm.myConfig.normal.bgType === 'color' || _vm.myConfig.normal.bgType === 'theme')
            ? _vm.myConfig.normal.bgColor
            : '',
      })},[_c('div',{staticStyle:{"display":"flex","width":"100%","padding":"0 18px 0 18px","align-items":"center"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"class-list-item",on:{"click":function($event){_vm.currentTab = 0}}},[_c('div',{class:{
                selected: _vm.currentTab === 0,
              }},[_vm._v(" 全部 ")]),_c('div',{staticClass:"line-block",class:{
                line: _vm.currentTab === 0,
              }})]),_c('div',{staticClass:"class-list-item",on:{"click":function($event){_vm.currentTab = 1}}},[_c('div',{class:{
                selected: _vm.currentTab === 1,
              }},[_vm._v(" 同城 ")]),_c('div',{staticClass:"line-block",class:{
                line: _vm.currentTab === 1,
              }})])]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","flex":"1"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 0),expression:"currentTab === 0"}],staticStyle:{"display":"flex","width":"100%","padding":"18px 18px 0 18px","align-items":"center","font-size":"14px","color":"#fff","opacity":"0.9"}},[_vm._m(0),_vm._m(1),_vm._m(2)])]):_vm._e()],2),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 0),expression:"currentTab === 0"}],staticStyle:{"width":"100%"},attrs:{"src":(_vm.baseImgUrl + "/app_design/school-contact-list-all.jpg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 1),expression:"currentTab === 1"}],staticStyle:{"width":"100%"},attrs:{"src":(_vm.baseImgUrl + "/app_design/assets/images/school-contact-list-local.jpg")}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"flex":"1","display":"flex","align-items":"center"}},[_c('div',[_vm._v("就读类别")]),_c('img',{staticStyle:{"width":"8px","height":"5px","margin-left":"5px"},attrs:{"src":require("../../assets/images/swiftdown.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"flex":"1","display":"flex","align-items":"center"}},[_c('div',[_vm._v("就读校区")]),_c('img',{staticStyle:{"width":"8px","height":"5px","margin-left":"5px"},attrs:{"src":require("../../assets/images/swiftdown.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"flex":"1","display":"flex","align-items":"center"}},[_c('div',[_vm._v("毕业年份")]),_c('img',{staticStyle:{"width":"8px","height":"5px","margin-left":"5px"},attrs:{"src":require("../../assets/images/swiftdown.png")}})])}]

export { render, staticRenderFns }