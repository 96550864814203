<template>
  <div class="app-blank flex-col flex-center">
    <img class="icon" src="../../../assets/images/app/app-blank/blank.png"/>
    <span class="text" style="margin-top: 21px; ">{{text || '暂无内容'}}</span>
  </div>
</template>

<script>
  export default {
    name: "AppBlank",
    props: {
      text: {
        type: String,
        default: '暂无内容'
      },
    },
  }
</script>

<style lang="scss" scoped>
.app-blank {
  min-height: 600px;

  .icon {
    width: 154px;
    height: 90px;
    /*background: #C4C4C4;*/
  }

  .text {
    font-size: 14px;
    color: #808080;
    line-height: 21px;
  }
}
</style>
