<template>
  <div :class="['el-form-item ', isFlex ? 'flex-box' : '']">
    <div
      class="radio-header"
      :style="{
        marginBottom: isFlex ? 0 : '24px',
      }"
      v-if="title"
    >
      <span class="title">{{ title }}</span>
      <span class="suggest" v-if="suggestText && suggestText[value]">{{
        `(建议尺寸${suggestTextDisplay}px)`
      }}</span>
    </div>
    <div class="radio-content">
      <el-radio
        :style="{
          marginBottom:
            index <
            options.length - (options.length % 4 ? options.length % 4 : 4)
              ? '16px'
              : '',
          width: options.length >= 4 ? '25%' : 100 / options.length + '%',
        }"
        v-for="(item, index) in options"
        :key="index"
        v-model="value"
        :label="item.value"
        >{{ item.name }}
      </el-radio>
    </div>
    <div class="tipsText" v-if="tipsText && tipsText[value]">
      {{ `${tipsText[value]}` }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StTemplateRadio',
  model: {
    prop: 'template',
    event: 'templateChange',
  },
  props: {
    title: {
      type: String,
    },
    suggestText: {
      type: [Array, Object],
      default: () => {
        return null
      },
    },
    tipsText: {
      type: [Array, Object],
      default: () => {
        return null
      },
    },
    options: {
      type: Array,
      required: true,
    },
    template: {
      type: [String, Number],
    },
    // 浮动
    isFlex: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value: {
      get() {
        return this.template
      },
      set(val) {
        this.$emit('templateChange', val)
      },
    },
    suggestTextDisplay() {
      const original = this.suggestText[this.value]
      const [w, h] = original.split('*').map((el) => parseInt(el.trim()))
      // if (w === w > 900) {
      //   return original
      // } else {
      return `${w * 3}*${h * 3}`
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.radio-header {
  align-items: center;
  //
  .title {
    line-height: 14px;
    font-size: 14px;
    color: #606266;
  }
  .suggest {
    margin-left: 6px;
    line-height: 14px;
    font-size: 14px;
    color: #c0c4cc;
  }
}

.radio-content {
  display: flex;
  flex-wrap: wrap;
  .el-radio {
    margin-right: 0;
    padding-right: 10px;
  }
}
.tipsText {
  font-size: 12px;
  color: #c0c4cc;

  line-height: 14px;
  margin-top: 8px;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
