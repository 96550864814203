var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:({
    padding: ("12px " + (_vm.myStyleType === 'template3' ? '16' : '0') + "px"),
    margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
    borderRadius: ((_vm.myConfig.normal.borderRadius) + "px"),
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage: _vm.myBgImage,
  })},[_c('StListTitle',{style:({
      padding: "0 16px 16px",
    }),attrs:{"config":_vm.myConfig}}),_c('div',{staticClass:"cover-box"},[_c('img',{staticClass:"cover",attrs:{"src":_vm.StCollegeRecommendListPreview}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }