var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"container"},[_c('div',{staticClass:"flex-content-box"},[_c('div',{staticClass:"category"},_vm._l((_vm.categoryOptions),function(item,i){return _c('div',{key:item.value,staticClass:"item",class:[i === 0 ? 'active' : '']},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_c('div',{staticClass:"goods"},[_vm._m(0),_c('div',{staticClass:"goods-list"},[_c('div',{staticClass:"swiper-view flex-column template2",style:({
            marginTop: _vm.myTemplateData.marginTop + 'px',
            marginLeft: _vm.myTemplateData.rowMargin + 'px',
            marginRight: _vm.myTemplateData.rowMargin + 'px',
          })},_vm._l((_vm.goods_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({})},[_c('div',{staticClass:"img-box",style:({
                borderRadius: _vm.myTemplateData.borderRadius + 'px',

                height:
                  _vm.myTemplateData.photoSize === 'template1'
                    ? '60px'
                    : _vm.myTemplateData.photoSize === 'template3'
                    ? '80px'
                    : '80px',
              })},[_c('st-load-image',{staticClass:"normal",attrs:{"src":item.image || item.res[0].thumbnail || item.res[0].url,"size":300}})],1),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"goods-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"goods-price"},[_c('div',{staticClass:"price-content"},[(item.price)?[_c('span',{staticClass:"price-unit"},[_vm._v("￥")]),_c('span',{staticClass:"price-count"},[_vm._v(_vm._s(item.price))])]:(item.integral)?[_c('span',{staticClass:"price-count"},[_vm._v(_vm._s(item.integral))]),_c('span',{staticClass:"price-unit"},[_vm._v("积分")])]:_vm._e(),(item.data_type && item.max_market_price)?_c('span',{staticClass:"old-price"},[_vm._v("￥"+_vm._s(item.max_market_price))]):_vm._e()],2),(_vm.myTemplateData.ShowPayBtn)?[(_vm.myTemplateData.buttonTemplate === 'template1')?_c('div',{staticClass:"button-template-1"},[_c('img',{attrs:{"src":require("../assets/images/button-1.png")}})]):_vm._e(),(_vm.myTemplateData.buttonTemplate === 'template2')?_c('div',{staticClass:"button-template-3"},[_c('img',{attrs:{"src":require("../assets/images/button-3.png")}})]):_vm._e()]:_vm._e()],2)])])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box"},[_c('div',{staticClass:"filter-item"},[_vm._v("综合")]),_c('div',{staticClass:"filter-item"},[_vm._v("销量")]),_c('div',{staticClass:"filter-item"},[_vm._v("价格"),_c('i',{staticClass:"el-icon-d-caret"})])])}]

export { render, staticRenderFns }