var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"donation-list",style:({
    padding: "12px 16px 0",

    marginTop: ((_vm.myConfig.normal.marginTop) + "px"),
  })},[_c('div',{style:({
      marginLeft: ((_vm.myConfig.normal.rowMargin) + "px"),
      marginRight: ((_vm.myConfig.normal.rowMargin) + "px"),
    })},[_c('StListTitle',{attrs:{"config":_vm.myConfig}})],1),(_vm.myConfig.templateData.template === 'template1')?[_c('div',{staticClass:"donation-swiper",style:({
        margin: "12px 0",
        padding: '10px 0',
      })},_vm._l((_vm.dataList.slice(0,_vm.myConfig.dataLoad.limit)),function(item,i){return _c('div',{key:item.id,staticClass:"content-box",style:({
          padding: ("0px " + (_vm.myConfig.normal.rowMargin) + "px"),
          overflow: 'hidden',
        })},[_c('div',{staticClass:"swiper-item template1",style:({
            backgroundColor:
              _vm.myConfig.normal.bgType === 'color'
                ? _vm.myConfig.normal.bgColor
                : '',
            backgroundImage: _vm.myBgImage,
          })},[_c('div',{staticClass:"item-detail"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.cover,"alt":""}})]),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"time"},[_c('img',{attrs:{"src":require("../../assets/images/st-donation-list/time.png"),"alt":""}}),_vm._v(" "+_vm._s(item.donation_time_text)+" ")])])]),_c('div',{staticClass:"progress",style:({
              backgroundColor:
                item.donation_status === 1 ? '#FFEEE2' : '#f8f8f8',
            })},[_c('div',{staticClass:"title"},[(item.donation_status === 0)?_c('img',{staticClass:"progress-icon",attrs:{"src":require("../../assets/images/st-donation-list/Slice-21.png"),"alt":""}}):(item.donation_status === 1)?_c('img',{staticClass:"progress-icon",attrs:{"src":require("../../assets/images/st-donation-list/Slice-19.png"),"alt":""}}):(item.donation_status === 2)?_c('img',{staticClass:"progress-icon",attrs:{"src":require("../../assets/images/st-donation-list/Slice-18.png"),"alt":""}}):_vm._e(),(item.donation_status === 1)?_c('img',{staticClass:"money-icon",attrs:{"src":require("../../assets/images/st-donation-list/Slice-22.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"progress-box"},[_c('div',{staticClass:"figure"},[_c('div',{staticClass:"areday",style:({
                    color: item.donation_status === 1 ? '#FF7B1B' : '#808080',
                  })},[(item.donation_status === 1)?_c('img',{attrs:{"src":require("../../assets/images/st-donation-list/icon-aready.png"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/st-donation-list/icon-target.png"),"alt":""}}),_c('p',[_vm._v("已筹金额：¥"+_vm._s(item.raised.toLocaleString()))])]),_c('div',{staticClass:"target"},[_c('img',{attrs:{"src":require("../../assets/images/st-donation-list/icon-target.png"),"alt":""}}),_c('p',[_vm._v("目标金额：¥"+_vm._s(item.target_price.toLocaleString()))])])]),_c('div',{staticClass:"progress-line"},[(!isNaN(item.progress))?_c('el-progress',{attrs:{"show-text":false,"percentage":item.progress,"color":item.donation_status === 1 ? '#FF7B1B' : '#808080'}}):_vm._e()],1)])])])])}),0)]:(_vm.myConfig.templateData.template === 'template2')?[_c('div',{staticClass:"donation-swiper",style:({
        margin: "12px 0px",
        padding: '10px 0',
      })},_vm._l((_vm.dataList.slice(0,_vm.myConfig.dataLoad.limit)),function(item,i){return _c('div',{key:item.id,staticClass:"content-box",style:({
          padding: ("0px " + (_vm.myConfig.normal.rowMargin) + "px"),
          overflow: 'hidden',
        })},[_c('div',{staticClass:"swiper-item template2"},[_c('div',{staticClass:"item-detail"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.cover,"alt":""}})]),_c('div',{staticClass:"desc",style:({
                backgroundColor:
                  _vm.myConfig.normal.bgType === 'color'
                    ? _vm.myConfig.normal.bgColor
                    : '',
                backgroundImage: _vm.myBgImage,
              })},[(item.start_institution.length)?_c('div',{staticClass:"start"},[_c('img',{attrs:{"src":item.start_institution[0].avatar,"alt":""}}),_c('span',[_vm._v("由 "+_vm._s(item.start_institution[0].name)+" 联合发起")])]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"time"},[_c('img',{attrs:{"src":require("../../assets/images/st-donation-list/time.png"),"alt":""}}),_vm._v(" "+_vm._s(item.donation_time_text)+" ")]),_c('div',{staticClass:"progress",style:({
                  backgroundColor:
                    item.donation_status === 1 ? '#FFEEE2' : '#f8f8f8',
                })},[_c('div',{staticClass:"title"},[(item.donation_status === 0)?_c('img',{staticClass:"progress-icon",attrs:{"src":require("../../assets/images/st-donation-list/Slice-21.png"),"alt":""}}):(item.donation_status === 1)?_c('img',{staticClass:"progress-icon",attrs:{"src":require("../../assets/images/st-donation-list/Slice-19.png"),"alt":""}}):(item.donation_status === 2)?_c('img',{staticClass:"progress-icon",attrs:{"src":require("../../assets/images/st-donation-list/Slice-18.png"),"alt":""}}):_vm._e(),(item.donation_status === 1)?_c('img',{staticClass:"money-icon",attrs:{"src":require("../../assets/images/st-donation-list/Slice-22.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"progress-box"},[_c('div',{staticClass:"figure"},[_c('div',{staticClass:"areday",style:({
                        color:
                          item.donation_status === 1 ? '#FF7B1B' : '#808080',
                      })},[(item.donation_status === 1)?_c('img',{attrs:{"src":require("../../assets/images/st-donation-list/icon-aready.png"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/st-donation-list/icon-target.png"),"alt":""}}),_c('p',[_vm._v("已筹金额：¥"+_vm._s(item.raised.toLocaleString()))])]),_c('div',{staticClass:"target"},[_c('img',{attrs:{"src":require("../../assets/images/st-donation-list/icon-target.png"),"alt":""}}),_c('p',[_vm._v(" 目标金额：¥"+_vm._s(item.target_price.toLocaleString())+" ")])])]),_c('div',{staticClass:"progress-line"},[(!isNaN(item.progress))?_c('el-progress',{attrs:{"show-text":false,"percentage":item.progress,"color":item.donation_status === 1 ? '#FF7B1B' : '#808080'}}):_vm._e()],1)])])])])])])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }