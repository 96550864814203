<template>
  <div class="container">
    <!-- 筛选tab -->
    <div class="filter-box">
      <div class="box-item active">综合</div>
      <div class="box-item">销量</div>
      <div class="box-item">价格<i class="el-icon-d-caret"></i></div>
    </div>
    <!-- 模板一 -->
    <!-- 瀑布流布局模板 -->
    <div
      v-loading="loading"
      :style="{
        marginTop: myTemplateData.marginTop + 'px',
        marginLeft: myTemplateData.rowMargin + 'px',
        marginRight: myTemplateData.rowMargin + 'px',
        paddingTop: '12px',
      }"
      class="swiper-view water-fall"
      v-if="myTemplateData.template === 'template1'"
    >
      <!-- 将列表分为两列进行渲染 -->
      <div v-for="(list, i) in waterfall_datas" class="item-list" :key="i">
        <div :ref="i ? 'rightList' : 'leftList'">
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <div
              class="item-box"
              :style="{
                borderRadius: myTemplateData.borderRadius + 'px',
                marginTop: index > 0 ? '12px' : '',
              }"
            >
              <div
                class="img-box"
                :style="{
                  borderRadius: `${myTemplateData.borderRadius}px ${myTemplateData.borderRadius}px 0 0`,
                  border: myTemplateData.showAddBorder ? '4px solid #fff' : '',
                }"
              >
                <st-load-image
                  :src="item.image || item.res[0].thumbnail || item.res[0].url"
                  @imgLoad="listRanding"
                  @imgError="listRanding"
                  :proportion="
                    myTemplateData.photoSize === 'template2' ? '75%' : '100%'
                  "
                />
              </div>
              <div class="item-content">
                <p class="goods-name">{{ item.name }}</p>
                <div class="goods-price">
                  <div class="price-content">
                    <template v-if="item.price">
                      <span class="price-unit">￥</span>
                      <span class="price-count">{{
                      Math.floor(item.price)
                    }}</span>
                    </template>
                    <template v-else-if="item.integral">
                             <span class="price-count">{{
                      Math.floor(item.integral)
                    }}</span>
                      <span class="price-unit">积分</span>
                    </template>
                    <span class="price-count-two" v-if="getSmallNum(item.price)"
                      >.{{ getSmallNum(item.price) }}</span
                    >
                    <span
                      class="old-price"
                      v-if="item.data_type && item.max_market_price"
                      >￥{{ item.max_market_price }}</span
                    >
                  </div>
                  <!-- 购买按钮 -->
                  <template v-if="myTemplateData.ShowPayBtn">
                    <div
                      class="button-template-1"
                      v-if="myTemplateData.buttonTemplate === 'template1'"
                    >
                      <img src="../assets/images/button-1.png" />
                    </div>
                    <!-- <ColorCustomIcon
                      v-if="myTemplateData.buttonTemplate === 'template2'"
                      :width="24"
                      :height="24"
                      :icon="require('../assets/images/button-2.png')"
                    ></ColorCustomIcon> -->
                    <div
                      class="button-template-3"
                      v-if="myTemplateData.buttonTemplate === 'template2'"
                    >
                      <img src="../assets/images/button-3.png" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 模板二 -->
    <div
      v-loading="loading"
      class="swiper-view flex-column"
      :class="myTemplateData.template"
      v-if="['template2'].includes(myTemplateData.template)"
      :style="{
        marginTop: myTemplateData.marginTop + 'px',
        marginLeft: myTemplateData.rowMargin + 'px',
        marginRight: myTemplateData.rowMargin + 'px',
        paddingTop: '12px',
      }"
    >
      <div
        class="list-item"
        :style="{
          marginTop: index > 0 ? '12px' : '0px',
          borderRadius: myTemplateData.borderRadius + 'px',
        }"
        v-for="(item, index) in goods_list"
        :key="index"
      >
        <div
          class="img-box"
          :style="{
            borderRadius: myTemplateData.borderRadius + 'px',

            width:
              myTemplateData.photoSize === 'template1'
                ? '124px'
                : myTemplateData.photoSize === 'template3'
                ? '92px'
                : '124px',
          }"
        >
          <st-load-image
            :src="item.image || item.res[0].thumbnail || item.res[0].url"
            class="normal"
            :size="300"
          />
        </div>
        <div class="item-content">
          <p class="goods-name">{{ item.name }}</p>
          <div class="goods-price">
            <div class="price-content">
              <template v-if="item.price">
                <span class="price-unit">￥</span>
                <span class="price-count">{{ item.price }}</span>
              </template>
              <template v-else-if="item.integral">
                <span class="price-count">{{ item.integral }}</span>
                <span class="price-unit">积分</span>
              </template>
              <span
                class="old-price"
                v-if="item.data_type && item.max_market_price"
                >￥{{ item.max_market_price }}</span
              >
            </div>
            <!-- 购买按钮 -->
            <template v-if="myTemplateData.ShowPayBtn">
              <div
                class="button-template-1"
                v-if="myTemplateData.buttonTemplate === 'template1'"
              >
                <img src="../assets/images/button-1.png" />
              </div>
              <!-- <ColorCustomIcon
                v-if="myTemplateData.buttonTemplate === 'template2'"
                :width="24"
                :height="24"
                :icon="require('../assets/images/button-2.png')"
              ></ColorCustomIcon> -->
              <div
                class="button-template-3"
                v-if="myTemplateData.buttonTemplate === 'template2'"
              >
                <img src="../assets/images/button-3.png" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 模板三 -->
    <div
      v-loading="loading"
      class="swiper-view template3"
      v-if="myTemplateData.template === 'template3'"
      :style="{
        marginTop: myTemplateData.marginTop + 'px',
        marginLeft: myTemplateData.rowMargin + 'px',
        marginRight: myTemplateData.rowMargin + 'px',
        paddingTop: '12px',
      }"
    >
      <div
        class="list-item"
        style="width: 100%"
        :style="{
          marginTop: index > 0 ? '12px' : '0px',
          borderRadius: myTemplateData.borderRadius + 'px',
        }"
        v-for="(item, index) in goods_list"
        :key="index"
      >
        <div class="img-box" :style="{}" style="width: 100%">
          <st-load-image
            :src="item.image || item.res[0].thumbnail || item.res[0].url"
            class="normal"
            :size="500"
            :proportion="
              myTemplateData.photoSize === 'template3' ? '100%' : '75%'
            "
          />
        </div>
        <div class="item-content">
          <p class="goods-name">{{ item.name }}</p>
          <div class="goods-price">
            <div class="price-content">
              <template v-if="item.price">
                <span class="price-unit">￥</span>
                <span class="price-count">{{ item.price }}</span>
              </template>
              <template v-else-if="item.integral">
                <span class="price-count">{{ item.integral }}</span>
                <span class="price-unit">积分</span>
              </template>
              <span
                class="old-price"
                v-if="item.data_type && item.max_market_price"
                >￥{{ item.max_market_price }}</span
              >
            </div>
            <!-- <div class="pay-btn" v-if="myTemplateData.ShowPayBtn">
              <div class="left-btn">
                <ColorCustomIcon
                  :width="16"
                  :height="16"
                  :icon="require('../assets/images/button-1.png')"
                ></ColorCustomIcon>
              </div>
              <div class="right-btn">购买</div>
            </div> -->
            <!-- 购买按钮 -->
            <template v-if="myTemplateData.ShowPayBtn">
              <div
                class="button-template-1"
                v-if="myTemplateData.buttonTemplate === 'template1'"
              >
                <img src="../assets/images/button-1.png" />
              </div>
              <!-- <ColorCustomIcon
                v-if="myTemplateData.buttonTemplate === 'template2'"
                :width="24"
                :height="24"
                :icon="require('../assets/images/button-2.png')"
              ></ColorCustomIcon> -->
              <div
                class="button-template-3"
                v-if="myTemplateData.buttonTemplate === 'template2'"
              >
                <img src="../assets/images/button-3.png" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goodsList } from "../../../../api/components";

import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";
import ColorCustomIcon from "@/modules/app-design/components/Normal/ColorCustomIcon";
export default {
  name: "CategoryTemplate1",
  components: { StLoadImage, ColorCustomIcon },

  props: {
    config: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      goods_list: [],
      // 瀑布流数据数组
      waterfall_datas: [[], []],
      randingIndex: 0,
    };
  },
  watch: {
    "myTemplateData.template"(val) {
      this.getGoodsList(false);
    },
  },
  computed: {
    myTemplateData() {
      return this.$props.config?.templateData || {};
    },
    systemPageName() {
      return this.$store.state.appDesign.pageDetail.system_page
    }
  },

  methods: {
    getGoodsList(notCancel) {
      this.waterfall_datas = [[], []];
      this.randingIndex = 0;
      let ids = [];
      let requestData = {
        page: 1,
        limit: 10,
        category_all: 1,
        category_ids: [],
        use_ids: 0,
        template: this.myTemplateData.template,
        ids,
        pay_type: this.systemPageName === 'integral_shop_category' ? 1 : -1
      };
      this.loading = true;
      goodsList(requestData, notCancel)
        .then((res) => {
          let { data } = res;

          this.goods_list = data.length ? data : this.defaultList;
          if (this.myTemplateData.template === "template1") {
            this.waterfall_datas[0].push(this.goods_list[0]);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 截取小数点后两位数字
    getSmallNum(num) {
      if (typeof num === "number") {
        let numStringArr = num.toString().split(".");
        return numStringArr.length > 1 ? numStringArr[1] : null;
      }
      return null;
    },
    // 模板1列表数据处理
    listRanding() {
      let leftHeight = this.$refs.leftList[0].offsetHeight;
      let rightHeight = this.$refs.rightList[0].offsetHeight;
      if (this.randingIndex + 1 >= this.goods_list.length) return;
      if (leftHeight > rightHeight) {
        this.waterfall_datas[1].push(this.goods_list[this.randingIndex + 1]);
      } else {
        this.waterfall_datas[0].push(this.goods_list[this.randingIndex + 1]);
      }
      this.randingIndex += 1;
    },
  },
  mounted() {
    console.log(this.config);
    this.getGoodsList();
  },
};
</script>

<style lang="scss" scoped>
.container {
  .filter-box {
    height: 42px;
    display: flex;
    background-color: #fff;

    .box-item.active {
      color: var(--main-color);
    }

    .box-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      font-family: "PingFang SC";
      color: #808080;
      i {
        margin-left: 4px;
        color: 10px;
      }
    }
  }
  //  模板一
  .water-fall {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    column-gap: 8px;
    padding: 0 16px;
    .list-item {
      width: 100%;
      .item-box {
        width: 100%;
        // padding: 8px;
        background-color: #fff;
        box-shadow: 0px 6px 10px 0px #f2f2f2;
        overflow: hidden;
        .img-box {
          width: 100%;
          position: relative;
          overflow: hidden;
          &::before {
            content: "";
            display: block;
            // padding-top: 100%;
          }
          .normal {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .default {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 28px !important;
            height: 28px !important;
            z-index: 2;
          }
        }
        .item-content {
          width: 100%;
          padding: 8px;
          .goods-name {
            font-size: 14px;
            color: #1a1a1a;
            line-height: 1.5;
            @include clamp(2);
          }
          .goods-price {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            line-height: 1.5;
            font-weight: bold;
            .price-content {
              .price-unit {
                font-size: 12px;
                color: #ff2647;
              }
              .price-count {
                font-size: 18px;
                color: #ff2647;
              }
              .price-count-two {
                font-size: 18px;
                color: #ff2647;
              }
            }
            .buy-btn {
              width: 30px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  // 模板二
  .flex-column {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    &.template2 {
      .list-item {
        padding: 12px;
        .img-box {
          width: 124px;
          height: 92px;
          margin-right: 11px;
        }
        .item-content {
          .goods-name {
            font-size: 16px;
            line-height: 22px;
          }
          .goods-price {
            .price-content {
              .price-unit {
                font-size: 12px;
                font-weight: bold;
                color: #ff2647;
                line-height: 17px;
              }
              .price-count {
                font-size: 16px;
                font-weight: bold;
                color: #ff2647;
                line-height: 22px;
              }
            }
            .pay-btn {
              width: 72px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--main-color);
              border-radius: 16px;
              font-size: 12px;
              color: #ffffff;
              line-height: 12px;
            }
          }
        }
      }
    }
    .list-item {
      background-color: #fff;
      padding: 12px;
      display: flex;
      box-shadow: 0px 6px 10px 0px #f2f2f2;

      .img-box {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .normal {
          width: 100%;
          height: 100%;
        }
        .default {
          width: 28px !important;
          height: 28px !important;
          z-index: 2;
        }
      }
      .item-content {
        flex: 1;
        position: relative;
        .goods-name {
          color: #1a1a1a;
          @include clamp(2);
        }
        .goods-price {
          width: 100%;
          position: absolute;
          bottom: 0px;
          margin-top: 9px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
      }
    }
  }
  // 模板三
  .template3 {
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px;
    .list-item {
      background-color: #fff;
      box-shadow: 0px 6px 10px 0px #f2f2f2;
      overflow: hidden;
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 100%;
        .normal {
          width: 100%;
          height: 100%;
        }
        .default {
          width: 28px !important;
          height: 28px !important;
          z-index: 2;
        }
      }
      .item-content {
        padding: 0 12px 14px;
        .goods-name {
          margin-top: 16px;
          font-size: 16px;
          font-weight: 500;
          font-family: "PingFang SC";
          color: #1a1a1a;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .goods-price {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .price-content {
            .price-unit {
              font-size: 12px;
              font-weight: bold;
              color: #ff2647;
              line-height: 17px;
            }
            .price-count {
              font-size: 24px;
              font-weight: bold;
              color: #ff2647;
              line-height: 33px;
            }
          }
          .pay-btn {
            height: 32px;
            border-radius: 30px;
            overflow: hidden;
            border: 1px solid var(--main-color);
            display: flex;
            .left-btn {
              width: 42px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .right-btn {
              width: 60px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: var(--main-color);
              color: #fff;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

// 购买按钮1
.button-template-1 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 12px;
    height: 12px;
  }
}
// 购买按钮3
.button-template-3 {
  width: 30px;
  height: 20px;
  border-radius: 22px;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 19px;
    height: 12px;
  }
}
// 通用原价
.old-price {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  font-family: "PingFang SC";
  color: #b3b3b3;
  text-decoration: line-through;
}
// 加载更多样式
.load-more {
  width: 100%;
  text-align: center;
  margin-top: 20px;

  .template1,
  .template2 {
    font-size: 12px;
    color: var(--main-color);
    line-height: 17px;
  }

  .template2 {
    display: inline-block;
    padding: 3px 11px;
    border-radius: 2px;
    border: 1px solid var(--main-color);
  }
}
</style>
