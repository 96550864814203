<!-- 消息广播组件 -->
<template>
  <div
    :style="{
      borderRadius: myConfig.normal.borderRadius + 'px',
      marginTop: `${myConfig.normal.marginTop}px`,
      marginLeft: `${myConfig.normal.padding}px`,
      marginRight: `${myConfig.normal.padding}px`,
      padding: '0 12px',
      backgroundColor:
        myConfig.normal.bgType === 'color' ? `${myConfig.normal.bgColor}` : '',
      backgroundImage:
        myConfig.normal.bgType === 'image'
          ? `url(${fixImageUrl(myConfig.normal.bgImage, {}, true)})`
          : '',
    }"
    class="st-broadcast"
  >
    <!-- 消息广播组件 模板1 -->
    <div
      v-if="myConfig.contentConfig.styleType === 'template1'"
      class="messageBroadcast"
      v-loading="loading"
      :style="{
        borderRadius: myConfig.contentConfig.borderRadius + 'px',
        // paddingRight: `${myConfig.normal.padding}px`,
        // paddingLeft: `${myConfig.normal.padding}px`,
        padding: `0 16px`,
        height: `${myConfig.normal.boxHeight}px`,
        boxShadow: myConfig.normal.boxShadow
          ? `0 2px ${myConfig.normal.boxShadow}px 0 rgba(0,0,0,0.06)`
          : 'none',
        backgroundColor: `${myConfig.contentConfig.bgColor}`,
      }"
    >
      <!-- 广播图标 -->
      <div class="broadcast">
        <img src="../../assets/images/st-broadcast/broadcast-icon.png" />
      </div>
      <!-- 轮播内容标题 -->
      <div class="title-box">
        <!-- <swiper
          ref="Swiper"
          class="broadcast-swiper"
          :options="options"
          :style="{ fontSize: `${myConfig.contentConfig.fontSize}px` }"
        >
          <swiper-slide class="swiper-item" v-for="item in titleList">{{
            item.title
          }}</swiper-slide>
        </swiper> -->
        <div
          class="title-content"
          :style="{ fontSize: `${myConfig.contentConfig.fontSize}px` }"
        >
          {{ this.titleList[0].title }}
        </div>
      </div>
      <!-- 更多广播 -->
      <div class="more-broadcast">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <!-- 消息广播组件 模板2 -->
    <div
      v-if="myConfig.contentConfig.styleType === 'template2'"
      class="messageBroadcast"
      v-loading="loading"
      :style="{
        borderRadius: myConfig.contentConfig.borderRadius + 'px',
        // 模板二默认 12px
        padding: `0 12px`,
        height: `${myConfig.normal.boxHeight}px`,
        boxShadow: myConfig.normal.boxShadow
          ? `0 2px ${myConfig.normal.boxShadow}px 0 rgba(0,0,0,0.06)`
          : 'none',
        backgroundColor: `${myConfig.contentConfig.bgColor}`,
      }"
    >
      <!-- 广播图标 -->
      <div
        :style="{
          marginLeft: '0px' || `${myConfig.normal.padding}px`,
        }"
        class="broadcast"
      >
        <i
          v-if="myConfig.contentConfig.iconColorType === 'custom'"
          class="el-icon-a-Slice172"
          :style="{
            color: `${myConfig.contentConfig.iconColor}`,
          }"
        ></i>
        <i v-else class="el-icon-a-Slice172 theme-color"></i>
      </div>
      <!-- 轮播内容标题 -->
      <div class="title-box">
        <!-- <swiper
          ref="Swiper"
          class="broadcast-swiper"
          :options="options"
          :style="{ fontSize: `${myConfig.contentConfig.fontSize}px` }"
        >
          <swiper-slide class="swiper-item" v-for="item in titleList">{{
            item.title
          }}</swiper-slide>
        </swiper> -->
        <div
          class="title-content"
          :style="{ fontSize: `${myConfig.contentConfig.fontSize}px` }"
        >
          {{ this.titleList[0].title }}
        </div>
      </div>
      <!-- 更多广播 -->
      <div
        :style="{
          marginRight: '0px' || `${myConfig.normal.padding}px`,
        }"
        class="more-broadcast"
      >
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { getBroadcast } from '../../api/page'
import 'swiper/dist/css/swiper.css'
import componentMixin from '../../mixins/component-mixin'

export default {
  name: 'st-broadcast',
  mixins: [componentMixin],
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    pagePadding: {
      type: Number,
      default: 16,
    },
  },
  data() {
    let that = this
    return {
      pageset: this.$route.query.pageset,
      loading: false,
      speedChanging: false,
      // 广播列表
      titleList: [
        {
          id: 'gz7PeWw9',
          title: '这是一条测试消息。',
          send_status: 0,
          is_top: 0,
          start_time: 1661097600,
          end_time: 1661097600,
          start_time_text: '2022年08月22日',
          show_text: '待展示',
          show_status: 0,
          send_text: '未发送',
          receive_text: '复古风格,社团云1,test,',
        },
      ],
      active: 0,
      options: {
        loop: true,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        speed: 1000,
        slidesPerView: 'auto',
        centeredSlides: true,
        // 修改swiper自己或子元素时，自动初始化swiper
        observer: false,
        // 修改swiper的父元素时，自动初始化swiper
        observeParents: false,
        // 默认为true，Swiper会强制加载所有图片后才初始化。
        // preloadImages: false,
        on: {
          slideChange: function () {
            that.active = this.realIndex
          },
        },
      },
      model: {
        normal: {
          marginTop: 0,
          padding: 0, // 两侧边距
          switchTime: 1,
          boxHeight: 34,
          boxShadow: 8,
          bgType: 'color',
          bgColor: 'transparent',
          bgImage: '',
          borderRadius: 0,
        },
        contentConfig: {
          styleType: 'template1',
          iconColorType: 'normal',
          iconColor: '#fb2d48',
          fontSize: 12,
          bgColor: '#fff',
          borderRadius: 0,
        },
      },
      timer: null,
    }
  },
  // 监听数据变化
  watch: {
    // 监听切换时间变化
    'myConfig.normal.switchTime'(val) {
      this.changeSpeed()
    },
    pagePadding(val) {
      this.changeSpeed()
    },
  },
  computed: {
    swiper() {
      return this.$refs.Swiper
    },
  },
  created() {
    console.log(this.myConfig)
    // this.getBroadcastList();
    this.changeSpeed()
    // this.options.loop = this.list.length > 2;
    console.log(this.myConfig.normal.bgImage)
  },
  methods: {
    //获取广播列表数据
    getBroadcastList(notCancel) {
      this.loading = true
      getBroadcast()
        .then((res) => {
          if (res.code) {
            this.titleList = res.data.data
            this.loading = false
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 修改轮播速度
    changeSpeed() {
      /**
       * 轮播图自动播放会导致截图生成位置错乱，
       * 所以，当编辑备份时，禁止自动播放
       */
      if (this.pageset) return
      this.loading = true
      this.speedChanging = true
      this.active = 0
      // 修改 options
      this.options.autoplay.delay = this.myConfig.normal.switchTime * 1000
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (this.swiper?.swiper) {
          // 修改播放间隔时间
          this.swiper.swiper.params.autoplay.delay = this.options.autoplay.delay
        }
        this.loading = false
        this.speedChanging = false
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
.messageBroadcast {
  padding: 10px 0;
  height: 40px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .swiper-item {
    text-align: center;
  }

  .broadcast {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      // width: 100%;
      // height: 100%;
      width: 18px;
      height: 18px;
    }
    i {
      font-size: 18px;
    }
  }

  .title-box {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 8px;
    text-align: center;
    .title-content {
      width: 100%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
    }
  }

  .more-broadcast {
    display: flex;
    justify-content: center;
    align-items: center;

    i.el-icon-arrow-right {
      font-size: 10px;
    }
  }
}
.theme-color {
  color: var(--main-color) !important;
}
</style>

<style lang="scss" scope>
@font-face {
  font-family: 'element-icons'; /* Project id 3677879 */
  src: url('https://saasres.shetuan.pro/cos/static/admin/app_design/fonts/font_3677879_3w9yhbnacnn.woff2?t=1665210013146')
      format('woff2'),
    url('https://saasres.shetuan.pro/cos/static/admin/app_design/fonts/font_3677879_3w9yhbnacnn.woff?t=1665210013146')
      format('woff'),
    url('https://saasres.shetuan.pro/cos/static/admin/app_design/fonts/font_3677879_3w9yhbnacnn.ttf?t=1665210013146')
      format('truetype');
}

.element-icons {
  font-family: 'element-icons' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-icon-a-Slice172:before {
  content: '\e602';
}

.el-icon-a-Slice17:before {
  content: '\e601';
}

.el-icon-shengyin_shiti:before {
  content: '\eca5';
}
</style>
