<template>
  <el-dialog
    title="筛选条件"
    :visible.sync="openDialog"
    width="500px"
    class="dialog-vertical"
    append-to-body
    @open="dialogOpen"
  >
    <div v-loading="loading" class="dialog-content">
      <div class="select-box">
        <div class="title">
          <h3>系统资料</h3>
        </div>
        <el-checkbox-group v-model="selectTypes.systemConfig">
          <el-checkbox value="organization" label="organization.架构职务"
            >架构职务</el-checkbox
          >
          <el-checkbox
            value="apply_type"
            :checked="true"
            disabled
            label="apply_type.会员类型"
            >会员类型</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="select-box">
        <div class="title">
          <h3>自定义资料</h3>
          <!-- 切换按钮 -->
          <el-button
            v-if="showToggle"
            type="primary"
            icon="el-icon-sort"
            @click="customKeyChange"
            >{{ customKey === "user" ? "个人会员" : "单位会员" }}</el-button
          >
        </div>
        <!-- 个人会员的自定义资料 -->
        <el-checkbox-group
          v-show="customKey === 'user'"
          v-model="selectTypes.customConfig.user"
        >
          <el-checkbox
            v-for="(item, i) in userOptions"
            :label="item.value"
            :key="item.label + i"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>

        <!-- 单位会员的自定义资料 -->
        <el-checkbox-group
          v-show="customKey === 'unit'"
          v-model="selectTypes.customConfig.unit"
        >
          <el-checkbox
            v-for="(item, i) in unitOptions"
            :label="item.value"
            :key="item.label + i"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  SelectMemberFields,
  getMemberIdentity,
} from "@/modules/app-design/api/components";

export default {
  name: "memberSortDialog",
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    selectTypes: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //加载
      // 默认显示个人会员的自定义资料
      customKey: "user",
      // 单位会员选项
      unitOptions: [],
      // 个人会员选项
      userOptions: [],
      // 是否显示切换类型按钮
      showToggle: false,
      
    };
  },
  watch: {},
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  created() {
    // 如果为空，默认配置
    // if (this.$props.selectTypes == {}) {
    //   this.$props.selectTypes = {
    //     systemConfig: [],
    //     customConfig: {
    //       user: [],
    //       unit: [],
    //     },
    //   };
    // }
  },
  methods: {
    // 自定义资料会员类型切换
    customKeyChange() {
      console.log(this.selectTypes);
      this.customKey === "user"
        ? (this.customKey = "unit")
        : (this.customKey = "user");
    },
    // 弹窗打开执行
    dialogOpen() {
      console.log(this.selectTypes);
      // 获取类型
      this.getSelectFields();
      this.getIdentity()
    },
    // 获取会员资料选择类型
    getSelectFields() {
      this.loading = true;
      SelectMemberFields()
        .then((res) => {
          console.log(res);
          // 个人会员多选框的选项
          this.userOptions = res.data.user;
          // 单位会员多选框的选项
          this.unitOptions = res.data.unit;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取会员类型
    getIdentity() {
      getMemberIdentity().then((res) => {
        // 有两个会员类型
        if (res.data.personal_user_enable && res.data.unit_user_enable) {
          // 显示切换按钮
          this.showToggle = true;
          console.log('有两个类型')
        }
        if (!res.data.personal_user_enable && res.data.unit_user_enable) {
          this.customKey = "unit";
        } else {
          this.customKey = "user";
        }
      });
    },
    // 确定
    submit() {
      console.log(this.selectTypes);
      this.openDialog = false;
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  margin-bottom: 20px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    h3 {
    }
  }
  .el-checkbox {
    min-width: 120px;
    padding: 10px 0;
  }
}
</style>
