<template>
  <div class="blur" :style="`background-image: url(${fixSpecialImageUrl(bgImage,size)});`">
    <div class="blur-bg overflow" :style="`filter: blur(${blur});`">
      <div class="blur" :style="`background-image: url(${fixSpecialImageUrl(bgImage,size, 'cpy')});`">
      </div>
    </div>
  </div>
</template>

<script>
import {randomString} from "@/base/utils/tool";

export default {
  props: {
    bgImage: {
      type: String,
      default: "",
    },
    blur: {
      type: String,
      default: "14px",
    },
    size: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      randomId: randomString(10)
    }
  },
  methods: {
    fixSpecialImageUrl(src, sizeStr, ...others) {
      let str = others ? others.join('_') : ''
      const isPageset = this.$route.query.pageset
      return this.fixImageUrl(src, {
        w: sizeStr === 'small' ? 200 : (sizeStr === 'medium' ? 300 : (sizeStr === 'large' ? 500 : (sizeStr || 200)))
      }, isPageset ? `${this.randomId}_${str}_s` : '')
    }
  },
};
</script>

<style lang="scss" scoped>
.blur {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.blur-bg.overflow {
  width: calc(100% + 56px);
  height: calc(100% + 56px);
  background: inherit;
  margin-left: -28px;
  margin-top: -28px;
  display: flex;
}
</style>
