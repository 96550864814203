<!--
 * @Author: dongjia
 * @Date: 2021-07-27 14:13:17
 * @LastEditTime: 2021-08-18 18:31:14
 * @LastEditors: aleaner
 * @Description: 宽高编辑器组件
 * @FilePath: \app-design\components\Form\StSetWidthHeight.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="width-height-input">
    <el-input type="number" v-model.number="value.width"></el-input>
    <img class="ratio-lock" :class="value.aspectRatio?'active':''"
      src="../../assets/images/st-free-container/lock-icon.png" />
    <!-- @click="value.aspectRatio = !value.aspectRatio" -->
    <el-input type="number" v-model.number="value.height"></el-input>
  </div>
</template>

<script>
export default {
  name: "StSetWidthHeight",
  model: {
    prop: "config",
    event: "configChange",
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          width: 100,
          height: 50,
          aspectRatio: false,
        };
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("configChange", val);
      },
    },
  },
  computed: {
    value: {
      get() {
        return this.config;
      },
      set(val) {
        this.$emit("configChange", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.width-height-input {
  display: flex;
  align-items: center;
  .el-input {
    width: 60px;
  }
  .ratio-lock {
    cursor: not-allowed;
    background-color: #acacac;
    width: 26px;
    height: 26px;
    margin: 0 7px;

    &.active {
      background-color: #3479ef;
    }
  }
}
</style>