<template>
  <div
    @mouseenter="maintainFreeContainerSelected"
    @mouseleave="cancleFreeContainerSelected"
    class="page-right"
    v-show="isSystemActive || pageDetail"
  >
    <div class="right-name" v-if="isListSelected && list.length">
      <p
        @click="toggleCollapse"
        class="page-title cursor-pointer"
        title="点击一键展开/收起"
        v-if="!isFreeContainerSelected"
      >
        {{ list[selected].title + '设置' }}

        <!-- 全局展开/折叠提示图标 -->
<!--        <page-right-un-fold-svg-icon v-if="!is_collapse" />-->
<!--        <page-right-fold-svg-icon v-else />-->
      </p>
      <template v-else>
        <p class="page-title">{{
          list[selected].props.components[
          componentListConfig.free_container_select
          ].title + '设置'
          }}</p>
      </template>
      <p
        class="differ-config"
        v-if="
          !isFreeContainerSelected &&
          list[selected].extra &&
          list[selected].extra.version_different
        "
      >
        （组件设置已升级,请手动将设置内容更新）
      </p>
    </div>
    <!--    <div class="right-name" v-else-if="selected === 'list'">组件列表</div>-->
    <div class="right-name" v-else-if="selected === 'page'">
      <p
        @click="toggleCollapse"
        class="page-title cursor-pointer"
        title="点击一键展开/收起"
      >
        页面设置
<!--        <page-right-un-fold-svg-icon v-if="!is_collapse" />-->
<!--        <page-right-fold-svg-icon v-else />-->
      </p>
      <p class="differ-config" v-if="VersionDiffer">
        ( 页面设置已升级,请手动将设置内容更新 )
      </p>
    </div>
    <div class="right-name" v-else>
      <p class="page-title_ flex flex-between">
        <span
          @click="toggleCollapse"
          class="page-title cursor-pointer"
          title="点击一键展开/收起"
          >{{ rightName }}设置
<!--          <page-right-un-fold-svg-icon v-if="!is_collapse" :size="18" />-->
<!--          <page-right-fold-svg-icon v-else :size="18" />-->
        </span>
        <!-- 系统页或者系统设置都有的 -->
        <template
          v-if="
            ['activity_detail'].includes(selected) ||
            (showReset &&
              formType &&
              formModel &&
              (formModel.length || Object.keys(formModel).length))
          "
        >
          <template
            v-if="
              (pageDetail && pageDetail.system_page) ||
              (!pageDetail && selected !== -1)
            "
          >
            <el-button @click="resetConfirm" size="medium" type="text"
              >恢复默认</el-button
            >
          </template>
        </template>
      </p>
      <p class="differ-config" v-if="VersionDiffer">
        (
        {{
          pageDetail && pageDetail.system_page ? '页面' : '组件'
        }}设置已升级,请手动将设置内容更新 )
      </p>
    </div>

    <!-- div or el-scrollbar -->
    <div class="workspace-right-content">
      <!-- 页面设置 -->
      <!--      -页面设置<br/>-->
      <st-form-editor
        :selected="selected"
        :type="page.component"
        ref="pageEditor"
        v-model="myPage.props"
        v-show="selected === page.selectedName"
      />
      <!-- 系统页面 -->
      <!--      <template v-for="(key) in Object.keys(system)">-->
      <!--        <st-form-editor :ref="'system_page_' + system[key].selectedName" v-show="selected === system[key].selectedName" v-model="system[key].props"-->
      <!--          :type="system[key].component" :key="key"></st-form-editor>-->
      <!--      </template>-->

      <!-- 切换个人/单位 -->
      <!-- <div class="flex-center" v-if="selected === 'eleCard'">
        <el-radio-group :value="isUnit" @change="toggleUserUnit" size="mini">
          <el-radio-button :label="0">个人会员</el-radio-button>
          <el-radio-button :label="1">会员单位</el-radio-button>
        </el-radio-group>
      </div> -->

      <!-- 这里用来动态存放系统页、自定义页 -->
      <st-form-editor
        :selected="selected"
        :type="formType"
        @form-editor-update="$emit('form-editor-update', $event)"
        ref="DesignForm"
        v-model="formModel"
        v-show="!isFreeContainerSelected"
      />

      <!-- TODO: 组件列表 -->
      <div class="component-list-editor" v-if="selected === 'list'">
        <div class="component-list-controls">
          <el-button @click="removeAllComponents" type="text"
            >清空组件</el-button
          >
        </div>
        <div class="component-list">
          <draggable
            chosen-class="chosen-component-item"
            ghost-class="ghost-component-item"
            v-model="list"
          >
            <div
              :key="item.extra.timestamp"
              @mouseout="hover = -1"
              @mouseover="hover = index"
              class="component-item"
              v-for="(item, index) in list"
            >
              <span class="component-item-name">{{ item.title }}</span>
              <img
                :src="hover === index ? activeIcon : icon"
                class="component-item-del cursor-pointer"
              />
            </div>
          </draggable>
        </div>
      </div>
      <!-- 自定义页面 -->
      <!--      <div v-for="(ed, index) in myList" :key="ed.extra.timestamp">-->

      <!-- 用v-if表单渲染会混乱 -->
      <!--          <st-form-editor v-show="index === selected && !isFreeContainerSelected" -->
      <!--                          v-model="ed.props" :type="ed.component"></st-form-editor>-->

      <!-- 此写法多次切换表单后会导致浏览器卡死 -->
      <!--      <template v-if="list && list[selected] && isListSelected">-->
      <!--        <st-form-editor v-model="list[selected].props" :type="list[selected].component"></st-form-editor>-->
      <!--      </template>-->
      <!--      </div>-->
      <!-- 自由容器内部组件 -->
      <div v-for="(ed, index) in freeContainerList" :key="ed.index">
        <!-- 用v-if表单渲染会混乱 -->
        <div
          :key="ed_item.extra.timestamp"
          v-for="(ed_item, i) in ed.props.components"
        >
          <!--          -自由容器<br/>-->
          <st-form-editor
            :type="ed_item.component"
            v-model="ed_item.props"
            v-show="
              ed.index === selected &&
              componentListConfig.free_container_select === i
            "
          />
        </div>
      </div>
    </div>
    <div class="disabled-mask flex-col flex-center">
      <p class="disabled-paragraph">
        当前自定义页面的【{{ customIndexPageName }}】已被<br />
        设为首页，请在自定义页面使用底部<br />
        导航组件进行设置
      </p>
      <img src="../../assets/images/right/disabled-right.png" height="202" />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import StFormEditor from './StFormEditor'
import { getSystemPageDefault } from '@/modules/app-design/api/page-right'
import { formatConfigData } from '../../assets/js/tool'
let library = require('../../assets/jsons/component-library.json')
let systemDict = require('../../assets/jsons/system-page.json')

import { mapState } from 'vuex'
// import PageRightUnFoldSvgIcon from './PageRightUnFoldSvgIcon'
// import PageRightFoldSvgIcon from './PageRightFoldSvgIcon'

export default {
  name: 'PageRight',
  components: {
    // PageRightFoldSvgIcon,
    // PageRightUnFoldSvgIcon,
    StFormEditor,
    draggable,
  },
  props: {
    selected: {
      type: [String, Number],
      default: -1,
    },
    // 组件列表
    list: Array,
    // 页面设置
    page: Object,
    // 系统相关（头部、底部、头部跳转、系统页等）
    system: Object,
    setting: Object,
  },
  data() {
    return {
      hover: -1,
      activeIcon: require('../../assets/images/right/del_blue.png'),
      icon: require('../../assets/images/right/del_gray.png'),
      myList: [],
      freeContainerList: [], // 自由容器内部组件
      myPage: JSON.parse(JSON.stringify(library.page)),
      formType: '',
      formModel: {},
      showReset: false,

      is_collapse: false,
    }
  },
  watch: {
    pageDetail: {
      handler(val) {
        if (this.page && val && val.id) {
          // console.log('custom page version: ', this.page.version)
          try {
            let myPage = JSON.parse(JSON.stringify(this.page))
            myPage.props = this.$mergeModel(library.page.props, myPage.props)
            this.myPage = myPage
          } catch (e) {
            console.log(e)
          }
        }
      },
      deep: true,
    },
    // "componentListConfig.free_container_select"(val) {
    //   console.log("free_container_select", val);
    // },
    /**
     * 如果是拖拽调整组件顺序，刚好是上下两个互换，则没有触发 selected 的 watch
     * @param val
     */
    selected(val) {
      // console.log(
      //   "pageConfig in selected watcher: ",
      //   val,
      //   this.page,
      //   this.list
      // );

      let c = JSON.parse(JSON.stringify(this.componentListConfig))
      c.current_select = typeof val === 'number' ? this.selected : -1
      this.$store.commit('appDesign/setStateAttr', {
        key: 'componentListConfig',
        val: c,
      })

      this.refreshForm(val)

      this.is_collapse = false
    },
    list(val) {
      this.refreshList(val)
    },
    myPage(val) {
      this.$emit('update-page', val)
    },
  },
  computed: {
    ...mapState('appDesign', [
      'pageDetail',
      'componentListConfig',
      'isUseCustomIndex',
      'customIndexPageName',
      'isUnit',
    ]),

    /* 是否正在编辑系统设置的内容 */
    isSystemActive() {
      if (this.pageDetail) return false
      return Object.keys(this.setting).includes(this.selected)
    },
    isListSelected() {
      let sel = this.selected

      return typeof sel === 'number' && sel >= 0
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    rightName() {
      let system = this.system
      let setting = this.setting
      let selected = this.selected
      return (
        (this.tenantVersion === 'school' &&
          system &&
          system[selected] &&
          system[selected].titleObj &&
          system[selected].titleObj[this.tenantVersion]) ||
        (system && system[selected] && system[selected].title) ||
        (setting &&
          setting[selected] &&
          (setting[selected].title || setting[selected].name))
      )
    },
    VersionDiffer() {
      let selected = this.selected
      let system = this.system
      let setting = this.setting
      return (
        (selected === 'page' && this.page.version !== library.page.version) ||
        (system &&
          system[selected] &&
          library.system[selected].version &&
          system[selected].version !== library.system[selected].version) ||
        (setting &&
          setting[selected] &&
          library.setting[selected].version &&
          setting[selected].version !== library.setting[selected].version)
      )
    },
    // 是否选中自由容器内部组件
    isFreeContainerSelected() {
      let selected = this.selected
      if (typeof selected === 'string') return false
      if (selected === -1) return false
      if (
        this.list[selected] &&
        this.list[selected].component !== 'st-free-container'
      )
        return false
      return this.componentListConfig.free_container_select !== -1
    },
  },
  methods: {
    toggleCollapse() {
      const target =
        this.selected === 'page'
          ? this.$refs.pageEditor
          : this.selected !== 'list' && !this.isFreeContainerSelected
          ? this.$refs.DesignForm
          : null

      if (target) {
        target.toggleExpand(!this.is_collapse)
        this.is_collapse = !this.is_collapse
      }
    },
    toggleUserUnit(e) {
      this.$store.commit('appDesign/setStateAttr', {
        key: 'isUnit',
        val: e ? 0 : 1,
      })
    },
    removeAllComponents() {
      // TODO: 清空组件
    },
    // 维持自由容器内部组件选中状态
    maintainFreeContainerSelected() {
      this.componentListConfig.maintain_select = true
      // console.log(this.componentListConfig,'this.componentListConfig')
      // console.log("in", this.componentListConfig.maintain_select);
    },
    cancleFreeContainerSelected(ev) {
      if (ev.toElement?.className === 'workspace-middle-box') {
        this.componentListConfig.maintain_select = false
      }
      // console.log("out", this.componentListConfig.maintain_select);
    },
    resetConfirm() {
      this.$confirm('确定要恢复默认设置吗？', '提示', {
        type: 'info',
      })
        .then((res) => {
          this.resetSystemPage()
        })
        .catch((err) => {})
    },
    resetSystemPage() {
      this.$store.commit('appDesign/setStateAttr', {
        key: 'loading',
        val: true,
      })
      const isSetting = this.pageDetail === null
      let system_page = this.pageDetail
        ? this.pageDetail.system_page
        : this.setting[this.selected].system_page
      getSystemPageDefault(system_page)
        .then((res) => {
          try {
            let finalConfig = formatConfigData(res.data, system_page, true)

            let key = isSetting
              ? this.selected
              : systemDict[this.pageDetail.system_page] ||
                this.pageDetail.system_page

            if (finalConfig.page) {
              this.formModel = finalConfig.page.props
              let component = finalConfig.page.component
              let configTitle = finalConfig.page.title
              if (isSetting) {
                this.setting[key].props = this.formModel
                this.setting[key].component = component
              } else {
                this.system[key].props = this.formModel
                this.system[key].component = component
                // 标题也需要重置
                this.system[key].title = configTitle
              }

              this.formType = component
            }

            if (finalConfig.list && finalConfig.list.length) {
              // console.log('finalConfig.list: ', finalConfig.list)
              this.$emit(
                'update-list',
                finalConfig.list.map((l, lI) => {
                  return {
                    ...l,
                    extra: {
                      count: 0,
                      /* 组件列表项唯一 key */
                      timestamp: new Date().getTime() + lI + 10 * Math.random(),
                    },
                  }
                })
              )
            }
          } catch (err) {
            console.log(err)
          }
          this.$message.success('已恢复默认设置')
          this.$store.commit('appDesign/setLoading', false)
        })
        .catch((err) => {
          this.$message.error('恢复默认设置失败')
          this.$store.commit('appDesign/setLoading', false)
        })
    },
    refreshForm(val) {
      val = val || this.selected
      // console.log(this.pageDetail.system_page, this.pageDetail.id)
      // console.log('--------------refresh form-------------')
      // console.log(val, 'selected')
      // console.log('---------------------------')
      // console.log(this.pageDetail, 'pageDetail')
      // console.log('---------------------------')

      if (val !== -1) {
        if (
          this.pageDetail &&
          typeof val === 'number' &&
          val < this.myList.length &&
          !this.isFreeContainerSelected
        ) {
          this.formType = this.myList[val].component
          this.formModel = this.myList[val].props
        } else if (typeof val === 'string' && val !== 'page') {
          /* 适配底部导航既是系统设置也是系统页的情况 */
          if (val === 'bottomNav') {
            /* 系统页的底部导航 */
            this.formType = this.system[val].component
            this.formModel = this.system[val].props
          } else if (this.setting[val]) {
            this.formType = this.setting[val].component
            this.formModel = this.setting[val].props
          } else if (this.system[val]) {
            this.formType = this.system[val].component
            this.formModel = this.system[val].props
            // console.log(this.system[val])
          } else {
            this.formType = ''
            this.formModel = {}
          }
        } else {
          this.formType = ''
          this.formModel = {}
        }
      }

      // console.log(this.formType, this.formModel)
      // console.log('---------------------------')

      /* 如果没有配置则不显示恢复默认，但要排除有组件列表的系统页 */
      this.$nextTick(() => {
        this.showReset = this.$refs.DesignForm.hasIsShow
      })
    },
    refreshList(val) {
      // console.log('refreshList in pageRight')
      val = val || this.list
      let freeContainerList = []
      this.myList = val
      this.myList.forEach((el, index) => {
        if (el.component === 'st-free-container') {
          el.index = index
          freeContainerList.push(el)
        }
      })
      this.freeContainerList = freeContainerList
      this.componentListConfig.list = this.myList

      this.refreshForm()
    },
  },
}
</script>

<style lang="scss" scoped>
.page-right {
  height: calc(100vh - 54px);
  /* 方式二、旧的 div */
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 120px;

  border-left: 1px solid #eee;

  /* 方式一、使用 el-scrollbar，还要配合 flex-col、flex-1 */
  /*overflow: hidden;*/
}

.right-name {
  font-weight: bold;
  line-height: 80px;
  background-color: #f7f7f7;
  /* 使用 el-scrollbar 就注释以下三个 */
  position: sticky;
  top: 0;
  z-index: 5;

  height: 80px;
  color: #3a3a3a;
  font-size: 18px;
  padding: 20px 24px 20px 20px;

  .page-title {
    font-weight: bold;
    line-height: 40px;
    user-select: none;
  }
  .differ-config {
    font-size: 12px;
    line-height: 12px !important;
    font-weight: normal !important;
    position: absolute;
    bottom: 10px;
    color: #ff5350;
  }
}

.workspace-right-content {
  /*padding: 0 16px 0 20px;*/

  // .component-list-editor {
  // }

  .component-list-controls {
    text-align: right;
  }

  /* 占位 */
  .ghost-component-item {
    height: 1px !important;
    /*width: 60%;*/
    overflow: hidden;
    color: $primary !important;
    position: relative;
    box-shadow: 0 2px 4px 0 #d5deeb;
    opacity: 0.8;
    background-color: white;
    padding-top: 10px;

    &::before {
      content: '';
      /*width: 8px;*/
      /*height: 8px;*/
      /*border-radius: 50%;*/
      height: 1px;
      width: 100%;
      border-top: 1px solid $primary;
      /*position: absolute;*/
    }
  }

  /* 拖拽时 */
  .chosen-component-item {
    background-color: white;
    /*box-shadow: 0 2px 4px 0 #D5DEEB;*/
    /*opacity: 0.8;*/
    cursor: move;
  }

  .component-list {
    .component-item {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 8px;
      color: #3a3a3a;

      &:hover {
        background-color: #e9f1fd;
        color: $primary;

        .component-item-name {
        }
      }

      .component-item-name {
        font-size: 14px;
        line-height: 14px;
      }

      .component-item-del {
        width: 20px;
        min-width: 20px;
      }
    }
  }
}

.disabled-mask {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  color: white;
  display: none;
}

.disabled-paragraph {
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 32px;
}
</style>
