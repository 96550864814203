<!--
 * @Author: dongjia
 * @Date: 2021-08-27 15:17:53
 * @LastEditTime: 2022-01-07 17:28:37
 * @LastEditors: aleaner
 * @Description: 绑定对象的下拉选项框
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Form\StObjSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="obj-select">
    <el-select v-model="value.value" placeholder="请选择" @change="handleValChange">
      <el-option v-for="(item,index) in options" :value="item.value" :label="item.label"
        :disabled="item.disabled" :key="index"></el-option>
    </el-select>
  </div>
</template>

<script>
const JUMP_LINK_MODEL = require("@/modules/app-design/assets/jsons/jump-link.json");
export default {
  name: "StObjSelector",
  model: {
    prop: "valData",
    event: "valChange",
  },
  props: {
    valData: {
      type: Object,
      default: () => {
        return {
          jump_link: JUMP_LINK_MODEL,
          label: "",
          value: "",
        };
      },
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      model: {
        jump_link: JUMP_LINK_MODEL,
        label: "",
        value: "",
      },
    };
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        let data = this.$mergeModel(
          this.model,
          this.options.find((el) => el.value === val.value)
        );
        this.$emit("valChange", data);
      },
    },
  },
  computed: {
    value: {
      get() {
        return this.valData;
      },
      set(val) {
        console.log("change", val);
        this.$emit("valChange", val);
      },
    },
  },
  methods: {
    handleValChange(val) {
      console.log("valChange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>