var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"system-page"},[_c('st-page-set',{attrs:{"title":"企微联系","jump-config":_vm.jumpConfig}}),(_vm.pluginStatus)?_c('div',{staticClass:"page-content",style:({
    backgroundColor: _vm.myConfig.normal.bgColor,
  })},[_c('img',{staticClass:"page-bg",attrs:{"src":require("../../assets/images/st-company-contact/bg-img.png")}}),_c('div',{staticClass:"main-content"},[(_vm.myConfig.titleConfig.isShow)?_c('p',{staticClass:"content-title",style:({
        fontWeight: _vm.myConfig.titleConfig.fontWeight,
        fontSize: _vm.myConfig.titleConfig.fontSize + 'px',
        color: _vm.myConfig.titleConfig.fontColor,
      })},[_vm._v(_vm._s(_vm.myConfig.titleConfig.title))]):_vm._e(),_c('div',{staticClass:"content-btns",style:({
        marginTop: _vm.myConfig.titleConfig.isShow?'20px':'0'
      })},[(_vm.myConfig.contactMeConfig.isShow && _vm.pluginStatus.contact_status)?_c('div',{staticClass:"btn-item"},[_c('img',{staticClass:"btn-icon",attrs:{"src":require("../../assets/images/st-company-contact/contact-me.png")}}),(_vm.myConfig.contactMeConfig.btnText.isShow)?_c('p',{staticClass:"btn-title",style:({
        fontWeight: _vm.myConfig.contactMeConfig.fontWeight,
        fontSize: _vm.myConfig.contactMeConfig.fontSize + 'px',
        color: _vm.myConfig.contactMeConfig.fontColor,
      })},[_vm._v(_vm._s(_vm.myConfig.contactMeConfig.btnText.name))]):_vm._e()]):_vm._e(),(_vm.myConfig.joinChatConfig.isShow && _vm.pluginStatus.group_status)?_c('div',{staticClass:"btn-item"},[_c('img',{staticClass:"btn-icon",attrs:{"src":require("../../assets/images/st-company-contact/join-group-chat.png")}}),(_vm.myConfig.joinChatConfig.btnText.isShow)?_c('p',{staticClass:"btn-title",style:({
        fontWeight: _vm.myConfig.joinChatConfig.fontWeight,
        fontSize: _vm.myConfig.joinChatConfig.fontSize + 'px',
        color: _vm.myConfig.joinChatConfig.fontColor,
      })},[_vm._v(_vm._s(_vm.myConfig.joinChatConfig.btnText.name))]):_vm._e()]):_vm._e()])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }