<template>
  <div
    class="container st-search"
    v-if="myConfig.normal.isShow"
    :style="{
      marginTop: myConfig.normal.marginTop + 'px',
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage:
        myConfig.normal.bgType === 'image'
          ? `url(${fixImageUrl(myConfig.normal.bgImage, { h: 500 }, true)})`
          : '',
      borderRadius: myConfig.normal.borderRadius + 'px',
      padding: `${myConfig.normal.colPadding}px ${myConfig.normal.rowPadding}px`
    }"
  >
    <div
      class="search"
      :style="{
        borderRadius:
          myConfig.normal.shapeType === 'circle'
            ? myConfig.normal.height + 'px'
            : `${myConfig.normal.searchRadius}px`,
        justifyContent:
          myConfig.normal.textPosition === 'center' ? 'center' : '',
        height: myConfig.normal.height + 'px',
        backgroundColor: myConfig.normal.searchColor,
      }"
    >
      <div class="search-box">
        <!-- <el-image class="search-icon" :src="require('../../assets/images/search.png')" fit="cover"> -->
        <p
          class="search-icon el-icon-search"
          :style="{
            color: myConfig.normal.placeholderColor,
          }"
        ></p>
        <!--        </el-image>-->
        <p
          v-if="myConfig.normal.placeholderConfig.isShow"
          class="search-placeholder"
          :style="{
            color: myConfig.normal.placeholderColor,
          }"
        >
          {{
            myConfig.normal.placeholder
              ? myConfig.normal.placeholder
              : myConfig.normal.placeholderConfig.name || '请输入搜索内容'
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'st-search',
  props: {
    config: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      model: {
        normal: {
          isShow: 1,
          placeholderConfig: {
            isShow: 1,
            name: '',
          },
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          marginTop: 0,
          rowPadding: 0,
          colPadding: 0,
          searchType: [''],
          placeholder: '',
          interactiveType: '',
          shapeType: '',
          textPosition: '',
          height: 0,
          searchColor: '',
          placeholderColor: '',
          borderRadius:0,
          searchRadius: 6, // 当 shapeType 为 圆角时生效
        },
      },
    }
  },
  // watch: {
  //   "myConfig.normal.searchType"(val) {
  //     console.log("myConfig.normal.searchType", val);
  //   },
  // },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  padding: 12px 19px;
}
.search {
  display: flex;
  align-items: center;
  width: 100%;
  /*margin-right: 10px;*/
  height: 32px;
  padding: 0 15px;

  .search-box {
    display: flex;
    align-items: center;
  }

  .search-icon {
    font-size: 14px;
    margin-right: 4px;
  }

  .search-placeholder {
    font-size: 14px;
    line-height: 14px;
  }
}
</style>
