<!--
 * @Author: dongjia
 * @Date: 2021-07-28 14:54:37
 * @LastEditTime: 2021-08-06 18:04:13
 * @LastEditors: aleaner
 * @Description: 更换模板页面
 * @FilePath: \app-design\components\Page\TemplatePage.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="template-container" v-loading="loading">
    <!-- tab栏 -->
    <div class="page-tabs" v-if="tabsArr.length">
      <div :class="['tabs-item','cursor-pointer',filterForm.tag === item?'active':'']"
        v-for="(item,index) in tabsArr" :key="index" @click="tabChange(item)">{{item}}</div>
    </div>
    <!-- 模板列表 -->
    <el-scrollbar style="height: calc(100vh - 54px - 13px - 20px - 40px - 32px);">
      <div class="tamplate-list">
        <div class="list-item" v-for="(item,index) in templateList" :key="index">
          <el-image class="item-cover" :src="fixImageUrl(item.cover || (item.pages && item.pages[0] && item.pages[0].cover), {h: 600})" fit="cover" />
          <!-- 使用中状态 -->
          <img v-if="item.is_using" class="item-corner" src="../../assets/images/template-page/using.png"/>
          <!-- 卡片底部 -->
          <div class="bottom-desc">
            <p class="bottom-left">{{item.name}}</p>
            <p class="bottom-right">{{item.price?'':'免费'}}</p>
          </div>
          <!-- 鼠标停留遮罩 -->
          <div class="hover-mask">
            <template v-if="!item.is_using && platform !== 'h5'">
              <div class="code-img">
                <el-image class="wx-qrcode" :src="item.mpcode" fit="cover" />
              </div>
              <p class="code-tip">扫码预览</p>
            </template>
            <div v-else-if="item.is_using" style="width: 100%; height: 150px;" />
            <div v-else style="width: 100%; height: 180px;" />
            <el-button :style="{
            'margin-top': !item.is_using && platform !== 'h5' ? '22px' : ''
          }" size="medium" type="primary" @click="handleShowPreview(item)">马上使用</el-button>
            <!-- 即整套使用，还原成初始配置 -->
            <el-tooltip class="item" effect="dark" content="初始化为原模板" placement="right">
              <el-button v-if="item.is_using" style="margin-top: 22px" size="medium" type="primary" @click="onClickInit(item)">初始化</el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="none-list" v-if="!loading && templateList.length == 0">
          <!--        <div class="none-list-box">-->
          <!--          <img class="none-img" src="../../assets/images/template-page/none-list.png" />-->
          <!--          <p class="none-text">暂无模版</p>-->
          <!--        </div>-->
          <el-empty description="暂无模板" />
        </div>
      </div>
    </el-scrollbar>
    <Pagination :total="pageData.total" :page-size="filterForm.page_size" :page="pageData.current_page" :change="changPage" />
    <TemplatePreviewDialog v-model="showTemplatePreview" :previewData="previewData"
                           @init="rollbackDialog.show = true" @switch-page="switchPage" />
    <el-dialog title="提示" class="dialog-vertical" :visible="rollbackDialog.show"
               width="450px" append-to-body close-on-click-modal @close="rollbackDialog.show = false">
      <p>替换模板后，将不保留原<span style="color: red;">设计样式和所有数据</span>。确定更换该模板吗？</p>
      <template #footer>
        <el-button size="small" @click="rollbackDialog.show = false">取消</el-button>
        <el-button size="small" type="primary"
                   :loading="rollbackDialog.backupLoading"
                   @click="handleInit(previewData)">备份并使用</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import {TemplateTags, TemplateList, applyTemplate} from '../../api/page'
import TemplatePreviewDialog from "./TemplatePreviewDialog";
import Pagination from "@/base/components/Default/Pagination";
import { mapState } from "vuex";
export default {
  name: "TemplatePage",
  components: { TemplatePreviewDialog, Pagination },
  inject: ['postData'],
  data() {
    return {
      loading: true,
      tabsArr: [],
      pageData: {},
      filterForm: {
        page_size: 15,
        keyword: "",
        tag: "",
      },
      templateList: [],
      previewData: null,
      showTemplatePreview: false,
      rollbackDialog: {
        show: false,
        backupLoading: false,
      },
      platform: this.$route.params.platform,
    };
  },
  watch: {
    templateSearch(val) {
      this.filterForm.keyword = val;
      this.getTemplateList({ ...this.filterForm });
    },
  },
  computed: {
    ...mapState("appDesign", ["templateSearch"]),
  },
  created() {
    this.getTemplateTags();
  },
  methods: {
    onClickInit(item) {
      this.previewData = item
      this.rollbackDialog.show = true
    },
    /* 整套使用 */
    handleInit(item) {
      this.rollbackDialog.backupLoading = true
      applyTemplate({
        ...this.postData,
        template_id: item.id,
        is_backup: 1 // 0：直接使用，1：自动备份
      }).then(res => {
        this.$message.success(res.msg)

        this.rollbackDialog.show = false
        this.rollbackDialog.backupLoading = false
        this.showTemplatePreview = false
        this.$store.commit("appDesign/setShowTemplate", false);

        // 不刷新页面修改地址栏参数
        this.$emit('update-location-search', {})

        // this.$store.commit("appDesign/setIsRefreshPages", true);
        this.$bus.emit("allowGetPageDetail");
      }).catch(err => {
        console.log(err)
        this.rollbackDialog.backupLoading = false
        this.rollbackDialog.show = false
      })
    },
    switchPage(a, b) {
      this.$emit('switch-page', a, b)
    },
    getTemplateTags() {
      this.loading = true;
      TemplateTags()
        .then((res) => {
          this.tabsArr = res.data;
          this.filterForm.tag = res.data[0];
          this.getTemplateList(this.filterForm);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getTemplateList(requestData) {
      this.loading = true;
      TemplateList({
        ...requestData,
        ...this.postData,
        platform: this.$route.params.platform
      })
        .then((res) => {
          const { data } = res;
          this.templateList = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 切换tab栏
    tabChange(item) {
      this.filterForm.tag = item;
      this.getTemplateList(this.filterForm);
    },
    // 点击预览
    handleShowPreview(item) {
      this.previewData = item;
      this.showTemplatePreview = true;
    },
    //分页
    changPage(data) {
      this.filterForm.page_size = data.page_size;
      this.getTemplateList({ ...this.filterForm, page: data.page });
    },
  },
};
</script>

<style lang="scss" scoped>
.template-container {
  min-height: 400px;
  width: 100%;
  padding: 13px 15px 20px 56px;

  .page-tabs {
    display: flex;
    align-items: center;

    .tabs-item + .tabs-item {
      margin-left: 24px;
    }

    .tabs-item {
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #676767;
      line-height: 18px;

      &.active {
        font-size: 13px;
        font-weight: bold;
        color: #181818;
        line-height: 18px;
        border-bottom: 3px solid #3576ff;
      }
    }
  }

  .tamplate-list {
    width: 100%;
    min-height: calc(100% - 150px);
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .none-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20vh;

      .none-list-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .none-img {
          width: 210px;
          height: 100px;
        }

        .none-text {
          margin-top: 24px;
          font-size: 14px;
          color: #979da6;
          line-height: 14px;
        }
      }
    }

    .list-item {
      margin-right: 54px;
      margin-bottom: 54px;
      width: 220px;
      height: 391px;
      box-shadow: 0 6px 10px 0 rgba(87, 87, 87, 0.19);
      border-radius: 14px;
      position: relative;

      .item-cover {
        width: 100%;
        height: 100%;
        border-radius: 14px;
      }

      .item-corner {
        position: absolute;
        top: -2px;
        left: -2px;
        width: 65px;
        height: 65px;
      }

      .bottom-desc {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background: rgba(0, 0, 0, 0.64);
        box-shadow: 0 6px 10px 0 rgba(87, 87, 87, 0.19);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 13px;
        border-radius: 0 0 14px 14px;

        .bottom-left {
          font-size: 13px;
          color: #ffffff;
          line-height: 18px;
        }

        .bottom-right {
          font-size: 13px;
          color: #ff5534;
          line-height: 18px;
        }
      }

      &:hover {
        .item-corner {
          display: none;
        }

        .hover-mask {
          opacity: 1;
          transition: all 0.5s ease;
        }
      }

      .hover-mask {
        position: absolute;
        z-index: 1;
        opacity: 0;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 14px;

        .el-button {
          min-width: 114px;
          letter-spacing: 1px;
        }

        .code-img {
          width: 118px;
          height: 118px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 5px;
          margin-top: 71px;
          overflow: hidden;
          background-color: #fff;

          .wx-qrcode {
            width: 100%;
            height: 100%;
          }
        }

        .code-tip {
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
          margin-top: 10px;
        }

        .el-button + .el-button {
          margin-top: 12px;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
