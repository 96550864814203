var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comment-area",style:({
    backgroundColor: _vm.myConfig.normal.bgColor,
    marginTop: _vm.myConfig.normal.marginTop + 'px',
  })},[_c('div',{staticClass:"comment-top"},[_c('p',{staticClass:"top-left"},[_vm._v("热门评论 (999)")]),_c('p',{staticClass:"top-right"},[_c('span',{style:({
      marginRight: '5px',
      color: _vm.myConfig.normal.sortType === 'popular'?'#000000':'#aaaaaa'
    })},[_vm._v("按热度")]),_vm._v("|"),_c('span',{style:({
      marginLeft: '5px',
      color: _vm.myConfig.normal.sortType === 'time'?'#000000':'#aaaaaa'
    })},[_vm._v("按时间")])])]),_c('div',{staticClass:"comment-content"},[_vm._v("暂无评论内容")])])}
var staticRenderFns = []

export { render, staticRenderFns }