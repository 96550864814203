<template>
  <div class="photo-wall single-media-wall">
    <div class="wall-content" :style="showInline?'display: flex;':''">
      <div class="img-content" :class="disabled?'disabled-hover':'no-disabled-hover'" :style="{
          width: widthSize,
          height: heightSize,
          'border-radius': BorderRadius,
          border: 'none'
        }" @click="!disabled || JSON.stringify(MediaData) === '{}' ? (show = true) : ''">
        <div class="photo" v-if="UploadType === 'image' && (MediaData || DefaultImage)">
          <el-image class="photo" :style="{
            width: widthSize,
            height: heightSize,
            'border-radius': BorderRadius,
          }" :src="MediaData ? fixImageUrl(MediaData) : DefaultImage" :fit="fit"></el-image>

          <div v-if="!disabled && showMask" class="delete"
            @click.stop.prevent="MediaData === '' && DefaultImage?(show = true):deleteMediaData()">
            {{DefaultImage && '' === MediaData?'点击更换':'删除'}}
          </div>
        </div>
      </div>
      <slot name="info"></slot>
    </div>
    <!-- 图片弹窗 -->
    <media-selector v-model="show" :type="UploadType" @select="handleMedia"></media-selector>
  </div>
</template>
<script>
import MediaSelector from "@/modules/common/components/MediaSelector";
export default {
  name: "defaultImageSelector",
  model: {
    prop: "MediaData",
    event: "handleMediaData",
  },
  props: {
    // 大小 px、%
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    borderRadius: {
      type: [Number, String],
      default: 0,
    },
    UploadType: {
      type: String,
      default: "image",
    },
    // 图片src或视频object
    MediaData: {
      type: [String, Object],
      required: true,
    },
    // 图片如何适应容器框
    fit: {
      type: String,
      default: "cover",
    },
    //添加按钮文字提示
    // addText: {
    //   type: String,
    //   default: "上传图片",
    // },
    //是否显示蒙层
    showMask: {
      type: Boolean,
      default: true,
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 提示与图片是否换行显示
    showInline: {
      type: Boolean,
      default: false,
    },
    //默认图片
    DefaultImage: {
      type: String,
      default: "",
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    //内容发生变化的回调事件
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      VideoData: {},
      show: false, // 显示图片弹窗
    };
  },
  computed: {
    widthSize() {
      if (typeof this.width === "number") {
        return this.width + "px";
      } else {
        return this.width;
      }
    },
    heightSize() {
      if (typeof this.height === "number") {
        return this.height + "px";
      } else {
        return this.height;
      }
    },
    BorderRadius() {
      if (typeof this.borderRadius === "number") {
        return this.borderRadius + "px";
      } else {
        return this.borderRadius;
      }
    },
    srcList() {
      return [this.MediaData];
    },
    isLive() {
      if (this.UploadType === "video") {
        if (typeof this.MediaData === "string") {
          if (this.MediaData.indexOf(".m3u8") !== -1) return true;
        } else if (this.MediaData.url) {
          let hasLiveSuffix = this.MediaData.url.indexOf(".m3u8") !== -1;
          if (hasLiveSuffix) return true;
        }
      }
      return false;
    },
  },
  methods: {
    // 确认
    handleMedia(mediaList) {
      if (this.UploadType === "image") {
        const url = mediaList[0].url;
        this.$emit("handleMediaData", url);
        this.onChange(url);
      } else {
        this.$emit("handleMediaData", {
          ...mediaList[0],
          ...mediaList[0].config,
        });
        this.onChange({
          ...mediaList[0],
          ...mediaList[0].config,
        });
      }
    },
    // 删除
    deleteMediaData() {
      if (this.UploadType === "image") {
        this.$emit("handleMediaData", "");
        this.onChange("");
      }
    },
  },
  components: {
    MediaSelector,
  },
};
</script>
<style lang="scss" scoped>
.photo-wall {
  line-height: 1;

  &.is-error {
    .img-content {
      border: 1px dashed $danger !important;
    }
  }

  > .wall-content {
    // width: 100%;
    display: inline-block;

    .img-content {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      background-size: cover;

      .photo {
        vertical-align: middle;
        background-color: rgb(241, 241, 241);
      }

      .add,
      .bg-mask,
      .video-mask,
      .delete {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }

      //添加
      .add {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .add-content {
          font-size: 14px;
          line-height: 1;
          color: rgb(117, 117, 117);
          text-align: center;

          i {
            font-size: 25px;
          }

          .info {
            margin-top: 5px;
            color: inherit;
          }
        }
      }

      // 背景蒙层
      .bg-mask {
        height: 100%;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: all 0.3s ease;

        p + p {
          margin-left: 15px;
        }
      }

      .video-mask {
        height: 100%;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.3);

        p {
          font-size: 30px;
        }
      }

      //删除
      .delete {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        @include nowrap();
        background-color: rgba(0, 0, 0, 0.6);
        transition: transform 0.2s ease-out;
        transform: translateY(30px);
      }
    }

    .disabled-hover {
      &:hover {
        cursor: not-allowed;
      }
    }

    .no-disabled-hover {
      &:hover {
        border-color: #3576ff !important;

        .add {
          .add-content {
            color: #3576ff;
          }
        }

        .bg-mask {
          opacity: 1;
        }

        .delete {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
