<template>
  <div class="view" :style="{
    backgroundColor: myConfig.normal.bgType === 'color'?myConfig.normal.bgColor:'',
    margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0px`,
    backgroundImage: myConfig.normal.bgType === 'image'?`url(${fixImageUrl(myConfig.normal.bgImage,{h:500}, true)})`:''
  }">
  <!--     marginTop: myConfig.normal.marginTop + 'px', -->
    <div v-if="myConfig.normal.isShowClose" class="closeBtn el-icon-close"></div>
    <div class="view-content">
      <div class="content-top">小程序关联的公众号</div>
      <div class="content-bottom">
        <el-image class="content-icon"
          :src="require('../../assets/images/st-official-account/default-icon.png')"></el-image>
        <div class="bottom-center">
          <p class="bottom-center-top">公众号名称</p>
          <p class="bottom-center-bottom">公众号简介文案名称最是到这里…</p>
        </div>
        <div class="bottom-right">
          <el-button class="btn" type="success" plain size="mini">关注</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StOfficialAccount",
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          rowMargin: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          isShowClose: 0,
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.view {
  background-size: cover;
  background-position: center;
}
.closeBtn {
  width: 100%;
  text-align: end;
  line-height: 14px;
  color: #c8c9cc;
}
.view {
  padding: 12px 16px;
  .view-content {
    padding: 8px 12px 12px;
    width: 100%;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #e5e5e5;

    .content-top {
      font-size: 12px;
      color: #999999;
      line-height: 12px;
    }
    .content-bottom {
      margin-top: 12px;
      display: flex;
      align-items: center;
      .content-icon {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
      }
      .bottom-center {
        margin-left: 8px;
        .bottom-center-top {
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          line-height: 16px;
        }
        .bottom-center-bottom {
          margin-top: 8px;
          font-size: 12px;
          color: #999999;
          line-height: 12px;
          height: 12px;
          overflow: hidden;
        }
      }
      .bottom-right {
        flex-shrink: 0;
        margin-left: 23px;
        padding-top: 6px;
        .btn {
          background-color: #fff;
          color: #13ce66;
          border-color: #13ce66;
        }
      }
    }
  }
}
</style>
