<template>
  <div :style="[myRootStyle]" class="container" style="border-radius: 6px">
    <template v-if="myStyleType === 'template1'">
      <div :style="{paddingBottom: swiperData.length > 1 ? '12px' : ''}" v-if="myConfig.templateData.showType === 'swiper'">
        <swiper class="show-swiper" ref="mySwiper" :options="options">
          <swiper-slide
            class="row-item swiper-no-swiping"
            :style="{
              paddingBottom: swiperData.length > 1 ? '8px' : '0',
            }"
            v-for="(item, index) in swiperData.length
              ? swiperData
              : defaultList"
            :key="index"
          >
            <div
              v-for="(nav, i) in item"
              class="nav-item"
              :key="i"
              :style="{
                flex: `0 0 ${100 / myConfig.templateData.numLimit}%`,
                marginTop: i >= myConfig.templateData.numLimit ? '12px' : '',
              }"
            >
              <el-image
                :style="{
                  borderRadius: borderRadiusString,
                }"
                style="overflow: hidden"
                :src="fixSpecialImageUrl(nav.icon, '', index, i)"
                fit="cover"
              />
              <p
                class="nav-name"
                :style="{
                  color: myConfig.templateData.textColor,
                  marginTop: myConfig.templateData.textSpacing + 'px',
                }"
              >
                {{ getCategoryName(nav) || nav.name }}
              </p>
            </div>
          </swiper-slide>
          <div v-show="swiperData.length > 1" class="swiper-pagination"></div>
        </swiper>
      </div>
      <div v-else class="show-fixed">
        <div
          v-for="(nav, i) in myConfig.tabs.list.length
            ? myConfig.tabs.list
            : defaultList"
          class="nav-item"
          :style="{
            flex: `0 0 ${100 / myConfig.templateData.numLimit}%`,
            marginTop: i >= myConfig.templateData.numLimit ? '12px' : '',
          }"
          :key="i"
        >
          <el-image
            :src="fixSpecialImageUrl(nav.icon, '', i)"
            :style="{
              borderRadius: borderRadiusString,
            }"
            fit="cover"
            style="overflow: hidden"
          />
          <p
            class="nav-name"
            :style="{
              color: myConfig.templateData.textColor,
              marginTop: myConfig.templateData.textSpacing + 'px',
            }"
          >
            {{ getCategoryName(nav) || nav.name }}
          </p>
        </div>
      </div>
    </template>
    <!-- 模板二 -->
    <template v-else-if="['template2'].includes(myStyleType)">
      <div class="x-scroll-list">
        <div
          v-for="(nav, i) in myConfig.tabs.list.length
            ? myConfig.tabs.list
            : defaultList"
          class="nav-item"
          :class="myStyleType"
          :key="i"
          :style="{
            marginRight: `${myConfig.templateData.dataSpacing}px`,
          }"
        >
          <template>
            <el-image
              :src="fixSpecialImageUrl(nav.icon, '', i)"
              :style="{
                borderRadius: borderRadiusString,
              }"
              fit="cover"
              style="overflow: hidden"
            />
            <p
              class="nav-name"
              :style="{
                color: myConfig.templateData.textColor,
                marginTop: myConfig.templateData.textSpacing + 'px',
              }"
            >
              {{ getCategoryName(nav) ? getCategoryName(nav) : nav.name }}
            </p>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import componentMixin from '../../mixins/component-mixin'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'
import { getAllShopCategory } from '@/modules/app-design/api/components'

export default {
  name: 'st-shop-category-nav',
  mixins: [componentMixin],
  components: {
    StLoadImage,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      defaultIcon: require('../../assets/images/default-icon.png'),
      defaultList: [
        require('../../assets/jsons/default-list.json').imageTextNav,
      ],
      ch_sort: require('../../assets/jsons/ch-sort.json'),
      swiperData: [],
      options: {
        loop: false,
        autoplay: false,
        speed: 500,
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          slideChange: () => {},
        },
      },
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          rowMargin: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
        },
        templateData: {
          styleType: 'template1',
          showType: '',
          rowLimit: 0,
          numLimit: 0,
          textColor: '',
          textSpacing: 6,
          // "borderRadiusConfig": {
          //   "borderRadiusTopLeft": 0,
          //   "borderRadiusTopRight": 0,
          //   "borderRadiusBottomLeft": 0,
          //   "borderRadiusBottomRight": 0
          // },
          borderRadius: 4,
          dataSpacing: 15,
        },
        tabs: {
          list: [
            {
              icon: '',
              name: '',
              is_show: 1,
              jump_link: require('@/modules/app-design/assets/jsons/jump-link.json'),
              categoryId: '',
            },
          ],
        },
      },
      // 分类选项
      categoryOptions: [],
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    tabList() {
      return this.myConfig.tabs.list.filter((el) => el.is_show === 1)
    },
    borderRadiusString() {
      // let conf = this.myConfig.templateData.borderRadiusConfig || {};
      // return `${conf.borderRadiusTopLeft || 0}px ${conf.borderRadiusTopRight || 0}px ${conf.borderRadiusBottomRight || 0}px ${conf.borderRadiusBottomLeft || 0}px`;
      return `${this.myConfig.templateData.borderRadius}px`
    },
  },
  watch: {
    myConfig: {
      deep: true,
      handler(val) {
        this.swiperChange()
      },
    },
    'myConfig.normal.rowLimit'(val) {
      this.swiperChange()
    },
    'myConfig.normal.numLimit'(val) {
      this.swiperChange()
    },
    myStyleType(val) {
      if (val === 'template1') {
        this.swiperChange()
      }
    },
  },
  created() {
    if (this.myStyleType === 'template1') {
      this.swiperChange()
    }
    this.getShopCategory()
  },
  methods: {
    swiperChange() {
      this.swiperData = []

      let swiperNum = Math.ceil(
        this.tabList.length /
          (this.myConfig.templateData.numLimit *
            this.myConfig.templateData.rowLimit)
      )
      for (let i = 0; i < swiperNum; i++) {
        const list = this.tabList.slice(
          i *
            this.myConfig.templateData.numLimit *
            this.myConfig.templateData.rowLimit,
          this.myConfig.templateData.numLimit *
            this.myConfig.templateData.rowLimit *
            (i + 1)
        )
        this.swiperData.push(list)
        console.log(this.swiperData)
      }
      // console.log('图文导航数据', this.swiperData);
    },
    // 根据分类id 获取分类名称
    getCategoryName(nav) {
      let filterArray = this.categoryOptions.filter((item) => {
        return item.value === nav.categoryId
      })
      if (filterArray.length) {
        return filterArray[0].label
      } else {
        return false
      }
    },
    // 获取商品分类配置项
    getShopCategory() {
      getAllShopCategory().then((res) => {
        console.log(res)
        this.categoryOptions = res.data.filter((item) => item.label !== '全部')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  background-size: cover;
  background-position: center;
}
.show-swiper {
  padding: 12px 0;
}
.show-fixed {
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
}
.row-item {
  display: flex;
  flex-wrap: wrap;
}

.swiper-pagination {
  bottom: -14px;
  width: 100%;
  ::v-deep.swiper-pagination-bullet {
    margin-left: 4px;
    height: 4px;
    width: 12px;
    border-radius: 4px;
    transition: all 0.5s ease;
    background-color: var(--main-color);
    opacity: 0.19;

    &.swiper-pagination-bullet-active {
      height: 4px;
      width: 4px;
      opacity: 1;
    }
  }
}

.nav-item {
  text-align: center;

  .el-image {
    width: 50px;
    height: 50px;
    // background-color: #fff;
  }

  .nav-name {
    font-size: 12px;
    line-height: 12px;
  }
}

.x-scroll-list {
  display: flex;
  overflow-x: hidden;
  padding: 12px 0;

  .nav-item {
    flex-shrink: 0;
    overflow: hidden;

    &.template2 {
      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        // background-color: #fff;
      }

      .nav-name {
        font-size: 12px;
        line-height: 12px;
      }
    }

    &:first-child {
      margin-left: 16px;
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
