var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",staticStyle:{"border-radius":"6px"},style:([_vm.myRootStyle])},[(_vm.myStyleType === 'template1')?[(_vm.myConfig.templateData.showType === 'swiper')?_c('div',{style:({paddingBottom: _vm.swiperData.length > 1 ? '12px' : ''})},[_c('swiper',{ref:"mySwiper",staticClass:"show-swiper",attrs:{"options":_vm.options}},[_vm._l((_vm.swiperData.length
            ? _vm.swiperData
            : _vm.defaultList),function(item,index){return _c('swiper-slide',{key:index,staticClass:"row-item swiper-no-swiping",style:({
            paddingBottom: _vm.swiperData.length > 1 ? '8px' : '0',
          })},_vm._l((item),function(nav,i){return _c('div',{key:i,staticClass:"nav-item",style:({
              flex: ("0 0 " + (100 / _vm.myConfig.templateData.numLimit) + "%"),
              marginTop: i >= _vm.myConfig.templateData.numLimit ? '12px' : '',
            })},[_c('el-image',{staticStyle:{"overflow":"hidden"},style:({
                borderRadius: _vm.borderRadiusString,
              }),attrs:{"src":_vm.fixSpecialImageUrl(nav.icon, '', index, i),"fit":"cover"}}),_c('p',{staticClass:"nav-name",style:({
                color: _vm.myConfig.templateData.textColor,
                marginTop: _vm.myConfig.templateData.textSpacing + 'px',
              })},[_vm._v(" "+_vm._s(_vm.getCategoryName(nav) || nav.name)+" ")])],1)}),0)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.swiperData.length > 1),expression:"swiperData.length > 1"}],staticClass:"swiper-pagination"})],2)],1):_c('div',{staticClass:"show-fixed"},_vm._l((_vm.myConfig.tabs.list.length
          ? _vm.myConfig.tabs.list
          : _vm.defaultList),function(nav,i){return _c('div',{key:i,staticClass:"nav-item",style:({
          flex: ("0 0 " + (100 / _vm.myConfig.templateData.numLimit) + "%"),
          marginTop: i >= _vm.myConfig.templateData.numLimit ? '12px' : '',
        })},[_c('el-image',{staticStyle:{"overflow":"hidden"},style:({
            borderRadius: _vm.borderRadiusString,
          }),attrs:{"src":_vm.fixSpecialImageUrl(nav.icon, '', i),"fit":"cover"}}),_c('p',{staticClass:"nav-name",style:({
            color: _vm.myConfig.templateData.textColor,
            marginTop: _vm.myConfig.templateData.textSpacing + 'px',
          })},[_vm._v(" "+_vm._s(_vm.getCategoryName(nav) || nav.name)+" ")])],1)}),0)]:(['template2'].includes(_vm.myStyleType))?[_c('div',{staticClass:"x-scroll-list"},_vm._l((_vm.myConfig.tabs.list.length
          ? _vm.myConfig.tabs.list
          : _vm.defaultList),function(nav,i){return _c('div',{key:i,staticClass:"nav-item",class:_vm.myStyleType,style:({
          marginRight: ((_vm.myConfig.templateData.dataSpacing) + "px"),
        })},[[_c('el-image',{staticStyle:{"overflow":"hidden"},style:({
              borderRadius: _vm.borderRadiusString,
            }),attrs:{"src":_vm.fixSpecialImageUrl(nav.icon, '', i),"fit":"cover"}}),_c('p',{staticClass:"nav-name",style:({
              color: _vm.myConfig.templateData.textColor,
              marginTop: _vm.myConfig.templateData.textSpacing + 'px',
            })},[_vm._v(" "+_vm._s(_vm.getCategoryName(nav) ? _vm.getCategoryName(nav) : nav.name)+" ")])]],2)}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }