<template>
  <div class="drag-list st-drag-nav-list">
    <!-- 默认功能组件 -->
    <div
      v-if="formType === 'st-default-user-center-nav'"
      class="drag-list-header"
    >
      <p class="list-title">修改内容</p>
      <p class="form-tip">拖动选中的导航可以对其排序</p>
    </div>
    <div v-else class="drag-list-header">
      <p class="list-title">添加内容</p>
      <p class="form-tip">
        最多添加 {{ limit }} 个导航，拖动选中的导航可以对其排序
      </p>
    </div>
    <draggable
      v-if="navList"
      v-model="navList"
      class="nav-list"
      :disabled="unDraggable"
      animation="500"
      @sort="sortChange"
      draggable=".nav-item"
    >
      <template v-if="navList.length > 0">
        <div class="nav-item" v-for="(nav, index) in navList" :key="index">
          <div class="item-title">
            <p>
              <span
                :class="[
                  'item-collapse',
                  'cursor-pointer',
                  itemFoldable ? 'el-icon-caret-top' : '',
                ]"
                :style="
                  hasCollapseArr.includes(index)
                    ? ''
                    : 'transform:rotate(180deg);'
                "
                @click="handleCollapse(index)"
              />{{ formType === 'st-shop-categories' ? '分类' : '导航'
              }}{{ index + 1 }}
            </p>
            <div style="display: flex">
              <el-button
                class="set-index"
                type="primary"
                v-if="nav.is_default !== undefined && nav.is_default === 0"
                @click="setIndex(index)"
                >设为初始页
              </el-button>
              <div
                class="seted-index"
                v-if="nav.is_default !== undefined && nav.is_default === 1"
              >
                <img
                  class="seted-icon"
                  src="../../assets/images/st-drag-nav-list/blue-star.png"
                />
                <p class="seted-text">已设为初始页</p>
              </div>
              <!-- 默认功能组件 -->
              <div v-if="formType === 'st-default-user-center-nav' || !allowDelete"></div>
              <el-button
                v-else
                type="text"
                style="padding: 0"
                @click="deleteItem(index)"
                >删除</el-button
              >
            </div>
          </div>
          <div
            class="item-content"
            :style="{
              height:
                itemFoldable && hasCollapseArr.includes(index) ? '0px' : '',
              marginTop:
                itemFoldable && hasCollapseArr.includes(index) ? '0px' : '22px',
              borderTop:
                itemFoldable && hasCollapseArr.includes(index)
                  ? 'none'
                  : '1px #eeeeee solid',
            }"
          >
            <div class="nav-content" v-if="nav.icon_normal !== undefined">
              <p class="nav-title">默认图标</p>
              <IconSelect
                :icon.sync="navList[index].icon_normal"
                :iconList="iconList"
                :suggestSize="suggestSize"
              />
            </div>
            <div class="nav-content" v-if="nav.icon_checked !== undefined">
              <p class="nav-title">选中图标</p>
              <IconSelect
                :icon.sync="navList[index].icon_checked"
                :iconList="iconList"
                :suggestSize="suggestSize"
              />
            </div>
            <div class="nav-content" v-if="nav.icon !== undefined">
              <p class="nav-title">
                {{ formType === 'st-shop-categories' ? '分类' : '导航' }}图标
              </p>
              <IconSelect
                :icon.sync="navList[index].icon"
                :iconList="iconList"
                :suggestSize="suggestSize"
              />
            </div>
            <div class="nav-content" v-if="nav.leftIcon !== undefined">
              <p class="nav-title">左侧图标</p>
              <IconSelect
                :icon.sync="navList[index].leftIcon"
                :iconList="iconList"
                :suggestSize="suggestSize"
              />
            </div>
            <div class="nav-content" v-if="nav.rightIcon !== undefined">
              <p class="nav-title">右侧图标</p>
              <IconSelect
                :icon.sync="navList[index].rightIcon"
                :iconList="iconList"
                :suggestSize="suggestSize"
              />
            </div>
            <div
              class="nav-content"
              v-if="nav.name !== undefined && formType !== 'st-shop-categories'"
              @mousemove="unDraggable = true"
              @mouseout="unDraggable = false"
            >
              <p class="nav-title">导航名称</p>
              <el-input
                style="width: 100%"
                v-model="navList[index].name"
                placeholder="请输入导航名称"
                @focus="unDraggable = true"
                @blur="unDraggable = false"
                @input="test($event, index)"
              >
              </el-input>
            </div>
            <!-- 商城分类选择 -->
            <div
              class="nav-content"
              v-if="formType === 'st-shop-categories' && categoryOptions.length"
              @mousemove="unDraggable = true"
              @mouseout="unDraggable = false"
            >
              <p class="nav-title">选择分类</p>
              <!-- <el-input
                style="width: 100%"
                v-model=""
                placeholder="请输入导航名称"
                @focus="unDraggable = true"
                @blur="unDraggable = false"
                @input="test($event, index)"
              >
              </el-input> -->
              <el-select
                v-model="navList[index].categoryId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in categoryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div class="nav-content" v-if="nav.jump_type !== undefined" @mousemove="unDraggable = true"
              @mouseout="unDraggable = false">
              <p class="nav-title">打开方式</p>
              <el-radio-group style="margin-left: auto" v-model="navList[index].jump_type" size="small">
                <el-radio-button label="view">默认</el-radio-button>
                <el-radio-button label="path">新页面打开</el-radio-button>
              </el-radio-group>
            </div> -->

            <div
              class="nav-content"
              v-if="nav.jump_link !== undefined && formType === 'st-bottom-nav'"
            >
              <p class="nav-title">链接地址</p>
              <div class="w-full flex-col">
                <el-dropdown
                  @command="
                    (val) => {
                      handleCommand(val, index)
                    }
                  "
                  style="width: 100%"
                  v-if="
                    !nav.jump_link ||
                    !nav.jump_link.jump_type ||
                    !nav.jump_link.jump_config
                  "
                >
                  <el-button class="nav-btn el-dropdown-link">
                    设置链接地址
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item disabled>打开方式</el-dropdown-item>
                    <el-dropdown-item command="view" divided
                      >内置页打开</el-dropdown-item
                    >
                    <el-dropdown-item command="path"
                      >新页面打开</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="link-selected" v-else>
                  <!-- <el-input v-if="nav.jump_link.jump_config" class="link-name" disabled
                    v-model="navList[index].jump_link.jump_config.name"></el-input> -->
                  <p class="link-selected-name">
                    {{
                      navList[index].jump_link.jump_config.name +
                      (navList[index].jump_link.jump_type === 'view'
                        ? '(内置页打开)'
                        : '(新页面打开)')
                    }}
                  </p>
                  <el-dropdown
                    @command="
                      (val) => {
                        handleCommand(val, index)
                      }
                    "
                  >
                    <el-button class="el-dropdown-link" type="text"
                      >修改</el-button
                    >
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item disabled>打开方式</el-dropdown-item>
                      <el-dropdown-item command="view" divided
                        >内置页打开</el-dropdown-item
                      >
                      <el-dropdown-item command="path"
                        >新页面打开</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-button @click="handleDel(index)" type="text"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div
              class="nav-content"
              v-if="
                nav.jump_link !== undefined &&
                formType !== 'st-bottom-nav' &&
                formType !== 'st-shop-categories'
              "
            >
              <p class="nav-title">链接地址</p>
              <div class="w-full flex-col">
                <el-button
                  @click="openDialog(index)"
                  class="nav-btn"
                  plain
                  type="default"
                  v-if="
                    !nav.jump_link ||
                    !nav.jump_link.jump_type ||
                    !nav.jump_link.jump_config
                  "
                >
                  设置链接地址
                </el-button>
                <div class="link-selected" v-else>
                  <el-input
                    :title="navList[index].jump_link.jump_config.name"
                    :value="navList[index].jump_link.jump_config.name"
                    class="link-name"
                    disabled
                    v-if="
                      nav.jump_link.jump_config &&
                      !isSinglePage(nav.jump_link.jump_config)
                    "
                  ></el-input>
                  <el-button
                    :title="navList[index].jump_link.jump_config.name"
                    @click="toEditSinglePage(nav.jump_link.jump_config)"
                    class="link-name"
                    size="mini"
                    v-else-if="nav.jump_link.jump_config"
                    >{{ navList[index].jump_link.jump_config.name }}</el-button
                  >
                  <div class="btn-group">
                    <el-button
                      :disabled="formType === 'st-default-user-center-nav'"
                      @click="openDialog(index)"
                      type="text"
                      >修改</el-button
                    >
                    <el-button
                      :disabled="formType === 'st-default-user-center-nav'"
                      @click="handleDel(index)"
                      type="text"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                nav.jump_link &&
                nav.jump_link.jump_config &&
                isSinglePage(nav.jump_link.jump_config)
              "
              class="nav-content"
              style="margin-top: 0"
            >
              <p class="nav-title">&nbsp;</p>
              <div class="link-selected">
                <el-button
                  type="text"
                  :title="nav.jump_link.jump_config.path"
                  @click="toEditSinglePage(nav.jump_link.jump_config)"
                  >前往编辑页面内容<i class="el-icon-arrow-right"></i
                ></el-button>
              </div>
            </div>
            <div
              class="nav-content"
              style="margin-top: 0"
              v-else-if="
                nav.jump_link &&
                nav.jump_link.jump_config &&
                (nav.jump_link.jump_config.system_page ||
                  nav.jump_link.jump_config.id ||
                  nav.jump_link.jump_config.path ||
                  (nav.jump_link.jump_config.view &&
                    (nav.jump_link.jump_config.view.id ||
                      nav.jump_link.jump_config.view.system_page)))
              "
            >
              <p class="nav-title">&nbsp;</p>
              <div class="link-selected">
                <el-button
                  :title="nav.jump_link.jump_config.path"
                  @click="toDesign(nav.jump_link.jump_config)"
                  type="text"
                  >前往编辑页面内容<i class="el-icon-arrow-right"></i
                ></el-button>
              </div>
            </div>
            <div
              class="nav-content"
              v-if="nav.is_show !== undefined && allowHide"
              @mousemove="unDraggable = true"
              @mouseout="unDraggable = false"
            >
              <p class="nav-title">导航状态</p>
              <el-radio-group
                style="margin-left: auto"
                v-model="navList[index].is_show"
                size="small"
              >
                <el-radio-button :label="1">显示</el-radio-button>
                <el-radio-button :label="0">隐藏</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
      </template>
      <div
        class="nav-item-add"
        :style="{ marginTop: navList.length > 0 ? '24px' : '0' }"
        v-if="
          navList.length < limit && formType !== 'st-default-user-center-nav'
        "
      >
        <el-button
          style="width: 100%"
          size="mini"
          type="primary"
          plain
          @click="handleAdd"
          >{{ addButtonText }}
        </el-button>
      </div>
    </draggable>
    <link-config-dialog
      v-model="showLinkConfigDialog"
      :link="selectedLinkConfig"
      :jump_type="jumpType"
      :requestType="selectableType"
      :exclude_requestType="unselectableType"
      :component="formType"
      @change="linkChange"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
import LinkConfigDialog from '../Normal/LinkConfigDialog.vue'
import {
  getAllShopCategory,
  getIcon,
} from '@/modules/app-design/api/components'
import IconSelect from './IconSelect'
import { getKeyValueByQueryString } from '../../utils/webview'
const JUMP_LINK_MODEL = require('@/modules/app-design/assets/jsons/jump-link.json')

export default {
  name: 'StDragNavList',
  components: {
    draggable,
    SingleMediaWall,
    LinkConfigDialog,
    IconSelect,
  },
  inject: ['switchPage'],
  model: {
    prop: 'list',
    event: 'listChange',
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    formType: {
      type: String,
      default: '',
    },
    // 是否允许删除
    allowDelete: {
      type: Boolean,
      default: true,
    },
    // 是否允许隐藏
    allowHide: {
      type: Boolean,
      default: true,
    },
    initItem: {
      type: Object,
      required: true,
    },
    limit: {
      type: Number,
      default: 5,
    },
    navNameLength: {
      type: Number,
      default: 99,
    },
    addButtonText: {
      type: String,
      default: '添加导航',
    },
    suggestSize: {
      type: Object,
      default: () => {
        return {
          width: 100,
          height: 100,
        }
      },
    },
    // 列表项是否可折叠
    itemFoldable: {
      type: Boolean,
      default: false,
    },
    // 可选择链接类型
    selectable_type: {
      type: Array,
      default: () => [],
    },
    // 不可选择链接类型
    unselectable_type: {
      type: Array,
      default: () => [],
    },
    // 跳转类型
    jump_type: {
      type: String,
      default: 'all',
    },
  },
  data() {
    return {
      showLinkConfigDialog: false,
      selectedLinkConfig: null,
      currentIndex: -1,
      current_jumptype: 'view', // 当前链接设置跳转方式
      unDraggable: false,
      visible: false,
      hasCollapseArr: [],
      selectableType: [],
      unselectableType: [],
      // 商品分类选择项
      categoryOptions: [],
      // 图标列表
      iconList: [],
      isShowTemplateIcons: false,
    }
  },
  created() {
    this.selectableType = JSON.parse(JSON.stringify(this.selectable_type))
    this.unselectableType = JSON.parse(JSON.stringify(this.unselectable_type))
    // 获取商城分类
    if (this.$props.formType === 'st-shop-categories') {
      this.getShopCategory()
    }
    this.getIconList()
  },
  computed: {
    navList: {
      get() {
        return this.list
      },
      set(val) {
        this.$emit('listChange', val)
      },
    },
    jumpType() {
      return this.formType === 'st-bottom-nav'
        ? this.current_jumptype
        : this.jump_type
    },
  },
  watch: {
    navList: {
      handler(val) {
        this.$emit('listChange', val)
        console.log(val, 'listChange')
      },
      deep: true,
    },
  },
  methods: {
    toDesign(jump_config) {
      let that = this
      const { system_page, id, view, path } = jump_config
      if (system_page) {
        _toDesign({ system_page })
      } else if (id) {
        _toDesign({ id })
      } else if (view.system_page) {
        _toDesign({ system_page: view.system_page })
      } else if (view.id) {
        _toDesign({ id: view.id })
      } else if (path) {
        const isCustom =
          /^\/sub\/custom\/pages\/custom\?id=[\da-zA-Z]{8}$/gi.test(path)
        if (isCustom) {
          const idArr = path.match(/[\da-zA-Z]{8}/gi)
          if (idArr.length) {
            _toDesign({ id: idArr[0] })
          } else {
            this.$message.info('暂不支持快捷编辑')
          }
        } else {
          this.$message.info('暂不支持快捷编辑')
        }
      } else {
        this.$message.info('暂不支持快捷编辑')
      }
      function _toDesign(query) {
        // const {platform, id} = that.$route.params

        /* 进入页面装修后，重新请求缓存的分类数据 */
        // that.$store.commit('appDesign/setStateAttr', [{
        //   key: 'lastFetchTimestampRecord', val: {}
        // }])

        // let routerData = that.$router.resolve({
        //   name: "AppDesignPage",
        //   params: { platform: platform, id: id },
        //   query
        // });
        // window.open(routerData.href, "_blank"); // 新标签页打开

        // this.$router.replace() // 想要在当前打开

        that.switchPage({
          ...query,
          ...(query.system_page ? { id: 0 } : { system_page: '' }), // 适配注入的方法，两个都要传，系统页id为0，自定义页system_page为空字符串
        }) // 利用已封装好的切换（自定义页、系统页）页面方法
      }
    },
    toEditSinglePage({ path }) {
      const routerData = {
        name: 'EditContent',
        params: {
          id: getKeyValueByQueryString(path, 'id'),
        },
      }
      window.open(this.$router.resolve(routerData).href, '_blank')
    },
    /**
     * 判断是单页面，可以直接点击去编辑哦
     */
    isSinglePage(config) {
      return config.path.indexOf('sub/single/pages/index?id=') !== -1
    },
    test(val, i) {
      if (val.length > this.navNameLength) {
        this.$message.error(
          `导航名称超出最大字数限制（最大为${this.navNameLength}个字）`
        )
        this.navList[i].name = val.slice(0, this.navNameLength)
      }
    },
    handleAdd() {
      this.navList.push({ ...this.initItem })
    },
    // 排序发生变化时
    sortChange(ev) {
      if (this.hasCollapseArr.length) {
        if (
          this.hasCollapseArr.includes(ev.oldIndex) !==
          this.hasCollapseArr.includes(ev.newIndex)
        ) {
          if (this.hasCollapseArr.includes(ev.oldIndex)) {
            this.hasCollapseArr = this.hasCollapseArr.map((el) => {
              if (el === ev.oldIndex) {
                return ev.newIndex
              } else {
                return el
              }
            })
            return
          }
          if (this.hasCollapseArr.includes(ev.newIndex)) {
            this.hasCollapseArr = this.hasCollapseArr.map((el) => {
              if (el === ev.newIndex) {
                return ev.oldIndex
              } else {
                return el
              }
            })
            return
          }
        }
      }
    },
    // 折叠导航设置
    handleCollapse(index) {
      console.log('index', index)
      if (this.hasCollapseArr.includes(index)) {
        this.hasCollapseArr = this.hasCollapseArr.filter((el) => el !== index)
      } else {
        this.hasCollapseArr.push(index)
      }
    },
    // 设为首页
    setIndex(i) {
      this.navList.forEach((el, index) => {
        el.is_default = i === index ? 1 : 0
      })
      this.$message.success('操作成功')
    },
    // 选择跳转方式
    handleCommand(val, i) {
      this.currentIndex = i
      this.current_jumptype = val
      this.selectedLinkConfig = this.navList[i].jump_link || null
      if (this.formType === 'st-bottom-nav') {
        this.selectableType =
          val === 'view'
            ? ['system_page', 'custom_page']
            : JSON.parse(JSON.stringify(this.selectable_type))
      }
      this.showLinkConfigDialog = true
    },
    // 显示弹窗
    openDialog(i) {
      this.currentIndex = i
      this.current_jumptype = this.navList[i].jump_type
      this.selectedLinkConfig = this.navList[i].jump_link || null
      this.showLinkConfigDialog = true
    },
    // 删除
    handleDel(i) {
      this.navList[i].jump_link = JUMP_LINK_MODEL
    },
    linkChange(val) {
      this.navList[this.currentIndex].jump_link = this.$mergeModel(
        JUMP_LINK_MODEL,
        val
      )
    },
    // 删除
    deleteItem(i) {
      if (this.formType === 'st-bottom-nav') {
        this.$msgbox
          .confirm(
            '删除此底部导航项，此底部导航链接的页面将同时无法看到底部导航，确定删除吗？',
            '提示',
            {
              type: 'info',
            }
          )
          .then((res) => {
            this.navList.splice(i, 1)
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm('确定删除这条数据？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.navList.splice(i, 1)
          })
          .catch((err) => {})
      }
    },
    // 获取商品分类配置项
    getShopCategory() {
      getAllShopCategory().then((res) => {
        console.log(res)
        this.categoryOptions = res.data.filter((item) => item.label !== '全部')
      })
    },
    // 获取图标
    getIconList() {
      // 将组件标识给后端就行了
      console.log(this.$props.formType)
      getIcon({ component: this.$props.formType }).then((res) => {
        this.iconList = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.drag-list-header {
  padding: 0 $space;
}

.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.form-tip {
  font-size: 12px;
  color: #c0c4cc;
  padding-top: 8px;
  padding-bottom: 11px;
}
.nav-list {
  background-color: #f6f9ff;
  // margin: 0 -19px 0 -24px;
  padding: 16px 12px;
}
.nav-item-add {
  width: 100%;
  text-align: center;
}
.nav-item {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;

  .item-title {
    display: flex;
    justify-content: space-between;

    .item-collapse {
      transition: all 0.3s ease;
      margin-right: 5px;
    }

    .set-index {
      padding: 4px 12px;
      font-size: 14px;
    }
    .seted-index {
      display: flex;
      align-items: center;
      margin-right: 16px;
      .seted-icon {
        width: 17px;
        height: 16px;
        margin-right: 4px;
      }
      .seted-text {
        font-size: 14px;
        color: #3576ff;
        line-height: 14px;
      }
    }
  }
  .item-content {
    transition: all 0.2s ease;
    overflow: hidden;

    .nav-content {
      margin-top: 20px;
      display: flex;
      position: relative;
      .nav-title {
        line-height: 30px;
        font-size: 14px;
        color: #3a3a3a;
        margin-right: 18px;
        min-width: 56px;
      }
      .nav-btn {
        width: 100%;
        height: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .img-info {
        color: #c0c4cc;
        position: absolute;
        bottom: 0;
        left: 150px;
        font-size: 12px;
        line-height: 17px;
      }

      .link-selected {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex: 1;
        min-width: 0; // 防止超出
        word-break: break-word;

        .btn-group {
          display: flex;
          align-items: center;
          margin-left: 5px;

          .el-button + .el-button {
            margin-left: 5px;
          }
        }
        .link-name {
          /*width: 150px;*/
          flex: 1;
          min-width: 0;
          @include nowrap();

          ::v-deep.el-input__inner {
            text-overflow: ellipsis;
            white-space: nowrap;
            background-color: #fff;
            color: #3a3a3a;
            cursor: text;
          }
        }

        .el-dropdown {
          display: flex;
          align-items: center;
        }
        ::v-deep.el-button--text {
          padding: 0;
        }
        .link-selected-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 150px;
          background-color: #fff;
          background-image: none;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          color: #3a3a3a;
          display: inline-block;
          font-size: 14px;
          line-height: 30px;
          outline: 0;
          padding: 0 15px;
          transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
    }
  }
}
.el-input,
.el-select {
  ::v-deep.el-input__inner {
    height: 30px;
  }
  ::v-deep.el-input__icon {
    line-height: 30px;
  }
}

.nav-item + .nav-item {
  margin-top: 24px;
}

.drag-list {
  ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(53, 118, 255, 0.15);
    border: 1px solid #3576ff;
    color: #3576ff;
    box-shadow: none;
  }
}
.icon-grid {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(auto-fit, 40px);
  grid-gap: 10px;

  .icon-grid-item {
    padding: 8px;

    &.no-hover {
      padding: 0;
    }

    &:not(.no-hover):hover {
      background-color: $borderLighter;
      border-radius: 4px;
    }
  }

  ::v-deep.el-image__error {
    background-color: unset;
    font-size: 10px;
    width: 100%;
    height: 100%;
  }
}

.w-full {
  width: 100%;
}
</style>
