<template>
  <div class="st-theme">
    <div style="position: absolute; top: 0; left: 0;width: 100%;">
      <st-page-set title=" " :config="{normal: {statusBarColor: 'white'}}" :jump-config="jumpConfig"></st-page-set>
    </div>
    <div class="head">
      <img src="../../assets/images/st-theme/bg.png"/>
    </div>
    <div class="tabs">
      <div class="tab">简介</div>
      <div class="tab">图文</div>
      <div class="tab">报名</div>
      <div class="tab">心得</div>
    </div>
    <div class="content">
      <div class="title">第十三届会员纳新仪式</div>
      <div class="status">
        <div class="progress" :style="{
          backgroundColor: config.normal['isCustom'] ? config.normal.helpColor : config.normal.theme[1],
          color: config.normal['isCustom'] ? config.normal.mainColor : config.normal.theme[0]
        }">报名中</div>
        <div class="statistics">报名人数  10/30人</div>
      </div>
      <div class="list">
        <div class="item">
          <img src="../../assets/images/st-theme/proceed.png"/>
          <div class="right-list">
            <div class="right">
              <div class="label">报名时间</div>
              <div class="detail">2020.01.17 9:00 ~ 2020.01.19 17:00</div>
            </div>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/images/st-theme/finish.png"/>
          <div class="right-list">
            <div class="right">
              <div class="label">着装要求</div>
              <div class="detail">佩戴徽章·统一正装</div>
            </div>
            <div class="right">
              <div class="label">报名要求</div>
              <div class="detail">期满一年的正式成员或持公益事业证书<i class="el-icon-arrow-down"></i></div>
            </div>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/images/st-theme/proceed.png"/>
          <div class="right-list">
            <div class="right">
              <div class="label">活动时间</div>
              <div class="detail">2020.01.17 9:00 ~ 2020.01.19 17:00</div>
            </div>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/images/st-theme/finish.png"/>
          <div class="right-list">
            <div class="right">
              <div class="label">活动地址</div>
              <div class="detail">云莱斯酒店三楼宴会厅 <i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-bar">
      <div class="icon-btn">
        <img src="../../assets/images/st-theme/collect.png"/>
        <span>收藏</span>
      </div>
      <div class="icon-btn">
        <img src="../../assets/images/st-theme/share.png"/>
        <span>分享</span>
      </div>
      <div class="sign-btn" :style="{
        backgroundColor: config.normal['isCustom'] ? config.normal.mainColor : config.normal.theme[0],
        color: config.normal.theme[2] || 'white'
      }">免费报名</div>
    </div>
  </div>
</template>

<script>
  import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
  export default {
    name: "StTheme",
    components: {StPageSet},
    props: {
      config: {
        type: Object,
        default: () => {}
      },
      jumpConfig: Object,
    },
    data() {
      return {

      }
    },
  }
</script>

<style lang="scss" scoped>
.st-theme {
  width: 100%;
  height: 667px;
  position: relative;
  overflow: hidden;

  .status-bar {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .top-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 23px 8px 0 6px;

    &.simple {
      background-color: white;
      position: static;
      padding-top: 0;
      margin-top: 23px;

      .capsule-left {
        width: 43px;
        background-color: unset;
        border: none;
      }

      .handle-icon {
        display: none;
      }
    }

    .back-icon {
      width: 20px;
      height: 20px;
      margin-right: auto;
    }

    .title {
      flex: 1;
    }

    .capsule-left {
      width: 87px;
      height: 32px;
      background: rgba(51, 51, 51, .49);
      border-radius: 16px;
      /*opacity: 0.29;*/
      border: 1px solid rgba(0, 0, 0, 0.08);
      display: flex;
      /*justify-content: space-around;*/
      align-items: center;

      .capsule-item {
        width: 100%;
      }

      .divider {
        width: 1px;
        height: 19px;
        background: #000000;
        opacity: 0.2;
      }
    }

    .capsule-right {

    }

    .handle-icon {
      width: 87px;
      height: 32px;
      margin-left: auto;
    }
  }

  .head {
    width: 100%;
    height: 258px;
    background-color: #333C50;

    img {
      margin: 96px 0 0 77px;
      width: 222px;
      height: 107px;
    }
  }

  .tabs {
    display: flex;
    height: 51px;
    box-shadow: 0 1px 0 0 #DDDDDD;
    padding: 0 17px;

    .tab {
      font-size: 18px;
      color: #888888;
      line-height: 50px;
      white-space: nowrap;
      flex: 1;
      text-align: center;

      &:first-child {
        font-weight: bold;
        color: #333333;
        position: relative;

        &::after {
          content: "";
          width: 20px;
          height: 4px;
          background: #191E29;
          position: absolute;
          bottom: 0;
          left: calc(50% - 20px / 2);
        }
      }
    }
  }

  .fixed-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    position: absolute;
    bottom: 0;
    width: 375px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0 -2px 4px 0 rgba(238, 238, 238, 0.5);

    .icon-btn {
      width: 29px;
      font-size: 9px;
      color: #4A4C4F;
      line-height: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
      }
    }

    .sign-btn {
      width: 245px;
      height: 44px;
      border-radius: 3px;
      background: $primary;
      box-shadow: 0px -2px 4px 0px rgba(238, 238, 238, 0.5);
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 44px;
      text-align: center;
    }
  }

  .content {
    padding: 24px 18px;

    .title {
      font-size: 20px;
      font-weight: bold;
      color: #2E2E2E;
      line-height: 30px;
    }

    .status {
      margin-top: 12px;
      display: flex;
      align-items: center;

      .progress {
        height: 20px;
        background: rgba(58, 122, 255, 0.15);
        border-radius: 2px;
        font-size: 12px;
        color: #3A7AFF;
        line-height: 12px;
        padding: 4px 6px;
      }

      .statistics {
        font-size: 12px;
        color: #333333;
        line-height: 12px;
        margin-left: 20px;
      }
    }

    .list {
      margin-top: 19px;
      font-size: 12px;

      .item {
        background: #F8F8F8;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 14px 9px;

        & + .item {
          margin-top: 10px;
        }

        & > img {
          width: 16px;
          margin-right: 7px;
        }

        .right-list {
          display: flex;
          flex-direction: column;
          width: 100%;

          .right {
            display: flex;
            align-items: center;

            .label {
              font-size: 12px;
              color: #666666;
              line-height: 12px;
              margin-right: 15px;
            }

            .detail {
              color: black;
              flex: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;

              i {
                color: #A8A9AA;
              }
            }
          }
        }
      }
    }
  }
}
</style>
