var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:({
    padding: ("12px " + (_vm.myStyleType === 'template3' ? '16' : '0') + "px"),
    margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
    borderRadius: ((_vm.myConfig.normal.borderRadius) + "px"),
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage: _vm.myBgImage,
  })},[_c('StListTitle',{style:({
      padding: ("0 " + (_vm.myStyleType !== 'template3' ? '16' : '0') + "px 16px"),
    }),attrs:{"config":_vm.myConfig}}),(['template1'].includes(_vm.myStyleType))?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"template1-data-list"},[_c('div',{staticClass:"item-list"},_vm._l((_vm.dataList),function(item,i){return _c('div',{key:item.id,staticClass:"item",style:({
          marginLeft: ((i > 0 ? _vm.myConfig.templateData.dataSpacing : 0) + "px"),
        })},[_c('img',{staticClass:"avatar",attrs:{"src":item.cover}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),(i === 0 && _vm.myConfig.showConfig.isShowBtn)?_c('img',{staticClass:"rank-icon",attrs:{"src":_vm.firstIcon}}):_vm._e(),(i === 1 && _vm.myConfig.showConfig.isShowBtn)?_c('img',{staticClass:"rank-icon",attrs:{"src":_vm.secondIcon}}):_vm._e(),(i === 2 && _vm.myConfig.showConfig.isShowBtn)?_c('img',{staticClass:"rank-icon",attrs:{"src":_vm.thirdIcon}}):_vm._e()])}),0)]):_vm._e(),(_vm.myConfig.showConfig.isShowBtn)?_c('div',{staticClass:"check-btn"},[_c('img',{staticClass:"btn-bg",attrs:{"src":_vm.btnBg}}),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.myConfig.showConfig.title)+" ")])]):_vm._e(),(_vm.myLoadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myLoadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myLoadingConfig.handleText)+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }