<!--
 * @Author: dongjia
 * @Date: 2022-01-12 10:01:50
 * @LastEditTime: 2022-01-20 18:22:37
 * @LastEditors: aleaner
 * @Description: 在线学习组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StOnlineLearning.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="{
      padding: `12px ${myStyleType === 'template3' ? '16' : '0'}px`,
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      borderRadius: `${myConfig.normal.borderRadius}px`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage: myBgImage,
    }"
  >
    <StListTitle
      :style="{
        padding: `0 ${myStyleType !== 'template3' ? '16' : '0'}px 16px`,
      }"
      :config="myConfig"
    />

    <!-- 模版一 -->
    <div
      v-loading="loading"
      class="template1-data-list"
      v-if="['template1'].includes(myStyleType)"
    >
      <div class="item-list">
        <div
          class="item"
          v-for="(item, i) in dataList"
          :key="item.id"
          :style="{
            marginLeft: `${i > 0 ? myConfig.templateData.dataSpacing : 0}px`,
          }"
        >
          <img class="avatar" :src="item.cover" />
          <div class="name">{{ item.name }}</div>

          <img class="rank-icon" v-if="i === 0 && myConfig.showConfig.isShowBtn" :src="firstIcon" />
            <img class="rank-icon" v-if="i === 1 && myConfig.showConfig.isShowBtn" :src="secondIcon" />
            <img class="rank-icon" v-if="i === 2 && myConfig.showConfig.isShowBtn" :src="thirdIcon" />
        </div>
      </div>
    </div>
    <!-- 查看按钮 -->
    <div class="check-btn" v-if="myConfig.showConfig.isShowBtn">
      <img class="btn-bg" :src="btnBg" />
      <div class="text">
        {{ myConfig.showConfig.title }}
      </div>
    </div>
    <div class="load-more" v-if="myLoadingConfig.loadingType === 'handle'">
      <div :class="[myLoadingConfig.handleType]">
        {{ myLoadingConfig.handleText }}
      </div>
    </div>
  </div>
</template>

<script>
import { getEleBookList } from '../../api/components'
import componentMixin from '@/modules/app-design/mixins/component-mixin'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'

export default {
  name: 'st-college-ele-book-list',
  components: { StLoadImage },
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      btnBg:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/button-bg.png',
      playIcon:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/play-icon.png',
      listenIcon:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/listen-icon.png',
        firstIcon:
        window.serverConfig.VUE_APP_ADMINURL + '/app_design/images/first.png',
      secondIcon:
        window.serverConfig.VUE_APP_ADMINURL + '/app_design/images/second.png',
      thirdIcon:
        window.serverConfig.VUE_APP_ADMINURL + '/app_design/images/third.png',
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
          borderRadius: 12,
        },
        templateData: {
          styleType: 'template1',

          dataSpacing: 12,
        },
        dataConfig: {
          dataType: 'select',
          orderRule: 'normal',
          dataAll: 1,
          dataFilter: [''],
          dataArr: [
            {
              id: '',
              name: '',
            },
          ],
        },
        loadingConfig: {
          loadingType: 'none',
          limit: 10,
          handleType: 'template1',
          handleText: '加载更多',
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '在线学习',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          isShowBtn: 1,
          showType: 'template1',
          title: '查看更多',
        },
      },
      dataList: [],
    }
  },
  watch: {
    'myLoadingConfig.limit'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataType'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getList(false)
      }
    },
    'myConfig.dataConfig.dataAll'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.orderRule'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataFilter'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getList(false)
      }
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList(notCancel) {
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        page: 1,
        tab: 'normal',
        limit: this.myLoadingConfig.limit,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        template: this.myStyleType,
        ids,
        order_rule: this.myConfig.dataConfig.orderRule,
        category_all: this.myConfig.dataConfig.dataAll,
        category_ids: this.myConfig.dataConfig.dataFilter.map((item) => item),
      }
      this.loading = true
      getEleBookList(requestData, notCancel)
        .then((res) => {
          const { list } = res.data
          this.dataList = list

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/component';

.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;
  .template1-data-list {
    overflow: hidden;
    .item-list {
      width: max-content;
      display: flex;
      padding: 6px 12px 0;
      .item {
        width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .rank-icon {
            width: 24px;
            height: 24px;
            position: absolute;
            left: -6px;
            top: -6px;
          }
        .avatar {
          width: 90px;
          height: 120px;
          border-radius: 4px;
          object-fit: cover;
        }
        .name {
          margin-top: 8px;
          @include nowrap();
          max-width: 100%;

          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 19.5px;
        }
      }
      .item + .item {
        margin-left: 8px;
      }
    }
  }
  .check-btn {
    margin: 12px 12px 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .text {
      color: #45350e;
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      position: relative;
      z-index: 3;
    }
    .btn-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}
</style>
