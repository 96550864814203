<template>
  <div class="st-enterprise-item-gov">
    <div class="item-top">
      <div class="image-wrapper" :style="{backgroundColor: !item.logo? myBgColor: ''}">
        <st-load-image v-if="item.logo" class="image" :src="item.logo" mode="aspectFit" :size="200" />
        <div v-else class="name">
          <!-- 字符本身可能会重复 -->
          <span :key="sI" v-for="(s, sI) in item.alias_name">{{s}}</span>
        </div>
      </div>
      <div class="text-wrapper">
        <span class="text clamp2">{{item.name}}</span>
        <span class="text2 clamp">信息来源：{{item.project_name}}</span>
      </div>
    </div>
<!--    <div class="item-bottom">-->
<!--      <span class="label">信息来源</span>-->
<!--      <p class="source">{{item.project_name}}</p>-->
<!--    </div>-->
  </div>
</template>

<script>
  import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";
  const colorList = [
    "rgb(67, 190, 64)",
    "rgb(253, 103, 39)",
    "rgb(239, 57, 57)",
    "rgb(208, 48, 241)",
    "rgb(244, 111, 54)",
    "rgb(43, 138, 242)",
    "rgb(248, 163, 46)",
    "rgb(225, 73, 50)"
  ];
  export default {
    name: "StEnterpriseItemGov",
    components: {StLoadImage},
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        myBgColor: colorList[Math.floor(Math.random() * colorList.length)]
      }
    },
  }
</script>

<style lang="scss" scoped>
  .st-enterprise-item-gov {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 220px;
    border-radius: 5px;

    .item-top {
      display: flex;
      align-items: center;
    }

    .image-wrapper {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 10px;
      display: flex;

      .name {
        margin: auto;
        font-size: 14px;
        line-height: 14px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        text-align: center;
        color: white;
      }

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .text-wrapper {
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: bold;
      color: #191e29;
      display: flex;
      flex-direction: column;
      min-width: 0;

      .text2 {
        line-height: 12px;
        font-size: 12px;
        color: #969799;
        margin-top: 10px;
      }
    }

    .item-bottom {
      margin-top: 5px;
      display: flex;
      align-items: center;

      .label {
        width: 60px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        background-color: var(--help-color);
        color: var(--main-color);
        margin-right: 10px;
        flex-shrink: 0;
        border-radius: 4px;
      }

      .source {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
