<!--
 * @Author: dongjia
 * @Date: 2021-10-08 17:06:56
 * @LastEditTime: 2021-10-11 11:36:38
 * @LastEditors: aleaner
 * @Description: 关于我组件
 * @FilePath: \app-design\components\Preview\StAboutMe.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="member-msg" :style="{
    marginTop: myConfig.normal.marginTop + 'px',
    position: 'relative',
  }">
  
    <StMemberContainer :contentConfig="myConfig.contentConfig" :borderBottomRadius="borderRadius"
      :isShowBtn="false">
      <div class="msg-content">
        <div class="default-content"
          v-if="myConfig.normal.isShowDefaultPage && !myConfig.contentConfig.msgList.length">
          <img class="default-img" src="../../assets/images/st-member-index/default-content-icon.png" />
          <div class="default-text">亲～该页面暂时没有内容哦</div>
        </div>
        <div class="content-list" v-if="myConfig.contentConfig.msgList.length">
          <StAboutMeContent class="list-item" v-for="(item,index) in myConfig.contentConfig.msgList"
            :key="index" :content="item" />
        </div>
      </div>
    </StMemberContainer>
  </div>
</template>

<script>
import StMemberContainer from "./StMemberContainer";
import StAboutMeContent from "./StAboutMeContent";
export default {
  name: "StAboutMe",
  components: { StMemberContainer, StAboutMeContent },
  props: {
    config: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      model: {
        normal: {
          marginTop: 0,
          isShowDefaultPage: 1,
        },
        contentConfig: {
          titleConfig: {
            name: "页面标题",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 14,
          fontColor: "#000000",
          msgList: [
            {
              name: "个性签名",
              key: "signature",
              is_show: 1,
            },
            {
              name: "自我介绍",
              key: "description",
              is_show: 1,
            },
            {
              name: "个人标签",
              key: "label",
              is_show: 1,
            },
            {
              name: "兴趣爱好",
              key: "hobby",
              is_show: 1,
            },
            {
              name: "喜爱书籍",
              key: "like_books",
              is_show: 1,
            },
            {
              name: "喜爱影视剧",
              key: "like_movie",
              is_show: 1,
            },
            {
              name: "喜爱照片",
              key: "like_photo",
              is_show: 1,
            },
            {
              name: "喜爱视频",
              key: "like_video",
              is_show: 1,
            },
          ],
        },
      },
      borderRadius: 0,
    };
  },
  watch: {
    "myConfig.normal.marginTop"(val) {
      this.$bus.emit("marginTopChange", { marginTop: val, index: this.index });
    },
  },
  created() {
    this.$bus.on("marginTopChange", (res) => {
      if (res.index - 1 === this.index) {
        this.borderRadius = res.marginTop > 0 ? 4 : 0;
      }
    });
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-content {
  .default-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
    .default-img {
      width: 210px;
      height: 100px;
    }
    .default-text {
      margin-top: 24px;
      font-size: 14px;
      color: #979da6;
      line-height: 14px;
    }
  }
}

.member-msg {
  overflow: hidden;
}

.content-list {
  padding: 15px 15px 15px 20px;
  .list-item + .list-item {
    margin-top: 21px;
  }
}
</style>