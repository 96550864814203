<template>
  <div style="margin-bottom: 24px; padding: 0 20px">
    <p class="title" v-if="title">{{ title }}</p>
    <el-checkbox-group
      v-if="value && value.length"
      class="checkbox-content"
      v-model="value"
      :min="min"
    >
      <el-checkbox
        :style="
          index < options.length - (options.length % 4 ? options.length % 4 : 4)
            ? 'margin-bottom: 16px;'
            : ''
        "
        v-for="(item, index) in options"
        :disabled="item.disabled"
        :key="index"
        :label="item.value"
        >{{ item.name }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: 'StCheckBoxGroup',
  model: {
    prop: 'template',
    event: 'templateChange',
  },
  props: {
    title: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    template: {
      type: Array,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    value: {
      get() {
        return this.template
      },
      set(val) {
        this.$emit('templateChange', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  line-height: 14px;
  margin-bottom: 18px;
  font-size: 14px;
  color: #606266;
}

.checkbox-content {
  display: flex;
  flex-wrap: wrap;
  .el-checkbox {
    margin-right: 0;
    width: 25%;
    padding-right: 10px;
  }
}
</style>
