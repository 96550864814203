import { render, staticRenderFns } from "./StStartPage.vue?vue&type=template&id=3168e6c2&scoped=true&"
import script from "./StStartPage.vue?vue&type=script&lang=js&"
export * from "./StStartPage.vue?vue&type=script&lang=js&"
import style0 from "./StStartPage.vue?vue&type=style&index=0&id=3168e6c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3168e6c2",
  null
  
)

export default component.exports