<!--
 * @Author: dongjia
 * @Date: 2021-06-22 17:12:25
 * @LastEditTime: 2021-06-22 18:02:30
 * @LastEditors: aleaner
 * @Description: 企业库信息展示组件
 * @FilePath: \app-design\components\Form\StInfoSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="selector">
    <el-select class="select-input" v-model="value" placeholder="请选择" :multiple="multiple" :multiple-limit="3"
      :collapseTags="collapseTags">
      <el-option v-for="(item,index) in selectOpt" :key="index" :value="item.value" :label="item.label">
      </el-option>
    </el-select>
    <p class="form-tips" v-if="formTip">提示：{{formTip}}</p>
  </div>
</template>

<script>
    // 获取企业库信息展示
import { getCompanyLibOptions } from "../../api/components";

export default {
  name: "StInfoSelector",
  model: {
    prop: "selected",
    event: "change",
  },
  props: {
    selected: {
      type: [String, Number, Array, Object],
      default: "",
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 多选时是否折叠选中选项
    collapseTags: {
      type: Boolean,
      default: false,
    },
    formTip: {
      type: String,
      default: "",
    },
  },
  data(){
    return {
      // 选择配置
      selectOpt:[]
    }
  },
  methods:{
    // 获取企业库信息展示
    getOptions(){
      getCompanyLibOptions().then(res => {
        this.selectOpt = res.data;
        console.log(this.selectOpt)
      })
    }
  },
  created(){
    this.getOptions()
  },
  computed: {
    value: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  .select-input {
    width: 100%;
  }
  .form-tips {
    text-align: justify;
    line-height: 16px;
    font-size: 13px;
    color: #9d9ea0;
    padding-top: 7px;
    // .tip-icon {
    //   font-size: 15px;
    //   margin-right: 5px;
    // }
  }
}
</style>