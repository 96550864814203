<template>
  <div>
    <!-- 通讯录列表选择类型弹窗 -->
    <div class="mail-sort">
      <el-button size="medium" @click="isShowDialog = true">请选择</el-button>
    </div>
    <MemberSortDialogVue v-model="isShowDialog" :selectTypes="value" @selectTypesChange="typeChange"></MemberSortDialogVue>
  </div>
</template>

<script>
import MemberSortDialogVue from "../Normal/memberSortDialog.vue";
export default {
  name: "StMailSortDialog",
  components: {
    MemberSortDialogVue,
  },
  model: {
    prop: "selectTypes",
    event: "typesChange",
  },
  props: {
    selectTypes: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      // 是否显示筛选弹窗
      isShowDialog: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.selectTypes;
      },
      set(val) {
        this.$emit("typesChange", val);
      },
    },
  },
  methods: {
    // 关闭弹窗
    handleClose() {},
    // 打开弹窗回调
    openDialog() {
      this.getSelectFields();
    },
    // 资料类型改变
    typeChange(val) {
      this.value = val
    }
  },
};
</script>

<style lang='scss' scoped>
.mail-sort {
  float: right;
  display: flex;
}
</style>
