<template>
  <div class="st-analysis-page flex-col">
    <st-nav style="z-index: 1;position: relative;" />
    <img v-if="!(myConfig.normal.bgType === 'image' && myConfig.normal.bgImage)" class="bg" :class="{theme: myConfig.header.bgType === 'theme'}" :style="{
      backgroundColor: myConfig.header.bgType === 'color' ? myConfig.header.bgColor: ''
    }" :src="myConfig.normal.bgType === 'image' && myConfig.normal.bgImage ? myConfig.normal.bgImage: require('../../../assets/images/st-analysis-page/page-bg.png')" />
    <div class="org flex-align-center">
      <img class="logo" :src="defaultAvatar" />
      <div class="flex-col">
        <span class="title">何仙超</span>
        <span class="subtitle">创会会长</span>
      </div>
    </div>
  </div>
</template>

<script>
import StNav from "@/modules/app-design/components/Preview/StNav";
import model from './props.json'

export default {
  name: "StAnalysisPageForUser",
  components: {StNav},
  provide() {
    return {
      headerJump: (() => this.$mergeModel({
        "showConfig": {
          "showType": "template1"
        },
        "tabs": {
          "list": [
            {
              "icon": "",
              "name": "导航一",
              "is_show": 1,
              "jump_link": null
            }
          ]
        }
      }, this.headerJump))(),
      config: () => this.myConfig
    }
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png",
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel({
        ...model
      }, this.config);
    },
  },
}
</script>

<style lang="scss" scoped>
  .st-analysis-page {
    .bg {
      position: absolute;
      width: 100%;
      top: 0;

      &.theme {
        background-color: var(--main-color);
      }
    }

    .org {
      width: 343px;
      background-color: white;
      border-radius: 6px;
      margin: 12px auto 8px;
      padding: 16px 12px;
      position: relative;

      .logo {
        width: 48px;
        height: 48px;
        margin-right: 12px;
        border-radius: 50%;
      }

      .title {
        color: rgba(26,26,26,1);
        font-weight: bold;
        line-height: 1.5;
        font-size: 18px;
      }

      .subtitle {
        line-height: 1.5;
        color: rgba(179,179,179,1);
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
</style>
