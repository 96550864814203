// import store from "@/base/store";

export default {
  getRules(getVm, value, platform) {
    return {
      contentConfig: {
        title: '内容设置',
        rules: [
          {
            type: "ElSelect",
            field: "data_keys",
            title: "信息展示",
            display: true,
            props: {
              style: "width: 100%;",
              valueKey: 'value',
              multiple: true
            },
            "children": (() => {
              const options = [
                {
                  label: '行业',
                  value: 'category'
                },
                {
                  label: '城市',
                  value: 'city'
                },
                {
                  label: '认证',
                  value: 'ident_status'
                },
                {
                  label: '上市',
                  value: 'is_on_stock'
                }
              ]
              return options.map(op => {
                return {
                  type: "ElOption",
                  props: {
                    label: op.label,
                    value: op,
                    key: op.value,
                  }
                }
              })
            })()
          }
        ]
      }
    }
  }
}
