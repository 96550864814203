<template>
  <div class="st-link-config">
    <slot name="empty">
      <el-button
        v-if="isEmptyConfig"
        class="nav-btn"
        type="default"
        plain
        @click="showLinkConfigDialog = true"
      >
        {{ placeholder || '设置链接地址' }}
      </el-button>
    </slot>

    <slot :empty="isEmptyConfig" :jump-link="linkData">
      <div class="link-selected" v-if="!isEmptyConfig">
        <el-input
          class="link-name"
          :size="size"
          disabled
          v-model="linkData.jump_config.name"
        ></el-input>
        <div class="handle-btn">
          <el-button type="text" @click="showLinkConfigDialog = true"
            >修改</el-button
          >
          <el-button type="text" @click="handleDel">删除</el-button>
        </div>
      </div>
    </slot>

    <link-config-dialog
      v-model="showLinkConfigDialog"
      :link="linkData"
      :jump_type="jump_type"
      :requestType="selectable_type"
      :exclude_requestType="unselectable_type"
      @change="linkChange"
    />
  </div>
</template>

<script>
import LinkConfigDialog from '../Normal/LinkConfigDialog.vue'
const JUMP_LINK_MODEL = require('@/modules/app-design/assets/jsons/jump-link.json')

export default {
  name: 'StLinkConfig',
  components: { LinkConfigDialog },
  model: {
    prop: 'linkData',
    event: 'change',
  },
  props: {
    linkData: {
      type: [Object, String],
      default: () => {
        return JUMP_LINK_MODEL
      },
    },
    // 可选择链接类型
    selectable_type: {
      type: Array,
      default: () => [],
    },
    // 不可选择链接类型
    unselectable_type: {
      type: Array,
      default: () => [],
    },
    // 跳转类型
    jump_type: {
      type: String,
      default: 'all',
    },
    size: String,
    placeholder: String,
  },
  data() {
    return {
      showLinkConfigDialog: false,
    }
  },
  computed: {
    isEmptyConfig() {
      let linkData = this.linkData
      if (!linkData) return true
      if (linkData && !linkData.jump_type) return true
      if (linkData && !linkData.jump_config) return true
      return !!(
        linkData &&
        linkData.jump_config &&
        linkData.jump_config.name == ''
      )
    },
  },
  watch: {
    linkData(newValue) {
      if (typeof newValue === 'string') {
        this.$emit('change', JUMP_LINK_MODEL)
      }
    },
  },
  created() {
    this.$nextTick(() => {
      if (this.linkData?.jump_config?.name === '') {
        this.$emit('change', JUMP_LINK_MODEL)
      }
    })
  },
  methods: {
    linkChange(val) {
      this.$emit('change', val)
    },
    //删除
    handleDel() {
      this.$emit('change', JUMP_LINK_MODEL)
    },
    open() {
      this.showLinkConfigDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-btn {
  width: 100%;
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-selected {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .link-name {
    width: 140px;
    ::v-deep.el-input__inner {
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #fff;
      color: #3a3a3a;
      cursor: text;
    }
  }
  .handle-btn {
    display: flex;
    align-items: center;

    ::v-deep.el-button--text {
      padding: 0;
    }
  }
}
</style>
