var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:([_vm.myRootStyle, {
      '--empty-icon-bg': _vm.defaultIconBgColor,
      padding: ("12px " + (['template3', 'template5', 'template9'].includes(_vm.myStyleType) ? '16' : '0') + "px  " + (['template3', 'template4', 'template5', 'template9'].includes(_vm.myStyleType) ? '12' : '0') + "px")
    }])},[_c('StListTitle',{style:({
        padding: ("0 " + (!['template3', 'template5', 'template9'].includes(_vm.myStyleType) ? '16' : '0') + "px 16px"),
      }),attrs:{"config":_vm.myConfig}}),(['template1', 'template2', 'template7', 'template8'].includes(_vm.myStyleType))?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view scroll",class:[_vm.myConfig.dataConfig.interactiveType]},_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",class:_vm.myStyleType,style:({
          marginRight: _vm.myTemplateData.dataSpacing + 'px',
          backgroundImage: _vm.myStyleType === 'template8' && item.page_cover ? ("url(" + (_vm.fixSpecialImageUrl(item.page_cover, 'large', index)) + ")") : '',
          backgroundColor: _vm.myStyleType === 'template8' && !item.page_cover ? 'var(--empty-icon-bg)' : '',
        })},[(_vm.myStyleType === 'template1')?[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.page_cover,"size":300}}),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),(item.articles.length && item.articles[0].title||_vm.myConfig.dataConfig.interactiveType==='vertical')?_c('div',{staticClass:"item-news"},[_c('div',{staticClass:"news-tip"},[_vm._v("资讯")]),_c('div',{staticClass:"news-content"},[_vm._v(" "+_vm._s(item.articles.length && item.articles[0].title?item.articles[0].title:'暂无内容')+" ")])]):_vm._e(),(item.activity.length && item.activity[0].name||_vm.myConfig.dataConfig.interactiveType==='vertical')?_c('div',{staticClass:"item-news"},[_c('div',{staticClass:"news-tip"},[_vm._v("活动")]),_c('div',{staticClass:"news-content"},[_vm._v(" "+_vm._s(item.activity.length && item.activity[0].name?item.activity[0].name:'暂无内容')+" ")])]):_vm._e(),_c('div',{staticClass:"item-collect"},[_c('div',{staticClass:"collect-avatar"},[_c('st-avatars',{attrs:{"arr":item.organizations}})],1),_c('div',{staticClass:"collect-content"},[(item.organizations_count)?_c('span',{staticStyle:{"margin-right":"6px"}},[_vm._v("共 "+_vm._s(item.organizations_count > 999 ? "999+" : item.organizations_count)+" 名会员")]):_c('span',[_vm._v("暂无会员")])])])])]:_vm._e(),(_vm.myStyleType === 'template2')?[_c('div',{staticClass:"item-image"},[_c('st-load-image',{staticClass:"item-cover",attrs:{"src":item.page_cover,"size":200}})],1),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.name)+" ")])])]:_vm._e(),(_vm.myStyleType === 'template7')?[_c('div',{staticClass:"item-box",style:({
              backgroundColor: _vm.randomColor[index % _vm.randomColor.length],
            })},[_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),(item.organizations_count)?_c('div',{staticClass:"content-bottom"},[_vm._v("共 "+_vm._s(item.organizations_count)+" 名会员")]):_c('div',{staticClass:"content-bottom"},[_vm._v("暂无会员")])])])]:_vm._e(),(_vm.myStyleType === 'template8')?[_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-desc"},[_vm._v(_vm._s(item.description))]),_c('div',{staticClass:"item-collect"},[_c('div',{staticClass:"collect-avatar"},[_c('st-avatars',{attrs:{"arr":item.organizations}})],1),_c('div',{staticClass:"collect-content"},[(item.organizations_count)?_c('span',{staticStyle:{"margin-right":"6px"}},[_vm._v("共 "+_vm._s(item.organizations_count > 999 ? "999+" : item.organizations_count)+" 名会员")]):_c('span',[_vm._v("暂无会员")])])])])]:_vm._e()],2)}),0):_vm._e(),(_vm.myStyleType === 'template6')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view scroll-special",staticStyle:{"overflow-x":"auto","overflow":"hidden"}},_vm._l((_vm.twoDataLists),function(list,index){return _c('div',{key:index,staticClass:"list-item",style:({
          marginRight: _vm.myTemplateData.dataSpacing + 'px',
        })},_vm._l((list),function(item,i){return _c('div',{key:i,staticClass:"item-list-item",style:(i > 0 ? 'padding-top: 8px' : 'padding-bottom: 8px')},[_c('div',{staticClass:"item-image"},[_c('st-load-image',{staticClass:"item-cover",attrs:{"src":item.page_cover,"size":200}})],1),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),(item.organizations_count)?_c('div',{staticClass:"content-bottom"},[_vm._v("共 "+_vm._s(item.organizations_count)+" 名会员")]):_c('div',{staticClass:"content-bottom"},[_vm._v("暂无会员")])])])}),0)}),0):_vm._e(),(['template3', 'template5', 'template9'].includes(_vm.myStyleType))?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view flex-column",staticStyle:{"padding-left":"0px"}},[_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",class:_vm.myStyleType,style:({
          marginTop: index === 0 ? '0' : _vm.myTemplateData.dataSpacing + 'px',
          backgroundImage: _vm.myStyleType === 'template9' && item.page_cover ? ("url(" + (_vm.fixSpecialImageUrl(item.page_cover, 'large', index)) + ")") : '',
          backgroundColor: _vm.myStyleType === 'template9' && !item.page_cover ? 'var(--empty-icon-bg)' : '',
        })},[(_vm.myStyleType === 'template3')?[_c('div',{staticClass:"item-image"},[_c('st-load-image',{staticClass:"item-cover",attrs:{"src":item.page_cover,"size":200}})],1),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"item-desc"},[_vm._v(_vm._s(item.description))])])]:_vm._e(),(_vm.myStyleType === 'template5')?[_c('StBrandItemBig',{attrs:{"item":item}})]:_vm._e(),(_vm.myStyleType === 'template9')?[_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-desc"},[_vm._v(_vm._s(item.description))]),_c('div',{staticClass:"item-collect"},[_c('div',{staticClass:"collect-avatar"},[_c('st-avatars',{attrs:{"arr":item.organizations,"indent":7}})],1),_c('div',{staticClass:"collect-content"},[(item.organizations_count)?_c('span',{staticStyle:{"margin-right":"6px"}},[_vm._v("共 "+_vm._s(item.organizations_count > 999 ? "999+" : item.organizations_count)+" 名会员")]):_c('span',[_vm._v("暂无会员")])]),(item.is_joinable === 1)?_c('div',{staticClass:"join-btn"},[_vm._v("+ 申请加入")]):_vm._e()])])]:_vm._e()],2)}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText)+" ")])]):_vm._e()],2):_vm._e(),(_vm.myStyleType === 'template4')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template4",staticStyle:{"padding":"0 16px"}},[(_vm.brandSwiperList.length)?_c('div',{ref:"mySwiper",staticClass:"banner-swiper",style:({
          justifyContent: _vm.brand_list.length > 2 ? 'center' : '',
        })},_vm._l((_vm.brandSwiperList),function(item,index){return _c('div',{key:index,staticClass:"swiper-no-swiping banner"},[_c('st-load-image',{staticClass:"banner-image",attrs:{"src":item.page_cover,"data-index":index,"size":500,"border-radius":_vm.myTemplateData.borderRadius}}),_c('div',{staticClass:"banner-content",attrs:{"data-index":index}},[_c('div',{staticClass:"content-tag"},[_c('el-image',{staticClass:"tag-img",attrs:{"fit":"cover","src":require('../../assets/images/st-brand-list/circle-star.png')}}),_c('p',{staticClass:"tag-content"},[_vm._v(_vm._s(_vm.myConfig.listTitle.title ? _vm.myConfig.listTitle.title : "标题名称"))])],1),_c('div',{staticClass:"content-mask"},[_c('div',{staticClass:"content-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-collect"},[_c('div',{staticClass:"collect-avatar"},[_c('st-avatars',{attrs:{"arr":item.organizations}})],1),_c('div',{staticClass:"show-detail"},[_vm._v("查看详情")])])])])],1)}),0):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }