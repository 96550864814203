var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-avatars",style:({
		height: ("calc(" + (_vm.width + (_vm.borderWidth || 1) * 2) + "px)")
	})},[_vm._l((_vm.arr.slice(0, _vm.max)),function(user,index){return _c('img',{key:index,staticClass:"icon",style:({
				marginLeft :  index > 0?(-_vm.indent) + 'px':0,
				'z-index' : _vm.reverse ? (_vm.max + 1 + index) : ((_vm.max + 1) - index),
				'background-color' : user.avatar ? 'white' : '#605D5D',
				width: (_vm.width + "px"),
				height: (_vm.width + "px"),
				border: ((_vm.borderWidth || 1) + "px solid white")
			}),attrs:{"src":_vm.fixSpecialImageUrl(user.avatar, '', index)}})}),(_vm.showDefaultAvatar)?_vm._l((_vm.icon_people.slice(0, _vm.max - _vm.arr.length)),function(icon,index){return _c('img',{key:icon.avatar,staticClass:"icon",style:({
				marginLeft : (_vm.arr.length === 0 && index < 1)?0:(-_vm.indent + 'px'),
				'z-index' : _vm.reverse ? (_vm.max + 1 + index + _vm.arr.length) : ((_vm.max + 1) - (index + _vm.arr.length)),
				width: (_vm.width + "px"),
				height: (_vm.width + "px"),
        border: ((_vm.borderWidth || 1) + "px solid white")
			}),attrs:{"src":icon.avatar}})}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }