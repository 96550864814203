<template>
  <div class="brand-list" :style="{
        margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
        padding: `12px ${myStyleType === 'template2'?'16':'0'}px`,
        backgroundColor: myConfig.normal.bgType === 'color'?myConfig.normal.bgColor:'',
        backgroundImage: myBgImage
      }">
    <StListTitle :style="{
      padding: `0 ${myStyleType !== 'template2'?'16':'0'}px 16px`
    }" :config="myConfig" />
    <div v-loading="loading" class="swiper-view template1"
      v-if="myStyleType === 'template1'">
      <div class="list-item" :style="{
        marginRight: myTemplateData.dataSpacing + 'px',
      }" v-for="(item,index) in brand_list" :key="index">
        <st-load-image class="item-image" :src="item.image" :size="300" />
        <div class="item-content">
          <p class="item-title">
            {{item.name}}
          </p>
        </div>
      </div>
    </div>
    <div v-if="myStyleType === 'template2'"  v-loading="loading" class="swiper-view template2" style="padding-left:0">
      <div class="list-item" :style="{
        marginTop: index === 0?'0':myTemplateData.dataSpacing + 'px',
      }" v-for="(item, index) in brand_list" :key="index">
        <st-load-image class="item-image" :src="item.image" :size="300" :border-radius="myTemplateData.borderRadius" />
        <div class="item-content">
          <p class="item-title">{{item.name}}</p>
          <p class="item-desc">{{item.description}}</p>
        </div>
      </div>
      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{myConfig.loadingConfig.handleText}}</div>
      </div>
    </div>
    <div v-if="myStyleType === 'template3'" v-loading="loading" class="swiper-view template3">
      <div class="list-item" :style="{
        marginRight: myTemplateData.dataSpacing + 'px',
      }" v-for="(item,index) in brand_list" :key="index">
        <st-load-image class="item-image" :src="item.image" :size="300" :border-radius="4" />
        <div class="item-content">
          <p class="item-title">{{item.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { specialArticleList } from "../../api/components";
import componentMixin from '../../mixins/component-mixin'
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";

export default {
  name: "StSpecialArticle",
  components: {StLoadImage},
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      defaultList: require("../../assets/jsons/default-list.json")
        .specialArticleList,
      defaultAvatars: [
        require("../../assets/images/st-spirit-list/circle-avatar1.png"),
        require("../../assets/images/st-spirit-list/circle-avatar2.png"),
        require("../../assets/images/st-spirit-list/circle-avatar3.png"),
        require("../../assets/images/st-spirit-list/circle-avatar4.png"),
        require("../../assets/images/st-spirit-list/circle-avatar5.png"),
        require("../../assets/images/st-spirit-list/circle-avatar6.png"),
      ],
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          rowMargin: 0,
        },
        templateData: {
          styleType: "template1",
          dataSpacing: 10,
          borderRadius: 8,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          dataAll: 1,
          dataFilter: [1],
        },
        loadingConfig: {
          loadingType: "none",
          limit: 10,
          handleType: "template1",
          handleText: "加载更多",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "文章专题",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
        },
      },
      brand_list: [],
    };
  },
  watch: {
    "myConfig.loadingConfig.limit"(val) {
      this.getSpecialArticleList(false);
    },
    "myConfig.dataConfig.dataType"(val) {
      this.getSpecialArticleList(false);
    },
    "myConfig.dataConfig.dataArr"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getSpecialArticleList(false);
      }
    },
    "myConfig.dataConfig.dataAll"(val) {
      this.getSpecialArticleList(false);
    },
    "myConfig.dataConfig.dataFilter"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getSpecialArticleList(false);
      }
    },
  },
  created() {
    this.getSpecialArticleList();
  },
  methods: {
    getSpecialArticleList(notCancel) {
      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });
      let requestData = {
        page: 1,
        limit: this.myConfig.loadingConfig.limit,
        category_all: this.myConfig.dataConfig.dataAll,
        category_ids: this.myConfig.dataConfig.dataFilter,
        use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
        template: this.myStyleType,
        ids,
      };
      this.loading = true;
      specialArticleList(requestData, notCancel)
        .then((res) => {
          const { data } = res;
          this.defaultList.forEach((el) => {
            el.organizations = this.defaultAvatars;
          });
          this.brand_list = data.length ? data : this.defaultList;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;
  .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;

    .check-more {
      font-size: 12px;
      color: #999999;
    }
  }

  .swiper-view {
    display: flex;
    overflow: hidden;
    padding-left: 16px;
    min-height: 100px;
    &.template1 {
      width: 100%;
      .list-item {
        flex-shrink: 0;
        width: 162px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 12px;
        overflow: hidden;
        box-shadow: 0px 6px 10px 0px #f2f2f2;

        .item-image {
          position: relative;
          width: 100%;
          height: 110px;
          // border-radius: 4px;
        }

        .item-content {
          // width: 100%;
          margin: 8px 10px 11px;
          height: 36px;

          .item-title {
            font-size: 14px;
            color: #353535;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    &.template2 {
      flex-direction: column;
      .list-item {
        display: flex;
        // align-items: center;
        width: 100%;
        // background-color: #fff;
        border-radius: 4px;
        margin-right: 10px;

        .item-image {
          width: 146px;
          flex-shrink: 0;
          position: relative;
          height: 86px;
          border-radius: 4px;
        }

        .item-content {
          margin-left: 10px;
          .item-title {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
            color: #191e29;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .item-desc {
            margin-top: 8px;
            font-size: 12px;
            color: #666666;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &.template3 {
      width: 100%;
      .list-item {
        flex-shrink: 0;
        width: 143px;
        // background-color: #fff;
        // box-shadow: 0px 6px 10px 0px #f2f2f2;
        margin-bottom: 12px;

        .item-image {
          position: relative;
          width: 100%;
          height: 80px;
          border-radius: 4px;
        }

        .item-content {
          width: 100%;
          margin-top: 4px;

          .item-title {
            font-size: 12px;
            font-weight: bold;
            color: #191e29;
            line-height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
// 加载更多样式
.load-more {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  .template1 {
    font-size: 12px;
    color: var(--main-color);
    line-height: 17px;
  }
  .template2 {
    display: inline-block;
    padding: 3px 11px;
    border-radius: 2px;
    border: 1px solid var(--main-color);
    font-size: 12px;
    color: var(--main-color);
    line-height: 17px;
  }
}
</style>
