var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:([
    _vm.myRootStyle,
    {
      padding: "12px 0px 0px",
    } ])},[_c('StListTitle',{style:({
      padding: "0 16px 16px",
    }),attrs:{"config":_vm.myConfig}}),(_vm.myConfig.templateData.styleType === 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-content template1"},_vm._l((_vm.association_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
        marginRight: _vm.myConfig.templateData.dataSpacing + 'px',
        borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
      })},[_c('st-load-image',{staticClass:"item-cover",attrs:{"src":item.icon,"mode":"aspectFit","size":300}}),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"content-title"},[_vm._v(_vm._s(item.name))]),(_vm.myConfig.templateData.showRange)?_c('div',{staticClass:"content-star"},[_vm._l((5),function(i){return _c('img',{key:i,staticClass:"star-icon",class:i <= item.star ? 'stared' : '',attrs:{"src":require("../../assets/images/st-business-association/star-icon.png")}})}),_c('div',{staticClass:"star-text"},[_vm._v(_vm._s(item.star_text))])],2):_vm._e()])],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }