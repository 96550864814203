<!--
 * @Author: dongjia
 * @Date: 2022-01-12 10:01:50
 * @LastEditTime: 2022-01-20 18:22:37
 * @LastEditors: aleaner
 * @Description: 在线学习组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StOnlineLearning.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="{
      padding: `12px ${myStyleType === 'template3' ? '16' : '0'}px`,
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      borderRadius: `${myConfig.normal.borderRadius}px`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage: myBgImage,
    }"
  >
    <StListTitle
      :style="{
        padding: `0 16px 16px`,
      }"
      :config="myConfig"
    />

    <div class="cover-box">
      <img class="cover" :src="StCollegeRecommendListPreview" />
    </div>
  </div>
</template>

<script>
import { getAudioList } from '../../api/components'
import componentMixin from '@/modules/app-design/mixins/component-mixin'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'
import ColorThief from 'colorthief'
import ColorCustomIcon from '@/modules/app-design/components/Normal/ColorCustomIcon'
export default {
  name: 'st-college-recommend-list',
  components: { StLoadImage, ColorCustomIcon },
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      StCollegeRecommendListPreview:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/st-college-recommend-list-preview.png',
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
          borderRadius: 12,
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '在线学习',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          isShowBtn: 1,
          showType: 'template1',
          title: '查看更多',
        },
      },
      dataList: [],
    }
  },
  watch: {

  },
  created() {
    // this.getList()
  },
  methods: {
    // getList(notCancel) {
    //   let ids = []
    //   this.myConfig.dataConfig.dataArr.forEach((el) => {
    //     ids.push(el.id)
    //   })
    //   let requestData = {
    //     page: 1,
    //     tab: 'normal',
    //     limit: this.myLoadingConfig.limit,
    //     use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
    //     template: this.myStyleType,
    //     ids,
    //     order_rule: this.myConfig.dataConfig.orderRule,
    //     category_all: this.myConfig.dataConfig.dataAll,
    //     category_ids: this.myConfig.dataConfig.dataFilter.map((item) => item),
    //     rand_type: this.myConfig.dataConfig.randType,
    //     rand_limit: this.myConfig.dataConfig.randLimit,
    //   }
    //   this.loading = true
    //   getAudioList(requestData, notCancel)
    //     .then((res) => {
    //       const { list } = res.data
    //       this.dataList = list

    //       this.loading = false
    //     })
    //     .catch(() => {
    //       this.loading = false
    //     })
    // },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/component';

.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;

  .cover-box {
    padding: 0 12px;
    .cover {
      width: 100%;
      height: auto;
    }
  }
  .check-btn {
    margin: 12px 12px 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .text {
      color: #45350e;
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      position: relative;
      z-index: 3;
    }
    .btn-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}
</style>
