var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.myConfig)?_c('div',{staticClass:"st-structure flex-col",class:[_vm.myConfig.templateData.styleType],style:({
      backgroundColor:
        _vm.myConfig.templateData.bgType === 'color'
          ? _vm.myConfig.templateData.bgColor
          : '',
      backgroundImage:
        _vm.myConfig.templateData.bgType === 'image'
          ? ("url(" + (_vm.myConfig.templateData.bgImage) + ")")
          : '',
    })},[_c('st-page-set',{attrs:{"config":_vm.myConfig,"jump-config":_vm.jumpConfig,"bg-color":_vm.myConfig.header.bgColor}}),_c('st-search',{attrs:{"config":{ normal: _vm.myConfig.searchConfig }}}),_c('div',{staticClass:"nav-box",style:({ backgroundColor: _vm.myConfig.tabs.bgColor || '#fff' })},[(_vm.myConfig.templateData.styleType === 'template1')?_c('div',{staticStyle:{"height":"8px"}}):_vm._e(),_c('div',{staticClass:"nav-list",style:({
          paddingTop: _vm.tabConfig.normal.navPaddingTop + 'px',
          marginLeft: ((_vm.tabConfig.normal.navRowMargin) + "px"),
          marginRight: ((_vm.tabConfig.normal.navRowMargin) + "px"),
          '--line-width': _vm.tabConfig.normal.underlineWidth || 0,
          '--line-height': _vm.tabConfig.normal.underlineHeight || 0,
          '--line-radius': _vm.tabConfig.normal.underlineBorderRadius || 0,
          '--line-bottom': _vm.tabConfig.normal.underlineBottom || 0,
          '--line-bg': _vm.tabConfig.normal.underlineColor || 'var(--main-color)',
        })},[_vm._l((_vm.myTabs),function(item,index){return _c('div',{key:index,staticClass:"nav-item",class:[index === _vm.currentIndex ? 'active' : ''],style:({
            '--active-color': _vm.myConfig.tabs.fontActiveColor,
            '--inactive-color': _vm.myConfig.tabs.fontInactiveColor,
          })},[(item.leftIcon)?_c('el-image',{staticClass:"nav-image",attrs:{"src":item.leftIcon,"fit":"cover"}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(item.name ? item.name : ("组织" + (_vm.ch_sort[index])))+" "),(index === _vm.currentIndex)?_c('div',{staticClass:"nav-active",style:({
                backgroundColor: _vm.tabConfig.normal.selectedColor,
              })}):_vm._e()]),(item.rightIcon)?_c('el-image',{staticClass:"nav-image",attrs:{"src":item.rightIcon,"fit":"cover"}}):_vm._e()],1)}),(_vm.myConfig.otherConfig.showFilter)?_c('div',{staticClass:"filter",style:({
            backgroundColor: _vm.myConfig.tabs.bgColor || '#fff',
          })},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/st-structure/filter-icon-new.png")}}),_c('span',{staticClass:"text"},[_vm._v("更多")])]):_vm._e()],2)]),(_vm.myConfig.templateData.styleType === 'template1')?[_c('div',{staticClass:"title"},[_vm._v("社团云组织")]),(_vm.myConfig.otherConfig.showTotal)?_c('div',{staticClass:"total"},[_vm._v(" （100 名会员） ")]):_vm._e(),_c('div',{staticClass:"list",style:({
          backgroundColor:
            _vm.myConfig.templateData.contentBgType === 'color'
              ? _vm.myConfig.templateData.contentBgColor
              : '',
          backgroundImage:
            _vm.myConfig.templateData.contentBgType === 'image'
              ? ("url(" + (_vm.myConfig.templateData.contentBgImage) + ")")
              : '',
        })},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"sub-title"},[_vm._v(" 主席"),(_vm.config.otherConfig.showCount && false)?_c('span',{staticClass:"count"},[_vm._v("（1人）")]):_vm._e()]),_c('p',{staticStyle:{"font-size":"14px","margin-top":"10px","text-align":"center"}},[(_vm.myConfig.otherConfig.showIdentity)?[_vm._v(" 个人会员 ")]:_vm._e()],2),_c('div',{staticClass:"scroll-list",style:({
              justifyContent: true ? 'center' : 'flex-start',
              paddingLeft: true ? 0 : '23px',
            })},[_c('div',{staticClass:"scroll-item"},[_c('el-image',{staticClass:"avatar",attrs:{"src":_vm.defaultAvatar}}),_c('span',{staticClass:"name"},[_vm._v("倪景明")])],1)])]),_vm._l((1),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"sub-title"},[_vm._v(" 会员"),(_vm.config.otherConfig.showCount)?_c('span',{staticClass:"count"},[_vm._v("（20人）")]):_vm._e()]),_c('p',{staticStyle:{"font-size":"14px","margin-top":"10px","text-align":"center"}},[(_vm.myConfig.otherConfig.showIdentity)?[_vm._v(" 单位会员 ")]:_vm._e()],2),_c('div',{staticClass:"scroll-list",style:({
              justifyContent: false ? 'center' : 'flex-start',
              paddingLeft: false ? 0 : '23px',
            })},[_c('div',{staticClass:"scroll-item"},[_c('el-image',{staticClass:"avatar",attrs:{"src":_vm.defaultAvatar}}),_c('span',{staticClass:"name"},[_vm._v("顾桂花")])],1),_c('div',{staticClass:"scroll-item"},[_c('el-image',{staticClass:"avatar",attrs:{"src":_vm.defaultAvatar}}),_c('span',{staticClass:"name"},[_vm._v("钊亭晚")])],1),_c('div',{staticClass:"scroll-item"},[_c('el-image',{staticClass:"avatar",attrs:{"src":_vm.defaultAvatar}}),_c('span',{staticClass:"name"},[_vm._v("胡永修")])],1),_c('div',{staticClass:"scroll-item"},[_c('el-image',{staticClass:"avatar",attrs:{"src":_vm.defaultAvatar}}),_c('span',{staticClass:"name"},[_vm._v("吕玉兰")])],1)])])})],2),_c('div',{staticStyle:{"width":"100%","height":"20px"}})]:_c('div',{staticClass:"content"},[(_vm.myConfig.otherConfig.showTotal)?_c('p',{staticClass:"summary"},[_vm._v("共209名会员")]):_vm._e(),_vm._l((_vm.organizations),function(position,pIndex){return _c('div',{staticClass:"single-card",style:({
          backgroundColor:
            _vm.myConfig.templateData.contentBgType === 'color'
              ? _vm.myConfig.templateData.contentBgColor
              : '',
          backgroundImage:
            _vm.myConfig.templateData.contentBgType === 'image'
              ? ("url(" + (_vm.myConfig.templateData.contentBgImage) + ")")
              : '',
        })},[_c('div',{staticClass:"single-card-head flex-between"},[_c('h4',[_vm._v(_vm._s(position.title))]),(_vm.myConfig.otherConfig.showCount && pIndex !== 0)?_c('p',[_vm._v("48名会员")]):_vm._e()]),_c('el-divider'),_c('div',{staticClass:"single-card-body"},[(position.companies)?_c('p',{staticClass:"summary",staticStyle:{"font-size":"14px"}},[(_vm.myConfig.otherConfig.showIdentity)?[_vm._v(" 会员 ")]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"grid"},_vm._l((position.people.slice(
                0,
                _vm.myConfig.otherConfig.maxShowCount
              )),function(person){return _c('div',{staticClass:"person flex-align-center"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.defaultAvatar}}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(person.name))])])}),0),(position.people.length >= 6 && false)?_c('div',{staticClass:"dots flex-center"},[_c('span',{staticClass:"dot active"}),_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"})]):_vm._e(),(position.companies)?_c('p',{staticClass:"summary",staticStyle:{"font-size":"14px","margin-top":"10px"}},[(_vm.myConfig.otherConfig.showIdentity)?[_vm._v(" 会员单位 ")]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"grid2"},_vm._l((position.companies),function(person){return _c('div',{staticClass:"person flex-col flex-align-center"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.defaultIcon}}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(person.name))])])}),0),(position.companies && position.companies.length >= 2 && false)?_c('div',{staticClass:"dots flex-center"},[_c('span',{staticClass:"dot active"}),_c('span',{staticClass:"dot"}),_c('span',{staticClass:"dot"})]):_vm._e()])],1)})],2),_c('div',{staticClass:"fixed-btn-group"},[(_vm.config.otherConfig.showPhoto)?_c('div',{staticClass:"clap"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/st-structure/share2.png")}}),_c('span',{staticClass:"text"},[_vm._v("架构图示")])]):_vm._e(),(_vm.config.shareConfig.showShare)?_c('div',{staticClass:"clap"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/st-structure/share2.png")}})]):_vm._e()])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }