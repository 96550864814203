<!--
 * @Author: dongjia
 * @Date: 2021-06-17 15:47:12
 * @LastEditTime: 2021-06-18 11:29:58
 * @LastEditors: aleaner
 * @Description: 文章详情页文章内容组件
 * @FilePath: \app-design\components\Preview\StArticleContent.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="tip-area" :style="{
      backgroundColor: myConfig.normal.bgColor
    }">
    <div class="content-text">
      <p>文章内容区域</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StArticleContent",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          opacity: 0,
          bgColor: "",
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-area {
  width: 100%;
  height: 212px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
