<template>
  <div class="st-theme-selector el-form-item">
    <theme-item v-for="(item, index) in list" :key="index"
                :arr="item" :active="index === selected"
                @click="selected = index"
    ></theme-item>
  </div>
</template>

<script>
  import ThemeItem from "./ThemeItem";
  export default {
    name: "StThemeSelector",
    components: {ThemeItem},
    props: {
      value: {
        type: Array,
        default: () => ['#3576FF', '#E2EBFF', '#FFFFFF']
      },
    },
    data() {
      return {
        list: [
          ['#3576FF', '#E2EBFF', '#FFFFFF'],
          ['#FF4444', 'rgba(255, 68, 68, 0.15)', '#FFFFFF'],
          ['#0EBB08', 'rgba(14, 187, 8, 0.15)', '#FFFFFF'],
          ['#FDC600', 'rgba(253, 198, 0, 0.15)', '#FFFFFF'],
          ['#65C4AA', '#DDF2EC', '#FFFFFF'],
          ['#FE557C', '#FFE7E8', '#FFFFFF'],
          ['#884CFF', '#EFE6FF', '#FFFFFF'],
          ['#2F2F34', '#EBECF1', '#FFFFFF'],
        ],
        selected: 0,
      }
    },
    watch: {
      selected(val) {
        this.$emit('input', this.list[val])
      },
      value(val) {
        this.setChecked(val)
      }
    },
    created() {
      this.setChecked()
    },
    methods: {
      setChecked(val) {
        val = val || this.value
        for (let i = 0; i < this.list.length; i++) {
          let li = this.list[i];
          let isChecked = true;
          if (li[0] !== val[0]) isChecked = false;
          if (li[1] !== val[1]) isChecked = false;
          if (li[2] !== val[2]) isChecked = false;
          if (isChecked) {
            this.selected = i;
            break;
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.st-theme-selector {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  &:before {
    display: none;
  }
}
</style>
