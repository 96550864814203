<!-- 可自定义icon颜色组件 -->
<template>
  <div
    class="icon"
    :style="{
      width: width + 'px',
      height: height + 'px',
    }"
  >
    <div
      class="custom-icon"
      :style="{
        width: width + 'px',
        height: height + 'px',
        transform: `translateX(-${width}px)${false ? 'translateZ(0)' : ''}`,
        backgroundImage: `url(${icon})`,
        filter: `drop-shadow(${width}px 0 0 ${color})`,
        borderRight:'unset',
      }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "var(--main-color)",
    },
    // 注意不要带单位
    width: {
      type: [String, Number],
      default: 0,
    },
    height: {
      type: [String, Number],
      default: 0,
    },
    // 本地图片要加 require 导入
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
    };
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.icon {
  overflow: hidden;

  .custom-icon {
    /* 外部使用了 transform 可能~对这里有影响 */
    // width: 100%;
    // height: 100%;
    // transform: translateX(-100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: content-box;
  }
}
</style>
