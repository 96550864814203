<!--
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 11:02:35
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-28 13:56:06
-->
<template>
  <div
    class="donation-list"
    :style="{
      padding: `12px 16px 0`,

      marginTop: `${myConfig.normal.marginTop}px`,
    }"
    v-loading="loading"
  >
    <div
      :style="{
        marginLeft: `${myConfig.normal.rowMargin}px`,
        marginRight: `${myConfig.normal.rowMargin}px`,
      }"
    >
      <!-- 组件标题 -->
      <StListTitle :config="myConfig" />
    </div>
    <!-- 模板一 -->
    <template v-if="myConfig.templateData.template === 'template1'">
      <div
        class="donation-swiper"
        :style="{
          margin: `12px 0`,
          padding: '10px 0',
        }"
      >
        <div
          class="content-box"
          :style="{
            padding: `0px ${myConfig.normal.rowMargin}px`,
            overflow: 'hidden',
          }"
          v-for="(item, i) in dataList.slice(0,myConfig.dataLoad.limit)"
          :key="item.id"
        >
          <div
            class="swiper-item template1"
            :style="{
              backgroundColor:
                myConfig.normal.bgType === 'color'
                  ? myConfig.normal.bgColor
                  : '',
              backgroundImage: myBgImage,
            }"
          >
            <div class="item-detail">
              <div class="img">
                <img :src="item.cover" alt="" />
              </div>
              <div class="desc">
                <div class="title">{{ item.name }}</div>
                <div class="time">
                  <img
                    src="../../assets/images/st-donation-list/time.png"
                    alt=""
                  />

                  {{ item.donation_time_text }}
                </div>
              </div>
            </div>
            <div
              class="progress"
              :style="{
                backgroundColor:
                  item.donation_status === 1 ? '#FFEEE2' : '#f8f8f8',
              }"
            >
              <div class="title">
                <img
                  v-if="item.donation_status === 0"
                  class="progress-icon"
                  src="../../assets/images/st-donation-list/Slice-21.png"
                  alt=""
                />
                <img
                  v-else-if="item.donation_status === 1"
                  class="progress-icon"
                  src="../../assets/images/st-donation-list/Slice-19.png"
                  alt=""
                />
                <img
                  v-else-if="item.donation_status === 2"
                  class="progress-icon"
                  src="../../assets/images/st-donation-list/Slice-18.png"
                  alt=""
                />
                <!-- 金币图标 -->
                <img
                  v-if="item.donation_status === 1"
                  class="money-icon"
                  src="../../assets/images/st-donation-list/Slice-22.png"
                  alt=""
                />
              </div>
              <div class="progress-box">
                <div class="figure">
                  <div
                    class="areday"
                    :style="{
                      color: item.donation_status === 1 ? '#FF7B1B' : '#808080',
                    }"
                  >
                    <img
                      v-if="item.donation_status === 1"
                      src="../../assets/images/st-donation-list/icon-aready.png"
                      alt=""
                    />
                    <img
                      v-else
                      src="../../assets/images/st-donation-list/icon-target.png"
                      alt=""
                    />
                    <p>已筹金额：¥{{ item.raised.toLocaleString() }}</p>
                  </div>
                  <div class="target">
                    <img
                      src="../../assets/images/st-donation-list/icon-target.png"
                      alt=""
                    />
                    <p>目标金额：¥{{ item.target_price.toLocaleString() }}</p>
                  </div>
                </div>
                <div class="progress-line">
                  <el-progress
                    v-if="!isNaN(item.progress)"
                    :show-text="false"
                    :percentage="item.progress"
                    :color="item.donation_status === 1 ? '#FF7B1B' : '#808080'"
                  ></el-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 模板二 -->
    <template v-else-if="myConfig.templateData.template === 'template2'">
      <div
        class="donation-swiper"
        :style="{
          margin: `12px 0px`,
          padding: '10px 0',
        }"
      >
        <div
          class="content-box"
          :style="{
            padding: `0px ${myConfig.normal.rowMargin}px`,
            overflow: 'hidden',
          }"
          v-for="(item, i) in dataList.slice(0,myConfig.dataLoad.limit)"
          :key="item.id"
        >
          <div class="swiper-item template2">
            <div class="item-detail">
              <div class="img">
                <img :src="item.cover" alt="" />
              </div>
              <div
                class="desc"
                :style="{
                  backgroundColor:
                    myConfig.normal.bgType === 'color'
                      ? myConfig.normal.bgColor
                      : '',
                  backgroundImage: myBgImage,
                }"
              >
                <div class="start" v-if="item.start_institution.length">
                  <img :src="item.start_institution[0].avatar" alt="" />
                  <span>由 {{ item.start_institution[0].name }} 联合发起</span>
                </div>
                <div class="title">{{ item.name }}</div>
                <div class="time">
                  <img
                    src="../../assets/images/st-donation-list/time.png"
                    alt=""
                  />

                  {{ item.donation_time_text }}
                </div>
                <div
                  class="progress"
                  :style="{
                    backgroundColor:
                      item.donation_status === 1 ? '#FFEEE2' : '#f8f8f8',
                  }"
                >
                  <div class="title">
                    <img
                      v-if="item.donation_status === 0"
                      class="progress-icon"
                      src="../../assets/images/st-donation-list/Slice-21.png"
                      alt=""
                    />
                    <img
                      v-else-if="item.donation_status === 1"
                      class="progress-icon"
                      src="../../assets/images/st-donation-list/Slice-19.png"
                      alt=""
                    />
                    <img
                      v-else-if="item.donation_status === 2"
                      class="progress-icon"
                      src="../../assets/images/st-donation-list/Slice-18.png"
                      alt=""
                    />
                    <!-- 金币图标 -->
                    <img
                      v-if="item.donation_status === 1"
                      class="money-icon"
                      src="../../assets/images/st-donation-list/Slice-22.png"
                      alt=""
                    />
                  </div>
                  <div class="progress-box">
                    <div class="figure">
                      <div
                        class="areday"
                        :style="{
                          color:
                            item.donation_status === 1 ? '#FF7B1B' : '#808080',
                        }"
                      >
                        <img
                          v-if="item.donation_status === 1"
                          src="../../assets/images/st-donation-list/icon-aready.png"
                          alt=""
                        />
                        <img
                          v-else
                          src="../../assets/images/st-donation-list/icon-target.png"
                          alt=""
                        />
                        <p>已筹金额：¥{{ item.raised.toLocaleString() }}</p>
                      </div>
                      <div class="target">
                        <img
                          src="../../assets/images/st-donation-list/icon-target.png"
                          alt=""
                        />
                        <p>
                          目标金额：¥{{ item.target_price.toLocaleString() }}
                        </p>
                      </div>
                    </div>
                    <div class="progress-line">
                      <el-progress
                        v-if="!isNaN(item.progress)"
                        :show-text="false"
                        :percentage="item.progress"
                        :color="
                          item.donation_status === 1 ? '#FF7B1B' : '#808080'
                        "
                      ></el-progress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import componentMixin from "../../mixins/component-mixin";
import { getDonationList } from "../../api/components";

export default {
  name: "StDonationList",
  mixins: [componentMixin],
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          rowMargin: 0,
          bgColor: "#fff",
          bgImage:
            "",
        },
        templateData: {
          template: "template1",
          dataMarginTop: 0,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [{ name: "测试", id: "-1" }],
          projectStatus: [0, 1, 2],
        },
        dataLoad: {
          dataLoadType: "none",
          limit: 10,
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "募捐活动",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showMore: {
          isShow: 1,
          showType: "template2",
          title: "查看更多",
        },
      },
      // 数据列表
      dataList: [
        {
          id: "-1",
          name: "测试捐款数据",
          start_institution: [
            {
              name: "测试支持机构",
              avatar:
                "https://testsaasres.shetuan.pro/cos/qXV4g30AvmQRawYK/images/20221108/45f2d24b4e93cb2dff16f383e95ca726.png",
            },
          ],
          target_price: 99999,
          cover:
            "https://testsaasres.shetuan.pro/cos/qXV4g30AvmQRawYK/images/20221108/9c4c2e411db8d901c189e12a7331f8a7.png",
          donation_status: 1,
          donation_status_text: "进行中",
          donation_time_text: "2022.12.11 - 2023.12.11",
          raised: 0,
          progress: 0,
        },
      ],
      // 加载条
      loading: false,
      // 显示进度条
      showProgress: false,
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  methods: {
    // 获取捐赠数据
    getList() {
      this.loading = true;
      // 默认显示用户筛选的数据
      let postData = {
        status_all: 0,
        status: this.myConfig.dataConfig.projectStatus,
      };
      // 数据类型为用户选择数据
      if (this.myConfig.dataConfig.dataType === "select") {
        let ids = [];
        this.myConfig.dataConfig.dataArr.map((item) => {
          ids.push(item.id);
        });
        // 是否指定数据 0，1
        postData.use_ids = 1;
        // 数据 ids
        postData.ids = ids;
      }
      getDonationList(postData)
        .then((res) => {
          console.log(res);
          if (res.data.length) {
            this.dataList = res.data;
            console.log(res.data);
          } else {
            this.dataList = [
              {
                id: "-1",
                name: "这是测试捐款数据",
                start_institution: [
                  {
                    name: "测试支持机构",
                    avatar:
                      "https://testsaasres.shetuan.pro/cos/qXV4g30AvmQRawYK/images/20221108/45f2d24b4e93cb2dff16f383e95ca726.png",
                  },
                ],
                target_price: 99999,
                cover:
                  "https://s.cn.bing.net/th?id=OHR.YiPeng_ZH-CN0652265903_1920x1080.jpg&rf=LaDigue_1920x1080.jpg",
                donation_status: 1,
                donation_status_text: "进行中",
                donation_time_text: "2022.12.11 - 2023.12.11",
                raised: 0,
                progress: 0,
              },
            ];
          }
          this.loading = false;
          this.showProgress = true;
          // 对进度条做处理
          this.dataList.forEach((item) => {
            // 计算进度条
            item.progress = parseInt((item.raised / item.target_price) * 100);
            // 进度条不能大于一百
            if (item.progress > 100) {
              item.progress = 100;
            }else if (
              parseFloat((item.raised / item.target_price) * 100) > 0 &&
              parseFloat(item.raised / item.target_price < 1)
            ) {
              // 大于0，小于1 的进度通通都是 1
              item.progress = 1
            }
          });
          console.log(this.dataList);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  watch: {
    "myConfig.dataConfig.projectStatus"(val) {
      this.getList();
    },
  },
  mounted() {
    console.log(this.myConfig);
    this.getList();
  },
};
</script>

<style lang="scss" scope>
.donation-list {
  position: relative;
  overflow: hidden;
  .donation-swiper {
    width: 99999px;
    margin-top: 12px;
    overflow: hidden;
    // display: flex;

    .content-box {
      width: 343px;
      margin-right: 8px;
    }
    .swiper-item.template1 {
      margin-bottom: 10px;
      float: left;
      width: 100%;
      height: 208px;
      border-radius: 8px;
      background-color: #fff;
      padding: 16px 12px 14px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

      overflow: hidden;
      background-size: 100% auto;
      background-repeat: repeat-y;
      .item-detail {
        display: flex;
        align-items: center;
        .img {
          width: 100px;
          height: 70px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
          }
        }

        .desc {
          flex: 1;
          margin-left: 9px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            font-size: 16px;
            font-weight: 700;
            font-family: "PingFang SC";
            line-height: 24px;
            height: 48px;
            @include clamp(2);
          }

          .time {
            margin-top: 9px;
            display: flex;
            align-items: center;
            font-size: 10px;
            color: #b3b3b3;
            img {
              margin-right: 4px;
            }
          }
        }
      }

      .progress {
        margin-top: 16px;
        height: 90px;
        border-radius: 8px;
        opacity: 1;
        background: #ffeee2;
        padding: 8px 4px 4px;
        position: relative;
        .title {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0 5px;
          img.progress-icon {
            width: 53px;
            height: 16px;
          }
          img.money-icon {
            margin-top: -18px;
          }
        }
        .progress-box {
          width: calc(100% - 8px);
          position: absolute;
          left: 4px;
          bottom: 4px;
          height: 58px;
          border-radius: 6px;
          opacity: 1;
          background: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(4px);
          padding: 12px 10px;

          .figure {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            font-family: "PingFang SC";
            .areday {
              flex: 1;
              display: flex;
              align-items: center;
              color: #ff7b1b;
              img {
                margin-right: 6px;
              }

              p {
              }
            }

            .target {
              flex: 1;
              display: flex;
              align-items: center;
              color: #808080;
              img {
                margin-right: 6px;
              }

              p {
              }
            }
          }
          .progress-line {
            margin-top: 12px;
          }
        }
      }
    }
    .swiper-item.template2 {
      float: left;
      width: 100%;
      margin-bottom: 10px;

      border-radius: 8px;
      background-color: pink;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      overflow: hidden;

      .item-detail {
        .img {
          height: 114px;
          border-radius: 8px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .desc {
          position: relative;
          height: 217px;
          padding: 15px 12px 17px;
          background-color: #fff;
          border-radius: 8px;
          margin-top: -10px;
          .start {
            display: flex;
            align-items: center;
            border-radius: 48px;
            background: linear-gradient(
              90deg,
              rgba(248, 248, 248, 1) 0%,
              rgba(248, 248, 248, 0) 100%
            );
            img {
              width: 20px;
              height: 20px;
              margin-right: 4px;
              border-radius: 50%;
            }

            span {
              color: #808080;
              font-size: 12px;
            }
          }

          .title {
            padding-top: 15px;
            font-weight: 700;
            font-size: 16px;
            color: #1a1a1a;
            // @include clamp(2);
          }

          .time {
            margin-top: 8px;
            display: flex;
            align-items: center;
            font-size: 10px;
            color: #b3b3b3;
            img {
              margin-right: 4px;
            }
          }
          .progress {
            margin-top: 16px;
            height: 90px;
            border-radius: 8px;
            opacity: 1;
            background: #ffeee2;
            padding: 8px 4px 4px;
            position: relative;
            .title {
              position: relative;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              padding: 0 5px;
              img.progress-icon {
                width: 53px;
                height: 16px;
              }
              img.money-icon {
                margin-top: -18px;
              }
            }
            .progress-box {
              width: calc(100% - 8px);
              position: absolute;
              left: 4px;
              bottom: 4px;
              height: 58px;
              border-radius: 6px;
              opacity: 1;
              background: rgba(255, 255, 255, 0.8);
              backdrop-filter: blur(4px);
              padding: 12px 10px;

              .figure {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                font-family: "PingFang SC";
                .areday {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  color: #ff7b1b;
                  img {
                    margin-right: 6px;
                  }

                  p {
                  }
                }

                .target {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  color: #808080;
                  img {
                    margin-right: 6px;
                  }

                  p {
                  }
                }
              }
              .progress-line {
                margin-top: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
