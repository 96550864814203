<!--
 * @Author: dongjia
 * @Date: 2021-10-16 11:31:39
 * @LastEditTime: 2022-03-30 10:07:19
 * @LastEditors: aleaner
 * @Description: 社区热门话题组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StHotTopic.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="{
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      backgroundColor: myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage:
        myConfig.normal.bgType === 'image' ? `url(${fixImageUrl(myConfig.normal.bgImage, { h: 500 }, true)})` : '',
    }"
  >
    <StListTitle :config="myConfig" />
    <div
      class="list-content"
      :style="{
        minHeight: loading ? '100px' : '0px',
      }"
      :class="myConfig.templateData.styleType"
      v-loading="loading"
    >
      <div class="list-item" v-for="(item, index) in list.slice(0, 4)" :key="index">
        <template v-if="myConfig.templateData.styleType === 'template1'">
          <img class="item-icon" src="../../assets/images/st-hot-topic/topic-icon.png" />
          <div class="item-name">{{ item.name }}</div>
        </template>
        <template v-if="myConfig.templateData.styleType === 'template2'">
          <div class="item-top">
            <div class="item-top-left">#</div>
            <div
              class="item-top-right"
              :style="{
                color: myConfig.normal.titleFontColor,
              }"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            class="item-bottom"
            style="opacity: 0.7"
            :style="{
              color: myConfig.normal.titleFontColor,
            }"
          >
            {{ item.user_count }} 名会员正在讨论
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { hotTopicList } from "../../api/components";
import StListTitle from "@/modules/app-design/components/Preview/Common/StListTitle";

export default {
  name: "StHotTopic",
  components: { StListTitle },
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      defaultList: require("../../assets/jsons/default-list.json").hotTopicList,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "rgba(255, 255, 255, 0)",
          titleFontColor: "rgba(0, 0, 0, 0.5)",
          bgImage: "",
          rowMargin: 0,
        },
        templateData: {
          styleType: "template1",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "热门话题",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
          titleColor: "#FFFFFF",
        },
      },
      list: [],
    };
  },
  watch: {
    // "myConfig.loadingConfig.limit"(val) {
    //   this.getHotTopicList(false);
    // },
    // "myConfig.dataConfig.dataType"(val) {
    //   this.getHotTopicList(false);
    // },
    // "myConfig.dataConfig.dataArr"(val, oldVal) {
    //   if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
    //     this.getHotTopicList(false);
    //   }
    // },
    // "myConfig.dataConfig.dataFilter"(val) {
    //   this.getHotTopicList(false);
    // },
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {
    this.getHotTopicList();
  },
  methods: {
    getHotTopicList(notCancel) {
      // let ids = [];
      // this.myConfig.dataConfig.dataArr.forEach((el) => {
      //   ids.push(el.id);
      // });
      // let requestData = {
      //   page: 1,
      //   limit: this.myConfig.loadingConfig.limit,
      //   category_id: this.myConfig.dataConfig.dataFilter,
      //   use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
      //   template: this.myConfig.templateData.styleType,
      //   ids,
      // };
      this.loading = true;
      hotTopicList()
        .then((res) => {
          const { data } = res;
          this.list = data.length ? data : this.defaultList;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // }, 200),
  },
};
</script>

<style lang="scss" scoped>
div {
  line-height: 1.5;
}
.brand-list {
  padding: 12px 16px;
  background-size: 100% auto;
  background-repeat: repeat-y;
  .list-title {
    padding: 0 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;

    .check-more {
      font-size: 12px;
      color: #999999;
    }
  }

  .list-content {
    &.template1 {
      display: flex;
      flex-wrap: wrap;
      .list-item {
        display: flex;
        align-items: center;
        padding: 8px 11px;
        flex-shrink: 0;
        margin-right: 10px;
        margin-bottom: 9px;
        background: #f5f6f7;
        border-radius: 15px;
        .item-icon {
          width: 14px;
          height: 14px;
        }
        .item-name {
          margin-left: 5px;
          font-size: 14px;
          color: #363636;
          line-height: 14px;
        }
      }
    }

    &.template2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 12px;
      column-gap: 12px;
      .list-item {
        .item-top {
          display: flex;
          align-items: center;
          .item-top-left {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            font-size: 14px;
            color: #fff;
            line-height: 1;
            border-radius: 50%;
            background-color: #ff495f;
          }
          .item-top-right {
            width: 1px;
            flex-grow: 1;
            margin-left: 4px;
            font-size: 14px;
            color: #ffffff;
            @include nowrap();
          }
        }
        .item-bottom {
          margin-left: 24px;
          margin-top: 4px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}
</style>
