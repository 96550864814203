<!--
 * @Author: dongjia
 * @Date: 2021-12-23 18:14:10
 * @LastEditTime: 2021-12-24 11:22:31
 * @LastEditors: aleaner
 * @Description: 商会会刊
 * @FilePath: \app-design\components\Preview\StProceedings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div>
    <st-page-set :title="myConfig.share.title" ></st-page-set>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
export default {
  name: "StProceedings",
  components: {StPageSet},
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
    jumpConfig: Object,
  }, data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        share: {
          title: "",
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
