<!--
 * @Author: dongjia
 * @Date: 2021-10-16 16:40:22
 * @LastEditTime: 2021-10-16 17:44:24
 * @LastEditors: aleaner
 * @Description: 社区帖子列表组件
 * @FilePath: \app-design\components\Preview\StPostList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="nav-content">
    <div class="nav-item" v-for="(item, index) in list" :key="index">
      <div class="item-header">
        <div class="header-left">
          <el-image v-if="item.user.avatar" class="header-avatar" :src="item.user.avatar" fit="cover">
          </el-image>
          <img v-else class="header-avatar" src="../../assets/images/st-me-page/avatar1.png" />
        </div>
        <div class="header-center">
          <div class="center-top">
            <div class="user-name">{{item.user.nickname}}</div>
          </div>
          <div class="center-bottom">
            <div class="user-title-name">{{item.user.title_name}}</div>
          </div>
        </div>
        <div class="header-right">
          <div class="right-btn">+ 关注</div>
          <div class="right-handler">
            <img class="handler-icon" src="../../assets/images/st-post-list/icon_index_more.png" />
          </div>
        </div>
      </div>
      <div class="item-content">
        <div class="content-text">{{item.name}}</div>
        <div class="content-images">
          <div class="item-img" :style="{
            marginRight: index%3 === 2?'0':'7px',
            backgroundColor: !item?'#f7f7f8':''
          }" v-for="(item,index) in item.images" :key="index">
            <el-image v-if="item" class="content-img" :src="fixImageUrl(item, {}, true)" fit="cover"></el-image>
            <img v-else class="default-icon" src="../../assets/images/mini-default-icon@2x.png" />
          </div>
        </div>
        <div class="content-another">
          <div class="content-activity">
            <div class="activity-img" :style="{
            backgroundColor: !item.activity.image?'#f7f7f8':''
          }">
              <el-image v-if="item.activity.image" class="image" :src="fixImageUrl(item.activity.image, {}, true)">
              </el-image>
              <img v-else class="default-icon" src="../../assets/images/mini-default-icon@2x.png" />
            </div>
            <div class="activity-content">
              <div class="activity-name">{{item.activity.name}}</div>
              <div class="activity-time">{{item.activity.time_text}}</div>
            </div>
          </div>
        </div>
        <div class="content-topics">
          <div class="topic-item" v-for="(item, index) in item.topics" :key="index">{{item}}</div>
        </div>
      </div>
      <div class="item-bottom">
        <div class="bottom-left">
          <div class="btn-item">
            <img class="btn-icon" src="../../assets/images/st-post-list/liked.png" />
            <div class="btn-num">{{item.like_num}}</div>
          </div>
          <div class="btn-item">
            <img class="btn-icon" src="../../assets/images/st-post-list/icon_index_comment.png" />
            <div class="btn-num">{{item.comment_num}}</div>
          </div>
          <div class="btn-item">
            <img class="btn-icon" src="../../assets/images/st-post-list/forward.png" />
          </div>
        </div>
        <div class="bottom-right">{{item.created_time}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-content {
  width: 100%;
  min-height: 300px;

  .nav-item + .nav-item {
    margin-top: 8px;
    padding-top: 20px;
  }

  .nav-item {
    padding: 0 18px;
    background-color: #ffffff;

    .item-header {
      display: flex;
      align-items: center;
      .header-left {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        .header-avatar {
          width: 100%;
          height: 100%;
        }
      }

      .header-center {
        margin-left: 12px;

        .center-top {
          display: flex;
          align-items: center;
          .user-name {
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            line-height: 16px;
          }
        }
        .center-bottom {
          margin-top: 8px;
          .user-title-name {
            font-size: 12px;
            color: #969799;
            line-height: 12px;
          }
        }
      }

      .header-right {
        margin-left: auto;
        flex-shrink: 0;
        display: flex;
        align-items: center;

        .right-btn {
          padding: 5px 7px;
          border-radius: 2px;
          border: 1px solid var(--main-color);
          font-size: 12px;
          font-weight: 400;
          color: var(--main-color);
          line-height: 12px;
        }
        .right-handler {
          margin-left: 8px;
          width: 24px;
          height: 24px;
          .handler-icon {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .item-content {
      margin-top: 14px;
      .content-text {
        font-size: 14px;
        color: #000000;
        line-height: 22px;
      }
      .content-images {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;

        .item-img {
          flex-shrink: 0;
          width: 108px;
          height: 108px;
          margin-bottom: 7px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          .content-img {
            width: 100%;
            height: 100%;
          }
          .default-icon {
            width: 28px;
            height: 28px;
          }
        }
      }

      .content-another {
        margin-top: 4px;
        .content-activity {
          width: 100%;
          background-color: #f7f8fa;
          border-radius: 2px;
          display: flex;
          align-items: center;
          padding: 8px;
          .activity-img {
            width: 44px;
            height: 44px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            .image {
              width: 100%;
              height: 100%;
            }
            .default-icon {
              width: 28px;
              height: 28px;
            }
          }
          .activity-content {
            margin-left: 8px;

            .activity-name {
              font-size: 14px;
              color: #000000;
              line-height: 14px;
            }

            .activity-time {
              margin-top: 8px;
              font-size: 12px;
              color: #969799;
              line-height: 12px;
            }
          }
        }
      }

      .content-topics {
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        .topic-item {
          padding: 6px 10px;
          border-radius: 12px;
          border: 1px solid #ebebeb;
          font-size: 12px;
          color: #000000;
          line-height: 12px;
          margin-right: 10px;
          margin-bottom: 8px;
        }
      }
    }

    .item-bottom {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bottom-left {
        display: flex;
        align-items: center;
        .btn-item {
          display: flex;
          align-items: center;
          margin-right: 8px;
          .btn-icon {
            width: 24px;
            height: 24px;
          }
          .btn-num {
            margin-left: 2px;
            font-size: 12px;
            color: #000000;
            line-height: 12px;
          }
        }
      }
      .bottom-right {
        font-size: 12px;
        color: #c8c9cc;
        line-height: 12px;
      }
    }
  }
}
</style>
