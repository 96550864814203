var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-nav",style:({
    marginTop: _vm.myConfig.normal.marginTop + 'px !important',
    marginLeft: ((_vm.myConfig.normal.rowMargin) + "px"),
    marginRight: ((_vm.myConfig.normal.rowMargin) + "px"),
    backgroundColor: _vm.myConfig.normal.bgColor,
  })},[_c('div',{staticClass:"nav-list"},_vm._l((_vm.defaultList),function(item,index){return _c('div',{key:index,staticClass:"nav-item"},[(item.leftIcon)?_c('el-image',{staticClass:"nav-image",attrs:{"src":item.leftIcon,"fit":"cover"}}):_vm._e(),_c('div',{class:['nav-name',index === _vm.currentIndex?'active':''],style:({
      color: index === _vm.currentIndex?_vm.myConfig.normal.selectedColor:_vm.myConfig.normal.defaultColor
    })},[_vm._v(" "+_vm._s(item.name)+" "),(index === _vm.currentIndex)?_c('div',{staticClass:"nav-active",style:({
        backgroundColor:_vm.myConfig.normal.selectedColor
      })}):_vm._e()]),(item.rightIcon)?_c('el-image',{staticClass:"nav-image",attrs:{"src":item.rightIcon,"fit":"cover"}}):_vm._e()],1)}),0),_c('StPostList',{staticClass:"nav-content",style:({
    backgroundColor: _vm.myConfig.normal.contentBgColor,
  }),attrs:{"list":_vm.contentList}}),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"publish-btn"},[_c('img',{staticClass:"publish-icon",attrs:{"src":require("../../assets/images/st-source/publish-icon.png")}})])}]

export { render, staticRenderFns }