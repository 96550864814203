<template>
  <div class="activity-list" :style="[myRootStyle]">
    <StListTitle :config="myConfig" />
    <div v-loading="loading" class="list-view">
      <div class="list-item" :style="{
        marginTop: index !== 0? myConfig.templateData.dataSpacing + 'px':'0',
      }" v-for="(item,index) in activityList" :key="item.id">
        <st-activity-item-gov :activity="item"></st-activity-item-gov>
      </div>
      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{myConfig.loadingConfig.handleText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGovActivities } from "@/modules/app-design/api/components";
import StActivityItemGov from "@/modules/app-design/components/Preview/StActivityItemGov";
import componentMixin from "@/modules/app-design/mixins/component-mixin";

export default {
  name: "StActivityListGov",
  mixins: [componentMixin],
  components: { StActivityItemGov },
  data() {
    return {
      loading: false,
      defaultList: require("../../assets/jsons/default-list.json").activityList,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "rgba(255, 255, 255, 0)",
          bgImage: "",
          rowMargin: 20,
        },
        templateData: {
          styleType: "template1",
          dataSpacing: 15,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          dataFilter: [1],
        },
        loadingConfig: {
          loadingType: "none",
          limit: 10,
          handleType: "template1",
          handleText: "加载更多",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "活动列表",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
        },
      },
      activityList: [],
    };
  },
  created() {
    this.getActivityList();
  },
  watch: {
    "myConfig.loadingConfig.limit"(val) {
      this.getActivityList();
    },
    "myConfig.dataConfig.dataType"(val) {
      this.getActivityList();
    },
    "myConfig.dataConfig.dataArr"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityList();
      }
    },
    "myConfig.dataConfig.dataFilter"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityList();
      }
    },
  },
  methods: {
    getActivityList() {
      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });
      let requestData = {
        page: 1,
        limit: this.myConfig.loadingConfig.limit,
        // statuses: this.myConfig.dataConfig.dataFilter,
        use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
        // template: this.myConfig.templateData.styleType,
        ids,
      };
      this.loading = true;
      getGovActivities(requestData)
        .then((res) => {
          const { data } = res;
          this.activityList = data.length ? data : this.defaultList;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/component";

.activity-list {
  padding: 12px 16px 0;
  background-size: 100% auto;
  background-repeat: repeat-y;

  .list-title {
    padding-bottom: 16px;
  }

  .list-view {
    padding: 0 0 12px;
    min-height: 100px;

    .list-item {
      width: 100%;
      overflow: hidden;
      box-shadow: 0px 6px 10px 0px #f2f2f2;
    }
  }
}

</style>
