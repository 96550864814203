<!--
 * @Author: dongjia
 * @Date: 2021-12-16 10:21:30
 * @LastEditTime: 2021-12-16 11:15:58
 * @LastEditors: aleaner
 * @Description: 文章详情（先行版）
 * @FilePath: \app-design\components\Preview\StArticleDetailAdvance.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div>
    <StPageSet v-if="platform !== 'H5'" :config="headerConfig" />
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
export default {
  components: { StPageSet },
  name: "StArticleDetailAdvance",
  data() {
    return {
      platform: this.$route.params.platform,
      headerConfig: {
        normal: {
          titleConfig: {
            name: "文章详情",
            isShow: 1,
          },
          fontWeight: "normal",
          fontColor: "#000000",
          bgColor: "",
          statusBarColor: "black",
          showHeaderJump: 1,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>