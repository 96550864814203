import store from '@/base/store'

export default {
  getRules(getVm, value, platform, others) {
    // getVm: 在 update 中调用

    return {
      normal: {
        title: '常规设置',
        rules: [
          {
            type: 'StNumberSlider',
            field: 'marginTop',
            native: true,
            display: true,
            props: {
              label: '组件间距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'bgType',
            native: true,
            props: {
              title: '背景设置',
              options: [
                {
                  label: '颜色',
                  value: 'color',
                },
                {
                  label: '图片',
                  value: 'image',
                },
              ],
            },
          },
          {
            type: 'StColorPicker',
            field: 'bgColor',
            title: '背景颜色',
            display: true,
            props: {
              showAlpha: true,
              defaultColor: 'rgba(255, 255, 255, 1)',
            },
            link: ['bgType'],
            update: (val, rule, fApi, init = false) => {
              rule.display = fApi.form.bgType === 'color'
            },
          },
          {
            type: 'SingleMediaWall',
            field: 'bgImage',
            title: '背景图片',
            display: false,
            props: {
              width: 60,
              height: 60,
              addText: '',
            },
            style: {
              float: 'right',
            },
            link: ['bgType'],
            update: (val, rule, fApi, init = false) => {
              rule.display = fApi.form.bgType === 'image'
            },
          },
          {
            type: 'StNumberSlider',
            field: 'rowMargin',
            native: true,
            display: true,
            props: {
              label: '两侧边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'rowPadding',
            native: true,
            props: {
              label: '内侧边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'colPadding',
            native: true,
            props: {
              label: '上下边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'borderRadius',
            native: true,
            props: {
              label: '圆角设置',
              min: 0,
              max: 48,
            },
          },
        ],
      }
    }
  },
}
