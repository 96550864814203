var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:({
    margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
    backgroundColor: _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage:
      _vm.myConfig.normal.bgType === 'image' ? ("url(" + (_vm.fixImageUrl(_vm.myConfig.normal.bgImage, { h: 500 }, true)) + ")") : '',
  })},[_c('StListTitle',{attrs:{"config":_vm.myConfig}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-content",class:_vm.myConfig.templateData.styleType,style:({
      minHeight: _vm.loading ? '100px' : '0px',
    })},_vm._l((_vm.list.slice(0, 4)),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[(_vm.myConfig.templateData.styleType === 'template1')?[_c('img',{staticClass:"item-icon",attrs:{"src":require("../../assets/images/st-hot-topic/topic-icon.png")}}),_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))])]:_vm._e(),(_vm.myConfig.templateData.styleType === 'template2')?[_c('div',{staticClass:"item-top"},[_c('div',{staticClass:"item-top-left"},[_vm._v("#")]),_c('div',{staticClass:"item-top-right",style:({
              color: _vm.myConfig.normal.titleFontColor,
            })},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"item-bottom",staticStyle:{"opacity":"0.7"},style:({
            color: _vm.myConfig.normal.titleFontColor,
          })},[_vm._v(" "+_vm._s(item.user_count)+" 名会员正在讨论 ")])]:_vm._e()],2)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }