<!--
 * @Author: dongjia
 * @Date: 2021-07-28 16:26:25
 * @LastEditTime: 2021-10-13 14:05:09
 * @LastEditors: aleaner
 * @Description: 模板预览弹窗
 * @FilePath: \saas-admin-vue\src\modules\app-design\components\Page\TemplatePreviewDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="" :visible.sync="openDialog" custom-class="my-dialog" fullscreen :show-close="false" class="dialog-vertical"
    append-to-body close-on-click-modal>
    <div class="dialog-container" v-if="previewData">
      <div class="container-left">
        <div class="switch-btn">
          <!--  v-if="previewData.pages.length > 1" -->
          <img v-if="previewData.pages.length > 1" @click="$refs.carousel.prev()"
            class="btn-icon cursor-pointer" src="../../assets/images/before-page.png" />
        </div>
        <div class="left-middle">
          <el-carousel class="carousel" indicator-position="none" :autoplay="false"
            arrow="always" name="index" ref="carousel" @change="indexChange">
            <el-carousel-item class="carousel-item" v-for="(item,index) in previewData.pages" :key="index">
              <span class="page-name">{{item.name}}</span>
              <el-scrollbar class="carousel-img" style="height: calc(100% - 40px);">
                <el-image class="carousel-img" style="height: auto; min-height: 430px; margin: 0;"
                          :src="(item.cover||previewData.cover)"
                          :preview-src-list="[item.cover||previewData.cover]" fit="cover">
                  <span slot="error" class="el-image__error" style="height: 748px;">暂无效果预览</span>
                </el-image>
              </el-scrollbar>
            </el-carousel-item>
          </el-carousel>
          <div v-if="previewData.pages.length > 1" class="indicator">
            <div @mouseenter="$refs.carousel.setActiveItem(num - 1)"
              :class="['indicator-item',current_index+1 === num ?'active':'']"
              v-for="num in previewData.pages.length"></div>
          </div>
        </div>
        <div class="switch-btn">
          <img v-if="previewData.pages.length > 1" @click="$refs.carousel.next()"
            class="btn-icon cursor-pointer" src="../../assets/images/after-page.png" />
        </div>
      </div>
      <div class="container-right" v-if="previewData">
        <div class="flex-col flex-center" style="flex: 1; ">
          <div class="qrcode-box" v-loading="QRCode_loading">
            <el-image class="qrcode-img" :src="QRCode || previewData.mpcode" fit="cover" />
          </div>
          <p class="qrcode-tip">微信扫码预览</p>
        </div>
        <div class="bottom-btn">
          <el-button class="item-btn" size="small" type="primary"
                     :disabled="!previewData.pages.length"
                     @click="useOnePage(previewData.pages[current_index])"
                     :loading="saveLoading">单页使用</el-button>
          <el-button class="item-btn" size="small" type="primary"
                     :disabled="!previewData.pages.length"
                     @click="$emit('init')"
                     :loading="saveLoading">整套使用
          </el-button>
          <el-button class="item-btn" size="small" type="default" @click="openDialog = false"
            :loading="saveLoading">关闭</el-button>
        </div>
      </div>
      <img class="close-btn cursor-pointer" src="../../assets/images/dialog-close.png"
        @click="openDialog = false" />
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  getTemplatePage,
  savePageDetail,
} from '../../api/page'
import {formatConfigData} from '../../assets/js/tool'
import {generateQRCode} from "@/modules/common/api";

export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  inject: ['postData'],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    previewData: {
      type: Object,
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    /* 页面（自定义、系统）详情、全局loading、系统设置列表 */
    ...mapState("appDesign", [
      "pageDetail",
      "loading",
    ]),
  },
  data() {
    return {
      current_index: 0,
      QRCode: '',
      QRCode_loading: false,
      saveLoading: false,
    };
  },
  watch: {
    open(val) {
      if (val) {
        if (!this.QRCode && this.$route.params.platform === 'h5') {
          this.QRCode_loading = true
          generateQRCode(this.previewData.h5_qrcode).then(res => {
            this.QRCode = res.data.qrcode
            this.QRCode_loading = false
          }).catch(err => {
            this.QRCode_loading = false
          })
        }
      }
    }
  },
  methods: {
    // 轮播切换时触发
    indexChange(index) {
      this.current_index = index;
    },
    // 点击单页使用
    useOnePage(data) {
      this.$msgbox
        .confirm(
          // `页面模版会覆盖 “${
          //   this.pageDetail.config.page.props.normal.title ||
          //   this.pageDetail.config.page.props.normal.titleConfig.name
          // }” 页面已有组件，是否继续？`,
          "将新建页面显示当前页面的设计样式和数据",
          "提示",
          {
            type: "warning",
          }
        )
        .then((res) => {
          this.saveLoading = true;
          // this.saveDetail();

          getTemplatePage(data.template_id, data.code).then(res => {
            this.$emit('switch-page', {}, {
              newPage: {
                name: '单页使用',
                pid: '',
                sort : 0,
                config: res.data.config
              },
            })
            this.openDialog = false
            this.saveLoading = false
            this.$store.commit("appDesign/setShowTemplate", false);
          }).catch(err => {
            console.log(err)
            this.saveLoading = false
          })
        })
        .catch((err) => {});
    },
    /**
     * 保存页面配置(自定义页面和系统页面)
     * 更新版本号和数据结构
     **/
    async saveDetail(hideMsg = true) {
      let config = this.previewData.pages[this.current_index];
      if (!config || !this.pageDetail) {
        if (this.loading) this.$store.commit("appDesign/setLoading", false);
        return;
      }
      // 合并数据
      let temp = formatConfigData(config, this.pageDetail.system_page, true)
      let params = {
        ...this.pageDetail,
        config: temp,
        project_id: this.postData.project_id,
      };
      this.$store.commit("appDesign/setLoading", true);
      try {
        let res = await savePageDetail(params);

        // 不允许保存 ,data 返回 false 表示不能保存
        if (!res.data) {
          this.$store.commit("appDesign/setLoading", false);
          // 显示不允许保存弹窗
          this.$confirm(
            "当前小程序正处于「审核」状态，无法预览、保存修改的内容，如需要预览、保存，请先撤回审核",
            "提示",
            {
              // 区分取消和关闭
              distinguishCancelAndClose: true,
              confirmButtonText: "我知道了",
              cancelButtonText: "撤回审核",
              type: "warning",
            }
          )
            .then(() => {})
            .catch((action) => {
              if (action === "cancel") {
                undoCommit().then((res) => {
                  this.$message({
                    type: "success",
                    message: "撤回审核成功",
                  }).catch((err) => {
                    this.$message({
                      type: "error",
                      message: "撤回审核失败",
                    });
                  });
                });
              }
            });
          // 退出
          return;
        }
        
        /* 保存成功，更新备份的数据 */
        this.$store.commit("appDesign/setBackupJson", JSON.stringify(temp));

        let detail = {
          ...params,
          /* 刷新修改时间 */
          update_time: res.data,
        };
        // 如果标题有修改
        let isChangePageTitle = false;
        if (config.page.props?.normal?.titleConfig.name) {
          /* 如果修改了常规设置 - 页面标题，需要刷新页面列表 */
          isChangePageTitle =
            config.page.props.normal.titleConfig.name !==
            this.pageDetail.config.page.props.normal.titleConfig.name;
        }

        // 更新修改时间，随即触发watcher
        this.$store.commit("appDesign/setPageDetail", detail);

        if (!hideMsg) {
          this.$message.success(res.msg);
        }

        if (isChangePageTitle) {
          this.$store.commit("appDesign/setIsRefreshPages", true);
        }
        this.saveError = false;
      } catch (err) {
        this.saveLoading = false;
        // console.log(err);
        // this.saveError = true;
      } finally {
        this.saveLoading = false;
        this.$store.commit("appDesign/setResetPageDetail", true);
        this.$store.commit("appDesign/setLoading", false);
        this.$store.commit("appDesign/setShowTemplate", false);
      }
    },
  },
};
</script>
<style lang="scss">
.el-carousel__arrow {
  display: none;
}
</style>
<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.my-dialog {
    max-height: 920px;
    max-width: 1120px;
    min-width: 1120px;

    .el-dialog__body {
      height: calc(100% - 20px);
    }
  }

  ::v-deep.dialog-vertical .el-dialog {
    border-radius: 4px;
    overflow: hidden;
  }
  ::v-deep.dialog-vertical .el-dialog__header,
  ::v-deep.dialog-vertical .el-dialog__body {
    padding: 0;
  }
}

.dialog-container {
  /*height: 562px;*/
  height: 100%;
  display: flex;
  position: relative;
  margin-top: -20px;

  .container-left {
    padding: 0 27px;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    background: #f0f0f0;

    .switch-btn {
      width: 19px;
      height: 33px;

      .btn-icon {
        width: 100%;
        height: 100%;
      }
    }

    .left-middle {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .carousel {
        padding: 29px 32px 0 32px;
        min-width: 331px;
        width: 100%;
        max-width: 476px;
        /*height: 506px;*/
        height: calc(100% - 29px - 30px);

        ::v-deep.el-carousel__container {
          height: 100%;
        }

        .page-name {
          display: block;
          margin-bottom: 20px;
          text-align: center;
          font-weight: bold;
          font-size: 16px;
        }

        .carousel-item {
          width: 100%;
          height: 100%;

          .carousel-img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .indicator {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .indicator-item + .indicator-item {
          margin-left: 10px;
        }

        .indicator-item {
          width: 8px;
          height: 8px;
          background: #cbcbcb;
          border-radius: 50%;

          &.active {
            background: #3a7aff;
          }
        }
      }
    }
  }
  .container-right {
    /*flex: 1;*/
    width: 450px;
    height: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .qrcode-box {
      width: 230px;
      height: 230px;
      padding: 20px;

      .qrcode-img {
        width: 100%;
        height: 100%;
      }
    }

    .qrcode-tip {
      font-size: 14px;
      color: #000000;
      line-height: 20px;
      margin-top: 11px;
    }

    .bottom-btn {
      width: 100%;
      height: 77px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 33px;
      border-top: 1px solid #ececec;

      .item-btn + .item-btn {
        margin-left: 10px;
      }
      .item-btn {
        width: 108px;
        height: 36px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .close-btn {
    position: absolute;
    width: 19px;
    height: 19px;
    top: 20px;
    right: 20px;
  }
}
</style>
