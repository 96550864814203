<!--
 * @Author: dongjia
 * @Date: 2021-07-30 09:49:55
 * @LastEditTime: 2021-08-13 17:54:06
 * @LastEditors: aleaner
 * @Description: 企微联系系统页
 * @FilePath: \app-design\components\Preview\StCompanyContact.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="system-page">
    <st-page-set title="企微联系" :jump-config="jumpConfig"></st-page-set>
    <div class="page-content" :style="{
      backgroundColor: myConfig.normal.bgColor,
    }" v-if="pluginStatus">
      <img class="page-bg" src="../../assets/images/st-company-contact/bg-img.png" />
      <div class="main-content">
        <p class="content-title" v-if="myConfig.titleConfig.isShow" :style="{
          fontWeight: myConfig.titleConfig.fontWeight,
          fontSize: myConfig.titleConfig.fontSize + 'px',
          color: myConfig.titleConfig.fontColor,
        }">{{myConfig.titleConfig.title}}</p>
        <div class="content-btns" :style="{
          marginTop: myConfig.titleConfig.isShow?'20px':'0'
        }">
          <div class="btn-item" v-if="myConfig.contactMeConfig.isShow && pluginStatus.contact_status">
            <img class="btn-icon" src="../../assets/images/st-company-contact/contact-me.png" />
            <p class="btn-title" v-if="myConfig.contactMeConfig.btnText.isShow" :style="{
          fontWeight: myConfig.contactMeConfig.fontWeight,
          fontSize: myConfig.contactMeConfig.fontSize + 'px',
          color: myConfig.contactMeConfig.fontColor,
        }">{{myConfig.contactMeConfig.btnText.name}}</p>
          </div>
          <div class="btn-item" v-if="myConfig.joinChatConfig.isShow && pluginStatus.group_status">
            <img class="btn-icon" src="../../assets/images/st-company-contact/join-group-chat.png" />
            <p class="btn-title" v-if="myConfig.joinChatConfig.btnText.isShow" :style="{
          fontWeight: myConfig.joinChatConfig.fontWeight,
          fontSize: myConfig.joinChatConfig.fontSize + 'px',
          color: myConfig.joinChatConfig.fontColor,
        }">{{myConfig.joinChatConfig.btnText.name}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPluginStatus } from "../../api/components";
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
export default {
  name: "StCompanyContact",
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    jumpConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        normal: {
          titleConfig: {
            name: "企微联系",
            isShow: 1,
          },
          fontWeight: "normal",
          fontColor: "#000000",
          bgColor: "",
          statusBarColor: "black",
          showHeaderJump: 1,
        },
        titleConfig: {
          isShow: 1,
          title: "企业微信联系方式",
          fontWeight: "normal",
          fontSize: 14,
          fontColor: "#000000",
        },
        contactMeConfig: {
          isShow: 1,
          ID: "",
          btnText: {
            name: "咨询服务",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 14,
          fontColor: "#000000",
        },
        joinChatConfig: {
          isShow: 1,
          ID: "",
          btnText: {
            name: "加入群聊",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 14,
          fontColor: "#000000",
        },
        shareConfig: {
          shareTitle: "",
          image: "",
        },
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/start-top.png"),
        white: require("../../assets/images/white-status-bar.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
      // 插件开通状态数据
      pluginStatus: null,
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {
    getPluginStatus()
      .then((res) => {
        this.pluginStatus = res.data;
      })
      .catch((err) => {});
  },
};
</script>

<style lang="scss" scoped>
.system-page {
  width: 375px;
  min-height: 661px;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
  .start-top-img {
    width: 375px;
    height: 65px;
    background-color: white;
  }

  .page-title {
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -36px;
    font-size: 18px;
  }

  .header-jump {
    position: absolute;

    &.default-back-icon {
      top: 33px;
      left: 11px;
      width: 20px;
      height: 20px;
    }

    &.template-back-icon {
      top: 26px;
      left: 5px;
      width: 78px;
      height: 32px;
    }
  }

  .page-content {
    width: 100%;
    height: 603px;
    // background-color: #4a8fec;
    position: relative;

    .page-bg {
      width: 236px;
      height: 234px;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    .main-content {
      position: relative;
      top: 176px;
      margin: 0 auto;
      width: 240px;
      padding: 18px 0;
      background-color: #fff;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content-btns {
        display: flex;

        .btn-item + .btn-item {
          margin-left: 39px;
        }

        .btn-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .btn-icon {
            width: 32px;
            height: 32px;
          }

          .btn-title {
            margin-top: 13px;
          }
        }
      }
    }
  }
}
</style>
