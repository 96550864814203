<!--
 * @Author: dongjia
 * @Date: 2021-10-08 16:45:29
 * @LastEditTime: 2021-10-11 09:58:20
 * @LastEditors: aleaner
 * @Description: 荣誉证书组件
 * @FilePath: \app-design\components\Preview\StHonorCertificate.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="member-msg" :style="{
    marginTop: myConfig.normal.marginTop + 'px',
    position: 'relative',
  }">
    <StMemberContainer :contentConfig="myConfig.contentConfig" :borderBottomRadius="borderRadius">
      <div class="msg-content">
        <div class="default-content" v-if="myConfig.normal.isShowDefaultPage && list.length === 0">
          <img class="default-img" src="../../assets/images/st-member-index/default-content-icon.png" />
          <div class="default-text">亲～该页面暂时没有内容哦</div>
        </div>
        <div class="content-list" v-if="list.length">
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <img class="item-icon" src="../../assets/images/st-member-index/certificate-icon.png" />
            <div class="item-right">
              <div class="item-type">{{item.certificate_category_text}}</div>
              <div class="item-name">{{item.name}}</div>
              <div class="item-unit">颁发单位：{{item.issuing_unit}}</div>
            </div>
          </div>
        </div>
      </div>
    </StMemberContainer>
  </div>
</template>

<script>
import StMemberContainer from "./StMemberContainer";
export default {
  name: "StHonorCertificate",
  components: { StMemberContainer },
  props: {
    config: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      model: {
        normal: {
          marginTop: 0,
          isShowDefaultPage: 1,
        },
        contentConfig: {
          titleConfig: {
            name: "页面标题",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 14,
          fontColor: "#000000",
        },
      },
      borderRadius: 0,
      list: [
        {
          icon: "",
          name: "年度最佳销售精英",
          certificate_category_text: "会员证书",
          issuing_unit: "年度销售精英单位",
        },
        {
          icon: "",
          name: "年度最佳销售精英",
          certificate_category_text: "会员证书",
          issuing_unit: "年度销售精英单位",
        },
      ],
    };
  },
  watch: {
    "myConfig.normal.marginTop"(val) {
      this.$bus.emit("marginTopChange", { marginTop: val, index: this.index });
    },
  },
  created() {
    this.$bus.on("marginTopChange", (res) => {
      if (res.index - 1 === this.index) {
        this.borderRadius = res.marginTop > 0 ? 4 : 0;
      }
    });
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.member-msg {
  overflow: hidden;
}
.msg-content {
  .default-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
    .default-img {
      width: 210px;
      height: 100px;
    }
    .default-text {
      margin-top: 24px;
      font-size: 14px;
      color: #979da6;
      line-height: 14px;
    }
  }
}

.content-list {
  display: flex;
  flex-wrap: nowrap;
  padding: 15px 0 15px 20px;

  .list-item + .list-item {
    margin-left: 11px;
  }
  .list-item {
    flex-shrink: 0;
    width: 257px;
    height: 82px;
    background: #ecf2ff;
    border-radius: 12px;
    padding: 11px 17px;
    display: flex;
    align-items: center;
    .item-icon {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    .item-right {
      margin-left: 9px;
      .item-type {
        font-size: 12px;
        color: #000000;
        line-height: 14px;
      }
      .item-name {
        margin-top: 2px;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        line-height: 17px;
      }
      .item-unit {
        margin-top: 2px;
        font-size: 12px;
        color: #828282;
        line-height: 20px;
      }
    }
  }
}
</style>