<template>
  <el-tabs class="page-left" tab-position="left" type="card" v-model="pane_name" @tab-click="handleClick">
    <el-tab-pane name="page">
      <h3 slot="label" class="tab-label flex-col"><img class="tab-icon"
          src="../../assets/images/left/manager.png" />页面管理</h3>
      <page-manager class="tab-list" @switch-page="switchPage" @copy-page-detail="$emit('copy-page-config')"
        @copy-page-id="$emit('copy-page-id', $event)" @reset-selected="$emit('input', -1)">
      </page-manager>
    </el-tab-pane>
    <!-- 组件库 -->
    <el-tab-pane name="component-library">
      <h3 slot="label" class="tab-label flex-col"><img class="tab-icon"
          src="../../assets/images/left/library.png" />组件库</h3>
      <page-component-library class="tab-list" :selected="value" :list="list" :setting="setting" />
    </el-tab-pane>
    <el-tab-pane name="system">
      <h3 slot="label" class="tab-label flex-col"><img class="tab-icon"
          src="../../assets/images/left/setting.png" />系统设置</h3>
      <div class="tab-list tab-system" v-loading="loading">
        <div :class="['tab-item', value === item.selectedName?'active':'']" v-for="(item,index) in systemList"
          :key="index" @click="changeSelect(item.selectedName, item.system_page, index)">{{item.name}}</div>
      </div>
    </el-tab-pane>
<!--    <el-tab-pane name="search">-->
<!--      <h3 slot="label" class="tab-label flex-col"><i class="tab-icon el-icon-search" style="font-size: 32px;" />页面搜索</h3>-->
<!--    </el-tab-pane>-->
  </el-tabs>
</template>

<script>
import PageComponentLibrary from "./PageComponentLibrary";
import PageManager from "./PageManager";
import { mapState } from "vuex";

export default {
  name: "PageLeft",
  components: { PageComponentLibrary, PageManager },
  props: {
    // main.selected
    value: {
      type: [Number, String],
    },
    list: {
      type: Array,
      default: () => [],
    },
    setting: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("appDesign", ["systemList", "paneName"]),
    pane_name: {
      get() {
        return this.paneName;
      },
      set(val) {
        this.$store.commit("appDesign/setPaneName", val);
      },
    },
  },
  created() {},
  methods: {
    /**
     * @params{Object} params
     * @params{Object} options
     * */
    switchPage(a, b) {
      this.$emit("switch-page", a, b);
    },
    // 点击系统设置列表
    changeSelect(key, sys, index) {
      this.$emit("switch-page", {
        selectedName: key,
        id: 0,
        system_page: sys,
        index,
      });
    },
    handleClick(e) {
      /* 点击系统设置面板 */
      if (e.paneName === "system") {}
    }
  },
};
</script>

<style lang="scss" scoped>
.page-left {
  height: 100%;
  background-color: #f7f7f7;

  &::v-deep.el-tabs.el-tabs--left {
    position: relative;

    .el-tab-pane {
      overflow-y: auto;
      height: 100%;
      background-color: white;
    }

    .el-tabs__nav {
      border-bottom: none !important;

      border-top: none;
      border-left: none;
    }

    .el-tabs__header.is-left {
      border-bottom: none;
      margin-right: 0;
    }

    .el-tabs__content {
      overflow: visible;
      height: 100%;
    }

    .el-tabs__item {
      height: unset !important;
      line-height: unset;
      width: 80px;
      padding: 0 10px;
      border-left: 2px solid #f7f7f7;

      &:hover {
        color: #303133;
      }

      &.is-active:hover {
        color: #3576ff;
      }

      &.is-left {
        background-color: #f7f7f7;
        border-top-color: #f7f7f7 !important;
        border-right-color: #f7f7f7 !important;
        border-bottom: 1px solid #f7f7f7;

        &:first-child {
          border-top: 1px solid #f7f7f7;
        }
      }

      &.is-left.is-active {
        border-width: 1px;
        border-style: solid;
        border-left: 2px solid #3479ef;
        background-color: white;
        border-top-color: #dcdfe6 !important;
        border-right-color: #dcdfe6 !important;
        border-bottom: 1px solid #dcdfe6 !important;

        &:last-child {
          border-bottom: 1px solid #dcdfe6 !important;
        }
      }
    }
  }

  .tab-label {
    align-items: center;
    padding: 16px 0 8px;
    font-weight: normal;
    font-size: 12px;

    .tab-icon {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;
    }
  }

  .tab-list {
    .tab-item {
      height: 36px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #3a3a3a;
      cursor: pointer;

      &:hover {
        background: #e9f1fd;
        color: #3576ff;
      }

      &.active {
        background: #e9f1fd;
        color: #3576ff;
      }
    }
  }

  .tab-system {
    min-height: 300px;
  }
}
</style>
