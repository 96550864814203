var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"st-channels-live-list",style:([_vm.myRootStyle])},[_c('st-list-title',{staticStyle:{"padding":"12px 16px 0"},attrs:{"config":_vm.myConfig}}),(_vm.myStyleType === 'template1')?_c('div',{staticClass:"list template2"},[_vm._l((_vm.list),function(item,index){return [_c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"cover-box",style:({'border-radius': _vm.myTemplateData.borderRadius + 'px'})},[_c('st-load-image',{attrs:{"src":item.cover,"size":500}}),_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/st-channels/channels-play.png")}}),_c('st-channels-live-status',{staticClass:"tag",attrs:{"text":item.live_status}})],1),_c('div',{staticClass:"title-mask",style:({'border-radius': _vm.myTemplateData.borderRadius + 'px'})}),_c('div',{staticClass:"text clamp"},[_vm._v(_vm._s(item.theme))])])]})],2):(_vm.myStyleType === 'template2')?_c('div',{staticClass:"list template3"},[_c('div',[_vm._l((_vm.list),function(item,index){return [(index === 0)?_c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"bg-white",style:({
          'border-radius': _vm.list.length > 1 ? '6px 6px 0 0' : '6px'
        })}),_c('div',{staticClass:"bg-filter-box",style:({
          backgroundImage: ("url(" + (_vm.fixSpecialImageUrl(item.cover, 'large', index)) + ")")
        })},[_c('div',{staticClass:"bg-filter"}),_c('div',{staticClass:"bg-mask"})]),_c('div',{staticClass:"cover-box"},[_c('st-load-image',{attrs:{"src":item.cover,"size":200}}),_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/st-channels/channels-play.png")}})],1),_c('div',{staticClass:"content-box"},[_c('span',{staticClass:"title clamp2"},[_c('st-channels-live-status',{staticClass:"tag",attrs:{"text":item.live_status}}),_vm._v(" "+_vm._s(item.theme))],1),_c('div',{staticClass:"number-box"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/st-channels/channels-eye.png")}}),_c('span',{staticClass:"number"},[_vm._v(_vm._s(item.view_count))])])])]):[_c('div',{key:index,staticClass:"item"},[(item.live_status && item.live_status.indexOf('直播') !== -1)?[_c('img',{staticClass:"status",attrs:{"src":require("../../assets/images/st-channels/living.gif")}})]:_vm._e(),_c('span',{staticClass:"title clamp"},[_vm._v(_vm._s(item.theme))]),_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/st-channels/channels-play-white.png")}})],2)]]})],2)]):(_vm.myStyleType === 'template3')?_c('div',{staticClass:"list template4"},[_vm._l((_vm.list),function(item,index){return [_c('div',{key:index,staticClass:"item",style:({
          'border-radius': _vm.myTemplateData.borderRadius + 'px'
        })},[_c('div',{staticClass:"cover-box",style:({
          'border-radius': _vm.myTemplateData.borderRadius + 'px'
        })},[_c('st-load-image',{attrs:{"src":item.cover,"size":500}}),_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/images/st-channels/channels-play.png")}}),_c('st-channels-live-status',{staticClass:"tag",attrs:{"text":item.live_status}})],1),_c('div',{staticClass:"title-mask",style:({
          'border-radius': _vm.myTemplateData.borderRadius + 'px'
        })}),_c('div',{staticClass:"text clamp"},[_vm._v(_vm._s(item.theme))])])]})],2):_vm._e(),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }