<template>
  <div class="st-share">
    <!-- 状态栏 -->
    <img class="status-bar" src="../../assets/images/phone-statusbar1@2x.png" />
    <div class="nav-bar">
      <i class="icon el-icon-arrow-left"></i>
      <div class="nav-title">页面分享预览</div>
      <i class="icon el-icon-more"></i>
    </div>
    <div class="msg-list">
      <div class="msg-item">
        <div class="avatar">
          <!--          <img src="../../assets/images/st-share/Avatar@2x.png"/>-->
          <el-image fit="contain" :src="require('../../assets/images/st-share/Avatar@2x.png')"></el-image>
        </div>
        <div class="content">
          <div class="nickname">社团云演示</div>
          <div class="card">
            <div class="p-title">
              <img class="p-icon" src="../../assets/images/st-theme/icon.png" />
              小程序名称
            </div>
            <div class="title">{{config.normal.title || '小程序名称'}}</div>
            <div v-if="!config.normal.image" class="share-image">
              <img src="../../assets/images/st-theme/placeholder.png" />
            </div>
            <div v-else class="share-image full">
              <img class="full" :src="config.normal.image" />
            </div>
            <div class="foot">
              <img width="12" src="../../assets/images/st-share/mini.png" />
              小程序
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-bar">
      <div class="left-circle"></div>
      <div class="middle-rect"></div>
      <div class="right-circle"></div>
      <div class="right-circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StShare",
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.st-share {
  width: 100%;
  height: 667px;
  position: relative;
  overflow: hidden;
  background-color: rgba(237, 237, 237, 1);

  .status-bar {
    width: 100%;
    position: absolute;
    top: 0;
  }

  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 16px 12px;
    background: #ededed;
    border-bottom: 1px solid #dcdfe6;

    .icon {
      font-size: 24px;
    }
  }

  .msg-list {
    margin-top: 64px;

    .msg-item {
      display: flex;
      padding: 0 12px;

      .avatar {
        width: 45px;
        height: 45px;
        /*background: rgba(55, 33, 104, 1);*/
        border-radius: 50%;
        margin-right: 11px;

        img,
        .el-image {
          /*width: 100%;*/
          /*height: 100%;*/
          width: 45px;
          height: 45px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        width: 236px;

        .nickname {
          font-size: 12px;
          color: #6f6f6f;
          line-height: 12px;
          margin-bottom: 3px;
        }

        .card {
          width: 236px;
          background: #ffffff;
          border-radius: 4px;
          padding: 12px 12px 2px;
        }

        .p-title {
          font-size: 12px;
          color: #888888;
          line-height: 12px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .p-icon {
            width: 20px;
            margin-right: 5px;
          }
        }

        .title {
          color: #3a3a3a;
          margin-bottom: 10px;
          font-size: 15px;
          line-height: 20px;
          max-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }

        .share-image {
          width: 210px;
          height: 168px;
          background-color: #f3f5f7;
          display: flex;
          margin-bottom: 12px;
          overflow: hidden;

          img {
            margin: auto;
            width: 28px;
          }

          &.full {
            background-color: white;
            justify-content: center;

            img {
              height: auto;
              width: 100%;
            }
          }
        }

        .foot {
          font-size: 12px;
          color: #888888;
          line-height: 12px;
          padding: 5px 0 7px;
          border-top: 1px solid #dcdfe6;
          display: flex;
          align-items: center;

          img {
            background-color: $image-placeholder;
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .fixed-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 375px;
    background: #f6f6f6;
    position: absolute;
    bottom: 0;
    padding: 9px 10px 6px;

    .left-circle,
    .right-circle {
      background: #d8d8d8;
      border-radius: 50%;
      width: 28px;
      height: 28px;
    }

    .middle-rect {
      width: 240px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
    }
  }
}
</style>
