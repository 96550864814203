<!--
 * @Author: dongjia
 * @Date: 2021-07-26 16:17:36
 * @LastEditTime: 2021-07-26 16:49:02
 * @LastEditors: aleaner
 * @Description: 九宫格按钮（图片位置专属）
 * @FilePath: \app-design\components\Form\StNineBoxBtn.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="nine-box">
    <div class="box-item cursor-pointer" :class="value === item?'active':''" v-for="(item,index) in options"
      :index="item" @click="() => {value = item}">
    </div>
  </div>
</template>

<script>
export default {
  name: "StNineBoxBtn",
  model: {
    prop: "position",
    event: "change",
  },
  props: {
    position: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {
      options: [
        "top left",
        "top",
        "top right",
        "center left",
        "center",
        "center right",
        "bottom left",
        "bottom",
        "bottom right",
      ],
    };
  },
  computed: {
    value: {
      get() {
        return this.position;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.nine-box {
  border: 1px solid #e5e5e5;
  display: flex;
  flex-wrap: wrap;
  width: 65px;

  .box-item {
    flex-shrink: 0;
    width: 21px;
    height: 21px;
    border: 1px solid #e5e5e5;
    &:hover {
      background: #a3c1f5;
    }

    &.active {
      background: #3479ef !important;
      border: 1px solid #e5e5e5;
    }
  }
}
</style>