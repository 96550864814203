<template>
  <div class="quick-join">
    <!-- 
    
    {{myConfig.normal.buttonFirstText}}
    {{myConfig.normal.buttonSecondText}} -->
    <div class="popup">
      <div class="popup-title">{{ myConfig.normal.title }}</div>
      <div class="popup-content">{{ myConfig.normal.customContent }}</div>
      <div class="button-group">
        <div class="item">{{ myConfig.normal.buttonFirstText }}</div>
        <div
          class="item"
          :style="{
            color: '#586c94',
          }"
        >
          {{ myConfig.normal.buttonSecondText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'
export default {
  name: 'StChatMsg',
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      platform: this.$route.params.platform,
      model: {
        normal: {
          title: '快捷入会',
          customContent: '弹窗内容',
          buttonFirstText: '取消',
          buttonSecondText: '快捷入会',
        },
      },
      // 状态栏图片
      statusBar: {
        black: require('../../assets/images/phone-statusbar1@2x.png'),
        white: require('../../assets/images/phone-statusbar2@2x.png'),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require('../../assets/images/wechat-handle1@2x.png'),
        white: require('../../assets/images/wechat-handle2@2x.png'),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require('../../assets/images/middle/Back1@2x.png'),
          template2: require('../../assets/images/middle/homeBack1@2x.png'),
          template3: require('../../assets/images/middle/moreBack1@2x.png'),
          template4: require('../../assets/images/middle/moreBack1@2x.png'),
        },
        white: {
          template1: require('../../assets/images/middle/Back2@2x.png'),
          template2: require('../../assets/images/middle/homeBack2@2x.png'),
          template3: require('../../assets/images/middle/moreBack2@2x.png'),
          template4: require('../../assets/images/middle/moreBack2@2x.png'),
        },
      },
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss" scoped>
.quick-join {
  position: relative;
  width: 100%;
  height: 667px;
  background-color: #666;

  .popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    padding: 24px 0 12px;
    border-radius: 10px;
    background-color: #fff;
    .popup-title {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .popup-content {
      padding: 0px 24px 0px;

      margin-top: 12px;
      font-size: 14px;
      color: #a6a6a6;
      text-align: center;
    }

    .button-group {
      display: flex;
      align-items: center;
      margin-top: 24px;
      .item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
</style>
