<template>
  <div class="plug-view">
    <el-image
      class="view-bg"
      :src="`${baseImgUrl}/app_design/st-live-plugin/live-plugin.png`"
      fit="cover"
    ></el-image>
  </div>
</template>

<script>
export default {
  name: 'StLivePlugin',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      baseImgUrl: '',
    }
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss" scoped>
.plug-view {
  width: 100%;
  height: 213px;
  .view-bg {
    width: 100%;
    height: 100%;
  }
}
</style>
