<template>
  <div class="st-nav st-page-set" :style="{
    backgroundColor: platform === 'h5' ? '#eaeaea' : (bgColor || 'transparent')
  }">
    <div class="header" :style="{
			opacity: isShowFixedNav ? 0 : 1,
			backgroundImage: `url(${platform === 'h5' ? statusBar.black : statusBar[myConfig.normal.statusBarColor]})`
		}">
      <div class="header-content">
        <div class="left">
          <i v-if="platform === 'h5'" class="el-icon-close" style="font-size: 24px;"></i>
          <!-- 左侧胶囊 -->
          <img v-else-if="showLeft && myConfig.normal.showHeaderJump"
            :class="[(myJumpConfig.showConfig.showType !== 'template1')?'template-back-icon':'default-back-icon']"
            :src="backIcon[myConfig.normal.statusBarColor][myJumpConfig.showConfig.showType]" />
        </div>
        <div v-if="title || myConfig.normal.titleConfig.isShow" class="title" :style="{
          color: platform === 'h5'? 'black' : myConfig.normal.fontColor,
          fontWeight: myConfig.normal.fontWeight,
          fontSize: myConfig.normal.fontSize + 'px'
        }">
          <span>{{ myConfig.normal.statusBarColor === 'black' || platform === 'h5' ? myTitle : ' '}}</span>
          </div>
        <div class="right"><img v-if="platform !== 'h5'" class="handle-icon"
            :src="handleIcon[myConfig.normal.statusBarColor] || require('../../assets/images/wechat-handle1@2x.png')" />
          <i v-else class="el-icon-more more-icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "st-page-set",
  props: {
    title: {
      type: String,
    },
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
    jumpConfig: {
      type: Object,
      default: null,
    },
    bgColor: {
      type: String,
    },
    showLeft: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowFixedNav: false,
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
      /* 头部右侧胶囊样式图标 */
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      platform: this.$route.params.platform,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          titleConfig: {
            name: "页面设置",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 18,
          fontColor: "#000000",
          statusBarColor: "black",
          paddingBottom: 0,
          showHeaderJump: 1,
        },
      },
      jumpModel: {
        "showConfig": {
          "showType": "template1"
        },
      }
    };
  },
  computed: {
    ...mapState("appDesign", ["systemList", "pageDetail"]),
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    myJumpConfig() {
      return this.$mergeModel(this.jumpModel, this.jumpConfig);
    },
    myTitle () {
      if (this.title) return this.title;
      if (this.myConfig && this.myConfig.normal) return this.myConfig.normal.titleConfig.name;
      let pageDetail = this.pageDetail;
      if (pageDetail && pageDetail.config && pageDetail.config.page && pageDetail.config.page.title) return pageDetail.config.page.title;
      if (pageDetail.name) return pageDetail.name
      return '页面标题'
    }
  },
  created() {
    // console.log('st-nav', this.config, JSON.stringify(this.config));
  },
};
</script>

<style lang="scss" scoped>
.st-nav {
  width: 100%;
  overflow: hidden;

  .header {
    position: relative;
    padding: 20px 11px 0;
    transition: opacity 0.5s;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    z-index: 2;

    .header-content {
      height: 44px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .default-back-icon {
        width: 20px;
        height: 20px;
      }

      .template-back-icon {
        width: 78px;
        height: 32px;
      }

      .left .template-back-icon {
        position: absolute;
      }

      .left,
      .right {
        width: 24px;
      }

      div {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .title {
        max-width: 160px;
        text-align: center;

        span {
          @include nowrap();
        }
      }

      .back-icon {
        width: 20px;
        height: 20px;
        margin-right: auto;
      }

      .handle-icon,
      .more-icon {
        position: absolute;
        right: 0;
      }

      .more-icon {
        right: 6px;
      }

      .handle-icon {
        width: 87px;
        height: 32px;
        margin-left: auto;
      }
    }

    .type-select {
      position: absolute;
      top: 23px;
      width: 100%;
      padding: 0 18px;
      display: flex;
      justify-content: center;

      &.simple {
        position: static;
        display: none;
      }

      .type-item + .type-item {
        margin-left: 18px;
      }
      .type-item {
        display: flex;
        align-items: center;
        height: 34px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        line-height: 34px;
        .active {
          border-bottom: 2px #fff solid;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
        }
        .select-icon {
          width: 16px;
          height: 16px;
          margin-left: 2px;
        }
      }
    }
  }
}
</style>
