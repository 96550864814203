<!--
 * @Author: dongjia
 * @Date: 2021-06-22 17:12:25
 * @LastEditTime: 2021-06-22 18:02:30
 * @LastEditors: aleaner
 * @Description: 带文案提示的选择组件
 * @FilePath: \app-design\components\Form\StTipsSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="selector">
    <el-select
      class="select-input"
      v-model="value"
      placeholder="请选择"
      :multiple="multiple"
      :collapseTags="collapseTags"
    >
      <el-option
        v-for="(item, index) in ListOptions"
        :key="index"
        :value="item.value"
        :label="item.name"
        :disabled="item.disabled"
      >
      </el-option>
    </el-select>
    <p class="form-tips" v-if="formTip">提示：{{ formTip }}</p>
  </div>
</template>

<script>
import {getProjectsTypes} from "../../api/components";

export default {
  name: "StBusinessListSelector",
  model: {
    prop: "selected",
    event: "change",
  },
  data() {
    return {
      ListOptions: [
        {
          value: 0,
          label: "测试1",
        },
        {
          value: 1,
          label: "测试2",
        },
        {
          value: 2,
          label: "测试3",
        },
      ],
    };
  },
  props: {
    selected: {
      type: [String, Number, Array],
      default: [],
    },
    options: {
      type: Array,
      default: () => [],
    },

    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 多选时是否折叠选中选项
    collapseTags: {
      type: Boolean,
      default: false,
    },
    formTip: {
      type: String,
      default: "",
    },
  },
  computed: {
    value: {
      get() {
        return this.selected;
      },
      set(val) {
        console.log(val,this.selected);
        this.$emit("change", val);
      },
    },
  },
  mounted() {
    getProjectsTypes().then(res => {
      this.ListOptions = res.data
    })
  }
};
</script>

<style lang="scss" scoped>
.selector {
  .select-input {
    width: 100%;
  }
  .form-tips {
    text-align: justify;
    line-height: 16px;
    font-size: 13px;
    color: #9d9ea0;
    padding-top: 7px;
    // .tip-icon {
    //   font-size: 15px;
    //   margin-right: 5px;
    // }
  }
}
</style>
