<template>
  <div class="st-analysis-activity st-analysis-item">
    <div
      class="overview-bg"
      :style="{
        backgroundImage: `url(${baseImgUrl}/app_design/st-analysis-page/activity-joined.png)`,
      }"
    >
      <img
        style="visibility: hidden"
        class="overview"
        :src="`${baseImgUrl}/app_design/st-analysis-page/activity-joined.png`"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StAnalysisActivityJoined',
  props: {
    config: Object,
  },
  data() {
    return {
      baseImgUrl:''
    }
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
}
</script>

<style lang="scss" scoped>
.st-analysis-item {
  overflow: hidden;

  .overview {
    width: 100%;
    display: block;
  }

  .overview-bg {
    width: 343px;
    margin: -1px auto 8px;
    background-position: top center;
    background-size: calc(100% + 2px);
    background-repeat: no-repeat;
    border-radius: 6px;
    overflow: hidden;
  }
}
</style>
