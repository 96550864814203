<!--
 * @Author: dongjia
 * @Date: 2022-03-01 15:29:00
 * @LastEditTime: 2022-03-02 17:15:10
 * @LastEditors: aleaner
 * @Description: 社区导航帖子列表
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\st-community-nav\NavPostList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="post-list" :style="{
    backgroundColor: bgColor,
    padding: normal ? `${normal.colPadding}px ${normal.rowPadding}px` : '',
    '--data-spacing': listStyle ? `${listStyle.dataSpacing}px` : '12px',
    '--border-radius': listStyle ? `${listStyle.borderRadius}px` : '6px',
  }">
    <div class="list-title" v-if="showTitle">{{title}}</div>
    <div class="list-item" :class="{'topic-center':item.isTopicCenter === 1}"
      :key="index" :style="{
        backgroundColor: listStyle ? listStyle.bgColor : ''
      }" v-for="(item, index) in postList">
      <template v-if="item.isTopicCenter">
        <TopicCenter />
      </template>
      <template v-else>
        <div class="item-top">
          <img class="item-avatar" src="../../../assets/images/st-me-page/avatar1.png" />
          <div class="author-msg">
            <div class="author-name">{{item.author.nickname}}</div>
            <div class="author-position">{{item.author.title_name}}</div>
          </div>
          <div :class="{isFollow: item.is_follow}" class="follow-btn" v-if="showConfig.showFollow !== 0">
            {{!item.is_follow?'关注':item.is_follow === 1?'已关注':'相互关注'}}</div>
          <img class="item-handler" src="../../../assets/images/st-community-nav/post-control-icon.png" />
        </div>
        <div class="item-content">{{item.content}}</div>
        <div class="item-other-content" v-if="showOtherContent(item)">
          <div v-if="item.type === 0" class="image-list" :class="{grid: item.images.length > 1}">
            <div class="image-item" :class="item.images.length === 1?'bigImg':'normal'"
              v-for="(img,i) in item.images.slice(0, 6)">
              <img class="default-icon" src="../../../assets/images/mini-default-icon@2x.png" />
              <div class="image-mask" v-if="item.images.length > 6 && i === 5">+ {{item.images.length - 6}}
              </div>
            </div>
          </div>
          <div class="content-video" v-if="item.type === 1">
            <img class="default-icon" src="../../../assets/images/mini-default-icon@2x.png" />
            <img class="video-play-icon" src="../../../assets/images/video-play.png" />
          </div>
        </div>
        <div class="item-tags">
          <div class="tag group">
            <img class="tag-icon"
              src="../../../assets/images/st-community-nav/group-icon.png" />{{item.group_name}}
          </div>
          <div class="tag topic" v-for="(topic,i) in item.topics_name" :key="i">
            <img class="tag-icon" src="../../../assets/images/st-community-nav/topic-icon.png" />{{topic}}
          </div>
        </div>
        <div class="item-bottom">
          <div class="bottom-left">
            <div class="bottom-item">
              <img class="item-icon"
                :src="item.is_like?require('../../../assets/images/st-community-nav/post-like-icon.png'):require('../../../assets/images/st-community-nav/post-unlike-icon.png')" />
              {{item.likes_count}}
            </div>
            <div class="bottom-item">
              <img class="item-icon" src="../../../assets/images/st-community-nav/comment-icon.png" />
              {{item.comments_count}}
            </div>
            <div class="bottom-item">
              <img class="item-icon" src="../../../assets/images/st-community-nav/share-icon.png" />
              2
            </div>
          </div>
          <div class="bottom-right">{{item.create_time}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const defaultList =
  require("../../../assets/jsons/default-list").StCommunityNavPost;

import TopicCenter from "./TopicCenter";
export default {
  name: "NavPostList",
  components: { TopicCenter },
  props: {
    bgColor: {
      type: String,
      default: "#FFFFFF",
    },
    listStyle: Object,
    normal: Object,
    showConfig: Object,
    showTopicCenter: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "全部",
    },
  },
  data() {
    return {
      defaultList,
    };
  },
  computed: {
    postList() {
      let default_list = JSON.parse(JSON.stringify(defaultList));
      if (!this.showTopicCenter) {
        default_list.splice(
          default_list.findIndex((el) => el.isTopicCenter === 1),
          1
        );
      }
      return default_list;
    },
  },
  methods: {
    showOtherContent(item) {
      return (
        (item.type === 0 && item.images.length) ||
        (item.type === 1 && item.video.length)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.post-list {
  padding: 16px;
  .list-title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #1a1a1a;
    line-height: 1.5;
  }
  .list-item + .list-item {
    margin-top: var(--data-spacing);
  }
  .list-item {
    background-color: #ffffff;
    border-radius: var(--border-radius);
    padding: 12px;

    &.topic-center {
      background-color: transparent;
      padding: 0;
    }
    .item-top {
      display: flex;
      align-items: center;
      .item-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex-shrink: 0;
      }
      .author-msg {
        flex: 1;
        margin-left: 8px;
        .author-name {
          font-size: 14px;
          font-weight: bold;
          color: #1a1a1a;
          line-height: 1.5;
        }
        .author-position {
          font-size: 12px;
          color: #b3b3b3;
          line-height: 18px;
          @include clamp(1);
        }
      }
      .follow-btn {
        flex-shrink: 0;
        padding: 3px 8px;
        border: 1px solid var(--main-color);
        border-radius: 20px;
        color: var(--main-color);
        font-size: 12px;
        line-height: 1.5;
        &.isFollow {
          border: 1px solid #b3b3b3;
          color: #b3b3b3;
        }
      }
      .item-handler {
        width: 16px;
        height: 16px;
        margin-left: 16px;
        flex-shrink: 0;
      }
    }
    .item-content {
      margin-top: 12px;
      font-size: 14px;
      color: #1a1a1a;
      line-height: 1.5;
      @include clamp(2);
    }
    .item-other-content {
      margin-top: 12px;
      .image-list {
        &.grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          row-gap: 8px;
          column-gap: 8px;
        }

        .image-item {
          width: 100%;
          position: relative;
          border-radius: 6px;
          background-color: #f7f7f8;
          overflow: hidden;
          &.bigImg {
            @include fixed-ratio-box(75%);
          }
          &.normal {
            @include fixed-ratio-box(100%);
          }
          .image-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            line-height: 1;
            background-color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .content-video {
        border-radius: 6px;
        background-color: #f7f7f8;
        overflow: hidden;
        @include fixed-ratio-box(56.5%);
        .video-play-icon {
          position: absolute;
          width: 24px;
          height: 24px;
          bottom: 16px;
          right: 16px;
        }
      }
      .default-icon {
        width: 27px;
        height: 27px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .item-tags {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      line-height: 1;
      .tag {
        flex-shrink: 0.5;
        padding: 5px 8px;
        border-radius: 17px;
        display: flex;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 12px;
        .tag-icon {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          margin-right: 4px;
        }
        &.group {
          background-color: rgba(27, 118, 255, 0.1);
          color: #1b76ff;
        }
        &.topic {
          background-color: #fafafa;
          color: #808080;
        }
      }
    }
    .item-bottom {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bottom-left {
        display: flex;
        align-items: center;
        .bottom-item + .bottom-item {
          margin-left: 12px;
        }
        .bottom-item {
          width: 60px;
          display: flex;
          font-size: 12px;
          color: #808080;
          line-height: 1;
          .item-icon {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            margin-right: 4px;
          }
        }
      }
      .bottom-right {
        font-size: 12px;
        color: #b3b3b3;
        line-height: 1;
      }
    }
  }
}
</style>