var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-bottom-nav"},[(_vm.index === 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"tip-area",style:({
      backgroundColor: 'white',
      'min-height':
        _vm.myConfig.templateData.styleType === 'template1' ? '553px' : '603px',
    })},[_vm._t("default"),_vm._l((_vm.tabList),function(item,index){return [(item.jump_link)?[(item.jump_link.jump_config)?[_c('page-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex === index),expression:"currentIndex === index"}],key:item.name,attrs:{"id":item.jump_link.jump_config.view.system_page
                ? 0
                : item.jump_link.jump_config.view.id,"init":_vm.currentIndex === index,"jump_link":item.jump_link,"system_page":item.jump_link.jump_config.view.system_page,"from":"st-bottom-nav"},on:{"ready":function($event){_vm.loading = !$event}}})]:[_c('div',{staticClass:"tip-area",style:({
              backgroundColor: _vm.myConfig.normal.contentBgColor,
            })},[_vm._m(0,true)])]]:[_c('div',{staticClass:"tip-area",style:({
            backgroundColor: _vm.myConfig.normal.contentBgColor,
          })},[_vm._m(1,true)])]]})],2):_vm._e(),(_vm.myConfig.templateData.styleType === 'template1')?_c('div',{staticClass:"bottom-nav fixed",style:({
      backgroundColor:
        _vm.myConfig.templateData.bgType === 'color'
          ? _vm.myConfig.templateData.bgColor
          : '',
      backgroundImage:
        _vm.myConfig.templateData.bgType === 'image'
          ? ("url(" + (_vm.myConfig.templateData.bgImage) + ")")
          : '',
    })},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"nav cursor-pointer",style:({
        backgroundColor:
          (_vm.value === -1 && index === 0) || index === _vm.value
            ? _vm.myConfig.templateData.selectedBgColor
            : '',
      }),on:{"click":function($event){return _vm.handleActive(index)}}},[(_vm.myConfig.templateData.contentType !== 'text')?[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex === index),expression:"currentIndex === index"}],staticClass:"nav-logo",class:[
            _vm.myConfig.templateData.contentType === 'icon' ? 'logo-only' : '' ],style:({
            width: _vm.myConfig.templateData.fillBg ? '100%' : '25px',
            objectFit: _vm.myConfig.templateData.fillBg ? 'cover' : 'contain',
            flex: _vm.myConfig.templateData.fillBg ? 1 : '',
          }),attrs:{"src":item.icon_checked || item.icon_normal
              ? _vm.fixImageUrl(item.icon_checked || item.icon_normal, {}, true)
              : require('../../assets/images/default-icon.png')}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex !== index),expression:"currentIndex !== index"}],staticClass:"nav-logo",class:[
            _vm.myConfig.templateData.contentType === 'icon' ? 'logo-only' : '' ],style:({
            width: _vm.myConfig.templateData.fillBg ? '100%' : '25px',
            objectFit: _vm.myConfig.templateData.fillBg ? 'cover' : 'contain',
            flex: _vm.myConfig.templateData.fillBg ? 1 : '',
          }),attrs:{"src":item.icon_normal
              ? _vm.fixImageUrl(item.icon_normal, {}, true)
              : require('../../assets/images/default-icon.png')}})]:_vm._e(),(_vm.myConfig.templateData.contentType !== 'icon')?_c('div',{staticClass:"nav-text",class:[
          _vm.myConfig.templateData.contentType === 'text' ? 'text-only' : '' ],style:({
          color:
            _vm.myConfig.templateData[
              _vm.currentIndex === index ? 'selectedColor' : 'defaultColor'
            ],
          paddingBottom: _vm.myConfig.templateData.fillBg ? '4px' : '0',
        })},[_vm._v(" "+_vm._s(item.name ? item.name : ("导航" + (_vm.ch_sort[index])))+" ")]):_vm._e()],2)}),0):_vm._e(),(_vm.myConfig.templateData.styleType === 'template2')?[_c('div',{staticClass:"template2 fixed-center"},[_vm._l((_vm.tabList),function(item,index){return _c('div',{key:item.name,staticClass:"nav",class:[
          index === _vm.value || (_vm.value === -1 && item.is_default)
            ? 'active'
            : '' ],on:{"click":function($event){return _vm.handleActive(index)}}},[_c('div',{staticClass:"template2-mover",style:({
            backgroundColor: _vm.myConfig.templateData.selectedBgColor,
          })}),(item.icon_checked || item.icon_normal)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.value || (_vm.value === -1 && item.is_default)),expression:"index === value || (value === -1 && item.is_default)"}],staticClass:"nav-logo a",attrs:{"src":item.icon_checked || item.icon_normal,"mode":"aspectFit"}}):_vm._e(),(!item.icon_checked && !item.icon_normal)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.value || (_vm.value === -1 && item.is_default)),expression:"index === value || (value === -1 && item.is_default)"}],staticClass:"nav-logo a1",attrs:{"src":require('../../assets/images/default-icon.png')}}):_vm._e(),(item.icon_normal)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(
            (index !== _vm.value && _vm.value !== -1) ||
            (_vm.value === -1 && !item.is_default)
          ),expression:"\n            (index !== value && value !== -1) ||\n            (value === -1 && !item.is_default)\n          "}],staticClass:"nav-logo b",attrs:{"src":item.icon_normal,"mode":"aspectFit"}}):_vm._e(),(!item.icon_normal)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(
            (index !== _vm.value && _vm.value !== -1) ||
            (_vm.value === -1 && !item.is_default)
          ),expression:"\n            (index !== value && value !== -1) ||\n            (value === -1 && !item.is_default)\n          "}],staticClass:"nav-logo b1",attrs:{"src":require('../../assets/images/default-icon.png'),"mode":"aspectFit"}}):_vm._e()])}),_c('div',{staticClass:"template2-bg",style:({
          backgroundColor:
            _vm.myConfig.templateData.bgType === 'color'
              ? _vm.myConfig.templateData.bgColor
              : '',
          backgroundImage:
            _vm.myConfig.templateData.bgType === 'image'
              ? ("url(" + (_vm.myConfig.templateData.bgImage) + ")")
              : '',
          filter: ("blur(" + (_vm.myConfig.templateData.blur) + "px)"),
          opacity: _vm.myConfig.templateData.opacity,
          height: ("calc(61px + 2 * " + (_vm.myConfig.templateData.blur) + "px)"),
          width: ("calc(100% + 2 * " + (_vm.myConfig.templateData.blur) + "px)"),
          top: ("-" + (_vm.myConfig.templateData.blur) + "px"),
          left: ("-" + (_vm.myConfig.templateData.blur) + "px"),
        })})],2)]:_vm._e(),(_vm.myConfig.templateData.styleType === 'template3')?_c('div',{staticClass:"bottom-nav fixed",style:({
      backgroundColor:
        _vm.myConfig.templateData.bgType === 'color'
          ? _vm.myConfig.templateData.bgColor
          : '',
      backgroundImage:
        _vm.myConfig.templateData.bgType === 'image'
          ? ("url(" + (_vm.myConfig.templateData.bgImage) + ")")
          : '',
    })},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"nav",style:({
        backgroundColor:
          (_vm.value === -1 && index === 0) || index === _vm.value
            ? _vm.myConfig.templateData.selectedBgColor
            : '',
      }),on:{"click":function($event){return _vm.handleActive(index)}}},[(
          (_vm.tabList.length === 5 && index == 2) ||
          (_vm.tabList.length === 3 && index == 1) ||
          (_vm.tabList.length === 1 && index == 0)
        )?[_c('div',{staticClass:"active"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.value === -1 ? item.is_default : index === _vm.value),expression:"value === -1 ? item.is_default : index === value"}],staticClass:"big-nav-logo",attrs:{"src":item.icon_checked || item.icon_normal
                ? _vm.fixImageUrl(item.icon_checked || item.icon_normal, {}, true)
                : require('../../assets/images/default-icon.png')}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.value === -1 ? !item.is_default : index !== _vm.value),expression:"value === -1 ? !item.is_default : index !== value"}],staticClass:"big-nav-logo",attrs:{"src":item.icon_normal
                ? _vm.fixImageUrl(item.icon_normal, {}, true)
                : require('../../assets/images/default-icon.png')}}),_c('div',{staticClass:"nav-text",style:({
              color:
                _vm.myConfig.templateData[
                  (_vm.value === -1 ? item.is_default : index === _vm.value)
                    ? 'selectedColor'
                    : 'defaultColor'
                ],
            })},[_vm._v(" "+_vm._s(item.name ? item.name : ("导航" + (_vm.ch_sort[index])))+" ")])])]:[[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.value === -1 ? item.is_default : index === _vm.value),expression:"value === -1 ? item.is_default : index === value"}],staticClass:"nav-logo",attrs:{"src":item.icon_checked || item.icon_normal
                ? _vm.fixImageUrl(item.icon_checked || item.icon_normal, {}, true)
                : require('../../assets/images/default-icon.png')}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.value === -1 ? !item.is_default : index !== _vm.value),expression:"value === -1 ? !item.is_default : index !== value"}],staticClass:"nav-logo",attrs:{"src":item.icon_normal
                ? _vm.fixImageUrl(item.icon_normal, {}, true)
                : require('../../assets/images/default-icon.png')}})],[_c('div',{staticClass:"nav-text",style:({
              color:
                _vm.myConfig.templateData[
                  (_vm.value === -1 ? item.is_default : index === _vm.value)
                    ? 'selectedColor'
                    : 'defaultColor'
                ],
            })},[_vm._v(" "+_vm._s(item.name ? item.name : ("导航" + (_vm.ch_sort[index])))+" ")])]]],2)}),0):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v("内容区域")]),_c('p',[_vm._v("在右侧设置链接页面")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v("内容区域")]),_c('p',[_vm._v("在右侧设置链接页面")])])}]

export { render, staticRenderFns }