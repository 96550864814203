<template>
  <div class="el-form-item config-title">
    <div class="title-name">
      <p>{{ title }}</p>
      <div v-if="showBtn">
        <el-switch v-model="show" :active-value="1" :inactive-value="0">
        </el-switch>
        <!-- <div v-else class="form-check">
          <el-checkbox v-model="show" :false-label="1" :true-label="0" @change="handleChange"><span>隐藏</span></el-checkbox>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StIsShowTitle",
  model: {
    prop: "isShow",
    event: "isShowChange",
  },
  props: {
    title: {
      type: String,
      default: "显示设置",
    },
    isShow: {
      type: Number,
      default: 0,
    },
    showCheck: {
      type: Boolean,
      default: true,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(val) {
        this.$emit("isShowChange", val);
      },
    },
  },
  methods: {
    handleChange(val) {
      this.show = val ? 1 : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-name {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #606266;
}
</style>
