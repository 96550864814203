<template>
  <div class="st-analysis-item">
    <img class="overview" :src="`${baseImgUrl}/app_design/assets/images/st-analysis-page/lesson-user.png`" />
  </div>
</template>

<script>
export default {
  name: "StAnalysisLessonUser",
  props: {
    config: Object
  },
  data() {
    return {
      baseImgUrl: '',
    }
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
}
</script>

<style lang="scss" scoped>
  .st-analysis-item {
    overflow: hidden;

    .overview {
      border-radius: 6px;
      overflow: hidden;
      width: 345px;
      margin: -2px auto 8px;
      display: block;
    }
  }
</style>
