<!--
 * @Author: dongjia
 * @Date: 2021-08-11 11:17:38
 * @LastEditTime: 2021-08-11 16:37:08
 * @LastEditors: aleaner
 * @Description: 按钮样式设置表单
 * @FilePath: \app-design\components\Form\btnStyleSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="el-form-item">
    <div class="radio-header" v-if="title">
      <p class="title">{{title}}</p>
    </div>
    <div class="form-selector">
      <div class="form-item" v-for="(item,index) in selectArr" :key="item.value" :style="{
      border: value === item.value ?'1px solid #3576FF':''
    }" @click="handleSelect(item)">
        <div class="item-style" :style="item.css"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "btnStyleSelector",
  model: {
    prop: "dataValue",
    event: "input",
  },
  props: {
    dataValue: {
      type: String,
      default: "template1",
    },
    title: {
      type: String,
      default: "按钮样式",
    },
    backgroundColor: {
      type: String,
      default: "#3576FF",
    },
    borderColor: {
      type: String,
      default: "#3576FF",
    },
  },
  watch: {
    backgroundColor(val) {
      this.selectArr[0].css.background = this.selectArr[2].css.background = val;
    },
    borderColor(val) {
      this.selectArr[1].css.border =
        this.selectArr[3].css.border = `1px solid ${val}`;
    },
  },
  data() {
    let that = this;
    return {
      selectArr: [
        {
          value: "template1",
          css: {
            width: "94px",
            height: "38px",
            background: that.backgroundColor,
            borderRadius: "4px",
          },
        },
        {
          value: "template2",
          css: {
            width: "94px",
            height: "38px",
            border: `1px solid ${that.borderColor}`,
            borderRadius: "4px",
            background: "#FFFFFF",
          },
        },
        {
          value: "template3",
          css: {
            width: "94px",
            height: "38px",
            background: that.backgroundColor,
            borderRadius: "19px",
          },
        },
        {
          value: "template4",
          css: {
            width: "94px",
            height: "38px",
            border: `1px solid ${that.borderColor}`,
            borderRadius: "19px",
            background: "#FFFFFF",
          },
        },
      ],
    };
  },
  computed: {
    value: {
      get() {
        return this.dataValue;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    handleSelect(data) {
      this.value = data.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-header {
  display: flex;
  align-items: center;
  .title {
    line-height: 14px;
    margin-bottom: 16px;
    font-size: 14px;
    color: #606266;
  }
}
.form-selector {
  display: flex;
  flex-wrap: wrap;
  .form-item {
    cursor: pointer;
    width: 104px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>