<!--
 * @Author: dongjia
 * @Date: 2021-07-26 17:05:20
 * @LastEditTime: 2021-08-13 17:17:48
 * @LastEditors: aleaner
 * @Description: 边框描边设置表单
 * @FilePath: \app-design\components\Form\StBorderConfigForm.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="form-container">
    <el-color-picker class="picker" v-if="showColorPicker" v-model="value.borderColor" show-alpha>
    </el-color-picker>
    <el-select v-model="value.borderType">
      <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <el-select style="width: 100px;margin-left: 6px" v-model="value.borderSize">
      <el-option v-for="item in sizeOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "StBorderConfigForm",
  model: {
    prop: "config",
    event: "configChange",
  },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => {
        return {
          borderColor: "#ffffff",
          borderType: "solid",
          borderSize: 0,
        };
      },
    },
    showColorPicker: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      typeOptions: [
        { label: "—————", value: "solid" },
        { label: "------------", value: "dashed" },
      ],
      sizeOptions: [
        { label: "0px", value: 0 },
        { label: "1px", value: 1 },
        { label: "2px", value: 2 },
        { label: "3px", value: 3 },
        { label: "4px", value: 4 },
        { label: "5px", value: 5 },
        { label: "6px", value: 6 },
        { label: "7px", value: 7 },
        { label: "8px", value: 8 },
        { label: "9px", value: 9 },
        { label: "10px", value: 10 },
      ],
    };
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.$emit("configChange", val);
      },
    },
  },
  computed: {
    value: {
      get() {
        return this.config;
      },
      set(val) {
        this.$emit("configChange", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  display: flex;

  .picker {
    margin-right: 6px;
    ::v-deep.el-color-picker__trigger {
      width: 30px;
      height: 30px;
    }
    ::v-deep.el-color-picker__icon {
      display: none;
    }
  }
}
</style>