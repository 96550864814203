<template>
  <div
    class="st-enterprise-list-gov"
    style="padding-top: 12px"
    :style="[myRootStyle]"
  >
    <StListTitle
      :style="{
        padding: `0 ${
          myTemplateData.styleType !== 'template3' ? '16' : '0'
        }px 16px`,
      }"
      :config="myConfig"
    />
    <div v-loading="enterprise.loading">
      <div class="x-scroll-list">
        <template v-for="(item, index) in enterprise.list">
          <div
            class="item"
            :style="{ 'margin-right': myTemplateData.dataSpacing + 'px' }"
            :key="index"
          >
            <st-enterprise-item-gov :item="item" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import StEnterpriseItemGov from "@/modules/app-design/components/Preview/StEnterpriseItemGov";
import { getGovEnterprises } from "@/modules/app-design/api/components";
import componentMixin from "@/modules/app-design/mixins/component-mixin";
export default {
  name: "StEnterpriseListGov",
  mixins: [componentMixin],
  components: { StEnterpriseItemGov },
  data() {
    return {
      enterprise: {
        page: 1,
        list: [],
        no_more: false,
        no_result: false,
        keyword: "",
        loading: false,
      },
      model: {
        normal: {
          marginTop: 0,
          rowMargin: 0,
          bgType: "color",
          bgColor: "",
          bgImage: "",
        },
        templateData: {
          styleType: "template1",
          rowMargin: 0,
          dataSpacing: 10,
          listBorderRadius: 0,
          borderRadius: 8,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          dataFilter: [""],
          dataShowType: ["self_data", "gov_data"],
          dataAll: 1,
        },
        loadingConfig: {
          loadingType: "none",
          limit: 10,
          handleType: "template1",
          handleText: "加载更多",
        },
        listTitle: {
          isShow: 1,
          showType: "template2",
          title: "企业",
          fontWeight: "bold",
          fontSize: 14,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
        },
      },
    };
  },
  watch: {
    "myConfig.loadingConfig.limit"(newValue, oldValue) {
      if(newValue === oldValue) {
        return
      }
      this.reset();
      this.getSpecialList(false);
    },
    "myConfig.dataConfig.dataShowType"(newValue, oldValue) {
      if(newValue === oldValue) {
        return
      }
      this.reset();
      this.getSpecialList(false);
    },
    "myConfig.dataConfig.dataFilter"(newValue, oldValue) {
      if(newValue === oldValue) {
        return
      }
      this.reset();
      this.getSpecialList(false);
    },
  },
  created() {},
  mounted() {
    this.getSpecialList();
  },
  methods: {
    reset() {
      this.enterprise = {
        page: 1,
        list: [],
        no_more: false,
        no_result: false,
        keyword: "",
        loading: false,
      };
    },
    getSpecialList(notCancel = true) {
      if (this.enterprise.page > 1 && this.enterprise.no_more) return;
      if (this.enterprise.no_result) return;

      if (
        this.myConfig.loadingConfig.loadingType === "none" &&
        this.myConfig.loadingConfig.limit > 0 &&
        this.enterprise.page > 1
      ) {
        this.enterprise.no_more = true;
        return;
      }

      this.enterprise.loading = true;
      this.enterprise.no_more = false;
      this.enterprise.no_result = false;

      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });

      getGovEnterprises(
        {
          page: this.enterprise.page,
          limit: this.myConfig.loadingConfig.limit || -1,
          category_names: this.myConfig.dataConfig.dataAll ? "" : this.myConfig.dataConfig.dataFilter,
          category_all: this.myConfig.dataConfig.dataAll,
          use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
          // template: this.myTemplateData.styleType,
          // 数据类型
          data_sources: this.myConfig.dataConfig.dataShowType,
          ids: ids,
        },
        notCancel
      )
        .then((res) => {
          let { data } = res.data;
          let list = data || [];

          if (data.length === 0) {
            this.enterprise.no_result = true;
          }
          // 没有更多了
          if (
            this.myConfig.loadingConfig.limit &&
            list.length < this.myConfig.loadingConfig.limit
          ) {
            this.enterprise.no_more = true;
          }
          // 没有数据
          this.enterprise.no_result =
            this.enterprise.page === 1 && list.length === 0;
          // 拼接数据
          if (list.length > 0) {
            this.enterprise.list = this.enterprise.list.concat(list);
            // 最后， 页码加一
            this.enterprise.page += 1;
          }
          this.enterprise.loading = false;
        })
        .catch((err) => {
          this.enterprise.loading = false;
          if (this.enterprise.page === 1) {
            this.enterprise.no_result = true;
          }
        });
    },
  },
};
</script>

<style lang="scss">
.st-enterprise-list-gov {
  background-size: 100% auto;
  background-repeat: repeat-y;

  .item {
    box-shadow: 0 6px 10px 0 #f2f2f2;
    margin-bottom: 12px;

    &:first-child {
      margin-left: 16px;
    }

    &:last-child {
      margin-right: 16px !important;
    }
  }

  .x-scroll-list {
    overflow-x: hidden;
    display: flex;
  }
}
</style>
