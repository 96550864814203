var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-map",style:({
    margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage:
      _vm.myConfig.normal.bgType === 'image'
        ? ("url(" + (_vm.myConfig.normal.bgImg) + ")")
        : '',
    borderRadius: _vm.myConfig.normal.borderRadius + 'px',
  })},[_c('StListTitle',{attrs:{"config":{ listTitle: _vm.myConfig.titleConfig }}}),_c('baidu-map',{staticClass:"bmView",attrs:{"center":{
      lat: _vm.myConfig.normal.address.lat || 39.910924,
      lng: _vm.myConfig.normal.address.lng || 116.413387,
    },"zoom":_vm.zoom,"scroll-wheel-zoom":"","map-click":false},on:{"ready":_vm.loadMap}},[_c('bm-local-search',{staticStyle:{"display":"none"},attrs:{"keyword":_vm.myConfig.normal.address.city + _vm.myConfig.normal.address.detail,"auto-viewport":true},on:{"searchcomplete":_vm.getLocation}}),_c('bm-view',{style:({
        width: '100%',
        height:
          _vm.myConfig.normal.mapHeightType === 'normal'
            ? '156px'
            : _vm.myConfig.normal.height + 'px',
        borderRadius: '4px',
      })}),_c('bm-info-window',{attrs:{"position":{
        lat: _vm.myConfig.normal.address.lat || 39.910924,
        lng: _vm.myConfig.normal.address.lng || 116.413387,
      },"show":_vm.myConfig.normal.address.city !== '' &&
        _vm.myConfig.normal.address.detail !== '',"width":0,"height":0,"maxWidth":220,"closeOnClick":false,"autoPan":""}},[_c('p',{staticClass:"address-sign"},[_vm._v(" "+_vm._s(_vm.myConfig.normal.addressSign || _vm.myConfig.normal.address.city + _vm.myConfig.normal.address.detail)+" ")])])],1),(_vm.myConfig.normal.addressDetail)?_c('div',{staticClass:"address-detail"},[_c('div',{staticClass:"detail-item"},[_c('div',{staticClass:"item-left"},[_c('img',{staticClass:"left-icon",attrs:{"src":require("../../assets/images/st-map/position-icon.png")}}),_c('p',{staticClass:"item-title address-name"},[_vm._v(" "+_vm._s(_vm.myConfig.normal.address.city + _vm.myConfig.normal.address.detail || '地址名称')+" ")])]),_c('div',{staticClass:"item-right"},[(_vm.myConfig.normal.userPosition)?_c('p',{staticClass:"right-text"},[_vm._v(" 距离您 700m ")]):_vm._e(),_c('img',{staticClass:"right-icon",attrs:{"src":require("../../assets/images/st-map/handle-right.png")}})])]),_c('div',{staticClass:"detail-item"},[_c('div',{staticClass:"item-left"},[_c('img',{staticClass:"left-icon",attrs:{"src":require("../../assets/images/st-map/phone-icon.png")}}),_c('p',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.myConfig.normal.userPhone || '手机号码')+" ")])]),_vm._m(0)]),_c('div',{staticClass:"detail-item"},[_c('div',{staticClass:"item-left"},[_c('img',{staticClass:"left-icon",attrs:{"src":require("../../assets/images/st-map/clock-icon.png")}}),_c('p',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.myConfig.normal.businessTime || '开始时间~结束时间')+" ")])])]),_c('div',{staticClass:"detail-item"},[_c('div',{staticClass:"item-left"},[_c('img',{staticClass:"left-icon",attrs:{"src":require("../../assets/images/st-map/desc-icon.png")}}),_c('p',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.myConfig.normal.addressDesc || '地址简介')+" ")])])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-right"},[_c('img',{staticClass:"right-icon",attrs:{"src":require("../../assets/images/st-map/handle-right.png")}})])}]

export { render, staticRenderFns }