// import store from "@/base/store";

export default {
  getRules(getVm, value, platform) {
    return {
      normal: {
        title: '常规设置',
        isShow: 0,
        rules: [
          {
            type: 'StSpecialInput',
            field: 'titleConfig',
            title: '页面标题',
            display: true,
            update: (val, rule, fApi) => {
              const arr = [1, 2, 3]
              arr.forEach((el) => {
                fApi.rule[el].display = val.isShow === 1
              })
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'fontWeight',
            native: true,
            display: true,
            props: {
              title: '文本粗细',
              options: [
                {
                  label: '常规体',
                  value: 'normal',
                },
                {
                  label: '加粗体',
                  value: 'bold',
                },
              ],
            },
          },
          {
            type: 'StNumberSlider',
            field: 'fontSize',
            native: true,
            display: true,
            props: {
              label: '文本字号',
              min: 12,
              max: 40,
            },
          },
          {
            type: 'StColorPicker',
            field: 'fontColor',
            title: '文本颜色',
            display: true,
            props: {
              defaultColor: '#000000',
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'bgType',
            native: true,
            display: false,
            props: {
              title: '背景设置',
              options: [
                {
                  label: '颜色',
                  value: 'color',
                },
                {
                  label: '图片',
                  value: 'image',
                },
              ],
            },
          },
          {
            type: 'StColorPicker',
            field: 'bgColor',
            title: '背景颜色',
            display: false,
            props: {
              showAlpha: true,
              defaultColor: '#f8f8f8',
            },
            link: ['bgType'],
            update: (val, rule, fApi) => {
              rule.display = fApi.form.bgType === 'color'
            },
          },
          {
            type: 'SingleMediaWall',
            field: 'bgImage',
            title: '背景图片',
            display: false,
            props: {
              width: 60,
              height: 60,
              addText: '',
            },
            style: {
              float: 'right',
            },
            link: ['bgType'],
            update: (val, rule, fApi) => {
              rule.display = fApi.form.bgType === 'image'
            },
          },
        ],
      },
      contentConfig: {
        title: "内容设置",
        rules: [
          {
            "type": "StRadioButtonGroup",
            "field": "companyRes",
            "native": true,
            "display": true,
            "props": {
              "title": "企业形象",
              "options": [
                {
                  "label": "显示",
                  "value": 1
                },
                {
                  "label": "隐藏",
                  "value": 0
                }
              ]
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "companyHistory",
            "native": true,
            "display": true,
            "props": {
              "title": "企业历程",
              "options": [
                {
                  "label": "显示",
                  "value": 1
                },
                {
                  "label": "隐藏",
                  "value": 0
                }
              ]
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "companyBusiness",
            "native": true,
            "display": true,
            "props": {
              "title": "工商信息",
              "options": [
                {
                  "label": "显示",
                  "value": 1
                },
                {
                  "label": "隐藏",
                  "value": 0
                }
              ]
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "companyConsult",
            "native": true,
            "display": true,
            "props": {
              "title": "业务咨询",
              "options": [
                {
                  "label": "显示",
                  "value": 1
                },
                {
                  "label": "隐藏",
                  "value": 0
                }
              ]
            }
          }
        ]
      }
    }
  },
}
