<template>
  <div class="container" :style="[myRootStyle]">
    <div class="content-box">
      <StListTitle :config="myConfig"></StListTitle>
      <div class="navigation-list">
        <div class="item" v-for="(item, i) in tabList" :key="i">
          <div
            class="cover"
            :style="{
              backgroundImage: `url(${item.icon})`
            }"
          >
            <div class="info-box">
              <div class="name">{{ item.name }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>

          <div class="bottom"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import componentMixin from "../../mixins/component-mixin";
import StListTitle from "@/modules/app-design/components/Preview/Common/StListTitle";

export default {
  name: "st-image-text-nav",
  mixins: [componentMixin],
  components: {
    StListTitle,
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          rowMargin: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "栏目导航",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        tabs: {
          list: [
            {
              icon: "",
              name: "",
              desc:"",
              type:"normal",
              is_show: 1,
              jump_link: require("@/modules/app-design/assets/jsons/jump-link.json"),
            },
          ],
        },
      },
    };
  },
  computed: {
    tabList() {
      return this.myConfig.tabs.list.filter((el) => el.is_show === 1);
    },
    myRootStyle() {
      const rS = {}
      const myConfig = this.myConfig
      if (myConfig.normal?.bgType) {
        rS.backgroundColor = myConfig.normal.bgType === 'color'?myConfig.normal.bgColor:''
        rS.backgroundImage = this.myBgImage
      }
      if (myConfig.normal) {
        rS.padding = `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin || 0}px 0`
        if (myConfig.normal.rowMargin) {
          rS['--row-margin'] = myConfig.normal.rowMargin + 'px'
        }
      }
      if (myConfig.templateData?.dataSpacing) {
        rS['--data-spacing'] = myConfig.templateData.dataSpacing + 'px'
      }
      return rS
    },
  },
  watch: {
    myConfig: {
      deep: true,
      handler(val) {},
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  background-size: cover;
  background-position: center;
  .content-box {
    padding: 16px 16px;
    .navigation-list {
      margin-top: 12px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 17px 16px;
      .item {
        width: 100%;
        padding-top: 100%;
        position: relative;
        // overflow: hidden;

        .cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #e9e7ea;
          z-index: 2;
          border-radius: 6px;
          overflow: hidden;
          background-size: cover;
          .info-box {
            background: linear-gradient(180deg, #1a1a1a00 0%, #1a1a1aff 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 20px 12px 12px;
            z-index: 3;
            .name {
              color: #ffffff;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 2px;
            }

            .desc {
              color: #ffffffff;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        .bottom {
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
          bottom: -6px;
          width: 134px;
          height: 26px;
          border-radius: 6px;
          opacity: 1;
          background: #dbdbdb;
        }
      }
    }
  }
}
</style>
