import { render, staticRenderFns } from "./StThemeSelector.vue?vue&type=template&id=1530979e&scoped=true&"
import script from "./StThemeSelector.vue?vue&type=script&lang=js&"
export * from "./StThemeSelector.vue?vue&type=script&lang=js&"
import style0 from "./StThemeSelector.vue?vue&type=style&index=0&id=1530979e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1530979e",
  null
  
)

export default component.exports