<!--
 * @Author: dongjia
 * @Date: 2021-09-07 14:41:40
 * @LastEditTime: 2021-09-15 14:59:36
 * @LastEditors: aleaner
 * @Description: 云相册详情页
 * @FilePath: \app-design\components\Preview\StActivityAlbumDetail.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="source">
    <!-- 头部 -->
    <div class="header">
      <template v-if="platform !== 'h5'">
        <img
          class="start-top-img"
          :src="statusBar[myConfig.normal.statusBarColor]"
        />
        <div class="start-top" style="text-align: end">
          <img
            v-if="myConfig.normal.showHeaderJump"
            class="header-jump"
            :class="[
              headerJump.showConfig.showType !== 'template1'
                ? 'template-back-icon'
                : 'default-back-icon',
            ]"
            :src="
              backIcon[myConfig.normal.statusBarColor][
                headerJump.showConfig.showType
              ]
            "
          />
          <img
            class="start-top-handler"
            :src="handleIcon[myConfig.normal.statusBarColor]"
          />
        </div>
      </template>
      <!-- h5标题 -->
      <st-page-set v-else :config="myConfig" />
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div
          class="type-item"
          v-if="myConfig.normal.titleConfig.isShow"
          :style="{
            color: myConfig.normal.fontColor,
            fontWeight: myConfig.normal.fontWeight,
          }"
        >
          {{ myConfig.normal.titleConfig.name }}
        </div>
      </div>
    </div>
    <!-- 模板一 -->
    <div class="template1" v-if="myConfig.template.template === 'template1'">
      <div class="source-content">
        <div class="content-cover">
          <img
            class="default-icon"
            src="../../assets/images/mini-default-icon@2x.png"
          />
        </div>
        <div class="main-content">
          <p class="content-title">
            不可否认，后印象派中三位人物的名声似乎比前面任何一位印象派都要强
          </p>
          <div class="content-text">
            <div class="wrapper">
              <input id="exp1" class="exp" type="checkbox" />
              <div class="text">
                <label class="btn" for="exp1"></label>
                浮动元素是如何定位的正如我们前面提到的那样，当一个元素浮动之后，它会被移出正常的文档流，然后向左或者向右平移，一直平移直到碰到了所处的容器的边框，或者碰到另外一个浮动的元素。在下面的图片中，有三个红色的正方形。其中有两个向左浮动，一个向右浮动。要注意到第二个向左浮动的正方形被放在第一个向左浮动的正方形的右边。如果还有更多的正方形这样浮动，它们会继续向右堆放，直到填满容器一整行，之后换行至下一行。
              </div>
            </div>
          </div>
          <div class="content-msg">
            <div class="msg-item">
              <img
                class="msg-icon"
                src="../../assets/images/st-activity-photo/time-icon.png"
              />
              <p class="msg-text">2020.01.17 ~ 2020.01.19</p>
            </div>
            <div class="msg-item">
              <img
                class="msg-icon"
                src="../../assets/images/st-activity-photo/view-icon.png"
              />
              <p class="msg-text">1202 人看过</p>
            </div>
          </div>
        </div>
        <div class="album-list">
          <p class="title">活动相册</p>
          <div class="list">
            <div
              class="album-item"
              :class="'template' + num"
              v-for="num in 4"
              :key="num"
            >
              <img
                v-if="[1, 2].includes(num)"
                class="default-icon"
                src="../../assets/images/mini-default-icon@2x.png"
              />
              <div v-if="num === 3" v-for="i in 2" :key="i" class="item">
                <img
                  class="default-icon"
                  src="../../assets/images/mini-default-icon@2x.png"
                />
              </div>
              <div v-if="num === 4" v-for="i in 3" :key="i" class="item">
                <img
                  class="default-icon"
                  src="../../assets/images/mini-default-icon@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 模板二 -->
    <div
      class="template2"
      v-else-if="myConfig.template.template === 'template2'"
    >
      <div class="content-box">
        <div class="top-image">
          <img
            class="default-icon"
            :src="`${baseImgUrl}/app_design/st-activity-album-detail/cover.png`"
          />
          <!-- 时间 -->
          <div class="time-box">
            <img
              class="icon"
              src="../../assets/images/st-activity-album-detail/time.png"
            />
            <!-- 时间格式 -->
            <div class="text" v-if="myConfig.content.timeType">2022/11/09 12:00 ~ 2022/12/04 9:00</div>
            <div class="text" v-else>2022/11/09  ~ 2022/12/04 </div>
          </div>
        </div>
        <!-- 标题 -->

        <div class="title-box">
          <div class="title">2022年新春慰问慈善活动</div>
          <div class="line"></div>
          <p class="detail">
            最近十几年，整个互联网都在从文字向多媒体转型：文字论坛换成了图文社区、图文社区变成了视频平台、视频平台被短视频取代……就看我周围的情况，很多公众号主都转型做抖音、视频号了。尤其是最近这一两年，很多人一提到文字内容，就一副看“夕阳行业”的表情。
            但是，这里有一个容易被忽视的问题：即便图片、视频类内容再火，文字内容也将长期存在。<br/>
            例如网文，依旧有很大一批受众，即便增长放缓最终变成存量市场，也不会消失。<br/>
            视频内容虽然看着爽，但是因为成本高、制作周期长、信息量获取效率低……等等问题，并不能完全取代文字内容。<br/>
            这也是为什么即便很多人在喊：“公众号不行了，视频号、抖音才是未来”，我也依旧在写文章。因为我的核心能力是体验设计，这方面的内容比较小众、理解难度高、相关内容少，现阶段很难做成视频形式。
          </p>
        </div>
        <!-- 相片弹框 -->
        <div class="photo-box">
          <!-- 弹框的小横条 -->
          <div class="line"></div>
          <div class="content">
            <ColorCustomIcon
              :width="16"
              :height="16"
              :icon="
                require('../../assets/images/st-activity-album-detail/photo.png')
              "
            ></ColorCustomIcon>
            <div class="title">活动相册</div>
            <div class="count">2644张照片</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
import ColorCustomIcon from "@/modules/app-design/components/Normal/ColorCustomIcon";

export default {
  name: "StActivityAlbumDetail",
  components: { StPageSet, ColorCustomIcon },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseImgUrl:'',
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: "云相册详情",
            isShow: 1,
          },
          fontWeight: "normal",
          fontColor: "#000000",
          bgColor: "",
          statusBarColor: "black",
          showHeaderJump: 1,
          timeType: 0,
        },
        // 模板
        template: {
          template: "template1",
        },
        // 图片排版格式
        photoConfig: {
          typography: "template1",
        },
        // 内容
        content: {
          timeType: 0,
        },
        shareConfig: {
          shareTitle: "",
          image: "",
          showShare: 1,
          showAppMessageShare: 1,
          showMomentShare: 1,
          showPosterShare: 1,
        },
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  mounted() {
    console.log(this.myConfig);
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL

  },
};
</script>

<style lang="scss" scoped>
.source {
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  .header {
    width: 100%;
    .type-select {
      position: absolute;
      top: 23px;
      width: 100%;
      padding: 0 18px;
      display: flex;
      justify-content: center;

      &.simple {
        position: static;
        display: none;
      }

      .type-item + .type-item {
        margin-left: 18px;
      }
      .type-item {
        display: flex;
        align-items: center;
        height: 34px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        line-height: 34px;
        .active {
          border-bottom: 2px #fff solid;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
        }
        .select-icon {
          width: 16px;
          height: 16px;
          margin-left: 2px;
        }
      }
    }
  }
}

.start-top-img {
  width: 375px;
  height: 20px;
}
.start-top {
  padding-right: 7px;
  .header-jump {
    position: absolute;

    &.default-back-icon {
      top: 32px;
      left: 11px;
      width: 20px;
      height: 20px;
    }

    &.template-back-icon {
      top: 25px;
      left: 5px;
      width: 78px;
      height: 32px;
    }
  }
}
.start-top-handler {
  width: 87px;
  height: 32px;
}

// 模板一
.template1 {
  .source-content {
    .content-cover {
      width: 100%;
      height: 194px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f3f5f7;
      .default-icon {
        width: 36px;
        height: 36px;
      }
    }

    .main-content {
      padding: 12px 17px 19px;

      .content-title {
        @include clamp(2);
        font-size: 20px;
        font-weight: bold;
        color: #2e2e2e;
        line-height: 30px;
      }
      .content-text {
        margin-top: 8px;
        font-size: 14px;
        line-height: 24px;
        .content {
          color: #333333;
          @include clamp(3);
        }
        .expain-btn {
          color: #e60035;
        }
      }
    }

    .content-msg {
      margin-top: 8px;
      .msg-item + .msg-item {
        margin-top: 13px;
      }
      .msg-item {
        display: flex;
        align-items: center;
        .msg-icon {
          margin-right: 7px;
          width: 18px;
          height: 18px;
        }
        .msg-text {
          font-size: 12px;
          color: #000000;
          line-height: 12px;
        }
      }
    }

    .album-list {
      .title {
        padding: 0 17px;
        font-size: 18px;
        font-weight: bold;
        color: #191e29;
        line-height: 18px;
      }
      .list {
        margin-top: 17px;
        display: flex;
        flex-wrap: wrap;
        .album-item {
          &.template1 {
            width: 100%;
            height: 236px;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f5f7;
          }

          &.template2 {
            width: 250px;
            height: 250px;
            border: 1px solid #ffffff;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f5f7;
          }

          &.template3 {
            width: 125px;
            .item {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f3f5f7;
              width: 100%;
              height: 125px;
              border: 1px solid #ffffff;
            }
          }

          &.template4 {
            width: 100%;
            display: flex;
            .item {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f3f5f7;
              width: 125px;
              height: 125px;
              border: 1px solid #ffffff;
            }
          }

          .default-icon {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
  }

  // 多行文本展开收起样式
  .wrapper {
    display: flex;
    overflow: hidden;
  }
  .text {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    /* display: flex; */
    /*   display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
    position: relative;
    line-height: 24px;
    max-height: 74px;
    transition: 0.3s max-height;
  }
  .text::before {
    content: "";
    height: calc(100% - 26px);
    float: right;
  }
  .text::after {
    content: "";
    width: 999vw;
    height: 999vw;
    position: absolute;
    box-shadow: inset calc(100px - 999vw) calc(30px - 999vw) 0 0 #fff;
    margin-left: -100px;
  }
  .btn {
    position: relative;
    float: right;
    clear: both;
    margin-left: 20px;
    font-size: 14px;
    line-height: 24px;
    color: var(--main-color);
    cursor: pointer;
    /* margin-top: -30px; */
  }
  .btn::after {
    content: "展开";
  }
  .exp {
    display: none;
  }
  .exp:checked + .text {
    max-height: none;
  }
  .exp:checked + .text::after {
    visibility: hidden;
  }
  .exp:checked + .text .btn::before {
    visibility: hidden;
  }
  .exp:checked + .text .btn::after {
    content: "收起";
  }
  .exp:checked + .text .btn {
    margin-left: -30px;
  }
  .btn::before {
    content: "...";
    position: absolute;
    left: -5px;
    color: #333;
    transform: translateX(-100%);
  }
}
// 模板二
.template2 {
  .content-box {
    padding: 8px 16px;
    // 顶部图片
    .top-image {
      position: relative;
      height: 193px;
      img.default-icon {
        border-radius: 12px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .time-box {
        padding: 5px 8px;
        border-radius: 26px;
        display: flex;
        position: absolute;
        right: 3px;
        bottom: 6px;
        background: rgba(0, 0, 0, 0.2);
        img.icon {
          width: 12px;
          height: 12px;
          margin-right: 6px;
        }

        .text {
          flex: 1;
          font-size: 12px;
          color: #fff;
        }
      }
    }
    // 标题弹框
    .title-box {
      .title {
        margin-top: 16px;
        font-weight: 600;
        color: #1a1a1a;
        font-size: 20px;
        line-height: 36px;
      }

      .line {
        width: 42px;
        height: 2px;
        background: var(--main-color);
        margin: 8px 0 12px;
        border-radius: 15px;
      }

      .detail {
        color: #1a1a1a;
        line-height: 28px;
        font-size: 15px;
      }
    }
    // 相册弹框
    .photo-box {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 70px;
      width: 100%;
      box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.04);
      border-radius: 20px 20px 0 0;
      background-color: #fff;

      padding: 12px 16px;
      .content {
        width: 100%;
        display: flex;
        align-items: center;


        .title {
         
          margin-left: 8px;
          font-size: 16px;
          font-weight: 700;
          color: #1a1a1a;
          flex:1;
        }

        .count {
          width: 100px;
          justify-self: flex-end;
          font-size: 12px;
          font-weight: 400;
          color: #b3b3b3;
          text-align: right;
        }
      }

      .line {
        width: 24px;
        height: 3px ;
        background-color:rgba(0,0,0,0.2);
        margin: 0 auto 8px;
        border-radius: 20px;
        
      }
    }
  }
}
</style>
