<template>
  <div class="st-avatars" :style="{
		height: `calc(${width + (borderWidth || 1) * 2}px)`
	}">
    <img class="icon" :src="fixSpecialImageUrl(user.avatar, '', index)"
         v-for="(user, index) in arr.slice(0, max)" :key='index'
      :style="{
				marginLeft :  index > 0?(-indent) + 'px':0,
				'z-index' : reverse ? (max + 1 + index) : ((max + 1) - index),
				'background-color' : user.avatar ? 'white' : '#605D5D',
				width: `${width}px`,
				height: `${width}px`,
				border: `${borderWidth || 1}px solid white`
			}" />
    <!-- 不够五个，用预置头像凑齐 -->
    <template v-if="showDefaultAvatar">
      <img class="icon" v-for="(icon, index) in icon_people.slice(0, max - arr.length)" :key='icon.avatar'
        :src="icon.avatar" :style="{
				marginLeft : (arr.length === 0 && index < 1)?0:(-indent + 'px'),
				'z-index' : reverse ? (max + 1 + index + arr.length) : ((max + 1) - (index + arr.length)),
				width: `${width}px`,
				height: `${width}px`,
        border: `${borderWidth || 1}px solid white`
			}" />
    </template>
  </div>
</template>

<script>
import {randomString} from "@/base/utils/tool";

export default {
  name: "StAvatars",
  props: {
    // example：[{avatar: ""}]
    arr: {
      type: Array,
      default: () => [],
    },
    // 不含边框，加上边框是22（20 + 1 * 2），单位：px
    width: {
      type: Number,
      default: 20,
    },
    borderWidth: Number, // 默认 1px
    // 缩进，指的是以第一个头像左侧为起点的偏移量，单位：px
    indent: {
      type: Number,
      default: 10,
    },
    max: {
      type: Number,
      default: 5,
    },
    // 是否使用预置头像补齐
    showDefaultAvatar: {
      type: Boolean,
      default: true,
    },
    /* 堆叠顺序，默认是最右边在最上层 */
    reverse: Boolean,
  },
  data() {
    return {
      randomId: randomString(10),
      icon_people: [
        {
          avatar: require("../../../assets/images/st-spirit-list/square-avatar1.png"),
        },
        {
          avatar: require("../../../assets/images/st-spirit-list/square-avatar2.png"),
        },
        {
          avatar: require("../../../assets/images/st-spirit-list/square-avatar3.png"),
        },
        {
          avatar: require("../../../assets/images/st-spirit-list/square-avatar4.png"),
        },
        {
          avatar: require("../../../assets/images/st-spirit-list/square-avatar5.png"),
        },
      ],
    };
  },
  methods: {
    fixSpecialImageUrl(src, sizeStr, ...others) {
      let str = others ? others.join('_') : ''
      const isPageset = this.$route.query.pageset
      return this.fixImageUrl(src, {
        w: sizeStr === 'small' ? 200 : (sizeStr === 'medium' ? 300 : (sizeStr === 'large' ? 500 : (sizeStr || 200)))
      }, isPageset ? `${this.randomId}_${str}_s` : '')
    }
  },
};
</script>

<style lang="scss">
.st-avatars {
  position: relative;

  .icon {
    border-radius: 50%;
    object-fit: cover;
    position: relative; // 使 z-index 生效
  }
}
</style>
