var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['el-form-item ', _vm.isFlex ? 'flex-box' : '']},[(_vm.title)?_c('div',{staticClass:"radio-header",style:({
      marginBottom: _vm.isFlex ? 0 : '24px',
    })},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.suggestText && _vm.suggestText[_vm.value])?_c('span',{staticClass:"suggest"},[_vm._v(_vm._s(("(建议尺寸" + _vm.suggestTextDisplay + "px)")))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"radio-content"},_vm._l((_vm.options),function(item,index){return _c('el-radio',{key:index,style:({
        marginBottom:
          index <
          _vm.options.length - (_vm.options.length % 4 ? _vm.options.length % 4 : 4)
            ? '16px'
            : '',
        width: _vm.options.length >= 4 ? '25%' : 100 / _vm.options.length + '%',
      }),attrs:{"label":item.value},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_vm._v(_vm._s(item.name)+" ")])}),1),(_vm.tipsText && _vm.tipsText[_vm.value])?_c('div',{staticClass:"tipsText"},[_vm._v(" "+_vm._s(("" + (_vm.tipsText[_vm.value])))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }