var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list st-shop-goods",style:([
    _vm.myRootStyle,
    {
      '--empty-icon-bg': _vm.defaultIconBgColor,
    } ])},[_c('StListTitle',{attrs:{"config":_vm.myConfig}}),(_vm.goods_list.length)?[(_vm.myStyleType === 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view water-fall",style:({
        paddingTop: _vm.myConfig.listTitle.isShow ? '0' : '12px',
      })},_vm._l((_vm.waterfall_datas),function(list,i){return _c('div',{key:i,staticClass:"item-list"},[_c('div',{ref:i ? 'rightList' : 'leftList',refInFor:true},_vm._l((list),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[_c('div',{staticClass:"item-box",style:({
                borderRadius: '6px',
                marginTop: index > 0 ? '12px' : '',
              })},[_c('div',{staticClass:"img-box",style:({
                  borderRadius: '8px 8px 0 0',
                  border: _vm.myTemplateData.showAddBorder
                    ? '4px solid #fff'
                    : '',
                })},[_c('st-load-image',{attrs:{"src":item.image || item.res[0].thumbnail || item.res[0].url,"proportion":_vm.myTemplateData.photoSize === 'template2' ? '75%' : '100%'},on:{"imgLoad":_vm.listRanding,"imgError":_vm.listRanding}})],1),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"goods-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"goods-price"},[_c('div',{staticClass:"price-content"},[(item.price)?[_c('span',{staticClass:"price-unit"},[_vm._v("￥")]),_c('span',{staticClass:"price-count"},[_vm._v(_vm._s(Math.floor(item.price)))])]:[_c('span',{staticClass:"price-count"},[_vm._v(_vm._s(Math.floor(item.integral)))]),_c('span',{staticClass:"price-unit"},[_vm._v("积分")])],(_vm.getSmallNum(item.price))?_c('span',{staticClass:"price-count-two"},[_vm._v("."+_vm._s(_vm.getSmallNum(item.price)))]):_vm._e(),(item.data_type && item.max_market_price)?_c('span',{staticClass:"old-price"},[_vm._v("￥"+_vm._s(item.max_market_price))]):_vm._e()],2),(_vm.myTemplateData.ShowPayBtn)?[(_vm.myTemplateData.buttonTemplate === 'template1')?_c('div',{staticClass:"button-template-1"},[_c('img',{attrs:{"src":require("../../assets/images/st-shop-goods/button-1.png")}})]):_vm._e(),(_vm.myTemplateData.buttonTemplate === 'template2')?_c('div',{staticClass:"button-template-3"},[_c('img',{attrs:{"src":require("../../assets/images/st-shop-goods/button-3.png")}})]):_vm._e()]:_vm._e()],2)])])])}),0)])}),0):_vm._e(),(['template2'].includes(_vm.myStyleType))?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view flex-column",class:_vm.myStyleType},_vm._l((_vm.goods_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
          marginTop: index > 0 ? '12px' : '0px',
          borderRadius: '12px',
        })},[_c('div',{staticClass:"img-box",style:({
            borderRadius: '6px',

            width:
              _vm.myTemplateData.photoSize === 'template1'
                ? '124px'
                : _vm.myTemplateData.photoSize === 'template3'
                ? '92px'
                : '124px',
          })},[_c('st-load-image',{staticClass:"normal",attrs:{"src":item.image || item.res[0].thumbnail || item.res[0].url,"size":300}})],1),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"goods-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"goods-price"},[_c('div',{staticClass:"price-content"},[_c('span',{staticClass:"price-unit"},[_vm._v("￥")]),_c('span',{staticClass:"price-count"},[_vm._v(_vm._s(item.price))]),(item.data_type && item.max_market_price)?_c('span',{staticClass:"old-price"},[_vm._v("￥"+_vm._s(item.max_market_price))]):_vm._e()]),(_vm.myTemplateData.ShowPayBtn)?[(_vm.myTemplateData.buttonTemplate === 'template1')?_c('div',{staticClass:"button-template-1"},[_c('img',{attrs:{"src":require("../../assets/images/st-shop-goods/button-1.png")}})]):_vm._e(),(_vm.myTemplateData.buttonTemplate === 'template2')?_c('div',{staticClass:"button-template-3"},[_c('img',{attrs:{"src":require("../../assets/images/st-shop-goods/button-3.png")}})]):_vm._e()]:_vm._e()],2)])])}),0):_vm._e(),(_vm.myStyleType === 'template3')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template3"},_vm._l((_vm.goods_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",staticStyle:{"width":"100%"},style:({
          marginTop: index > 0 ? '12px' : '0px',
          borderRadius: '6px',
        })},[_c('div',{staticClass:"img-box",staticStyle:{"width":"100%"},style:({})},[_c('st-load-image',{staticClass:"normal",attrs:{"src":item.image || item.res[0].thumbnail || item.res[0].url,"size":500,"proportion":_vm.myTemplateData.photoSize === 'template3' ? '100%' : '75%'}})],1),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"goods-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"goods-price"},[_c('div',{staticClass:"price-content"},[_c('span',{staticClass:"price-unit"},[_vm._v("￥")]),_c('span',{staticClass:"price-count"},[_vm._v(_vm._s(item.price))]),(item.data_type && item.max_market_price)?_c('span',{staticClass:"old-price"},[_vm._v("￥"+_vm._s(item.max_market_price))]):_vm._e()]),(_vm.myTemplateData.ShowPayBtn)?[(_vm.myTemplateData.buttonTemplate === 'template1')?_c('div',{staticClass:"button-template-1"},[_c('img',{attrs:{"src":require("../../assets/images/st-shop-goods/button-1.png")}})]):_vm._e(),(_vm.myTemplateData.buttonTemplate === 'template2')?_c('div',{staticClass:"button-template-3"},[_c('img',{attrs:{"src":require("../../assets/images/st-shop-goods/button-3.png")}})]):_vm._e()]:_vm._e()],2)])])}),0):_vm._e()]:_vm._e(),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText)+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }