var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:([_vm.myRootStyle])},[_c('StListTitle',{attrs:{"config":_vm.myConfig}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"},{name:"show",rawName:"v-show",value:(_vm.myStyleType === 'template1'),expression:"myStyleType === 'template1'"}],staticClass:"swiper-view template1"},[_vm._l((_vm.live_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginTop: index !== 0 ? _vm.myTemplateData.dataSpacing + 'px': '0',
    })},[_c('div',{staticClass:"item-left"},[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.material_image,"border-radius":_vm.myTemplateData.borderRadius,"size":300}}),_c('div',{staticClass:"item-status",style:({
        borderRadius: ((_vm.myTemplateData.borderRadius) + "px 0 " + (_vm.myTemplateData.borderRadius) + "px 0")
      })},[_vm._v(_vm._s(item.live_text)+_vm._s(item.live_text === '预告'?'：' + item.start_time:''))])],1),_c('div',{staticClass:"item-right"},[_c('p',{staticClass:"right-top"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"right-bottom",class:item.live_text === '已结束'?'is-over':''},[_vm._v(" "+_vm._s(item.live_text_two))])])])}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],2),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"},{name:"show",rawName:"v-show",value:(_vm.myStyleType === 'template2'),expression:"myStyleType === 'template2'"}],staticClass:"swiper-view template2",style:(("row-gap: " + (_vm.myTemplateData.dataSpacing) + "px;column-gap: " + (_vm.myTemplateData.dataSpacing) + "px;"))},[_vm._l((_vm.live_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      borderRadius: _vm.myTemplateData.borderRadius + 'px',
    })},[_c('st-load-image',{staticClass:"live-image",attrs:{"src":item.material_image,"size":300}}),_c('div',{staticClass:"cover-title"},[_c('p',[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"item-status",style:({
        borderRadius: ("0 0 " + (_vm.myTemplateData.borderRadius) + "px 0")
      })},[_vm._v(_vm._s(item.live_text)+_vm._s(item.live_text === '预告'?'：' + item.start_time:''))])],1)}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],2),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"},{name:"show",rawName:"v-show",value:(_vm.myStyleType === 'template3'),expression:"myStyleType === 'template3'"}],staticClass:"swiper-view template3"},[_vm._l((_vm.live_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginTop: index !== 0 ? _vm.myTemplateData.dataSpacing + 'px': '0',
      paddingTop: index === 0 ? '0': '',
    })},[_c('div',{staticClass:"item-img",style:({
        borderRadius: _vm.myTemplateData.borderRadius + 'px',
      })},[_c('st-load-image',{staticClass:"live-image",attrs:{"src":item.material_image,"size":300}}),_c('div',{staticClass:"item-status",style:({
        borderRadius: ("0 0 " + (_vm.myTemplateData.borderRadius) + "px 0")
      })},[_vm._v(_vm._s(item.live_text==='预告'?item.live_text + '：' + item.start_time:item.live_text))])],1),_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-icons"},[_c('el-image',{staticClass:"item-icon",attrs:{"src":require('../../assets/images/st-live-list/wechat-icon.png'),"fit":"cover"}}),_c('el-image',{staticClass:"item-icon",attrs:{"src":require('../../assets/images/st-live-list/picture-icon.png'),"fit":"cover"}})],1)])}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }