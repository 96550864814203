<template>
  <div class="st-contacts">
    <div class="header">
      <template v-if="platform !== 'h5'">
        <img class="start-top-img" src="../../assets/images/phone-statusbar2@2x.png">
        <div class="start-top" style="text-align: end">
          <img class="start-top-handler" src="../../assets/images/wechat-handle2@2x.png">
        </div>
      </template>
      <st-page-set v-else title="人脉"></st-page-set>
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div class="type-item">
          <p class="active">会员名录</p>
          <el-image class="select-icon" :src="require('../../assets/images/select-down.png')"></el-image>
        </div>
        <!-- <div class="type-item">企业名录</div> -->
      </div>
      <div class="header-search">
        <p class="search-icon el-icon-search"></p>
        <p class="search-tip">搜索会员</p>
      </div>
    </div>
    <div class="member-list" :class="[platform === 'h5' && false ? 'simple' : '']">
      <div class="list-item" v-for="(item,index) in defaultList">
        <p class="item-name">{{`${item.name}(${item.members_count})`}}</p>
        <p class="item-icon el-icon-arrow-right"></p>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
export default {
  name: "StContacts",
  components: {StPageSet },
  data() {
    return {
      defaultList: require("../../assets/jsons/default-list.json").contactsList,
      platform: this.$route.params.platform,
    };
  },
};
</script>

<style lang="scss" scoped>
.st-contacts {
  width: 375px;
  height: 611px;
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;

  .header-nav {
    background-color: white;
  }

  .start-top-img {
    width: 375px;
    height: 20px;
  }
  .start-top {
    padding-right: 7px;
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }
  .header {
    position: absolute;
    top: 0;
    width: 100%;
    background: var(--main-color);
    .type-select {
      position: absolute;
      top: 28px;
      width: 100%;
      padding: 0 18px;
      display: flex;

      &.simple {
        position: static;
        display: none;
      }

      .type-item + .type-item {
        margin-left: 18px;
      }
      .type-item {
        display: flex;
        align-items: center;
        height: 34px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        line-height: 34px;
        .active {
          border-bottom: 2px #fff solid;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
        }
        .select-icon {
          width: 16px;
          height: 16px;
          margin-left: 2px;
        }
      }
    }
    .header-search {
      margin: 16px auto 26px;
      width: 335px;
      height: 32px;
      background: #f8fbff;
      border-radius: 18px;
      opacity: 0.89;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      .search-icon {
        color: #94989f;
        font-size: 14px;
      }
      .search-tip {
        font-size: 14px;
        color: #94989f;
        line-height: 14px;
        margin-left: 4px;
      }
    }
  }
  .member-list {
    padding-top: 138px;

    &.simple {
      padding-top: calc(138px + 44px);
    }

    .list-item {
      padding: 20px 19px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-name {
        font-size: 15px;
        font-weight: bold;
        color: #353535;
        line-height: 15px;
      }
      .item-icon {
        font-size: 15px;
        color: #b7bec8;
      }
    }
  }
}
</style>
