<template>
  <div class="el-form-item" style="margin-bottom: 18px">
    <p class="title">{{ label }}</p>
    <draggable v-if="images" v-model="images" class="img-list" :disabled="false" animation="500"
      draggable=".img-item">
      <template v-if="images.length > 0">
        <div class="img-item" v-for="(img, index) in images" :key="index">
          <options-image :src="images[index]" :preview-src-list="images" @delete="images.splice(index, 1)">
          </options-image>
        </div>
      </template>
      <div class="img-item-add" v-if="images.length < limit">
        <add-button :height="60" :width="60" @click="handleShowImgSelect" text=""></add-button>
      </div>
    </draggable>
    <div class="img-tips" style="line-height: 12px;margin-top: 0">尺寸推荐 300*300</div>
    <div style="font-size: 12px;color: #3479EF;margin-top: 16px;line-height: 17px;">
      拖动可调整图片的展示顺序
    </div>
    <media-selector v-model="show" :max="limit - images.length" ratio="180:180" multiple @select="handleMedia">
    </media-selector>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AddButton from "@/base/components/Base/AddButton";
import OptionsImage from "@/base/components/Media/OptionsImage";
import MediaSelector from "../../../common/components/MediaSelector";
export default {
  name: "st-drag-image-selector",
  components: {
    MediaSelector,
    draggable,
    AddButton,
    OptionsImage,
  },
  model: {
    prop: "imageList",
    event: "imageChange",
  },
  props: {
    label: {
      type: String,
    },
    imageList: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 9,
    },
  },
  computed: {
    images: {
      get() {
        return this.imageList;
      },
      set(val) {
        this.$emit("imageChange", val);
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    handleShowImgSelect() {
      this.show = true;
    },
    handleMedia(data) {
      data.forEach((img, index) => {
        this.images.push(img.url);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  line-height: 14px;
  margin-bottom: 12px;
  font-size: 14px;
  color: #606266;
}
.img-list {
  /*width: 278px;*/
  display: flex;
  flex-wrap: wrap;
}

.img-item,
.img-item-add {
  width: 60px;
  height: 60px;
  margin-bottom: 12px;
  margin-right: 12px;
}

.img-item {
  box-sizing: border-box;
  cursor: move;

  /*&:nth-child(4n) {*/
  /*  margin-right: 0;*/
  /*}*/
}

.img-tips {
  font-size: 12px;
  color: #c0c4cc;
  margin-top: 12px;
}
</style>
