<!--
 * @Author: dongjia
 * @Date: 2021-06-24 17:23:44
 * @LastEditTime: 2021-06-24 17:40:19
 * @LastEditors: aleaner
 * @Description: 文章详情页面设置
 * @FilePath: \app-design\components\Preview\StArticleDetail.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="st-nav">
    <st-page-set title="文章详情" :jump-config="jumpConfig"></st-page-set>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
export default {
  name: "StArticleDetail",
  components: {StPageSet},
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
    jumpConfig: Object,
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          title: "",
          titleColor: "",
          statusBarColor: "",
          paddingBottom: 0,
        },
        share: {
          title: "",
          description: "",
          image: "",
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {
    // console.log('st-nav', this.config, JSON.stringify(this.config));
  },
};
</script>

<style lang="scss" scoped>
.st-nav {
  width: 100%;
  overflow: hidden;

  .header {
    position: relative;
    padding: 20px 11px 0;
    transition: opacity 0.5s;
    width: 100%;
    background-color: transparent;
    z-index: 2;

    .header-content {
      height: 44px;
      display: flex;
      justify-content: space-between;
      position: relative;
      div {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .title {
        width: 100%;
        justify-content: center;
      }

      .back-icon {
        width: 20px;
        height: 20px;
        margin-right: auto;
      }
      .handle-icon {
        position: absolute;
        right: 0;
        width: 87px;
        height: 32px;
        margin-left: auto;
      }
    }
  }
}
.status-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px !important;
  z-index: 2;
}

.default-back-icon {
  width: 20px;
  height: 20px;
}
</style>

