<template>
  <div class="st-time-range el-form-item flex-col">
    <span class="st-time-range-title">起止时间</span>
    <div class="gap"></div>
    <!--    <el-date-picker v-model="myRange" type="datetimerange"-->
<!--                    start-placeholder="开始时间" end-placeholder="结束时间"/>-->
    <el-date-picker v-model="myStartTime" type="datetime"
                    @change="handleChange" :picker-options="pickerOptions"
                    placeholder="开始时间" format="yyyy-MM-dd HH:mm"/>
    <div class="gap"></div>
    <el-date-picker v-model="myEndTime" type="datetime"
                    @change="handleChange" :picker-options="pickerOptionsEnd"
                    placeholder="结束时间" format="yyyy-MM-dd HH:mm"/>
  </div>
</template>

<script>
  export default {
    name: 'StTimeRange',
    props: {
      value: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {
        myStartTime: "",
        myEndTime: "",
        pickerOptions: {
          disabledDate: (time) => {
            return this.limit(time, 'start');
          },
        },
        pickerOptionsEnd: {
          disabledDate: time => {
            return this.limit(time, 'end')
          }
        }
      }
    },
    watch: {
      value: {
        handler(newValue) {
          if (newValue.startTime === 0) {
            this.myStartTime = "";
          } else {
            this.myStartTime = newValue.startTime * 1000;
          }
          if (newValue.endTime === 0) {
            this.myEndTime = "";
          } else {
            this.myEndTime = newValue.endTime * 1000;
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      limit(time, key) {
        if (key === 'start') {
          let endTime = this.myEndTime || 0
          /** 选择开始时间，要求值不能大于结束时间，return 值为 true 则表示禁用该日期 */
          return time.getTime() > endTime
        } else {
          let startTime = this.myStartTime || 0
          /** 选择结束时间，要求值不能小于开始时间 */
          return time.getTime() < startTime
        }
      },
      handleChange(e) {
        this.$emit('input', {
          startTime: this.myStartTime === '' ? 0 : this.myStartTime / 1000,
          endTime: this.myEndTime === '' ? 0 : this.myEndTime / 1000
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.st-time-range {
  .st-time-range-title {
    line-height: 30px;
    font-size: 14px;
    color: #606266;
  }

  .gap {
    height: 10px;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
