var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"st-shop-category-page"},[_c('div',{staticClass:"header"},[(_vm.platform !== 'h5')?[_c('img',{staticClass:"start-top-img",attrs:{"src":_vm.statusBar[_vm.myConfig.normal.statusBarColor]}}),_c('div',{staticClass:"start-top",staticStyle:{"text-align":"end"}},[(_vm.myConfig.normal.showHeaderJump)?_c('img',{staticClass:"header-jump",class:[
            _vm.headerJump.showConfig.showType !== 'template1'
              ? 'template-back-icon'
              : 'default-back-icon' ],attrs:{"src":_vm.backIcon[_vm.myConfig.normal.statusBarColor][
              _vm.headerJump.showConfig.showType
            ]}}):_vm._e(),_c('img',{staticClass:"start-top-handler",attrs:{"src":_vm.handleIcon[_vm.myConfig.normal.statusBarColor]}})])]:_c('st-page-set',{attrs:{"config":_vm.myConfig}}),_c('div',{staticClass:"type-select",class:[_vm.platform === 'h5' ? 'simple' : '']},[(_vm.myConfig.normal.titleConfig.isShow)?_c('div',{staticClass:"type-item",style:({
          color: _vm.myConfig.normal.fontColor,
          fontWeight: _vm.myConfig.normal.fontWeight,
        })},[_vm._v(" "+_vm._s(_vm.myConfig.normal.titleConfig.name)+" ")]):_vm._e()])],2),(!_vm.loading)?_c('st-search',{attrs:{"config":_vm.searchConfig}}):_vm._e(),(_vm.config.templateData.styleType === 'template1' && !_vm.loading)?_c('CategoryTemplate1',{attrs:{"config":_vm.config}}):(_vm.config.templateData.styleType === 'template2' && !_vm.loading)?_c('CategoryTemplate2',{attrs:{"config":_vm.config}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }