<template>
  <div class="st-channels-live-list" :style="[myRootStyle]" v-loading="loading">
    <st-list-title :config="myConfig" style="padding: 12px 16px 0;"/>
    <div v-if="myStyleType === 'template1'" class="list template2">
      <template v-for="(item, index) in list">
        <div class="item" :key="index">
          <div class="cover-box" :style="{'border-radius': myTemplateData.borderRadius + 'px'}">
            <st-load-image :src="item.cover" :size="500" />
            <img class="icon" src="../../assets/images/st-channels/channels-play.png"/>
            <st-channels-live-status class="tag" :text="item.live_status"/>
          </div>
          <div class="title-mask" :style="{'border-radius': myTemplateData.borderRadius + 'px'}"></div>
          <div class="text clamp">{{item.theme}}</div>
        </div>
      </template>
    </div>
    <div v-else-if="myStyleType === 'template2'" class="list template3">
      <div><!-- 不能注释这个div，因为父级有伪类导致下面的.item的:first-child等选择器失效 -->
        <template v-for="(item, index) in list">
          <div v-if="index === 0" class="item" :key="index">
            <div class="bg-white" :style="{
            'border-radius': list.length > 1 ? '6px 6px 0 0' : '6px'
          }"></div>
            <div class="bg-filter-box" :style="{
            backgroundImage: `url(${fixSpecialImageUrl(item.cover, 'large', index)})`
          }">
              <div class="bg-filter"></div>
              <div class="bg-mask"></div>
            </div>
            <div class="cover-box">
              <st-load-image :src="item.cover" :size="200" />
              <img class="icon" src="../../assets/images/st-channels/channels-play.png"/>
            </div>
            <div class="content-box">
              <span class="title clamp2">
                <st-channels-live-status class="tag" :text="item.live_status"/>
                {{item.theme}}</span>
              <div class="number-box">
                <img class="icon" src="../../assets/images/st-channels/channels-eye.png"/>
                <span class="number">{{item.view_count}}</span>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="item" :key="index">
              <template v-if="item.live_status && item.live_status.indexOf('直播') !== -1">
                <img class="status" src="../../assets/images/st-channels/living.gif"/>
              </template>
              <span class="title clamp">{{item.theme}}</span>
              <img class="icon" src="../../assets/images/st-channels/channels-play-white.png"/>
            </div>
          </template>
        </template>
      </div>
    </div>
    <div v-else-if="myStyleType === 'template3'" class="list template4">
      <template v-for="(item, index) in list">
        <div class="item" :key="index" :style="{
            'border-radius': myTemplateData.borderRadius + 'px'
          }">
          <div class="cover-box" :style="{
            'border-radius': myTemplateData.borderRadius + 'px'
          }">
            <st-load-image :src="item.cover" :size="500" />
            <img class="icon" src="../../assets/images/st-channels/channels-play.png"/>
            <st-channels-live-status class="tag" :text="item.live_status"/>
          </div>
          <div class="title-mask" :style="{
            'border-radius': myTemplateData.borderRadius + 'px'
          }"/>
          <div class="text clamp">{{item.theme}}</div>
        </div>
      </template>
    </div>
    <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
      <div :class="[myConfig.loadingConfig.handleType]">
        {{myConfig.loadingConfig.handleText}}</div>
    </div>
  </div>
</template>

<script>
  import {channelsLiveList} from "@/modules/app-design/api/components";
  import StChannelsLiveStatus from "@/modules/app-design/components/Preview/StChannelsLiveStatus";
  import StLoadImage from './Common/StLoadImage'
  import componentMixin from "@/modules/app-design/mixins/component-mixin";

  const library = require("../../assets/jsons/component-library.json");
  const wxappComponents = library.custom_components.find(el => el.index === 'wxapp-components').components;
  const model = wxappComponents.find(el => el.component === 'st-channels-live-list').model

  const defaultList = require("../../assets/jsons/default-list.json").channelsLiveList;

  export default {
    name: "StChannelsLiveList",
    mixins: [componentMixin],
    components: {StLoadImage, StChannelsLiveStatus},
    data() {
      return {
        loading: true,
        model: model,
        defaultList,
        list: [],
      }
    },
    watch: {
      "myConfig.loadingConfig.limit"(val) {
        this.getList(false);
      },
      "myConfig.dataConfig.dataType"(val) {
        this.getList(false);
      },
      "myConfig.dataConfig.dataArr"(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.getList(false);
        }
      },
      "myConfig.dataConfig.dataAll"(val) {
        this.getList(false);
      },
      "myConfig.dataConfig.dataFilter"(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.getList(false);
        }
      },
    },
    created() {
      this.getList()
    },
    methods: {
      getList(notCancel) {
        let ids = [];
        this.myConfig.dataConfig.dataArr.forEach((el) => {
          ids.push(el.id);
        });
        let requestData = {
          page: 1,
          page_size: this.myConfig.loadingConfig.limit,
          category_all: this.myConfig.dataConfig.dataAll,
          category_ids: this.myConfig.dataConfig.dataFilter,
          keyword: "",
          limit: this.myConfig.loadingConfig.limit,
          use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
          ids,
          account_id: ""
        };
        this.loading = true;
        channelsLiveList(requestData, notCancel)
          .then((res) => {
            const { data } = res;
            const defaultData = this.defaultList;
            this.list = data.length ? data : defaultData;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/component";

  .st-channels-live-list {
    min-height: 150px;

    background-size: 100% auto;
    background-repeat: repeat-y;
    overflow-x: hidden;

    .tag {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 2;
    }

    .list {
      padding: 12px 16px;

      &.template2 {
        display: flex;
        flex-wrap: nowrap;

        .item {
          height: 196px;
          width: 342px;
          flex-shrink: 0;
          position: relative;

          &+.item {
            margin-left: var(--data-spacing);
          }

          .title-mask {
            width: 342px;
            height: 93px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
            position: absolute;
            bottom: 0;
            left: 0;
          }

          .cover-box {
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 6px;
            overflow: hidden;
            background-color: #C4C4C4;

            .cover {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .text {
            width: 318px;
            position: absolute;
            bottom: 16px;
            left: 12px;
            font-size: 14px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 21px;
          }

          .icon {
            width: 32px;
            height: 32px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &.template4 {
        display: flex;
        flex-wrap: nowrap;

        .item {
          padding: 12px;
          background-color: white;

          &+.item {
            margin-left: var(--data-spacing);
          }

          .cover-box {
            width: 203px;
            height: 203px;
            position: relative;
            border-radius: 6px;
            overflow: hidden;
            background-color: #C4C4C4;

            .cover {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .icon {
              width: 24px;
              height: 24px;
              right: 8px;
              bottom: 8px;
              position: absolute;
            }
          }

          .text {
            margin-top: 8px;
            height: 21px;
            font-size: 14px;
            color: #1A1A1A;
            line-height: 21px;
          }
        }
      }

      &.template3 {
        display: flex;
        flex-direction: column;

        .item {
          &:first-child {
            width: 100%;
            height: calc(156px + 12px);
            padding: 0 12px 12px;
            position: relative;
            display: flex;
            align-items: flex-start;
            background-color: unset;

            &::before, &::after {
              display: none;
            }

            .bg-white,
            .bg-filter-box,
            .bg-mask {
              width: 100%;
              height: 123px;
              position: absolute;
              bottom: 0;
              left: 0;
            }

            .bg-mask {
              background-color: rgba(22, 22, 22, .8);
            }

            .bg-white {
              background-color: white;
              border-radius: 6px 6px 0 0;
            }

            .bg-filter-box {
              border-radius: 6px;
              background-color: rgba(22, 22, 22, .7);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;

              overflow: hidden;

              .bg-filter {
                width: calc(100% + 28px);
                height: calc(100% + 28px);
                background: inherit;
                filter: blur(14px);
                margin-left: -14px;
                margin-top: -14px;
              }
            }

            .cover-box {
              width: 117px;
              height: 156px;
              border-radius: 6px;
              overflow: hidden;
              position: relative;
              display: flex;

              /*.cover {*/
              /*  width: 100%;*/
              /*  height: 100%;*/
              /*  margin: auto;*/
              /*  object-fit: cover;*/
              /*}*/

              .icon {
                width: 16px;
                height: 16px;
                position: absolute;
                right: 8px;
                bottom: 8px;
              }
            }

            .content-box {
              flex: 1;
              padding-top: calc(45px + 24px);
              margin-left: 12px;
              position: relative;
              display: flex;
              flex-direction: column;

              .title {
                font-size: 14px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 21px;

                .tag {
                  display: inline-block;
                  margin-right: 5px;
                  margin-bottom: 1px;
                  position: static;
                }
              }

              .number-box {
                margin-top: 8px;
                display: flex;
                align-items: center;

                .number {
                  font-size: 12px;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 18px;
                  margin-left: 8px;
                }
              }
            }
          }

          &:last-child {
            border-radius: 0 0 6px 6px;
          }

          &:nth-child(2) {
            &:after {
              display: none;
            }
          }

          padding: 12px 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          background-color: white;

          &:before {
            content: "";
            width: 4px;
            height: 4px;
            background-color: var(--main-color);
            border-radius: 50%;
          }

          &:after {
            content: "";
            width: calc(100% - 28px - 14px);
            height: .5px;
            position: absolute;
            top: 0;
            right: 14px;
            background-color:rgba(0, 0, 0, 0.1);
          }

          .title.clamp {
            flex: 1;
            margin-left: 10px;
            margin-right: 24px;
          }

          .icon {
            width: 16px;
            height: 16px;
          }

          .status {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
</style>
