<template>
  <div class="st-invitation-page">
<!--    <st-page-set title="邀请好友" :jump-config="jumpConfig" />-->
    <app-blank text="该系统页面暂时无法配置"/>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
import AppBlank from "@/modules/app-design/components/Preview/App/AppBlank";
export default {
  name: "StInvitationPage",
  components: {AppBlank, StPageSet},
  props: {
    config: {
      type: Object,
      required: true,
    },
    jumpConfig: Object,
  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>

</style>
