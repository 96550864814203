<!--
 * @Author: dongjia
 * @Date: 2022-01-12 10:01:50
 * @LastEditTime: 2022-01-20 18:22:37
 * @LastEditors: aleaner
 * @Description: 会刊列表组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StOnlineLearning.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="{
      padding: `12px ${myStyleType === 'template3' ? '16' : '0'}px`,
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage: myBgImage,
      '--dataSpacing': `${myConfig.templateData.dataSpacing}px`,
    }"
  >
    <StListTitle
      :style="{
        padding: `0 ${myStyleType !== 'template3' ? '16' : '0'}px 16px`,
      }"
      :config="myConfig"
    />
    <!-- 数据列表 -->
    <div class="data-list">
      <!-- 分类 -->
      <div class="category-box">
        <div class="category-list">
          <div class="item active">全部</div>
          <div class="item" v-for="item in yearList">{{ item }}</div>
        </div>
      </div>
      <!-- 模板一 -->
      <template v-if="myConfig.templateData.styleType === 'template1'">
        <div class="journal-list-template1">
          <div class="journal-item-box" v-for="item in template1List">
            <div class="item-box" v-for="item2 in item">
              <img
                v-if="item2.cover"
                :src="item2.cover"
                class="cover"
              />
              <div v-else class="cover"></div>
              <div class="right-box">
                <div class="title">{{ item2.name }}</div>
                <div class="desc">{{ item2.desc }}</div>
                <div class="tigs-box">
                  <div class="tigs">{{ item2.year }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 模板二 -->
      <template v-if="myConfig.templateData.styleType === 'template2'">
        <div class="journal-list-template2">
          <div class="journal-item-box" v-for="item in dataList">
            <div
              class="cover-box"
              :style="{
                paddingTop: `calc(100% * 1.3)`,
                borderRadius: `${myConfig.templateData.borderRadius}px`,
              }"
            >
              <img
                v-if="item.cover"
                :src="item.cover"
                class="cover"
              />
              <div v-else :src="item.cover" class="cover"></div>
            </div>

            <div class="title">{{ item.name }}</div>
            <div class="desc">{{ item.desc }}</div>
            <div class="tigs-box">
              <div class="tigs">{{ item.year }}</div> 
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="load-more" v-if="myLoadingConfig.loadingType === 'handle'">
      <div :class="[myLoadingConfig.handleType]">
        {{ myLoadingConfig.handleText }}
      </div>
    </div>
  </div>
</template>
<script>
import { journalList, journalYearList } from '../../api/components'
import componentMixin from '@/modules/app-design/mixins/component-mixin'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'

export default {
  name: 'StOnlineLearning',
  components: { StLoadImage },
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      defaultList: require('../../assets/jsons/default-list.json').brandList,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
        },
        templateData: {
          styleType: 'template1',
          dataSpacing: 12,
          dataGap: 12,
          borderRadius: 6,
        },
        loadingConfig: {
          loadingType: 'none',
          limit: 10,
          handleType: 'template1',
          handleText: '加载更多',
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '在线学习',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          showType: 'template1',
          title: '查看更多',
        },
      },
      form: {
        page: 1,
        page_size: 999999,
        tab: 'normal',
      },
      // 数据列表
      dataList: [],
      // 模板一 数据列表处理
      template1List: [],
      yearList: [],
    }
  },
  watch: {
    'myConfig.loadingConfig.limit'(val) {
      this.getList(false)
      console.log(this.myConfig.loadingConfig.limit)
    },
  },
  created() {
    // this.getOnlineLearningList()
    this.getList()
    this.getCategory()
    console.log(this.myConfig, 'this.myConfig')
  },
  methods: {
    getList() {
      this.loading = true
      journalList(this.form).then((res) => {
        this.dataList = res.data.data
        this.template1List = []
        console.log(this.dataList)
        this.dataList = this.dataList.slice(
          0,
          this.myConfig.loadingConfig.limit
        )
        // 数据列表每三项合并成一项数组
        for (let i = 0; i < this.dataList.length; i += 3) {
          this.template1List.push(this.dataList.slice(i, i + 3))
        }
        console.log(this.template1List)
        this.loading = false
      })
    },
    getCategory() {
      journalYearList().then((res) => {
        this.yearList = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;

  .data-list {
    padding: 0 16px;
    min-height: 100px;
    overflow: hidden;
    .category-box {
      margin-bottom: 14px;
      .category-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        .item.active {
          background: #1a75ff;
          color: #fff;
        }

        .item {
          padding: 2px 8px;
          border-radius: 46px;
          background: #f6f6f6;
          color: #b3b3b3;
          font-size: 13px;
          font-weight: 400;
          min-height: 24px;
          white-space: nowrap;
          display: flex;
          align-items: center;
        }
        .item + .item {
          margin-left: 12px;
        }
      }
    }
    // 模板一
    .journal-list-template1 {
      display: flex;

      .journal-item-box {
        width: 300px;

        .item-box {
          display: flex;
          align-items: center;

          .cover {
            min-width: 48px;
            height: 68px;
            border-radius: 4px;
            background: #dbd7d7;
            object-fit:cover;

          }

          .right-box {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
              color: #1a1a1a;
              font-size: 14px;
              font-weight: 500;
              width: 250px;
              @include nowrap();
            }
            .desc {
              color: #808080;
              font-size: 12px;
              font-weight: 400;
              width: 250px;
              @include nowrap();
            }
            .tigs-box {
              .tigs {
                border-radius: 50px;
                color: #b3b3b3;
                font-size: 12px;
                font-weight: 400;
                background: #f6f6f6;
                display: inline-block;
                padding: 3px 6px;
              }
            }
          }
        }
        .item-box + .item-box {
          margin-top: var(--dataSpacing);
        }
      }
      .journal-item-box + .journal-item-box {
        margin-left: 8px;
      }
    }
    // 模板二
    .journal-list-template2 {
      width: 100%;
      display: grid;
      gap: var(--dataSpacing);
      grid-template-columns: repeat(3, 1fr);
      .journal-item-box {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        .cover-box {
          position: relative;
          width: 100%;
          overflow: hidden;
          .cover {
            position: absolute;
            height: 100%;
            width: 100%;
            background: #dbd7d7;
            border-radius: 6px;
            top: 0;
            left: 0;
            object-fit:cover;
          }
        }

        .title {
          color: #1a1a1a;
          font-size: 13px;
          font-weight: 400;
          min-width: 0;

          @include nowrap();
        }
        .desc {
          color: #1a1a1a;
          font-size: 13px;
          font-weight: 400;
          min-width: 0;
          @include nowrap();
        }
        .tigs-box {
          margin-top: 4px;
          .tigs {
            border-radius: 50px;
            color: #b3b3b3;
            font-size: 12px;
            font-weight: 400;
            background: #f6f6f6;
            display: inline-block;
            padding: 3px 6px;
          }
        }
      }
    }
  }
}
</style>
