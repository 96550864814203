<!--
 * @Author: dongjia
 * @Date: 2022-01-12 10:01:50
 * @LastEditTime: 2022-01-20 18:22:37
 * @LastEditors: aleaner
 * @Description: 在线学习组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StOnlineLearning.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="{
      padding: `12px  0px`,
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage: myBgImage,
      borderRadius: myConfig.normal.borderRadius + 'px',
    }"
  >
    <StListTitle
      :style="{
        padding: `0 16px 16px`,
      }"
      :config="myConfig"
    />
    <div
      v-loading="loading"
      class="data-list"
      :class="myStyleType === 'template1' ? 'flex-column' : 'grid-two-column'"
      v-if="['template1', 'template2'].includes(myStyleType)"
      :style="
        myStyleType === 'template2'
          ? `row-gap:${myTemplateData.dataGap}px;column-gap:${myTemplateData.dataGap}px;`
          : ''
      "
    >
      <div
        class="list-item"
        :class="
          myStyleType === 'template1' && myTemplateData.dataSpacing === 0
            ? 'no-data-spacing'
            : ''
        "
        :style="{
          marginTop:
            myStyleType === 'template1' && index > 0
              ? myTemplateData.dataSpacing + 'px'
              : '',
          borderRadius:
            myStyleType === 'template1' && myTemplateData.dataSpacing !== 0
              ? myTemplateData.borderRadius + 'px'
              : myStyleType === 'template1' &&
                ![0, onlineLearning_list.length - 1].includes(index)
              ? '0px'
              : myTemplateData.borderRadius + 'px',
        }"
        v-for="(item, index) in onlineLearning_list"
        :key="item.id"
      >
        <template v-if="myStyleType === 'template1'">
          <div class="template1">
            <div
              class="item-image-box"
              :style="{
                borderRadius: myTemplateData.borderRadius + 'px',
              }"
            >
              <st-load-image class="item-img" :src="item.cover" :size="300" />
            </div>
            <div class="item-content">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-tag">{{ item.category_name }}</div>
              <div class="content-bottom">
                <div class="bottom-left">
                  共 {{ item.chapters_count }} 章节
                  {{ item.courses_count }} 节课
                </div>
                <div class="bottom-right">
                  <img
                    class="right-icon"
                    src="../../assets/images/st-online-learning/view-icon.png"
                  />
                  <div class="right-text">{{ item.view_count }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="myStyleType === 'template2'">
          <div class="template2">
            <div
              class="item-image-box"
              :style="{
                borderRadius: myTemplateData.borderRadius + 'px',
              }"
            >
              <st-load-image class="item-img" :src="item.cover" :size="300" />
            </div>
            <div class="item-content">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-tag">{{ item.category_name }}</div>
              <div class="content-bottom">
                <div class="bottom-top">
                  共 {{ item.chapters_count }} 章节
                  {{ item.courses_count }} 节课
                </div>
                <div class="bottom-bottom">
                  <img
                    class="right-icon"
                    src="../../assets/images/st-online-learning/view-icon.png"
                  />
                  <div class="right-text">{{ item.view_count }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 模版三，四，快速开发 -->
    <template v-else-if="['template3','template4'].includes(myStyleType)">
      <!-- 模版三 -->
      <div
        v-loading="loading"
        class="template3-data-list"
        v-if="['template3','template4'].includes(myStyleType)"
      >
        <div class="item-list">
          <div
            class="item"
            v-for="(item, i) in onlineLearning_list"
            :key="item.id"
            :style="{
              marginLeft: `${i > 0 ? myConfig.templateData.dataSpacing : 0}px`,
              borderRadius: myTemplateData.borderRadius + 'px',
            }"
          >
            <img class="cover" :src="item.cover" />
            <div class="title-box">
              <div class="name">{{ item.name }}</div>
              <div class="views-count">
                <img
                  class="right-icon"
                  src="../../assets/images/st-online-learning/view-icon.png"
                />
                <div class="right-text">{{ item.view_count }}人观看</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="load-more" v-if="myLoadingConfig.loadingType === 'handle'">
      <div :class="[myLoadingConfig.handleType]">
        {{ myLoadingConfig.handleText }}
      </div>
    </div>
  </div>
</template>

<script>
import { onlineLearningList } from '../../api/components'
import componentMixin from '@/modules/app-design/mixins/component-mixin'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'

export default {
  name: 'StOnlineLearning',
  components: { StLoadImage },
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      defaultList: require('../../assets/jsons/default-list.json')
        .OnlineLearningList,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
          borderRadius: 0,
        },
        templateData: {
          styleType: 'template1',
          dataSpacing: 12,
          dataGap: 12,
          borderRadius: 6,
        },
        dataConfig: {
          dataType: 'select',
          orderRule: 'normal',
          dataArr: [
            {
              id: '',
              name: '',
            },
          ],
          dataAll: 1,
          dataFilter: [''],
          dataShowType: ['self_data', 'gov_data'],
        },
        loadingConfig: {
          loadingType: 'none',
          limit: 10,
          handleType: 'template1',
          handleText: '加载更多',
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '在线学习',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          showType: 'template1',
          title: '查看更多',
        },
      },
      onlineLearning_list: [],
    }
  },
  watch: {
    'myLoadingConfig.limit'(val) {
      this.getOnlineLearningList(false)
    },
    'myConfig.dataConfig.dataType'(val) {
      this.getOnlineLearningList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getOnlineLearningList(false)
      }
    },
    'myConfig.dataConfig.dataAll'(val) {
      this.getOnlineLearningList(false)
    },
    'myConfig.dataConfig.dataShowType'(val) {
      this.getOnlineLearningList(false)
    },
    'myConfig.dataConfig.orderRule'(val) {
      this.getOnlineLearningList(false)
    },
    'myConfig.dataConfig.dataFilter'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getOnlineLearningList(false)
      }
    },
  },
  created() {
    this.getOnlineLearningList()
  },
  methods: {
    getOnlineLearningList(notCancel) {
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        page: 1,
        limit: this.myLoadingConfig.limit,
        category_all: this.myConfig.dataConfig.dataAll,
        category_names: this.myConfig.dataConfig.dataFilter,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        template: this.myStyleType,
  
        // 数据类型
        data_sources: this.myConfig.dataConfig.dataShowType,
        // 排序
        order_rule: this.myConfig.dataConfig.orderRule,
        ids,
      }
      this.loading = true
      onlineLearningList(requestData, notCancel)
        .then((res) => {
          const { data } = res
          this.onlineLearning_list = data.length
            ? data
            : this.defaultList.slice(0, this.myLoadingConfig.limit)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/component';

.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;

  .data-list {
    padding: 0 16px;
    min-height: 100px;

    &.flex-column {
      .no-data-spacing + .no-data-spacing {
        &:before {
          content: '';
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 0;
          left: 8px;
          right: 8px;
        }
      }
      .no-data-spacing {
        position: relative;
        &:nth-child(2) {
          border-bottom-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
        &:last-child {
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
        }
      }

      .list-item {
        .template1 {
          display: flex;

          .item-image-box {
            flex-shrink: 0;
            width: 112px;
            height: 112px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
          }
          .item-content {
            flex-grow: 1;
            margin-left: 8px;
            position: relative;
            .item-name {
              font-size: 16px;
              font-weight: bold;
              color: #1a1a1a;
              line-height: 1.5;
            }
            .item-tag {
              width: fit-content;
              margin-top: 8px;
              padding: 0 3px;
              height: 18px;
              background-color: var(--help-color);
              color: var(--main-color);
              border-radius: 2px;
              font-size: 12px;
              line-height: 1.5;
            }
            .content-bottom {
              position: absolute;
              bottom: 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .bottom-left {
                font-size: 12px;
                color: #808080;
                line-height: 1.5;
              }
              .bottom-right {
                display: flex;
                align-items: center;
                .right-icon {
                  width: 16px;
                  height: 16px;
                }
                .right-text {
                  font-size: 12px;
                  color: #b3b3b3;
                  line-height: 1.5;
                  margin-left: 7px;
                }
              }
            }
          }
        }
      }
    }
    &.grid-two-column {
      display: grid;
      grid-template-columns: repeat(2, minmax(10px, 1fr));

      .template2 {
        display: flex;
        flex-direction: column;
        .item-image-box {
          flex-shrink: 0;
          width: 100%;
          position: relative;
          overflow: hidden;
          &::before {
            content: '';
            display: block;
            padding-top: 56%;
          }
          .item-img {
            position: absolute;
            top: 0;
            left: 0;
          }
          .default-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .item-content {
          height: 126px;
          margin-top: 8px;
          position: relative;
          .item-name {
            font-size: 14px;
            font-weight: bold;
            color: #1a1a1a;
            line-height: 1.5;
          }
          .item-tag {
            width: fit-content;
            margin-top: 8px;
            padding: 0 3px;
            height: 18px;
            background-color: var(--help-color);
            color: var(--main-color);
            border-radius: 2px;
            font-size: 12px;
            line-height: 1.5;
          }
          .content-bottom {
            position: absolute;
            bottom: 0;
            width: 100%;

            .bottom-top {
              font-size: 12px;
              color: #808080;
              line-height: 1.5;
            }
            .bottom-bottom {
              margin-top: 8px;
              display: flex;
              align-items: center;
              .right-icon {
                width: 16px;
                height: 16px;
              }
              .right-text {
                font-size: 12px;
                color: #b3b3b3;
                line-height: 1.5;
                margin-left: 7px;
              }
            }
          }
        }
      }
    }
    .list-item {
      background-color: #fff;
      padding: 8px;
      .item-img {
        width: 100%;
        height: 100%;
      }
      .default-img {
        width: 28px;
        height: 28px;
      }
    }
  }
  .template3-data-list {
    min-height: 100px;
    overflow: hidden;
    padding: 0 12px;
    overflow: hidden;
    .item-list {
      width: max-content;
      display: flex;
      .item {
        width: 150px;
        height: 166px;
        border-radius: 8px;

        overflow: hidden;
        background-color: #f8f9fb;
        display: flex;
        flex-direction: column;
        .cover {
          width: 100%;
          height: 84px;
          object-fit: cover;
        }
        .title-box {
          flex: 1;
          padding: 8px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            color: #1a1a1a;
            font-size: 13px;
            @include clamp(2);
          }

          .views-count {
            display: flex;
            align-items: center;
            .right-icon {
              width: 12px;
              height: 12px;
            }
            .right-text {
              color: #a1a8af;
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              line-height: 18px;
              margin-left: 7px;
            }
          }
        }
      }
      .item + .item {
        margin-left: 8px;
      }
    }
  }
}
</style>
