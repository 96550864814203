var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article",style:([
    _vm.myRootStyle,
    {
      borderRadius: _vm.myConfig.normal.borderRadius + 'px',
      '--title-color': _vm.myConfig.listStyle.titleColor || 'black',
      '--time-color': _vm.myConfig.listStyle.timeColor || '#979da6',
    } ])},[_c('StListTitle',{style:({
      padding: ((_vm.myConfig.normal.colPadding) + "px " + (_vm.myConfig.normal.rowPadding) + "px 16px"),
    }),attrs:{"config":_vm.myConfig}}),(['template1', 'template2'].includes(_vm.myStyleType))?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"article-list",style:({
      padding: ((_vm.myConfig.listTitle.isShow ? '0' : '12px') + " " + (_vm.myConfig.normal.rowPadding) + "px " + (_vm.myConfig.normal.colPadding) + "px"),
    })},[_vm._l((_vm.dataList),function(item,index){return [_c('div',{key:index,staticClass:"list-item",class:[
          _vm.myStyleType,
          _vm.myConfig.templateData.dataSpacing ? '' : 'nearest' ],style:({
          marginTop:
            index === 0 ? '0' : _vm.myConfig.templateData.dataSpacing + 'px',
          borderRadius:
            _vm.myStyleType === 'template2'
              ? _vm.myConfig.templateData.borderRadius + 'px'
              : 0,
          backgroundColor: _vm.myConfig.listStyle.bgColor,
        })},[(item.type === 0)?_c('div',{staticClass:"text",class:[_vm.myStyleType]},[_c('div',{staticClass:"text-title"},[(item.item_type)?_c('span',{staticClass:"is-special"},[_vm._v("专题")]):_vm._e(),_vm._v(_vm._s(item.title)+" ")]),_c('div',{staticClass:"text-public"},[_c('span',[_vm._v(_vm._s(item.source_name))]),_c('span',[_vm._v(_vm._s(item.post_time))])])]):_vm._e(),(
            (item.type === 1 &&
              (!item.images || (item.images && item.images.length < 3)) &&
              _vm.myStyleType === 'template1') ||
            (_vm.myStyleType === 'template2' && item.type === 1)
          )?_c('div',{staticClass:"image-text",style:({
            flexDirection:
              _vm.myConfig.listStyle.imageText === 'left' ? 'row' : 'row-reverse',
          })},[_c('div',{staticClass:"video-images",class:[_vm.myStyleType],style:({
              marginRight:
                _vm.myConfig.listStyle.imageText === 'left'
                  ? _vm.myStyleType === 'template2'
                    ? '12px'
                    : '18px'
                  : '',
              marginLeft:
                _vm.myConfig.listStyle.imageText === 'right'
                  ? _vm.myStyleType === 'template2'
                    ? '12px'
                    : '18px'
                  : '',
              background: item.image.url ? '' : '#f3f5f7',
              borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
            })},[(!item.image.url)?_c('img',{staticClass:"default-icon",attrs:{"src":require("../../assets/images/mini-default-icon@2x.png")}}):_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"crossorigin":"anonymous","src":_vm.fixImageUrl(item.image.url, {}, _vm.randomId + '_' + index)}})]),_c('div',{staticClass:"texts",class:[_vm.myStyleType]},[_c('div',{staticClass:"text-title",staticStyle:{"height":"40px"}},[(item.item_type)?_c('span',{staticClass:"is-special"},[_vm._v("专题")]):_vm._e(),_vm._v(_vm._s(item.title)+" ")]),_c('div',{staticClass:"text-public"},[_c('span',[_vm._v(_vm._s(item.source_name))]),_c('span',[_vm._v(_vm._s(item.post_time))])])])]):_vm._e(),(
            item.type === 1 &&
            item.images &&
            item.images.length === 3 &&
            _vm.myStyleType === 'template1'
          )?_c('div',{staticClass:"image-text",style:({
            flexDirection:
              _vm.myConfig.listStyle.imageText === 'left' ? 'row' : 'row-reverse',
          })},[_c('div',{staticClass:"texts",class:[_vm.myStyleType]},[_c('div',{staticClass:"text-title",staticStyle:{"margin-bottom":"8px"}},[(item.item_type)?_c('span',{staticClass:"is-special"},[_vm._v("专题")]):_vm._e(),_vm._v(_vm._s(item.title)+" ")]),_c('div',{staticClass:"image-list"},_vm._l((item.images),function(el,imageI){return _c('div',{staticClass:"img-box"},[(!el.url)?_c('img',{staticClass:"default-icon",attrs:{"src":require("../../assets/images/mini-default-icon@2x.png")}}):_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"crossorigin":"anonymous","src":_vm.fixImageUrl(el.url, {}, _vm.randomId + '_' + index)}})])}),0),_c('div',{staticClass:"text-public"},[_c('span',[_vm._v(_vm._s(item.source_name))]),_c('span',[_vm._v(_vm._s(item.post_time))])])])]):_vm._e(),(item.type === 2)?_c('div',{staticClass:"large-image"},[_c('div',{staticClass:"text-title"},[(item.item_type)?_c('span',{staticClass:"is-special"},[_vm._v("专题")]):_vm._e(),_vm._v(_vm._s(item.title)+" ")]),_c('div',{staticClass:"large-images",style:({
              background: item.image.url ? '' : '#f3f5f7',
              borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
            })},[(!item.image.url)?_c('img',{staticClass:"default-icon",attrs:{"src":require("../../assets/images/mini-default-icon@2x.png")}}):_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"crossorigin":"anonymous","src":_vm.fixImageUrl(
                  item.image.url,
                  { w: 500 },
                  _vm.randomId + '_' + index
                )}})]),_c('div',{staticClass:"text-public",staticStyle:{"margin-top":"10px"}},[_c('span',[_vm._v(_vm._s(item.source_name))]),_c('span',[_vm._v(_vm._s(item.post_time))])])]):_vm._e(),(item.type === 3 && _vm.myConfig.listStyle.video !== 'big')?_c('div',{staticClass:"image-text",style:({
            flexDirection:
              _vm.myConfig.listStyle.video === 'left' ? 'row' : 'row-reverse',
          })},[_c('div',{staticClass:"video-images",style:({
              marginRight:
                _vm.myConfig.listStyle.video === 'left'
                  ? _vm.myStyleType === 'template2'
                    ? '12px'
                    : '18px'
                  : '',
              marginLeft:
                _vm.myConfig.listStyle.video === 'right'
                  ? _vm.myStyleType === 'template2'
                    ? '12px'
                    : '18px'
                  : '',
              background: item.video.thumbnail ? '' : '#f3f5f7',
            })},[_c('img',{staticClass:"default-icon",attrs:{"src":require("../../assets/images/video-play.png")}}),(item.video.thumbnail)?_c('img',{staticClass:"video-img",staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"crossorigin":"anonymous","src":_vm.fixImageUrl(item.video.thumbnail, {}, true)}}):_vm._e()]),_c('div',{staticClass:"texts"},[_c('div',{staticClass:"text-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text-public"},[_c('span',[_vm._v(_vm._s(item.source_name))]),_c('span',[_vm._v(_vm._s(item.post_time))])])])]):_vm._e(),(item.type === 3 && _vm.myConfig.listStyle.video === 'big')?_c('div',{staticClass:"large-image"},[_c('div',{staticClass:"text-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"large-images",style:({
              background: item.video.thumbnail ? '' : '#f3f5f7',
              borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
            })},[_c('img',{staticClass:"play",attrs:{"src":require("../../assets/images/video-play.png")}}),(item.video.thumbnail)?_c('img',{staticClass:"video-img",staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"crossorigin":"anonymous","src":_vm.fixImageUrl(item.video.thumbnail, {}, true)}}):_vm._e()]),_c('div',{staticClass:"text-public",staticStyle:{"margin-top":"10px"}},[_c('span',[_vm._v(_vm._s(item.source_name))]),_c('span',[_vm._v(_vm._s(item.post_time))])])]):_vm._e()])]}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText)+" ")])]):_vm._e()],2):(['template3'].includes(_vm.myStyleType))?_c('div',{staticClass:"template3-content flex"},[(_vm.dataList.length > 0)?[_vm._l((_vm.dataList),function(item,index){return [_c('div',{key:item.id,staticClass:"each-item"},[_c('div',{staticClass:"jinnang-item flex-col"},[_c('img',{staticStyle:{"aspect-ratio":"150/200","border-radius":"8px","overflow":"hidden"},attrs:{"src":item.images && item.images[0] && item.images[0].url || ''}}),_c('div',{staticClass:"info flex-col"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.name || item.title))])]),_c('div',{staticClass:"flex-between flex-align-center",staticStyle:{"margin-top":"6px"}},[_c('div',{staticClass:"tag flex-align-center"},[_c('span',{staticClass:"tag-text"},[_vm._v(_vm._s(item.category_name))])]),_c('span',{staticClass:"views"},[_vm._v(_vm._s(item.study_users_count)+" 人观看")])])])])])]})]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }