<!--
 * @Author: dongjia
 * @Date: 2022-03-02 10:16:53
 * @LastEditTime: 2022-03-05 14:20:14
 * @LastEditors: aleaner
 * @Description: 社区导航关注模块列表组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\st-community-nav\NavFollowList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="follow-list" :style="{
    backgroundColor: bgColor
  }">
    <FollowMemberList />
    <!-- 帖子列表模块 -->
    <NavPostList :bgColor="bgColor" :showTitle="true" title="全部" />
  </div>
</template>

<script>
import FollowMemberList from "./FollowMemberList";
import NavPostList from "./NavPostList.vue";
export default {
  components: { FollowMemberList, NavPostList },
  props: {
    bgColor: {
      type: String,
      default: "#ffffff",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.follow-list {
}
</style>