var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center-nav",style:({
    marginTop: 11 + _vm.myConfig.normal.marginTop + 'px',
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage:
      _vm.myConfig.normal.bgType === 'image'
        ? ("url(" + (_vm.fixImageUrl(_vm.myConfig.normal.bgImage, { h: 500 }, true)) + ")")
        : '',
  })},[(_vm.myConfig.normal.showType === 'normal')?_c('div',{staticClass:"nav-list normal",style:({
      color: _vm.myConfig.normal.textColor,
    })},_vm._l((_vm.myConfig.tabs.list),function(item,index){return _c('div',{key:index,staticClass:"nav-item"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('img',{staticClass:"nav-img",attrs:{"src":require("../../assets/images/st-me-page/handle-next.png")}})])}),0):_vm._e(),(_vm.myConfig.normal.showType === 'special')?_c('div',{staticClass:"nav-list special"},_vm._l((_vm.specialList),function(item,index){return _c('div',{key:index,staticClass:"nav-item"},[(index > 0)?_c('div',{staticClass:"nav-division"}):_vm._e(),_c('div',{staticClass:"special-list"},_vm._l((item),function(nav,i){return _c('div',{key:i,staticClass:"item-list",style:({
            flex: ("0 0 " + (100 / _vm.myConfig.normal.numLimit) + "%"),
          })},[_c('el-image',{staticClass:"nav-icon",attrs:{"src":nav.icon || require('../../assets/images/default-icon.png')}}),_c('p',{staticClass:"nav-name",style:({
              color: _vm.myConfig.normal.textColor,
            })},[_vm._v(" "+_vm._s(nav.name)+" ")])],1)}),0)])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }