<template>
  <div class="activity-item-desc">
    <!-- 线下 -->
    <template v-if="activity.type">
      <!-- 活动 -->
      <div class="join-num" v-if="activity.activity_status_text_two === 6">
        还没有开始报名
      </div>
      <!-- 没有人 -->
      <template v-if="activity.activity_users_count === 0">
        <!-- 活动未开始 -->
        <!-- <div class="join-num" v-if="activity.activity_status_text_two === 2">暂时没人报名</div> -->
        <!-- 活动进行中 -->
        <!-- <div class="join-num" v-if="activity.activity_status_text_two === 3">暂时没人观看</div> -->
        <!-- 活动已结束 -->
        <!-- <div class="join-num" v-if="activity.activity_status_text_two === 4">没人参加</div> -->
        <!-- 活动报名中 -->
        <!-- <div class="join-num" v-if="activity.activity_status_text_two === 5">暂时没人报名</div> -->
      </template>
      <!-- 有人 -->
      <template v-if="activity.activity_users_count">
        <div class="join-num" v-if="activity.activity_status_text_two === 2">
          {{ activity.activity_users_count }} 人报名
        </div>
        <div class="join-num" v-if="activity.activity_status_text_two === 3">
          {{ activity.activity_users_count }} 人看过
        </div>
        <div class="join-num" v-if="activity.activity_status_text_two === 4">
          {{ activity.activity_users_count }} 人报名
        </div>
        <div class="join-num" v-if="activity.activity_status_text_two === 5">
          {{ activity.activity_users_count }} 人报名
        </div>
      </template>
    </template>
    <!-- 线上 -->
    <template v-else>
      <!-- 直播未开始 -->
      <div class="join-num" v-if="activity.activity_status_text_two === 6">
        直播还未开始
      </div>
      <!-- 没人 -->
      <template v-if="activity.activity_users_count === 0">
        <!-- 未开始 -->
        <div class="join-num" v-if="activity.activity_status_text_two === 2">
          直播还未开始
        </div>
        <!-- 进行中 -->
        <div class="join-num" v-if="activity.activity_status_text_two === 3">
          直播已开始
        </div>
        <!-- 已结束 -->
        <!-- <div class="join-num" v-if="activity.activity_status_text_two === 4">没人观看</div> -->
      </template>
      <!-- 有人 -->
      <template v-if="activity.activity_users_count">
        <div
          class="join-num"
          v-if="
            activity.activity_status_text_two === 2 ||
            activity.activity_status_text_two === 6
          "
        >
          {{ activity.activity_users_count }} 人想看
        </div>
        <div class="join-num" v-if="activity.activity_status_text_two === 3">
          {{ activity.activity_users_count }} 人在看
        </div>
        <div class="join-num" v-if="activity.activity_status_text_two === 4">
          {{ activity.activity_users_count }} 人看过
        </div>
      </template>
    </template>
  </div>
</template>
<script>
/* 活动列表项 各状态参加人员文案 */
export default {
  name: 'sub-activity-item-desc',
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'activity.activity_users_count': {
      handler: function (newValue, oldValue) {
        if (newValue === undefined) return
        this.observeUsersCount()
      },
      immediate: true,
    },
  },
  mounted() {
    this.observeUsersCount()
  },
  methods: {
    observeUsersCount() {
      const { activity } = this

      if (!activity) return

      let text = '' // 展示的文案
      if (this.activity.type === 1) {
        if (this.activity.activity_status_text_two === 6) {
          text = '还没有开始报名'
        } else if (activity.activity_users_count === 0) {
        } else if (activity.activity_users_count) {
          switch (activity.activity_status_text_two) {
            case 2:
              text = `${activity.activity_users_count} 人报名`
              break
            case 3:
              text = `${activity.activity_users_count} 人看过`
              break
            case 4:
              text = `${activity.activity_users_count} 人报名`
              break
            case 5:
              text = `${activity.activity_users_count} 人报名`
              break
          }
        }
      } else {
        // 线下活动
        if (this.activity.activity_status_text_two === 6) {
          text = '直播还未开始'
        } else if (activity.activity_users_count === 0) {
          if (activity.activity_status_text_two === 2) {
            text = '直播还未开始'
          } else if (activity.activity_status_text_two === 3) {
            text = '直播已开始'
          }
        } else if (activity.activity_users_count) {
          switch (activity.activity_status_text_two) {
            case 2:
            case 6:
              text = `${activity.activity_users_count} 人想看`
              break
            case 3:
              text = `${activity.activity_users_count} 人在看`
              break
            case 4:
              text = `${activity.activity_users_count} 人看过`
              break
          }
        }
      }

      console.log(text, 'ttttttttt')

      this.$emit('text', text)
    },
  },
}
</script>
<style lang="scss">
.activity-item-desc {
  display: inline;
}
</style>
