var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"activity-status-btn status-btn",class:{
    'button': _vm.role === 'button',
    'tag': _vm.role === 'tag',
    'streaming-btn': _vm.activity[_vm.statusKey] === '直播中',
    'off-btn':
      [4].includes(_vm.activity.activity_status_text_two) ||
      _vm.activity[_vm.statusKey] === '已结束' ||
      _vm.activity[_vm.statusKey] === '报名未开始' ||
      _vm.activity[_vm.statusKey] === '活动未开始' ||
      _vm.activity[_vm.statusKey] === '直播已取消' ||
      _vm.activity[_vm.statusKey] === '禁播' ||
      _vm.activity[_vm.statusKey] === '已过期' ||
      _vm.activity[_vm.statusKey] === '异常',
    'active-btn':
      [3].includes(_vm.activity.activity_status_text_two) ||
      _vm.activity[_vm.statusKey] === '进行中' ||
      _vm.activity[_vm.statusKey] === '直播未开始' ||
      _vm.activity[_vm.statusKey] === '未开始',
    'ready-btn':
      [5].includes(_vm.activity.activity_status_text_two) ||
      _vm.activity[_vm.statusKey] === '报名中'
  },style:({
    'border-radius': _vm.role === 'tag' ? ("0 " + ((_vm.radius || 0)) + "px 0 " + ((_vm.radius || 0)) + "px") : ''
  })},[_vm._v(_vm._s(_vm.activity[_vm.statusKey]))])}
var staticRenderFns = []

export { render, staticRenderFns }