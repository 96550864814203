var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"liked-area",style:({
    marginTop: _vm.myConfig.normal.marginTop + 'px',
    backgroundColor: _vm.myConfig.normal.bgColor
  })},[(_vm.myConfig.templateData.styleType === 'template1')?_c('div',{staticClass:"liked-content template1"},[_c('p',{staticClass:"liked-tip"},[_vm._v("- "+_vm._s(10)+" 人点赞 -")]),_c('div',{staticClass:"liked-avatar"},_vm._l((_vm.defaultAvatars.concat( _vm.defaultAvatars)),function(avatar,index){return _c('div',{key:index,staticClass:"avatar-box"},[_c('el-image',{staticClass:"avatar-item",style:({
          borderRadius: _vm.myConfig.avatarConfig.borderRadius + 'px',
          border: ("1px " + (_vm.myConfig.avatarConfig.borderType) + " " + (_vm.myConfig.avatarConfig.borderColor))
        }),attrs:{"src":avatar}})],1)}),0)]):_vm._e(),(_vm.myConfig.templateData.styleType === 'template2')?_c('div',{staticClass:"liked-content template2"},[_c('div',{staticClass:"liked-avatar"},_vm._l((_vm.defaultAvatars.concat( _vm.defaultAvatars)),function(avatar,index){return _c('div',{key:index,staticClass:"avatar-box"},[_c('el-image',{staticClass:"avatar-item",style:({
          borderRadius: _vm.myConfig.avatarConfig.borderRadius + 'px',
          border: ("1px " + (_vm.myConfig.avatarConfig.borderType) + " " + (_vm.myConfig.avatarConfig.borderColor)),
          left: index * 20 + 'px',
          zIndex: index + 1
        }),attrs:{"src":avatar}})],1)}),0),_c('p',{staticClass:"liked-tip"},[_vm._v(_vm._s(10)+" 人点赞")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }