<!--
 * @Author: dongjia
 * @Date: 2021-06-21 16:45:20
 * @LastEditTime: 2021-10-27 11:59:41
 * @LastEditors: aleaner
 * @Description: 系统设置-电子名片
 * @FilePath: \app-design\components\Preview\StElectronCard.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="feedback">
    <img style="width: 100%;"  :src="`${baseImgUrl}/app_design/st-electron-card/card.jpg`" />
  </div>
</template>

<script>

export default {
  name: "st-electron-card",
  data(){
    return {
      baseImgUrl:''
    }
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
};
</script>

<style lang="scss" scoped>

</style>
