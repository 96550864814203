<template>
  <div class="source">
    <st-page-set title="文章搜索"></st-page-set>
    <div class="search" v-if="myConfig.searchConfig.placeholder.isShow">
      <div class="header-search">
        <div class="search-content">
          <p class="search-icon el-icon-search"></p>
          <p class="search-tip">{{  myConfig.searchConfig.placeholder.name }} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'
export default {
  name: 'StArticleSubject',
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        searchConfig: {
          placeholder: {
            name: '搜索内容',
            isShow: 1,
          },
        },
        "showConfig": {
          "showType": "column"
        }
      },
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss" scoped>
.source {
  width: 375px;
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
}
.search {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-size: cover;
  background-position: center;
  .header-search {
    width: 335px;
    background: #f8fbff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 10px 15px;

    .search-content {
      display: flex;
      align-items: center;
      .search-icon {
        font-size: 14px;
      }
      .search-tip {
        font-size: 14px;
        line-height: 14px;
        margin-left: 4px;
      }
    }
  }
}
</style>
