<template>
  <div
    class="brand-list"
    :style="[myRootStyle, {
      '--empty-icon-bg': defaultIconBgColor,
      padding: `12px ${['template3', 'template5', 'template9'].includes(myStyleType) ? '16' : '0'}px  ${
        ['template3', 'template4', 'template5', 'template9'].includes(myStyleType) ? '12' : '0'
      }px`
    }]"
  >
    <StListTitle
      :style="{
        padding: `0 ${!['template3', 'template5', 'template9'].includes(myStyleType) ? '16' : '0'}px 16px`,
      }"
      :config="myConfig"
    />
    <div
      v-loading="loading"
      class="swiper-view scroll"
      :class="[myConfig.dataConfig.interactiveType]"
      v-if="['template1', 'template2', 'template7', 'template8'].includes(myStyleType)"
    >
      <div
        class="list-item"
        :class="myStyleType"
        :style="{
          marginRight: myTemplateData.dataSpacing + 'px',
          backgroundImage: myStyleType === 'template8' && item.page_cover ? `url(${fixSpecialImageUrl(item.page_cover, 'large', index)})` : '',
          backgroundColor: myStyleType === 'template8' && !item.page_cover ? 'var(--empty-icon-bg)' : '',
        }"
        v-for="(item, index) in brand_list"
        :key="index"
      >
        <template v-if="myStyleType === 'template1'">
          <st-load-image class="item-image" :src="item.page_cover" :size="300" />
          <div class="item-content">
            <p class="item-title">{{ item.name }}</p>
            <div class="item-news" v-if="item.articles.length && item.articles[0].title||myConfig.dataConfig.interactiveType==='vertical'">
              <div class="news-tip">资讯</div>
              <div class="news-content">
                {{ item.articles.length && item.articles[0].title?item.articles[0].title:'暂无内容' }}
              </div>
            </div>
            <div class="item-news" v-if="item.activity.length && item.activity[0].name||myConfig.dataConfig.interactiveType==='vertical'">
              <div class="news-tip">活动</div>
              <div class="news-content">
                {{ item.activity.length && item.activity[0].name?item.activity[0].name:'暂无内容' }}
              </div>
            </div>
            <div class="item-collect">
              <div class="collect-avatar">
                <st-avatars :arr="item.organizations" />
              </div>
              <div class="collect-content">
                <span style="margin-right: 6px" v-if="item.organizations_count"
                  >共 {{ item.organizations_count > 999 ? "999+" : item.organizations_count }} 名会员</span
                >
                <span v-else>暂无会员</span>
              </div>
            </div>
          </div>
        </template>
        <template v-if="myStyleType === 'template2'">
          <div class="item-image">
            <st-load-image class="item-cover" :src="item.page_cover" :size="200" />
          </div>
          <div class="item-content">
            <p class="item-title">
              {{ item.name }}
            </p>
          </div>
        </template>
        <template v-if="myStyleType === 'template7'">
          <div
            class="item-box"
            :style="{
              backgroundColor: randomColor[index % randomColor.length],
            }"
          >
            <div class="item-content">
              <div class="item-name">{{ item.name }}</div>
              <div class="content-bottom" v-if="item.organizations_count">共 {{ item.organizations_count }} 名会员</div>
              <div class="content-bottom" v-else>暂无会员</div>
            </div>
          </div>
        </template>
        <template v-if="myStyleType === 'template8'">
          <div class="item-content">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-desc">{{ item.description }}</div>
            <div class="item-collect">
              <div class="collect-avatar">
                <st-avatars :arr="item.organizations"></st-avatars>
              </div>
              <div class="collect-content">
                <span style="margin-right: 6px" v-if="item.organizations_count"
                  >共 {{ item.organizations_count > 999 ? "999+" : item.organizations_count }} 名会员</span
                >
                <span v-else>暂无会员</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      v-loading="loading"
      class="swiper-view scroll-special"
      style="overflow-x: auto; overflow: hidden;"
      v-if="myStyleType === 'template6'"
    >
      <div
        class="list-item"
        :style="{
          marginRight: myTemplateData.dataSpacing + 'px',
        }"
        v-for="(list, index) in twoDataLists"
        :key="index"
      >
        <div
          class="item-list-item"
          :style="i > 0 ? 'padding-top: 8px' : 'padding-bottom: 8px'"
          v-for="(item, i) in list"
          :key="i"
        >
          <div class="item-image">
            <st-load-image class="item-cover" :src="item.page_cover" :size="200" />
          </div>
          <div class="item-content">
            <div class="item-name">{{ item.name }}</div>
            <div class="content-bottom" v-if="item.organizations_count">共 {{ item.organizations_count }} 名会员</div>
            <div class="content-bottom" v-else>暂无会员</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-loading="loading"
      style="padding-left: 0px"
      class="swiper-view flex-column"
      v-if="['template3', 'template5', 'template9'].includes(myStyleType)"
    >
      <div
        class="list-item"
        :class="myStyleType"
        :style="{
          marginTop: index === 0 ? '0' : myTemplateData.dataSpacing + 'px',
          backgroundImage: myStyleType === 'template9' && item.page_cover ? `url(${fixSpecialImageUrl(item.page_cover, 'large', index)})` : '',
          backgroundColor: myStyleType === 'template9' && !item.page_cover ? 'var(--empty-icon-bg)' : '',
        }"
        v-for="(item, index) in brand_list"
        :key="index"
      >
        <template v-if="myStyleType === 'template3'">
          <div class="item-image">
            <st-load-image class="item-cover" :src="item.page_cover" :size="200" />
          </div>
          <div class="item-content">
            <p class="item-title">{{ item.name }}</p>
            <p class="item-desc">{{ item.description }}</p>
          </div>
        </template>
        <template v-if="myStyleType === 'template5'">
          <StBrandItemBig :item="item" />
        </template>
        <template v-if="myStyleType === 'template9'">
          <div class="item-content">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-desc">{{ item.description }}</div>
            <div class="item-collect">
              <div class="collect-avatar">
                <st-avatars :arr="item.organizations" :indent="7"></st-avatars>
              </div>
              <div class="collect-content">
                <span style="margin-right: 6px" v-if="item.organizations_count"
                  >共 {{ item.organizations_count > 999 ? "999+" : item.organizations_count }} 名会员</span
                >
                <span v-else>暂无会员</span>
              </div>
              <div class="join-btn" v-if="item.is_joinable === 1">+ 申请加入</div>
            </div>
          </div>
        </template>
      </div>
      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{ myConfig.loadingConfig.handleText }}
        </div>
      </div>
    </div>
    <div v-loading="loading" style="padding: 0 16px" class="swiper-view template4" v-if="myStyleType === 'template4'">
      <!-- 循环播放 -->
      <div
        ref="mySwiper"
        class="banner-swiper"
        :style="{
          justifyContent: brand_list.length > 2 ? 'center' : '',
        }"
        v-if="brandSwiperList.length"
      >
        <!-- 通过添加swiper-no-swiping类名实现禁止拖动 -->
        <div class="swiper-no-swiping banner" v-for="(item, index) in brandSwiperList" :key="index">
          <st-load-image class="banner-image" :src="item.page_cover" :data-index="index"
                         :size="500" :border-radius="myTemplateData.borderRadius" />
<!--          <img-->
<!--            class="banner-image"-->
<!--            :class="{default: !item.page_cover}"-->
<!--            :style="{-->
<!--              borderRadius: myTemplateData.borderRadius + 'px',-->
<!--            }"-->
<!--            :src="fixSpecialImageUrl(item.page_cover, 'large', index)"-->
<!--            :data-index="index"-->
<!--          />-->
          <div class="banner-content" :data-index="index">
            <div class="content-tag">
              <el-image
                class="tag-img"
                fit="cover"
                :src="require('../../assets/images/st-brand-list/circle-star.png')"
              ></el-image>
              <p class="tag-content">{{ myConfig.listTitle.title ? myConfig.listTitle.title : "标题名称" }}</p>
            </div>
            <div class="content-mask">
              <div class="content-title">{{ item.name }}</div>
              <div class="item-collect">
                <div class="collect-avatar">
                  <st-avatars :arr="item.organizations"></st-avatars>
                </div>
                <div class="show-detail">查看详情</div>
              </div>
            </div>
          </div>
          <!-- <div v-show="false" :class="['swiper-pagination',myConfig.normal.indicatorType]">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { multiLevelList } from "../../api/components";
import StBrandItemBig from "@/modules/app-design/components/Preview/StBrandItemBig";
import StAvatars from "@/modules/app-design/components/Preview/Common/StAvatars";

import chunk from "lodash/chunk";
import componentMixin from "../../mixins/component-mixin";
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";

export default {
  name: "StMultiLevelList",
  components: {StLoadImage, StBrandItemBig, StAvatars },
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      defaultList: require("../../assets/jsons/default-list.json").multiLevelList,
      defaultAvatars: [
        require("../../assets/images/st-spirit-list/circle-avatar1.png"),
        require("../../assets/images/st-spirit-list/circle-avatar2.png"),
        require("../../assets/images/st-spirit-list/circle-avatar3.png"),
        require("../../assets/images/st-spirit-list/circle-avatar4.png"),
        require("../../assets/images/st-spirit-list/circle-avatar5.png"),
        require("../../assets/images/st-spirit-list/circle-avatar6.png"),
      ],
      randomColor: [
        "#00BD4C",
        "#6934FF",
        "#1BADFF",
        "#5498FF",
        "#1B76FF",
        "#1B24FF",
        "#FF495F",
        "#FF1B1B",
        "#FF7B1B",
        "#FFA41B",
      ],
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          rowMargin: 0,
        },
        templateData: {
          styleType: "template1",
          dataSpacing: 15,
          borderRadius: 8,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          dataFilter: "",
          interactiveType: "horizontal"
        },
        loadingConfig: {
          loadingType: "none",
          limit: 10,
          handleType: "template1",
          handleText: "加载更多",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "多层级组织",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
        },
      },
      brand_list: [],
    };
  },
  watch: {
    myStyleType(val) {
      this.getMultiLevelList(false);
    },
    "myConfig.loadingConfig.limit"(val) {
      this.getMultiLevelList(false);
    },
    "myConfig.dataConfig.dataType"(val) {
      this.getMultiLevelList(false);
    },
    "myConfig.dataConfig.dataArr"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getMultiLevelList(false);
      }
    },
  },
  computed: {
    twoDataLists() {
      return chunk(this.brand_list, 2);
    },
    brandSwiperList() {
      return this.brand_list.length > 2
        ? [this.brand_list[this.brand_list.length - 1], this.brand_list[0], this.brand_list[1]]
        : this.brand_list.length
        ? [this.brand_list[0], this.brand_list[1]]
        : [];
    },
  },
  created() {
    this.getMultiLevelList();
  },
  methods: {
    getMultiLevelList(notCancel) {
      this.loading = true;
      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });
      let requestData = {
        page: 1,
        limit: this.myConfig.loadingConfig.limit,
        use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
        template: this.myStyleType,
        ids,
      };
      multiLevelList(requestData, notCancel)
        .then((res) => {
          const { data } = res;
          this.brand_list = data.length ? data : this.defaultList;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/component';

.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;
  .list-title {
    padding: 12px 16px 16px;
  }

  .swiper-view {
    display: flex;
    padding-left: 16px;
    min-height: 100px;

    &.scroll {
      overflow: hidden;
      width: 100%;
      padding-bottom: 12px;
      .list-item {
        flex-shrink: 0;
        box-shadow: 0px 6px 10px 0px #f2f2f2;

        .item-image {
          position: relative;
          width: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          .item-cover {
            width: 100%;
            height: 100%;
          }

          .default-icon {
            width: 28px;
            height: 28px;
            z-index: 2;
          }
        }

        &.template1 {
          width: 192px;
          height: 100%;
          background-color: #fff;
          border-radius: 4px;

          .item-image {
            height: 108px;
            border-radius: 4px 4px 0 0;
          }

          .item-content {
            width: 100%;
            padding: 16px 10px;

            .item-title {
              font-size: 14px;
              font-weight: bold;
              color: #191e29;
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 12px;
            }

            .item-news {
              display: flex;
              align-items: center;
              margin-top: 8px;
              .news-tip {
                margin-right: 4px;
                padding: 0 3px;
                flex-shrink: 0;
                font-size: 8px;
                color: #333c50;
                border-radius: 2px 2px 0px 2px;
                border: 1px solid #333c50;
              }
              .news-content {
                font-size: 12px;
                color: #333c50;
                line-height: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .item-collect {
              margin-top: 8px;
              display: flex;
              align-items: center;

              .collect-avatar {
                display: flex;
                position: relative;
                // width: 74px;
                margin-right: 8px;
              }

              .collect-content {
                font-size: 12px;
                color: #666666;
                line-height: 12px;
              }
            }
          }
        }
        &.template2 {
          width: 143px;
          box-shadow: none;
          .item-image {
            height: 80px;
            border-radius: 4px;
          }
          .item-content {
            width: 100%;
            margin-top: 4px;

            .item-title {
              font-size: 12px;
              font-weight: bold;
              color: #191e29;
              line-height: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
        &.template7 {
          width: 146px;
          .item-box {
            height: 103px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .item-content {
              background-color: #fff;
              border-radius: 6px;
              height: 95px;
              padding: 8px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .item-name {
                font-size: 14px;
                font-weight: bold;
                color: #1a1a1a;
                line-height: 1.5;
                @include clamp(2);
              }
              .content-bottom {
                font-size: 12px;
                color: #808080;
                line-height: 1.5;
              }
            }
          }
        }
        &.template8 {
          width: 330px;
          height: 184px;
          border-radius: 6px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
          }
          .item-content {
            position: relative;
            padding: 12px;
            .item-name {
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
              line-height: 1.5;
              @include nowrap();
            }
            .item-desc {
              margin-top: 4px;
              font-size: 12px;
              color: #ffffff;
              line-height: 1.5;
              @include nowrap();
            }
            .item-collect {
              margin-top: 8px;
              display: flex;
              align-items: center;

              .collect-avatar {
                display: flex;
                position: relative;
                // width: 74px;
                margin-right: 8px;
              }

              .collect-content {
                font-size: 12px;
                color: #fff;
                line-height: 12px;
              }
            }
          }
        }
      }
    }

    &.scroll-special {
      width: 100%;
      padding-bottom: 12px;
      .list-item {
        width: 214px;
        flex-shrink: 0;
        .item-list-item + .item-list-item {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
        .item-list-item {
          display: flex;
          .item-image {
            position: relative;
            width: 64px;
            height: 64px;
            border-radius: 4px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            .item-cover {
              width: 100%;
              height: 100%;
            }

            .default-icon {
              width: 28px;
              height: 28px;
              z-index: 2;
            }
          }
          .item-content {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .item-name {
              font-size: 14px;
              font-weight: bold;
              color: #1a1a1a;
              line-height: 1.5;
              @include clamp(2);
            }
            .content-bottom {
              font-size: 12px;
              color: #808080;
              line-height: 1.5;
            }
          }
        }
      }
    }
    &.flex-column {
      flex-direction: column;
      // padding-bottom: 12px;
      .list-item {
        display: flex;
        width: 100%;

        .item-image {
          flex-shrink: 0;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          .item-cover {
            width: 100%;
            height: 100%;
          }

          .default-icon {
            width: 28px;
            height: 28px;
            z-index: 2;
          }
        }

        &.template3 {
          display: flex;
          .item-image {
            width: 143px;
            height: 80px;
            border-radius: 4px;
          }

          .item-content {
            margin-left: 10px;
            .item-title {
              font-size: 16px;
              font-weight: bold;
              color: #191e29;
              line-height: 22px;
              @include nowrap();
            }
            .item-desc {
              margin-top: 8px;
              font-size: 12px;
              color: #666666;
              line-height: 18px;
              @include clamp(2);
            }
          }
        }

        &.template5 {
          display: flex;
          flex-direction: column;
        }

        &.template9 {
          width: 100%;
          position: relative;
          border-radius: 6px;
          overflow: hidden;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &::before {
            content: "";
            display: block;
            padding-top: 76%;
          }
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
          }
          .item-content {
            position: absolute;
            bottom: 0px;
            width: 100%;
            padding: 12px;
            z-index: 2;
            .item-name {
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
              line-height: 1.5;
              @include nowrap();
            }
            .item-desc {
              margin-top: 4px;
              font-size: 12px;
              color: #ffffff;
              line-height: 1.5;
              @include clamp(2);
            }
            .item-collect {
              width: 100%;
              margin-top: 16px;
              display: flex;
              align-items: center;

              .collect-avatar {
                display: flex;
                position: relative;
                // width: 74px;
                margin-right: 8px;
              }

              .collect-content {
                font-size: 12px;
                color: #fff;
                line-height: 12px;
              }

              .join-btn {
                border: 1px solid #fff;
                margin-left: auto;
                padding: 0 8px;
                height: 24px;
                border-radius: 24px;
                line-height: 22px;
                font-size: 12px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }

    &.template4 {
      overflow: hidden;
      padding-left: 0px;
      min-height: 140px;
      padding-bottom: 12px;
      .banner-swiper {
        display: flex;
        height: 188px;
        width: 100%;
      }

      .banner + .banner {
        margin-left: 10px;
      }

      .banner {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .banner-content {
          box-sizing: border-box;
          position: absolute;
          overflow: hidden;
          // padding: 16px;
          top: 0;
          left: 0;
          z-index: 1;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          .content-tag {
            margin: 16px 16px 0px;
            margin-right: auto;
            display: flex;
            align-items: center;
            padding: 6px 8px 6px 6px;
            height: 28px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 14px;
            .tag-img {
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              margin-right: 4px;
            }
            .tag-content {
              font-size: 14px;
              color: #ffffff;
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .content-mask {
            margin-top: 59px;
            padding: 0px 16px 16px;
            // background: rgba(0, 0, 0, 0.3);
            // filter: blur(8px);
          }
          .content-title {
            padding-top: 7px;
            font-size: 20px;
            font-weight: bold;
            color: #ffffff;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .item-collect {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 14px;
            .collect-avatar {
              width: 74px;
              display: flex;
              position: relative;
              // width: 74px;
              margin-right: 8px;

              .avatar-item {
                background-color: #ccc;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid #ffffff;
              }
            }
            .show-detail {
              padding: 7px 14px;
              height: 28px;
              background: #ffffff;
              border-radius: 17px;
              font-size: 14px;
              color: var(--main-color);
              line-height: 14px;
            }
          }
        }

        .banner-image {
          width: 100%;
          height: 100%;
          object-fit: cover;

          &.default {
            background: var(--empty-icon-bg);
            object-fit: none;
          }
        }

        .banner-name {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          line-height: 21px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }

      .swiper-two {
        .swiper-item {
          position: relative;
          width: 100%;
          height: 140px;
          overflow: hidden;
          .item-content {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 140px;
          }
        }
        .advert-img {
          width: 100%;
          height: 100%;
        }
        .title-cover {
          position: absolute;
          width: 100%;
          height: 64px;
          padding: 20px 17px 10px;
          bottom: 0;
          background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          line-height: 21px;
        }
      }
    }

    &.vertical {
    /* two column */
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: var(--data-spacing);
      padding: 0 16px 16px;

      .list-item {
        min-width: 0;
        margin-right: 0 !important;
        width: 100% !important;

        .item-image {
          height: 93px !important;
        }
      }
    }
  }
}

  .item-cover {
    ::v-deep.el-image__inner {
      background-color: var(--empty-icon-bg);
    }
  }
</style>
