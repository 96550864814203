<!--
 * @Author: dongjia
 * @Date: 2021-10-16 13:42:57
 * @LastEditTime: 2022-03-30 10:07:07
 * @LastEditors: aleaner
 * @Description: 社区热门小组组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StHotGroup.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="content-container" :style="myRootStyle">
    <div
      class="brand-list"
      :style="[
        
        {
          minHeight: loading ? '100px' : '0px',
          '--data-spacing': '8px',
          '--swiper-color': myConfig.templateData.swiperColor,
          borderRadius: myConfig.normal.borderRadius + 'px',
          marginLeft: myConfig.normal.rowMargin + 'px',
          marginRight: myConfig.normal.rowMargin + 'px',
        },
      ]"
      v-loading="loading"
    >
      <!--通用标题 -->
      <div
        class="list-title"
        :style="{
          backgroundImage:
            myConfig.listTitle.isTitleDecoration && myConfig.listTitle.isAddIcon
              ? `url(${require('@/modules/app-design/assets/images/st-community-talent/bg-icon.png')})`
              : `url(${require('@/modules/app-design/assets/images/st-community-talent/bg.png')})`,
        }"
      >
        <div
          v-if="myConfig.listTitle.titleFamily === 'template2'"
          class="text"
          :style="{
            'font-size': myConfig.listTitle.fontSize + 'px',
            fontWeight: myConfig.listTitle.fontWeight,
            color: myConfig.listTitle.titleColor,
          }"
        >
          {{ myConfig.listTitle.title }}
        </div>
        <div
          class="art-text"
          v-if="
            myConfig.listTitle.titleFamily === 'template1' &&
            myConfig.listTitle.artFontPhoto
          "
        >
          <img :src="myConfig.listTitle.artFontPhoto" />
        </div>
      </div>

      <!-- 单列展示 -->
      <div
        class="template1 content-box"
        v-if="myStyleType === 'template1'"
        :style="{}"
      >
        <div
          class="item-box"
          v-for="(item, index) in list"
          :key="index"
          :style="{
            borderRadius: '4px',
            border:
              myConfig.normal.interfaceStyle == 'card'
                ? 'rgba(26, 117, 255, 0.1)'
                : 'none',
            backgroundColor:
              myConfig.normal.interfaceStyle == 'card' ? '#fff' : 'transparent',
          }"
        >
          <div
            class="list-item"
            :style="{
              padding:
                myConfig.normal.interfaceStyle == 'card' ? '12px' : '0px',
              background:
                myConfig.normal.interfaceStyle == 'card'
                  ? `linear-gradient(180deg,  rgba(253, 190, 32,0.05) 0%, rgba(253, 190, 32, 0) 100%)`
                  : 'none',
              border:
                myConfig.normal.interfaceStyle == 'card'
                  ? '0.5px solid #fdbe2040'
                  : 'none',
            }"
          >
            <div class="cover">
              <img :src="item.avatar || defaultAvatar" />
            </div>
            <div class="desc">
              <div class="title-box">
                <div class="name">{{ item.nickname }}</div>
                <div class="title-name">{{ item.title_name }}</div>
              </div>
              <template v-if="item.work_place && item.work_role">
                <div class="detail">
                  {{ item.work_place }} {{ item.work_role }}
                </div>
              </template>
              <template v-else-if="item.category_names">
                <div class="detail">
                  {{ item.category_names }}
                </div>
              </template>
            </div>
            <div class="bg">
                <img  src="@/modules/app-design/assets/images/st-community-talent/box-bg.png" />
              </div>
          </div>
        </div>
        <div
          class="load-more"
          v-if="myConfig.templateData.loadingType === 'handle'"
        >
          <div :class="[myConfig.templateData.handleType]">
            {{ myConfig.templateData.handleText }}
          </div>
        </div>
      </div>
      <!-- 两列展示 -->
      <div
        class="template2 content-box"
        v-if="myStyleType === 'template2'"
        :style="{
          padding:
            myConfig.normal.interfaceStyle == 'card'
              ? '10px 12px'
              : '10px 12px',
        }"
      >
        <div class="item-content">
          <div
            class="item-box"
            v-for="(item, index) in list.slice(0, 2)"
            :key="index"
          >
            <div
              class="list-item"
              :style="{
                borderRadius: '6px',
                padding:
                  myConfig.normal.interfaceStyle == 'card' ? '12px' : '0px',
                background:
                  myConfig.normal.interfaceStyle == 'card'
                    ? `linear-gradient(180deg,  rgba(253, 190, 32,0.05) 0%, rgba(253, 190, 32, 0) 100%)`
                    : 'none',
                border:
                  myConfig.normal.interfaceStyle == 'card'
                    ? '0.5px solid #fdbe2040'
                    : 'none',
              }"
            >
              <div class="top">
                <div class="cover">
                  <img :src="item.avatar || defaultAvatar" />
                  <!-- <img
                    v-if="index === 0"
                    class="tag"
                    src="@/modules/app-design/assets/images/st-community-talent/tag1.png"
                  />
                  <img
                    v-else
                    class="tag"
                    src="@/modules/app-design/assets/images/st-community-talent/tag2.png"
                  /> -->
                </div>
                <div class="desc">
                  <div class="name">{{ item.nickname }}</div>
                  <div class="title-name">{{ item.title_name }}</div>
                </div>
              </div>
              <template  v-if="item.work_place && item.work_role">
                <div class="bottom">
                  {{ item.work_place }} {{ item.work_role }}
                </div>
              </template>
              <template v-else-if="item.category_names">
                <div class="bottom" >
                  {{ item.category_names }}
                </div>
              </template>
              <div class="bg">
                <img  src="@/modules/app-design/assets/images/st-community-talent/box-bg.png" />
              </div>
            </div>
          </div>
        </div>
        <!-- 轮播图指示点 -->
        <div class="doi">
          <div class="item active"></div>
          <div class="item"></div>
          <div class="item"></div>
        </div>
      </div>
      <!-- 多列展示 -->
      <div
        class="template3 content-box"
        v-if="myStyleType === 'template3'"
        :style="{}"
      >
        <div class="item-content">
          <div
            class="item-box"
            v-for="(item, index) in list"
            :key="index"
            :style="{
              border:
                myConfig.normal.interfaceStyle == 'card'
                  ? '0.5px solid #fdbe2040'
                  : 'none',
              background:
                myConfig.normal.interfaceStyle == 'card'
                  ? `linear-gradient(180deg,  rgba(253, 190, 32,0.05) 0%, rgba(253, 190, 32, 0) 100%)`
                  : 'transparent',
              minWidth:
                myConfig.normal.interfaceStyle == 'card' ? '140px' : '108px',
            }"
          >
            <div
              class="list-item"
              :style="{
                padding:
                  myConfig.normal.interfaceStyle == 'card' ? '12px 0px' : '0px',
              }"
            >
              <img :src="item.avatar || defaultAvatar" />

              <div class="name">{{ item.nickname }}</div>

              <div class="member">{{ item.title_name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { communityTalentList } from "../../api/components";
import StAvatars from "@/modules/app-design/components/Preview/Common/StAvatars";
import componentMixin from "../../mixins/component-mixin";
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";

export default {
  name: "StHotGroup",
  mixins: [componentMixin],
  components: { StLoadImage, StAvatars },
  data() {
    return {
      loading: false,
      defaultList:
        require("../../assets/jsons/default-list.json").communityTalentList ||
        [],
      // 定义 config 格式和默认数据
      model: {
        normal: {
          interfaceStyle: "card",
          marginTop: 0,
          bgType: "default",
          bgColor: "rgba(255, 255, 255, 0)",
          bgImage: "",
          rowMargin: 0,
          borderRadius: 4,
        },
        templateData: {
          styleType: "template1",
          dataLimit: 12,
          swiperColor: "#606266",
          handleType: "template1",
          handleText: "加载更多",
          loadingType: "none",
        },
        listTitle: {
          titleFamily: "template1",
          artFontPhoto: require("@/modules/app-design/assets/images/st-community-talent/title.png"),
          titleColor: "#000000",
          fontWeight: "bold",
          fontSize: 18,
          title: "热门小组",
          isTitleDecoration: 1,
          isAddIcon: 1,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          category_type: "circle_group",
          group_category_ids: [""],
          organization_category_ids: [1],
          order_by: "interact_point",
          isDesc: 1,
        },
      },
      list: [],
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/wxapps/icons/avatar1.png",
    };
  },
  watch: {
    myConfig: {
      handler(val, oldVal) {
        // dataConfig 改变
        if (val.dataConfig.dataType !== oldVal.dataConfig.dataType) {
          this.getList();
        }
        if (
          val.dataConfig.group_category_ids.length !==
          oldVal.dataConfig.group_category_ids.length
        ) {
          this.getList();
        }
        if (
          val.dataConfig.organization_category_ids.length !==
          oldVal.dataConfig.organization_category_ids.length
        ) {
          this.getList();
        }
        if (val.dataConfig.order_by !== oldVal.dataConfig.order_by) {
          this.getList();
        }
        if (val.dataConfig.category_type !== oldVal.dataConfig.category_type) {
          this.getList();
        }
        if (val.dataConfig.isDesc !== oldVal.dataConfig.isDesc) {
          this.getList();
        }
        if (
          val.dataConfig.dataArr.length !== oldVal.dataConfig.dataArr.length
        ) {
          this.getList();
        }
        // 数据条数改变
        if (
          val.templateData.dataLimit.length !==
          oldVal.templateData.dataLimit.length
        ) {
          this.getList();
        }
      },
      deep: true,
    },
  },
  computed: {
    // brandFilterList() {
    //   return this.brand_list.filter(
    //     (el, elIndex) =>
    //       elIndex <
    //       (this.myConfig.loadingConfig.limit
    //         ? this.myConfig.loadingConfig.limit
    //         : 5)
    //   );
    // },
    linearConfig() {
      return this.myConfig.templateData.linearGradientConfig;
    },
    myRootStyle() {
      const rS = {};
      const myConfig = this.myConfig;
      if (myConfig.normal?.bgType) {
        rS.backgroundColor =
          myConfig.normal.bgType === "color" ? myConfig.normal.bgColor : "";
        rS.backgroundImage = this.myBgImage;
      }
      if (myConfig.normal) {
        rS.margin = `${myConfig.normal.marginTop}px ${0}px 0`;
        if (myConfig.normal.rowMargin) {
          rS["--row-margin"] = myConfig.normal.rowMargin + "px";
        }
      }
      if (myConfig.templateData?.dataSpacing) {
        rS["--data-spacing"] = myConfig.templateData.dataSpacing + "px";
      }
      return rS;
    },
  },
  created() {
    this.getList();
    console.log(this.myConfig, this.config);
  },
  methods: {
    getList(notCancel) {
      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });
      let requestData = {
        page: 1,
        limit: this.myConfig.templateData.dataLimit,
        order_by: this.myConfig.dataConfig.order_by,
        use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
        ids: ids,
        category_all: this.myConfig.dataConfig.dataType === "filter",
        category_ids:
          this.myConfig.dataConfig.category_type === "circle_group"
            ? this.myConfig.dataConfig.group_category_ids
            : this.myConfig.dataConfig.organization_category_ids,
        category_type: this.myConfig.dataConfig.category_type,
        is_desc: this.myConfig.dataConfig.isDesc,
      };

      this.loading = true;
      communityTalentList(requestData)
        .then((res) => {
          const { data } = res;

          // 过滤限制条数
          this.list = data.length ? data : this.defaultList;
          console.log(this.list);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 16px;

  .brand-list {
    background-size: 100% auto;
    background-repeat: repeat-y;
    margin: 0 16px;
    overflow: hidden;
    border: 0.5px solid rgba(#1a75ff, 0.1);
    background-color:#fff;
    .list-title {
      // background: linear-gradient(
      //   -270deg,
      //   rgba(#f1f3f4, 0.4) 0%,
      //   rgba(#dbe6f5, 0.4) 100%
      // );
      padding: 12px 12px 10px;
      background-size: 100% 100%;
      position: relative;
      .text {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
      }
      .art-text {
        img {
          width: 68px;
          height: 24px;
          object-fit: contain;
        }
      }

      .bg-icon {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .template1.content-box {
      padding: 0px 12px 10px;

      .item-box {
        background-color: #fff;
        border-radius: 4px;
        border: 0.5px solid rgba(#1a75ff, 0.1);
        overflow: hidden;
        .list-item {
          opacity: 1;
          background: linear-gradient(
            180deg,
            rgba(253, 190, 32, 0.05) 0%,
            rgba(253, 190, 32, 0) 100%
          );
          padding: 12px;
          display: flex;
          border: 0.5px solid #fdbe2040;
          border-radius: 4px;
          position: relative;

          .cover {
            margin-right: 12px;
            height: 62px;
            width: 62px;
            border-radius: 50%;
            img {
              height: 62px;
              width: 62px;
              border-radius: 50%;
            }
          }

          .desc {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title-box {
              display: flex;
              align-items: center;
              // justify-content: space-between;
              .name {
                color: #1a1a1a;
                font-size: 16px;
                font-weight: 500;
                @include clamp(1);
              }
              .title-name {
                margin-left: 8px;
                color: #b3b3b3ff;
                font-size: 12px;
                font-weight: 400;

                @include clamp(1);
              }

              .more {
                width: 16px;
                height: 16px;
                opacity: 1;
                border: 0.5px solid rgba(26, 117, 255, 0.1);
                background: rgba(26, 117, 255, 0.05);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                .el-icon-arrow-right {
                  font-size: 6px;
                  color: rgba(26, 117, 255, 1);
                  font-weight: 700;
                }
              }
              margin-bottom: 6px;
            }

            .detail {
              display: flex;
              align-items: center;
              color: #b3b3b3ff;
              font-size: 12px;
              font-weight: 400;
              @include clamp(1);
              .st-avatar {
                margin-right: 8px;
              }
            }
          }
          .bg {
              width:20px;
              height:12px;
              position: absolute;
              right:8px;
              bottom:8px;
            }
        }
      }

      .item-box + .item-box {
        margin-top: var(--data-spacing);
      }
    }

    .template2.content-box {
      padding: 0px 12px 10px;
      .item-content {
        display: flex;
        .item-box {
          flex: 1;
          // height: 155px;
          // padding-top: calc(50% - (var(--data-spacing) / 2));
          // height: 114px;
          overflow: hidden;
          position: relative;
          .list-item {
            // width: 100%;
            // height: 100%;
            // top: 0;
            // left: 0;
            // position: absolute;
            overflow: hidden;
            position:relative;
            .top {
              display: flex;
              align-items: center;
              .cover {
                width: 42px;
                height: 42px;
                position: relative;
                img {
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                }
                .tag {
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  border-radius: 50%;

                  bottom: 0px;
                  right: 0px;
                }
              }

              .desc {
                margin-left: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .name {
                  color: rgb(26, 26, 26);
                  font-size: 16px;
                  font-weight: 500;
                  @include clamp(1);
                }

                .title-name {
                  color: rgb(179, 179, 179);
                  font-size: 12px;
                  font-weight: 400;
                  margin-top: 4px;
                  min-height: 18px;
                  @include clamp(1);
                }
              }
            }
            .bottom {
              margin-top: 12px;
              color: #808080;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              @include clamp(2);
            }
            .bg {
              width:20px;
              height:12px;
              position: absolute;
              right:8px;
              bottom:8px;
            }
          }
        }
        .item-box + .item-box {
          margin-left: var(--data-spacing);
        }
      }

      .doi {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        .item.active {
          width: 8px;
          height: 4px;
          border-radius: 27px;
          opacity: 1;
          background-color: var(--swiper-color);
        }

        .item {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          opacity: 0.2;
          background-color: rgba(144, 147, 153);
        }
        .item + .item {
          margin-left: 4px;
        }
      }
    }

    .template3.content-box {
      padding: 0px 12px 10px;
      .item-content {
        display: flex;
        white-space: nowrap;
        .item-box {
          min-width: 130px;
          background: linear-gradient(
            180deg,
            rgba(26, 117, 255, 0.03) 0%,
            rgba(26, 117, 255, 0) 100%
          );
          border: 0.5px solid rgba(26, 117, 255, 0.1);
          overflow: hidden;
          border-radius: 4px;
          .list-item {
            padding: 12px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
              width: 62px;
              height: 62px;
              border-radius: 50%;
            }

            .name {
              margin-top: 8px;
              width: 96px;
              text-overflow: ellipsis;
              overflow: hidden;

              color: rgb(26, 26, 26);
              font-size: 16px;
              font-weight: 500;
              text-align: center;
              @include clamp(1);
              white-space: normal;
            }

            .member {
              margin-top: 4px;
              color: rgb(179, 179, 179);
              font-size: 12px;
              font-weight: 400;
              @include clamp(1);
              white-space: normal;
            }
          }
        }
        .item-box + .item-box {
          margin-left: var(--data-spacing);
        }
      }
    }
  }
}

// 加载更多样式
.load-more {
  width: 100%;
  text-align: center;
  margin-top: 20px;

  .template1, .template2 {
    font-size: 12px;
    color: var(--main-color);
    line-height: 17px;
  }

  .template2 {
    display: inline-block;
    padding: 3px 11px;
    border-radius: 2px;
    border: 1px solid var(--main-color);
  }
}
</style>
