var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:({
    padding: ("12px " + (_vm.myStyleType === 'template3' ? '16' : '0') + "px"),
    margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage: _vm.myBgImage,
    '--dataSpacing': ((_vm.myConfig.templateData.dataSpacing) + "px"),
  })},[_c('StListTitle',{style:({
      padding: ("0 " + (_vm.myStyleType !== 'template3' ? '16' : '0') + "px 16px"),
    }),attrs:{"config":_vm.myConfig}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"data-list"},[(_vm.myConfig.templateData.styleType === 'template1')?_vm._l((_vm.dataList),function(data,i){return _c('div',{key:data.id,staticClass:"article-item",style:({
          marginTop: i ? _vm.myConfig.templateData.dataSpacing + 'px' : 0,
          borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
        })},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"left-box"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(data.name))]),(data.is_top)?_c('div',{staticClass:"is_top"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/modules/app-design/assets/images/st-employ-carreer-list/is_top.png")}}),_vm._v(" 置顶 ")]):_vm._e()]),_c('div',{staticClass:"right"},[(data.min_wages === data.max_wages)?[_vm._v(_vm._s(data.max_wages)+"K")]:[_vm._v(" "+_vm._s(data.min_wages)+"-"+_vm._s(data.max_wages)+"K ")]],2)]),_c('div',{staticClass:"tags"},[_vm._l((data.tags),function(item,i){return _c('div',{key:i,staticClass:"tag"},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('div',{staticClass:"tag default"},[_vm._v(_vm._s(data.category_name))])],2),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"info"},[_c('img',{staticClass:"icon",attrs:{"src":data.company.logo,"mode":"aspectFill"}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(data.company.name))]),_c('div',{staticClass:"people"},[_vm._v(_vm._s(data.company.scale_text))])]),(data.region.length)?_c('div',{staticClass:"area"},[_vm._v(" "+_vm._s(_vm.beautifyJobCity(data.region).replace('·', ' '))+" ")]):_vm._e()])])}):_vm._e()],2),(_vm.myConfig && _vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText)+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }