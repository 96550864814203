<template>
  <div class="login-method-selector">
    <el-checkbox-group v-model="myValue" class="flex-col">
      <el-checkbox
        :label="op.id"
        v-for="op in options"
        :key="op.id"
        :disabled="myValue && myValue.length === 1 && myValue.includes(op.id)"
      >
        <div class="flex-col">
          <p>{{ op.title }}</p>
          <p class="info">
<!--            <i class="el-icon-warning-outline" />-->
            <i class="el-icon-warning" style="color: rgb(192, 196, 204);" />
            <template v-for="t in op.tips">
              <span v-if="typeof t === 'string'">{{t}}</span>
              <a v-if="t.link" :href="t.link" target="_blank">{{ t.text }}</a>
            </template>
          </p>
        </div>
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "LoginMethodSelector",
  props: {
    value: {
      type: Array,
      default: () => ['code-login'],
    },
  },
  data() {
    return {
      options: [
        {
          title: '快速登录',
          id: 'rapid-login',
          tips: [
            '勾选前请先前往微信小程序后台购买使用次数，',
            {
              text: '购买指引',
              link: 'https://www.yuque.com/ax101w/yz6xgk/mqqftmrw87wi69ev?singleDoc#',
            },
          ],
        },
        {
          title: '验证码登录',
          id: 'code-login',
          tips: [
            {
              text: '查看短信使用次数',
              link: '/admin/home',
            },
          ],
        },
      ],
    }
  },
  computed: {
    myValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    // 点击去购买
    goToPay() {
      // this.handleJumpUserAdmin("Home");
      this.$router.push({
        name: 'UserCenterExtrasSms',
        params: {
          project_id: this.$store.state.tenant.id || ''
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-checkbox {
  ::v-deep .el-checkbox__label {
    padding-left: 8px;
  }
}

.el-checkbox + .el-checkbox {
  margin-top: 24px;
}

.info {
  font-size: 10px;
  line-height: 1.3;
  margin-top: 8px;
  /*<!--color: $info;-->*/
  color: #c0c4cc;
  font-weight: normal;
  transform: scale(0.9);
  transform-origin: top left;

  i {
    margin-right: 3px;
    color: rgb(192, 196, 204);
    font-size: 13px;
  }

  &+.el-form-item__error {
    margin-top: 4px;
  }

  a {
    color: #2a74e8;
    text-decoration: none;

    &:hover,
    &:active {
      opacity: .7;
    }
  }
}
</style>
