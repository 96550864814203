var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-header"},[(['template1'].includes(_vm.myConfig.templateData.template))?[(_vm.platform !== 'h5')?_c('img',{staticClass:"status-bar",attrs:{"src":_vm.statusBar[_vm.page.props.normal.statusBarColor]}}):_vm._e()]:_vm._e(),(_vm.myConfig.templateData.template === 'template1')?_c('div',{staticClass:"header-template1 flex-col flex-align-center",style:({
      paddingLeft: _vm.myConfig.normal.rowMargin ? (_vm.myConfig.normal.rowMargin + 'px') : '0px',
      paddingRight: _vm.myConfig.normal.rowMargin ? (_vm.myConfig.normal.rowMargin + 'px') : '0px',
      //paddingBottom:  myConfig.contentConfig.showSearch ? '10px' : '',
    })},[_c('div',{staticClass:"head"},[_c('el-image',{staticClass:"head-logo",class:{auto: _vm.myConfig.contentConfig.iconShape === 'auto'},attrs:{"fit":"cover","src":_vm.myConfig.contentConfig.icon ||
          require('../../../assets/images/default-icon.png')}}),(_vm.myConfig.contentConfig.titleConfig.isShow)?_c('p',{staticClass:"head-title",style:({
          fontWeight: _vm.myConfig.contentConfig.fontWeight,
          fontSize: _vm.myConfig.contentConfig.fontSize + 'px',
          lineHeight: _vm.myConfig.contentConfig.fontSize + 'px',
          color: _vm.myConfig.contentConfig.fontColor,
        })},[_vm._v(" "+_vm._s(_vm.myConfig.contentConfig.titleConfig.name)+" ")]):_vm._e(),(_vm.myConfig.gov.isEnableOthers)?_c('img',{staticStyle:{"width":"7px","height":"3px","margin-left":"6px"},attrs:{"src":require("../../../assets/images/st-custom-header/dropdown-icon.png"),"fit":"contain"}}):_vm._e(),(_vm.platform !== 'h5')?_c('el-image',{staticClass:"handle-icon",staticStyle:{"position":"absolute","top":"27px","right":"10px"},attrs:{"src":_vm.handleIcon[_vm.page.props.normal.statusBarColor]}}):_vm._e()],1),(_vm.myConfig.contentConfig.showSearch)?_c('div',{staticClass:"uni-searchbar"},[_c('div',{staticClass:"search",style:([_vm.searchBoxStyle])},[_c('div',{staticClass:"search-box"},[_c('i',{staticClass:"search-icon el-icon-search",style:({
            color: _vm.myConfig.searchConfigTemplate1.placeholderColor,
          })}),_c('p',{staticClass:"search-placeholder",style:({
            color: _vm.myConfig.searchConfigTemplate1.placeholderColor,
          })},[_vm._v(" "+_vm._s(_vm.myConfig.searchConfigTemplate1.placeholder ? _vm.myConfig.searchConfigTemplate1.placeholder : '请输入搜索内容')+" ")])])])]):_vm._e(),(_vm.myConfig.contentConfig.showSearch)?_c('div',{staticClass:"spacing",style:({height:((_vm.myConfig.normal.paddingBottom) + "px")})}):_vm._e()]):(
      !['template5', 'template6'].includes(_vm.myConfig.templateData.template)
    )?[(_vm.myConfig.templateData.template !== 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.myConfig.templateData.template !== 'template7' && _vm.loading),expression:"myConfig.templateData.template !== 'template7' && loading"}],staticClass:"header-banner",class:[_vm.myConfig.templateData.template]},[(_vm.platform !== 'h5')?[_c('div',{staticStyle:{"height":"20px"}}),_c('el-image',{staticClass:"status-bar",attrs:{"src":_vm.statusBar[_vm.page.props.normal.statusBarColor]}})]:(
          _vm.myConfig.templateData.template === 'template3' ||
          _vm.myConfig.templateData.template === 'template4'
        )?[_c('div',{staticStyle:{"height":"32px"}})]:_vm._e(),(_vm.myConfig.templateData.template !== 'template7')?_c('div',{staticClass:"header-top",staticStyle:{"padding-right":"7px","padding-top":"4px","padding-left":"20px"},style:({
          paddingBottom: ((_vm.myConfig.templateData.template === 'template2'
              ? _vm.platform === 'h5'
                ? 174
                : 154
              : _vm.myConfig.templateData.template === 'template3'
              ? _vm.platform === 'h5'
                ? 196
                : 176
              : _vm.platform === 'h5'
              ? 256
              : 236) + "px"),
        })},[(
            _vm.myConfig.bannerSearchConfig.isShow &&
            _vm.myConfig.templateData.template === 'template2'
          )?_c('div',{staticClass:"search template2",style:({
            borderRadius:
              (_vm.myConfig.bannerSearchConfig.template2_searchType ===
                'circle' &&
                _vm.myConfig.templateData.template === 'template2') ||
              (_vm.myConfig.bannerSearchConfig.template4_searchType ===
                'circle' &&
                _vm.myConfig.templateData.template === 'template4')
                ? '18px'
                : '6px',
            justifyContent:
              _vm.myConfig.bannerSearchConfig.textPosition === 'center'
                ? 'center'
                : '',
            flex: _vm.platform === 'h5' ? 1 : '',
          })},[_c('el-image',{staticClass:"search-icon",attrs:{"src":require('../../../assets/images/white-search-icon.png'),"fit":"cover"}}),_c('p',{staticClass:"search-placeholder"},[_vm._v(" "+_vm._s(_vm.myConfig.bannerSearchConfig.placeholder ? _vm.myConfig.bannerSearchConfig.placeholder : '搜索')+" ")])],1):(
            _vm.myConfig.bannerSearchConfig.isShow &&
            _vm.myConfig.templateData.template === 'template4'
          )?_c('div',{staticClass:"search template4",style:({
            borderRadius:
              _vm.myConfig.bannerSearchConfig.searchType === 'circle'
                ? '18px'
                : '4px',
          })},[_c('i',{staticClass:"search-icon el-icon-search"}),_c('p',{staticClass:"search-placeholder"},[_vm._v(" "+_vm._s(_vm.myConfig.bannerSearchConfig.placeholder ? _vm.myConfig.bannerSearchConfig.placeholder : '搜索')+" ")])]):_c('div'),(_vm.platform !== 'h5')?_c('el-image',{staticClass:"handle-icon",attrs:{"src":_vm.handleIcon[_vm.page.props.normal.statusBarColor]}}):_vm._e()],1):_c('div',{staticClass:"header-top flex-between flex-align-center",staticStyle:{"height":"44px","padding-right":"10px"}},[(false)?_c('div',{staticClass:"search-bar",staticStyle:{"top":"27px","left":"10px"}},[_c('i',{staticClass:"el-icon el-icon-search"}),(_vm.myConfig.searchConfigTemplate1.placeholder)?_c('span',{staticStyle:{"margin-left":"6px"}},[_vm._v(_vm._s(_vm.myConfig.searchConfigTemplate1.placeholder))]):_vm._e()]):_c('div'),(_vm.platform !== 'h5')?_c('el-image',{staticClass:"handle-icon",attrs:{"src":_vm.handleIcon[_vm.page.props.normal.statusBarColor]}}):_vm._e()],1)],2):_vm._e(),(
        _vm.myConfig.templateData.template !== 'template1' &&
        _vm.myConfig.templateData.template !== 'template7'
      )?_c('div',{staticClass:"banner-content",class:{ clipPath: _vm.myConfig.templateData.template === 'template3' },style:({
        backgroundColor: _vm.advert_list.length ? '' : '#777777',
        height:
          _vm.myConfig.templateData.template === 'template2'
            ? ''
            : _vm.myConfig.templateData.template === 'template4'
            ? '292px'
            : '232px',
      })},[(!_vm.advert_list.length)?_c('el-image',{staticClass:"default-icon",attrs:{"src":require('../../../assets/images/mini-default-icon@2x.png')}}):_vm._e(),(_vm.advert_list.length)?_c('el-image',{staticClass:"advert-img",attrs:{"src":_vm.fixImageUrl(
            _vm.advert_list[0].banner_type === 3
              ? _vm.advert_list[0].video.thumbnail
              : _vm.advert_list[0].image,
            { h: 400 },
            true
          ),"fit":"cover"}}):_vm._e(),(!_vm.advert_list.length || _vm.advert_list[0].cover_title)?_c('div',{staticClass:"title-cover",class:_vm.myConfig.templateData.template},[_vm._v(" "+_vm._s(_vm.advert_list.length ? _vm.advert_list[0].cover_title : '请输入内容')+" ")]):_vm._e(),(_vm.advert_list.length !== 1)?_c('div',{staticClass:"swiper-dots",class:_vm.myConfig.templateData.template},_vm._l((_vm.advert_list.length ? _vm.advert_list.length : 4),function(i){return _c('div',{key:i,class:['item-dot', i === 1 ? 'active' : '']})}),0):_vm._e()],1):_vm._e(),(_vm.myConfig.templateData.template === 'template7')?_c('div',{staticClass:"flex",staticStyle:{"padding-left":"16px"},style:({
        paddingTop: ("calc(100px + " + (_vm.myConfig.contentConfigTemplate7.coverHeight) + "px)"),
      })},[_c('el-image',{staticStyle:{"height":"48px","width":"auto"},attrs:{"src":_vm.fixImageUrl(_vm.myConfig.contentConfigTemplate7.icon, { w: 500 }),"fit":"contain"}})],1):_vm._e()]:(_vm.myConfig.templateData.template === 'template5')?_c('div',{staticClass:"template5"},[(_vm.platform !== 'h5')?_c('img',{staticClass:"status-bar",staticStyle:{"position":"absolute","top":"0","left":"0"},style:({
        'background-image':
          _vm.template5Config.bgImg && false
            ? ("url(" + (_vm.fixImageUrl(_vm.template5Config.bgImg, { w: 900 }, true)) + ")")
            : '',
      }),attrs:{"src":_vm.statusBar[_vm.page.props.normal.statusBarColor]}}):_vm._e(),_c('div',{staticClass:"head"},[_c('div',{staticClass:"search-bar",staticStyle:{"top":"27px","left":"10px"},style:({
          'border-radius':
            _vm.myConfig.searchConfigTemplate1.searchType === 'circle'
              ? '18px'
              : '6px',
          justifyContent:
            _vm.myConfig.searchConfigTemplate1.textPosition === 'center'
              ? 'center'
              : '',
          backgroundColor: _vm.myConfig.searchConfigTemplate1.searchColor,
          color: _vm.myConfig.searchConfigTemplate1.placeholderColor,
        })},[_c('i',{staticClass:"el-icon el-icon-search"}),(_vm.myConfig.searchConfigTemplate1.placeholder)?_c('span',{staticStyle:{"margin-left":"6px"}},[_vm._v(_vm._s(_vm.myConfig.searchConfigTemplate1.placeholder))]):_vm._e()]),(_vm.platform !== 'h5')?_c('el-image',{staticClass:"handle-icon",staticStyle:{"position":"absolute","top":"27px","right":"10px"},attrs:{"src":_vm.handleIcon[_vm.page.props.normal.statusBarColor]}}):_vm._e()],1),_c('div',{staticClass:"poster flex-col"},[_c('img',{staticClass:"bg-image",style:({
          'object-fit': _vm.template5Config.bgImg ? '' : 'none',
          'background-color': _vm.template5Config.bgImg
            ? ''
            : 'rgb(243, 245, 247)',
        }),attrs:{"src":_vm.template5Config.bgImg
            ? _vm.fixImageUrl(_vm.template5Config.bgImg, { w: 900 }, true)
            : require('../../../assets/images/default-icon.png')}}),_c('div',{staticClass:"linear-gradient flex-col",style:({
          background: _vm.template5Config.linearGradientConfig.enable
            ? ("linear-gradient(180deg, rgba(255, 112, 81, 0) 0%, " + (_vm.template5Config.linearGradientConfig.color) + " 100%)")
            : '',
          height:
            _vm.template5Config.titleConfig.isShow &&
            _vm.template5Config.titleConfig.name
              ? '121px'
              : '77px',
        })},[_c('div',{staticClass:"icon-title flex"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.template5Config.icon ||
              require('../../../assets/images/default-icon.png')}}),(_vm.template5Config.businessNameConfig.isShow)?_c('span',{staticClass:"clamp2",style:({
              fontWeight: _vm.template5Config.businessNameConfig.fontWeight,
              fontSize: _vm.template5Config.businessNameConfig.fontSize + 'px',
              color: _vm.template5Config.businessNameConfig.fontColor,
            })},[_vm._v(_vm._s(_vm.template5Config.businessNameConfig.name))]):_vm._e()]),(
            _vm.template5Config.titleConfig.isShow &&
            _vm.template5Config.titleConfig.name
          )?_c('h3',{staticClass:"sub-title",style:({
            fontWeight: _vm.template5Config.titleConfig.fontWeight,
            fontSize: _vm.template5Config.titleConfig.fontSize + 'px',
            color: _vm.template5Config.titleConfig.fontColor,
          })},[_vm._v(" "+_vm._s(_vm.template5Config.titleConfig.name)+" ")]):_vm._e()])]),_c('div',{staticClass:"join flex-col",style:({
        'background-color': _vm.template5Config.linearGradientConfig.color,
      })},[(_vm.template5Config.descriptionConfig.isShow)?_c('p',{staticClass:"desc",style:({
          fontWeight: _vm.template5Config.descriptionConfig.fontWeight,
          fontSize: _vm.template5Config.descriptionConfig.fontSize + 'px',
          color: _vm.template5Config.descriptionConfig.fontColor,
        })},[_vm._v(" "+_vm._s(_vm.template5Config.descriptionConfig.name)+" ")]):_vm._e(),_c('div',{staticClass:"join-bar flex-between"},[_c('div',{staticClass:"flex-center"},[_c('st-avatars',{attrs:{"arr":_vm.peopleData.list,"width":24}}),(_vm.template5Config.memberCountConfig.isShow)?_c('span',{staticClass:"join-people",staticStyle:{"margin-left":"12px"},style:({
              color: _vm.template5Config.memberCountConfig.fontColor || '#fff',
              fontWeight:
                _vm.template5Config.memberCountConfig.fontWeight || 'normal',
              fontSize:
                _vm.template5Config.memberCountConfig.fontSize + 'px' || '14px',
            })},[_vm._v(_vm._s(_vm.peopleData.count)+" "+_vm._s(_vm.template5Config.memberCountConfig.name))]):_vm._e()],1),_c('div',{staticClass:"join-btn"},[_vm._v("申请入会")])])])]):(_vm.myConfig.templateData.template === 'template6')?_c('div',{staticClass:"template5"},[(_vm.platform !== 'h5')?_c('img',{staticClass:"status-bar",staticStyle:{"position":"absolute","top":"0","left":"0"},style:({
        'background-image':
          _vm.template6Config.bgImg && false
            ? ("url(" + (_vm.fixImageUrl(_vm.template6Config.bgImg, { w: 900 }, true)) + ")")
            : '',
      }),attrs:{"src":_vm.statusBar[_vm.page.props.normal.statusBarColor]}}):_vm._e(),_c('div',{staticClass:"head"},[_c('div',{staticClass:"search-bar",staticStyle:{"top":"27px","left":"10px"},style:({
          'border-radius':
            _vm.myConfig.searchConfigTemplate1.searchType === 'circle'
              ? '18px'
              : '6px',
          justifyContent:
            _vm.myConfig.searchConfigTemplate1.textPosition === 'center'
              ? 'center'
              : '',
          backgroundColor: _vm.myConfig.searchConfigTemplate1.searchColor,
          color: _vm.myConfig.searchConfigTemplate1.placeholderColor,
        })},[_c('i',{staticClass:"el-icon el-icon-search"}),(_vm.myConfig.searchConfigTemplate1.placeholder)?_c('span',{staticStyle:{"margin-left":"6px"}},[_vm._v(_vm._s(_vm.myConfig.searchConfigTemplate1.placeholder))]):_vm._e()]),(_vm.platform !== 'h5')?_c('el-image',{staticClass:"handle-icon",staticStyle:{"position":"absolute","top":"27px","right":"10px"},attrs:{"src":_vm.handleIcon[_vm.page.props.normal.statusBarColor]}}):_vm._e()],1),_c('div',{staticClass:"poster flex-col"},[_c('img',{staticClass:"bg-image",style:({
          'object-fit': _vm.advert_list.length ? 'cover' : 'none',
          'background-color': _vm.advert_list.length ? '' : 'rgb(243, 245, 247)',
          height: '100%',
        }),attrs:{"src":_vm.advert_list.length
            ? _vm.fixImageUrl(_vm.advert_list[0].image, { w: 900 }, true)
            : require('../../../assets/images/default-icon.png')}}),_c('div',{staticClass:"swiper-doi"},_vm._l((_vm.advert_list),function(item,i){return _c('div',{key:i,staticClass:"doi-item",class:[i === 0 ? 'active' : '']})}),0),_c('div',{staticClass:"linear-gradient flex-col",style:({
          background: _vm.template6Config.linearGradientConfig.enable
            ? ("linear-gradient(180deg, rgba(255, 112, 81, 0) 0%, " + (_vm.template6Config.linearGradientConfig.color) + " 100%)")
            : '',
          height:
            _vm.template6Config.titleConfig.isShow &&
            _vm.template6Config.titleConfig.name
              ? '121px'
              : '77px',
        })},[_c('div',{staticClass:"icon-title flex"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.template6Config.icon ||
              require('../../../assets/images/default-icon.png')}}),(_vm.template6Config.businessNameConfig.isShow)?_c('span',{staticClass:"clamp2",style:({
              fontWeight: _vm.template6Config.businessNameConfig.fontWeight,
              fontSize: _vm.template6Config.businessNameConfig.fontSize + 'px',
              color: _vm.template6Config.businessNameConfig.fontColor,
            })},[_vm._v(_vm._s(_vm.template6Config.businessNameConfig.name))]):_vm._e()]),(
            _vm.template6Config.titleConfig.isShow &&
            _vm.template6Config.titleConfig.name
          )?_c('h3',{staticClass:"sub-title",style:({
            fontWeight: _vm.template6Config.titleConfig.fontWeight,
            fontSize: _vm.template6Config.titleConfig.fontSize + 'px',
            color: _vm.template6Config.titleConfig.fontColor,
          })},[_vm._v(" "+_vm._s(_vm.template6Config.titleConfig.name)+" ")]):_vm._e()])]),_c('div',{staticClass:"join flex-col",style:({
        'background-color': _vm.template6Config.linearGradientConfig.color,
      })},[(_vm.template6Config.descriptionConfig.isShow)?_c('p',{staticClass:"desc",style:({
          fontWeight: _vm.template6Config.descriptionConfig.fontWeight,
          fontSize: _vm.template6Config.descriptionConfig.fontSize + 'px',
          color: _vm.template6Config.descriptionConfig.fontColor,
        })},[_vm._v(" "+_vm._s(_vm.template6Config.descriptionConfig.name)+" ")]):_vm._e(),_c('div',{staticClass:"join-bar flex-between"},[_c('div',{staticClass:"flex-center"},[_c('st-avatars',{attrs:{"arr":_vm.peopleData.list,"width":24}}),(_vm.template6Config.memberCountConfig.isShow)?_c('span',{staticClass:"join-people",staticStyle:{"margin-left":"12px"},style:({
              color: _vm.template6Config.memberCountConfig.fontColor || '#fff',
              fontWeight:
                _vm.template6Config.memberCountConfig.fontWeight || 'normal',
              fontSize:
                _vm.template6Config.memberCountConfig.fontSize + 'px' || '14px',
            })},[_vm._v(_vm._s(_vm.peopleData.count)+" "+_vm._s(_vm.template6Config.memberCountConfig.name))]):_vm._e()],1),_c('div',{staticClass:"join-btn"},[_vm._v("申请入会")])])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }