var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:([_vm.myRootStyle, {
      padding: ("12px " + (_vm.myConfig.templateData.styleType === 'template3'?'16':'0') + "px")
  }])},[_c('StListTitle',{style:({
    padding: ("0 " + (_vm.myConfig.templateData.styleType !== 'template3'?'16':'0') + "px 16px")
  }),attrs:{"config":_vm.myConfig}}),(_vm.myConfig.templateData.styleType === 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template1",class:[_vm.myConfig.dataConfig.interactiveType],style:({
      'grid-gap': (_vm.myConfig.templateData.dataSpacing + 'px'),
  })},_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.page_cover,"size":300}}),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),_c('div',[(item.articles && item.articles.length && item.articles[0].title||_vm.myConfig.dataConfig.interactiveType==='vertical')?_c('div',{staticClass:"item-news"},[_c('div',{staticClass:"news-tip"},[_vm._v("资讯")]),_c('div',{staticClass:"news-content"},[_vm._v(" "+_vm._s(item.articles && item.articles.length && item.articles[0].title?item.articles[0].title:'暂无内容'))])]):_vm._e(),(item.activity && item.activity.length && item.activity[0].name||_vm.myConfig.dataConfig.interactiveType==='vertical')?_c('div',{staticClass:"item-news"},[_c('div',{staticClass:"news-tip"},[_vm._v("活动")]),_c('div',{staticClass:"news-content"},[_vm._v(" "+_vm._s(item.activity && item.activity.length && item.activity[0].name?item.activity[0].name:'暂无内容'))])]):_vm._e()]),_c('div',{staticClass:"item-collect"},[_c('div',{staticClass:"collect-avatar"},[_c('st-avatars',{attrs:{"arr":item.collectedUsers}})],1),_c('div',{staticClass:"collect-content"},[(item.collects_count)?_c('span',{staticStyle:{"margin-right":"6px"}},[_vm._v("共 "+_vm._s(item.collects_count > 999?'999+':item.collects_count)+" 名会员")]):_c('span',[_vm._v("暂无会员")])])])])],1)}),0):_vm._e(),(_vm.myConfig.templateData.styleType === 'template2')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template2"},_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginRight: _vm.myConfig.templateData.dataSpacing + 'px',
    })},[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.page_cover,"size":200}}),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))])])],1)}),0):_vm._e(),(_vm.myConfig.templateData.styleType === 'template3')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template3",staticStyle:{"padding-left":"0px"}},[_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginTop: index === 0?'0':_vm.myConfig.templateData.dataSpacing + 'px',
    })},[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.page_cover,"size":200}}),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"item-desc"},[_vm._v(_vm._s(item.description))])])],1)}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],2):_vm._e(),(_vm.myConfig.templateData.styleType === 'template4')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template4",staticStyle:{"padding":"0 16px"}},[_c('div',{ref:"mySwiper",staticClass:"banner-swiper",style:({
     justifyContent: _vm.brand_list.length > 2?'center':''
    })},_vm._l((_vm.brandSwiperList),function(item,index){return _c('div',{key:index,staticClass:"swiper-no-swiping banner"},[_c('div',{staticClass:"banner-image-box",style:({
            borderRadius: _vm.myConfig.templateData.borderRadius + 'px'
          })},[_c('st-load-image',{staticClass:"banner-image",attrs:{"src":item.page_cover,"border-radius":_vm.myConfig.templateData.borderRadius,"size":400}})],1),_c('div',{staticClass:"banner-content",attrs:{"data-index":index}},[_c('div',{staticClass:"content-tag"},[_c('el-image',{staticClass:"tag-img",attrs:{"fit":"cover","src":require('../../assets/images/st-brand-list/circle-star.png')}}),_c('p',{staticClass:"tag-content"},[_vm._v(_vm._s(_vm.myConfig.listTitle.title?_vm.myConfig.listTitle.title:'标题名称'))])],1),_c('div',{staticClass:"content-mask"},[_c('div',{staticClass:"content-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-collect"},[_c('div',{staticClass:"collect-avatar"},[_c('st-avatars',{attrs:{"arr":item.collectedUsers}})],1),_c('div',{staticClass:"show-detail"},[_vm._v("查看详情")])])])])])}),0)]):_vm._e(),(_vm.myConfig.templateData.styleType === 'template5')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template5"},[_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginTop: index === 0?'0':_vm.myConfig.templateData.dataSpacing + 'px'
    })},[_c('StBrandItemBig',{attrs:{"item":item,"borderRadius":_vm.myConfig.templateData.borderRadius}})],1)}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],2):_vm._e(),(_vm.myConfig.templateData.styleType === 'template6')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template6"},[_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",class:((!item.activity || !item.activity.length) && (!item.articles||!item.articles.length))?'style1':'style2',style:({
      marginTop: index === 0?'0':_vm.myConfig.templateData.dataSpacing + 'px',
      borderRadius: _vm.myConfig.templateData.borderRadius + 'px'
    })},[_c('div',{staticClass:"item-cover-box",style:({
        backgroundColor: item.page_cover?'':'#F3F5F7'
      })},[(!((!item.activity||!item.activity.length) && (!item.articles||!item.articles.length)) && item.page_cover)?_c('StGroundGlass',{attrs:{"bgImage":item.page_cover}}):_vm._e(),(((!item.activity||!item.activity.length) && (!item.articles||!item.articles.length)) && item.page_cover)?_c('el-image',{staticClass:"item-cover",attrs:{"src":_vm.fixSpecialImageUrl(item.page_cover, 'large', index),"fit":"cover"}}):_vm._e()],1),((!item.activity||!item.activity.length) && (!item.articles||!item.articles.length))?_c('div',{staticClass:"main-content1"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-desc"},[_vm._v(_vm._s(item.description))])]):_c('div',{staticClass:"main-content2"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"content-list"},[(item.articles.length)?_c('div',{staticClass:"list-data",style:({
            borderRadius: _vm.myConfig.templateData.borderRadius + 'px'
          })},[(item.articles[0].video || item.articles[0].image)?[_c('div',{staticClass:"data-cover-box",style:({
              backgroundColor: item.page_cover?'':'#F3F5F7',
              borderRadius: _vm.myConfig.templateData.borderRadius + 'px'
            })},[(item.articles[0].video)?[(item.articles[0].video.thumbnail)?_c('el-image',{staticClass:"data-cover",attrs:{"src":_vm.fixSpecialImageUrl(item.articles[0].video.thumbnail, '', index, 'article', 'video'),"fit":"cover"}}):_vm._e(),_c('img',{staticClass:"default-icon video-play",attrs:{"src":require("../../assets/images/video-play.png")}})]:(item.articles[0].image)?[(item.articles[0].image.url)?_c('el-image',{staticClass:"data-cover",attrs:{"src":_vm.fixSpecialImageUrl(item.articles[0].image.url, '', index, 'article', 'image'),"fit":"cover"}}):_vm._e()]:_vm._e(),_c('div',{staticClass:"data-tag blue",style:({
                  borderRadius: ("0 0 0 " + (_vm.myConfig.templateData.borderRadius) + "px")
                })},[_vm._v("资讯")])],2),_c('div',{staticClass:"data-right"},[_c('div',{staticClass:"data-title"},[_vm._v(_vm._s(item.articles[0].title))]),_c('div',{staticClass:"right-bottom"},[_c('div',{staticClass:"bottom-item"},[_vm._v(_vm._s(item.articles[0].source_name))]),_c('div',{staticClass:"bottom-item"},[_vm._v(_vm._s(item.articles[0].post_time))])])])]:[_c('div',{staticClass:"data-name"},[_vm._v(_vm._s(item.articles[0].title))])]],2):_vm._e(),(item.activity.length)?_c('div',{staticClass:"list-data",style:({
            borderRadius: _vm.myConfig.templateData.borderRadius + 'px'
          })},[_c('div',{staticClass:"data-cover-box",style:({
              backgroundColor: item.page_cover?'':'#F3F5F7',
              borderRadius: _vm.myConfig.templateData.borderRadius + 'px'
            })},[_c('el-image',{staticClass:"data-cover",attrs:{"src":_vm.fixSpecialImageUrl(item.activity[0].cover, '', index, 'activity', 'cover'),"fit":item.activity[0].cover ? 'cover' : 'contain'}}),_c('div',{staticClass:"data-tag yellow",style:({
                borderRadius: ("0 0 0 " + (_vm.myConfig.templateData.borderRadius) + "px")
              })},[_vm._v("活动")])],1),_c('div',{staticClass:"data-right"},[_c('div',{staticClass:"data-title"},[_vm._v(_vm._s(item.activity[0].name))]),_c('div',{staticClass:"right-bottom"},[_c('div',{staticClass:"bottom-item"},[_vm._v(_vm._s(item.activity[0].activity_start_time))])])])]):_vm._e()])])])}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }