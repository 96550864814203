<!--
 * @Author: dongjia
 * @Date: 2021-10-08 15:12:39
 * @LastEditTime: 2021-10-09 15:43:46
 * @LastEditors: aleaner
 * @Description: 最新动态组件
 * @FilePath: \app-design\components\Preview\StNewTrends.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="member-msg" :style="{
    marginTop: myConfig.normal.marginTop + 'px',
    position: 'relative',
  }">
    <StMemberContainer :contentConfig="myConfig.contentConfig" :borderBottomRadius="borderRadius"
      headerBtnText="更多">
      <div class="msg-content">
        <div class="default-content" v-if="myConfig.normal.isShowDefaultPage && list.length === 0">
          <img class="default-img" src="../../assets/images/st-member-index/default-content-icon.png" />
          <div class="default-text">亲～该页面暂时没有内容哦</div>
        </div>
        <div class="content-list" v-if="list.length">
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <div class="item-left">
              <el-image class="avatar" :src="require('../../assets/images/st-me-page/avatar1.png')"
                fit="cover"></el-image>
            </div>
            <div class="item-right">
              <div class="right-top">
                <div class="top-left">
                  <div class="item-name">{{item.title}}</div>
                  <div class="item-time">{{item.time_text}}</div>
                </div>
                <div class="top-right">
                  <img class="item-colse" src="../../assets/images/st-member-index/close-icon.png" />
                </div>
              </div>
              <div class="right-bottom">
                <StTrendsContent :contentType="item.content_type" :content="item.content" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StMemberContainer>
  </div>
</template>

<script>
import StMemberContainer from "./StMemberContainer";
import StTrendsContent from "./StTrendsContent";
export default {
  name: "StNewTrends",
  components: { StMemberContainer, StTrendsContent },
  props: {
    config: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      model: {
        normal: {
          marginTop: 0,
          isShowDefaultPage: 1,
        },
        contentConfig: {
          titleConfig: {
            name: "页面标题",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 14,
          fontColor: "#000000",
          trendsMsg: [""],
        },
      },
      borderRadius: 0,
      list: [
        {
          avatar: "",
          is_me: 1,
          title: "关注了这个小组",
          time_text: "昨天 12:30",
          content_type: 0, // 关注小组
          content: {
            image: "",
            name: "小组名称",
            join_num: 701,
            post_num: 420,
          },
        },
        {
          avatar: "",
          is_me: 1,
          title: "发布了一个话题",
          time_text: "2021-5-30",
          content_type: 1, // 发布话题
          content: {
            name: "话题名称",
            description: "话题简介",
            content_num: 701,
            view_num_text: "12.4万",
          },
        },
      ],
    };
  },
  watch: {
    "myConfig.normal.marginTop"(val) {
      this.$bus.emit("marginTopChange", { marginTop: val, index: this.index });
    },
  },
  created() {
    this.$bus.on("marginTopChange", (res) => {
      if (res.index - 1 === this.index) {
        this.borderRadius = res.marginTop > 0 ? 4 : 0;
      }
    });
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-content {
  .default-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
    .default-img {
      width: 210px;
      height: 100px;
    }
    .default-text {
      margin-top: 24px;
      font-size: 14px;
      color: #979da6;
      line-height: 14px;
    }
  }
}

.content-list {
  margin-top: 9px;
  padding-bottom: 10px;
  .list-item + .list-item {
    border-top: 1px solid #f6f6f6;
  }
  .list-item {
    display: flex;
    padding: 11px 10px 11px 20px;
    .item-left {
      flex-shrink: 0;
      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }

    .item-right {
      width: 100%;
      margin-left: 11px;
      .right-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-left {
          .item-name {
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            line-height: 16px;
          }
          .item-time {
            margin-top: 4px;
            font-size: 12px;
            color: #bdc3cc;
            line-height: 16px;
          }
        }
        .top-right {
          .item-colse {
            width: 13px;
            height: 13px;
            margin-right: 6px;
          }
        }
      }

      .right-bottom {
      }
    }
  }
}
</style>