var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon",style:({
    width: _vm.width + 'px',
    height: _vm.height + 'px',
  })},[_c('div',{staticClass:"custom-icon",style:({
      width: _vm.width + 'px',
      height: _vm.height + 'px',
      transform: ("translateX(-" + _vm.width + "px)" + (false ? 'translateZ(0)' : '')),
      backgroundImage: ("url(" + _vm.icon + ")"),
      filter: ("drop-shadow(" + _vm.width + "px 0 0 " + _vm.color + ")"),
      borderRight:'unset',
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }