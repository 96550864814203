<template>
  <div class="brand-list" :style="[myRootStyle]">
    <StListTitle :config="myConfig" />
    <div v-loading="loading" class="swiper-view template1"
      v-show="myStyleType === 'template1'">
      <div class="list-item" :style="{
        marginTop: index !== 0 ? myTemplateData.dataSpacing + 'px': '0',
      }" v-for="(item, index) in live_list" :key="index">
        <div class="item-left">
          <st-load-image class="item-image" :src="item.material_image"
                         :border-radius="myTemplateData.borderRadius" :size="300" />
          <div class="item-status" :style="{
          borderRadius: `${myTemplateData.borderRadius}px 0 ${myTemplateData.borderRadius}px 0`
        }">{{item.live_text}}{{item.live_text === '预告'?'：' + item.start_time:''}}</div>
        </div>
        <div class="item-right">
          <p class="right-top">{{item.name}}</p>
          <!-- <p class="right-middle">{{item.live_text === '预告'?item.start_time + ' 开播' :''}}</p> -->
          <div class="right-bottom" :class="item.live_text === '已结束'?'is-over':''">
            {{item.live_text_two}}</div>
        </div>
      </div>
      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{myConfig.loadingConfig.handleText}}</div>
      </div>
    </div>
    <div v-loading="loading" class="swiper-view template2"
      :style="`row-gap: ${myTemplateData.dataSpacing}px;column-gap: ${myTemplateData.dataSpacing}px;`"
      v-show="myStyleType === 'template2'">
      <div class="list-item" :style="{
        borderRadius: myTemplateData.borderRadius + 'px',
      }" v-for="(item,index) in live_list" :key="index">
        <st-load-image class="live-image" :src="item.material_image" :size="300" />
        <div class="cover-title">
          <p>{{item.name}}</p>
        </div>
        <div class="item-status" :style="{
          borderRadius: `0 0 ${myTemplateData.borderRadius}px 0`
        }">{{item.live_text}}{{item.live_text === '预告'?'：' + item.start_time:''}}</div>
      </div>
      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{myConfig.loadingConfig.handleText}}</div>
      </div>
    </div>
    <div v-loading="loading" class="swiper-view template3"
      v-show="myStyleType === 'template3'">
      <div class="list-item" :style="{
        marginTop: index !== 0 ? myTemplateData.dataSpacing + 'px': '0',
        paddingTop: index === 0 ? '0': '',
      }" v-for="(item,index) in live_list" :key="index">
        <div class="item-img" :style="{
          borderRadius: myTemplateData.borderRadius + 'px',
        }">
          <st-load-image class="live-image" :src="item.material_image" :size="300" />
          <div class="item-status" :style="{
          borderRadius: `0 0 ${myTemplateData.borderRadius}px 0`
        }">{{item.live_text==='预告'?item.live_text + '：' + item.start_time:item.live_text}}</div>
        </div>
        <div class="item-title">{{item.name}}</div>
        <div class="item-icons">
          <el-image class="item-icon" :src="require('../../assets/images/st-live-list/wechat-icon.png')"
            fit="cover"></el-image>
          <el-image class="item-icon" :src="require('../../assets/images/st-live-list/picture-icon.png')"
            fit="cover"></el-image>
        </div>
      </div>
      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{myConfig.loadingConfig.handleText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { liveList } from "../../api/components";
import componentMixin from "@/modules/app-design/mixins/component-mixin";
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";

export default {
  name: "StLiveList",
  components: {StLoadImage},
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      defaultList: require("../../assets/jsons/default-list.json").liveList,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          rowMargin: 0,
        },
        templateData: {
          styleType: "template1",
          borderRadius: 8,
          dataSpacing: 17,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          dataFilter: [101, 102, 103],
        },
        loadingConfig: {
          loadingType: "none",
          limit: 10,
          handleType: "template1",
          handleText: "加载更多",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "直播列表",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
        },
      },
      live_list: [],
    };
  },
  watch: {
    "myConfig.loadingConfig.limit"(val) {
      this.getLiveList(false);
    },
    "myConfig.dataConfig.dataType"(val) {
      this.getLiveList(false);
    },
    "myConfig.dataConfig.dataArr"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getLiveList(false);
      }
    },
    "myConfig.dataConfig.dataFilter"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getLiveList(false);
      }
    },
  },
  created() {
    this.getLiveList();
  },
  methods: {
    getLiveList(notCancel) {
      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });
      let requestData = {
        page: 1,
        limit: this.myConfig.loadingConfig.limit,
        live_status: this.myConfig.dataConfig.dataFilter,
        use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
        template: this.myStyleType,
        ids,
      };
      this.loading = true;
      liveList(requestData, notCancel)
        .then((res) => {
          const { data } = res;
          const defaultData = this.defaultList.filter((item) =>
            this.myConfig.dataConfig.dataFilter.includes(item.type)
          );
          this.live_list = data.length ? data : defaultData;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/component';

  .brand-list {
  padding: 12px 16px;
  // background-color: #f7f8f9;
  background-size: 100% auto;
  background-repeat: repeat-y;
  .list-title {
    padding-bottom: 16px;
  }

  .swiper-view {
    min-height: 100px;
    &.template1 {
      .list-item {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        .item-left {
          width: 167px;
          flex-shrink: 0;
          margin-right: 10px;
          height: 94px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;

          .item-image {
            width: 100%;
            height: 100%;
          }
        }
        .item-right {
          width: 100%;
          position: relative;
          .right-top {
            position: absolute;
            top: 8px;
            height: 40px;
            font-size: 14px;
            color: #323233;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .right-bottom {
            position: absolute;
            bottom: 6px;
            display: inline-block;
            margin-top: 4px;
            border-radius: 2px;
            padding: 4px 12px;
            font-size: 12px;
            color: var(--main-color);
            line-height: 12px;
            border: 1px var(--main-color) solid;
            &.is-over {
              color: var(--main-color);
              border: 1px var(--main-color) solid;
            }
          }
        }
      }
    }
    &.template2 {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));

      .list-item {
        width: 100%;
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f3f5f7;
        &::before {
          content: "";
          display: block;
          padding-top: 100%;
        }
        .live-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .default-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          z-index: 1;
        }
        .cover-title {
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 52px;
          background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          display: flex;
          align-items: center;
          z-index: 2;
          // justify-content: center;
          p {
            margin: 0 12px;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .item-status {
          z-index: 2;
        }
      }
    }
    &.template3 {
      // padding: 0 20px 12px;
      background-color: #f5f6f7;
      .list-item {
        padding: 20px 20px 12px;
        background-color: #fff;
        .item-img {
          width: 100%;
          height: 135px;
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f3f5f7;
          .live-image {
            width: 100%;
            height: 100%;
          }
          .default-image {
            width: 28px;
            height: 28px;
            z-index: 2;
          }
        }
        .item-title {
          margin-top: 12px;
          font-size: 14px;
          color: #323233;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item-icons {
          margin-top: 4px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .item-icon + .item-icon {
            margin-left: 14px;
          }
          .item-icon {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }

    .item-status {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 8px;
      height: 28px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
    }
}
</style>
