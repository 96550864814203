var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-wall single-media-wall"},[_c('div',{staticClass:"wall-content",style:(_vm.showInline?'display: flex;':'')},[_c('div',{staticClass:"img-content",class:_vm.disabled?'disabled-hover':'no-disabled-hover',style:({
        width: _vm.widthSize,
        height: _vm.heightSize,
        'border-radius': _vm.BorderRadius,
        border: 'none'
      }),on:{"click":function($event){!_vm.disabled || JSON.stringify(_vm.MediaData) === '{}' ? (_vm.show = true) : ''}}},[(_vm.UploadType === 'image' && (_vm.MediaData || _vm.DefaultImage))?_c('div',{staticClass:"photo"},[_c('el-image',{staticClass:"photo",style:({
          width: _vm.widthSize,
          height: _vm.heightSize,
          'border-radius': _vm.BorderRadius,
        }),attrs:{"src":_vm.MediaData ? _vm.fixImageUrl(_vm.MediaData) : _vm.DefaultImage,"fit":_vm.fit}}),(!_vm.disabled && _vm.showMask)?_c('div',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.MediaData === '' && _vm.DefaultImage?(_vm.show = true):_vm.deleteMediaData()}}},[_vm._v(" "+_vm._s(_vm.DefaultImage && '' === _vm.MediaData?'点击更换':'删除')+" ")]):_vm._e()],1):_vm._e()]),_vm._t("info")],2),_c('media-selector',{attrs:{"type":_vm.UploadType},on:{"select":_vm.handleMedia},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }