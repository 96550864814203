<!--
 * @Author: dongjia
 * @Date: 2021-08-09 11:46:02
 * @LastEditTime: 2021-09-08 11:04:14
 * @LastEditors: aleaner
 * @Description: 下拉选择器
 * @FilePath: \app-design\components\Form\StElSelect.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div>
    <el-select v-model="value" :multiple="multiple" collapse-tags placeholder="请选择">
      <el-option v-for="item in options" :key="item[valueKey]" :label="item[labelKey]"
        :value="item[valueKey]"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "StElSelect",
  model: {
    prop: "data",
    event: "input",
  },
  props: {
    data: {
      type: [Array, String, Number],
    },
    options: {
      type: [Array, Object],
      default: () => [],
    },
    valueKey: {
      type: String,
      default: "value",
    },
    labelKey: {
      type: String,
      default: "label",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value: {
      get() {
        return this.data;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    console.log(this.options,this.valueKey,this.labelKey)
  },
  watch: {
    "options"(newV,oldV) {
      console.log(newV,oldV)
    }
  }
};
</script>

<style lang="scss" scoped>
</style>