<!--
 * @Author: dongjia
 * @Date: 2021-10-08 17:09:33
 * @LastEditTime: 2021-10-09 16:45:13
 * @LastEditors: aleaner
 * @Description: 关联企业组件
 * @FilePath: \app-design\components\Preview\StRelatedCompany.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="member-msg" :style="{
    marginTop: myConfig.normal.marginTop + 'px',
    position: 'relative',
  }">
    <StMemberContainer :contentConfig="myConfig.contentConfig" :borderBottomRadius="borderRadius">
      <div class="msg-content">
        <div class="default-content" v-if="myConfig.normal.isShowDefaultPage && list.length === 0">
          <img class="default-img" src="../../assets/images/st-member-index/default-content-icon.png" />
          <div class="default-text">亲～该页面暂时没有内容哦</div>
        </div>
        <div class="content-list" v-if="list.length">
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <img class="company-img" src="../../assets/images/st-member-index/company-icon.png" />
            <div class="company-name">{{item.name}}</div>
          </div>
        </div>
      </div>
    </StMemberContainer>
  </div>
</template>

<script>
import StMemberContainer from "./StMemberContainer";
export default {
  name: "StRelatedCompany",
  components: { StMemberContainer },
  props: {
    config: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      model: {
        normal: {
          marginTop: 0,
          isShowDefaultPage: 1,
        },
        contentConfig: {
          titleConfig: {
            name: "页面标题",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 14,
          fontColor: "#000000",
          trendsMsg: [""],
        },
      },
      borderRadius: 0,
      list: [
        {
          image: "",
          name: "深圳市腾讯计算机系统有限公司",
        },
        {
          image: "",
          name: "南山必胜客",
        },
        {
          image: "",
          name: "北山必胜客",
        },
      ],
    };
  },
  watch: {
    "myConfig.normal.marginTop"(val) {
      this.$bus.emit("marginTopChange", { marginTop: val, index: this.index });
    },
  },
  created() {
    this.$bus.on("marginTopChange", (res) => {
      if (res.index - 1 === this.index) {
        this.borderRadius = res.marginTop > 0 ? 4 : 0;
      }
    });
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.member-msg {
  overflow: hidden;
}
.msg-content {
  .default-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
    .default-img {
      width: 210px;
      height: 100px;
    }
    .default-text {
      margin-top: 24px;
      font-size: 14px;
      color: #979da6;
      line-height: 14px;
    }
  }
}
.content-list {
  display: flex;
  flex-wrap: nowrap;
  padding: 9px 0 15px 15px;
  .list-item + .list-item {
    margin-left: 8px;
  }
  .list-item {
    width: 130px;
    flex-shrink: 0;
    .company-img {
      width: 130px;
      height: 72px;
      border-radius: 4px;
    }
    .company-name {
      width: 100%;
      @include clamp(2);
      margin-top: 7px;
      font-size: 12px;
      color: #1b212f;
      line-height: 16px;
    }
  }
}
</style>