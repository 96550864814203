<template>
  <div style="margin-bottom: 24px">
    <div class="radio-content">
      <div
        class="radio-item"
        v-for="(item, index) in options"
        :key="index"
        @click="changeActive(item)"
        :class="[value === item.value ? 'active' : '']"
      >
        <div class="image-box">
          <template v-if="item.value === 'template1'">
            <img
              v-if="value === item.value"
              :src="`${baseImgUrl}/app_design/assets/images/st-template-new-radio/template1-active.png`"
            />
            <img
              v-else
              :src="`${baseImgUrl}/app_design/assets/images/st-template-new-radio/template1.png`"
            />
          </template>
          <template v-if="item.value === 'template2'">
            <img
              v-if="value === item.value"
              :src="`${baseImgUrl}/app_design/assets/images/st-template-new-radio/template2-active.png`"
            />
            <img
              v-else
              :src="`${baseImgUrl}/app_design/assets/images/st-template-new-radio/template2.png`"
            />
          </template>
          <template v-if="item.value === 'template3'">
            <img
              v-if="value === item.value"
              :src="`${baseImgUrl}/app_design/assets/images/st-template-new-radio/template3-active.png`"
            />
            <img
              v-else
              :src="`${baseImgUrl}/app_design/assets/images/st-template-new-radio/template3.png`"
            />
          </template>
        </div>
        <div class="text">{{ item.type }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StTemplateNewRadio',
  model: {
    prop: 'template',
    event: 'templateChange',
  },
  props: {
    title: {
      type: String,
    },
    suggestText: {
      type: [Array, Object],
      default: () => {
        return null
      },
    },
    tipsText: {
      type: [Array, Object],
      default: () => {
        return null
      },
    },
    options: {
      type: Array,
      required: true,
    },
    template: {
      type: [String, Number],
    },
  },
  computed: {
    value: {
      get() {
        return this.template
      },
      set(val) {
        this.$emit('templateChange', val)
      },
    },
  },
  methods: {
    changeActive(item) {
      this.value = item.value
    },
  },
  data() {
    return {
      baseImgUrl: '',
    }
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
}
</script>

<style lang="scss" scoped>
.radio-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  .radio-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .image-box {
      width: 100%;
      border-radius: 4px;

      height: 77px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .text {
      margin-top: 8px;
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 400;
    }
    &.active {
      .image-box {
      }

      .text {
        color: $primary;
      }
    }
  }
}
</style>
