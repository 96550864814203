var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-me",style:({
    marginTop: _vm.myConfig.normal.marginTop + 'px'
  })},[(_vm.myConfig.templateData.styleType === 1)?_c('div',{staticClass:"main template1",style:({
    margin: ("0 " + (_vm.myConfig.templateData.rowMargin) + "px")
  })},[(_vm.myConfig.templateData.titleConfig.isShow)?_c('div',{staticClass:"main-title",style:({
      color: _vm.myConfig.templateData.fontColor,
      fontWeight: _vm.myConfig.templateData.fontWeight,
      fontSize: _vm.myConfig.templateData.fontSize + 'px'
    })},[_vm._v(" "+_vm._s(_vm.myConfig.templateData.titleConfig.name))]):_vm._e(),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"content-left"},[_c('img',{staticClass:"content-image",attrs:{"src":require("../../assets/images/st-contact-me/avatar-icon.png")}}),_c('div',{staticClass:"content-name"},[_vm._v(" "+_vm._s(_vm.myConfig.templateData.isMask?'真实姓名':_vm.contactText[_vm.myConfig.templateData.contactText]))])]),_c('div',{class:['content-right', _vm.myConfig.templateData.btnColor1]},[_vm._v(" "+_vm._s(_vm.buttonText[_vm.myConfig.templateData.buttonText]))])])]):_vm._e(),(_vm.myConfig.templateData.styleType === 2)?_c('div',{staticClass:"main template2",style:({
    justifyContent: _vm.flexType[_vm.myConfig.templateData.showPosition]
  })},[_c('div',{staticClass:"main-content"},[(_vm.myConfig.templateData.titleConfig.isShow)?_c('div',{staticClass:"main-title",style:({
      color: _vm.myConfig.templateData.fontColor,
      fontWeight: _vm.myConfig.templateData.fontWeight,
      fontSize: _vm.myConfig.templateData.fontSize + 'px'
    })},[_vm._v(" "+_vm._s(_vm.myConfig.templateData.titleConfig.name))]):_vm._e(),_c('img',{staticClass:"content-image",attrs:{"src":require("../../assets/images/st-contact-me/chat-icon.png")}})])]):_vm._e(),(_vm.myConfig.templateData.styleType === 3)?_c('div',{staticClass:"main template3",style:({
    margin: ("0 " + (_vm.myConfig.templateData.rowMargin) + "px")
  })},[_vm._m(0),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"right-top"},[_c('img',{staticClass:"mini-icon",attrs:{"src":require("../../assets/images/st-contact-me/chat-icon-mini.png")}}),_c('p',{staticClass:"top-title"},[_vm._v(_vm._s(_vm.contactText[_vm.myConfig.templateData.contactText]))])]),_c('p',{staticClass:"right-bottom"},[_vm._v(_vm._s(_vm.serviceText[_vm.myConfig.templateData.serviceText]))])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-left"},[_c('img',{staticClass:"content-image",attrs:{"src":require("../../assets/images/st-contact-me/people-icon.png")}})])}]

export { render, staticRenderFns }