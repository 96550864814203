export default {
  "st-join-organize": {
    templateData: {
      styleType: function() {
        return (val, rule, fApi) => {

        }
      },
      size: function (vm) {
        return (val, rule, fApi) => {
          const _this = vm;
          rule.display = fApi.form.styleType === "template2";
        };
      },
      leftButtonText: () => {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template2'
        }
      },
      rightButtonText: () => {
        return (val, rule, fApi) => {
          rule.display = fApi.form.styleType === 'template2'
        }
      }
    }
  }
}