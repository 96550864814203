<!--
 * @Author: dongjia
 * @Date: 2022-03-01 14:02:20
 * @LastEditTime: 2022-03-03 15:24:27
 * @LastEditors: aleaner
 * @Description: 社区导航组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StCommunityNav.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="custom-nav st-community-square"
    :style="{
      marginTop: myConfig.normal.marginTop + 'px',
      marginLeft: myConfig.normal.rowMargin + 'px',
      marginRight: myConfig.normal.rowMargin + 'px',
      borderRadius: myConfig.normal.borderRadius + 'px',
    }"
  >
    <NavPostList :bgColor="myConfig.normal.bgColor" :listStyle="myConfig.listStyle" :normal="myConfig.normal" :showConfig="myConfig.showConfig" />

    <div class="publish-btn" v-if="myConfig.issueConfig.issuePost">
      <img
        class="publish-icon"
        src="../../assets/images/st-community-nav/publish-icon.png"
      />
    </div>
  </div>
</template>

<script>
import NavPostList from "./st-community-nav/NavPostList.vue";

export default {
  name: "StCommunityNav",
  components: { NavPostList },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultNavList: [
        { name: "广场", key: "posts" },
        { name: "关注", key: "follow" },
        { name: "我的小组", key: "myGroup" },
        { name: "我的话题", key: "myTopic" },
      ],
      contentList: require("../../assets/jsons/default-list.json").StPostList,
      ch_sort: require("../../assets/jsons/ch-sort.json"),
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          "rowMargin": 0,
          "rowPadding": 16,
          "colPadding": 16,
          "bgType": "color",
          "bgColor": "",
          "bgImage": "",
          borderRadius: 0
        },
        // templateData: {
        //   styleType: "template1",
        //   navColor: "#FFFFFF",
        //   bgColor: "#FFFFFF",
        // },
        contentConfig: {
          showMyGroup: 1,
          showMyTopic: 1,
          showTopicCenter: 1,
        },
        issueConfig: {
          issuePost: 1,
          issueResource: 1,
        },
        "listStyle": {
          "bgColor": "",
          "borderRadius": 6,
          "dataSpacing": 12
        },
        "showConfig": {
          "showFollow": 1
        }
      },
      currentIndex: 0,
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    navList() {
      const list = JSON.parse(JSON.stringify(this.defaultNavList));
      if (!this.myConfig.contentConfig.showMyGroup) {
        list.splice(
          list.findIndex((el) => el.key === "myGroup"),
          1
        );
      }
      if (!this.myConfig.contentConfig.showMyTopic) {
        list.splice(
          list.findIndex((el) => el.key === "myTopic"),
          1
        );
      }
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-nav {
  position: relative;
  overflow: hidden;

  .nav-list {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    // justify-content: space-around;
    overflow-x: scroll;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    padding: 8px 0 0 16px;
  }
  .nav-item + .nav-item {
    margin-left: 24px;
  }
  .nav-item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-name {
      font-size: 14px;
      height: 40px;
      line-height: 2;
      display: flex;
      align-items: center;
      position: relative;
      &.active {
        font-size: 18px;
        font-weight: bold;
      }
      .active-bottom {
        width: 18px;
        height: 6px;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        .active-bottom-icon {
          width: 18px;
          height: 6px;
          transform: translateX(-18px);
          background-image: url("../../assets/images/st-community-nav/community-nav-icon.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          filter: drop-shadow(18px 0 0 var(--main-color));
        }
      }
    }
  }

  .refresh-btn {
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-right: 16px;
    flex-shrink: 0;
  }

  $fixed-bottom: 175px;
  $fixed-right: 24px;
  $fixed-btn-size: 54px;

  .publish-btn {
    position: absolute;
    /* 减去底部导航的高度 */
    bottom: $fixed-bottom - 50px;
    right: $fixed-right;
    width: $fixed-btn-size;
    height: $fixed-btn-size;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-color);

    .publish-icon {
      width: 24px;
      height: 24px;
    }
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
