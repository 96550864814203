var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"free-container",style:({
  height: _vm.myConfig.normal.height + 'px',
  marginTop: ((_vm.myConfig.normal.marginTop) + "px")
})},[(_vm.myConfig.normal.bgConfig === 'custom')?_c('div',{staticClass:"comp-bg",style:(Object.assign({}, {backgroundColor: _vm.myConfig.normal.bgColor,
    opacity: (100 - _vm.myConfig.normal.opacity)/100,
    backgroundImage: _vm.myConfig.normal.styleType === 'image'?("url(" + (_vm.myConfig.normal.bgImg) + ") !important"): ''},
    _vm.bgShowType,
    {borderRadius: _vm.borderRadiusString,
    margin: ("0 " + (_vm.myConfig.normal.rowMargin) + "px"),
    width: ("calc(100% - " + (2 * _vm.myConfig.normal.rowMargin) + "px)"),
    boxShadow: ((_vm.myConfig.shadow.horizontal) + "px " + (_vm.myConfig.shadow.vertical) + "px " + (_vm.myConfig.shadow.distance) + "px " + (_vm.myConfig.shadow.size) + "px #ccc")}))}):_vm._e(),(_vm.componentData)?_c('draggable',{staticClass:"comp",attrs:{"animation":"400","ghostClass":"ghost-free-container","options":_vm.listOptions,"sort":false},on:{"add":_vm.handleAdd},model:{value:(_vm.componentData.props.components),callback:function ($$v) {_vm.$set(_vm.componentData.props, "components", $$v)},expression:"componentData.props.components"}},[_vm._l((_vm.componentData.props.components),function(item,index){return _c('div',{key:item.extra.timestamp,staticClass:"comp-container",staticStyle:{"position":"absolute","left":"0","top":"0"}},[_c('vue-drag-resize',{staticClass:"comp-item",style:({
        paddingRight: item.props.gov && item.props.gov.isEnableOthers ? '13px' : 0,
        backgroundImage: item.props.gov && item.props.gov.isEnableOthers ? ("url(" + (require('../../assets/images/st-custom-header/dropdown-icon.png')) + ")"): 'none'
      }),attrs:{"parentW":375,"parentH":_vm.myConfig.normal.height,"parentLimitation":true,"isActive":item.isActive,"preventActiveBehavior":_vm.componentListConfig.maintain_select,"aspectRatio":item.props.normal.widthHeight.aspectRatio,"w":item.props.normal.widthHeight.width,"minh":1,"minw":1,"x":item.props.normal.left,"y":item.props.normal.top,"h":item.props.normal.widthHeight.height,"z":item.props.normal.index},on:{"clicked":function($event){return _vm.handleActive(item,index)},"deactivated":function($event){return _vm.cancelActive(item,index)},"resizestop":function (newRect) { return _vm.change(item.props.normal,newRect); },"dragstop":function (newRect) { return _vm.change(item.props.normal,newRect); }}},[(item.component === 'st-text' || item.component === 'st-link')?_c('div',{staticClass:"comp-box",style:({
          fontSize: item.props.normal.fontSize + 'px',
          fontWeight: item.props.normal.textConfig.fontWeight,
          color: item.props.normal.textConfig.fontColor,
          fontStyle: item.props.normal.textConfig.fontStyle,
          textDecoration: item.props.normal.textConfig.textDecoration,
          textAlign: item.props.normal.textConfig.textAlign,
          border: ((item.props.normal.borderConfig.borderSize) + "px " + (item.props.normal.borderConfig.borderType) + " " + (item.props.normal.borderConfig.borderColor)),
          borderRadius: item.props.normal.borderRadius + 'px',
          backgroundColor: item.props.normal.bgColor,
        })},[(item.isShowInput)?_c('el-input',{ref:"inputarea",refInFor:true,staticClass:"input-text",staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"textarea"},on:{"blur":function($event){item.isShowInput = false}},model:{value:(item.props.normal.text),callback:function ($$v) {_vm.$set(item.props.normal, "text", $$v)},expression:"item.props.normal.text"}}):_vm._e(),(!item.isShowInput)?_c('p',[_vm._v(" "+_vm._s(item.props.normal.text || "请输入文本内容")+" ")]):_vm._e()],1):_vm._e(),(item.component === 'st-image')?_c('div',{staticClass:"comp-box imgbox",style:({
          border: ((item.props.normal.borderConfig.borderSize) + "px " + (item.props.normal.borderConfig.borderType) + " " + (item.props.normal.borderConfig.borderColor)),
          borderRadius: item.props.normal.borderRadius + 'px',
          backgroundColor: item.props.normal.bgColor,
        })},[(item.props.normal.image)?_c('img',{staticClass:"img",style:({objectFit: item.props.normal.imgFit}),attrs:{"src":_vm.fixSpecialImageUrl(item.props.normal.image, 500, index)}}):_c('div',{staticClass:"none-img"},[_vm._v("请上传图片")])]):_vm._e(),(item.component === 'st-button')?_c('div',{staticClass:"comp-box btnbox",style:({
          fontSize: item.props.normal.fontSize + 'px',
          color: item.props.normal.fontColor,
          border: ((item.props.normal.borderConfig.borderSize) + "px " + (item.props.normal.borderConfig.borderType) + " " + (item.props.normal.btnColor)),
          borderRadius: item.props.normal.borderRadius + 'px',
          backgroundColor: ['template1','template3'].includes(item.props.normal.btnStyle)? item.props.normal.btnColor: '#ffffff',
        })},[_c('span',[_vm._v(_vm._s(item.props.normal.text || '按钮'))])]):_vm._e(),_c('div',{staticClass:"comp-handle flex-col"},[_c('div',{staticClass:"handle-btn",on:{"click":function($event){$event.stopPropagation();return _vm.handleDel(item,index)}}},[_c('img',{staticClass:"btn-icon",attrs:{"src":require("../../assets/images/st-free-container/delect-icon.png")}})]),_c('div',{staticClass:"handle-btn",on:{"click":function($event){$event.stopPropagation();return _vm.handleChangeLayer(item.props.normal, 1)}}},[_c('i',{staticClass:"btn-icon el-icon el-icon-top"})]),_c('div',{staticClass:"handle-btn",on:{"click":function($event){$event.stopPropagation();return _vm.handleChangeLayer(item.props.normal, 2)}}},[_c('i',{staticClass:"btn-icon el-icon el-icon-bottom"})])])])],1)}),_c('div',{staticClass:"none-tip-box"},[(_vm.componentData.props.components.length === 0)?_c('div',{staticClass:"none-tip"},[_c('p',{staticClass:"none-text"},[_vm._v("可拖动文本、图片、按钮、链接组件至自由容器")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.myConfig.normal.height > 90),expression:"myConfig.normal.height > 90"}],staticClass:"none-btn",attrs:{"size":"medium","type":"primary"},on:{"click":_vm.handleAddComp}},[_vm._v(" "+_vm._s(_vm.isShowNormalComponents?'添加组件':'取消添加组件'))])],1):_vm._e()])],2):_vm._e(),(false)?_c('img',{staticClass:"handle-icon",attrs:{"src":require("../../assets/images/st-free-container/handle-height-icon.png")},on:{"mousedown":function($event){return _vm.changeHeight($event)},"mouseup":function($event){return _vm.stopHeightChange($event)}}}):_vm._e(),_c('media-selector',{attrs:{"type":"image"},on:{"select":_vm.handleMedia},model:{value:(_vm.showImageUpload),callback:function ($$v) {_vm.showImageUpload=$$v},expression:"showImageUpload"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }