<template>
  <MiniProgramViewer
    class="start-left"
    :class="[myConfig.normal.showType]"
    :footer-image="
      myConfig.normal.showType !== 'default' ? '' : myConfig.normal.bottom
    "
    :is-show-left="false"
    :footer-text="myConfig.normal.copyright"
    :isShowFooter="myConfig.normal.isShow === 1"
    :status-bar-color="
      myConfig.normal.showType !== 'default' ? 'white' : 'black'
    "
  >
    <div v-show="myConfig.normal.isShow">
      <template
        v-if="
          myConfig.normal.showType && myConfig.normal.showType !== 'default'
        "
      >
        <img
          v-if="myConfig.normal.showType === 'image' && myConfig.normal.image"
          class="full-img image"
          :src="myConfig.normal.image"
        />
        <img
          v-else-if="
            myConfig.normal.showType === 'video' &&
            myConfig.normal.video.thumbnail
          "
          class="full-img video"
          :src="myConfig.normal.video.thumbnail"
        />
        <div v-else class="full-img" :class="[myConfig.normal.showType]" />

        <div
          :style="{
            bottom: `calc(20px)`,
          }"
          class="tools flex"
        >
          <div
            :class="{ muted: true }"
            class="tool mute flex-center"
            v-if="myConfig.normal.showType === 'video'"
          >
            <svg
              class="icon"
              height="16"
              p-id="2285"
              t="1731693320785"
              version="1.1"
              viewBox="0 0 1024 1024"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M392.240762 712.240762l41.472-41.472 126.293333 126.268952V544.475429l58.660572-58.660572V861.379048c0 28.525714-34.474667 42.788571-54.613334 22.625523l-171.812571-171.78819zM143.993905 624.006095h132.827428L218.160762 682.666667H117.321143A31.98781 31.98781 0 0 1 85.333333 650.654476V373.345524c0-17.65181 14.336-31.98781 32.012191-31.98781h245.321143l201.386666-201.386666c20.138667-20.138667 54.613333-5.851429 54.613334 22.649904v119.564191l-58.660572 58.660571V226.986667l-173.031619 173.031619H143.993905v224.01219zM836.266667 268.214857l-41.496381 41.496381A281.819429 281.819429 0 0 1 880.006095 512c0 109.909333-62.70781 205.141333-154.331428 251.904l26.624 52.224a341.284571 341.284571 0 0 0 84.163047-547.693714L836.266667 268.190476z"
                fill="#ffffff"
                p-id="2286"
              ></path>
              <path
                d="M745.74019 358.741333l-41.49638 41.496381a154.648381 154.648381 0 0 1-36.668953 249.612191l26.624 52.272762A213.333333 213.333333 0 0 0 810.666667 512a212.699429 212.699429 0 0 0-64.926477-153.258667zM792.941714 168.228571L189.561905 771.632762l41.472 41.472L834.438095 209.700571 792.941714 168.228571z"
                fill="#ffffff"
                p-id="2287"
              ></path>
            </svg>
          </div>
          <div class="tool flex-center">
            <span v-if="myConfig.normal.showType === 'video'">跳过</span>
            <span v-else>跳过&nbsp;{{ myConfig.dataConfig.skipTime || '?' }}s</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="img-swiper"
          :class="myConfig.normal.images.length > 0 ? '' : 'empty-image'"
        >
          <slider
            ref="slider"
            :options="options"
            v-if="myConfig.normal.images.length > 0"
          >
            <slideritem
              v-for="(item, index) in myConfig.normal.images"
              :key="index"
            >
              <el-image :src="item" fix="cover" />
            </slideritem>
          </slider>
        </div>
        <el-image
          class="start-top-title"
          v-if="myConfig.normal.middle"
          :src="myConfig.normal.middle"
          fit="contain"
        >
        </el-image>
        <div class="start-top-title none" v-if="!myConfig.normal.middle"></div>
      </template>
    </div>
  </MiniProgramViewer>
</template>

<script>
import MiniProgramViewer from '@/base/components/Preview/MiniProgramViewer'
import { slider, slideritem } from 'vue-concise-slider'
export default {
  name: 'st-start-page',
  components: { MiniProgramViewer, slider, slideritem },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        normal: {
          isShow: 0,
          images: [''],
          bottom: '',
          copyright: '',
          middle: '',
          video: {
            url: '',
            thumbnail: '',
            type: 'video',
          },
          image: '',
          showType: 'default',
          duration: 3,
          jump_link: null,
        },
        dataConfig: {
          bannerCategory: '',
          skipTime: 3,
        },
      },
      options: {
        thresholdDistance: 30,
        loop: false,
        pagination: false,
        itemAnimation: true,
      },
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.start-left {
  &.image {
    ::v-deep {
      .mini-program-nav-bar {
        background-color: unset;
        /*z-index: 0;*/
        display: none;
      }
    }
  }
  &.startPage {
    ::v-deep {
      .mini-program-nav-bar {
        background-color: unset;
        /*z-index: 0;*/
        display: none;
      }
    }
  }

  &.video {
    ::v-deep {
      .mini-program-nav-bar {
        background-color: unset;
        display: none;
      }

      .mini-program-head {
        z-index: 2;
      }
    }
  }

  .img-swiper {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 172px;
  }

  .empty-image {
    background-color: $image-placeholder;
    // border: 1px solid #dcdfe6;
  }

  .start-top-title {
    width: 288px;
    height: 19px;
    position: absolute;
    top: 296px;
    left: 44px;

    &.none {
      background: #f5f7fa;
      // border: 1px solid #dcdfe6;
    }
  }

  .full-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    object-fit: cover;
    z-index: 1;

    &.image {
      z-index: 0;
    }
  }
}

$page-side: 12px;

.tools {
  position: fixed;
  right: calc(#{$page-side});
  z-index: 101;
  color: white;
  font-size: 14px;

  $tool-h: 30px;

  .tool {
    height: $tool-h;
    border-radius: $tool-h * 0.5;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: $tool-h;
    text-align: center;
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    min-width: 80px;
    position: relative;
    white-space: nowrap;

    &.mute {
      width: $tool-h;
      padding: 0;
      min-width: $tool-h;
      border-radius: 50%;
    }

    & + .tool {
      margin-left: 10px;
    }
  }
}
</style>
