<template>
  <div class="pay-status" v-show="!pays_tatus">
    <el-image class="tip-icon" :src="require('../../assets/images/need-to-pay.png')"></el-image>
    <p class="tip-text">{{tipText}}</p>
    <el-button class="pay-btn" type="primary" plain size="small">{{btnText}}</el-button>
  </div>
</template>

<script>
export default {
  name: "StPayStatus",
  model: {
    prop: "payStatus",
    event: "change",
  },
  props: {
    tipText: {
      type: String,
      default: "该内容未激活，请先前往购买",
    },
    btnText: {
      type: String,
      default: "前往购买",
    },
    payStatus: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    pays_tatus: {
      get() {
        return this.payStatus;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  .tip-icon {
    width: 70px;
    height: 70px;
  }
  .tip-text {
    margin-top: 23px;
    font-size: 14px;
    font-weight: 500;
    color: #606266;
    line-height: 14px;
  }
  .pay-btn {
    min-width: 182px;
    margin-top: 23px;
  }
}
</style>