<template>
  <div class="st-contacts" style="user-select: none" :style="{ paddingBottom: myConfig.normal.paddingBottom + 'px' }">
    <div
      class="header"
      :class="platform !== 'h5' && myConfig.normal.bgConfig === 'theme' ? 'theme-color' : ''"
      :style="{
        backgroundColor:
          platform === 'h5'
            ? ''
            : myConfig.normal.bgConfig === 'custom' &&
              (myConfig.normal.bgType === 'color' || myConfig.normal.bgType === 'theme')
            ? myConfig.normal.bgColor
            : '',
        backgroundImage:
          myConfig.normal.bgType === 'image' ? `url(${fixImageUrl(myConfig.normal.bgImage, { h: 400 }, true)})` : '',
        backgroundSize: 'cover',
      }"
    >
      <!-- <el-image
        class="bg-image"
        v-if="myConfig.normal.bgConfig === 'custom' && myConfig.normal.bgType === 'image'"
        :src="fixImageUrl(myConfig.normal.bgImage, { h: 400 }, true)"
        fit="cover"
      ></el-image> -->
      <template v-if="platform !== 'h5'">
        <img class="start-top-img" :src="statusBar[myConfig.normal.statusBarColor]" />
        <div class="start-top" style="text-align: end">
          <img
            v-if="myConfig.normal.showHeaderJump"
            class="header-jump"
            :class="[headerJump.showConfig.showType !== 'template1' ? 'template-back-icon' : 'default-back-icon']"
            :src="backIcon[myConfig.normal.statusBarColor][headerJump.showConfig.showType]"
          />
          <img class="start-top-handler" :src="handleIcon[myConfig.normal.statusBarColor]" />
        </div>
      </template>
      <st-page-set v-else :config="myConfig" />
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div
          class="type-item"
          v-if="myConfig.normal.titleConfig.isShow"
          :style="{
            color: myConfig.normal.fontColor,
            fontWeight: myConfig.normal.fontWeight,
            fontSize: myConfig.normal.fontSize + 'px',
          }"
        >
          {{ myConfig.normal.titleConfig.name }}
        </div>
      </div>

      <div
        class="search"
        v-if="myConfig.searchConfig.isShow"
        :class="platform !== 'h5' && myConfig.normal.bgConfig === 'theme' ? 'theme-color' : ''"
        :style="{
          backgroundColor:
            platform === 'h5'
              ? ''
              : myConfig.normal.bgConfig === 'custom' &&
                (myConfig.normal.bgType === 'color' || myConfig.normal.bgType === 'theme')
              ? myConfig.normal.bgColor
              : '',
        }"
        style="display: block"
      >
        <div style="display: flex; width: 100%; padding: 0 18px 0 18px; align-items: center">
          <div style="display: flex; align-items: center">
            <div class="class-list-item" @click="currentTab = 0">
              <div
                :class="{
                  selected: currentTab === 0,
                }"
              >
                全部
              </div>
              <div
                class="line-block"
                :class="{
                  line: currentTab === 0,
                }"
              ></div>
            </div>
            <div class="class-list-item" @click="currentTab = 1">
              <div
                :class="{
                  selected: currentTab === 1,
                }"
              >
                同城
              </div>
              <div
                class="line-block"
                :class="{
                  line: currentTab === 1,
                }"
              ></div>
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end; flex: 1">
            <!-- <p class="search-icon el-icon-search" style="color: white; font-size: 20px"></p> -->
          </div>
        </div>

        <div
          style="
            display: flex;
            width: 100%;
            padding: 18px 18px 0 18px;
            align-items: center;
            font-size: 14px;
            color: #fff;
            opacity: 0.9;
          "
          v-show="currentTab === 0"
        >
          <div style="flex: 1; display: flex; align-items: center">
            <div>就读类别</div>
            <img src="../../assets/images/swiftdown.png" style="width: 8px; height: 5px; margin-left: 5px" />
          </div>
          <div style="flex: 1; display: flex; align-items: center">
            <div>就读校区</div>
            <img src="../../assets/images/swiftdown.png" style="width: 8px; height: 5px; margin-left: 5px" />
          </div>
          <div style="flex: 1; display: flex; align-items: center">
            <div>毕业年份</div>
            <img src="../../assets/images/swiftdown.png" style="width: 8px; height: 5px; margin-left: 5px" />
          </div>
        </div>
      </div>
    </div>

    <img  :src="`${baseImgUrl}/app_design/school-contact-list-all.jpg`" style="width: 100%" v-show="currentTab === 0" />
    <img :src="`${baseImgUrl}/app_design/assets/images/school-contact-list-local.jpg`" style="width: 100%" v-show="currentTab === 1" />
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";

export default {
  name: "StMailContent",
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseImgUrl:'',
      baseImgUrl2:'',
      currentTab: 0,
      defaultList: require("../../assets/jsons/default-list.json").companyList,
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: "通讯录",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 18,
          fontColor: "#FFFFFF",
          statusBarColor: "black",
          bgConfig: "theme",
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          showHeaderJump: 1,
          paddingBottom: 0,
        },
        searchConfig: {
          isShow: 1,
          placeholder: {
            name: "搜索内容",
            isShow: 1,
          },
          placeholderColor: "#979da6",
          textPosition: "left",
          bgConfig: "theme",
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          searchType: "circle",
          interactiveType: "sticky",
          height: 32,
          searchColor: "#f8fbff",
        },
        shareConfig: {
          shareTitle: "",
          image: "",
        },
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
    };
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.class-list-item {
  color: #fff;
  font-size: 14px;
  opacity: 0.9;
  text-align: center;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .selected {
    font-size: 18px;
    opacity: 1;
  }

  .line {
    width: 40%;
    height: 3px;
    border-radius: 6px;
    background-color: #fff;
    margin: 0 auto;
    margin-top: 4px;
  }

  .line-block {
    width: 40%;
    height: 3px;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 4px;
  }
}

.st-contacts {
  width: 375px;
  // min-height: 667px;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
  background-color: #f4f5f6;

  .header-nav {
    background-color: white;
  }

  .start-top-img {
    width: 375px;
    height: 20px;
  }
  .start-top {
    padding-right: 7px;
    .header-jump {
      position: absolute;

      &.default-back-icon {
        top: 32px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      &.template-back-icon {
        top: 25px;
        left: 5px;
        width: 78px;
        height: 32px;
      }
    }
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }
  .header {
    width: 100%;
    position: relative;
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .type-select {
      position: absolute;
      top: 23px;
      width: 100%;
      padding: 0 18px;
      display: flex;
      justify-content: center;

      &.simple {
        position: static;
        display: none;
      }

      .type-item + .type-item {
        margin-left: 18px;
      }
      .type-item {
        display: flex;
        align-items: center;
        height: 34px;
        text-align: center;
        color: #ffffff;
        line-height: 34px;
        .active {
          border-bottom: 2px #fff solid;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
        }
        .select-icon {
          width: 16px;
          height: 16px;
          margin-left: 2px;
        }
      }
    }
  }
}

.search {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-size: cover;
  background-position: center;
  .header-search {
    width: 335px;
    background: #f8fbff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    .search-content {
      display: flex;
      align-items: center;
      .search-icon {
        font-size: 14px;
      }
      .search-tip {
        font-size: 14px;
        line-height: 14px;
        margin-left: 4px;
      }
    }
  }
}

.theme-color {
  background: var(--main-color) !important;
}
</style>
