import { getAnalysisUserInfoOptions } from '../../../api/components'

export default {
  beforeCreate(callback) {
    /* 一次性把表单选项数据请求完再生成规则 */
      Promise.all(
        [new Promise(r => {
          getAnalysisUserInfoOptions().then(res => {
            r({
              data_show: res.data
            })
          }).catch(() => {
          })
        })]
      ).then((res) => {
        /* callback an array like: [{key: res.data},...] */
        callback(res)
      }).catch(() => {
        callback()
      })
  },
  getRules(getVm, value, platform, props = {}) {
    /* 本组件规则只需一个接口，故直接取第一个接口返回的数据 */
    const data_type_options = props[0].data_show
    return {
      contentConfig: {
        title: '内容设置',
        rules: [
          {
            type: "ElSelect",
            field: "data_keys",
            title: "信息展示",
            display: true,
            props: {
              style: "width: 100%;",
              valueKey: 'value',
              multiple: true
            },
            "children": (() => {
              const options = data_type_options[0].list.map(el => ({...el, data_type: data_type_options[0].key}))
              options.push(...data_type_options[1].list.map(el => ({...el, data_type: data_type_options[1].key})))
              return options.map(op => {
                return {
                  type: "ElOption",
                  props: {
                    label: op.label,
                    value: op,
                    key: op.value,
                  }
                }
              })
            })()
          }
        ]
      }
    }
  }
}
