<!--
 * @Author: dongjia
 * @Date: 2021-12-06 15:49:58
 * @LastEditTime: 2022-01-11 15:55:04
 * @LastEditors: aleaner
 * @Description: 联系我们系统页
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StContactUs.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="page-index">
    <StPageSet
      class="header-fixed"
      :config="myConfig"
      :jump-config="headerJump"
      bg-color="transparent"
    />
    <div
      class="page-bg"
      :style="{
        backgroundColor:
          myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      }"
    >
      <img
        v-if="myConfig.normal.bgType === 'image'"
        class="page-bg-img"
        alt=""
        :src="
          fixImageUrl(
            myConfig.normal.bgImage || defaultBg,
            { w: 900 },
            randomId
          )
        "
      />
      <!-- logo,名称 -->
      <div class="bg-content">
        <el-image
          class="page-logo"
          :src="myConfig.contentConfig.logo || defaultLogo"
        />
        <div
          class="page-name"
          :style="{
            fontSize: myConfig.contentConfig.fontSize + 'px',
            fontWeight: myConfig.contentConfig.fontWeight,
            color: myConfig.contentConfig.fontColor,
          }"
          v-if="myConfig.contentConfig.titleConfig.isShow"
        >
          {{ myConfig.contentConfig.titleConfig.name }}
        </div>
      </div>
    </div>
    <div class="contacts-content">
      <div class="mian-content">
        <div
          class="content-title"
          :style="{
            fontSize: myConfig.contactsConfig.fontSize + 'px',
            fontWeight: myConfig.contactsConfig.fontWeight,
            color: myConfig.contactsConfig.fontColor,
          }"
          v-if="myConfig.contactsConfig.titleConfig.isShow"
        >
          {{ myConfig.contactsConfig.titleConfig.name }}
        </div>
        <!-- 自定义字段的 -->
        <div
          class="content-item flex"
          v-if="infoList.length"
          style="flex-wrap: wrap; align-items: flex-start"
        >
          <template v-for="c in infoList">
            <div class="content-name" v-if="c.isShow && c.contents.length">
              <p style="white-space: nowrap">{{ c.label || '标题' }}</p>
              <template v-for="(cc, cci) in c.contents">
                <template v-if="c.is_system">
                  <div v-if="cc.value" class="type-list">
                    <div
                      class="type-item"
                      v-for="(item, itemI) in contactTypeArrResult.filter(
                        (r) => r.label === cc.label
                      )"
                      :key="itemI"
                    >
                      <img :src="item.icon" />
                      <div class="item-name">{{ item.name }}</div>
                    </div>
                  </div>
                  <span v-else-if="cci === 0">{{ '' || '请输入' }}</span>
                </template>
                <div
                  v-else-if="
                    /^1[3-9]\d{9}$/.test(cc.value) ||
                    /^0\d{2,3}-\d{7,8}|\(?0\d{2,3}[)-]?\d{7,8}|\(?0\d{2,3}[)-]*\d{7,8}$/.test(
                      cc.value
                    )
                  "
                  v-show="cc.isShow"
                  class="item-flex"
                  :key="cc.value"
                >
                  <img
                    style="width: 14px; height: 14px"
                    src="../../assets/images/st-contact-us/phone-icon.png"
                  />
                  <div class="item-name phone">{{ cc.value || '请输入' }}</div>
                </div>
                <span v-else v-show="cc.isShow"
                  >{{ cc.value || '请输入' }}
                  <span
                    v-if="
                      /^(\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3})|([\d]{6})$/.test(
                        cc.value
                      )
                    "
                    class="copy-btn"
                    >复制</span
                  >
                </span>
              </template>
            </div>
          </template>
        </div>
        <div class="content-item">
          <div class="content-name">
            <p>地址</p>
          </div>
          <div class="item-flex address">
            <div class="item-name">
              {{ myConfig.mapConfig.addressSign || fullAddress }}
            </div>
            <div class="address-btn">
              <img
                v-if="myConfig.mapConfig.addressSign || fullAddress"
                style="width: 12px; height: 12px; margin-right: 3px"
                src="../../assets/images/st-contact-us/address-icon.png"
              />
              到这里
            </div>
          </div>
          <!-- TODO：测试
          {{myConfig.mapConfig.address}}

          <p v-for="ad in addressOptions">
            {{ad.title}}<br/>
            {{ad.point}}<br/>
            {{ad.address}}<br/>
            {{ad.city}}<br/>
            {{ad.province}}<br/>
          </p>
-->
          <!-- 给地图加点击事件getLocationPoint，点击地图获取位置相关的信息，经纬度啥的 -->
          <!-- scroll-wheel-zoom：是否可以用鼠标滚轮控制地图缩放，zoom是视图比例 -->
          <baidu-map
            class="bmView"
            :center="location"
            :zoom="zoom"
            scroll-wheel-zoom
            :map-click="false"
            @ready="loadMap"
          >
            <!-- 如果需要动画，加上： animation="BMAP_ANIMATION_BOUNCE" -->
            <bm-marker
              :dragging="false"
              :position="{
                lng: location.lng || 116.404,
                lat: location.lat || 39.915,
              }"
            >
              <bm-label
                :content="myConfig.mapConfig.addressSign || fullAddress"
                :labelStyle="{ color: 'red', fontSize: '24px' }"
                :offset="{ width: -35, height: 30 }"
              />
            </bm-marker>
            <bm-local-search
              :keyword="fullAddress"
              :auto-viewport="false"
              style="display: none"
              @searchcomplete="getLocation"
            >
            </bm-local-search>
            <bm-view
              :style="{
                width: '100%',
                marginTop: '12px',
                height:
                  myConfig.mapConfig.mapHeightType === 'normal'
                    ? '162px'
                    : myConfig.mapConfig.mapHeight + 'px',
                borderRadius: '4px',
              }"
            ></bm-view>
            <bm-info-window
              :position="location"
              :show="
                loaded &&
                myConfig.mapConfig.address.city !== '' &&
                myConfig.mapConfig.address.detail !== ''
              "
              :width="0"
              :height="0"
              :maxWidth="220"
              :closeOnClick="false"
              autoPan
            >
              <p class="address-sign">
                {{ myConfig.mapConfig.addressSign || fullAddress }}
              </p>
            </bm-info-window>
            <!-- 缩放器 -->
            <!--            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>-->
          </baidu-map>
        </div>
      </div>
    </div>
    <!--  分享按钮 -->
    <div class="share-btn" v-if="myConfig.shareConfig.showShare">
      <img
        class="share-icon"
        src="../../assets/images/st-contact-us/share-icon.png"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'
import { randomString } from '@/base/utils/tool'

export default {
  components: { StPageSet },
  name: 'StContactUs',
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    const baseUrl =
      window.serverConfig.VUE_APP_ADMINURL || 'https://saasapi.shetuan.pro'

    return {
      randomId: randomString(8),
      platform: this.$route.params.platform,
      model: require('../../assets/jsons/component-library.json').system
        .contactUs.props,
      // zoom: 12.8, //地图缩放比例
      zoom: 25 || 16, //地图缩放比例
      // 联系方式渲染配置
      contactTypeArr: [
        {
          icon: require('../../assets/images/st-contact-us/wechat-icon_sm.png'),
          label: '微信二维码',
          name: '微信',
        },
        {
          icon: require('../../assets/images/st-contact-us/qq-icon_sm.png'),
          label: 'QQ 二维码',
          name: 'QQ',
        },
        {
          icon: require('../../assets/images/st-contact-us/company-wx-icon.png'),
          label: '企业微信二维码',
          name: '企业微信',
        },
      ],
      defaultBg: baseUrl + '/cyc/images/wxapps/images/contact-us-bg.png',
      defaultLogo: baseUrl + '/cyc/images/wxapps/images/logo-icon.png',
      //{lat:myConfig.mapConfig.address.lat || 39.910924 ,lng:myConfig.mapConfig.address.lng || 116.413387}
      location: { lat: '', lng: '' },
      loaded: false,
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
    ...mapState('appDesign', ['addressOptions']),
    contactTypeArrResult() {
      return this.contactTypeArr.filter(
        (el) => this.myConfig.contactsConfig[el.key] !== ''
      )
    },
    infoList() {
      const cConfig = this.myConfig.contactsConfig
      const customArr = cConfig.custom_infos
      if (customArr.length) return customArr
      const oldArr = []
      const oldKeys = [
        'contactsName',
        'contactsPhone',
        'contactsEmail',
        'zipCode',
      ]
      const oldLabels = ['联系人', '联系电话', '邮箱', '邮编']
      // "contactsName": {
      //   "name": "",
      //     "isShow": 1
      // },
      // "contactsPhone": {
      //   "name": "",
      //     "isShow": 1
      // },
      // "contactsEmail": {
      //   "name": "",
      //     "isShow": 1
      // },
      // "zipCode": {
      //   "name": "",
      //     "isShow": 1
      // },
      oldKeys.forEach((k, i) => {
        if (cConfig[k].isShow && cConfig[k].name) {
          oldArr.push({
            isShow: 1,
            is_system: 0,
            label: oldLabels[i],
            inline: 1,
            contents: [
              {
                value: cConfig[k].name,
                jump_link: null,
                isShow: 1,
                label: '',
              },
            ],
          })
        }
      })
      return oldArr
    },
    fullAddress() {
      if (!this.loaded) return ''
      const { detail, city } = this.myConfig.mapConfig.address
      return (detail.indexOf(city) === -1 ? city : '') + detail
    },
  },
  watch: {
    // 立即监听配置的 gcj02 坐标，并转换为 bd09
    'myConfig.mapConfig.address.lng': {
      handler(newValue) {
        if (!this.loaded) return
        if (newValue) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.location.lat = this.myConfig.mapConfig.address.lat
              this.location.lng = this.myConfig.mapConfig.address.lng
              // const {lat, lng} = this.myConfig.mapConfig.address
              // const {lat: lat_, lng: lng_} = this.txMapToBdMap(lng, lat)
              // this.location.lat = lat_;
              // this.location.lng = lng_;
              // console.log('百度地图坐标准备好了', lat_, lng_)
            }, 500)
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 腾讯地图转百度地图经纬度
     * @param {Number} lat
     * @param {Number} lng
     * @returns
     */
    txMapToBdMap(lng, lat) {
      let x_pi = (3.14159265358979324 * 3000.0) / 180.0
      let x = lng
      let y = lat
      let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi)
      let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi)
      let lngs = z * Math.cos(theta) + 0.0065
      let lats = z * Math.sin(theta) + 0.006

      return {
        lng: lngs,
        lat: lats,
      }
    },
    // 加载地图信息
    loadMap({ BMap: _BMap, map }) {
      console.log('map loaded. ')

      // const {lat, lng} = this.myConfig.mapConfig.address
      // const {lat: lat_, lng: lng_} = this.txMapToBdMap(lng, lat)
      // this.location.lat = lat_;
      // this.location.lng = lng_;
      // console.log('百度地图坐标准备好了', lat_, lng_)

      this.loaded = true
    },
    getLocation(e) {
      this.$nextTick(() => {
        if (e) {
          let searchResult
          for (let key in e) {
            if (
              Array.isArray(e[key]) &&
              e[key].length &&
              e[key][0].point !== undefined
            ) {
              searchResult = e[key]
            }
          }

          // 发现有重复的名称
          let results = []
          const searched = searchResult || []
          searched.forEach((s) => {
            if (results.find((r) => r.address === s.address)) {
              // 已存在重复名称
            } else {
              results.push(s)
            }
          })

          this.$store.commit('appDesign/setAddressOptions', results)
          // this.positionDetail = e.Hr[0].point;
          this.$store.commit('appDesign/setSearching', false)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-index {
  .share-btn {
    position: absolute;
    right: 28px;
    bottom: 231px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    .share-icon {
      width: 24px;
      height: 24px;
    }
  }
}
.header-fixed {
  position: absolute;
  top: 0;
  left: 0;
}
.page-bg {
  width: 100%;
  height: 268px;
  position: relative;

  .page-bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .bg-content {
    position: absolute;
    top: 95px;
    left: 17px;
    display: flex;
    align-items: center;
    .page-logo {
      width: auto;
      height: 32px;
      margin-right: 12px;
    }
    .page-name {
      line-height: 1;
    }
  }
}
.contacts-content {
  position: relative;
  padding: 0 17px;
  .mian-content {
    width: 100%;
    padding: 12px;
    margin-top: -121px;
    border-radius: 6px;
    background-color: #fff;
    .content-title {
      line-height: 1.5;
    }
    .content-item {
      &.flex {
        display: flex;
        align-items: center;
      }

      .item-flex {
        display: flex;
        margin-top: 8px;
        align-items: center;

        &.address {
          justify-content: space-between;
          .item-name {
            width: 233px;
            @include nowrap();
          }
          .address-btn {
            display: flex;
            align-items: center;
            background: var(--main-color);
            border-radius: 16px;
            padding: 5px 6px;
            font-size: 12px;
            color: #ffffff;
            line-height: 1;
          }
        }
        .item-name {
          height: 20px;
          font-size: 14px;
          color: #000000;
          line-height: 1.5;
          margin-right: 10px;

          &.phone {
            color: #1b76ff;
          }
        }
        img {
          flex-shrink: 0;
          margin-right: 4px;
        }
      }

      .content-name {
        min-width: 50%;
        margin-top: 12px;
      }
      .content-phone {
        flex-shrink: 0;
      }

      .type-list {
        margin-top: 8px;
        display: inline-flex;
        align-items: center;

        & + .type-list,
        .type-item + .type-item {
          margin-left: 16px;
        }

        .type-item {
          display: flex;
          align-items: center;
          .item-name {
            margin-left: 8px;
            font-size: 14px;
            color: #1a1a1a;
            line-height: 1;
          }

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      p {
        font-size: 12px;
        color: #b3b3b3;
        line-height: 18px;
      }
      span {
        display: flex;
        align-items: center;
        height: 20px;
        margin-top: 4px;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        @include nowrap();
      }

      .copy-btn {
        margin-top: 0px;
        color: #1b76ff;
        margin-left: 12px;
      }
    }
  }
  .join-entry {
    margin-top: 10px;
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 18px;
      line-height: 1;
      color: #464646;
    }
    .join-entry-img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
