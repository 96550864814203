<template>
  <div class="container" v-loading="loading">
    <div class="flex-content-box">
      <div class="category">
        <div
          class="item"
          v-for="(item, i) in categoryOptions"
          :key="item.value"
          :class="[i === 0 ? 'active' : '']"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="goods">
        <div class="filter-box">
          <div class="filter-item">综合</div>
          <div class="filter-item">销量</div>
          <div class="filter-item">价格<i class="el-icon-d-caret"></i></div>
        </div>
        <div class="goods-list">
          <!-- 模板二 -->
          <div
            class="swiper-view flex-column template2"
            :style="{
              marginTop: myTemplateData.marginTop + 'px',
              marginLeft: myTemplateData.rowMargin + 'px',
              marginRight: myTemplateData.rowMargin + 'px',
            }"
          >
            <div
              class="list-item"
              :style="{}"
              v-for="(item, index) in goods_list"
              :key="index"
            >
              <div
                class="img-box"
                :style="{
                  borderRadius: myTemplateData.borderRadius + 'px',

                  height:
                    myTemplateData.photoSize === 'template1'
                      ? '60px'
                      : myTemplateData.photoSize === 'template3'
                      ? '80px'
                      : '80px',
                }"
              >
                <st-load-image
                  :src="item.image || item.res[0].thumbnail || item.res[0].url"
                  class="normal"
                  :size="300"
                />
              </div>
              <div class="item-content">
                <p class="goods-name">{{ item.name }}</p>
                <div class="goods-price">
                  <div class="price-content">
                    <template v-if="item.price">
                      <span class="price-unit">￥</span>
                      <span class="price-count">{{ item.price }}</span>
                    </template>
                    <template v-else-if="item.integral">
                      <span class="price-count">{{ item.integral }}</span>
                      <span class="price-unit">积分</span>
                    </template>
                    <span
                      class="old-price"
                      v-if="item.data_type && item.max_market_price"
                      >￥{{ item.max_market_price }}</span
                    >
                  </div>
                  <!-- 购买按钮 -->
                  <template v-if="myTemplateData.ShowPayBtn">
                    <div
                      class="button-template-1"
                      v-if="myTemplateData.buttonTemplate === 'template1'"
                    >
                      <img src="../assets/images/button-1.png" />
                    </div>
                    <!-- <ColorCustomIcon
                v-if="myTemplateData.buttonTemplate === 'template2'"
                    :width="24"
                :height="24"
                :icon="require('../assets/images/button-2.png')"
                    ></ColorCustomIcon> -->
                    <div
                      class="button-template-3"
                      v-if="myTemplateData.buttonTemplate === 'template2'"
                    >
                      <img src="../assets/images/button-3.png" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goodsList, getAllShopCategory } from '../../../../api/components'

import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'
import ColorCustomIcon from '@/modules/app-design/components/Normal/ColorCustomIcon'

export default {
  name: 'CategoryTemplate1',
  components: { StLoadImage, ColorCustomIcon },

  props: {
    config: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      goods_list: [],
      // 瀑布流数据数组
      waterfall_datas: [[], []],
      randingIndex: 0,
      // 分类
      categoryOptions: [],
    }
  },
  watch: {
    'myTemplateData.template'(val) {
      this.getGoodsList(false)
      this.getCategory()
    },
  },
  computed: {
    myTemplateData() {
      return this.$props.config?.templateData || {}
    },
    systemPageName() {
      return this.$store.state.appDesign.pageDetail.system_page
    },
  },

  methods: {
    // 获取商品分类
    getCategory() {
      getAllShopCategory().then((res) => {
        console.log(res)
        // 过滤调全部选项
        this.categoryOptions = res.data.filter((item) => item.value !== -1)
      })
    },
    getGoodsList(notCancel) {
      this.waterfall_datas = [[], []]
      this.randingIndex = 0
      let ids = []
      let requestData = {
        page: 1,
        limit: 10,
        category_all: 1,
        category_ids: [],
        use_ids: 0,
        template: this.myTemplateData.template,
        ids,
        pay_type: this.systemPageName === 'integral_shop_category' ? 1 : -1,
      }
      this.loading = true
      goodsList(requestData, notCancel)
        .then((res) => {
          let { data } = res

          this.goods_list = data.length ? data : this.defaultList
          if (this.myTemplateData.template === 'template1') {
            this.waterfall_datas[0].push(this.goods_list[0])
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 截取小数点后两位数字
    getSmallNum(num) {
      if (typeof num === 'number') {
        let numStringArr = num.toString().split('.')
        return numStringArr.length > 1 ? numStringArr[1] : null
      }
      return null
    },
  },
  mounted() {
    console.log(this.config)
    this.getGoodsList()
    this.getCategory()
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 16px;
  background-color: #fff;
  height: 100%;

  .flex-content-box {
    display: flex;

    .category {
      width: 84px;
      background-color: #f7f8fa;

      .item {
        font-size: 12px;
        font-weight: 400;
        width: 100%;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #808080;
      }

      .active {
        background-color: #fff;
        color: var(--main-color);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
        border-radius: 0 6px 6px 0;
        position: relative;
        z-index: 999;
      }
    }

    .goods {
      flex: 1;

      .filter-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .filter-item {
          height: 42px;
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #808080;
          font-size: 12px;

          i {
          }
        }
      }

      .goods-list {
        // 模板二
        .flex-column {
          display: flex;
          flex-direction: column;

          &.template2 {
            .list-item {
              padding: 12px;

              .img-box {
                width: 80px;

                margin-right: 11px;
              }

              .item-content {
                .goods-name {
                  font-size: 14px;
                  color: #1a1a1a;
                  line-height: 22px;
                }

                .goods-price {
                  .price-content {
                    .price-unit {
                      font-size: 12px;
                      font-weight: bold;
                      color: #ff2647;
                      line-height: 17px;
                    }

                    .price-count {
                      font-size: 16px;
                      font-weight: bold;
                      color: #ff2647;
                      line-height: 22px;
                    }
                  }

                  .pay-btn {
                    width: 72px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--main-color);
                    border-radius: 16px;
                    font-size: 12px;
                    color: #ffffff;
                    line-height: 12px;
                  }
                }
              }
            }
          }

          .list-item {
            background-color: #fff;
            padding: 12px;
            display: flex;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.07);

            .img-box {
              display: flex;
              flex-shrink: 0;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              .normal {
                width: 100%;
                height: 100%;
              }

              .default {
                width: 28px !important;
                height: 28px !important;
                z-index: 2;
              }
            }

            .item-content {
              flex: 1;
              position: relative;

              .goods-name {
                color: #1a1a1a;
                @include clamp(2);
                height: 42px;
              }

              .goods-price {
                width: 100%;
                // position: absolute;
                bottom: 0px;
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
              }
            }
          }
        }
      }
    }
  }
}

// 购买按钮1
.button-template-1 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
    height: 12px;
  }
}

// 购买按钮3
.button-template-3 {
  width: 30px;
  height: 20px;
  border-radius: 22px;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 19px;
    height: 12px;
  }
}

// 通用原价
.old-price {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  font-family: 'PingFang SC';
  color: #b3b3b3;
  text-decoration: line-through;
}

// 加载更多样式
.load-more {
  width: 100%;
  text-align: center;
  margin-top: 20px;

  .template1,
  .template2 {
    font-size: 12px;
    color: var(--main-color);
    line-height: 17px;
  }

  .template2 {
    display: inline-block;
    padding: 3px 11px;
    border-radius: 2px;
    border: 1px solid var(--main-color);
  }
}
</style>
