<!--
 * @Author: dongjia
 * @Date: 2021-09-22 14:51:23
 * @LastEditTime: 2022-01-20 18:22:01
 * @LastEditors: aleaner
 * @Description: 商协会业务组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StBusinessAssociationList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="[
      myRootStyle,
      {
        padding: `12px 0px 0px`,
      },
    ]"
  >
    <StListTitle
      :style="{
        padding: `0 16px 16px`,
      }"
      :config="myConfig"
    />
    <div
      v-loading="loading"
      class="list-content template1"
      v-if="myConfig.templateData.styleType === 'template1'"
    >
      <div
        class="list-item"
        :style="{
          marginRight: myConfig.templateData.dataSpacing + 'px',
          borderRadius: myConfig.templateData.borderRadius + 'px',
        }"
        v-for="(item, index) in association_list"
        :key="index"
      >
        <st-load-image
          class="item-cover"
          :src="item.icon"
          mode="aspectFit"
          :size="300"
        />
        <div class="item-content">
          <div class="content-title">{{ item.name }}</div>
          <div class="content-star" v-if="myConfig.templateData.showRange">
            <img
              class="star-icon"
              :class="i <= item.star ? 'stared' : ''"
              v-for="i in 5"
              :key="i"
              src="../../assets/images/st-business-association/star-icon.png"
            />
            <div class="star-text">{{ item.star_text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { associationList,getProjectsTypes} from "../../api/components";
import componentMixin from "@/modules/app-design/mixins/component-mixin";
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";

export default {
  name: "StBusinessAssociationList",
  components: { StLoadImage },
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      defaultList: require("../../assets/jsons/default-list.json")
        .associationList,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          rowMargin: 0,
        },
        templateData: {
          styleType: "template1",
          dataSpacing: 15,
          borderRadius: 8,
          showRange: 1,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          dataFilter: [0,1,2,3,4],
          platformFilter: [""],
        },
        loadingConfig: {
          loadingType: "none",
          limit: 10,
          handleType: "template1",
          handleText: "加载更多",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "商协会",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
        },
      },
      association_list: [],
      timer: "",
    };
  },
  created() {
    this.getAssociationList();
   
    // this.getTypes()
  },
  methods: {
    getAssociationList(notCancel) {
      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });
      console.log(this.myConfig.dataConfig.dataFilter);
      let requestData = {
        page: 1,
        limit: this.myConfig.loadingConfig.limit,
        types: JSON.stringify(this.myConfig.dataConfig.dataFilter),
        platform_ids: JSON.stringify(this.myConfig.dataConfig.platformFilter),
        use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
        template: this.myConfig.templateData.styleType,
        ids,
      };
      this.loading = true;
      associationList(requestData, notCancel)
        .then((res) => {
          const { data } = res;
          this.association_list = data.length ? data : this.defaultList;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    myConfig: {
      handler(val) {
        console.log(val);
        // 防抖
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          
          this.getAssociationList();
        }, 500);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;
  .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;

    .check-more {
      font-size: 12px;
      color: #999999;
    }
  }

  .list-content {
    display: flex;
    overflow: hidden;
    padding-left: 16px;
    min-height: 100px;
    &.template1 {
      width: 100%;
      .list-item {
        flex-shrink: 0;
        width: 192px;
        background-color: #fff;
        box-shadow: 0px 6px 10px 0px #f2f2f2;
        overflow: hidden;
        margin-bottom: 12px;

        .item-cover {
          width: 100%;
          height: 108px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .item-content {
          // height: 80px;
          padding: 10px;
          .content-title {
            font-weight: bold;
            text-align: justify;
            @include nowrap();
            font-size: 14px;
            color: #333c50;
            line-height: 20px;
          }
          .content-star {
            margin-top: 2px;
            display: flex;
            align-items: center;

            .star-icon + .star-icon {
              margin-left: 5px;
            }

            .star-icon {
              width: 10px;
              height: 9px;
              background-color: #e5e5e5;

              &.stared {
                background-color: var(--main-color);
              }
            }

            .star-text {
              margin-left: 15px;
              font-size: 12px;
              color: #c6c6c6;
              line-height: 20px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
