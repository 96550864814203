<template>
  <div :style="{
    '--row-margin': `${myConfig.normal.rowMargin}px`,
    '--margin-top': `${myConfig.normal.marginTop}px`,
  }" class="st-college-people-learning-around">
    <img :src="previewSrc" class="preview-src" />
  </div>
</template>

<script>

export default {
  name: 'st-college-people-learning-around',
  props: {
    config: {
      type: Object,
      default: function () {
        return {}
      },
    },
    page: {
      type: Object,
      default: function () {
        return {}
      },
    },
    version: [String, Number],
  },
  data() {
    const resUrl = window.serverConfig.VUE_APP_ADMINURL

    const model = JSON.parse(JSON.stringify(require('./props')))

    return {
      // https://xxx.myqcloud.com/cos/static/admin/app_design/images/week-learning.png
      previewSrc: `${resUrl}/app_design/images/people-learning-around.png`,
      platform: this.$route.params.platform,
      loading: false,
      // 状态栏图片
      statusBar: {
        black: require('../../../assets/images/phone-statusbar1@2x.png'),
        white: require('../../../assets/images/phone-statusbar2@2x.png'),
      },
      /* 头部右侧胶囊样式图标 */
      handleIcon: {
        black: require('../../../assets/images/wechat-handle1@2x.png'),
        white: require('../../../assets/images/wechat-handle2@2x.png'),
      },
      // 定义 config 格式和默认数据
      model,
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
  created() {

  },
  methods: {
    canIUse(configPath = '') {
      if (!configPath) return false

      if (!this.version) return false

      if (versionControl === undefined) return true
      console.log('当然了，在后台的时候，一定是可用的')
      return true || this.version >= versionControl[configPath]
    },
  },
}
</script>

<style lang="scss" scoped>
.st-college-people-learning-around {
  height: calc(387px);
  overflow: hidden;
  position: relative;

  margin: 0 var(--row-margin);
  margin-top: var(--margin-top);
}

.preview-src {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}
</style>
