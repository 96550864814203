<template>
  <span class="st-channels-live-status" :class="[isLiving ? 'on' : (isEnd ? 'off' : '')]">
    <template v-if="isLiving">直播</template>
    <template v-else-if="isEnd">已结束</template>
    <template v-else-if="isFore">预告</template>
  </span>
</template>

<script>
  export default {
    name: "StChannelsLiveStatus",
    props: {
      text: String
    },
    computed: {
      isLiving() {
        return this.text.indexOf('直播') !== -1;
      },
      isEnd() {
        return this.text.indexOf('结束') !== -1;
      },
      isFore() {
        return this.text.indexOf('预') !== -1;
      }
    },
  }
</script>

<style lang="scss" scoped>
.st-channels-live-status {
  padding: 0 4px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  color: #FFFFFF;
  background-color: rgb(255, 154, 26);

  &.on {
    background-color: #FF1B1B;
  }

  &.off {
    background-color: rgb(170, 170, 170);
  }
}
</style>
