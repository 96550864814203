<template>
  <div
    class="component-lib"
    :class="activeAnimation ? 'active' : ''"
    style="padding-bottom: 60px"
  >
    <!-- 组件库 -->
    <el-collapse v-model="activeMenu">
      <!-- 基础组件 -->
      <el-collapse-item
        :name="group.index"
        v-for="(group, index) in componentLibrary"
        :key="group.index"
      >
        <template slot="title">
          <span class="el-tree-node__content custom-collapse-title">
            <i class="el-icon-caret-right el-tree-node__expand-icon" />
            <span class="custom-tree-node">{{ group.name }}</span>
          </span>
        </template>
        <!-- 自由容器组件库 -->
        <draggable
          v-if="
            group.index === 'free-container' &&
            list.findIndex((el) => el.component === 'st-free-container') != -1
          "
          class="library-list free-container"
          filter=".library-item__disabled"
          v-model="group.components"
          :options="containerDragOptions"
          :move="onMove"
          :clone="(original) => handleClone(original, group.index)"
        >
          <!-- 可拖拽组件 -->
          <div
            :class="it.status === 1 ? 'library-item' : 'library-item__disabled'"
            v-for="(it, i) in group.components"
            v-show="
              (it.use_platform &&
                it.use_platform.includes($route.params.platform)) ||
              !it.use_platform
            "
            :key="it.name"
          >
            <div class="box" v-if="it.status === 1">
              <!-- 未激活图标 -->
              <img
                class="icon"
                :src="
                  require(`../../assets/images/library/${
                    it.icon || 'st-default'
                  }.png`)
                "
              />
              <!-- 激活图标 -->
              <img
                class="icon active"
                :src="
                  require(`../../assets/images/library/${
                    it.icon || 'st-default'
                  }__active.png`)
                "
              />
              <div class="text" style="color: #3a3a3a">{{ it.title }}</div>
              <!-- <div class="count" v-if="it.extra">{{it.extra.count}}/{{it.extra.maxCount}}
              </div> -->
            </div>
            <!-- <el-popover v-else placement="top" width="250" trigger="hover" content="该组件需购买使用，请点击前往购买">
              <div class="box" slot="reference">
                <img class="disabled-icon"
                  :src="require(`../../assets/images/library/${it.icon || 'st-default'}__disabled.png`)" />
                <div class="text" style="color:#C0C4CC">{{it.title}}</div>
                <el-button class="pay-btn" type="text">点击购买</el-button>
              </div>
            </el-popover> -->
            <div class="placement">
              <span>放进自由容器中</span>
            </div>
          </div>
        </draggable>
        <!-- 支持从组件库拖到工作区 -->
        <draggable
          v-if="group.index !== 'free-container'"
          class="library-list"
          filter=".library-item__disabled"
          v-model="group.components"
          :options="dragOptions"
          :move="onMove"
          :clone="(original) => handleClone(original, group.index)"
        >
          <!-- 可拖拽组件 -->
          <div
            :class="it.status === 1 ? 'library-item' : 'library-item__disabled'"
            v-for="(it, i) in group.components"
            :key="it.name"
            v-if="
              it.use_page.includes(
                pageDetail && pageDetail.system_page
                  ? pageDetail.system_page
                  : 'custom-page'
              )
            "
            v-show="
              ((it.use_platform &&
                it.use_platform.includes($route.params.platform)) ||
              !it.use_platform)&&it.status !== 0
            "
            @click.stop="handleClickClone(it, group.index)"
          >
            <div class="box" v-if="it.status === 1">
              <!-- 未激活图标 -->
              <img
                class="icon"
                :src="
                  require(`../../assets/images/library/${
                    it.icon || 'st-default'
                  }.png`)
                "
              />
              <!-- 激活图标 -->
              <img
                class="icon active"
                :src="
                  require(`../../assets/images/library/${
                    it.icon || 'st-default'
                  }__active.png`)
                "
              />
              <div class="text" style="color: #3a3a3a">{{ it.title }}</div>
              <div class="count" v-if="it.extra">
                <template v-if="isTabPage && it.component === 'st-bottom-nav'"
                  >1/1</template
                >
                <template v-else
                  >{{ it.extra.count }}/{{ it.extra.maxCount }}</template
                >
              </div>
            </div>
            <el-popover
              v-if="it.status === 0"
              placement="top"
              width="250"
              trigger="hover"
              content="该组件需购买使用，请点击前往购买"
            >
              <div class="box" slot="reference" @click="goToBuy(it)">
                <img
                  class="disabled-icon"
                  :src="
                    require(`../../assets/images/library/${
                      it.icon || 'st-default'
                    }__disabled.png`)
                  "
                />
                <div class="text" style="color: #c0c4cc">{{ it.title }}</div>
                <el-button class="pay-btn" type="text">点击购买</el-button>
              </div>
            </el-popover>
            <el-popover
              v-if="it.status === 2"
              placement="top"
              width="250"
              trigger="hover"
              content="该组件已过期，请点击前往续费"
            >
              <div class="box" slot="reference" @click="goToBuy(it)">
                <img
                  class="disabled-icon"
                  :src="
                    require(`../../assets/images/library/${
                      it.icon || 'st-default'
                    }__disabled.png`)
                  "
                />
                <div class="text" style="color: #c0c4cc">{{ it.title }}</div>
                <el-button class="pay-btn" type="text">点击续费</el-button>
              </div>
            </el-popover>
            <div class="no-placement" v-if="isSystemActive">
              <span>该页面无法放置组件</span>
            </div>
            <div
              class="no-placement"
              v-else-if="it.extra.count >= it.extra.maxCount"
            >
              <span>组件放置已达上限</span>
            </div>
            <div
              class="no-placement"
              v-else-if="it.component === 'st-custom-nav' && hasCommunityNav"
            >
              <span>当前组件与社区导航组件有冲突</span>
            </div>
            <div
              class="no-placement"
              v-else-if="
                ['st-community-nav', 'st-community-square'].includes(
                  it.component
                ) && hasCustomNav
              "
            >
              <span>当前组件与自定义导航组件有冲突</span>
            </div>
            <!-- 组件列表中已存在底部导航组件（不是系统导航），所以不能放其他组件了 -->
            <div class="no-placement" v-else-if="hasBottomNav">
              <span>已有底部导航组件，无法再添加其他组件</span>
            </div>
            <!-- 底部导航组件和系统导航冲突 -->
            <div
              class="no-placement"
              v-else-if="it.component === 'st-bottom-nav' && isTabPage"
            >
              <span>当前组件与系统设置的底部导航有冲突</span>
            </div>
            <div
              class="no-placement"
              v-else-if="it.component === 'st-custom-nav' && hasPageLoadingList"
            >
              <span>当前组件与分页加载的列表组件有冲突</span>
            </div>
            <div
              class="no-placement"
              v-else-if="it.component === 'st-bottom-nav' && list.length"
            >
              <span>当前组件与其他组件有冲突</span>
            </div>
            <div
              class="no-placement"
              v-else-if="['st-text'].includes(it.component)"
            >
              <span>该组件只能放置在自由容器当中</span>
            </div>
            <div
              class="placement"
              v-else-if="it.component === 'st-custom-header'"
            >
              <span>该组件放置区域只能在头部</span>
            </div>
            <div
              class="placement"
              v-else-if="
                it.component === 'st-custom-nav' ||
                it.component === 'st-community-nav-old' ||
                it.component === 'st-community-nav' ||
                it.component === 'st-community-square'||
                it.component === 'st-membership-dynamics'
              "
            >
              <span>该组件放置区域只能在最下方</span>
            </div>
            <div class="placement" v-else>
              <span>组件放置区域</span>
            </div>
          </div>
        </draggable>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { getComponentLibrary } from "@/modules/app-design/assets/js/tool";

/**
 * 声明哪些系统页显示哪些组件库分类下组件
 */
let componentsPage = require("../../assets/jsons/components-page.json");
import bus from "../../assets/js/bus.js";
/**
 * 获取可用的组件库
 */
import { componentLibApi } from "../../api/components";

import draggable from "vuedraggable";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("appDesign");

export default {
  name: "PageComponentLibrary",
  components: {
    draggable,
  },
  props: {
    selected: {
      type: [String, Number],
    },
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    setting: Object,
  },
  data() {
    return {
      activeMenu: [
        "free-container",
        "base",
        "business",
        // "community-components",
        // "gov",
        // "wxapp-components",
        // "user-center",
        // "company-wxapp-components",
        "activity-components",
        // "shop-components",
        "member",
        "finance",
        "enterprise",
        "news",
        "activity",
        "community",
        "resource",
        "lessons",
        "goods",
        "donation",
        "others",
      ],
      dragOptions: {
        group: {
          name: "drag",
          pull: "clone",
          put: false,
        },
        sort: false,
      },
      containerDragOptions: {
        group: {
          name: "container-drag",
          pull: "clone",
          put: false,
        },
        sort: false,
      },
      componentLib: [],
      myList: [],
      /* 这玩意儿在 PageMiddle 也有一份 */
      havePageLoadingComponent: [
        "st-article-list",
        "st-activity-list",
        "st-activity-list-gov",
        "st-activity-photo",
        "st-brand-list",
        "st-live-list",
        "st-shop-goods",
        "st-multi-level-list",
        "st-special-article",
        "st-channels-video-list",
        "st-channels-live-list",
        "st-activity-posts",
      ],
      isShowNormalComponents: true, //显示除自由容器组件外的组件库
      activeAnimation: false,
      timer: null,
    };
  },
  watch: {
    list(val) {
      this.myList = val;
    },
  },
  computed: {
    ...mapState(["clone", "library", "pageDetail"]),
    isSystemActive() {
      return (
        Object.keys(this.library.setting).includes(this.selected) &&
        !this.pageDetail
      );
    },
    isTabPage() {
      if (this.system && this.system["bottomNav"]) {
        const bottomNavProps = this.system["bottomNav"].props;
        if (!bottomNavProps) return false;

        const list = this.system["bottomNav"].props.tabs.list;
        for (let index = 0; index < list.length; index++) {
          let tab = this.system["bottomNav"].props.tabs.list[index];
          if (tab.is_show) {
            let view = tab.jump_link?.jump_config?.view;
            if (view) {
              if (
                this.pageDetail &&
                this.pageDetail.id &&
                view.id === this.pageDetail.id
              ) {
                return true;
              } else if (
                this.pageDetail &&
                this.pageDetail.system_page &&
                view.system_page === this.pageDetail.system_page
              ) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    hasCustomNav() {
      return !!this.list.find((li) => ['st-custom-nav','st-membership-dynamics'].includes(li.component));
    },
    hasBottomNav() {
      return !!this.list.find((li) => li.component === "st-bottom-nav");
    },
    hasCommunityNav() {
      /**
       * 当数组中的元素在测试条件时返回 true 时, find() 返回符合条件的元素，之后的值不会再调用执行函数。
       * 如果没有符合条件的元素返回 undefined
       * */
      return !!this.list.find((li) =>
        ["st-community-nav", "st-community-square"].includes(li.component)
      );
    },
    hasPageLoadingList() {
      return (
        this.havePageLoadingComponent.includes(
          this.myList[this.myList.length - 1]?.component
        ) &&
        this.myList[this.myList.length - 1].props.loadingConfig.loadingType ===
          "page"
      );
    },
    componentLibrary() {
      let list = [];
      if (
        this.list.length &&
        this.list.findIndex((el) => el.component === "st-free-container") !== -1
      ) {
        if (this.isShowNormalComponents) {
          list = [
            "free-container",
            ...componentsPage[
              this.pageDetail &&
              this.pageDetail.system_page &&
              componentsPage[this.pageDetail.system_page]
                ? this.pageDetail.system_page
                : "custom-page"
            ],
          ];
        } else {
          list = ["free-container"];
        }
      } else {
        list =
          componentsPage[
            this.pageDetail && this.pageDetail.system_page
              ? this.pageDetail.system_page
              : "custom-page"
          ] || [];
        // if (this.pageDetail && this.pageDetail.system_page && componentsPage[this.pageDetail.system_page] === undefined) {
        //   console.error('请在 components-page.json 中声明可拖拽的组件分类')
        // }
      }
      /* ！组件库分类的顺序问题：到超级后台修改排序即可 */
      return this.library.custom_components.filter((el) =>
        list.includes(el.index)
      );
    },
  },
  created() {
    this.$store.commit(
      "appDesign/setLibrary",
      JSON.parse(JSON.stringify(getComponentLibrary()))
    );
    this.getComponentLib();
  },
  mounted() {
    bus.$on("activeComponentLib", () => {
      this.activeAnimation = true;
      if (this.timer) clearTimeout(this.timer);
      setTimeout(() => {
        this.activeAnimation = false;
      }, 1500);
    });
    bus.$on("hideComponents", (hideComponents) => {
      if (hideComponents !== undefined) {
        this.isShowNormalComponents = hideComponents;
      }
    });
  },
  methods: {
    // 获取组件库列表
    async getComponentLib() {
      componentLibApi({ platform: this.$route.params.platform })
        .then((res) => {
          const componentLib = [];
          const { data } = res;
          // 本地组件库与接后端组件库匹配显示
          let searchGetable = true;
          let articleCategoryGetable = true;
          let specialArticleCategoryGetable = true;
          let shopCategoryGetable = true;
          let ShowMemberIdentity = true;
          let brandCategoryGetable = true;
          let activityPhotoCategoryGetable = true;
          let spiritColumnOptionsGetable = true;
          let onlineLearningCategoryGetable = true;
          let groupListGetable = true;
          let apiTasks = [];
          // 将本地的自定义组件配置跟请求的配置做对比
          data.map((item1) => {
            this.library.custom_components.map((item2) => {
              // 匹配上了
              if (item1.name === item2.name) {
                const components = [];
                item1.components.map((item3) => {
                  const element = item2.components.find(
                    (el) => el.component === item3.component
                  );
                  if (element) {
                    components.push({ ...element, ...item3 });
                  }
                  // 获取基础、业务组件表单配置相关选项数据
                  if (
                    ["st-search", "st-custom-header"].includes(item3.component)
                  ) {
                    if (searchGetable) {
                      apiTasks.push(
                        this.$store.dispatch("appDesign/getSearchTypes", {
                          platform: this.$route.params.platform,
                        })
                      );
                    }
                    searchGetable = false;
                  }
                  if (["st-article-list"].includes(item3.component)) {
                    if (articleCategoryGetable)
                      apiTasks.push(
                        this.$store.dispatch("appDesign/getArticleCategory", {
                          has_subject: 0,
                          multi_select: 1,
                        })
                      );
                    articleCategoryGetable = false;
                  }
                  if (["st-special-article"].includes(item3.component)) {
                    if (specialArticleCategoryGetable)
                      apiTasks.push(
                        this.$store.dispatch(
                          "appDesign/getSpecialArticleCategory",
                          {
                            has_subject: 1,
                            multi_select: 1,
                          }
                        )
                      );
                    specialArticleCategoryGetable = false;
                  }
                  if (["st-shop-goods"].includes(item3.component)) {
                    if (shopCategoryGetable)
                      apiTasks.push(
                        this.$store.dispatch("appDesign/getShopCategory")
                      );
                    shopCategoryGetable = false;
                  }
                  if (["st-structure"].includes(item3.component)) {
                    if (ShowMemberIdentity)
                      apiTasks.push(
                        this.$store.dispatch("appDesign/getShowMemberIdentity")
                      );
                    shopCategoryGetable = false;
                  }
                  if (["st-brand-list"].includes(item3.component)) {
                    if (brandCategoryGetable)
                      apiTasks.push(
                        this.$store.dispatch(
                          "appDesign/getBrandCategoryOptions",
                          { is_normal: 1 }
                        )
                      );
                    brandCategoryGetable = false;
                  }
                  if (["st-activity-photo"].includes(item3.component)) {
                    if (activityPhotoCategoryGetable)
                      apiTasks.push(
                        this.$store.dispatch(
                          "appDesign/getActivityPhotoCategoryOptions"
                        )
                      );
                    activityPhotoCategoryGetable = false;
                  }
                  if (["st-spirit-list"].includes(item3.component)) {
                    if (spiritColumnOptionsGetable)
                      apiTasks.push(
                        this.$store.dispatch("appDesign/getSpiritColumnOptions")
                      );
                    spiritColumnOptionsGetable = false;
                  }
                  if (["st-online-learning"].includes(item3.component)) {
                    if (onlineLearningCategoryGetable)
                      apiTasks.push(
                        this.$store.dispatch(
                          "appDesign/getOnlineLearningOptions"
                        )
                      );
                    onlineLearningCategoryGetable = false;
                  }
                  // 社区达人
                  if (["st-community-talent"].includes(item3.component)) {
                    if (groupListGetable)
                      // 小组
                      apiTasks.push(
                        this.$store.dispatch("appDesign/getHotGroupList")
                      );
                    // 架构
                    apiTasks.push(
                      this.$store.dispatch("appDesign/getParentList")
                    );
                    groupListGetable = false;
                  }
                });
                componentLib.push({
                  ...item1,
                  components: components,
                  index: item2.index,
                });
              }
            });
          });

          /* 获取系统设置列表 */
          apiTasks.push(
            this.$store.dispatch("appDesign/getSettingList", {
              platform: this.$route.params.platform,
            })
          );
          /* 获取系统页会员身份 组织架构页需要用到*/
          apiTasks.push(
            this.$store.dispatch("appDesign/getShowMemberIdentity")
          );
          if (apiTasks.length) {
            this.$store.commit("appDesign/setLoading", true);
            // this.$emit("handleApiTasks", apiTasks);
            Promise.all(apiTasks)
              .then((res) => {
                this.$bus.emit("allowGetPageDetail");
              })
              .catch((err) => {
                this.$store.commit("appDesign/setLoading", false);
                console.log("部分请求失败");
              });
          } else {
            this.$bus.emit("allowGetPageDetail");
          }
          this.componentLib = componentLib;

          let myLib = JSON.parse(JSON.stringify(this.library));
          myLib.custom_components = componentLib;
          this.$store.commit("appDesign/setLibrary", myLib);
          // console.log("componentLib", componentLib.map(b => b.name));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onMove(e) {
      let flag = true;
      const el = e.relatedContext.element;
      // 不允许停靠
      if (
        el?.component === "st-custom-header" ||
        (el?.component === "st-article-list" &&
          el?.props.normal.loadingType === "page")
      ) {
        flag = false;
      }
      return flag;
    },
    handleClone(original, key) {
      let copy = JSON.parse(JSON.stringify(original));
      // console.log('extra origin', copy.extra)
      let extra = {
        // 保存页面配置时会忽略
        extra: {
          // 存储了count，maxCount
          ...copy.extra,
          // 不加唯一标识会造成数据混乱
          timestamp: new Date().getTime(),
          /* 标记base/business */
          type: key,
          // resize: false,
          version_different: false,
        },
      };
      let cloneObj = Object.assign({}, copy, extra);
      // console.log('clone', cloneObj)
      // 辅助记录所拖拽组件的使用次数等
      this.$store.commit("appDesign/setClone", {
        key: key,
        data: cloneObj,
      });
      return cloneObj;
    },
    // 点击组件克隆
    handleClickClone(original, key) {
      if (!original.status) return;
      if (original.extra.count >= original.extra.maxCount) {
        this.$message.error("放置已达上限");
        return;
      }
      if (this.isSystemActive) {
        this.$message.error("该页面无法放置组件");
        return;
      }
      if (original.component === "st-custom-nav" && this.hasCommunityNav) {
        this.$message.error("当前组件与社区导航组件有冲突");
        return;
      }
      if (
        ["st-community-nav", "st-community-square"].includes(
          original.component
        ) &&
        this.hasCustomNav
      ) {
        this.$message.error("当前组件与自定义导航组件有冲突");
        return;
      }
      if (this.hasBottomNav) {
        this.$message.error("已有底部导航组件，无法再添加其他组件");
        return;
      }
      if (original.component === "st-bottom-nav" && this.isTabPage) {
        this.$message.error("当前组件与系统设置的底部导航有冲突");
        return;
      }
      if (original.component === "st-custom-nav" && this.hasPageLoadingList) {
        this.$message.error("当前组件与分页加载的列表组件有冲突");
        return;
      }
      if (original.component === "st-bottom-nav" && this.list.length) {
        this.$message.error("当前组件与其他组件有冲突");
        return;
      }
      let copy = JSON.parse(JSON.stringify(original));
      // console.log('extra origin', copy.extra)
      let extra = {
        // 保存页面配置时会忽略
        extra: {
          // 存储了count，maxCount
          ...copy.extra,
          // 不加唯一标识会造成数据混乱
          timestamp: new Date().getTime(),
          /* 标记base/business */
          type: key,
          // resize: false,
          version_different: false,
        },
      };
      let cloneObj = Object.assign({}, copy, extra);
      // console.log('clone', cloneObj)
      // 辅助记录所拖拽组件的使用次数等
      this.$store.commit("appDesign/setClone", {
        key: key,
        data: cloneObj,
      });
      bus.$emit("addComponent", cloneObj);
    },
    // 跳转购买组件应用
    goToBuy(item) {
      let featureSign = item.belong_feature;
      this.handleJumpUserAdmin(
        "AppDetail",
        { featureSign },
        {
          project: item.buy_project_id,
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.component-lib {
  &.active {
    animation-name: example;
    animation-duration: 1.5s;
  }

  @keyframes example {
    0% {
      opacity: 0.5;
    }

    25% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
}

.el-collapse {
  .custom-collapse-title {
    width: 100%;
    color: #606266;
    font-weight: normal;
    height: 36px;
    font-size: 12px;
  }

  .el-collapse-item__header {
    border-bottom: none;
    height: 36px;
    font-size: 14px;

    &.is-active {
      .el-tree-node__expand-icon {
        transform: rotate(90deg);
      }
    }
  }

  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .el-collapse-item__arrow {
    display: none;
  }
}

.library-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 66.5px);
  justify-content: space-around;
  padding: 0 14px;

  .library-item {
    padding: 4px 0;
    cursor: pointer;
    text-align: center;

    &:hover,
    &.active {
      color: white;

      .box {
        background-color: $primary;
      }

      .icon {
        display: none;

        &.active {
          display: block;
        }
      }

      .text,
      .count {
        color: white !important;
      }
    }

    .box {
      border-radius: 2px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .disabled-icon {
      width: 24px;
      height: 24px;

      &.active {
        display: none;
      }
    }

    .icon {
      width: 24px;
      height: 24px;

      &.active {
        display: none;
      }
    }

    .pay-btn {
      padding: 8px 0 0;
      font-size: 12px;
      color: #3576ff;
      line-height: 12px;
    }

    .text,
    .count {
      font-size: 12px;
      line-height: 12px;
    }

    .text {
      color: #3a3a3a;
      margin-top: 12px;
    }

    .count {
      color: #999999;
      margin-top: 8px;
    }
  }

  .library-item__disabled {
    padding: 4px 0;
    cursor: pointer;
    text-align: center;

    .box {
      border-radius: 2px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .disabled-icon {
      width: 24px;
      height: 24px;

      &.active {
        display: none;
      }
    }

    .icon {
      width: 24px;
      height: 24px;

      &.active {
        display: none;
      }
    }

    .pay-btn {
      padding: 8px 0 0;
      font-size: 12px;
      color: #3576ff;
      line-height: 12px;
    }

    .text,
    .count {
      font-size: 12px;
      line-height: 12px;
    }

    .text {
      color: #3a3a3a;
      margin-top: 12px;
    }

    .count {
      color: #999999;
      margin-top: 8px;
    }
  }

  .no-placement,
  .placement {
    display: none;
  }
}

/* 选中样式 */
.chosen {
  opacity: 0;
}

/* 占位样式 */
.ghost {
  .box {
    display: none;
  }

  .no-placement,
  .placement {
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 9px;
      color: white;
    }
  }

  .no-placement {
    /* 竖向双色斑马条纹 */
    background: linear-gradient(
      to right,
      rgba(255, 83, 80, 0.4) 12.5%,
      rgba(255, 176, 175, 0.2) 0
    );
    background-size: 8px 100%;

    span {
      background: #ff9391;
    }
  }

  .placement {
    background: linear-gradient(
      to right,
      rgba(53, 118, 255, 0.4) 12.5%,
      rgba(53, 118, 255, 0.2) 0
    );
    background-size: 8px 100%;

    span {
      background: #6f9aff;
    }
  }
}

/* 自定义容器占位样式 */
.free-container {
  .library-item {
    cursor: move;
  }
}

.ghost-free-container {
  position: absolute;
  right: -20px;
  top: 100px;

  .box {
    display: none;
  }

  .no-placement,
  .placement {
    display: block;
    transform: translateX(100%);
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 2px;
    font-size: 12px;
    color: #3576ff;
    line-height: 12px;
    padding: 8px 15px;
    white-space: nowrap;
    pointer-events: none;

    &::before {
      content: "\00a0";
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-width: 7px 16px 7px 0;
      border-color: transparent white transparent transparent;
      position: absolute;
      left: -13%;
    }
  }
}

.el-popover {
  padding: 10px;
}
</style>
