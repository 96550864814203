<!--
 * @Author: dongjia
 * @Date: 2021-07-14 13:45:58
 * @LastEditTime: 2021-08-10 14:14:26
 * @LastEditors: aleaner
 * @Description: 个人主页（旧）
 * @FilePath: \app-design\components\Preview\StOldMePage.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="st-me-page">
    <img v-if="platform !== 'h5'" class="start-top-img" src="../../assets/images/start-top.png">
    <st-page-set v-else title="我的"></st-page-set>
    <div class="me-bg" :style="{top: (platform == 'h5'? 64: 0) + 'px'}">
      <img class="me-bg-image" :src="fixImageUrl(myConfig.normal.bgImage,{h: 400}, true)" />
    </div>
    <div class="me-content">
      <div class="me-card-list">
        <div class="me-card">
          <div class="me-avatar">
            <img class="me-avatar-image" src="../../assets/images/st-me-page/avatar1.png" />
          </div>
          <div class="me-nickname">社团云演示</div>
          <div class="me-intro">组织架构 | 会内职务</div>
          <div class="me-count flex-center">
            <div class="count-item flex-col">
              <span class="count-num">0</span>
              <span>收藏</span>
            </div>
            <div class="count-item flex-col">
              <span class="count-num">0</span>
              <span>活动</span>
            </div>
            <div class="count-item flex-col">
              <span class="count-num">0</span>
              <span>资源</span>
            </div>
          </div>
        </div>
        <div class="me-card">
          <div class="func-list flex-col">
            <div class="func-group" v-for="(item, index) in Math.ceil(list.length / lineNum)">
              <div class="func-item flex-col flex-center" v-for="(it, i) in list" :key="it.name"
                v-if="i >= (index * lineNum) && i < (index + 1) * lineNum">
                <img class="func-icon" :src="require(`../../assets/images/st-me-page/center${i + 1}.png`)" />
                <div class="func-text">{{it.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="me-card">
          <div class="me-item flex-between">
            <span class="">退出账号</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
export default {
  name: "StOldMePage",
  components: {StPageSet},
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        normal: {
          bgImage: "",
        },
      },
      list: [
        { name: "我的消息" },
        { name: "我的主页" },
        { name: "我的企业" },
        { name: "我的资讯" },
        { name: "我的订单" },
        { name: "会费缴纳" },
      ],
      lineNum: 4,
      baseUrl: window.serverConfig.VUE_APP_ADMINURL,
      platform: this.$route.params.platform,
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  }
};
</script>

<style lang="scss" scoped>
.st-me-page {
  width: 100%;
  position: relative;

  .start-top-img {
    position: relative;
    width: 100%;
    height: 65px;
    z-index: 8;
  }

  .me-bg {
    position: absolute;
    top: 0;
    width: 100%;

    .me-bg-image {
      height: 235px;
      width: 100%;
    }
  }

  .me-content {
    padding: 100px 20px 0;
    position: relative;
    z-index: 2;

    .me-card {
      border-radius: 8px;
      background-color: white;
      text-align: center;

      & + .me-card {
        margin-top: 8px;
      }
    }

    .me-avatar {
      height: 34px;
      position: relative;

      .me-avatar-image {
        border-radius: 50%;
        background-color: $image-placeholder;
        width: 68px;
        height: 68px;
        position: absolute;
        bottom: 0;
        left: calc(50% - 34px);
      }
    }

    .me-nickname {
      margin-top: 10px;
      font-size: 18px;
      color: #303030;
      padding: 5px 0;
    }

    .me-intro {
      font-size: 12px;
      color: #888;
      line-height: 17px;
      padding: 5px 0;
    }

    .me-count {
      display: flex;
      padding: 8px 0 28px;

      .count-item {
        font-size: 14px;
        color: #888;
        width: 101px;
      }

      .count-num {
        font-weight: bold;
        size: 20px;
        color: #353535;
        margin-bottom: 10px;
      }
    }
  }

  .func-list {
    padding: 10px 0;

    .func-group {
      display: flex;
      justify-content: flex-start;
      position: relative;

      & + .func-group {
        &::before {
          content: "";
          position: absolute;
          width: 90%;
          height: 1px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: #f1f1f1;
        }
      }
    }

    .func-item {
      width: 84px;
      padding: 10px 0;
    }

    .func-icon {
      width: 36px;
      height: 36px;
    }

    .func-text {
      font-size: 12px;
      color: #353535;
      margin-top: 4px;
    }
  }

  .me-item {
    width: 90%;
    height: 65px;
    margin: 0 auto;
  }
}
</style>
