<template>
  <div class="brand-list" :style="[myRootStyle, {
        minHeight: from === 'systemPage'?'564px':'100px'
    }]">
    <StListTitle :config="myConfig" v-if="from === 'components'" />
    <div v-loading="loading" class="swiper-view template1"
      v-if="myConfig.templateData.styleType === 'template1'" :style="{
        'grid-column-gap': myConfig.templateData.dataSpacing + 'px',
        'grid-row-gap': myConfig.templateData.dataSpacing + 'px'
      }">
      <div class="list-item" :style="{
        borderRadius: myConfig.templateData.borderRadius + 'px',
      }" v-for="(item,index) in photoList" :key="index">
        <div class="list-photo">
          <st-load-image :src="item.cover" class="photo-img" :size="200" />
        </div>
        <div class="photo-title">
          <p>{{item.activity_name}}</p>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="swiper-view template2"
      v-if="myConfig.templateData.styleType === 'template2'">
      <div class="list-item" :style="{
        marginTop: index !== 0 ? myConfig.templateData.dataSpacing + 'px': '0',
      }" v-for="(item,index) in photoList" :key="index">
        <div class="photo-list">
          <div class="photo-item" :style="{
            position: num === 1 ? 'relative':'absolute',
            width: num === 1 ? 'calc(100% - 10px)': 'calc(100% - 44px)',
            borderRadius: myConfig.templateData.borderRadius + 'px',
            left: num === 1 ? '0px': '45px',
            zIndex: 3-num,
            backgroundColor: num === 1 ? 'rgb(244, 248, 257)' : '#f2f3f5'
          }" v-for="num in 2" :key="num">
            <el-image v-if="num === 1 && item.cover" class="photo-img" :src="fixSpecialImageUrl(item.cover, 'large', index, num)"
              fit="cover"></el-image>
            <el-image v-else-if="num === 2 && item.photo.length" class="photo-img"
              :src="fixSpecialImageUrl(item.photo[0].res.url, 'medium', index, num)" fit="cover"></el-image>
            <el-image v-else class="default-photo"
              :src="require('../../assets/images/st-activity-photo/default-photo-icon.png')"></el-image>
            <div class="cover-title" v-if="num === 1">
              <p class="photo-title">{{item.activity_name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="swiper-view template3"
      v-if="myConfig.templateData.styleType === 'template3'">

      <div class="list-item" :style="{
        marginTop: index !== 0 ? myConfig.templateData.dataSpacing + 'px': '0',
        borderRadius: myConfig.templateData.borderRadius + 'px',
        boxShadow: myConfig.templateData.shadowVague?`0px ${myConfig.templateData.shadowVague}px 10px 0px #F2F2F2`:''
      }" v-for="(item,index) in photoList" :key="index">
        <p class="photo-title">{{item.activity_name}}</p>
        <div class="photo-data"
          v-if="myConfig.templateData.isShowMaterial || myConfig.templateData.isShowViewNum">
          <div class="data-item" v-if="myConfig.templateData.isShowMaterial">
            <el-image class="item-icon"
              :src="require('../../assets/images/st-activity-photo/picture-icon.png')" fit="cover"></el-image>
            <div class="data-num">{{item.photo_image_count}}</div>
          </div>
          <div class="data-item" v-if="myConfig.templateData.isShowViewNum">
            <el-image class="item-icon" :src="require('../../assets/images/st-activity-photo/views-icon.png')"
              fit="cover"></el-image>
            <div class="data-num">{{item.view_num}}</div>
          </div>
        </div>
        <div class="photo-list">
          <div class="photo-item" v-for="num in 4" :key="num" :style="{
              borderRadius:myConfig.templateData.borderRadius + 'px',
            }">
            <el-image v-if="item.photo[num-1]" class="photo-img" :src="fixSpecialImageUrl(item.photo[num-1].res.url, '', index, num)"
              fit="cover"></el-image>
            <el-image v-else class="default-photo"
              :src="require('../../assets/images/st-activity-photo/default-photo-icon.png')"></el-image>
          </div>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="swiper-view grid-two-column"
      v-if="myConfig.templateData.styleType === 'template4'" :style="{
        'grid-column-gap': myConfig.templateData.dataSpacing + 'px',
        'grid-row-gap': myConfig.templateData.dataSpacing + 'px'
      }">
      <div class="list-item" v-for="(item,index) in photoList" :key="index" :style="{
        borderRadius: myConfig.templateData.borderRadius + 'px',
        boxShadow: myConfig.templateData.shadowVague?`0px ${myConfig.templateData.shadowVague}px 10px 0px #F2F2F2`:''
      }">
        <div class="item-time">{{item.activity_simple_time}}</div>
        <div class="item-title">{{item.activity_name}}</div>
        <div class="item-imgs" :class="item.photo.length>3?'multi-img':''">
          <div class="item-img-box" v-if="item.photo.length<=3">
            <st-load-image class="box-img" :src="item.photo[0].res.url" :size="200" proportion="100%" :border-radius="myConfig.templateData.borderRadius" />
          </div>
          <div class="item-img-box" v-if="item.photo.length>3" v-for="(img, i) in item.photo" :key="i">
            <st-load-image class="box-img" :src="img.res.url" :size="200" proportion="100%" :border-radius="myConfig.templateData.borderRadius" />
            <div class="img-mask" v-if="item.photo_image_count > 4 && i === 3" :style="{
                      borderRadius: myConfig.templateData.borderRadius + 'px',
            }">+
              {{item.photo_image_count - 4>999?999:item.photo_image_count - 4}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="swiper-view single-column"
      v-if="myConfig.templateData.styleType === 'template5'">
      <div class="list-item" v-for="(item,index) in photoList" :key="index" :style="{
        marginTop: index > 0?myConfig.templateData.dataSpacing + 'px': '',
        borderRadius: myConfig.templateData.borderRadius + 'px',
        boxShadow: myConfig.templateData.shadowVague?`0px ${myConfig.templateData.shadowVague}px 10px 0px #F2F2F2`:''
      }">
        <div class="item-time">{{item.activity_simple_time}}</div>
        <div class="item-title">{{item.activity_name}}</div>
        <div class="item-imgs">
          <div class="item-img-box" v-if="item.photo.length<3" v-for="(img, i) in item.photo" :key="i" :style="{
              borderRadius:myConfig.templateData.borderRadius + 'px',
            }">
            <st-load-image class="box-img" :src="img.res.url" :size="200" />
          </div>
          <div class="item-img-box" v-if="item.photo.length>=3" v-for="num in 3" :key="num" :style="{
              borderRadius:myConfig.templateData.borderRadius + 'px'
            }">
            <st-load-image class="box-img" :src="item.photo[num - 1].res.url" :size="200" />
            <div class="img-mask" v-if="item.photo_image_count > 3 && num === 3">+
              {{item.photo_image_count - 3>999?999:item.photo_image_count - 3}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
      <div :class="[myConfig.loadingConfig.handleType]">
        {{myConfig.loadingConfig.handleText}}</div>
    </div>
  </div>
</template>

<script>
import { activityPhotoList } from "../../api/components";
import componentMixin from "@/modules/app-design/mixins/component-mixin";
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";

export default {
  name: "StActivityPhoto",
  components: {StLoadImage},
  mixins: [componentMixin],
  props: {
    from: {
      type: String,
      default: "components",
    },
  },
  data() {
    return {
      loading: false,
      defaultList: require("../../assets/jsons/default-list.json")
        .activityPhotoList,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          rowMargin: 0,
        },
        templateData: {
          styleType: "template1",
          borderRadius: 8,
          dataSpacing: 15,
          shadowVague: 6,
          isShowMaterial: 1,
          isShowViewNum: 1,
        },
        dataConfig: {
          dataType: "filter",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          dataAll: 1,
          dataFilter: [""],
        },
        loadingConfig: {
          loadingType: "none",
          limit: 10,
          handleType: "template1",
          handleText: "加载更多",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "活动相册",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
        },
      },
      photoList: [],
    };
  },
  watch: {
    "myConfig.loadingConfig.limit"(val) {
      this.getActivityPhotoList(false);
    },
    "myConfig.dataConfig.dataType"(val) {
      this.getActivityPhotoList(false);
    },
    "myConfig.dataConfig.dataArr"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityPhotoList(false);
      }
    },
    "myConfig.dataConfig.dataAll"(val) {
      this.getActivityPhotoList(false);
    },
    "myConfig.dataConfig.dataFilter"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityPhotoList(false);
      }
    },
  },
  created() {
    if (this.from === "components") {
      this.getActivityPhotoList();
    } else {
      this.photoList = this.defaultList;
    }
  },
  methods: {
    getActivityPhotoList(notCancel) {
      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });
      let requestData = {
        page: 1,
        limit: this.myConfig.loadingConfig.limit,
        category_all: this.myConfig.dataConfig.dataAll,
        category_ids: this.myConfig.dataConfig.dataFilter,
        use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
        ids,
      };
      this.loading = true;
      activityPhotoList(requestData, notCancel)
        .then((res) => {
          const { data } = res;
          this.defaultList.forEach((el) => {
            el.organizations = this.defaultAvatars;
          });
          this.photoList = data.length ? data : this.defaultList;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/component";

.brand-list {
  padding: 12px 16px;
  background-size: 100% auto;
  background-repeat: repeat-y;
  // background-color: #f7f8f9;

  .list-title {
    padding-bottom: 16px;
  }

  .swiper-view {
    min-height: 100px;
    &.template1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .list-item {
        background-color: #fff;
        overflow: hidden;
        box-shadow: 0 6px 10px 0 #f2f2f2;
        .list-photo {
          width: 100%;
          position: relative;
          background-color: #f2f3f5;
          &::before {
            content: "";
            display: block;
            padding-top: 50%;
          }
          .photo-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .default-photo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 44px;
            height: 44px;
          }
        }
        .photo-title {
          margin: 10px;
          font-size: 14px;
          font-weight: bold;
          color: #191e29;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          p {
            height: 40px;
          }
        }
      }
    }
    &.template2 {
      .photo-list {
        position: relative;
        display: flex;
        align-items: center;
        .photo-item {
          border-radius: 4px;
          overflow: hidden;
          &::before {
            content: "";
            display: block;
            padding-top: 73%;
          }
          .photo-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .default-photo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 44px;
            height: 44px;
          }
          .cover-title {
            width: 100%;
            height: 64px;
            position: absolute;
            display: flex;
            align-items: center;
            bottom: 0;
            background: linear-gradient(
              360deg,
              rgba(0, 0, 0, 0.5) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            .photo-title {
              margin: 12px;
              font-size: 14px;
              font-weight: bold;
              color: #ffffff;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    &.template3 {
      .list-item {
        padding: 16px 12px;
        background-color: #fff;
        overflow: hidden;
        .photo-title {
          font-size: 14px;
          font-weight: bold;
          color: #323233;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .photo-data {
        display: flex;
        align-items: center;
        margin-top: 5px;

        .data-item + .data-item {
          margin-left: 12px;
        }

        .data-item {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          .item-icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
          .data-num {
            width: 31px;
            font-size: 14px;
            color: #969799;
            line-height: 14px;
          }
        }
      }
      .photo-list {
        display: flex;
        align-items: center;
        margin-top: 13px;
        .photo-item + .photo-item {
          margin-left: 12px;
        }
        .photo-item {
          width: 100%;
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          background-color: #f2f3f5;
          &::before {
            content: "";
            padding-top: 100%;
            display: block;
          }
          .photo-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .default-photo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 44px;
            height: 44px;
          }
        }
      }
    }
    &.grid-two-column {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      .list-item {
        padding: 8px;
        background-color: #fff;
        .item-time {
          font-size: 12px;
          color: #b3b3b3;
          line-height: 1.5;
        }
        .item-title {
          margin-top: 4px;
          height: 42px;
          font-size: 14px;
          color: #1a1a1a;
          line-height: 1.5;
          @include clamp(2);
        }
        .item-imgs {
          margin-top: 8px;
          &.multi-img {
            display: grid;
            grid-template-columns: repeat(2, minmax(10px, 1fr));
            column-gap: 4px;
            row-gap: 4px;
          }
          .item-img-box {
            width: 100%;
            position: relative;
            overflow: hidden;

            .box-img {
              /*position: absolute;*/
              /*top: 0;*/
              /*left: 0;*/
              width: 100%;
              height: 100%;
            }
            .default-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 28px;
              height: 28px;
            }
            .img-mask {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
              background: rgba(0, 0, 0, 0.7);
              z-index: 1;
            }
          }
        }
      }
    }
    &.single-column {
      .list-item {
        padding: 8px;
        background-color: #fff;
        .item-time {
          font-size: 12px;
          color: #b3b3b3;
          line-height: 1.5;
        }
        .item-title {
          margin-top: 4px;
          font-size: 14px;
          color: #1a1a1a;
          line-height: 1.5;
          @include nowrap();
        }
        .item-imgs {
          margin-top: 8px;
          display: grid;
          grid-template-columns: repeat(3, minmax(10px, 1fr));
          column-gap: 8px;
          .item-img-box {
            width: 100%;
            position: relative;
            overflow: hidden;
            &::before {
              content: "";
              display: block;
              padding-top: 100%;
            }
            .box-img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            .default-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 28px;
              height: 28px;
            }
            .img-mask {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
              background: rgba(0, 0, 0, 0.7);
            }
          }
        }
      }
    }
  }
}
</style>
