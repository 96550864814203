<template>
  <div class="theme-item" :class="active? 'active':''" @click="handleClick">
    <div class="theme-item-inner" v-for="(item) in arr" :style="{
      backgroundColor: item,
      borderColor: item === '#FFFFFF' ? '#DCDFE6' : item
    }" />
  </div>
</template>

<script>
  export default {
    name: "ThemeItem",
    props: {
      arr: {
        type: Array,
        default: () => []
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClick() {
        this.$emit('click')
      }
    },
  }
</script>

<style lang="scss" scoped>
.theme-item {
  display: flex;
  flex-wrap: nowrap;
  width: calc(24px * 3);
  height: 24px;
  cursor: pointer;

  &.active {
    outline: 1px solid #3576FF;
    outline-offset: 1px;
  }

  .theme-item-inner {
    height: 100%;
    width: 24px;
    border-width: 1px;
    border-style: solid;
  }
}
</style>
