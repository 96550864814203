<template>
  <div
    class="st-structure flex-col"
    v-if="myConfig"
    :class="[myConfig.templateData.styleType]"
    :style="{
      backgroundColor:
        myConfig.templateData.bgType === 'color'
          ? myConfig.templateData.bgColor
          : '',
      backgroundImage:
        myConfig.templateData.bgType === 'image'
          ? `url(${myConfig.templateData.bgImage})`
          : '',
    }"
  >
    <!-- config: 页面设置 page; jumpConfig: 头部跳转 headerJump -->
    <st-page-set
      :config="myConfig"
      :jump-config="jumpConfig"
      :bg-color="myConfig.header.bgColor"
    ></st-page-set>
    <st-search :config="{ normal: myConfig.searchConfig }"></st-search>
    <!--    <img v-if="myConfig.templateData.styleType === 'template2'"-->
    <!--         src="../../assets/images/st-structure/template2.png" width="100%" />-->
    <!-- 筛选分类 -->
    <div :style="{ backgroundColor: myConfig.tabs.bgColor || '#fff' }" class="nav-box">
      <div style="height: 8px" v-if="myConfig.templateData.styleType === 'template1'" />
      <div
        class="nav-list"
        :style="{
          paddingTop: tabConfig.normal.navPaddingTop + 'px',
          marginLeft: `${tabConfig.normal.navRowMargin}px`,
          marginRight: `${tabConfig.normal.navRowMargin}px`,
          '--line-width': tabConfig.normal.underlineWidth || 0,
          '--line-height': tabConfig.normal.underlineHeight || 0,
          '--line-radius': tabConfig.normal.underlineBorderRadius || 0,
          '--line-bottom': tabConfig.normal.underlineBottom || 0,
          '--line-bg': tabConfig.normal.underlineColor || 'var(--main-color)',
        }"
      >
        <div
          class="nav-item"
          v-for="(item, index) in myTabs"
          :key="index"
          :class="[index === currentIndex ? 'active' : '']"
          :style="{
            '--active-color': myConfig.tabs.fontActiveColor,
            '--inactive-color': myConfig.tabs.fontInactiveColor,
          }"
        >
          <el-image
            class="nav-image"
            v-if="item.leftIcon"
            :src="item.leftIcon"
            fit="cover"
          ></el-image>
          <!--
                :style="{
              color:
                index === currentIndex
                  ? tabConfig.normal.selectedColor
                  : tabConfig.normal.defaultColor,
            }"
          -->
          <div>
            {{ item.name ? item.name : `组织${ch_sort[index]}` }}
            <div
              class="nav-active"
              :style="{
                backgroundColor: tabConfig.normal.selectedColor,
              }"
              v-if="index === currentIndex"
            ></div>
          </div>
          <el-image
            class="nav-image"
            v-if="item.rightIcon"
            :src="item.rightIcon"
            fit="cover"
          ></el-image>
        </div>
        <!-- 筛选 -->
        <div
          :style="{
            backgroundColor: myConfig.tabs.bgColor || '#fff',
          }"
          class="filter"
          v-if="myConfig.otherConfig.showFilter"
        >
          <img
            class="icon"
            src="../../assets/images/st-structure/filter-icon-new.png"
          />
          <span class="text">更多</span>
        </div>
      </div>
<!--      <div v-if="myConfig.templateData.styleType === 'template2'" style="height: 6px" />-->
    </div>
    <!-- 模板一 -->
    <template v-if="myConfig.templateData.styleType === 'template1'">
      <!-- 内容 -->
      <div class="title">社团云组织</div>
      <div v-if="myConfig.otherConfig.showTotal" class="total">
        （100 名会员）
      </div>
      <div
        class="list"
        :style="{
          backgroundColor:
            myConfig.templateData.contentBgType === 'color'
              ? myConfig.templateData.contentBgColor
              : '',
          backgroundImage:
            myConfig.templateData.contentBgType === 'image'
              ? `url(${myConfig.templateData.contentBgImage})`
              : '',
        }"
      >
        <div class="item">
          <div class="sub-title">
            主席<span v-if="config.otherConfig.showCount && false" class="count"
              >（1人）</span
            >
          </div>
          <p style="font-size: 14px; margin-top: 10px; text-align: center">
            <template v-if="myConfig.otherConfig.showIdentity">
              个人会员
            </template>
          </p>
          <div
            class="scroll-list"
            :style="{
              justifyContent: true ? 'center' : 'flex-start',
              paddingLeft: true ? 0 : '23px',
            }"
          >
            <div class="scroll-item">
              <el-image class="avatar" :src="defaultAvatar"></el-image>
              <span class="name">倪景明</span>
            </div>
          </div>
        </div>
        <div class="item" v-for="(item, index) in 1" :key="index">
          <div class="sub-title">
            会员<span v-if="config.otherConfig.showCount" class="count"
              >（20人）</span
            >
          </div>
          <p style="font-size: 14px; margin-top: 10px; text-align: center">
            <template v-if="myConfig.otherConfig.showIdentity">
              单位会员
            </template>
          </p>
          <div
            class="scroll-list"
            :style="{
              justifyContent: false ? 'center' : 'flex-start',
              paddingLeft: false ? 0 : '23px',
            }"
          >
            <div class="scroll-item">
              <el-image class="avatar" :src="defaultAvatar"></el-image>
              <span class="name">顾桂花</span>
            </div>
            <div class="scroll-item">
              <el-image class="avatar" :src="defaultAvatar"></el-image>
              <span class="name">钊亭晚</span>
            </div>
            <div class="scroll-item">
              <el-image class="avatar" :src="defaultAvatar"></el-image>
              <span class="name">胡永修</span>
            </div>
            <div class="scroll-item">
              <el-image class="avatar" :src="defaultAvatar"></el-image>
              <span class="name">吕玉兰</span>
            </div>
          </div>
        </div>
        <!-- <div class="load-btn" style="color: #4a8fec">查看更多</div> -->
      </div>
      <div style="width: 100%; height: 20px"></div>
    </template>

    <!-- 模板二 -->
    <div v-else class="content">
      <!-- 是否显示会员总数 -->
      <p v-if="myConfig.otherConfig.showTotal" class="summary">共209名会员</p>
      <div
        v-for="(position, pIndex) in organizations"
        class="single-card"
        :style="{
          backgroundColor:
            myConfig.templateData.contentBgType === 'color'
              ? myConfig.templateData.contentBgColor
              : '',
          backgroundImage:
            myConfig.templateData.contentBgType === 'image'
              ? `url(${myConfig.templateData.contentBgImage})`
              : '',
        }"
      >
        <div class="single-card-head flex-between">
          <h4>{{ position.title }}</h4>
          <!--          <img />-->
          <p v-if="myConfig.otherConfig.showCount && pIndex !== 0">48名会员</p>
        </div>
        <el-divider />
        <div class="single-card-body">
          <p v-if="position.companies" class="summary" style="font-size: 14px">
            <template v-if="myConfig.otherConfig.showIdentity"> 会员 </template>
          </p>
          <div class="grid">
            <div
              v-for="person in position.people.slice(
                0,
                myConfig.otherConfig.maxShowCount
              )"
              class="person flex-align-center"
            >
              <img class="avatar" :src="defaultAvatar" />
              <span class="name">{{ person.name }}</span>
            </div>
          </div>
          <div
            v-if="position.people.length >= 6 && false"
            class="dots flex-center"
          >
            <span class="dot active"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
          <p
            v-if="position.companies"
            class="summary"
            style="font-size: 14px; margin-top: 10px"
          >
            <template v-if="myConfig.otherConfig.showIdentity">
              会员单位
            </template>
          </p>
          <div class="grid2">
            <div
              v-for="person in position.companies"
              class="person flex-col flex-align-center"
            >
              <img class="avatar" :src="defaultIcon" />
              <span class="name">{{ person.name }}</span>
            </div>
          </div>
          <div
            v-if="position.companies && position.companies.length >= 2 && false"
            class="dots flex-center"
          >
            <span class="dot active"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部说明 -->
    <!--    <div class="watermark">底部版权说明</div>-->

    <!--    <div class="fixed-bar" v-if="config.otherConfig.showPhoto ||config.shareConfig.showShare">-->
    <!--      <div class="fixed-item" v-if="config.otherConfig.showPhoto">-->
    <!--        <img class="icon" src="../../assets/images/st-structure/group.png"/>-->
    <!--        <span class="text">架构图示</span>-->
    <!--      </div>-->
    <!--      <div v-if="config.otherConfig.showPhoto && config.shareConfig.showShare && config.shareConfig.template === 'template1'" class="divider"></div>-->
    <!--      <div class="fixed-item" v-if="config.shareConfig.showShare && config.shareConfig.template === 'template1'">-->
    <!--        <img class="icon" src="../../assets/images/st-structure/share.png"/>-->
    <!--        <span class="text">分享</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="static-bar"></div>-->

    <div class="fixed-btn-group">
      <!-- 点赞 -->
      <!--      <div v-if="config.otherConfig.showLike" class="clap">-->
      <!--        <img class="icon" src="../../assets/images/st-structure/clap.png"/>-->
      <!--        <span class="num">123</span>-->
      <!--      </div>-->
      <div v-if="config.otherConfig.showPhoto" class="clap">
        <img class="icon" src="../../assets/images/st-structure/share2.png" />
        <span class="text">架构图示</span>
      </div>
      <div v-if="config.shareConfig.showShare" class="clap">
        <img class="icon" src="../../assets/images/st-structure/share2.png" />
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'
import StSearch from '@/modules/app-design/components/Preview/StSearch'
export default {
  name: 'StStructure',
  components: {
    StPageSet,
    StSearch,
  },
  props: {
    config: Object,
    jumpConfig: Object,
  },
  data() {
    return {
      organizations: [
        {
          title: '会长',
          people: [
            {
              name: '周家福',
            },
          ],
        },
        {
          title: '副会长',
          people: [
            {
              name: '郑希前',
            },
            {
              name: '钱一唯',
            },
            {
              name: '李豫卓',
            },
            {
              name: '何天添',
            },
            {
              name: '李琳',
            },
            {
              name: '吴洁立',
            },
          ],
          companies: [
            {
              name: '上海耀皮汽车玻璃有限公司',
            },
            {
              name: '广州虎将网络科技有限公司',
            },
          ],
        },
        {
          title: '副会长',
          people: [
            {
              name: '何天添',
            },
            {
              name: '李琳',
            },
            {
              name: '吴洁立',
            },
          ],
        },
      ],
      defaultIcon: require('../../assets/images/default-icon.png'),
      defaultList: require('../../assets/jsons/default-list.json').stStructure,
      ch_sort: require('../../assets/jsons/ch-sort.json'),
      model: {
        normal: {
          titleConfig: {
            name: '组织架构',
            isShow: 1,
          },
          fontWeight: 'normal',
          fontSize: 18,
          fontColor: '#000000',
          statusBarColor: 'black',
          showHeaderJump: 1,
          headerBgColor: '',
        },
        header: {
          bgColor: '#fff',
        },
        tabs: {
          bgColor: '#fff',
          // lineType: "theme", // custom or theme
          // lineColor: "#4b93ff", // 选中时线条颜色
          fontActiveColor: 'rgb(26, 26, 26)',
          fontInactiveColor: 'rgb(179, 179, 179)',
        },
        templateData: {
          styleType: 'template1',
          bgType: 'color',
          bgColor: '#F8F8F8',
          bgImage: '',
          contentBgType: 'color',
          contentBgColor: '#fff',
          contentBgImage: '',
        },
        otherConfig: {
          showFilter: 1,
          showLike: 1,
          showPhoto: 1,
          showTotal: 1,
          showCount: 1,
          maxShowCount: 9,
          showIdentity: 0,
          enableStatistics: 0,
        },
        searchConfig: {
          isShow: 1,
          placeholderConfig: {
            isShow: 1,
            name: '搜索会员名称、会内职务',
          },
          shapeType: 'circle',
          textPosition: 'left',
          searchColor: 'rgba(243,244,245,1)',
          bgColor: '#ffffff',
          height: 32,
          placeholderColor: '#979da6',
        },
        shareConfig: {
          shareTitle: '',
          image: '',
          showShare: 1,
          showAppMessageShare: 1,
          showMomentShare: 1,
          showPosterShare: 1,
        },
      },
      tabConfigTemplate2: {
        normal: {
          defaultColor: '#B3B3B3',
          selectedColor: '#1A1A1A',
          underlineWidth: '14px',
          underlineHeight: '3px',
          underlineBorderRadius: '16px',
          underlineBottom: '10px',
        },
        tabs: {
          list: [
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              name: '东莞茂名商会',
            },
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              name: '广州市茂名商会',
            },
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              name: '深圳茂青会',
            },
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              name: '企业走访',
            },
          ],
        },
      },
      currentIndex: 0,
      defaultAvatar: require('../../assets/images/st-structure/default_avatar.png'),
    }
  },
  created() {
    console.log(this.myConfig)
  },
  computed: {
    myConfig() {
      if (this.config.templateData === undefined) {
        //  转移旧数据 normal -> templateData
        const n = this.config.normal
        if (n?.bgType) {
          this.config.templateData = {
            bgType: n.bgType,
            bgColor: n.bgColor,
            bgImage: n.bgImage,
            contentBgType: n.innerBgType,
            contentBgColor: n.innerBgColor,
            contentBgImage: n.innerBgImage,
          }
        }
      }
      return this.$mergeModel(this.model, this.config)
    },
    myTabs() {
      const tabConfig = this.tabConfig
      return tabConfig.tabs.list.length ? tabConfig.tabs.list : defaultList
    },
    tabConfig() {
      const tabConfig = {
        normal: {
          defaultColor: '#B3B3B3',
          selectedColor: '#1A1A1A',
          underlineWidth: '14px',
          underlineHeight: '3px',
          underlineBorderRadius: '16px',
          underlineBottom: 0,
        },
        tabs: {
          list: [
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              name: '社团云组织',
            },
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              name: '行业小组',
            },
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              name: '基金会',
            },
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              name: '秘书处',
            },
          ],
        },
      }
      return this.myConfig.templateData.styleType === 'template1'
        ? tabConfig
        : this.tabConfigTemplate2
    },
  },
}
</script>

<style lang="scss" scoped>
.st-structure {
  min-height: inherit;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  /* 侧边悬浮按钮 */
  .fixed-btn-group {
    position: absolute;
    right: 16px;
    bottom: 92px;
    display: flex;
    flex-direction: column;

    .clap {
      position: relative;

      .icon {
        width: 62px;
        height: 66px;
      }

      .text {
        font-size: 12px;
        text-align: center;
        background-color: white;
        position: absolute;
        top: 14px;
        left: 16px;
        width: 32px;
        letter-spacing: 2px;
      }

      .num {
        font-size: 10px;
        font-weight: 500;
        color: #de1111;
        line-height: 14px;
        position: absolute;
        right: 4px;
        top: 6px;
      }
    }
  }

  /* 水印 */
  /*.watermark {*/
  /*  font-size: 14px;*/
  /*  color: #ffffff;*/
  /*  line-height: 24px;*/
  /*  text-align: center;*/
  /*  margin: 25px 0 10px;*/
  /*}*/

  /*
  .fixed-bar,
  .static-bar {
    height: 52px;
  }

  .fixed-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0px -2px 6px 0px rgba(6, 0, 112, 0.23);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .divider {
      width: 1px;
      height: 21px;
      border: 1px solid #e5e5e5;
    }

    .fixed-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .text {
        font-size: 16px;
        color: #666666;
        line-height: 22px;
      }
    }
  }*/

  /*.load-btn {*/
  /*  height: 22px;*/
  /*  font-size: 16px;*/
  /*  font-weight: 500;*/
  /*  color: #4a8fec;*/
  /*  line-height: 22px;*/
  /*  text-align: center;*/
  /*  margin-top: 26px;*/
  /*}*/

  .list {
    width: 318px;
    margin: 17px auto 0;
    border-radius: 24px;
    padding: 21px 0 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .item {
      width: 100%;
    }

    .item + .item {
      margin-top: 44px;
    }

    .scroll-list {
      display: flex;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      margin-top: 20px;

      .scroll-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 13px;

        .avatar {
          width: 58px;
          height: 58px;
          border-radius: 50%;
          background-color: #999;
        }

        .name {
          font-size: 16px;
          color: #000000;
          line-height: 16px;
          margin-top: 10px;
        }
      }
    }
  }

  .title,
  .total {
    display: block;
    max-width: 67%;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
  }

  .title {
    margin-top: 21px;
  }

  .sub-title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    line-height: 16px;
    text-align: center;
    position: relative;

    .count {
      font-size: 14px;
      font-weight: normal;
      position: absolute;
    }
  }

  ::v-deep .st-search {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  /* 更多 */
  .filter {
    height: 44px;
    padding: 16px 13px 12px 14px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;

    .icon {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    .text {
      font-size: 14px;
      color: rgb(151, 157, 166);
      line-height: 20px;
    }
  }

  .nav-list {
    display: flex;
    overflow: hidden;
    position: relative;
  }

  .nav-item {
    font-size: 14px;

    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 0 13px 13px;*/
    padding: 0 13px;
    height: 44px;
    position: relative;

    &.active {
      font-weight: bold;
      color: var(--active-color);
    }
  }
}

.template1 {
  .nav-item {
    color: #333C50;

    /* 激活的样式 */
    &.active {
      .nav-active {
        position: absolute;
        height: 3px;
        width: calc((100% - 13px * 2) / 2 + 13px);
        bottom: 0;
        left: 50%;
        /*margin-left: calc((100% - 10px) / -2);*/
        transform: translateX(-50%);
      }
    }
  }

  .filter {
    padding-top: 12px;
  }
}

.template2 {
  .nav-item {
    color: var(--inactive-color);
    height: 50px;
    line-height: 50px;

    &.active:after {
      content: '';
      width: var(--line-width);
      height: var(--line-height);
      border-radius: var(--line-radius);
      // background: var(--line-bg);
      background: #4b93ff;
      position: absolute;
      bottom: var(--line-bottom);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .filter {
    height: 50px;
    padding-top: 12px;
  }

  .summary {
    color: rgba(179, 179, 179, 1);
    font-size: 12px;
    padding: 8px 16px 0;
    margin-top: 0;
  }

  .single-card {
    width: 343px;
    margin: 8px auto 0;
    border-radius: 8px;

    & + .single-card {
      margin-top: 12px;
    }

    .single-card-head {
      p {
        color: #b3b3b3;
        font-size: 14px;
        margin-top: 16px;
        margin-right: 12px;
      }
    }

    .summary {
      padding: 0 0 12px;
    }

    h4 {
      color: rgba(26, 26, 26, 1);
      font-size: 14px;
      font-weight: bold;
      margin-top: 16px;

      &:before {
        content: '';
        // background-color: var(--main-color);
        background-color: #4b93ff;
        width: 4px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
    }

    .el-divider {
      width: 319px;
      margin: 12px auto 11px;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .single-card-body {
      padding: 0 12px 16px;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 12px;
    }

    .grid2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 12px;

      .person {
        background-color: #fafafa;
        border-radius: 8px;
        padding: 8px 12px;
      }

      .name {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'PingFang SC';
        color: #1a1a1a;
      }
    }
  }

  .dots {
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin: 12px 2px;
      opacity: 0.10000000149011612;
      // background-color: var(--main-color);
      background-color: #4b93ff;

      &.active {
        opacity: 1;
      }
    }
  }

  .content {
    max-height: 626px;
    overflow: hidden;
  }
}
</style>
