<template>
  <div class="feedback">
    <div class="header">
      <template v-if="platform !== 'h5'">
        <img class="start-top-img" :src="statusBar[myConfig.normal.statusBarColor]">
        <div class="start-top" style="text-align: end">
          <img v-if="myConfig.normal.showHeaderJump" class="header-jump"
            :class="[headerJump.showConfig.showType !== 'template1'?'template-back-icon':'default-back-icon']"
            :src="backIcon[myConfig.normal.statusBarColor][headerJump.showConfig.showType]" />
          <img class="start-top-handler" :src="handleIcon[myConfig.normal.statusBarColor]">
        </div>
      </template>
      <st-page-set v-else :config="myConfig"></st-page-set>
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div class="type-item" v-if="myConfig.normal.titleConfig.isShow" :style="{
          color: myConfig.normal.fontColor,
          fontWeight: myConfig.normal.fontWeight
        }">{{myConfig.normal.titleConfig.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
export default {
  name: "StMessageFeedback",
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: "商城主页",
            isShow: 1,
          },
          fontWeight: "normal",
          fontColor: "#000000",
          bgColor: "#4a8fec",
          statusBarColor: "black",
          showHeaderJump: 1,
        },
        buttonConfig: {
          btnText: "提交",
          interactiveType: "normal",
        },
        shareConfig: {
          shareTitle: "",
          image: "",
        },
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  // background: #ffffff;
  .type-select {
    position: absolute;
    top: 23px;
    width: 100%;
    padding: 0 18px;
    display: flex;
    justify-content: center;

    &.simple {
      position: static;
      display: none;
    }

    .type-item + .type-item {
      margin-left: 18px;
    }
    .type-item {
      display: flex;
      align-items: center;
      height: 34px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      line-height: 34px;
      .active {
        border-bottom: 2px #fff solid;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        line-height: 34px;
      }
      .select-icon {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
  }
  .header-search {
    margin: 16px auto 26px;
    width: 335px;
    height: 32px;
    background: #f8fbff;
    border-radius: 18px;
    opacity: 0.89;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    .search-icon {
      color: #94989f;
      font-size: 14px;
    }
    .search-tip {
      font-size: 14px;
      color: #94989f;
      line-height: 14px;
      margin-left: 4px;
    }
  }
}

.feedback {
  width: 375px;
  // background-color: #fff;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
  .header-nav {
    background-color: white;
  }

  .start-top-img {
    width: 375px;
    height: 20px;
  }
  .start-top {
    padding-right: 7px;
    .header-jump {
      position: absolute;

      &.default-back-icon {
        top: 32px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      &.template-back-icon {
        top: 25px;
        left: 5px;
        width: 78px;
        height: 32px;
      }
    }
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }
}
</style>
