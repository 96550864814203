<!--
 * @Author: dongjia
 * @Date: 2022-03-02 16:13:48
 * @LastEditTime: 2022-03-02 17:07:39
 * @LastEditors: aleaner
 * @Description: 社区导航话题中心
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\st-community-nav\TopicCenter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="topic-center">
    <StListTitle :config="listTitleConfig" />
    <div class="scroll-list">
      <div class="scroll-item" v-for="(list,index) in defaultList" :key="index">
        <div class="topic-title">
          <img class="title-icon" :src="titleIcons[index]" />
          {{list.name}}
        </div>
        <div class="line"></div>
        <div class="topic-list">
          <div class="list-item" v-for="(item,i) in list.topics" :key="i">
            <div class="item-top">
              <img class="topic-icon" src="../../../assets/images/st-community-nav/circle-topic-icon.png" />
              <div class="topic-name">{{item.name}}</div>
              <span class="el-icon-arrow-right"
                style="font-size: 12px;color: #B3B3B3;flex-shrink: 0;margin-left: auto;"></span>
            </div>
            <div class="item-bottom">{{item.user_comments_count}} 人正在讨论</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const defaultList =
  require("../../../assets/jsons/default-list.json").topicCenterList;
import StListTitle from "../Common/StListTitle";
export default {
  components: { StListTitle },
  data() {
    return {
      defaultList,
      titleIcons: [
        require("../../../assets/images/st-community-nav/today-hot-topic-icon.png"),
        require("../../../assets/images/st-community-nav/new-topic-icon.png"),
      ],
      listTitleConfig: {
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "话题中心",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template2",
          title: "查看更多",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.topic-center {
  .scroll-list {
    margin-top: 12px;
    display: flex;
    flex-wrap: nowrap;
    .scroll-item + .scroll-item {
      margin-left: 12px;
    }
    .scroll-item {
      flex-shrink: 0;
      width: 249px;
      padding: 12px;
      background-color: #ffffff;
      border-radius: 6px;
      .topic-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #1a1a1a;
        line-height: 1;
        .title-icon {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
      .line {
        margin-top: 12px;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .topic-list {
        margin-top: 12px;
        .list-item + .list-item {
          margin-top: 12px;
        }
        .list-item {
          .item-top {
            width: 100%;
            display: flex;
            align-items: center;
            .topic-icon {
              width: 20px;
              height: 20px;
              flex-shrink: 0;
            }
            .topic-name {
              flex: 1;
              margin-left: 8px;
              margin-right: 20px;
              font-size: 14px;
              color: #1a1a1a;
              line-height: 1;
              @include nowrap();
            }
          }
          .item-bottom {
            margin-left: 28px;
            margin-top: 4px;
            font-size: 12px;
            color: #b3b3b3;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
</style>