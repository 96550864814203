<!--
 * @Author: dongjia
 * @Date: 2021-08-03 15:58:04
 * @LastEditTime: 2022-01-21 10:47:17
 * @LastEditors: aleaner
 * @Description: 基础组件-地图组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StMap.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="st-map"
    :style="{
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage:
        myConfig.normal.bgType === 'image'
          ? `url(${myConfig.normal.bgImg})`
          : '',
      borderRadius: myConfig.normal.borderRadius + 'px',
    }"
  >
    <StListTitle :config="{ listTitle: myConfig.titleConfig }" />
    <!-- 给地图加点击事件getLocationPoint，点击地图获取位置相关的信息，经纬度啥的 -->
    <!-- scroll-wheel-zoom：是否可以用鼠标滚轮控制地图缩放，zoom是视图比例 -->
    <baidu-map
      class="bmView"
      :center="{
        lat: myConfig.normal.address.lat || 39.910924,
        lng: myConfig.normal.address.lng || 116.413387,
      }"
      :zoom="zoom"
      scroll-wheel-zoom
      :map-click="false"
      @ready="loadMap"
    >
      <bm-local-search
        :keyword="myConfig.normal.address.city + myConfig.normal.address.detail"
        :auto-viewport="true"
        style="display: none"
        @searchcomplete="getLocation"
      >
      </bm-local-search>
      <bm-view
        :style="{
          width: '100%',
          height:
            myConfig.normal.mapHeightType === 'normal'
              ? '156px'
              : myConfig.normal.height + 'px',
          borderRadius: '4px',
        }"
      ></bm-view>
      <bm-info-window
        :position="{
          lat: myConfig.normal.address.lat || 39.910924,
          lng: myConfig.normal.address.lng || 116.413387,
        }"
        :show="
          myConfig.normal.address.city !== '' &&
          myConfig.normal.address.detail !== ''
        "
        :width="0"
        :height="0"
        :maxWidth="220"
        :closeOnClick="false"
        autoPan
      >
        <p class="address-sign">
          {{
            myConfig.normal.addressSign ||
            myConfig.normal.address.city + myConfig.normal.address.detail
          }}
        </p>
      </bm-info-window>
    </baidu-map>
    <div v-if="myConfig.normal.addressDetail" class="address-detail">
      <div class="detail-item">
        <div class="item-left">
          <img
            class="left-icon"
            src="../../assets/images/st-map/position-icon.png"
          />
          <p class="item-title address-name">
            {{
              myConfig.normal.address.city + myConfig.normal.address.detail ||
              '地址名称'
            }}
          </p>
        </div>
        <div class="item-right">
          <p class="right-text" v-if="myConfig.normal.userPosition">
            距离您 700m
          </p>
          <img
            class="right-icon"
            src="../../assets/images/st-map/handle-right.png"
          />
        </div>
      </div>
      <div class="detail-item">
        <div class="item-left">
          <img
            class="left-icon"
            src="../../assets/images/st-map/phone-icon.png"
          />
          <p class="item-title">
            {{ myConfig.normal.userPhone || '手机号码' }}
          </p>
        </div>
        <div class="item-right">
          <img
            class="right-icon"
            src="../../assets/images/st-map/handle-right.png"
          />
        </div>
      </div>
      <div class="detail-item">
        <div class="item-left">
          <img
            class="left-icon"
            src="../../assets/images/st-map/clock-icon.png"
          />
          <p class="item-title">
            {{ myConfig.normal.businessTime || '开始时间~结束时间' }}
          </p>
        </div>
      </div>
      <div class="detail-item">
        <div class="item-left">
          <img
            class="left-icon"
            src="../../assets/images/st-map/desc-icon.png"
          />
          <p class="item-title">
            {{ myConfig.normal.addressDesc || '地址简介' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StListTitle from '@/modules/app-design/components/Preview/Common/StListTitle'

export default {
  name: 'StMap',
  components: { StListTitle },
  props: {
    config: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  
  data() {
    return {
      model: {
        normal: {
          marginTop: 0,
          rowMargin: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImg: '',
          mapHeightType: 'normal',
          height: 156,
          borderRadius: 0,
          address: {
            city: '',
            detail: '',
            lat: 0,
            lng: 0,
          },
          addressSign: '',
          addressDetail: 0,
          userPosition: 1,
          userPhone: '',
          businessTime: '',
          addressDesc: '',
        },
        titleConfig: {
          isShow: 1,
          showType: 'template1',
          title: '地图模块',
          fontWeight: 'bold',
          fontSize: 18,
          fontColor: '#000000',
        },
      },
      zoom: 12.8, //地图缩放比例
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
    ...mapState('appDesign', ['addressOptions']),
  },
  watch: {
    'myConfig.normal.address': {
      deep: true,
      handler(val) {
        // if (val.detail) {
        //   this.myConfig.normal.addressSign = val.detail;
        // }
      },
    },
  },
  methods: {
    // 加载地图信息
    loadMap({ BMap, map }) {
      // this.location.lng = this.form.lng || 116.413387;
      // this.location.lat = this.form.lat || 39.910924;
    },
    getLocation(e) {
      this.$nextTick(() => {
        if (e) {
          let searchResult
          for (let key in e) {
            if (
              Array.isArray(e[key]) &&
              e[key].length &&
              e[key][0].point !== undefined
            ) {
              searchResult = e[key]
            }
          }
          this.$store.commit('appDesign/setAddressOptions', searchResult || [])
          // this.positionDetail = e.Hr[0].point;
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.st-map {
  padding: 0 16px 12px;
  min-height: 100px;
  background-size: cover;
  background-position: center;
  .list-title {
    padding-top: 12px;
    line-height: 1;
  }

  .bmView {
    padding-top: 12px;

    .address-sign {
      font-size: 12px;
      color: #3a3a3a;
      line-height: 18px;
    }
  }

  .address-detail {
    .detail-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      .item-left {
        display: flex;

        .left-icon {
          width: 20px;
          height: 20px;
        }

        .item-title {
          margin-left: 10px;
          font-size: 14px;
          color: #000000;
          line-height: 20px;
          word-break: break-all;

          &.address-name {
            width: 200px;
            @include nowrap;
          }
        }
      }

      .item-right {
        display: flex;
        align-items: center;

        .right-text {
          font-size: 12px;
          color: #999999;
          line-height: 14px;
        }

        .right-icon {
          width: 10px;
          height: 10px;
          margin-left: 6px;
        }
      }
    }
  }
}
</style>
