<template>
  <div
    :style="{
      marginTop: myConfig.normal.marginTop + 'px !important',
      marginLeft: `${myConfig.normal.rowMargin}px`,
      marginRight: `${myConfig.normal.rowMargin}px`,
      backgroundColor: myConfig.normal.bgColor,
      borderRadius: `${myConfig.normal.topRadius}px ${myConfig.normal.topRadius}px 0 0`,
    }"
    class="custom-nav"
  >
    <div
      :style="{
        paddingTop: myConfig.normal.navPaddingTop + 'px',
        marginLeft: `${myConfig.normal.navRowMargin}px`,
        marginRight: `${myConfig.normal.navRowMargin}px`,
      }"
      class="nav-list"
    >
      <div
        :key="index"
        @click.stop.prevent="currentIndex = index"
        class="nav-item"
        v-for="(item, index) in myConfig.tabs.list.length
          ? tabList
          : defaultList"
      >
        <el-image
          :src="item.leftIcon"
          class="nav-image"
          fit="cover"
          v-if="item.leftIcon"
        ></el-image>
        <div
          :class="['nav-name', index === currentIndex ? 'active' : '']"
          :style="{
            color:
              index === currentIndex
                ? myConfig.normal.selectedColor
                : myConfig.normal.defaultColor,
          }"
        >
          {{ item.name ? item.name : `导航${ch_sort[index]}` }}
          <div class="nav-active" v-if="index === currentIndex"></div>
        </div>
        <el-image
          :src="item.rightIcon"
          class="nav-image"
          fit="cover"
          v-if="item.rightIcon"
        ></el-image>
      </div>
    </div>
    <div
      :style="{
        backgroundColor: myConfig.normal.contentBgColor,
      }"
      class="tip-area"
      v-if="!tabList.length"
    >
      <div>
        <p>自定义导航内容区域</p>
        <p>在右侧设置链接页面</p>
      </div>
    </div>
    <template v-for="(item, index) in tabList">
      <template v-if="item.jump_link">
        <template v-if="item.jump_link.jump_config">
          <page-view
            :id="item.jump_link.jump_config.view.id"
            :init="currentIndex === index && updated"
            :jump_link="item.jump_link"
            :key="item.name"
            :system_page="item.jump_link.jump_config.view.system_page"
            v-show="currentIndex === index"
            from="st-custom-nav"
          />
        </template>
        <template v-else>
          <div
            :style="{
              backgroundColor: myConfig.normal.contentBgColor,
            }"
            class="tip-area"
          >
            <div>
              <p>自定义导航内容区域</p>
              <p>在右侧设置链接页面</p>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div
          :style="{
            backgroundColor: myConfig.normal.contentBgColor,
          }"
          class="tip-area"
        >
          <div>
            <p>自定义导航内容区域</p>
            <p>在右侧设置链接页面</p>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import PageView from '../Page/PageView'
export default {
  name: 'stCustomNav',
  components: { PageView },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultList: require('../../assets/jsons/default-list.json').StCustomNav,
      ch_sort: require('../../assets/jsons/ch-sort.json'),
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          navType: '',
          interactiveType: '',
          defaultColor: '',
          selectedColor: '',
          bgColor: '',
          contentBgColor: '',
          rowMargin: 0,
          topRadius: 0,
          navRowMargin: 20,
          navPaddingTop: 0,
        },
        tabs: {
          list: [
            {
              leftIcon: '',
              rightIcon: '',
              iconInterval: 0,
              is_show: 1,
              name: '',
              jump_link: require('@/modules/app-design/assets/jsons/jump-link.json'),
            },
          ],
        },
      },
      currentIndex: 0,

      updated: true,
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
    tabList() {
      return this.myConfig.tabs.list.filter((el) => el.is_show === 1)
    },
  },
  watch: {
    tabList: {
      handler: function (newValue, oldValue) {
        if (this._timer) {
          clearTimeout(this._timer)
          this._timer = null;
        }
        this.updated = false
        this._timer = setTimeout(() => {
          this.updated = true
        }, 500)
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-nav {
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .nav-list {
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-around;
    overflow: scroll hidden;
  }

  .nav-item {
    margin-left: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 12px;

    .nav-name {
      font-size: 14px;
      line-height: 42px;
      position: relative;
      &.active {
        font-size: 18px;
        font-weight: bold;
      }
      .nav-active {
        position: absolute;
        height: 3px;
        /*width: calc(100% - 12px);*/
        width: 32px;
        bottom: 0;
        left: 50%;
        /*margin-left: calc((100% - 10px) / -2);*/
        margin-left: -16px;
        background-color: var(--main-color);
      }
    }
  }

  .tip-area {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
