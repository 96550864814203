// import store from "@/base/store";

import store from '@/base/store'
import { bannerCategoryAll } from '@/modules/app-design/api/components'
// const baseUrl = window.serverConfig.VUE_APP_BASEURL

export default {
  getRules(getVm, value, platform, others) {
    // getVm: 在 update 中调用

    return {
      normal: {
        isShow: 1,
        rules: [
          {
            "type": "StNumberSlider",
            "field": "rowMargin",
            "native": true,
            // "display": true,
            "props": {
              "label": "两侧边距",
              "min": 0,
              "max": 30
            }
          },
          {
            "type": "StNumberSlider",
            "field": "paddingBottom",
            "native": true,
            "display": true,
            "props": {
              "label": "底部间距",
              "min": 0,
              "max": 40
            }
          }
        ]
      },
      pageJump: {
        title: '前往设置图片广告内容',
        routeName: 'AdList',
        isShow: 0,
      },
      templateData: {
        title: '模板设置',
        rules: [
          {
            type: 'StTemplateRadio',
            field: 'template',
            native: true,
            props: {
              title: '选择模板',
              options: [
                {
                  name: '模板1',
                  value: 'template1',
                },
                {
                  name: '模板2',
                  value: 'template2',
                },
                {
                  name: '模板3',
                  value: 'template3',
                },
                {
                  name: '模板4',
                  value: 'template4',
                },
                {
                  name: '模板5',
                  value: 'template5',
                },
                {
                  name: '模板6',
                  value: 'template6',
                },
                {
                  name: '模板7',
                  value: 'template7',
                },
              ],
            },
            update: (val, rule, fApi, init = false) => {
              const _this = getVm()

              _this.rulesArr.normal.rules.find(r => r.field === 'paddingBottom').display = val === 'template1'

              // TODO：看常规设置有必要动态隐藏吗？？
              // getVm().rulesArr.normal.isShow = getVm().rulesArr.templateData.rules[0].value === 'template1' ? 1 : 0

              // TODO：两侧边距动态隐藏
              // const normalRules = getVm().rulesArr.normal.rules
              // for (let i = 0; i < normalRules.length; i++) {
              //   if (normalRules[i].field === 'rowMargin') {
              //     normalRules[i].display = val === 'template1'
              //   }
              // }

              // 模板6去除掉视频类型数据
              if (val === 'template6') {
                _this.rulesArr.dataConfig.rules[4].props.pageData.banner_types =
                  [0, 2]

                _this.rulesArr.dataConfig.rules[3].value = [0, 2]
                _this.rulesArr.dataConfig.rules[3].props.options[2].disabled = true
                if (!init) {
                  _this.rulesArr.dataConfig.rules[4].value = []
                }
              } else {
                _this.rulesArr.dataConfig.rules[4].props.pageData.banner_types =
                  [0, 2, 3]
                _this.rulesArr.dataConfig.rules[3].value = [0, 2, 3]
                _this.rulesArr.dataConfig.rules[3].props.options[2].disabled = false
              }
              _this.rulesArr.contentConfig.isShow =
                val === 'template1' ||
                val === 'template5' ||
                val === 'template6'
                  ? 1
                  : 0
              // _this.rulesArr.upContentConfig.isShow = val == 'template1' ? 1 : 0;

              _this.rulesArr.pageJump.isShow = val === 'template1' ? 0 : 1

              _this.rulesArr.searchConfigTemplate1.isShow =
                val === 'template1' ||
                val === 'template5' ||
                val === 'template6'
                  ? 1
                  : 0
              if (!init) {
                /**
                 * 初始化时不要触发以下代码
                 */
                if (['template5', 'template6'].includes(val)) {
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'searchColor'
                  ).value = 'rgba(255, 255, 255, 1)'
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'placeholderColor'
                  ).value = '#b3b3b3'
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'placeholder'
                  ).value = ''
                } else {
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'searchColor'
                  ).value = 'rgba(243,244,245,1)'
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'placeholderColor'
                  ).value = '#979da6'
                }
              }

              _this.rulesArr.bannerSearchConfig.isShow = [
                'template2',
                'template4',
              ].includes(val)
                ? 1
                : 0
              _this.rulesArr.bannerSearchConfig.rules.find(
                (el) => el.field === 'template2_searchType'
              ).display = val === 'template2'
              _this.rulesArr.bannerSearchConfig.rules.find(
                (el) => el.field === 'template4_searchType'
              ).display = val === 'template4'

              let dataTemplateList1 = [
                'template2',
                'template3',
                'template4',
                'template6',
              ]
              let dataTemplateList2 = ['template2', 'template3', 'template4']

              _this.rulesArr.dataConfig.isShow = dataTemplateList1.includes(val)
                ? 1
                : 0
              _this.rulesArr.loadingConfig.isShow = dataTemplateList2.includes(
                val
              )
                ? 1
                : 0

              /* 只有模板一有政务相关配置 */
              _this.rulesArr.gov.isShow = val === 'template1' ? 1 : 0

              _this.rulesArr.contentConfig.rules.forEach((el) => {
                let bol = true
                if (
                  ['template5', 'template6'].includes(el.field) ||
                  ['template5', 'template6'].includes(val)
                ) {
                  if (val !== el.field) bol = false
                }
                if (el.field === 'showSearch') {
                  if (val !== 'template1') bol = false
                }
                el.display = bol
              })

              _this.rulesArr.contentConfigTemplate7.isShow = val === 'template7' ? 1 : 0
            },
          },
        ],
      },
      dataConfig: {
        title: '数据设置',
        isShow: 1,
        rules: [
          {
            type: 'StRadioButtonGroup',
            field: 'dataType',
            display: true,
            native: true,
            props: {
              title: '数据类型',
              options: [
                {
                  label: '选择数据',
                  value: 'select',
                },
                {
                  label: '过滤设置',
                  value: 'filter',
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'dataAll',
            display: true,
            native: true,
            props: {
              title: '分类过滤',
              options: [
                {
                  label: '全部分类',
                  value: 1,
                },
                {
                  label: '自定义',
                  value: 0,
                },
              ],
            },
            link: ['dataType'],
            update: (val, rule, fApi, init = false) => {
              console.log(val, rule, fApi.init)
              // console.log(222, value()[0])
              rule.display = fApi.form.dataType === 'filter'
              if (!init) {
                if (val === 1) {
                  fApi.form.dataFilter = []
                }
              }
            },
          },
          {
            type: 'StElSelect',
            field: 'dataFilter',
            title: '选择分类',
            display: true,
            props: {
              valueKey: 'id',
              labelKey: 'name',
              multiple: true,
              options: [],
            },
            style: 'float: right;',
            link: ['dataType', 'dataAll'],
            update: (val, rule, fApi, init = false) => {
              if (init) {
                if (fApi.form.dataAll && val.length && val[0])
                  fApi.form.dataAll = 0
              }
              rule.display =
                  fApi.form.dataType === 'filter' && fApi.form.dataAll === 0

              // fApi.rule.forEach((el) => {
              //   if (filterKeys.includes(el.field)) {
              //     el.display = fApi.form.dataType === 'filter'
              //   }
              // })
              bannerCategoryAll({})
                  .then((res) => {
                    // console.log(
                    //     res.data,
                    //     rule.value,
                    //     '123123123123',
                    //     res.data.length !== rule.props.options.length,
                    //     rule.value.length
                    // )
                    // if (res.data.length !== rule.props.options.length) {
                    //   // 清空之前的所有选项
                    //   rule.value = []
                    // }
                    rule.props.options = res.data
                    if (!rule.value.length) {
                      rule.value = res.data.map((item) => item.id)
                    }
                  })
                  .catch((err) => {})
            },
          },
          {
            type: 'StCheckBoxGroup',
            field: 'type',
            native: true,
            display: true,
            props: {
              title: '广告类型',
              options: [
                {
                  name: '链接',
                  value: 0,
                  disabled: false,
                },
                {
                  name: '图文',
                  value: 2,
                  disabled: false,
                },
                {
                  name: '视频',
                  value: 3,
                  disabled: false,
                },
              ],
              min: 1,
            },
            link: ['dataType'],
            update: (val, rule, fApi, init) => {
              rule.display = fApi.form.dataType === 'filter'
              if (fApi.form.dataType === 'filter') {
                if (val) {
                  if (!val.length) {
                    fApi.form.type = init ? [0, 2, 3] : [0]
                  }
                }
              }
            },
          },
          {
            type: 'StDragDataList',
            field: 'dataArr',
            display: true,
            native: true,
            props: {
              formType: 'st-banner',
              itemTitle: '广告名称',
              addButtonText: '添加广告数据',
              pageData: {
                banner_type_all: 0,
                banner_types: [0, 2, 3],
              },
            },
            link: ['dataType'],
            update: (val, rule, fApi) => {
              rule.display = fApi.form.dataType === 'select'
            },
          },
        ],
      },
      loadingConfig: {
        title: '数据加载',
        isShow: 1,
        rules: [
          {
            type: 'input-number',
            field: 'limit',
            title: '数据条数',
            props: {
              min: 1,
              max: 30,
            },
            style: {
              float: 'right',
            },
          },
        ],
      },
      contentConfig: {
        title: '内容设置',
        isShow: 1,
        rules: [
          {
            type: 'object',
            field: 'template5',
            display: false,
            native: true,
            value: {
              bgImg: '',
              linearGradientConfig: {
                enable: 1,
                color: 'rgba(255, 112, 81, 1)',
              },
              icon: '',
              businessNameConfig: {
                name: '',
                isShow: 1,
                fontWeight: 'normal',
                fontSize: 18,
                fontColor: '#000000',
              },
              titleConfig: {
                name: '',
                isShow: 1,
                fontWeight: 'normal',
                fontSize: 18,
                fontColor: '#000000',
              },
              descriptionConfig: {
                name: '',
                isShow: 1,
                fontWeight: 'normal',
                fontSize: 18,
                fontColor: '#000000',
              },
              memberCountConfig: {
                name: '会员',
                isShow: 1,
                fontWeight: 'normal',
                fontSize: 14,
                fontColor: '#ffffff',
              },
            },
            props: {
              rule: [
                {
                  type: 'SingleMediaWall',
                  field: 'bgImg',
                  title: '背景图',
                  props: {
                    width: 60,
                    height: 60,
                    ratio: `750:686`,
                    showInline: true,
                    addText: '',
                  },
                  children: [
                    {
                      type: 'div',
                      slot: 'info',
                      class: 'st-inline-img-info',
                      children: ['建议尺寸: 1125*1029'],
                    },
                  ],
                },
                {
                  type: 'StGradientsSetter',
                  field: 'linearGradientConfig',
                  props: {
                    defaultColor: 'rgba(255, 112, 81, 1)',
                  },
                },
                {
                  type: 'SingleMediaWall',
                  field: 'icon',
                  title: '图标',
                  props: {
                    width: 60,
                    height: 60,
                    ratio: `58:58`,
                    showInline: true,
                    addText: '',
                  },
                  children: [
                    {
                      type: 'div',
                      slot: 'info',
                      class: 'st-inline-img-info',
                      children: ['建议图标尺寸58*58图片格式为PNG'],
                    },
                  ],
                },
                {
                  type: 'StSpecialInput',
                  field: 'businessNameConfig',
                  props: {
                    advanced: true,
                    label: '商会名称',
                  },
                },
                {
                  type: 'StSpecialInput',
                  field: 'titleConfig',
                  props: {
                    advanced: true,
                    label: '标题内容',
                  },
                },
                {
                  type: 'StSpecialInput',
                  field: 'descriptionConfig',
                  props: {
                    advanced: true,
                    label: '描述内容',
                  },
                },
                {
                  type: 'StSpecialInput',
                  field: 'memberCountConfig',
                  props: {
                    advanced: true,
                    inputEnable: true,
                    inputLength: 9,
                    label: '会员数量',
                  },
                },
              ],
            },
          },
          {
            type: 'object',
            field: 'template6',
            display: false,
            native: true,
            value: {
              linearGradientConfig: {
                enable: 1,
                color: 'rgba(255, 112, 81, 1)',
              },
              icon: '',
              businessNameConfig: {
                name: '',
                isShow: 1,
                fontWeight: 'normal',
                fontSize: 18,
                fontColor: '#000000',
              },
              titleConfig: {
                name: '',
                isShow: 1,
                fontWeight: 'normal',
                fontSize: 18,
                fontColor: '#000000',
              },
              descriptionConfig: {
                name: '',
                isShow: 1,
                fontWeight: 'normal',
                fontSize: 18,
                fontColor: '#000000',
              },
              memberCountConfig: {
                name: '人成为了会员',
                isShow: 1,
                fontWeight: 'normal',
                fontSize: 14,
                fontColor: '#fff'
              }
            },
            props: {
              rule: [
                {
                  type: 'StGradientsSetter',
                  field: 'linearGradientConfig',
                  props: {
                    defaultColor: 'rgba(255, 112, 81, 1)',
                  },
                },
                {
                  type: 'SingleMediaWall',
                  field: 'icon',
                  title: '图标',
                  props: {
                    width: 60,
                    height: 60,
                    ratio: `58:58`,
                    showInline: true,
                    addText: '',
                  },
                  children: [
                    {
                      type: 'div',
                      slot: 'info',
                      class: 'st-inline-img-info',
                      children: ['建议图标尺寸58*58图片格式为PNG'],
                    },
                  ],
                },
                {
                  type: 'StSpecialInput',
                  field: 'businessNameConfig',
                  props: {
                    advanced: true,
                    label: '商会名称',
                  },
                },
                {
                  type: 'StSpecialInput',
                  field: 'titleConfig',
                  props: {
                    advanced: true,
                    label: '标题内容',
                  },
                },
                {
                  type: 'StSpecialInput',
                  field: 'descriptionConfig',
                  props: {
                    advanced: true,
                    label: '描述内容',
                  },
                },
                {
                  type: 'StSpecialInput',
                  field: 'memberCountConfig',
                  props: {
                    advanced: true,
                    inputEnable: true,
                    inputLength: 9,
                    label: '会员数量',
                  },
                },
              ],
            },
          },
          {
            type: 'StSpecialInput',
            field: 'titleConfig',
            title: '标题内容',
            display: true,
            update: (val, rule, fApi) => {
              const vm = getVm()

              const filterKey = ['fontWeight', 'fontSize', 'fontColor']
              fApi.rule.forEach((el) => {
                if (filterKey.includes(el.field)) {
                  el.display =
                    val.isShow === 1 &&
                    vm.rulesArr.templateData.rules[0].value !== 'template5' &&
                    vm.rulesArr.templateData.rules[0].value !== 'template6'
                }
              })
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'fontWeight',
            display: true,
            native: true,
            props: {
              title: '文本粗细',
              options: [
                {
                  label: '常规体',
                  value: 'normal',
                },
                {
                  label: '加粗体',
                  value: 'bold',
                },
              ],
            },
          },
          {
            type: 'StNumberSlider',
            field: 'fontSize',
            native: true,
            display: true,
            props: {
              label: '文本字号',
              min: 12,
              max: 24,
            },
          },
          {
            type: 'StColorPicker',
            field: 'fontColor',
            title: '文本颜色',
            display: true,
            props: {
              defaultColor: '#000000',
            },
          },
          {
            type: 'SingleMediaWall',
            field: 'icon',
            title: '图标',
            display: true,
            props: {
              width: 60,
              height: 60,
              ratio: `58:58`,
              showInline: true,
              addText: '',
            },
            children: [
              {
                type: 'div',
                slot: 'info',
                class: 'st-inline-img-info',
                children: ['建议图标尺寸58*58图片格式为PNG'],
              },
            ],
          },
          {
            "type": "StRadioButtonGroup",
            "field": "iconShape",
            "title": "图标比例",
            "display": true,
            "props": {
              "options": [
                { "label": "1:1", "value": "square" },
                { "label": "自由", "value": "auto" }
              ]
            }
          },
          {
            type: 'StRadioButtonGroup',
            field: 'showSearch',
            display: true,
            native: true,
            props: {
              title: '搜索框',
              options: [
                {
                  label: '显示',
                  value: 1,
                },
                {
                  label: '隐藏',
                  value: 0,
                },
              ],
            },
          },
        ],
      },
      contentConfigTemplate7: {
        title: '内容设置',
        isShow: 1,
        rules: [
          {
            type: 'SingleMediaWall',
            field: 'icon',
            title: '图标',
            display: true,
            props: {
              width: 60,
              height: 60,
              ratio: `163:46`,
              showInline: true,
              addText: '',
            },
            children: [
              {
                type: 'div',
                slot: 'info',
                class: 'st-inline-img-info',
                children: ['图标尺寸高度 138，宽度任意'],
              },
            ],
          },
          {
            type: 'SingleMediaWall',
            field: 'cover',
            title: '背景图片',
            props: {
              width: 60,
              height: 60,
              ratio: `1:1`,
              showInline: true,
              addText: '',
              // 使用默认的话（不能删
              // useDefaultAvatar: true,
              // avatarUrl: `${baseUrl}/static/admin/images/login_background.png`
            },
            children: [
              {
                type: 'div',
                slot: 'info',
                class: 'st-inline-img-info',
                children: ['建议尺寸 1125 * 1125'],
              },
            ],
          },
          {
            "type": "StNumberSlider",
            "field": "coverHeight",
            "native": true,
            "display": true,
            "props": {
              "label": "高度1",
              "min": 0,
              "max": 500
            }
          },
          {
            "type": "StColorPicker",
            "field": "color",
            "title": "主色",
            "props": {
              "showAlpha": false,
              "defaultColor": "#840000"
            }
          },
          {
            "type": "StNumberSlider",
            "field": "colorHeight",
            "native": true,
            "display": true,
            "props": {
              "label": "高度2",
              "min": 0,
              "max": 500
            }
          },
        ],
      },
      upContentConfig: {
        title: '页面上滑内容设置',
        isShow: 1,
        rules: [
          {
            "type": "StRadioButtonGroup",
            "field": "showIcon",
            "title": "显示上滑图标",
            "display": true,
            "props": {
              "options": [
                { "label": "显示", "value": 1 },
                { "label": "隐藏", "value": 0 }
              ]
            }
          },
          {
            type: 'SingleMediaWall',
            field: 'icon',
            title: '上滑图标',
            props: {
              width: 60,
              height: 60,
              ratio: `58:58`,
              showInline: true,
              addText: '',
            },
            children: [
              {
                type: 'div',
                slot: 'info',
                class: 'st-inline-img-info',
                children: ['建议图标尺寸58*58图片格式为PNG，不传时会默认读取内容设置的图标'],
              },
            ],
          },
          {
            "type": "StRadioButtonGroup",
            "field": "iconShape",
            "title": "上滑图标比例",
            "display": true,
            "props": {
              "options": [
                { "label": "1:1", "value": "square" },
                { "label": "自由", "value": "auto" }
              ]
            }
          },
          {
            type: 'StTemplateRadio',
            field: 'showType',
            native: true,
            props: {
              title: '上滑显示',
              options: [
                {
                  name: '无',
                  value: 'none',
                },
                {
                  name: '标题内容',
                  value: 'text',
                },
                {
                  name: '搜索框',
                  value: 'search',
                },
              ],
            },
            update: (val, rule, fApi) => {
              const filterKey = [
                'titleConfig',
                'fontWeight',
                'fontSize',
                'fontColor',
              ]
              fApi.rule.forEach((el) => {
                if (filterKey.includes(el.field)) {
                  el.display =
                    val === 'text' && fApi.form.titleConfig.isShow === 1
                }
              })
              const filterSearchKey = ['searchColor', 'placeholderColor']
              fApi.rule.forEach((el) => {
                if (filterSearchKey.includes(el.field)) {
                  el.display = val === 'search'
                }
              })
            },
          },
          {
            type: 'StSpecialInput',
            field: 'titleConfig',
            display: true,
            title: '标题内容',
            update: (val, rule, fApi) => {
              const filterKey = ['fontWeight', 'fontSize', 'fontColor']
              fApi.rule.forEach((el) => {
                if (filterKey.includes(el.field)) {
                  el.display = val.isShow === 1 && fApi.form.showType === 'text'
                }
              })
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'fontWeight',
            display: true,
            native: true,
            props: {
              title: '文本粗细',
              options: [
                {
                  label: '常规体',
                  value: 'normal',
                },
                {
                  label: '加粗体',
                  value: 'bold',
                },
              ],
            },
          },
          {
            type: 'StNumberSlider',
            field: 'fontSize',
            native: true,
            display: true,
            props: {
              label: '文本字号',
              min: 12,
              max: 24,
            },
          },
          {
            type: 'StColorPicker',
            field: 'fontColor',
            title: '文本颜色',
            display: true,
            props: {
              defaultColor: '#000000',
            },
          },
          {
            type: 'StColorPicker',
            field: 'searchColor',
            title: '框体颜色',
            display: false,
            props: {
              defaultColor: 'rgba(243,244,245,1)',
            },
          },
          {
            type: 'StColorPicker',
            field: 'placeholderColor',
            title: '文本颜色',
            display: false,
            props: {
              defaultColor: '#979da6',
            },
          },
        ],
      },
      // 已过时
      searchConfig: {
        title: '搜索框设置',
        isShow: 0,
        rules: [
          {
            type: 'StIsShowTitle',
            field: 'isShow',
            native: true,
            display: true,
            props: {
              title: '搜索框设置',
            },
          },
          {
            type: 'select',
            field: 'searchContentType',
            title: '搜索内容',
            display: true,
            options: [],
            props: {
              multiple: true,
              collapseTags: true,
            },
            update: (val, rule, fApi) => {
              if (rule.options.length === 0) {
                rule.options = store.state.appDesign.searchTypes
              }
              const data = []
              if (!val.length || (val.length === 1 && val[0] === '')) {
                rule.options.forEach((el) => {
                  data.push(el.value)
                })
                fApi.form.searchContentType = data
              } else if (val.length === 1) {
                rule.options.forEach((el) => {
                  if (el.value === val[0]) {
                    el.disabled = true
                  }
                })
              } else if (val.length > 1) {
                rule.options.forEach((el) => {
                  el.disabled = false
                })
                fApi.form.searchContentType.forEach(s => {
                  if (rule.options.find(op => op.value === s)) {
                    data.push(s)
                  }
                })
                fApi.form.searchContentType = data
              }
            },
          },
          {
            type: 'ElInput',
            field: 'placeholder',
            title: '搜索提示',
            display: true,
            props: {
              maxlength: 20,
              'show-word-limit': true,
              placeholder: '请输入搜索提示',
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'searchType',
            native: true,
            display: true,
            props: {
              title: '框体样式',
              options: [
                {
                  label: '圆角',
                  value: 'square',
                },
                {
                  label: '圆形',
                  value: 'circle',
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'interactiveType',
            native: true,
            display: true,
            props: {
              title: '交互方式',
              options: [
                {
                  label: '默认',
                  value: 'default',
                },
                {
                  label: '滚动时至顶部固定',
                  value: 'sticky',
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'textPosition',
            native: true,
            display: true,
            props: {
              title: '文本位置',
              options: [
                {
                  label: '居左',
                  value: 'left',
                },
                {
                  label: '居中',
                  value: 'center',
                },
              ],
            },
          },
          {
            type: 'StColorPicker',
            field: 'searchColor',
            title: '框体颜色',
            display: true,
            props: {
              defaultColor: 'rgba(243,244,245,1)',
            },
          },
          {
            type: 'StColorPicker',
            field: 'placeholderColor',
            title: '文本颜色',
            display: true,
            props: {
              defaultColor: '#979da6',
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'openHot',
            native: true,
            display: true,
            props: {
              title: '热门搜索',
              options: [
                {
                  label: '开启',
                  value: 1,
                },
                {
                  label: '关闭',
                  value: 0,
                },
              ],
            },
          },
        ],
      },
      advertConfig: {
        title: '图片广告设置',
        isShow: 0,
        rules: [
          {
            type: 'StNumberSlider',
            field: 'speed',
            native: true,
            display: false,
            props: {
              label: '切换速度',
              min: 0,
              max: 2000,
            },
          },
          {
            type: 'StTemplateRadio',
            field: 'indicatorType',
            native: true,
            display: true,
            props: {
              title: '指示器',
              options: [
                {
                  name: '模板1',
                  value: 'template1',
                },
                {
                  name: '模板2',
                  value: 'template2',
                },
                {
                  name: '模板3',
                  value: 'template3',
                },
                {
                  name: '模板4',
                  value: 'template4',
                },
              ],
            },
          },
        ],
      },
      searchConfigTemplate1: {
        title: '搜索框设置',
        isShow: 1,
        rules: [
          {
            type: 'select',
            field: 'searchContentType',
            title: '搜索内容',
            display: true,
            options: [],
            props: {
              multiple: true,
              collapseTags: true,
            },
            update:  (val, rule, fApi) => {
              if (rule.options.length === 0) {
                rule.options = store.state.appDesign.searchTypes
              }
              const data = []
              if (!val.length || (val.length === 1 && val[0] === '')) {
                rule.options.forEach((el) => {
                  data.push(el.value)
                })
                fApi.form.searchContentType = data
              } else if (val.length === 1) {
                rule.options.forEach((el) => {
                  if (el.value === val[0]) {
                    el.disabled = true
                  }
                })
              } else if (val.length > 1) {
                rule.options.forEach((el) => {
                  el.disabled = false
                })
                fApi.form.searchContentType.forEach(s => {
                  if (rule.options.find(op => op.value === s)) {
                    data.push(s)
                  }
                })
                fApi.form.searchContentType = data
              }
            },
          },
          {
            type: 'ElInput',
            field: 'placeholder',
            title: '搜索提示',
            display: true,
            props: {
              maxlength: 20,
              'show-word-limit': true,
              placeholder: '请输入搜索提示',
              clearable: true,
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'searchType',
            native: true,
            display: true,
            props: {
              title: '框体样式',
              options: [
                {
                  label: '圆角',
                  value: 'square',
                },
                {
                  label: '圆形',
                  value: 'circle',
                },
              ],
            },
            update: (val, rule, fApi, init) => {
              const _this = getVm()
              if (fApi.form.searchType === 'square') {
                if (!init) {
                  fApi.form.borderRadius = 6 // 当 searchType 为 square 时，默认值：6
                  // _this.rulesArr.searchConfigTemplate1.rules.forEach(rule => {
                  //   if (rule.field === 'borderRadius') {
                  //     // rule.value = 6
                  //   }
                  // })
                }
              } else {
                if (!init) {
                  // fApi.form.height = 32 // 没必要
                }
              }
            }
          },
          {
            type: 'StNumberSlider',
            field: 'borderRadius',
            native: true,
            display: true,
            props: {
              label: '圆角',
              min: 0,
              max: 25
            },
            link: ['searchType'],
            update: (val, rule, fApi, init) => {
              rule.display = fApi.form.searchType === 'square'
            },
          },
          {
            "type": "StNumberSlider",
            "field": "height",
            "native": true,
            "display": true,
            "props": {
              "label": "框体高度",
              "min": 32,
              "max": 50
            }
          },
          {
            type: 'StRadioButtonGroup',
            field: 'interactiveType',
            native: true,
            display: true,
            props: {
              title: '交互方式',
              options: [
                {
                  label: '默认',
                  value: 'default',
                },
                {
                  label: '滚动时至顶部固定',
                  value: 'sticky',
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'textPosition',
            native: true,
            display: true,
            props: {
              title: '文本位置',
              options: [
                {
                  label: '居左',
                  value: 'left',
                },
                {
                  label: '居中',
                  value: 'center',
                },
              ],
            },
          },
          {
            type: 'StColorPicker',
            field: 'searchColor',
            title: '框体颜色',
            display: true,
            props: {
              defaultColor: 'rgba(243,244,245,1)',
            },
          },
          {
            type: 'StColorPicker',
            field: 'placeholderColor',
            title: '文本颜色',
            display: true,
            props: {
              defaultColor: '#979da6',
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'openHot',
            native: true,
            display: true,
            props: {
              title: '热门搜索',
              options: [
                {
                  label: '开启',
                  value: 1,
                },
                {
                  label: '关闭',
                  value: 0,
                },
              ],
            },
          },
        ],
      },
      bannerSearchConfig: {
        title: '搜索框设置',
        isShow: 0,
        rules: [
          {
            type: 'StIsShowTitle',
            field: 'isShow',
            native: true,
            display: true,
            props: {
              title: '搜索框',
            },
          },
          {
            type: 'select',
            field: 'searchContentType',
            title: '搜索内容',
            display: true,
            options: [],
            props: {
              multiple: true,
              collapseTags: true,
            },
            link: ['isShow'],
            update: (val, rule, fApi) => {
              if (rule.options.length === 0) {
                rule.options = store.state.appDesign.searchTypes
              }
              const data = []
              if (!val.length || (val.length === 1 && val[0] === '')) {
                rule.options.forEach((el) => {
                  data.push(el.value)
                })
                fApi.form.searchContentType = data
              } else if (val.length === 1) {
                rule.options.forEach((el) => {
                  if (el.value === val[0]) {
                    el.disabled = true
                  }
                })
              } else if (val.length > 1) {
                rule.options.forEach((el) => {
                  el.disabled = false
                })
                fApi.form.searchContentType.forEach(s => {
                  if (rule.options.find(op => op.value === s)) {
                    data.push(s)
                  }
                })
                fApi.form.searchContentType = data
              }
            },
          },
          {
            type: 'ElInput',
            field: 'placeholder',
            title: '搜索提示',
            display: true,
            props: {
              maxlength: 20,
              'show-word-limit': true,
              placeholder: '请输入搜索提示',
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'template2_searchType',
            native: true,
            display: true,
            props: {
              title: '框体样式',
              options: [
                {
                  label: '圆角',
                  value: 'square',
                },
                {
                  label: '圆形',
                  value: 'circle',
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'template4_searchType',
            native: true,
            display: true,
            props: {
              title: '框体样式',
              options: [
                {
                  label: '圆角',
                  value: 'square',
                },
                {
                  label: '圆形',
                  value: 'circle',
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'interactiveType',
            native: true,
            display: true,
            props: {
              title: '交互方式',
              options: [
                {
                  label: '默认',
                  value: 'default',
                },
                {
                  label: '滚动时至顶部固定',
                  value: 'sticky',
                },
              ],
            },
          },
          {
            type: 'StColorPicker',
            field: 'placeholderColor',
            title: '文本颜色',
            display: true,
            props: {
              defaultColor: 'rgba(255,255,255,1)',
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'openHot',
            native: true,
            display: true,
            props: {
              title: '热门搜索',
              options: [
                {
                  label: '开启',
                  value: 1,
                },
                {
                  label: '关闭',
                  value: 0,
                },
              ],
            },
          },
        ],
      },
      gov: {
        title: '政务功能设置',
        isShow: 1,
        rules: [
          {
            type: 'StRadioButtonGroup',
            field: 'isEnableOthers',
            native: true,
            props: {
              title: '查看其它商会',
              options: [
                {
                  label: '开启',
                  value: 1,
                },
                {
                  label: '关闭',
                  value: 0,
                },
              ],
            },
            update: (val, rule, fApi) => {
              fApi.rule.forEach((el) => {
                if (el.field !== 'isEnableOthers') el.display = val === 1
              })
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'isFilterArea',
            native: true,
            display: false,
            props: {
              title: '按地区筛选',
              options: [
                {
                  label: '显示',
                  value: 1,
                },
                {
                  label: '关闭',
                  value: 0,
                },
              ],
            },
          },
          {
            type: 'select',
            field: 'filterAreaType',
            title: '地区筛选',
            display: false,
            options: [
              {
                label: '筛选到省',
                value: 0,
              },
              {
                label: '筛选到市',
                value: 1,
              },
              {
                label: '筛选到区',
                value: 2,
              },
            ],
            link: ['isFilterArea'],
            update: (val, rule, fApi) => {
              rule.display =
                fApi.form.isFilterArea === 1 && fApi.form.isEnableOthers === 1
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'isFilterStar',
            native: true,
            display: false,
            props: {
              title: '按星级筛选',
              options: [
                {
                  label: '显示',
                  value: 1,
                },
                {
                  label: '关闭',
                  value: 0,
                },
              ],
            },
          },
        ],
      },
    }
  },
}
