<!--
 * @Author: dongjia
 * @Date: 2022-03-01 14:02:20
 * @LastEditTime: 2022-03-03 15:24:27
 * @LastEditors: aleaner
 * @Description: 供求列表组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StCommunityNav.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="custom-nav"
    :style="{
      marginTop: myConfig.normal.marginTop + 'px',
    }"
  >
    <div class="source-content">
      <!-- <div class="source-type-children">
        <div class="children-item active">
          分类一
          <div class="line"></div>
        </div>
        <div class="children-item">分类二</div>
        <div class="children-item">分类三</div>
        <div class="children-item">分类四</div>
        <div class="children-item">分类五</div>
        <div class="children-item">分类六</div>
      </div> -->
      <!-- <div class="division-line"></div> -->
      <div class="list-content">
        <div class="list-item" v-for="(i, index) in 2" :key="index">
          <div class="item-user">
            <div class="user-avatar" style="background: #f3f5f7">
              <el-image
                class="default-icon"
                :src="require('../../assets/images/mini-default-icon@2x.png')"
              >
              </el-image>
            </div>
            <div class="user-msg">
              <p class="msg-top">会员名称</p>
              <p class="msg-bottom">单位名称 | 单位职务</p>
            </div>
            <div class="user-handler">
              <div class="handler-top el-icon-more"></div>
              <div class="handler-bottom">三小时前</div>
            </div>
          </div>
          <div class="item-content">
            我将来要当一名麦田里的守望者。有那么一群孩子在一大块麦田里玩。几千几万的小孩子，附近没有一个大人，我是说——除了我。
          </div>
          <div class="item-image default">
            <el-image
              class="default-icon"
              :src="require('../../assets/images/mini-default-icon@2x.png')"
              fit="cover"
            ></el-image>
          </div>
          <div class="content-type">类型</div>
          <div class="content-bottom">
            <div class="bottom-nav">
              <div class="nav-item">
                <el-image
                  class="nav-icon"
                  :src="
                    require('../../assets/images/st-source/icon_index_seed.png')
                  "
                >
                </el-image>
                <p class="nav-text">2021</p>
              </div>
            </div>
            <div class="bottom-nav">
              <div class="nav-item">
                <el-image
                  class="nav-icon"
                  :src="
                    require('../../assets/images/st-source/icon_index_comment.png')
                  "
                >
                </el-image>
                <p class="nav-text">评论</p>
              </div>
            </div>
            <div class="bottom-nav">
              <div class="nav-item">
                <el-image
                  class="nav-icon"
                  :src="
                    require('../../assets/images/st-source/icon_index_contact.png')
                  "
                >
                </el-image>
                <p class="nav-text">联系TA</p>
              </div>
            </div>
            <div class="bottom-nav">
              <div class="nav-item">
                <el-image
                  class="nav-icon"
                  :src="
                    require('../../assets/images/st-source/icon_index_share.png')
                  "
                >
                </el-image>
                <p class="nav-text">分享</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="publish-btn">
        <el-image class="btn-icon" :src="require('../../assets/images/st-source/publish-icon.png')">
        </el-image>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "StDemandList",
  components: {},
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
        },
        templateData: {
          styleType: "template1",
          navColor: "#FFFFFF",
          bgColor: "#FFFFFF",
        },
        contentConfig: {
          showMyGroup: 1,
          showMyTopic: 1,
          showTopicCenter: 1,
        },
        issueConfig: {
          issuePost: 1,
          issueResource: 1,
        },
      },
      currentIndex: 0,
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.source-content {
  position: relative;
  .source-search {
    margin: 14px auto 12px;
    padding: 12px 10px;
    width: 335px;
    height: 36px;
    background: #eeeff0;
    border-radius: 18px;
    display: flex;
    align-items: center;

    .search-icon {
      font-size: 12px;
      color: #888888;
      line-height: 12px;
      margin-right: 5px;
    }

    .search-tip {
      font-size: 12px;
      color: #888888;
      line-height: 12px;
    }
  }

  .source-type {
    display: flex;
    justify-content: center;
    align-items: center;
    .type-content {
      display: flex;

      .type {
        text-align: center;
        width: 80px;
        height: 44px;
        .content {
          display: inline-block;
          font-size: 18px;
          color: #aaaaaa;
          line-height: 44px;
        }
        .content-active {
          display: inline-block;
          font-size: 18px;
          font-weight: bold;
          color: #191e29;
          line-height: 44px;
          border-bottom: 3px #333c50 solid;
        }
      }
    }
  }

  .source-type-children {
    display: flex;
    padding-left: 24px;
    overflow: hidden;
    .children-item + .children-item {
      margin-left: 38px;
    }
    .children-item {
      flex-shrink: 0;
      font-size: 14px;
      color: #b7bec8;
      line-height: 50px;
      position: relative;
      &.active {
        font-weight: bold;
        color: #191e29;
      }
      .line {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 8px;
        width: 28px;
        height: 3px;
        background-color: var(--main-color);
        border-radius:30px;
      }
    }
  }
  .division-line {
    width: 375px;
    height: 8px;
    background: #f5f6f7;
  }
  .list-content {
    .list-item + .list-item {
      border-top: 8px #f5f6f7 solid;
    }
    .list-item {
      padding: 0 18px;
      .item-user {
        display: flex;
        align-items: center;
        padding: 24px 0 16px;
        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          .default-icon {
            width: 16px;
            height: 16px;
          }
        }
        .user-msg {
          width: 224px;
          margin-right: 18px;
          .msg-top {
            font-size: 15px;
            font-weight: bold;
            color: #666666;
            line-height: 15px;
          }
          .msg-bottom {
            margin-top: 7px;
            font-size: 12px;
            color: #979da6;
            line-height: 12px;
          }
        }
        .user-handler {
          .handler-top {
            width: 100%;
            font-size: 12px;
            color: #b7bec8;
            text-align: end;
          }
          .handler-bottom {
            margin-top: 6px;
            font-size: 10px;
            color: #b7bec8;
            line-height: 10px;
          }
        }
      }

      .item-content {
        font-size: 15px;
        color: #333c50;
        line-height: 24px;
      }
      .item-image {
        margin-top: 18px;
        width: 335px;
        height: 190px;
        background: #cccccc;
        border-radius: 4px;
        &.default {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #dcdfe6;
          .default-icon {
            width: 28px;
            height: 28px;
            z-index: 2;
          }
        }
      }
      .content-type {
        width: 40px;
        margin-top: 15px;
        padding: 6px 8px;
        background: #f5f5f5;
        border-radius: 12px;
        font-size: 12px;
        color: #979da6;
        line-height: 12px;
      }
      .content-bottom {
        display: flex;
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-top: 15px;
        padding-bottom: 15px;
        .bottom-nav {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          .nav-item {
            display: flex;
            align-items: center;
            .nav-icon {
              width: 24px;
              height: 24px;
              margin-right: 4px;
            }
            .nav-text {
              font-size: 12px;
              color: #888888;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .publish-btn {
    position: absolute;
    bottom: 113px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-color);
    // box-shadow: 0px 3px 7px 0px var(--main-color);
    width: 54px;
    height: 54px;
    .btn-icon {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
