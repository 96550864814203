<template>
  <div class="st-shop-category-page" v-loading="loading">
    <!-- 头部 -->
    <div class="header">
      <template v-if="platform !== 'h5'">
        <img
          class="start-top-img"
          :src="statusBar[myConfig.normal.statusBarColor]"
        />
        <div class="start-top" style="text-align: end">
          <img
            v-if="myConfig.normal.showHeaderJump"
            class="header-jump"
            :class="[
              headerJump.showConfig.showType !== 'template1'
                ? 'template-back-icon'
                : 'default-back-icon',
            ]"
            :src="
              backIcon[myConfig.normal.statusBarColor][
                headerJump.showConfig.showType
              ]
            "
          />
          <img
            class="start-top-handler"
            :src="handleIcon[myConfig.normal.statusBarColor]"
          />
        </div>
      </template>
      <st-page-set v-else :config="myConfig"></st-page-set>
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div
          class="type-item"
          v-if="myConfig.normal.titleConfig.isShow"
          :style="{
            color: myConfig.normal.fontColor,
            fontWeight: myConfig.normal.fontWeight,
          }"
        >
          {{ myConfig.normal.titleConfig.name }}
        </div>
      </div>
    </div>
    <!-- 搜索框 -->
    <st-search v-if="!loading" :config="searchConfig"></st-search>
    <!-- 分类模板一 -->
    <CategoryTemplate1
      v-if="config.templateData.styleType === 'template1' && !loading"
      :config="config"
    ></CategoryTemplate1>
    <!-- 分类模板二 -->
    <!-- 分类模板一 -->
    <CategoryTemplate2
      v-else-if="config.templateData.styleType === 'template2' && !loading"
      :config="config"
    ></CategoryTemplate2>
  </div>
</template>

<script>
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'
import CategoryTemplate1 from './components/CategoryTemplate1'
import CategoryTemplate2 from './components/CategoryTemplate2'
import { getPageDetail } from '../../../api/page.js'
import StSearch from '../StSearch.vue'

export default {
  name: 'StShopCategoryPage',
  components: { StPageSet, CategoryTemplate1, CategoryTemplate2, StSearch },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: '分类标题',
            isShow: 1,
          },
          fontWeight: 'normal',
          fontColor: '#000000',
          bgColor: '#4a8fec',
          statusBarColor: 'black',
          showHeaderJump: 1,
        },
        templateData: {
          styleType: 'template1',
        },
      },
      searchConfig: {},
      // 状态栏图片
      statusBar: {
        black: require('../../../assets/images/phone-statusbar1@2x.png'),
        white: require('../../../assets/images/phone-statusbar2@2x.png'),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require('../../../assets/images/wechat-handle1@2x.png'),
        white: require('../../../assets/images/wechat-handle2@2x.png'),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require('../../../assets/images/middle/Back1@2x.png'),
          template2: require('../../../assets/images/middle/homeBack1@2x.png'),
          template3: require('../../../assets/images/middle/moreBack1@2x.png'),
          template4: require('../../../assets/images/middle/moreBack1@2x.png'),
        },
        white: {
          template1: require('../../../assets/images/middle/Back2@2x.png'),
          template2: require('../../../assets/images/middle/homeBack2@2x.png'),
          template3: require('../../../assets/images/middle/moreBack2@2x.png'),
          template4: require('../../../assets/images/middle/moreBack2@2x.png'),
        },
      },
    }
  },
  methods: {
    init() {
      this.loading = true
      // 获取商城主页系统页搜索样式配置  this.searchConfig
      getPageDetail({
        id: 0,
        project_id: this.$route.params.id,
        pageset: this.$route.query.pageset || 'preview',
        system_page:
          this.systemPageName === 'integral_shop_category'
            ? 'integral_shop_index'
            : 'shop_index',
      })
        .then((res) => {
          res.data.config.list.forEach((item) => {
            if (item.component === 'st-search') {
              this.searchConfig = item.props
            }
          })
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
    systemPageName() {
      return this.$store.state.appDesign.pageDetail.system_page
    },
  },
  mounted() {
    console.log(this.$store.state)
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  background: #ffffff;
  .type-select {
    position: absolute;
    top: 23px;
    width: 100%;
    padding: 0 18px;
    display: flex;
    justify-content: center;

    &.simple {
      position: static;
      display: none;
    }

    .type-item + .type-item {
      margin-left: 18px;
    }
    .type-item {
      display: flex;
      align-items: center;
      height: 34px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      line-height: 34px;
      .active {
        border-bottom: 2px #fff solid;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        line-height: 34px;
      }
      .select-icon {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
  }
  .header-search {
    margin: 16px auto 26px;
    width: 335px;
    height: 32px;
    background: #f8fbff;
    border-radius: 18px;
    opacity: 0.89;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    .search-icon {
      color: #94989f;
      font-size: 14px;
    }
    .search-tip {
      font-size: 14px;
      color: #94989f;
      line-height: 14px;
      margin-left: 4px;
    }
  }
}

.st-shop-category-page {
  width: 375px;
  // background-color: #fff;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;

  .start-top-img {
    width: 375px;
    height: 20px;
  }

  .start-top {
    padding-right: 7px;
    .header-jump {
      position: absolute;
      &.default-back-icon {
        top: 32px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      &.template-back-icon {
        top: 25px;
        left: 5px;
        width: 78px;
        height: 32px;
      }
    }
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }

  .search-box {
    padding: 10px 16px 0px;
    display: flex;
    background-color: #fff;

    .left {
      flex: 1;
      .input-bg {
        border-radius: 21px;
        background-color: #f7f8fa;
        display: flex;
        align-items: center;
        padding: 11px 18px;
        i.el-icon-search {
          margin-right: 12px;
          font-size: 12px;
          color: #b3b3b3;
        }
        .text {
          color: #1a1a1a;
          font-size: 12px;
          font-weight: 400;
          font-family: 'PingFang SC';
        }
      }
    }

    .right {
      margin-left: 12px;
      color: rgba(26, 26, 26, 1);
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
  }
}
</style>
