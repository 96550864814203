var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"page-view",style:({
      // marginTop: hasCustomHeader || system_page === 'me_new' ? '-64px' : '',
      // marginTop: hasCustomHeader ? '-64px' : '',

      // transform: `scale(${ratio})`,
      backgroundImage:
        _vm.systemConfig &&
        _vm.systemConfig.props &&
        _vm.systemConfig.props.normal &&
        _vm.systemConfig.props.normal.bgType === 'image' &&
        !['contactUs'].includes(_vm.system_page)
          ? ("url(" + (_vm.systemConfig.props.normal.bgImage) + ")")
          : '',
      backgroundColor:
        (_vm.systemConfig &&
          _vm.systemConfig.props &&
          (_vm.systemConfig.props.normal &&
          _vm.systemConfig.props.normal.bgType === 'color'
            ? _vm.systemConfig.props.normal.bgColor
            : '')) ||
        (['me_new', 'memberIndex', 'contactUs'].includes(_vm.system_page)
          ? '#F4F5F6'
          : '#fff'),
    })},[(_vm.jump_link && _vm.isSinglePage(_vm.jump_link.jump_config))?_c('div',{staticStyle:{"padding":"12px"}},[_c('tinymce',{ref:"editor",attrs:{"inline":true,"readonly":true,"show-article-import":false,"show-statusbar":false,"show-toolbar":false},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}}),(_vm.form.files && _vm.form.files.length)?_c('div',{staticClass:"article-file-list"},[_vm._l((_vm.form.files),function(file,index){return [_c('div',{key:file.url,staticClass:"download-item-box"},[_c('download-item',{attrs:{"name":file.title,"type":file.type,"url":file.url}})],1)]})],2):_vm._e()],1):(_vm.list.length || _vm.system_page)?[(_vm.page.props && _vm.page.props.header)?_c('div',{staticClass:"page-top",class:['drag-item no-hover'],staticStyle:{"position":"absolute","z-index":"-1"},style:({
          height: _vm.page.props.header.height + (_vm.isH5 ? 0 : 64) + 'px',
          top: _vm.isH5 ? '64px' : 0,
        })},[_c('div',{staticClass:"header-fixed",style:({
            height: 'inherit',
            overflow: 'hidden',
          })},[_c('div',{staticClass:"page-bg",style:({
              height: 'inherit',
              backgroundColor:
                _vm.page.props.header.bgType === 'image'
                  ? ''
                  : _vm.page.props.header.bgColor || '',
              borderRadius: _vm.page.props.header.radian > 0 ? '0 0 50% 50%' : '0',
              width:
                _vm.page.props.header.radian > 0
                  ? 200 - _vm.page.props.header.radian * 1 + '%'
                  : '100%',
              marginLeft:
                _vm.page.props.header.radian > 0
                  ? -(200 - _vm.page.props.header.radian * 1) / 2 + '%'
                  : '-50%',
              opacity: 1 - _vm.page.props.header.opacity / 100,
            })},[(_vm.page.props.header.bgType === 'image')?_c('el-image',{style:({
                height: 'fit-content' || 'inherit',
              }),attrs:{"src":_vm.page.props.header.bgImage,"fit":"contain"}}):_vm._e()],1)])]):_vm._e(),(_vm.system_page === 'me_new')?_c('div',{staticClass:"me-bg-content",style:({
          height: (_vm.isH5 ? 135 : 135) + _vm.systemConfig.props.header.height + 'px',
          marginTop: _vm.isH5 ? '64px' : 0,
        })},[_c('div',{staticClass:"me-bg",style:({
            backgroundColor:
              _vm.systemConfig.props.header.bgType === 'color'
                ? _vm.systemConfig.props.header.bgColor
                : '',
            borderRadius:
              _vm.systemConfig.props.header.radian > 0 ? '0 0 50% 50%' : '0',
            width:
              _vm.systemConfig.props.header.radian > 0
                ? 300 - _vm.systemConfig.props.header.radian * 2 + '%'
                : '100%',
            marginLeft:
              _vm.systemConfig.props.header.radian > 0
                ? -(300 - _vm.systemConfig.props.header.radian * 2) / 2 + '%'
                : '-50%',
            opacity: 1 - _vm.systemConfig.props.header.opacity / 100,
          })},[(_vm.systemConfig.props.header.bgType === 'image')?_c('div',{staticClass:"me-bg-image-box",style:({
              backgroundImage: ("url(" + (_vm.systemConfig.props.header.bgImage ||
                _vm.baseUrl + '/cyc/images/wxapps/images/thumb_up.png') + ")"),
            })}):_vm._e(),(
              false &&
              _vm.systemConfig.props.messageConfig.styleType === 'template3'
            )?_c('div',{staticClass:"template3-bg flex",staticStyle:{"position":"absolute","width":"375px"},style:({
              top: !_vm.isH5
                ? ((_vm.systemConfig.props.header.height + 135 - 67) + "px")
                : ((_vm.systemConfig.props.header.height + 135 - 67) + "px"),
            })},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/st-me-page/template3-bg.png")}})]):_vm._e()])]):_vm._e(),(!_vm.hasCustomHeader && _vm.from !== 'st-custom-nav' && _vm.from !== 'st-bottom-nav')?_c(_vm.page.component,{tag:"component",attrs:{"config":_vm.page.props,"headerJump":_vm.setting && _vm.setting.headerJump ? _vm.setting.headerJump.props : {}}}):_vm._e(),(_vm.isSystemPage)?_c(_vm.page.component,{tag:"component",attrs:{"config":_vm.page.props,"headerJump":_vm.setting && _vm.setting.headerJump ? _vm.setting.headerJump.props : {}}}):_vm._e(),_vm._l((_vm.list),function(item,index){return _c(item.component,{key:item.id,tag:"component",staticClass:"component-item",attrs:{"config":item.props,"index":index,"page":_vm.page}})}),(_vm.system_page === 'me_new')?_c('div',{staticClass:"me-page-bottom"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"bottom-watermark"},_vm._l((_vm.bottomWatermarkList),function(item,index){return _c('div',{key:index,staticClass:"mark-item"},[(item.type === 'icon')?_c('el-image',{staticClass:"mark-icon",attrs:{"src":item.icon,"fit":"contain"}}):_vm._e(),(item.type === 'content')?_c('p',{staticClass:"mark-content"},[_vm._v(" "+_vm._s(item.content)+" ")]):_vm._e()],1)}),0)]):_vm._e(),(!_vm.system_page)?_c('div',{staticClass:"flex-center",staticStyle:{"min-height":"100px"}},[_c('st-bottom-watermark',{attrs:{"config":_vm.setting.bottomWatermark.props}})],1):_vm._e()]:(!_vm.loading)?[_c('div',{staticClass:"flex-1 flex-center"},[_c('el-empty')],1)]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-outlog"},[_c('p',[_vm._v("清除缓存")]),_c('img',{staticClass:"handle-icon",attrs:{"src":require("../../assets/images/st-me-page/handle-next.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-outlog"},[_c('p',[_vm._v("退出账号")]),_c('img',{staticClass:"handle-icon",attrs:{"src":require("../../assets/images/st-me-page/handle-next.png")}})])}]

export { render, staticRenderFns }