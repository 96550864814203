<!--
 * @Author: dongjia
 * @Date: 2022-01-12 10:01:50
 * @LastEditTime: 2022-01-20 18:22:37
 * @LastEditors: aleaner
 * @Description: 会刊列表组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Prediv\StOnlineLearning.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="{
      padding: `12px ${myStyleType === 'template3' ? '16' : '0'}px`,
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage: myBgImage,
      '--dataSpacing': `${myConfig.templateData.dataSpacing}px`,
    }"
  >
    <StListTitle
      :style="{
        padding: `0 ${myStyleType !== 'template3' ? '16' : '0'}px 16px`,
      }"
      :config="myConfig"
    />
    <!-- 数据列表 -->
    <div class="data-list" v-loading="loading">
      <!-- 模板一 -->
      <template v-if="myConfig.templateData.styleType === 'template1'">
        <div
          class="article-item"
          v-for="(data, i) in dataList"
          :key="data.id"
          :style="{
            marginTop: i ? myConfig.templateData.dataSpacing + 'px' : 0,
            borderRadius: myConfig.templateData.borderRadius + 'px',
          }"
        >
          <div class="title-content">
            <div class="left-box">
              <div class="left">{{ data.name }}</div>
              <div v-if="data.is_top" class="is_top">
                <img
                  class="icon"
                  src="@/modules/app-design/assets/images/st-employ-carreer-list/is_top.png"
                />
                置顶
              </div>
            </div>
            <div class="right">
              <template v-if="data.min_wages === data.max_wages"
                >{{ data.max_wages }}K</template
              >
              <template v-else>
                {{ data.min_wages }}-{{ data.max_wages }}K
              </template>
            </div>
          </div>

          <div class="tags">
            <div class="tag" v-for="(item, i) in data.tags" :key="i">
              {{ item.name }}
            </div>
            <div class="tag default">{{ data.category_name }}</div>
          </div>

          <div class="footer">
            <div class="info">
              <img :src="data.company.logo" class="icon" mode="aspectFill" />
              <div class="name">{{ data.company.name }}</div>
              <div class="people">{{ data.company.scale_text }}</div>
            </div>
            <div class="area" v-if="data.region.length">
              {{ beautifyJobCity(data.region).replace('·', ' ') }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div
      class="load-more"
      v-if="myConfig && myConfig.loadingConfig.loadingType === 'handle'"
    >
      <div :class="[myConfig.loadingConfig.handleType]">
        {{ myConfig.loadingConfig.handleText }}
      </div>
    </div>
  </div>
</template>
<script>
import { getEmployList } from '../../api/components'
import componentMixin from '@/modules/app-design/mixins/component-mixin'

export default {
  name: 'StEmployCareerList',
  components: {},
  mixins: [componentMixin],
  data() {
    return {
      defaultList: require('../../assets/jsons/default-list.json').careerList,
      loading: false,
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
        },
        templateData: {
          styleType: 'template1',
          dataSpacing: 12,
          dataGap: 12,
          borderRadius: 12,
        },
        loadingConfig: {
          loadingType: 'none',
          limit: 10,
          handleType: 'template1',
          handleText: '加载更多',
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '岗位列表',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          showType: 'template1',
          title: '查看更多',
        },
        dataConfig: {
          region: [''],
          dataAll: 0,
        },
      },
      form: {
        page: 1,
        page_size: 15,
        limit: -1,
        use_ids: 0,
        ids: [],
        keyword: '',
        company_id: '',
        category_id: '',
        company_category: '',
        region: '',
        tag_id: '',
        education: -1,
        min_wages: -1,
        max_wages: -1,
        exp: -1,
        types: '',
      },
      // 数据列表
      dataList: [],
    }
  },
  watch: {
    'myConfig.loadingConfig.limit'(val) {
      console.log(val, 'val')
      this.getList()
      // console.log(this.myConfig.loadingConfig.limit)
    },
    'myConfig.dataConfig.region': {
      handler(val) {
        this.getList()
      },
      // deep: true,
    },
  },
  created() {
    this.getList()

    console.log(this.myConfig, 'this.myConfig')
  },
  methods: {
    beautifyCity(value) {
      if (!Array.isArray(value)) {
        value = value.split(',')
      }
      const cityFullName = value[1] || value[0]
      if (cityFullName.endsWith('市')) {
        return cityFullName.slice(0, cityFullName.length - 1)
      }
      if (cityFullName.endsWith('特别行政区')) {
        return cityFullName.slice(0, cityFullName.length - 5)
      }
      return cityFullName
    },
    beautifyDistrict(value) {
      if (!Array.isArray(value)) {
        value = value.split(',')
      }
      const cityFullName = value[2] || value[1]

      if (cityFullName.endsWith('特别行政区')) {
        return cityFullName.slice(0, cityFullName.length - 5)
      } else if (cityFullName.endsWith('区')) {
        return cityFullName.slice(0, cityFullName.length - 1)
      }
      return cityFullName
    },
    beautifyJobCity(arr = []) {
      let city = this.beautifyCity(arr)
      let district = this.beautifyDistrict(arr)
      return city !== district ? city + '·' + district : city
    },
    getList() {
      this.loading = true
      getEmployList({
        ...this.form,
        region: this.myConfig.dataConfig.dataAll
          ? ''
          : this.myConfig.dataConfig.region.length
          ? this.myConfig.dataConfig.region[1]
          : '',
        limit: this.myConfig.loadingConfig.limit,
      }).then((res) => {
        console.log(res)
        this.dataList = res.data.list.length ? res.data.list : this.defaultList
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/component';

.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;

  .data-list {
    padding: 0 16px;
    min-height: 100px;
    overflow: hidden;
    .article-item {
      border-radius: 16px;
      padding: 16px;
      background-color: #fff;
      border: 1px solid #eff2f6;
      .title-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-box {
          display: flex;
          align-items: center;
          .left {
            max-width: 175px;
            color: #1a1a1a;
            font-size: 18px;
            font-weight: bold;
            @include clamp(1);
          }
        }

        .is_top {
          width: 44px;
          height: 21px;
          border-radius: 4px;
          font-size: 11px;
          background-color: rgba(255, 123, 27, 0.1);
          border: 1px solid rgba(255, 123, 27, 0.2);
          color: rgba(255, 123, 27, 1);
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 6px;
          .icon {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }
        }

        .right {
          white-space: nowrap;
          color: #ff7b1b;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .tags {
        margin-top: 8px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .tag {
          display: flex;
          align-items: center;
          padding: 3px 6px;
          border: 1rpx solid #1ddbe8;
          background: #ecfeff;
          border-radius: 6px;
          color: #1ddbe8;
          font-size: 10px;
          margin-right: 4px;
          margin-top: 4px;
          &.default {
            color: #acb2ba;
            border: 1rpx solid #acb2ba;
            background: #f8f9fb;
          }
        }
        // .tag + .tag {
        //   margin-left: 4 * 2rpx;
        // }
      }

      .footer {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          display: flex;
          align-items: center;
          color: #808080;
          .icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            // background-color: pink;
          }

          .name {
            font-size: 13px;
            margin-left: 6px;
            max-width: 100px;
            @include clamp(1);
          }

          .people {
            font-size: 13px;
            margin-left: 12px;
            @include clamp(1);
          }
        }

        .area {
          color: #cbcbcb;
          font-size: 13px;
          max-width: 100px;
          @include clamp(1);
        }
      }
    }

    .article-item + .article-item {
      margin-top: 12px;
    }
  }
}
</style>
