<!--
 * @Author: dongjia
 * @Date: 2022-01-12 10:01:50
 * @LastEditTime: 2022-01-20 18:22:37
 * @LastEditors: aleaner
 * @Description: 在线学习组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StOnlineLearning.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="brand-list"
    :style="{
      padding: `12px ${myStyleType === 'template3' ? '16' : '0'}px`,
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      borderRadius: `${myConfig.normal.borderRadius}px`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage: myBgImage,
    }"
  >
    <StListTitle
      :style="{
        padding: `0 ${myStyleType !== 'template3' ? '16' : '0'}px 16px`,
      }"
      :config="myConfig"
    />

    <!-- 模版一 -->
    <div
      v-loading="loading"
      class="template1-data-list"
      v-if="['template1'].includes(myStyleType)"
    >
      <div class="item-list">
        <div class="item" v-for="(item, i) in dataList" :key="item.id">
          <div class="cover-box">
            <img class="cover" :src="item.cover" />
            <img
              class="rank-icon"
              v-if="i === 0 && myConfig.showConfig.isShowBtn"
              :src="firstIcon"
            />
            <img
              class="rank-icon"
              v-if="i === 1 && myConfig.showConfig.isShowBtn"
              :src="secondIcon"
            />
            <img
              class="rank-icon"
              v-if="i === 2 && myConfig.showConfig.isShowBtn"
              :src="thirdIcon"
            />

            <div class="play-icon">
              <img class="play" :src="playIcon" />
            </div>
          </div>

          <div class="name">{{ item.name }}</div>
          <div class="desc">
            <img class="icon" :src="listenIcon" />
            <div class="text">{{ item.views_count }}人听过</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 模版二 -->
    <div
      v-loading="loading"
      class="template2-data-list"
      v-if="['template2','template4'].includes(myStyleType)"
    >
      <div class="item-list">
        <div class="item" v-for="(item, i) in dataList" :key="item.id">
          <div class="cover-box">
            <img class="cover" :src="item.cover" />
            <!-- <div class="play-icon">
              <img class="play" :src="playIcon" />
              <img class="film" :src="filmIcon" />
            </div> -->
          </div>
          <div class="title-box">
            <div class="name">{{ item.name }}</div>
            <div class="desc" v-if="item.description">
              {{ item.description }}
            </div>
          </div>
          <img class="play" :src="playIcon3" />
        </div>
      </div>
    </div>
    <!-- 模版三 -->
    <div
      v-loading="loading"
      class="template3-data-list"
      v-if="['template3'].includes(myStyleType)"
    >
      <div class="item-list">
        <div
          class="item"
          v-for="(item, i) in dataList"
          :key="item.id"
          :style="{
            backgroundColor:
              config.templateData.bgType === 'color' &&
              config.templateData.isCustomBg
                ? config.templateData.bgColor
                : '',
            backgroundImage:
              config.templateData.bgType === 'image' &&
              config.templateData.isCustomBg
                ? `url(${config.templateData.bgImage})`
                : '',
          }"
        >
          <div
            class="item-content"
            :style="{
              // '--bg-filter': `url(${item.cover})`,
              '--bg-filter': config.templateData.isCustomBg
                ? `url('')`
                : `url(${item.cover})`,
            }"
          >
            <img class="cover" :src="item.cover" />
            <div class="item-right">
              <div
                class="name"
                :style="{
                  color: config.templateData.fontColor,
                }"
              >
                {{ item.name }}
              </div>

              <div class="bottom-box">
                <div class="play-btn">
                  <img class="icon" :src="playIcon2" />
                  <div class="text">播放</div>
                </div>
                <div class="right-btn">
                  <!-- <img class="icon" :src="listenIcon2" /> -->
                  <color-custom-icon
                    :icon="listenIcon2"
                    class="icon"
                    :color="config.templateData.fontColor"
                    height="16"
                    width="16"
                  />
                  <div
                    class="text"
                    :style="{
                      color: config.templateData.fontColor,
                    }"
                  >
                    {{ item.views_count }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看按钮 -->
    <div class="check-btn" v-if="myConfig.showConfig.isShowBtn">
      <img class="btn-bg" :src="btnBg" />
      <div class="text">
        {{ myConfig.showConfig.title }}
      </div>
    </div>
    <div class="load-more" v-if="myLoadingConfig.loadingType === 'handle'">
      <div :class="[myLoadingConfig.handleType]">
        {{ myLoadingConfig.handleText }}
      </div>
    </div>
  </div>
</template>

<script>
import { getAudioList } from '../../api/components'
import componentMixin from '@/modules/app-design/mixins/component-mixin'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'
import ColorThief from 'colorthief'
import ColorCustomIcon from '@/modules/app-design/components/Normal/ColorCustomIcon'
export default {
  name: 'st-college-audio-list',
  components: { StLoadImage, ColorCustomIcon },
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      btnBg:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/button-bg.png',
      playIcon:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/play-icon.png',
      filmIcon:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/film-icon.png',
      listenIcon:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/listen-icon.png',
      playIcon2:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/play-icon2.png',
      playIcon3:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/play-icon3.png',
      filmIcon2:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/film-icon2.png',
      listenIcon2:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/listen-icon2.png',
      firstIcon:
        window.serverConfig.VUE_APP_ADMINURL + '/app_design/images/first.png',
      secondIcon:
        window.serverConfig.VUE_APP_ADMINURL + '/app_design/images/second.png',
      thirdIcon:
        window.serverConfig.VUE_APP_ADMINURL + '/app_design/images/third.png',
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
          borderRadius: 12,
        },
        templateData: {
          styleType: 'template1',
          bgType: 'color',
          bgColor: 'rgba(255, 255, 255, 0)',
          bgImage: '',
          fontColor: '#fff',
          isCustomBg: 1,
          dataSpacing: 12,
        },
        dataConfig: {
          randType: 0,
          randLimit: 10,
          dataType: 'select',
          orderRule: 'normal',
          dataAll: 1,
          dataFilter: [''],
          dataArr: [
            {
              id: '',
              name: '',
            },
          ],
        },
        loadingConfig: {
          loadingType: 'none',
          limit: 10,
          handleType: 'template1',
          handleText: '加载更多',
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '在线学习',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          isShowBtn: 1,
          showType: 'template1',
          title: '查看更多',
        },
      },
      dataList: [],
    }
  },
  watch: {
    'myLoadingConfig.limit'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataType'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getList(false)
      }
    },
    'myConfig.dataConfig.order_rule'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataAll'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.randType'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.randLimit'(val) {
      this.getList(false)
    },
    'myConfig.dataConfig.dataFilter'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getList(false)
      }
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList(notCancel) {
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        page: 1,
        tab: 'normal',
        limit: this.myLoadingConfig.limit,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        template: this.myStyleType,
        ids,
        order_rule: this.myConfig.dataConfig.orderRule,
        category_all: this.myConfig.dataConfig.dataAll,
        category_ids: this.myConfig.dataConfig.dataFilter.map((item) => item),
        rand_type: this.myConfig.dataConfig.randType,
        rand_limit: this.myConfig.dataConfig.randLimit,
      }
      this.loading = true
      getAudioList(requestData, notCancel)
        .then((res) => {
          const { list } = res.data
          this.dataList = list

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/component';

.brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;

  .template1-data-list {
    overflow: hidden;
    .item-list {
      padding: 0 12px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      overflow: hidden;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 26px 12px 16px;
        border-radius: 12px;
        background: #f8f9fb;
        border-radius: 8px;
        overflow: hidden;
        .cover-box {
          width: 90px;
          height: 120px;
          position: relative;
          .rank-icon {
            width: 24px;
            height: 24px;
            position: absolute;
            left: -6px;
            top: -6px;
          }
          .cover {
            width: 90px;
            height: 120px;
            object-fit: cover;
            border-radius: 4px;
          }
          .play-icon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 16px;
            height: 16px;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .play {
              width: 10px;
              height: 10px;
            }
          }
        }

        .name {
          margin-top: 8px;
          font-size: 13px;
          color: rgba(41, 43, 45, 1);
          font-style: normal;
          font-weight: 700;
          line-height: 19.5px;
          max-width: 100%;
          @include nowrap();
        }
        .desc {
          display: flex;
          align-items: center;
          margin-top: 8px;
          .icon {
            width: 12px;
            height: 12px;
            // background-color: pink;
          }
          .text {
            margin-left: 4px;
            color: rgba(41, 43, 45, 0.4);
            font-family: 'PingFang SC';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
          }
        }
      }
    }
  }
  .template2-data-list {
    .item-list {
      padding: 0 12px;
      overflow: hidden;
      .item {
        display: flex;
        align-items: center;
        overflow: hidden;
        .cover-box {
          width: 64px;
          height: 64px;
          border-radius: 7px;
          position: relative;
          .cover {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 7px;
          }
        }
        .title-box {
          flex: 1;
          margin-left: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
          .name {
            color: #292b2d;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            @include nowrap();
          }
          .desc {
            overflow: hidden;
            margin-top: 4px;
            color: #a1a8af;
            font-size: 13px;
            line-height: 19.5px;
            @include nowrap();
          }
        }
        .play {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          margin-left: 12px;
          // background-color: #399cde;
        }
      }
      .item + .item {
        margin-top: 8px;
      }
    }
  }
  .template3-data-list {
    .item-list {
      overflow: hidden;
      .item {
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        background-size: cover;
        .item-content {
          flex: 1;
          height: 88px;
          padding: 12px;
          display: flex;
        overflow: hidden;

          /* 头图背景+毛玻璃 */
          &:before {
            z-index: -1;

            content: '';
            display: block;
            height: 88px;
            width: 100%;

            flex-shrink: 0;
            background: var(--bg-filter);
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
            filter: blur(24px);
          }
          .cover {
            width: 64px;
            height: 64px;
            border-radius: 7px;
            margin-right: 12px;
            background-color: #fff;
            object-fit: cover;
          }
          .item-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;

            .name {
              font-size: 16px;
              color: rgb(255, 255, 255);
              @include nowrap();
            }

            .bottom-box {
              display: flex;
              justify-content: space-between;
              border-radius: 12px;
              .play-btn {
                display: flex;
                align-items: center;
                padding: 4px 8px;
                border-radius: 15px;
                background: #fff4e4;
                .icon {
                  width: 18px;
                  height: 18px;
                  // background-color: pink;
                  margin-right: 4px;
                }

                .text {
                  color: #e28d17;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 19.5px;
                }
              }

              .right-btn {
                display: flex;
                align-items: center;
                .icon {
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
                  // background-color: pink;
                }

                .text {
                  color: rgba(255, 255, 255, 0.8);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
      .item + .item {
        margin-top: 8px;
      }
    }
  }
  .check-btn {
    margin: 12px 12px 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .text {
      color: #45350e;
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      position: relative;
      z-index: 3;
    }
    .btn-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}
</style>
