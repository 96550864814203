<!--
 * @Author: dongjia
 * @Date: 2021-10-09 18:20:57
 * @LastEditTime: 2021-10-28 17:55:39
 * @LastEditors: aleaner
 * @Description: 关于我信息展示
 * @FilePath: \app-design\components\Preview\StAboutMeContent.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="about-me-content" v-if="content.is_show">
    <div class="content-header">
      <div class="content-title">
        <div class="title-dot"></div>{{content.name}}
      </div>
      <div class="content-btn">
        编辑
        <img class="btn-icon" src="../../assets/images/st-member-index/handle-icon.png" />
      </div>
    </div>
    <div class="content">
      <div v-if="content.key === 'signature'" class="text-content">{{content_data.signature}}</div>
      <div v-if="content.key === 'description'" class="text-content">{{content_data.description}}</div>
      <div v-if="content.key === 'hobby'" class="text-content">{{content_data.hobby}}</div>
      <div v-if="content.key === 'label'" class="label">
        <div class="label-tag" v-for="(item, index) in content_data.label" :key="index">
          <img class="tag-icon" src="../../assets/images/st-member-index/tag-icon.png" />
          {{item}}
        </div>
      </div>
      <div v-if="content.key === 'like_books'" class="like">
        <div class="like-item" v-for="(item, index) in content_data.like_books" :key="index">
          <img class="default-icon"
            :src="baseUrl + '/cyc/images/wxapps/images/bookbackground' + (index + 1) + '.png'" />
          <div class="books-name">{{item}}</div>
        </div>
      </div>
      <div v-if="content.key === 'like_movie'" class="like">
        <div class="like-item" v-for="(item, index) in content_data.like_movie" :key="index">
          <img class="default-icon" src="../../assets/images/st-member-index/like-movie-icon.png" />
        </div>
      </div>
      <div v-if="content.key === 'like_photo'" class="photos">
        <div class="photo-item" v-for="(item, index) in content_data.like_photo" :key="index">
          <img class="default-icon" src="../../assets/images/mini-default-icon@2x.png" />
        </div>
      </div>
      <div v-if="content.key === 'like_video'" class="video">
        <div class="video-mask"><img class="default-icon" src="../../assets/images/video-play.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      content_data: {
        signature: "这是我的个性签名",
        description: "介绍下自己的性格、习惯、优点、缺点。",
        label: ["标签1", "标签2", "标签3", "标签12345678"],
        hobby: "这是我的兴趣爱好！",
        like_books: ["谷物", "从地球看月球", "原则"],
        like_movie: [1, 2, 3],
        like_photo: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        like_video: "",
      },
      baseUrl: window.serverConfig.VUE_APP_ADMINURL,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content-title {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: #ecf2ff;
    border-radius: 13px;
    font-size: 12px;
    font-weight: bold;
    color: #3a7aff;
    line-height: 12px;
    .title-dot {
      width: 9px;
      height: 9px;
      background: #3a7aff;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .content-btn {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999999;
    line-height: 14px;
    .btn-icon {
      width: 10px;
      height: 10px;
      margin-left: 3px;
    }
  }
}

.content {
  margin-top: 12px;
  .text-content {
    font-size: 13px;
    font-weight: bold;
    color: #888888;
    line-height: 21px;
    text-indent: 2em;
  }
  .label {
    display: flex;
    flex-wrap: wrap;
    .label-tag {
      margin-right: 9px;
      margin-bottom: 8px;
      padding: 6px 12px;
      display: flex;
      border-radius: 13px;
      align-items: center;
      font-size: 12px;
      background: #ecf2ff;
      color: #3a7aff;
      line-height: 13px;
      .tag-icon {
        width: 13px;
        height: 13px;
        margin-right: 4px;
      }
    }
  }
  .like {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 9px;
    .like-item {
      flex-shrink: 0;
      margin-right: 16px;
      width: 95px;
      height: 138px;
      position: relative;

      .books-name {
        position: absolute;
        width: 72px;
        @include clamp(2);
        font-size: 12px;
        color: #ffffff;
        line-height: 14px;
        position: absolute;
        bottom: 32px;
        left: 6px;
      }
      .default-icon {
        width: 100%;
        height: 100%;
      }
    }
  }
  .photos {
    display: flex;
    flex-wrap: wrap;
    margin-left: 9px;
    .photo-item {
      width: 89px;
      height: 89px;
      margin-right: 6px;
      margin-bottom: 6px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(226, 226, 226);
      .default-icon {
        width: 28px;
        height: 28px;
      }
    }
  }
  .video {
    margin-left: 9px;
    width: 280px;
    height: 157px;
    border-radius: 4px;
    background-color: rgb(226, 226, 226);
    overflow: hidden;
    .video-mask {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.52);
      display: flex;
      align-items: center;
      justify-content: center;
      .default-icon {
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>