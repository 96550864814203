<template>
  <div class="drag-list st-drag-member-nav-list">
    <div class="drag-list-header">
      <p class="list-title">调整顺序</p>
      <p class="form-tip">
        拖动选中的导航可以对其排序
      </p>
    </div>
    <draggable
      v-if="navList"
      v-model="navList"
      class="nav-list"
      :disabled="unDraggable"
      animation="500"
      @sort="sortChange"
      draggable=".nav-item"
    >
      <template v-if="navList.length > 0">
        <div class="nav-item" v-for="(nav, index) in navList" :key="index">
          <div class="item-title">
            <p>
              <span
                :class="[
                  'item-collapse',
                  'cursor-pointer',
                  itemFoldable ? 'el-icon-caret-top' : '',
                ]"
                :style="
                  hasCollapseArr.includes(index)
                    ? ''
                    : 'transform:rotate(180deg);'
                "
                @click="handleCollapse(index)"
              />{{ formType === "st-shop-categories" ? "分类" : "导航"
              }}{{ index + 1 }}
            </p>
            <div style="display: flex"></div>
          </div>
          <div
            class="item-content"
            :style="{
              height:
                itemFoldable && hasCollapseArr.includes(index) ? '0px' : '',
              marginTop:
                itemFoldable && hasCollapseArr.includes(index) ? '0px' : '22px',
              borderTop:
                itemFoldable && hasCollapseArr.includes(index)
                  ? 'none'
                  : '1px #eeeeee solid',
            }"
          >
            <div
              class="nav-content"
              v-if="nav.name !== undefined"
              @mousemove="unDraggable = true"
              @mouseout="unDraggable = false"
            >
              <p class="nav-title">导航名称</p>
              <el-input
                style="width: 100%"
                v-model="navList[index].name"
                placeholder="请输入导航名称"
                @focus="unDraggable = true"
                @blur="unDraggable = false"
                @input="test($event, index)"
              >
              </el-input>
            </div>
            <!-- <div class="nav-content" v-if="nav.jump_type !== undefined" @mousemove="unDraggable = true"
              @mouseout="unDraggable = false">
              <p class="nav-title">打开方式</p>
              <el-radio-group style="margin-left: auto" v-model="navList[index].jump_type" size="small">
                <el-radio-button label="view">默认</el-radio-button>
                <el-radio-button label="path">新页面打开</el-radio-button>
              </el-radio-group>
            </div> -->

            <div
              class="nav-content"
              v-if="nav.is_show !== undefined"
              @mousemove="unDraggable = true"
              @mouseout="unDraggable = false"
            >
              <p class="nav-title">导航状态</p>
              <el-radio-group
                style="margin-left: auto"
                v-model="navList[index].is_show"
                size="small"
              >
                <el-radio-button :label="1">显示</el-radio-button>
                <el-radio-button :label="0">隐藏</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
      </template>
      <div
        class="nav-item-add"
        :style="{ marginTop: navList.length > 0 ? '24px' : '0' }"
        v-if="navList.length < limit && addEnable"
      >
        <el-button 
          style="width: 100%"
          size="mini"
          type="primary"
          plain
          @click="handleAdd"
          >{{ addButtonText }}
        </el-button>
      </div>
    </draggable>
    <link-config-dialog
      v-model="showLinkConfigDialog"
      :link="selectedLinkConfig"
      :jump_type="jumpType"
      :requestType="selectableType"
      :exclude_requestType="unselectableType"
      :component="formType"
      @change="linkChange"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
import LinkConfigDialog from "../Normal/LinkConfigDialog.vue";
import { getAllShopCategory } from "@/modules/app-design/api/components";
const JUMP_LINK_MODEL = require("@/modules/app-design/assets/jsons/jump-link.json");

export default {
  name: "StDragMemberNavList",
  components: {
    draggable,
    SingleMediaWall,
    LinkConfigDialog,
  },
  model: {
    prop: "list",
    event: "listChange",
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    formType: {
      type: String,
      default: "",
    },
    initItem: {
      type: Object,
      required: true,
    },
    limit: {
      type: Number,
      default: 5,
    },
    navNameLength: {
      type: Number,
      default: 99,
    },
    addButtonText: {
      type: String,
      default: "添加导航",
    },
    suggestSize: {
      type: Object,
      default: () => {
        return {
          width: 100,
          height: 100,
        };
      },
    },
    // 列表项是否可折叠
    itemFoldable: {
      type: Boolean,
      default: false,
    },
    // 可选择链接类型
    selectable_type: {
      type: Array,
      default: () => [],
    },
    // 不可选择链接类型
    unselectable_type: {
      type: Array,
      default: () => [],
    },
    // 跳转类型
    jump_type: {
      type: String,
      default: "all",
    },
    // 是否允许添加新的项，true：允许，false：不允许，即不显示添加按钮
    addEnable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showLinkConfigDialog: false,
      selectedLinkConfig: null,
      currentIndex: -1,
      current_jumptype: "view", // 当前链接设置跳转方式
      unDraggable: false,
      visible: false,
      hasCollapseArr: [],
      selectableType: [],
      unselectableType: [],
      // 商品分类选择项
      categoryOptions: [],
    };
  },
  created() {
    this.selectableType = JSON.parse(JSON.stringify(this.selectable_type));
    this.unselectableType = JSON.parse(JSON.stringify(this.unselectable_type));
  },
  computed: {
    navList: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit("listChange", val);
      },
    },
    jumpType() {
      return this.formType === "st-bottom-nav"
        ? this.current_jumptype
        : this.jump_type;
    },
  },
  watch: {
    navList: {
      handler(val) {
        this.$emit("listChange", val);
        console.log(val, "listChange");
      },
      deep: true,
    },
  },
  methods: {
    test(val, i) {
      if (val.length > this.navNameLength) {
        this.$message.error(
          `导航名称超出最大字数限制（最大为${this.navNameLength}个字）`
        );
        this.navList[i].name = val.slice(0, this.navNameLength);
      }
    },
    handleAdd() {
      if (Array.isArray(this.initItem)) {
        this.navList.push(...this.initItem);
      } else this.navList.push(this.initItem);
    },
    // 排序发生变化时
    sortChange(ev) {
      if (this.hasCollapseArr.length) {
        if (
          this.hasCollapseArr.includes(ev.oldIndex) !==
          this.hasCollapseArr.includes(ev.newIndex)
        ) {
          if (this.hasCollapseArr.includes(ev.oldIndex)) {
            this.hasCollapseArr = this.hasCollapseArr.map((el) => {
              if (el === ev.oldIndex) {
                return ev.newIndex;
              } else {
                return el;
              }
            });
            return;
          }
          if (this.hasCollapseArr.includes(ev.newIndex)) {
            this.hasCollapseArr = this.hasCollapseArr.map((el) => {
              if (el === ev.newIndex) {
                return ev.oldIndex;
              } else {
                return el;
              }
            });
            return;
          }
        }
      }
    },
    // 折叠导航设置
    handleCollapse(index) {
      console.log("index", index);
      if (this.hasCollapseArr.includes(index)) {
        this.hasCollapseArr = this.hasCollapseArr.filter((el) => el !== index);
      } else {
        this.hasCollapseArr.push(index);
      }
    },
    // 设为首页
    setIndex(i) {
      this.navList.forEach((el, index) => {
        el.is_default = i === index ? 1 : 0;
      });
      this.$message.success("操作成功");
    },
    // 选择跳转方式
    handleCommand(val, i) {
      this.currentIndex = i;
      this.current_jumptype = val;
      this.selectedLinkConfig = this.navList[i].jump_link || null;
      if (this.formType === "st-bottom-nav") {
        this.selectableType =
          val === "view"
            ? ["system_page", "custom_page"]
            : JSON.parse(JSON.stringify(this.selectable_type));
      }
      this.showLinkConfigDialog = true;
    },
    // 显示弹窗
    openDialog(i) {
      this.currentIndex = i;
      this.current_jumptype = this.navList[i].jump_type;
      this.selectedLinkConfig = this.navList[i].jump_link || null;
      this.showLinkConfigDialog = true;
    },
    // 删除
    handleDel(i) {
      this.navList[i].jump_link = JUMP_LINK_MODEL;
    },
    linkChange(val) {
      this.navList[this.currentIndex].jump_link = this.$mergeModel(
        JUMP_LINK_MODEL,
        val
      );
    },
    // 删除
    deleteItem(i) {
      this.$msgbox
        .confirm(
          this.formType === "st-bottom-nav"
            ? "删除此底部导航项，此底部导航链接的页面将同时无法看到底部导航，确定删除吗？"
            : "确定删除这条数据？",
          "提示",
          {
            type: "info",
          }
        )
        .then((res) => {
          this.navList.splice(i, 1);
        })
        .catch((err) => {});
    },
    // 获取商品分类配置项
    getShopCategory() {
      getAllShopCategory().then((res) => {
        console.log(res);
        this.categoryOptions = res.data.filter((item) => item.label !== "全部");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.form-tip {
  font-size: 12px;
  color: #c0c4cc;
  padding-top: 8px;
  padding-bottom: 11px;
}
.nav-list {
  background-color: #f6f9ff;
  // margin: 0 -19px 0 -24px;
  padding: 16px 12px;
}
.nav-item-add {
  width: 100%;
  text-align: center;
}
.nav-item {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;

  .item-title {
    display: flex;
    justify-content: space-between;

    .item-collapse {
      transition: all 0.3s ease;
      margin-right: 5px;
    }
  }
  .item-content {
    transition: all 0.2s ease;
    overflow: hidden;

    .nav-content {
      margin-top: 20px;
      display: flex;
      position: relative;
      .nav-title {
        line-height: 30px;
        font-size: 14px;
        color: #3a3a3a;
        margin-right: 18px;
        min-width: 56px;
      }
    }
  }
}
.el-input,
.el-select {
  ::v-deep.el-input__inner {
    height: 30px;
  }
  ::v-deep.el-input__icon {
    line-height: 30px;
  }
}

.nav-item + .nav-item {
  margin-top: 24px;
}

.drag-list {
  ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(53, 118, 255, 0.15);
    border: 1px solid #3576ff;
    color: #3576ff;
    box-shadow: none;
  }
}
</style>
