<template>
  <div class="drag-item-tool inner">
    <div class="tools">
      <!-- 添加.stop修饰符阻止冒泡 -->
      <!-- 重复一个 -->
      <!-- <img @click.stop="duplicate(i)" src="../assets/images/copy.png" />-->
      <!-- 上移 -->
      <!-- <img v-if="i > 0" @click.stop="moveUp(i)" src="../assets/images/up.png" />-->
      <!-- 下移 -->
      <!-- <img v-if="i < myList.length - 1" @click.stop="moveDown(i)" src="../assets/images/down.png" />-->
      <!-- 删除 -->
      <div @click.stop="deletePageItem()"
          class="tool flex-center"
          v-if="!hideDelete"
      >
        <img src="../../assets/images/right/del_gray.png" />
      </div>
    </div>
    <div class="arrow-label" v-if="title">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    hideDelete: Boolean,
  },
  methods: {
    deletePageItem() {
      this.$emit('delete')
    },
    /* 上移 */
    // moveUp(i) {
    //   let target = this.myList.splice(i, 1)[0]
    //   this.myList.splice(i - 1, 0, target)
    //   undoManager.add({
    //     undo: () => {
    //       let target = this.myList.splice(i, 1)[0]
    //       this.myList.splice(i - 1, 0, target)
    //     },
    //     redo: () => {
    //       let target = this.myList.splice(i, 1)[0]
    //       this.myList.splice(i - 1, 0, target)
    //     },
    //   })
    //   this.refreshUndoStatus()
    //   this.$emit('update-list', this.myList)
    // },
    /* 下移 */
    // moveDown(i) {
    //   let target = this.myList.splice(i, 1)[0]
    //   this.myList.splice(i + 1, 0, target)
    //   undoManager.add({
    //     undo: () => {
    //       let target = this.myList.splice(i, 1)[0]
    //       this.myList.splice(i + 1, 0, target)
    //     },
    //     redo: () => {
    //       let target = this.myList.splice(i, 1)[0]
    //       this.myList.splice(i + 1, 0, target)
    //     },
    //   })
    //   this.refreshUndoStatus()
    //   this.$emit('update-list', this.myList)
    // },
  },
}
</script>

<style lang="scss" scoped>
.drag-item-tool {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -40px;
  top: 0;

  .tools {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 0 2px 2px 0;
    cursor: default;

    .tool {
      cursor: pointer;
      width: 100%;
      height: 40px;
    }

    img {
      font-size: 18px;
      width: 20px;
    }
  }

  .arrow-label {
    display: block;
    position: absolute;
    right: 28px;
    transform: translateX(100%);
    top: 0;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 2px;
    font-size: 12px;
    color: #3a3a3a;
    line-height: 12px;
    padding: 8px 15px;
    white-space: nowrap;
    pointer-events: none;

    &::before {
      content: '\00a0';
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-width: 7px 16px 7px 0;
      border-color: transparent white transparent transparent;
      position: absolute;
      left: -15%;
    }
  }
}
</style>