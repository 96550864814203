var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:({
      margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
      padding: ("12px " + (_vm.myStyleType === 'template2'?'16':'0') + "px"),
      backgroundColor: _vm.myConfig.normal.bgType === 'color'?_vm.myConfig.normal.bgColor:'',
      backgroundImage: _vm.myBgImage
    })},[_c('StListTitle',{style:({
    padding: ("0 " + (_vm.myStyleType !== 'template2'?'16':'0') + "px 16px")
  }),attrs:{"config":_vm.myConfig}}),(_vm.myStyleType === 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template1"},_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginRight: _vm.myTemplateData.dataSpacing + 'px',
    })},[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.image,"size":300}}),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)}),0):_vm._e(),(_vm.myStyleType === 'template2')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template2",staticStyle:{"padding-left":"0"}},[_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginTop: index === 0?'0':_vm.myTemplateData.dataSpacing + 'px',
    })},[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.image,"size":300,"border-radius":_vm.myTemplateData.borderRadius}}),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"item-desc"},[_vm._v(_vm._s(item.description))])])],1)}),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],2):_vm._e(),(_vm.myStyleType === 'template3')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template3"},_vm._l((_vm.brand_list),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginRight: _vm.myTemplateData.dataSpacing + 'px',
    })},[_c('st-load-image',{staticClass:"item-image",attrs:{"src":item.image,"size":300,"border-radius":4}}),_c('div',{staticClass:"item-content"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))])])],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }