<template>
  <div class="st-brand-item-big brand" :style="{
    borderRadius: this.borderRadius + 'px'
  }">
    <div class="cover-wrapper">
      <st-load-image class="cover-img" :src="item.page_cover" proportion="55%" />
    </div>
    <div class="brand-text">
      <div class="brand-name clamp2">{{item.name}}</div>
      <div v-if="template === 'template3'" class="brand-desc clamp">
        {{item.description}}
      </div>
      <template v-else-if="template !== 'template2'">
        <div v-if="item.articles && item.articles.length" class="brand-msg">
          <div class="brand-msg-item">
            <div class="item-left">资讯</div>
            <div class="item-right">{{item.articles[0].title?item.articles[0].title:'暂时没有更新资讯信息'}}
            </div>
          </div>
        </div>
        <div v-if="item.activity && item.activity.length" class="brand-msg">
          <div class="brand-msg-item">
            <div class="item-left">活动</div>
            <div class="item-right">{{item.activity[0].name?item.activity[0].name:'暂时没有更新活动信息'}}
            </div>
          </div>
        </div>
        <div v-if="item.collectedUsers && item.collectedUsers.length" class="brand-bottom">
          <div class="join-avatars" style="display: flex;align-items: center;">
            <StAvatars :arr="item.collectedUsers" :indent="6" :width="18"></StAvatars>
          </div>
          <span v-if="item.collects_count != 0"
            class="brand-num">{{item.collects_count <= 999?item.collects_count:'999+'}}人收藏</span>
        </div>
        <div v-if="item.organizations && item.organizations.length" class="brand-bottom">
          <div class="join-avatars" style="display: flex;align-items: center;">
            <StAvatars :arr="item.organizations" :indent="7" :width="18"></StAvatars>
          </div>
          <span v-if="item.organizations_count != 0"
            class="brand-num">{{item.organizations_count <= 999?item.organizations_count:'999+'}}人收藏</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import StAvatars from "@/modules/app-design/components/Preview/Common/StAvatars";
import {randomString} from "@/base/utils/tool";
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";
export default {
  components: {StLoadImage, StAvatars },
  props: {
    item: {
      type: Object,
      required: true,
    },
    template: {
      type: String,
      default: "template1",
    },
    borderRadius: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      randomId: randomString(10)
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.st-brand-item-big {
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 10px 0 #f2f2f2;
  background-color: white;
  overflow: hidden;

  .brand-text {
    padding: 16px 10px 20px;

    .brand-name {
      font-size: 14px;
      color: #191e29;
      line-height: 18px;
      font-weight: bold;
      max-height: 36px;

      & + .brand-msg,
      & + .brand-bottom {
        margin-top: 12px;
      }
    }

    .brand-desc {
      margin-top: 8px;
      color: #979da6;
      font-size: 12px;
    }
  }

  .brand-msg {
    box-sizing: border-box;
    width: 100%;

    & + .brand-bottom,
    & + .brand-msg {
      margin-top: 6px;
    }

    & + .brand-bottom {
      margin-top: 12px;
    }

    .brand-msg-item {
      display: flex;
      align-items: center;
      height: 16px;

      .item-left {
        padding: 1px 3px;
        line-height: 12px;
        text-align: center;
        border-radius: 2px 2px 0 2px;
        color: #333c50;
        font-size: 9px;
        border: 1px solid #333c50;
        margin-right: 8px;
        flex-shrink: 1;
        box-sizing: border-box;
      }

      .item-right {
        font-size: 12px;
        color: #333c50;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 12px;
        flex: 1;
      }
    }
  }

  .brand-bottom {
    display: flex;
    align-items: center;
    height: 18px;
    margin-top: 6px;

    .join-avatars {
      position: relative;
      height: 22px;
    }

    .brand-num {
      font-size: 12px;
      color: #666666;
      margin-left: 10px;
    }
  }

  .cover-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}
</style>

