<!--
 * @Author: dongjia
 * @Date: 2021-09-17 14:44:40
 * @LastEditTime: 2021-09-29 14:15:34
 * @LastEditors: aleaner
 * @Description: 商协会系统页
 * @FilePath: \app-design\components\Preview\StBusinessAssociation.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="st-contacts">
    <div class="header" :class="myConfig.normal.bgConfig === 'theme'?'theme-color':''" :style="{
      backgroundColor: myConfig.normal.bgConfig === 'custom' && myConfig.normal.bgType === 'color'?myConfig.normal.bgColor:''
    }">
      <el-image class="bg-image"
        v-if="myConfig.normal.bgConfig === 'custom' && myConfig.normal.bgType === 'image'"
        :src="fixImageUrl(myConfig.normal.bgImage,{h:400}, true)" fit="cover"></el-image>
      <template v-if="platform !== 'h5'">
        <img class="start-top-img" :src="statusBar[myConfig.normal.statusBarColor]">
        <div class="start-top" style="text-align: end">
          <img v-if="myConfig.normal.showHeaderJump" class="header-jump"
            :class="[headerJump.showConfig.showType !== 'template1'?'template-back-icon':'default-back-icon']"
            :src="backIcon[myConfig.normal.statusBarColor][headerJump.showConfig.showType]" />
          <img class="start-top-handler" :src="handleIcon[myConfig.normal.statusBarColor]">
        </div>
      </template>
      <template v-else>
        <st-page-set :config="myConfig"></st-page-set>
      </template>
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div class="type-item" v-if="myConfig.normal.titleConfig.isShow" :style="{
          color: myConfig.normal.fontColor,
          fontWeight: myConfig.normal.fontWeight,
          fontSize: myConfig.normal.fontSize + 'px'
        }">{{myConfig.normal.titleConfig.name}}
        </div>
      </div>
    </div>

    <div class="search" v-if="myConfig.searchConfig.isShow"
      :class="myConfig.searchConfig.bgConfig === 'theme'?'theme-color':''" :style="{
        backgroundColor: myConfig.searchConfig.bgType === 'color'?myConfig.searchConfig.bgColor:'',
        backgroundImage: myConfig.searchConfig.bgType === 'image'?`url(${fixImageUrl(myConfig.searchConfig.bgImage, {}, true)})`:'',
        paddingRight: (myConfig.contentConfig.isFilterArea || myConfig.contentConfig.isFilterStar) && !(myConfig.contentConfig.isFilterArea && myConfig.contentConfig.isFilterStar)?'11px':'20px'
      }">
      <div class="header-search" :style="{
          height: myConfig.searchConfig.height + 'px',
          borderRadius: myConfig.searchConfig.searchType === 'circle'? myConfig.searchConfig.height + 'px': '0px',
          backgroundColor: myConfig.searchConfig.searchColor,
          color: myConfig.searchConfig.placeholderColor,
          justifyContent: myConfig.searchConfig.textPosition === 'center' ? 'center' : ''
        }">
        <div class="search-content">
          <p class="search-icon el-icon-search"></p>
          <p class="search-tip" v-if="myConfig.searchConfig.placeholder.isShow">
            {{myConfig.searchConfig.placeholder.name}}</p>
        </div>
      </div>
      <div class="search-filter"
        v-if="(myConfig.contentConfig.isFilterArea || myConfig.contentConfig.isFilterStar) && !(myConfig.contentConfig.isFilterArea && myConfig.contentConfig.isFilterStar)">
        <img class="filter-icon" src="../../assets/images/st-business-association/filter-icon.png" />
        <div class="filter-text">{{myConfig.contentConfig.isFilterArea?'选择地区':'星级筛选'}}</div>
      </div>
    </div>

    <div class="filter" v-if="myConfig.contentConfig.isFilterArea && myConfig.contentConfig.isFilterStar">
      <div class="filter-item">
        <div class="item-content">
          <div class="filter-text">选择地区</div>
          <img class="filter-icon" src="../../assets/images/st-business-association/select-icon.png" />
        </div>
      </div>
      <div class="center-line"></div>
      <div class="filter-item">
        <div class="item-content">
          <div class="filter-text">按星级筛选</div>
          <img class="filter-icon" src="../../assets/images/st-business-association/select-icon.png" />
        </div>
      </div>
    </div>

    <div class="content-list">
      <div class="list-item" :style="{
        marginTop: index > 0?'16px':'0'
      }" v-for="(item,index) in defaultList" :key="index">
        <div class="item-cover" :style="{
          backgroundColor: item.icon?'':'#f3f5f7'
        }">
          <el-image style="width: 100%;height: 100%" v-if="item.icon" :src="fixImageUrl(item.icon, {}, true)">
          </el-image>
          <img style="width: 28px;height: 28px" v-else src="../../assets/images/mini-default-icon@2x.png" />
        </div>
        <div class="item-content">
          <div class="content-text">{{item.name}}</div>
          <div class="member-num" v-if="myConfig.contentConfig.showMemberNum">会员人数：{{item.members_count}} 人
          </div>
          <div class="item-star" v-if="myConfig.contentConfig.showRange">
            <img class="star-icon" :class="i <= item.star?'stared':''" v-for="i in 5" :key="i"
              src="../../assets/images/st-business-association/star-icon.png" />
            <div class="star-text">{{item.star_text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
export default {
  name: "StBusinessAssociation",
  components: {StPageSet},
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultList: require("../../assets/jsons/default-list.json")
        .associationList,
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: "商协会",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 18,
          fontColor: "#FFFFFF",
          statusBarColor: "black",
          bgConfig: "theme",
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          showHeaderJump: 1,
          paddingBottom: 0,
        },
        searchConfig: {
          isShow: 1,
          placeholder: {
            name: "搜索内容",
            isShow: 1,
          },
          placeholderColor: "#979da6",
          textPosition: "left",
          bgConfig: "theme",
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          searchType: "circle",
          interactiveType: "sticky",
          height: 32,
          searchColor: "#f8fbff",
        },
        contentConfig: {
          showMemberNum: 1,
          showRange: 1,
          isFilterArea: 1,
          filterAreaType: 2,
          isFilterStar: 1,
        },
        shareConfig: {
          shareTitle: "",
          image: "",
        },
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.st-contacts {
  width: 375px;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;

  .header-nav {
    background-color: white;
  }

  .start-top-img {
    width: 375px;
    height: 20px;
  }
  .start-top {
    padding-right: 7px;
    .header-jump {
      position: absolute;

      &.default-back-icon {
        top: 32px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      &.template-back-icon {
        top: 25px;
        left: 5px;
        width: 78px;
        height: 32px;
      }
    }
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }
  .header {
    width: 100%;
    position: relative;
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .type-select {
      position: absolute;
      top: 23px;
      width: 100%;
      padding: 0 18px;
      display: flex;
      justify-content: center;

      &.simple {
        position: static;
        display: none;
      }

      .type-item + .type-item {
        margin-left: 18px;
      }
      .type-item {
        display: flex;
        align-items: center;
        height: 34px;
        text-align: center;
        color: #ffffff;
        line-height: 34px;
        .active {
          border-bottom: 2px #fff solid;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
        }
        .select-icon {
          width: 16px;
          height: 16px;
          margin-left: 2px;
        }
      }
    }
  }
}

.search {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 15px 20px;
  background-size: cover;
  background-position: center;
  .header-search {
    width: 100%;
    background: #f8fbff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    .search-content {
      display: flex;
      align-items: center;
      .search-icon {
        font-size: 14px;
      }
      .search-tip {
        font-size: 14px;
        line-height: 14px;
        margin-left: 4px;
      }
    }
  }

  .search-filter {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 16px;

    .filter-icon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }

    .filter-text {
      font-size: 14px;
      color: #333c50;
      line-height: 20px;
    }
  }
}

.filter {
  padding: 6px 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  .filter-item {
    height: 23px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .item-content {
      display: flex;
      align-items: center;
      .filter-text {
        font-size: 14px;
        color: #191e29;
        line-height: 14px;
      }
      .filter-icon {
        margin-left: 8px;
        width: 9px;
        height: 6px;
      }
    }
  }
  .center-line {
    width: 1px;
    height: 23px;
    opacity: 0.24;
    background-color: #979797;
  }
}

.content-list {
  padding: 18px 20px;
  .list-item {
    display: flex;

    .item-cover {
      border-radius: 4px;
      flex-shrink: 0;
      width: 104px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-content {
      position: relative;
      margin-left: 14px;
      .content-text {
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        @include clamp(2);
      }
      .member-num {
        font-size: 12px;
        color: #5b5b5b;
        line-height: 20px;
      }
      .item-star {
        position: absolute;
        bottom: 2px;
        left: 0;
        margin-top: 3px;
        display: flex;
        align-items: center;

        .star-icon + .star-icon {
          margin-left: 8px;
        }

        .star-icon {
          width: 14px;
          height: 13px;
          background-color: #e5e5e5;

          &.stared {
            background-color: var(--main-color);
          }
        }

        .star-text {
          margin-left: 8px;
          font-size: 12px;
          color: #c6c6c6;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
}

.theme-color {
  background: var(--main-color) !important;
}
</style>

