/*
 * @Author: dongjia
 * @Date: 2021-08-04 11:25:48
 * @LastEditTime: 2021-08-04 11:26:09
 * @LastEditors: aleaner
 * @Description: bus方法注册
 * @FilePath: \app-design\assets\js\bus.js
 * 怎么可能会有bug！！！
 */
import Vue from 'vue'
const bus = new Vue()
export default bus