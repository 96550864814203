<template>
  <div class="st-form-editor" v-loading="loading">
    <div class="component-explain" v-if="rulesArr && rulesArr.componentExplain">
      <p class="form-title" v-if="rulesArr.componentExplain.title">
        <span>{{ rulesArr.componentExplain.title }}</span>
      </p>
      <p class="explain-content">{{ rulesArr.componentExplain.content }}</p>
    </div>
    <!-- 活动报名情况组件 -->
    <div
      v-if="type === 'st-activity-join'"
      class="activity-status-bar flex-center"
    >
      <div
        @click="activityStatus = 0"
        :class="{ active: activityStatus === 0 }"
        class="activity-status"
      >
        报名未开始
      </div>
      <div
        @click="activityStatus = 1"
        :class="{ active: activityStatus === 1 }"
        class="activity-status"
      >
        报名开始后
      </div>
    </div>
    <div class="el-collapse-box flex-col" v-if="hasIsShow">
      <el-collapse v-model="activeNames" @change="activeChange">
        <template
          v-for="(key, index) in Object.keys(myValue).sort(
            (a, b) => keyDict.indexOf(a) - keyDict.indexOf(b)
          )"
        >
          <el-collapse-item
            :name="key"
            v-if="
              rulesArr[key] &&
              rulesArr[key].isShow !== 0 &&
              isH5FilterShare(key)
            "
            :key="type + key"
          >
            <template slot="title">
              <div
                class="component-title-name"
                :data-key="key"
                @click="activeChange(rulesArr[key], key, rulesArr)"
              >
                {{ rulesArr[key].title || keyTitle[key] }}
                <template v-if="rulesArr[key].subtitle">
                  <br />
                  <span
                    class="component-subtitle-name"
                    :style="{
                      opacity: activeNames.includes(key) ? 1 : 0,
                    }"
                  >
                    <i
                      class="el-icon-warning"
                      style="color: rgb(192, 196, 204)"
                    />
                    {{ rulesArr[key].subtitle }}</span
                  >
                </template>
              </div>
            </template>
            <div
              class="editor-item"
              :style="{
                paddingTop: rulesArr[key].subtitle ? '24px' : 0,
                order: keyDict.indexOf(key) > -1 ? keyDict.indexOf(key) : 99,
              }"
            >
              <template v-if="rulesArr && rulesArr[key]">
                <!-- 在form-create.json中，与rules同级的isShow属性控制配置界面是否可见 -->
                <div
                  v-show="
                    rulesArr[key].isShow === undefined ||
                    rulesArr[key].isShow === 1
                  "
                >
                  <!-- <p class="form-title" v-if="rulesArr[key].title">
                  <span>{{ rulesArr[key].title }}</span>
                </p> -->
                  <form-create
                    :rule="rulesArr[key].rules"
                    v-model="fApiArr[index]"
                    :option="options"
                    :value.sync="myValue[key]"
                  >
                  </form-create>
                </div>
              </template>
            </div>
          </el-collapse-item>
        </template>
      </el-collapse>
    </div>
    <!-- ！这个 v-else-if 判断可能还有问题，要留意 -->
    <div
      v-else-if="type && JSON.stringify(rulesArr) !== '[]'"
      class="st-form-editor-disable"
    >
      <div class="disable-tip">
        <el-image
          class="tip-icon"
          :src="require('../../assets/images/right/disable-edit-icon.png')"
        >
        </el-image>
        <p class="tip-text">
          该{{ typeof selected === 'number' ? '组件' : '页面' }}不支持设置
        </p>
      </div>
    </div>
    <div
      class="jump-btn"
      v-if="rulesArr && rulesArr.pageJump && rulesArr.pageJump.isShow"
    >
      <el-button size="mini" plain @click="handleJumpPage"
        >{{ rulesArr.pageJump.title }}
      </el-button>
    </div>
    <div
      class="component-tips"
      v-if="rulesArr && rulesArr.componentTips && rulesArr.componentTips.isShow"
    >
      <p class="component-tips-title">提示：</p>
      <p style="line-height: 20px; margin-top: 8px">
        1、使用该组件前，需<a
          class="link-text-type cursor-pointer"
          @click="jumpWechatPlate"
          >前往微信小程序后台</a
        >，{{ rulesArr.componentTips.TipsArr[0] }}
      </p>
      <div
        style="line-height: 20px; margin-top: 8px"
        v-for="(text, index) in tipList"
        :key="index"
      >
        <p v-if="typeof text === 'string'">{{ text }}</p>
        <div v-else>
          <p>{{ text[0] }}</p>
          <p
            style="line-height: 20px; margin-top: 8px; margin-left: 15px"
            v-for="(childText, i) in text.filter(
              (childEl, childElIndex) => childElIndex > 0
            )"
            :key="i"
          >
            {{ childText }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const rulesArr = require('../../assets/jsons/form-create.json')
import update from '../../assets/js/update'
import formComponents from '../../assets/js/form-component'
import formCreate from '@form-create/element-ui'
import { mapState } from 'vuex'

export default {
  name: 'StFormEditor',
  components: {
    formCreate: formCreate.$form(),
  },
  model: {
    prop: 'value',
    event: 'updateValue',
  },
  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
    // example: "st-page-set"
    type: {
      type: String,
    },
    selected: [Number, String],
  },
  data() {
    return {
      fApiArr: [],
      isH5: this.$route.params.platform === 'h5',
      options: {
        // 整体表单样式规则
        form: {
          size: 'small',
          labelWidth: '96px',
          labelPosition: 'left',
        },
        submitBtn: {
          show: false,
        },
        mounted: (fApi) => {
          fApi.rule.forEach((item, i) => {
            if (item.update) {
              /**
               * update v2.5.0+
               * 当value或link中配置的field的value发生变化时会触发update回调,
               * 可以在update回调中做一些关于value的联动处理
               */
              const updateKey = item.update
              /**
               * 是否立即触发 update 回调，
               * 常用于初始化设置，
               * 对于不需要初始化设置的属性，可以设置为 false 忽略
               * @type {boolean}
               */
              let immediateUpdate = true
              if (typeof updateKey !== 'function') {
                // console.log(this.type, updateKey, item.field)
                item.update = update[this.type][updateKey][item.field](this)
              }

              /* 这里执行是初始化 update */
              item.update(item.value, item, fApi, immediateUpdate)
              // console.log('update', item.value, this.type, updateKey, item.field)

              this.handleIsUnitChange(this.isUnit)
            }
          })
        },
        onSubmit: (formData) => {
          console.log(formData)
        },
      },
      baseUrl: window.serverConfig.VUE_APP_ADMINURL,
      // 为了统一配置的中文名称叫法，同时也可以少写一些配置咯，2024-09-28 18:15:15
      keyTitle: {
        normal: '常规设置'
      },
      // collapse-item 设置项排序依据，如运用 flex, 则 order数值越小，越靠前
      keyDict: [
        'normal', // 常规设置
        'shadow', // 阴影设置，目前只有一个自由容器拥有该设置组
        'templateData', // 模板设置
        'listTitle',
        'listStyle', // 列表样式，目前只有文章列表组件
        'otherConfig', // 其他设置，目前有主题、组织架构展示、会员主页3个地方
        'dataConfig', // 经常跟在 templateData 后面
        'loadingConfig', // 经常跟在 dataConfig 后面
        'header',
        'searchConfig',
        'tabs',
'showMore',
          'allConfig',

          'nearbyConfig',
          'sameCityConfig',

        'titleConfig', // 应该是顶部栏模板用的
        'iconConfig',
        'selectConfig',

        'contactsConfig',
        'mapConfig',
        'buttonConfig',

        'contentConfig',
        'contentConfigTemplate7',
        'upContentConfig',
        'sortConfig',
        'searchConfigTemplate1',
        'bannerSearchConfig',
        'messageConfig',
        'issueConfig',
        'serviceBtn',
        'consultBtn',
        'showConfig',
        'requestJoin',
        'gov',
        'shareConfig',
        'share',
      ],
      rulesArr: null,
      loading: false,
      activeNames: [],

      // 特殊组件字段
      activityStatus: 0,
    }
  },
  computed: {
    myValue: {
      get() {
        return this.value || {}
      },
      set(val) {
        this.$emit('updateValue', val)
      },
    },
    tipList() {
      return this.rulesArr.componentTips.TipsArr.filter(
        (el, elIndex) => elIndex > 0
      )
    },
   
    hasIsShow() {
      const keys = Object.keys(this.myValue)
      let hasIsShow = false
      if (
        !this.rulesArr ||
        (Array.isArray(this.rulesArr) && this.rulesArr.length === 0) ||
        !(keys && keys.length)
      )
        return hasIsShow
      for (let k = 0; k < keys.length; k++) {
        /* 这里的 key 比如 normal、share、template等等 */
        if (this.rulesArr[keys[k]] && this.rulesArr[keys[k]].isShow !== 0) {
          if (
            this.rulesArr[keys[k]].rules.filter((r) => r.display !== false)
              .length
          ) {
            hasIsShow = true
            break
          }
        }
      }
      return hasIsShow
    },
    ...mapState('appDesign', ['isUnit']),
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if (this.type === 'st-article-detail') {
          // console.log("st-article-detail", this.rulesArr);
        }
      },
      deep: true,
    },
    isUnit(val) {
      this.handleIsUnitChange(val)
    },
    type: {
      handler(val) {
        // console.log(this.myValue);
        if (!val) return []
        // 默认展开所有折叠面板
        let arrNames = []
        Object.keys(this.myValue).forEach((key) => {
          // console.log(key);
          arrNames.push(`${key}`)
        })
        this.activeNames = arrNames
        // console.log(this.activeNames);
        /* form-create.json 里面有规则就直接用，新的做法是扫描 components 目录下的组件文件 */
        if (rulesArr[val] !== undefined) {
          this.rulesArr = JSON.parse(JSON.stringify(rulesArr[val]))
          return
        }
        try {
          const file = require('@/modules/app-design/components/Preview/' +
            this.type +
            '/form-create.js')
          const formCreator = file.default || file

          if (formCreator.beforeCreate !== undefined) {
            this.loading = true
            formCreator.beforeCreate((_someData) => {
              this.someData = _someData
              this.rulesArr = formCreator.getRules(
                () => this,
                () => this.fApiArr,
                this.$route.params.platform,
                this.someData || {}
              )
              this.loading = false
            })
          } else {
            this.rulesArr = formCreator.getRules(
              () => this,
              () => this.fApiArr,
              this.$route.params.platform,
              this.someData || {}
            )
          }
        } catch (err) {
          console.log(err)
          this.rulesArr = null
          this.loading = false
        }
      },
      immediate: true,
    },
    activityStatus(val) {
      if (this.type === 'st-activity-join') {
        const keys = Object.keys(this.myValue)

        for (let k = 0; k < keys.length; k++) {
          /* 这里的 key 比如 normal、share、template等等 */
          if (this.rulesArr[keys[k]] && keys[k] === 'showConfig') {
            this.rulesArr[keys[k]].isShow = val === 0 ? 1 : 0
            continue
          }
          if (this.rulesArr[keys[k]] && keys[k] === 'messageConfig') {
            this.rulesArr[keys[k]].isShow = val === 1 ? 1 : 0
          }
        }

        this.$emit('form-editor-update', this.rulesArr)
      }
    },
  },
  created() {
    // 创建自定义表单组件实例
    Object.keys(formComponents).forEach((key) => {
      formCreate.component(formComponents[key])
    })
    this.fApiArr = Array.apply({}, { length: Object.keys(this.myValue) })
    // Object.keys(this.myValue).forEach((key) => {
    //   console.log(key)
    //   this.activeNames.push(`${key}`);

    // });
    // console.log(this.activeNames)
  },
  destroyed() {
    // if (this.type === "st-custom-header") {
    //   console.log("StFormEditor destroyed");
    //   // this.fApi.destroy()
    // }
  },
  methods: {
    /**
     * 一次性展开/收起的
     * @param collapse
     */
    toggleExpand(collapse = false) {
      if (collapse) {
        // 一键收起
        this.activeNames = []
      } else {
        // 默认展开所有折叠面板
        let arrNames = []
        Object.keys(this.myValue).forEach((key) => {
          // console.log(key);
          arrNames.push(`${key}`)
        })
        this.activeNames = arrNames
      }
    },
     // 过滤掉在h5不用显示的配置
     isH5FilterShare(key) {
      // 分享配置在h5不用显示
      if (this.isH5 && key === 'share') {
        return false
      } else {
        return true
      }
    },
    handleIsUnitChange(val) {
      if (this.type === 'st-electron-card') {
        Object.keys(this.myValue).forEach((el, elI) => {
          if (el === 'contentConfig') {
            if (this.fApiArr[elI]) {
              const rule = this.fApiArr[elI].getRule('userDataList')
              if (rule) {
                this.fApiArr[elI].updateRule('userDataList', {
                  display: val === 0,
                })
              }
              const rule2 = this.fApiArr[elI].getRule('unitDataList')
              if (rule2) {
                this.fApiArr[elI].updateRule('unitDataList', {
                  display: val === 1,
                })
              }
            }
          }
        })
      }
    },
    // 跳转微信公众平台
    jumpWechatPlate() {
      window.open('https://mp.weixin.qq.com/', '_blank')
    },
    // 表单底部页面跳转
    handleJumpPage() {
      let routerData =
        this.rulesArr.pageJump.routeName !== 'FormPage'
          ? this.$router.resolve({
              name: this.rulesArr.pageJump.routeName,
            })
          : this.$router.resolve({
              name: this.rulesArr.pageJump.routeName,
              params: {
                id: 0,
                pageset: 'user',
              },
            })
      window.open(routerData.href, '_blank')
    },
    activeChange(e, a, c) {
      console.log(e, a, c)
    },
  },
}
</script>

<style lang="scss">
.st-form-editor {
  /* 控制表单项 */
  .el-form.form-create > .el-row > .el-form-item,
  .el-form.form-create > .el-row > .el-col > .el-form-item,
  .el-form.form-create > .el-row > .native-input {
    padding: 0 $space;
  }

  /* 控制折叠面板标题 */
  .el-collapse-item__header {
    padding: 0 $space * 0.5 0 $space;
  }

  /* 重置el-button按钮样式 */
  .el-button.is-plain {
    color: $st-blue;
    border-color: $st-blue;
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    /*background-color: white;*/
  }

  .el-slider__runway {
    border-radius: 2px;
  }

  .el-slider__runway,
  .el-slider__bar {
    height: 4px;
  }

  .el-slider__runway.show-input {
    width: calc(100% - 146px);
    margin-right: 146px;
  }

  .el-slider__button-wrapper {
    top: -16px;
  }

  .el-slider__button {
    border-color: white;
    box-shadow: 0 0 8px 0 rgba(100, 101, 102, 0.12);
  }

  .el-checkbox__input.is-disabled {
    .el-checkbox__inner {
      background-color: #c0c4cc;
      border-color: #c0c4cc;

      &::after {
        border-color: white;
      }
    }
  }

  /*.el-form-item--mini.el-form-item,*/
  /*.el-form-item--small.el-form-item {*/
  /*  margin-bottom: 24px;*/
  /*}*/

  .el-row {
    & > .el-form-item {
      margin-bottom: 24px;
    }
  }

  .el-col {
    float: unset;

    & > .el-form-item {
      margin-bottom: 24px;

      .el-form-item + .el-form-item {
        margin-top: 24px;
      }
    }
  }

  .el-form-item--small .el-form-item__label {
    line-height: 30px;
  }

  .el-form-item__content {
    & > .el-cascader,
    & > .el-input,
    & > .el-select {
      width: 100%;
      float: right;
    }
    & > .el-checkbox {
      float: right;
    }
  }

  .el-input--small {
    line-height: 30px;

    .el-input__inner {
      height: 30px;
    }
  }

  .el-radio-button--small .el-radio-button__inner {
    padding: 8px 13px;
    min-width: 48px;
  }

  .el-input-number--small {
    line-height: 28px;
  }

  .el-color-picker--small {
    height: 30px;

    .el-color-picker__trigger {
      border-color: #dcdfe6;
      border-radius: 2px;
      padding: 2px;
    }

    .el-color-picker__color {
      border-color: #dcdfe6;
      border-radius: 0;
    }
  }
}

// 组件配置不可编辑缺省页样式
.st-form-editor-disable {
  width: 100%;
  height: calc(100vh - 284px);
  display: flex;
  justify-content: center;
  align-items: center;

  .disable-tip {
    text-align: center;
    .tip-icon {
      width: 210px;
      height: 100px;
    }
    .tip-text {
      margin-top: 20px;
      font-size: 14px;
      color: #999999;
      line-height: 14px;
    }
  }
}
// 表单标题样式
.form-title {
  font-weight: bold;
  font-size: 16px;
  color: #3a3a3a;
  line-height: 16px;
  padding: 20px 0 24px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
}

// 组件说明内容样式
.explain-content {
  font-size: 14px;
  color: #3a3a3a;
  margin-bottom: 20px;
}

// 图片提示行内显示样式
.st-inline-img-info {
  color: #c0c4cc;
  position: absolute;
  bottom: 0;
  left: 70px;
  font-size: 12px;
  line-height: 17px;
}

.st-img-info {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
}

// 单图上传组件提示样式
.st-single-img-info {
  margin-top: 10px;
}

.jump-btn {
  /*position: sticky;*/
  bottom: 0;
  z-index: 2;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  .el-button {
    width: 100%;
  }
}

/* 选择公众号提示 */
.component-tips-title,
.component-tips {
  font-size: 14px;
  color: #606266;
}
.link-text-type {
  color: #3a7aff;
  text-decoration: underline;
}
</style>
<style lang="scss" scoped>
::v-deep .el-collapse {
  border-top: none;
  .el-collapse-item__header {
    height: auto;
    line-height: normal;
  }
  // 每个组件的小配置标题
  .component-title-name {
    padding: 24px 0;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    position: relative;
  }

  .component-subtitle-name {
    color: #909399;
    font-size: 12px;
    margin-top: 8px;
    /*line-height: 32px;*/
    display: inline-block;
    position: absolute;
  }
}

.activity-status-bar {
  height: 60px;
  border-bottom: 1px solid #ebeef5;
  padding-top: 24px;

  .activity-status {
    padding: 0 45px 12px;
    color: #c0c4cc;
    position: relative;
    cursor: pointer;

    &.active {
      color: $primary;
      cursor: not-allowed;

      &::after {
        content: '';
        position: absolute;
        width: 18px;
        height: 0;
        border: 2px solid $primary;
        transform: translateX(-50%);
        left: 50%;
        bottom: -2px;
      }
    }
  }
}

.el-collapse-box {
  /*margin: 0 16px 0 20px;*/

  & + .jump-btn {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - #{$space * 2});
  }
}

.st-form-editor .jump-btn {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - #{$space * 2});
}
</style>
