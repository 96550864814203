var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"st-banner",style:([
    _vm.myRootStyle,
    {
      '--empty-icon-bg': _vm.defaultIconBgColor,
      backgroundColor: !_vm.myConfig.templateData.relative
        ? _vm.myConfig.normal.bgType === 'color'
          ? _vm.myConfig.normal.bgColor
          : ''
        : 'unset',
    } ])},[(
      ['template1'].includes(_vm.myConfig.templateData.styleType) &&
      !_vm.speedChanging
    )?_c('div',{staticClass:"swiper-one",class:[_vm.myConfig.templateData.styleType]},[_c('swiper',{ref:"mySwiper",staticClass:"banner-swiper",attrs:{"options":_vm.optionsNoLoop}},_vm._l((_vm.myList),function(item,index){return _c('swiper-slide',{key:item.id,staticClass:"swiper-no-swiping",class:_vm.active === index ? 'banner active' : 'banner'},[(!_vm.myConfig.templateData.relative)?_c('img',{staticClass:"banner-image",style:({
            boxShadow: _vm.myConfig.templateData.shadowVague
              ? ("0 2px " + (_vm.myConfig.templateData.shadowVague) + "px 0 rgba(122, 122, 122, 0.5)")
              : 'none',
            borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
            objectFit: (
              item.banner_type === 3 ? item.video.thumbnail : item.image
            )
              ? 'cover'
              : 'none',
            backgroundColor: (
              item.banner_type === 3 ? item.video.thumbnail : item.image
            )
              ? ''
              : 'var(--empty-icon-bg)',
          }),attrs:{"src":_vm.fixSpecialImageUrl(
              item.banner_type === 3 ? item.video.thumbnail : item.image,
              'large',
              index
            ),"data-index":index}}):_c('div',{staticClass:"banner-image",staticStyle:{"background-size":"375px"},style:({
            backgroundPosition: ("center calc(-" + (20 + 44) + "px - 10px)"),
            backgroundImage: ("url(" + ((item.banner_type === 3 ? item.video.thumbnail : item.image) ||
              'var(--empty-icon-bg)') + ")"),
            boxShadow: _vm.myConfig.templateData.shadowVague
              ? ("0 2px " + (_vm.myConfig.templateData.shadowVague) + "px 0 rgba(122, 122, 122, 0.5)")
              : 'none',
            borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
          })}),_c('div',{staticClass:"banner-mask",style:({
            borderRadius: ("0 0 " + (_vm.myConfig.templateData.borderRadius) + "px " + (_vm.myConfig.templateData.borderRadius) + "px"),
          }),attrs:{"data-index":index}},[_c('p',{staticClass:"banner-name"},[_vm._v(_vm._s(item.cover_title))])])])}),1)],1):_vm._e(),(
      _vm.myConfig.templateData.styleType === 'template2' &&
      _vm.myList.length &&
      !_vm.speedChanging
    )?_c('div',{staticClass:"swiper-template2",style:({
      padding:
        _vm.myConfig.templateData.playType === 'type2'
          ? ("0 " + (_vm.myConfig.templateData.rowPadding) + "px")
          : '',
    })},[_c('swiper',{ref:"mySwiper",staticClass:"swiper-two",attrs:{"options":_vm.options}},_vm._l((_vm.myList),function(item,index){return (_vm.myList.length)?_c('swiper-slide',{key:item.id,staticClass:"swiper-item",style:({
          zIndex: _vm.active === index ? 1 : 0,
        })},[_c('div',{staticClass:"item-box",staticStyle:{"height":"100%"},style:({
            padding:
              _vm.myConfig.templateData.playType === 'type1'
                ? ("0 " + (_vm.myConfig.templateData.rowPadding) + "px")
                : '',
          })},[_c('div',{staticClass:"item-content",style:({
              boxShadow: _vm.myConfig.templateData.shadowVague
                ? ("0 2px " + (_vm.myConfig.templateData.shadowVague) + "px 0 rgba(122, 122, 122, 0.5)")
                : 'none',
              borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
            })},[_c('el-image',{staticClass:"advert-img",attrs:{"src":_vm.fixSpecialImageUrl(
                  item.banner_type === 3 ? item.video.thumbnail : item.image,
                  'large',
                  index
                ),"fit":(item.banner_type === 3 ? item.video.thumbnail : item.image)
                  ? 'cover'
                  : 'none'}}),(item.cover_title)?_c('div',{staticClass:"title-cover"},[_vm._v(" "+_vm._s(item.cover_title)+" ")]):_vm._e()],1)])]):_vm._e()}),1),(_vm.myList.length !== 1)?_c('div',{staticClass:"dots-area",style:({
        right:
          _vm.myConfig.templateData.indicatorType === 'template1'
            ? _vm.myConfig.templateData.rowPadding + 'px'
            : 0,
      })},[_c('div',{class:['swiper-dots', _vm.myConfig.templateData.indicatorType],style:({
          right: 12 + 'px',
        })},_vm._l((_vm.myList.length ? _vm.myList.length : 4),function(i){return _c('div',{key:i,class:['item-dot', i === _vm.active + 1 ? 'active' : '']})}),0)]):_vm._e()],1):_vm._e(),(
      ['template3'].includes(_vm.myConfig.templateData.styleType) &&
      !_vm.speedChanging
    )?_c('div',{staticClass:"swiper-one",class:[_vm.myConfig.templateData.styleType],staticStyle:{"overflow":"hidden"}},[_c('swiper',{ref:"mySwiper",staticClass:"banner-swiper",staticStyle:{"height":"232px","overflow":"visible"},attrs:{"options":_vm.optionsNoLoop}},_vm._l((_vm.myList),function(item,index){return _c('swiper-slide',{key:item.id,staticClass:"swiper-no-swiping",class:_vm.active === index ? 'banner active' : 'banner',staticStyle:{"width":"100%"}},[(!_vm.myConfig.templateData.relative)?_c('img',{staticClass:"banner-image",style:({
            boxShadow: _vm.myConfig.templateData.shadowVague
              ? ("0 2px " + (_vm.myConfig.templateData.shadowVague) + "px 0 rgba(122, 122, 122, 0.5)")
              : 'none',
            borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
            objectFit: (
              item.banner_type === 3 ? item.video.thumbnail : item.image
            )
              ? 'cover'
              : 'none',
            backgroundColor: (
              item.banner_type === 3 ? item.video.thumbnail : item.image
            )
              ? ''
              : 'var(--empty-icon-bg)',
          }),attrs:{"src":_vm.fixSpecialImageUrl(
              item.banner_type === 3 ? item.video.thumbnail : item.image,
              'large',
              index
            ),"data-index":index}}):_c('div',{staticClass:"banner-image",staticStyle:{"background-size":"375px"},style:({
            backgroundPosition: ("center calc(-" + (20 + 44) + "px - 10px)"),
            backgroundImage: ("url(" + ((item.banner_type === 3 ? item.video.thumbnail : item.image) ||
              'var(--empty-icon-bg)') + ")"),
            boxShadow: _vm.myConfig.templateData.shadowVague
              ? ("0 2px " + (_vm.myConfig.templateData.shadowVague) + "px 0 rgba(122, 122, 122, 0.5)")
              : 'none',
            borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
          })})])}),1),_c('div',{style:({
        borderRadius: ("0 0 " + (_vm.myConfig.templateData.borderRadius) + "px " + (_vm.myConfig.templateData.borderRadius) + "px"),
      })},[(_vm.myList[_vm.active])?_c('p',{staticClass:"active-name"},[_vm._v(" "+_vm._s(_vm.myList[_vm.active].cover_title)+" ")]):_vm._e()]),_c('div',{staticClass:"active-box"},[_c('img',{attrs:{"src":require("../../assets/images/st-banner/line.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.active >= 9 ? '' : '0')+_vm._s(_vm.active + 1)+" ")])],1):_vm._e(),(['template4'].includes(_vm.myConfig.templateData.styleType))?_c('swiper',{ref:"mySwiper",staticClass:"w-full",class:[_vm.myConfig.templateData.styleType],style:({
      height: ("calc(142px + " + (_vm.myConfig.templateData.shadowVague * 2) + "px)"),
    }),attrs:{"options":_vm.options}},_vm._l((_vm.myList),function(item,index){return _c('swiper-slide',{key:index,staticClass:"flex-align-center"},[_c('img',{staticClass:"banner",style:({
          borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
          boxShadow: _vm.myConfig.templateData.shadowVague
            ? ("0 2px " + (_vm.myConfig.templateData.shadowVague) + "px 0 rgba(122, 122, 122, 0.5)")
            : 'none',
          // marginLeft: `${myConfig.templateData.rowPadding}px`,
          // marginRight: `${myConfig.templateData.rowPadding}px`,
          width: ("calc(100% - " + (_vm.myConfig.templateData.rowPadding * 2) + "px)"),
        }),attrs:{"src":item.banner}})])}),1):_vm._e(),(['template4'].includes(_vm.myConfig.templateData.styleType))?[_c('div',{staticClass:"pagination flex-center",class:[_vm.myConfig.templateData.styleType],style:({
        right: ((10 + _vm.myConfig.templateData.rowPadding) + "px")
      })},_vm._l((_vm.myList),function(item,index){return _c('div',{key:index,staticClass:"pagination__dot",class:{active: _vm.active === index || (isNaN(_vm.active) && index === 0)}})}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }