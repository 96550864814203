<template>
  <div v-if="myConfig.normal.isShow" class="st-internet-content-provider">
    <p class="content">{{myConfig.normal.text}}</p>
  </div>
</template>

<script>
export default {
  name: "StInternetContentProvider",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          text: '',
          isShow: 0
        }
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.st-internet-content-provider {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc((667 - 20 - 44 - 71) * 1px);

  .content {
    white-space: pre-wrap;
    text-align: center;
    font-size: 12px;
    color: #999;
    font-weight: normal;
  }
}
</style>
