<template>
  <div class="st-analysis-member st-analysis-item">
    <img class="overview" :src="`${baseImgUrl}/app_design/assets/images/st-analysis-page/history-fee.png`" />
  </div>
</template>

<script>
export default {
  name: "StAnalysisHistoryFee",
  props: {
    config: Object
  },
  data() {
    return {
      baseImgUrl: '',
    }
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
}
</script>

<style lang="scss" scoped>
  .st-analysis-item {
    .overview {
      border-radius: 6px;
      overflow: hidden;
      width: 343px;
      margin: 0 auto 8px;
      display: block;
    }
  }
</style>
