<template>
  <div>
    <el-popover
      v-if="iconList.length"
      v-model="isShowTemplateIcons"
      placement="bottom-start"
      title=""
      :visible-arrow="false"
      popper-class="template-icon"
      trigger="click"
      @click.stop
    >
      <template #reference>
        <div class="icon-selector">
          <add-button v-if="!myIcon" :width="60" :height="60" text="">
            <p slot="info" class="img-info">
              建议图标尺寸{{ suggestSize.width }}*{{
                suggestSize.width
              }}图片格式为PNG
            </p></add-button
          >
          <SingleMediaWall
            v-else
            v-model="myIcon"
            :width="60"
            :height="60"
            :ratio="`${suggestSize.width}:${suggestSize.width}`"
            :showInline="true"
            addText=""
          >
            <p slot="info" class="img-info">
              建议图标尺寸{{ suggestSize.width }}*{{
                suggestSize.width
              }}图片格式为PNG
            </p>
          </SingleMediaWall>
        </div>
      </template>
      <div class="icon-grid">
        <div
          @click="myIcon = icon"
          class="icon-grid-item cursor-pointer flex"
          v-for="icon in iconList"
          :key="icon"
        >
          <el-image class="flex-1" :src="icon"></el-image>
        </div>
        <div class="icon-grid-item cursor-pointer no-hover">
          <single-media-wall
            :ratio="`${suggestSize.width}:${suggestSize.width}`"
            :showInline="true"
            :border-radius="4"
            :width="52"
            :height="52"
            add-text=""
            @handleMediaData="myIcon = $event"
            ><add-button text="" :width="40" :height="40"
          /></single-media-wall>
        </div>
      </div>
    </el-popover>
    <SingleMediaWall
      v-else
      v-model="myIcon"
      :width="60"
      :height="60"
      :ratio="`${suggestSize.width}:${suggestSize.width}`"
      :showInline="true"
      addText=""
    >
      <p slot="info" class="img-info">
        建议图标尺寸{{ suggestSize.width }}*{{ suggestSize.width }}图片格式为PNG
      </p>
    </SingleMediaWall>
  </div>
</template>

<script>
import AddButton from '@/base/components/Base/AddButton'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
export default {
  components: {
    AddButton,
    SingleMediaWall,
  },
  props: {
    iconList: {
      type: Array,
      default: () => {
        return []
      },
    },
    suggestSize: {
      type: Object,
    },
    icon: String,
  },
  data() {
    return {
      isShowTemplateIcons: false,
    }
  },
  computed: {
    myIcon: {
      get() {
        return this.$props.icon
      },
      set(newValue) {
        this.$emit('update:icon', newValue)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.img-info {
  color: #c0c4cc;
  position: absolute;
  bottom: 0;
  left: 150px;
  font-size: 12px;
  line-height: 17px;
}
.icon-grid {
  display: grid;
  grid-template-columns: repeat(5, 52px);
  grid-template-rows: repeat(auto-fit, 52px);
  grid-gap: 10px;

  .icon-grid-item {
    padding: 8px;

    &.no-hover {
      padding: 0;
    }

    &:not(.no-hover):hover {
      background-color: $borderLighter;
      border-radius: 4px;
    }
  }

  ::v-deep.el-image__error {
    background-color: unset;
    font-size: 10px;
    width: 100%;
    height: 100%;
  }
}
</style>
