<!--
 * @Author: dongjia
 * @Date: 2022-01-20 17:20:04
 * @LastEditTime: 2022-02-19 15:57:53
 * @LastEditors: aleaner
 * @Description: 列表标题模块
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\Common\StListTitle.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-title" v-if="myConfig.listTitle && myConfig.listTitle.isShow">
    <div :class="{'template2': myConfig.listTitle.showType === 'template2'}" :style="{
        fontSize: myConfig.listTitle.fontSize + 'px',
        fontWeight: myConfig.listTitle.fontWeight,
        color: myConfig.listTitle.titleColor,
      }">{{myConfig.listTitle.title?myConfig.listTitle.title:'标题名称'}}</div>
    <div class="check-more" v-if="myConfig.showConfig && myConfig.showConfig.isShow" :style="{
      color: myConfig.showConfig.titleColor || '#999999',
    }"><span
        v-if="myConfig.showConfig.showType !== 'template3'">{{myConfig.showConfig.title?myConfig.showConfig.title:'查看更多'}}</span><span
        v-if="myConfig.showConfig.showType !== 'template2'" class="el-icon-arrow-right"></span></div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  computed:{
    myConfig(){
      // 兼容其他更多配置
      if(this.config.showMore) {
        this.config.showConfig = this.config.showMore;
      }
      return this.config;
    }
  }
}

</script>

<style lang="scss" scoped>
.list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  .template2 {
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 4px;
      height: 14px;
      margin-right: 8px;
      background-color: var(--main-color);
    }
  }
  .check-more {
    font-size: 12px;
  }
}
</style>
