<template>
  <div class="join-organize flex-col">
    <template v-if="myConfig.templateData.styleType === 'template1'">
    <div
      v-if="showNoteMask"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #333c50;
        font-size: 14px;
        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <div style="background: #fff; padding: 20px; margin: 35px; height: 312px; overflow: auto; width: calc(100% - 70px)">
        {{ myConfig.noteConfig.note }}
      </div>
      <img
        style="width: 16px; height: 16px; cursor: pointer"
        src="../../assets/images/close-btn.png"
        showNoteMask="true"
        @click="showNoteMask = false"
      />
    </div>
    <st-page-set :title="handleSaaSVersionKeyword('入会申请')" bgColor="white" :jump-config="jumpConfig"></st-page-set>

    <div class="organize-content">
      <div
        style="
          background: #fff;
          border-radius: 4px;
          padding: 15px;
          margin-bottom: 10px;
          font-size: 12px;
          color: #888;
          overflow: hidden;
        "
        v-if="myConfig.noteConfig.note !== ''"
      >
        <div class="wrapper">
          <input id="exp1" class="exp" type="checkbox" />
          <div class="text">
            <label class="btn" for="exp1" @click="showNoteMask = true" v-if="myConfig.noteConfig.note.length > 70"></label>
            {{ "备注：" + myConfig.noteConfig.note }}
          </div>
        </div>

        <!-- <span style="font-weight: 600; cursor: pointer" @click="showNoteMask = true">展开</span> -->
      </div>
      <div class="list-item" v-for="(item, index) in list.length ? list : defaultList" :key="index">
        <div class="item-top">
          <el-image
            class="top-left"
            :src="item.icon ? item.icon : require('../../assets/images/default-icon.png')"
            fit="cover"
          ></el-image>
          <div class="top-right">
            <p class="top-text">{{ item.name }}</p>
            <p class="bottom-text">{{ item.description }}</p>
          </div>
        </div>
        <div class="item-bottom">
          <div class="bottom-text">
            <p>立即加入</p>
            <p class="el-icon-arrow-right"></p>
          </div>
        </div>
      </div>
    </div>
    </template>
    <template v-else>
      <img v-if="myConfig.templateData.size === 'small'" class="screenshot" :src="`${baseImgUrl}/app_design/assets/images/st-join-organize/small.png`" />
      <img v-else class="screenshot" :src="screenshot" />
    </template>
  </div>
</template>

<script>
import { organizationList } from "../../api/components";
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";

export default {
  name: "StJoinOrganize",
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    jumpConfig: Object,
  },
  data() {
    return {
      baseImgUrl:'',
      showNoteMask: false,
      defaultList: require("../../assets/jsons/default-list.json").organizationList,
      list: [],
      platform: this.$route.params.platform,
      screenshot:
          window.serverConfig.VUE_APP_ADMINURL +
          '/app_design/images/large.png',
    };
  },
  created() {
    this.getOrganizationList();
    console.log("myConfig: ", this.myConfig);
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL

  },
  computed: {
    myConfig() {
      return this.$mergeModel(require('../../assets/jsons/component-library.json').system.joinOrganize.props, this.config);
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  methods: {
    getOrganizationList() {
      organizationList()
        .then((res) => {
          this.list = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.join-organize {
  width: 375px;
  min-height: 661px;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;

  .start-top-img {
    width: 375px;
    height: 65px;
    background-color: white;
  }

  .page-title {
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -36px;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
}

.organize-content {
  width: 100%;
  min-height: 597px;
  padding: 18px;
  .list-item + .list-item {
    margin-top: 10px;
  }
  .list-item {
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    .item-top {
      padding: 24px 22px;
      display: flex;
      align-items: center;
      .top-left {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
      .top-right {
        .top-text {
          font-size: 16px;
          font-weight: bold;
          color: #272f3c;
          line-height: 16px;
        }
        .bottom-text {
          font-size: 12px;
          color: #979da6;
          line-height: 13px;
          margin-top: 7px;
        }
      }
    }

    .item-bottom {
      background-color: #b7bec8;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 8px 8px;
      .bottom-text {
        display: flex;
        font-size: 14px;
        color: #ffffff;
        line-height: 14px;
        p + p {
          margin-left: 4px;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  overflow: hidden;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
  -webkit-line-clamp: 3; //下多行文字溢出点点点...
  -webkit-box-orient: vertical; //设置或检索伸缩盒对象的子元素的排列方式
  position: relative;
}
.text::before {
  content: "";
  height: calc(100% - 14px);
  float: right;
}
.btn {
  float: right;
  clear: both;
  padding-left: 12px;
  cursor: pointer;
  /* margin-top: -30px; */
  font-size: 12px;
}
.btn::before {
  content: "展开";
  font-size: 12px;
  font-weight: 600;
}
.exp {
  display: none;
}
// .exp:checked + .text {
//   -webkit-line-clamp: 999;
// }
// .exp:checked + .text .btn::before {
//   content: "收起";
// }

.screenshot {
  width: 100%;
}
</style>
