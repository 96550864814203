var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:({
    padding: ("12px " + (_vm.myStyleType === 'template3' ? '16' : '0') + "px"),
    margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage: _vm.myBgImage,
    '--dataSpacing': ((_vm.myConfig.templateData.dataSpacing) + "px"),
  })},[_c('StListTitle',{style:({
      padding: ("0 " + (_vm.myStyleType !== 'template3' ? '16' : '0') + "px 16px"),
    }),attrs:{"config":_vm.myConfig}}),_c('div',{staticClass:"data-list"},[_c('div',{staticClass:"category-box"},[_c('div',{staticClass:"category-list"},[_c('div',{staticClass:"item active"},[_vm._v("全部")]),_vm._l((_vm.yearList),function(item){return _c('div',{staticClass:"item"},[_vm._v(_vm._s(item))])})],2)]),(_vm.myConfig.templateData.styleType === 'template1')?[_c('div',{staticClass:"journal-list-template1"},_vm._l((_vm.template1List),function(item){return _c('div',{staticClass:"journal-item-box"},_vm._l((item),function(item2){return _c('div',{staticClass:"item-box"},[(item2.cover)?_c('img',{staticClass:"cover",attrs:{"src":item2.cover}}):_c('div',{staticClass:"cover"}),_c('div',{staticClass:"right-box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item2.name))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item2.desc))]),_c('div',{staticClass:"tigs-box"},[_c('div',{staticClass:"tigs"},[_vm._v(_vm._s(item2.year))])])])])}),0)}),0)]:_vm._e(),(_vm.myConfig.templateData.styleType === 'template2')?[_c('div',{staticClass:"journal-list-template2"},_vm._l((_vm.dataList),function(item){return _c('div',{staticClass:"journal-item-box"},[_c('div',{staticClass:"cover-box",style:({
              paddingTop: "calc(100% * 1.3)",
              borderRadius: ((_vm.myConfig.templateData.borderRadius) + "px"),
            })},[(item.cover)?_c('img',{staticClass:"cover",attrs:{"src":item.cover}}):_c('div',{staticClass:"cover",attrs:{"src":item.cover}})]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))]),_c('div',{staticClass:"tigs-box"},[_c('div',{staticClass:"tigs"},[_vm._v(_vm._s(item.year))])])])}),0)]:_vm._e()],2),(_vm.myLoadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myLoadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myLoadingConfig.handleText)+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }