<template>
  <div class="flex-between flex-align-center">
    <label class="el-form-item__label">展示顺序</label>
    <div class="">
<!--      <el-radio-group class="flex-col" v-model="myValue">-->
<!--        <el-radio label="level">按等级配置排序</el-radio>-->
<!--        <el-radio label="join_time">按入会时间排序</el-radio>-->
<!--      </el-radio-group>-->
      <el-checkbox value="level" true-label="level" false-label="" :disabled="true">按等级配置排序</el-checkbox>
      <el-button type="text" size="medium" @click="jumpTo">查看等级配置</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "StMemberCardPositionOrderRadio",
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: ['value'],
  computed: {
    myValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("updateValue", val);
      }
    }
  },
  methods: {
    jumpTo() {
      let routerData = this.$router.resolve({
        name: 'LevelConfig'
      });
      window.open(routerData.href, "_blank");
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item__label {
    float: none;
  }

  .el-button {
    /*padding: 0;*/
    margin-left: 20px;
  }

</style>
