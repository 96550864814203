var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:([_vm.myRootStyle, {
      minHeight: _vm.from === 'systemPage'?'564px':'100px'
  }])},[(_vm.from === 'components')?_c('StListTitle',{attrs:{"config":_vm.myConfig}}):_vm._e(),(_vm.myConfig.templateData.styleType === 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template1",style:({
      'grid-column-gap': _vm.myConfig.templateData.dataSpacing + 'px',
      'grid-row-gap': _vm.myConfig.templateData.dataSpacing + 'px'
    })},_vm._l((_vm.photoList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
    })},[_c('div',{staticClass:"list-photo"},[_c('st-load-image',{staticClass:"photo-img",attrs:{"src":item.cover,"size":200}})],1),_c('div',{staticClass:"photo-title"},[_c('p',[_vm._v(_vm._s(item.activity_name))])])])}),0):_vm._e(),(_vm.myConfig.templateData.styleType === 'template2')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template2"},_vm._l((_vm.photoList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginTop: index !== 0 ? _vm.myConfig.templateData.dataSpacing + 'px': '0',
    })},[_c('div',{staticClass:"photo-list"},_vm._l((2),function(num){return _c('div',{key:num,staticClass:"photo-item",style:({
          position: num === 1 ? 'relative':'absolute',
          width: num === 1 ? 'calc(100% - 10px)': 'calc(100% - 44px)',
          borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
          left: num === 1 ? '0px': '45px',
          zIndex: 3-num,
          backgroundColor: num === 1 ? 'rgb(244, 248, 257)' : '#f2f3f5'
        })},[(num === 1 && item.cover)?_c('el-image',{staticClass:"photo-img",attrs:{"src":_vm.fixSpecialImageUrl(item.cover, 'large', index, num),"fit":"cover"}}):(num === 2 && item.photo.length)?_c('el-image',{staticClass:"photo-img",attrs:{"src":_vm.fixSpecialImageUrl(item.photo[0].res.url, 'medium', index, num),"fit":"cover"}}):_c('el-image',{staticClass:"default-photo",attrs:{"src":require('../../assets/images/st-activity-photo/default-photo-icon.png')}}),(num === 1)?_c('div',{staticClass:"cover-title"},[_c('p',{staticClass:"photo-title"},[_vm._v(_vm._s(item.activity_name))])]):_vm._e()],1)}),0)])}),0):_vm._e(),(_vm.myConfig.templateData.styleType === 'template3')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template3"},_vm._l((_vm.photoList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginTop: index !== 0 ? _vm.myConfig.templateData.dataSpacing + 'px': '0',
      borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
      boxShadow: _vm.myConfig.templateData.shadowVague?("0px " + (_vm.myConfig.templateData.shadowVague) + "px 10px 0px #F2F2F2"):''
    })},[_c('p',{staticClass:"photo-title"},[_vm._v(_vm._s(item.activity_name))]),(_vm.myConfig.templateData.isShowMaterial || _vm.myConfig.templateData.isShowViewNum)?_c('div',{staticClass:"photo-data"},[(_vm.myConfig.templateData.isShowMaterial)?_c('div',{staticClass:"data-item"},[_c('el-image',{staticClass:"item-icon",attrs:{"src":require('../../assets/images/st-activity-photo/picture-icon.png'),"fit":"cover"}}),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s(item.photo_image_count))])],1):_vm._e(),(_vm.myConfig.templateData.isShowViewNum)?_c('div',{staticClass:"data-item"},[_c('el-image',{staticClass:"item-icon",attrs:{"src":require('../../assets/images/st-activity-photo/views-icon.png'),"fit":"cover"}}),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s(item.view_num))])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"photo-list"},_vm._l((4),function(num){return _c('div',{key:num,staticClass:"photo-item",style:({
            borderRadius:_vm.myConfig.templateData.borderRadius + 'px',
          })},[(item.photo[num-1])?_c('el-image',{staticClass:"photo-img",attrs:{"src":_vm.fixSpecialImageUrl(item.photo[num-1].res.url, '', index, num),"fit":"cover"}}):_c('el-image',{staticClass:"default-photo",attrs:{"src":require('../../assets/images/st-activity-photo/default-photo-icon.png')}})],1)}),0)])}),0):_vm._e(),(_vm.myConfig.templateData.styleType === 'template4')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view grid-two-column",style:({
      'grid-column-gap': _vm.myConfig.templateData.dataSpacing + 'px',
      'grid-row-gap': _vm.myConfig.templateData.dataSpacing + 'px'
    })},_vm._l((_vm.photoList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
      boxShadow: _vm.myConfig.templateData.shadowVague?("0px " + (_vm.myConfig.templateData.shadowVague) + "px 10px 0px #F2F2F2"):''
    })},[_c('div',{staticClass:"item-time"},[_vm._v(_vm._s(item.activity_simple_time))]),_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.activity_name))]),_c('div',{staticClass:"item-imgs",class:item.photo.length>3?'multi-img':''},[(item.photo.length<=3)?_c('div',{staticClass:"item-img-box"},[_c('st-load-image',{staticClass:"box-img",attrs:{"src":item.photo[0].res.url,"size":200,"proportion":"100%","border-radius":_vm.myConfig.templateData.borderRadius}})],1):_vm._e(),_vm._l((item.photo),function(img,i){return (item.photo.length>3)?_c('div',{key:i,staticClass:"item-img-box"},[_c('st-load-image',{staticClass:"box-img",attrs:{"src":img.res.url,"size":200,"proportion":"100%","border-radius":_vm.myConfig.templateData.borderRadius}}),(item.photo_image_count > 4 && i === 3)?_c('div',{staticClass:"img-mask",style:({
                    borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
          })},[_vm._v("+ "+_vm._s(item.photo_image_count - 4>999?999:item.photo_image_count - 4))]):_vm._e()],1):_vm._e()})],2)])}),0):_vm._e(),(_vm.myConfig.templateData.styleType === 'template5')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view single-column"},_vm._l((_vm.photoList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
      marginTop: index > 0?_vm.myConfig.templateData.dataSpacing + 'px': '',
      borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
      boxShadow: _vm.myConfig.templateData.shadowVague?("0px " + (_vm.myConfig.templateData.shadowVague) + "px 10px 0px #F2F2F2"):''
    })},[_c('div',{staticClass:"item-time"},[_vm._v(_vm._s(item.activity_simple_time))]),_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.activity_name))]),_c('div',{staticClass:"item-imgs"},[_vm._l((item.photo),function(img,i){return (item.photo.length<3)?_c('div',{key:i,staticClass:"item-img-box",style:({
            borderRadius:_vm.myConfig.templateData.borderRadius + 'px',
          })},[_c('st-load-image',{staticClass:"box-img",attrs:{"src":img.res.url,"size":200}})],1):_vm._e()}),_vm._l((3),function(num){return (item.photo.length>=3)?_c('div',{key:num,staticClass:"item-img-box",style:({
            borderRadius:_vm.myConfig.templateData.borderRadius + 'px'
          })},[_c('st-load-image',{staticClass:"box-img",attrs:{"src":item.photo[num - 1].res.url,"size":200}}),(item.photo_image_count > 3 && num === 3)?_c('div',{staticClass:"img-mask"},[_vm._v("+ "+_vm._s(item.photo_image_count - 3>999?999:item.photo_image_count - 3))]):_vm._e()],1):_vm._e()})],2)])}),0):_vm._e(),(_vm.myConfig.loadingConfig.loadingType === 'handle')?_c('div',{staticClass:"load-more"},[_c('div',{class:[_vm.myConfig.loadingConfig.handleType]},[_vm._v(" "+_vm._s(_vm.myConfig.loadingConfig.handleText))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }