<!--
 * @Author: dongjia
 * @Date: 2021-12-08 11:24:14
 * @LastEditTime: 2022-01-11 16:11:44
 * @LastEditors: aleaner
 * @Description: 关注我们组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StFollowUs.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="component-container" :style="{
    marginTop: myConfig.normal.marginTop + 'px'
  }">
    <div class="component-header"
      v-if="myConfig.normal.titleConfig.isShow && myConfig.normal.titleConfig.name">
      <div class="header-line"></div>
      <div class="header-title" :style="{
        fontSize: myConfig.normal.fontSize + 'px',
            fontWeight: myConfig.normal.fontWeight,
            color: myConfig.normal.fontColor,
      }">{{myConfig.normal.titleConfig.name}}</div>
      <div class="header-line"></div>
    </div>
    <div class="follow-accounts" v-if="accounts_result.length">
      <div class="account-item" v-for="(item,index) in accounts_result" :key="index">
        <st-load-image class="item-icon" :size="60" :src="item.icon || (accounts.find(el => el.name === item.label) && accounts.find(el => el.name === item.label).icon)" />
        <div class="item-label">{{item.label}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";

export default {
  name: "StFollowUs",
  components: {StLoadImage},
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: require('../../assets/jsons/component-library.json').custom_components
        .find(el => el.index === 'base').components.find(el => el.component === 'st-follow-us').props,
      accounts: [
        {
          name: "视频号",
          icon: require("../../assets/images/st-contact-us/video-icon.png"),
          key: "videoAccount_qrCode",
        },
        {
          name: "公众号",
          icon: require("../../assets/images/st-contact-us/wechat-icon.png"),
          key: "officialAccount_qrCode",
        },
        {
          name: "微博",
          icon: require("../../assets/images/st-contact-us/weibo-icon.png"),
          key: "weibo_qrCode",
        },
        {
          name: "抖音",
          icon: require("../../assets/images/st-contact-us/tiktok-icon.png"),
          key: "tiktok_qrCode",
        },
      ],
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    accounts_result() {
      return this.myConfig.contentConfig.list
    },
  },
}
</script>

<style lang="scss" scoped>
.component-container {
  padding: 12px 0;

  .component-header {
    padding: 0 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-line {
      flex: 1;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .header-title {
      margin: 0 22px;
      flex-shrink: 0;
    }
  }
  .follow-accounts {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .account-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .item-icon {
        width: 36px;
        height: 36px;
      }
      .item-label {
        margin-top: 8px;
        font-size: 14px;
        color: #1a1a1a;
        line-height: 1.5;
      }
    }
  }
}
</style>
