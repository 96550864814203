<template>
  <div class="activity-component">
    <img
      v-if="component === 'st-activity-description'"
      class="card"
      draggable="false"
      :src="require('../../assets/images/st-activity-detail/introduction.png')"
    />
    <img
      v-else-if="component === 'st-activity-notices'"
      class="card"
      draggable="false"
      :src="require('../../assets/images/st-activity-detail/notices.png')"
    />
    <img
      v-else-if="component === 'st-activity-addresses'"
      class="card"
      draggable="false"
      :src="require('../../assets/images/st-activity-detail/addresses.png')"
    />
    <template v-else-if="component === 'st-activity-join'">
      <!-- 报名未开始前 -->
      <img
        v-if="activityStatus === 0"
        class="card"
        draggable="false"
        :src="require('../../assets/images/st-activity-detail/join.png')"
      />
      <!-- 报名开始后 -->
      <!-- 不显示进度条，不允许查看报名人数 -->
      <img
        v-else-if="
          activityStatus === 1 &&
          myConfig.messageConfig.progressBar === 0 &&
          myConfig.messageConfig.joinedMembers === 0
        "
        class="card"
        draggable="false"
        :src="require('../../assets/images/st-activity-detail/join-number.png')"
      />
      <!-- 显示进度条，不允许查看报名人数 -->
      <img
        v-else-if="
          activityStatus === 1 &&
          myConfig.messageConfig.progressBar === 1 &&
          myConfig.messageConfig.joinedMembers === 0
        "
        class="card"
        draggable="false"
        :src="require('../../assets/images/st-activity-detail/join-number.png')"
      />
      <!-- 不显示进度条，允许查看报名人数 -->
      <!-- 默认都显示 -->
      <img
        v-else
        class="card"
        draggable="false"
        :src="require('../../assets/images/st-activity-detail/报名情况.png')"
      />
    </template>
    <img
      v-else-if="component === 'st-activity-guests'"
      class="card"
      draggable="false"
      :src="require('../../assets/images/st-activity-detail/guests.png')"
    />
    <img
      v-else-if="component === 'st-activity-units'"
      class="card"
      draggable="false"
      :src="require('../../assets/images/st-activity-detail/hosts.png')"
    />
    <img
      v-else-if="component === 'st-activity-timeline'"
      class="card"
      draggable="false"
      :src="`${baseImgUrl}/app_design/st-activity-detail/timeline.png`"
    />
    <img
      v-else-if="component === 'st-activity-posts'"
      class="card"
      draggable="false"
      :src="`${baseImgUrl}/app_design/st-activity-detail/posts.png`"
    />
    <img
      v-else-if="component === 'st-activity-plans'"
      class="card"
      draggable="false"
      :src="require('../../assets/images/st-activity-detail/plans.png')"
    />
    <!--    <img class="card" draggable="false" :src="require('../../assets/images/st-activity-detail/relatives.png')" />-->
    <div
      v-else-if="component === 'st-activity-relatives'"
      class="bg-card"
      :style="{
        backgroundImage: `url(
          '${baseImgUrl}/app_design/assets/images/st-activity-detail/relatives.png'
        )`,
      }"
    ></div>

    <!-- 挡住进度条/挡住"想参加" -->
    <div
      v-if="
        component === 'st-activity-join' &&
        rulesArr &&
        ((activityStatus === 0 && myConfig.showConfig.joinBtn === 0) ||
          (activityStatus === 1 && myConfig.messageConfig.progressBar === 0))
      "
      style="
        position: absolute;
        top: 0;
        right: 16px;
        background-color: white;
        width: calc(21px + 75px + 90px);
        height: calc(12px + 25px + 3px);
      "
    ></div>
    <!-- 补进度条 -->
    <img
      v-if="
        activityStatus === 1 &&
        myConfig.messageConfig.progressBar === 1 &&
        myConfig.messageConfig.joinedMembers === 0
      "
      style="
        position: absolute;
        top: 0;
        left: 16px;
        width: calc(100% - 16px * 2 + 1px);
      "
      :src="require('../../assets/images/st-activity-detail/显示进度.png')"
    />
    <!--    {{ rulesArr && rulesArr.showConfig ? rulesArr.showConfig.isShow : '' }}-->
    <!--    {{ rulesArr }}-->
  </div>
</template>

<script>
export default {
  name: 'StActivityComponent',
  props: {
    component: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
    },
    rulesArr: [Object, Array],
  },
  data() {
    return {
      activityStatus: 0,
      baseImgUrl: '',
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(
        {
          normal: {
            marginTop: 0,
          },
          showConfig: {
            joinBtn: 1,
          },
          messageConfig: {
            progressBar: 1,
            joinedMembers: 1,
          },
        },
        this.config
      )
    },
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
  watch: {
    'rulesArr.showConfig': {
      handler(newValue) {
        this.activityStatus = newValue?.isShow !== 0 ? 0 : 1
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.activity-component {
  position: relative;
}

.card {
  width: 343px;
  display: block;
  margin: 8px auto;
}

.bg-card {
  width: 343px + 16px;
  height: 170px;
  background-position: 0;
  background-size: auto 100%;
  margin: 8px 0 8px 16px;
  background-repeat: no-repeat;
}
</style>
