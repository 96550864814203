<!--
 * @Author: dongjia
 * @Date: 2021-09-27 11:12:52
 * @LastEditTime: 2021-09-27 11:17:35
 * @LastEditors: aleaner
 * @Description: 复选框组件
 * @FilePath: \app-design\components\Form\StCheckBox.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div>
    <el-checkbox v-model="value" :true-label="tureLabel" :false-label="falseLabel">{{checkBoxLabel}}
    </el-checkbox>
  </div>
</template>

<script>
export default {
  name: "StCheckBox",
  model: {
    prop: "data",
    event: "input",
  },
  props: {
    data: {
      type: [Array, String, Number],
    },
    tureLabel: {
      type: [String, Number],
      default: 1,
    },
    falseLabel: {
      type: [String, Number],
      default: 0,
    },
    checkBoxLabel: {
      type: String,
      default: "开启",
    },
  },
  computed: {
    value: {
      get() {
        return this.data;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>