<template>
  <div class="st-company-index flex-center">
    企业主页内容
  </div>
</template>

<script>
export default {
  name: 'StCompanyIndex',
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
.st-company-index {
  height: 667px;
}
</style>