<!--
 * @Author: dongjia
 * @Date: 2021-08-09 10:26:56
 * @LastEditTime: 2021-08-27 10:05:56
 * @LastEditors: aleaner
 * @Description: 业务组件选择业务数据列表设置
 * @FilePath: \app-design\components\Form\StDragDataList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="drag-list st-drag-list">
    <div>
      <p class="list-title">{{ title || '添加数据' }}</p>
      <p class="form-tip">拖拽调整展示顺序</p>
    </div>
    <draggable
      v-if="dataList"
      v-model="dataList"
      class="nav-list"
      animation="500"
      draggable=".nav-item"
    >
      <template>
        <div class="nav-item" v-for="(data, index) in dataList" :key="index">
          <div
            class="data-name"
            v-if="
              ['st-member-message', 'st-member-message-unit'].includes(formType)
            "
          >
            <span class="el-icon-sort my-handle cursor-pointer" />
            {{ itemTitle }}：{{ filterLabel(data) }}
          </div>
          <div class="data-name" v-else>{{ itemTitle }}：{{ data }}</div>
        </div>
      </template>
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { infoComponentSelect } from '../../api/components'

export default {
  name: 'StDragList',
  components: {
    draggable,
  },
  model: {
    prop: 'list',
    event: 'listChange',
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    formType: {
      type: String,
      default: '',
    },
    itemTitle: {
      type: String,
      default: '专题名称',
    },
    addButtonText: {
      type: String,
      default: '添加专题数据',
    },
    title: {
      type: String,
      default: '',
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    deleteText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataSelectDialog: false,
      unDraggable: false,
      visible: false,
      routerName: {
        'st-brand-list': 'editBrand',
        'st-banner': 'edit-advert',
        'st-spirit-list': 'AddSpirit',
        'st-article-list': 'EditArticle',
        'st-special-article': 'ArticleSubjectAddEdit',
        'st-activity-list': 'ActivityDetail',
        'st-shop-goods': 'WxStoreGoodsDetail',
        'st-channels-video-list': 'WxAccountVideos',
        'st-channels-live-list': 'WxAccountLive',
        'st-donation-list': 'donationProject',
        'st-business-association-list': 'BusinessAssociationDetail',
        'st-shop-goods': 'GoodsDetail',
      },
      // 对应的key 回显列表
      keyList: [],
    }
  },
  computed: {
    dataList: {
      get() {
        return this.list
      },
      set(val) {
        this.$emit('listChange', val)
      },
    },
    // 回显Label
    filterLabel: {
      get() {
        return (val) => {
          let currentLabel = ''

          this.keyList.forEach((item2) => {
            if (item2.value === val) {
              currentLabel = item2.label
            }
          })
          return currentLabel
        }
      },
    },
    jumpType() {
      return this.formType === 'st-bottom-nav'
        ? this.current_jumptype
        : this.jump_type
    },
  },
  watch: {
    dataList: {
      handler(val) {
        this.$emit('listChange', val)
      },
      deep: true,
    },
  },
  methods: {
    // 会员资料列表
    getMemberMessageList() {
      infoComponentSelect().then((res) => {
        this.keyList = res.data
      })
    },
    // 单位资料列表
    getUnitMessageList() {
      infoComponentSelect({
        pageset: 'unit',
      }).then((res) => {
        this.keyList = res.data
      })
    },
    // 点击选择数据
    dataChange(val) {
      this.dataList = val
    },
    // 编辑数据
    handleEdit(row) {
      if (['st-special-article', 'st-article-list'].includes(this.formType)) {
        let routerData = this.$router.resolve({
          name: this.routerName[this.formType],
          query: {
            id: row.id,
            portal_user_id: 0,
            come_from: 'ArticleList',
          },
        })
        window.open(routerData.href, '_blank')
      } else if (['st-business-association-list'].includes(this.formType)) {
        let routerData = this.$router.resolve({
          name: this.routerName[this.formType],
          params: { detailId: row.id, type: 'edit' },
        })
        window.open(routerData.href, '_blank')
      } else if (['st-shop-goods'].includes(this.formType)) {
        // 商城
        let routerData = this.$router.resolve({
          name: this.routerName[this.formType],
          params: { id: row.id },
        })
        window.open(routerData.href, '_blank')
      } else {
        let routerData = this.$router.resolve({
          name: this.routerName[this.formType],
          params: { id: row.id, type: 'edit' },
        })
        window.open(routerData.href, '_blank')
      }
    },
  },
  mounted() {
    if (this.formType === 'st-member-message') {
      this.getMemberMessageList()
    }
    if (this.formType === 'st-member-message-unit') {
      this.getUnitMessageList()
    }
  },
}
</script>

<style lang="scss" scoped>
.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.form-tip {
  font-size: 12px;
  color: #c0c4cc;
  padding-top: 8px;
  padding-bottom: 11px;
}
.nav-list {
  background-color: #f6f9ff;
  // margin: 0 -19px 0 -24px;
  padding: 12px;
  margin-bottom: 20px;
}
.nav-item-add {
  width: 100%;
  text-align: center;
}
.nav-item {
  width: 100%;
  padding: 8px 12px;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .my-handle {
    margin-right: 8px;
  }
  .data-name {
    flex: 1;
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 400;
    min-width: 0;
    @include nowrap();
  }
  .data-btn {
    font-size: 12px;
    font-weight: 400;
    display: flex;

    .deleteText {
      color: #ff4949ff;
      cursor: pointer;
    }
    .editText {
      color: $primary;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

.nav-item + .nav-item {
  margin-top: 12px;
}
</style>
