import { render, staticRenderFns } from "./StAnalysisGoodsRankings.vue?vue&type=template&id=272b7ed8&scoped=true&"
import script from "./StAnalysisGoodsRankings.vue?vue&type=script&lang=js&"
export * from "./StAnalysisGoodsRankings.vue?vue&type=script&lang=js&"
import style0 from "./StAnalysisGoodsRankings.vue?vue&type=style&index=0&id=272b7ed8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272b7ed8",
  null
  
)

export default component.exports