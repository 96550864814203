<template>
  <div class="st-image" :style="{
    width: styles.width + '%',
    height: myHeight,
    marginTop: styles.marginTop + 'px'
  }">
    <img v-if="config.src" class="st-image-src" :style="{
      objectFit: styles.objectFit
    }" :src="config.src" />
    <div v-else class="st-image-placeholder"></div>
  </div>
</template>

<script>
export default {
  name: "StImage",
  props: {
    styles: {
      type: Object,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    myHeight() {
      let hei = this.styles.height;
      if (typeof hei === "string") {
        return hei;
      } else {
        return hei + "px";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st-image {
  width: 100%;
  height: 80px;

  .st-image-src,
  .st-image-placeholder {
    width: 100%;
    height: 100%;
  }

  .st-image-placeholder {
    background-color: gray;
  }
}
</style>
