<!--
 * @Author: dongjia
 * @Date: 2021-07-28 09:39:17
 * @LastEditTime: 2021-07-28 10:54:46
 * @LastEditors: aleaner
 * @Description: 文本样式编辑器
 * @FilePath: \app-design\components\Form\StFontStyleForm.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="font-form">
    <el-color-picker class="picker" v-model="data.fontColor" show-alpha></el-color-picker>
    <div :class="['chose-item',data.fontWeight === 'bold'?'active':'']" @click="changeFontWeight"><img
        class="item-icon" src="../../assets/images/st-free-container/font-weight.png" /></div>
    <div :class="['chose-item',data.fontStyle === 'italic'?'active':'']" @click="changeFontStyle"><img
        class="item-icon" src="../../assets/images/st-free-container/font-style.png" /></div>
    <div :class="['chose-item',data.textDecoration === 'underline'?'active':'']"
      @click="changeFontDecoration"><img class="item-icon"
        src="../../assets/images/st-free-container/font-decoration.png" /></div>
    <div v-if="showTextAlign" :class="['chose-item',data.textAlign === item?'active':'']"
      v-for="item in textAlignArr" :key="item" @click="data.textAlign = item"><img class="item-icon"
        :src="require(`../../assets/images/st-free-container/text-${item}.png`)" /></div>
  </div>
</template>

<script>
export default {
  name: "StFontStyleForm",
  model: {
    prop: "data",
    event: "dataChange",
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          fontColor: "#000000",
          fontWeight: "normal",
          fontStyle: "normal",
          textDecoration: "none",
          textAlign: "left",
        };
      },
    },
    showTextAlign: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      textAlignArr: ["left", "center", "right"],
    };
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.$emit("dataChange", val);
      },
    },
  },
  methods: {
    changeFontWeight() {
      this.data.fontWeight =
        this.data.fontWeight === "normal" ? "bold" : "normal";
    },
    changeFontStyle() {
      this.data.fontStyle =
        this.data.fontStyle === "normal" ? "italic" : "normal";
    },
    changeFontDecoration() {
      this.data.textDecoration =
        this.data.textDecoration === "none" ? "underline" : "none";
    },
  },
};
</script>

<style lang="scss" scoped>
.font-form {
  display: flex;
  align-items: center;

  .picker {
    margin-right: 6px;
    ::v-deep.el-color-picker__trigger {
      width: 30px;
      height: 30px;
    }
    ::v-deep.el-color-picker__icon {
      display: none;
    }
  }

  .chose-item {
    cursor: pointer;
    margin-left: 2px;
    width: 25px;
    height: 25px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    .item-icon {
      width: 18px;
      height: 18px;
    }

    &.active {
      background: #eef1fb;
    }
  }
}
</style>