<!--
 * @Author: dongjia
 * @Date: 2022-03-02 11:03:41
 * @LastEditTime: 2022-03-02 14:40:46
 * @LastEditors: aleaner
 * @Description: 我的小组列表
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\st-community-nav\NavMyGroup.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="my-group-list" :style="{
    backgroundColor: bgColor
  }">
    <div class="list-item" v-for="(item, index) in defaultList" :key="index">
      <div class="item-top">
        <div class="item-cover-view">
          <div class="item-cover">
            <img class="default-icon" src="../../../assets/images/mini-default-icon@2x.png" />
          </div>
          <div class="cover-shadow"></div>
        </div>
        <div class="item-msg">
          <div class="msg-top">
            <div class="item-name">{{item.name}}</div>
            <span class="el-icon-arrow-right" style="font-size: 12px;color: #B3B3B3;"></span>
          </div>
          <div class="split-line"></div>
          <div class="msg-bottom">
            <st-avatars :arr="item.members" :size="20" :max="3" :indent="8">
            </st-avatars>
            <div class="member-count">共 {{item.members_count}} 名会员</div>
            <div class="new-member-count" v-if="item.new_member_count">
              <div class="dot"></div>
              {{item.new_member_count}} 名新成员
            </div>
          </div>
        </div>
      </div>
      <div class="item-post" v-if="item.posts.length">
        <div class="post-content">
          <div class="author-name">{{item.posts[0].author_name}}：</div>
          <div class="content-text" :style="{
											marginRight: item.posts[0].type !== 3?'6px':'0'
										}" v-if="item.posts[0].content">{{item.posts[0].content}}</div>
          <div class="sub-content" v-if="item.posts[0].type !== 3">
            {{subContent(item.posts[0])}}</div>
        </div>
        <div class="post-comment">
          <el-image class="comment-icon"
            :src="item.posts[0].comments_count?require('../../../assets/images/st-community-nav/group-comment-icon.png'):require('../../../assets/images/st-community-nav/none-group-comment-icon.png')"
            mode="aspectFill"></el-image>
          <span class="comments-count"
            :style="'color:' + (item.posts[0].comments_count?'#FF7B1B':'#B3B3B3')">{{item.posts[0].comments_count}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const defaultList =
  require("../../../assets/jsons/default-list.json").stMyGroupList;

import StAvatars from "@/modules/app-design/components/Preview/Common/StAvatars";

export default {
  components: { StAvatars },
  props: {
    bgColor: {
      type: String,
      default: "#ffffff",
    },
  },
  data() {
    return {
      defaultList,
    };
  },
  methods: {
    subContent(data) {
      let content = "";
      switch (data.type) {
        case 0:
          content = `[图片]`;
          break;
        case 1:
          content = "[视频]";
          break;
        case 2:
          content = "[推荐帖子]";
          break;
      }
      return content;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-group-list {
  padding: 16px;

  .list-item + .list-item {
    margin-top: 12px;
  }

  .list-item {
    width: 100%;
    padding: 12px;
    background: #ffffff;
    border-radius: 12px;
    .item-top {
      display: flex;
      align-items: center;

      .item-cover-view {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        .item-cover {
          width: 62px;
          height: 62px;
          border-radius: 6px;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgb(235, 235, 235);
          .default-icon {
            width: 28px;
            height: 28px;
          }
        }

        .cover-shadow {
          margin-left: -48px;
          width: 52px;
          height: 52px;
          border-radius: 6px;
          background-color: rgba(200, 200, 200, 0.2);
        }
      }

      .item-msg {
        flex: 1;
        margin-left: 8px;

        .msg-top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item-name {
            font-size: 16px;
            font-weight: bold;
            color: #1a1a1a;
            line-height: 1.5;
            @include nowrap();
          }

          .join-btn {
            flex-shrink: 0;
            padding: 2px 8px;
            font-size: 12px;
            color: var(--main-color);
            line-height: 1.5;
            border-radius: 20px;
            border: 1px solid var(--main-color);
          }
        }

        .split-line {
          margin-top: 8px;
          height: 1px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.1);
        }

        .msg-bottom {
          margin-top: 8px;
          display: flex;
          align-items: center;

          .member-count {
            margin-left: 8px;
            font-size: 12px;
            color: #b3b3b3;
            line-height: 1.5;
          }

          .new-member-count {
            border-radius: 14px;
            margin-left: 12px;
            padding: 0 6px;
            font-size: 12px;
            color: var(--main-color);
            background-color: var(--help-color);
            line-height: 1.5;
            display: flex;
            align-items: center;

            .dot {
              flex-shrink: 0;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: var(--main-color);
              margin-right: 4px;
            }
          }
        }
      }
    }

    .item-post {
      margin-top: 8px;
      background-color: #f8f8f8;
      border-radius: 6px;
      padding: 12px;
      display: flex;
      justify-content: space-between;

      .post-content {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #808080;
        line-height: 1.5;

        .author-name {
          flex-shrink: 0;
        }

        .content-text {
          height: 18px;
          @include clamp(1);
        }

        .sub-content {
          flex-shrink: 0;
        }
      }

      .post-comment {
        margin-left: 25px;
        flex-shrink: 0;
        display: flex;
        align-items: center;

        .comment-icon {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
        }

        .comments-count {
          margin-left: 8px;
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>