<template>
  <el-dialog :title="myTitle" :visible.sync="openDialog" width="395px" :show-close="true" append-to-body
    class="dialog-vertical" close-on-click-modal>
    <div class="auth-content">
      <div class="code-box">
        <el-image v-if="codeImg" class="scan-code" :src="codeImg" fit="contain">
          <div style="width: 100%;height: 100%" slot="placeholder" v-loading="true"></div>
        </el-image>
        <div v-if="CodeErr" class="err-mask cursor-pointer">
          <img src="../../assets/images/refresh-img@2x.png"
            @click="getPreviewCode" /></div>
      </div>
      <p class="content-tip">{{CodeErr?'二维码失效，点击刷新':'请打开微信扫一扫进行预览体验'}}</p>
      <p class="content-tip"><el-button type="text" @click="copy">复制链接</el-button></p>
    </div>
  </el-dialog>
</template>

<script>
  import {PreviewCode, syncProduct} from "../../api/page";
import { copyText } from "@/base/utils/tool";

export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  inject: ['postData'],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Function,
      default: () => {},
    },
    code: String,
    link: String
  },
  data() {
    return {
      codeImg: "",
      linkAddress: "",
      CodeErr: false,
      TimeoutTimer: null,
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.CodeErr = false;
        this.codeImg = this.code;
        this.linkAddress = this.link;
        // this.getPreviewCode();
        // if (this.TimeoutTimer) clearTimeout(this.TimeoutTimer);
        // this.TimeoutTimer = setTimeout(() => {
        //   this.CodeErr = true;
        //   if (this.TimeoutTimer) clearTimeout(this.TimeoutTimer);
        // }, 500000);
      } else {
        // if (this.TimeoutTimer) clearTimeout(this.TimeoutTimer);
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    myTitle() {
      return this.$route.params.platform === 'h5' ? '发布成功，扫码查看' : '体验二维码'
    }
  },
  methods: {
    // 获取二维码
    getPreviewCode() {
      syncProduct(this.postData).then((res) => {
          this.codeImg = res.data.qrcode || res.data;
          this.linkAddress = res.data.link;
        })
        .catch((err) => {});
    },
    copy() {
      copyText(this.linkAddress).then(() => {
        this.$message.success('复制成功')
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.auth-content {
  text-align: center;
  padding: 0 47px;

  .code-box {
    width: 180px;
    height: 180px;
    padding: 20px;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #c0c4cc;
    position: relative;

    .scan-code {
      width: 100%;
      height: 100%;
    }

    .err-mask {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 88px;
        height: 88px;
      }
    }
  }

  .content-tip {
    font-size: 14px;
    color: #303133;
  }
}
</style>
