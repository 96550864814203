<!--
 * @Author: dongjia
 * @Date: 2021-09-01 14:40:55
 * @LastEditTime: 2021-09-17 15:16:26
 * @LastEditors: aleaner
 * @Description: 通讯录系统页
 * @FilePath: \app-design\components\Preview\StMailList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="st-contacts">
    <div
      class="header"
      :class="
        platform !== 'h5' && myConfig.normal.bgConfig === 'theme'
          ? 'theme-color'
          : ''
      "
      :style="{
        backgroundColor:
          platform === 'h5'
            ? '' : myConfig.header.bgColor ? myConfig.header.bgColor
            : myConfig.normal.bgConfig === 'custom' &&
              myConfig.normal.bgType === 'color'
            ? myConfig.normal.bgColor
            : '',
      }"
    >
      <el-image
        class="bg-image"
        v-if="
          myConfig.normal.bgConfig === 'custom' &&
          myConfig.normal.bgType === 'image'
        "
        :src="fixImageUrl(myConfig.normal.bgImage, { h: 400 }, true)"
        fit="cover"
      ></el-image>
      <template v-if="platform !== 'h5'">
        <img
          class="start-top-img"
          :src="statusBar[myConfig.normal.statusBarColor]"
        />
        <div class="start-top" style="text-align: end">
          <img
            v-if="myConfig.normal.showHeaderJump"
            class="header-jump"
            :class="[
              headerJump.showConfig.showType !== 'template1'
                ? 'template-back-icon'
                : 'default-back-icon',
            ]"
            :src="
              backIcon[myConfig.normal.statusBarColor][
                headerJump.showConfig.showType
              ]
            "
          />
          <img
            class="start-top-handler"
            :src="handleIcon[myConfig.normal.statusBarColor]"
          />
        </div>
      </template>
      <st-page-set v-else :config="myConfig" />
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div
          class="type-item"
          v-if="myConfig.normal.titleConfig.isShow"
          :style="{
            color: myConfig.normal.fontColor,
            fontWeight: myConfig.normal.fontWeight,
            fontSize: myConfig.normal.fontSize + 'px',
          }"
        >
          {{ myConfig.normal.titleConfig.name }}
        </div>
      </div>
    </div>

    <div
      class="search"
      v-if="myConfig.searchConfig.isShow"
      :class="myConfig.searchConfig.bgConfig === 'theme' ? 'theme-color' : ''"
      :style="{
        backgroundColor:
          myConfig.searchConfig.bgType === 'color'
            ? myConfig.searchConfig.bgColor
            : '',
        backgroundImage:
          myConfig.searchConfig.bgType === 'image'
            ? `url(${fixImageUrl(myConfig.searchConfig.bgImage, {}, true)})`
            : '',
      }"
    >
      <div
        class="header-search"
        :style="{
          height: myConfig.searchConfig.height + 'px',
          borderRadius:
            myConfig.searchConfig.searchType === 'circle'
              ? myConfig.searchConfig.height + 'px'
              : '0px',
          backgroundColor: myConfig.searchConfig.searchColor,
          color: myConfig.searchConfig.placeholderColor,
          justifyContent:
            myConfig.searchConfig.textPosition === 'center' ? 'center' : '',
        }"
      >
        <div class="search-content">
          <p class="search-icon el-icon-search"></p>
          <p class="search-tip" v-if="myConfig.searchConfig.placeholder.isShow">
            {{ myConfig.searchConfig.placeholder.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";

export default {
  name: "StMailContent",
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultList: require("../../assets/jsons/default-list.json").companyList,
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: "通讯录",
            isShow: 1,
          },
          fontWeight: "normal",
          fontSize: 18,
          fontColor: "#FFFFFF",
          statusBarColor: "black",
          bgConfig: "theme",
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          showHeaderJump: 1,
          paddingBottom: 0,
        },
        // 筛选配置
        sortConfig: {
          orderBy: "join_time",
          isDesc: 0,
          sortTypes: {
            systemConfig: '',
            customConfig: {
              user: '',
              unit: '',
            },
          },
        },
        header:{
          bgColor:""
        },
        searchConfig: {
          isShow: 1,
          placeholder: {
            name: "搜索内容",
            isShow: 1,
          },
          placeholderColor: "#979da6",
          textPosition: "left",
          bgConfig: "theme",
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          searchType: "circle",
          interactiveType: "sticky",
          height: 32,
          searchColor: "#f8fbff",
        },
        shareConfig: {
          shareTitle: "",
          image: "",
        },
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.st-contacts {
  width: 375px;
  // min-height: 667px;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
  background-color: #f4f5f6;

  .header-nav {
    background-color: white;
  }

  .start-top-img {
    width: 375px;
    height: 20px;
  }
  .start-top {
    padding-right: 7px;
    .header-jump {
      position: absolute;

      &.default-back-icon {
        top: 32px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      &.template-back-icon {
        top: 25px;
        left: 5px;
        width: 78px;
        height: 32px;
      }
    }
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }
  .header {
    width: 100%;
    position: relative;
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .type-select {
      position: absolute;
      top: 23px;
      width: 100%;
      padding: 0 18px;
      display: flex;
      justify-content: center;

      &.simple {
        position: static;
        display: none;
      }

      .type-item + .type-item {
        margin-left: 18px;
      }
      .type-item {
        display: flex;
        align-items: center;
        height: 34px;
        text-align: center;
        color: #ffffff;
        line-height: 34px;
        .active {
          border-bottom: 2px #fff solid;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
        }
        .select-icon {
          width: 16px;
          height: 16px;
          margin-left: 2px;
        }
      }
    }
  }
}

.search {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-size: cover;
  background-position: center;
  .header-search {
    width: 335px;
    background: #f8fbff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    .search-content {
      display: flex;
      align-items: center;
      .search-icon {
        font-size: 14px;
      }
      .search-tip {
        font-size: 14px;
        line-height: 14px;
        margin-left: 4px;
      }
    }
  }
}

.theme-color {
  background: var(--main-color) !important;
}
</style>
