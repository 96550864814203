<template>
  <div class="st-page-me-college">
    <img :src="screenshot" class="preview-src" />
  </div>
</template>

<script>
export default {
  name: 'st-page-me-college',
  data() {
    const resUrl = window.serverConfig.VUE_APP_ADMINURL

    return {
      screenshot: `${resUrl}/app_design/images/me-college.png`
    }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.st-page-me-college {
  height: calc(812px - 83px); // 底部导航不要
  overflow: hidden;
  position: relative;
}

.preview-src {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}
</style>