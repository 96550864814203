<!--
 * @Author: dongjia
 * @Date: 2021-08-02 16:24:20
 * @LastEditTime: 2021-08-23 18:21:03
 * @LastEditors: aleaner
 * @Description: 企微组件未开通提示
 * @FilePath: \app-design\components\Form\StDisableTip.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div>
    <div class="component-disable-tip">
      <el-image class="tip-icon" :src="require('../../assets/images/right/disable-edit-icon.png')">
      </el-image>
      <p class="tip-text">该插件未开通，<router-link :to="{ name: 'PluginFeature',query: {activeName: pluginKey}}"
          target="_blank">前往开通
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StDisableTip",
  props: {
    pluginKey: {
      type: String,
      default: "contact_status",
    },
  },
};
</script>

<style lang="scss" scoped>
.component-disable-tip {
  text-align: center;
  margin-bottom: 12px;
  .tip-icon {
    width: 210px;
    height: 100px;
  }
  .tip-text {
    margin-top: 20px;
    font-size: 14px;
    color: #999999;
    line-height: 14px;
  }
  a {
    text-decoration: none;
    color: #3576ff;
  }
}
</style>