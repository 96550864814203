<!--
 * @Author: dongjia
 * @Date: 2021-09-23 17:15:08
 * @LastEditTime: 2021-09-28 17:33:34
 * @LastEditors: aleaner
 * @Description: 商会之家组件
 * @FilePath: \app-design\components\Preview\StBusinessAssociationHome.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="st-container" :style="{
    margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`
  }">
    <st-load-image class="container-img" :src="myConfig.normal.bgImage" />
  </div>
</template>

<script>
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";
export default {
  name: "StBusinessAssociationHome",
  components: {StLoadImage},
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    const baseUrl = window.serverConfig.VUE_APP_ADMINURL || "https://saasapi.shetuan.pro"
    return {
      model: {
        normal: {
          marginTop: 0,
          bgImage: baseUrl + '/gov/images/shzj.png',
          rowMargin: 0,
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.st-container {
  height: 101px;
}
.container-img {
  width: 100%;
  height: 100%;
}
</style>
