<template>
  <div class="poster-share flex-center flex-col">
      <div class="scale-body">
      <el-image class="close-icon" :src="require('../../assets/images/st-poster-share/close-icon.png')"
        fit="cover"></el-image>
        <el-image class="content-bg-image" :src="require('../../assets/images/st-poster-share/white-mask.png')"
                  fit="cover"></el-image>
        <div class="user flex-align-center">
          <el-avatar :size="48" :src="defaultAvatar" style="border: 1px solid white;" />
          <div class="title flex-col">
            <span class="one">刘文杰 创会会长</span>
            <span class="two">向您分享一个文章专题</span>
          </div>
        </div>
        <div class="content">
          <div class="card">
            <div class="content-image flex-center">
              <el-image class="default-icon" :src="require('../../assets/images/mini-default-icon@2x.png')"
                fit="cover"></el-image>
            </div>
            <div class="detail">
              <span class="title">党史学习教育</span>
              <span class="desc">新华社华盛顿5月31日电  新闻分析：美国迈出恢复载人航天能力关键一步　美国东部时间5月30日15时22分（北京时间5月31日3时22分），载有两名美国宇航员的“龙”飞船搭乘一枚“猎鹰9”火箭，从佛罗里达州肯尼迪航天中心39A发射台升空。</span>
              <el-divider />
              <div class="grid">
                <div class="grid-left">
                  <div class="grid-item">24</div>
                  <div class="grid-item">文章数</div>
                </div>
                <div class="grid-right">
                  <div class="grid-item">2376</div>
                  <div class="grid-item">浏览数</div>
                </div>
              </div>
              <el-divider />
            </div>
            <div class="footer flex">
              <div class="flex-col flex-1">
                <img class="logo" :src="fixImageUrl(myConfig.normal.image, 300)" />
                <span class="text">扫码查看文章专题 <i class="el-icon-caret-right" /></span>
              </div>
              <div class="qr-code" />
            </div>
          </div>
        </div>
      </div>
    <div class="save-btn">保存图片</div>
  </div>
</template>

<script>
export default {
  name: "st-poster-share",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        normal: {
          template: "",
          image: "",
        },
      },
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png",
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.poster-share {
  min-height: 603px;
  background-color: #808080;
  padding-bottom: 60px;

  .save-btn {
    padding: 7px 18px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 14px;
    border: 1px solid #ffffff;
    font-size: 14px;
    color: #ffffff;
    line-height: 14px;
  }

  .scale-body{
    width: 361px;
    height: 620px;
    padding: 20px 12px 12px 12px;
    transform: scale(0.914);
    transform-origin: center;
    position: relative;
    background-color: var(--main-color);
    border-radius: 4px;
    overflow: hidden;
    max-height: 70%;
    @include no-select();

    .close-icon {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 32px;
      height: 32px;
    }

    .content-bg-image {
      position: absolute;
      top: 0;
      right: 0;
      width: 38.78%;
      height: auto;
    }

    .user {
      .title {
        margin-left: 12px;
      }

      .one {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
      }

      .two {
        color: #ffffff;
        font-size: 13px;
        line-height: 21px;
        margin-top: 2px;
      }
    }

    .content {
      margin-top: 13px;
    }

    .card {
      width: 336px;
      height: 527px;
      border-radius: 8px;
      background: rgb(255, 255, 255);
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
      padding: 16px;
    }

    .content-image {
      width: 304px;
      height: 171px;
      border-radius: 4px;
      background: rgb(217, 217, 217);
    }

    .detail {
      margin-top: 20px;

      .title {
        color: rgb(26, 26, 26);
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
      }

      .desc {
        @include clamp(2);
        color: rgb(128, 128, 128);
        font-size: 14px;
        line-height: 21px;
        margin-top: 8px;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;

        .grid-right {
          border-left: 1px solid rgba(0,0,0,.1);
        }

        .grid-item:first-child {
          color: rgb(26, 26, 26);
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
        }

        .grid-item {
          color: rgb(128, 128, 128);
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .footer {
      width: 304px;
      height: 89px;
      border-radius: 6px;
      background: rgb(250, 250, 250);
      padding: 9px 12px 8px;

      .logo {
        height: 30px;
        width: fit-content;
        margin-top: 6px;
        object-fit: contain;
      }

      .text {
        color: rgb(33, 27, 14);
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        margin-top: 8px;
      }

      .qr-code {
        width: 72px;
        height: 72px;
        background-color: white;
      }
    }
  }
}
</style>
