var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"msg-box",style:({
  borderBottomLeftRadius: _vm.borderBottomRadius + 'px',
  borderBottomRightRadius: _vm.borderBottomRadius + 'px'
})},[(_vm.contentConfig.titleConfig.isShow || _vm.isShowBtn)?_c('div',{staticClass:"box-header"},[_c('div',{staticClass:"box-title",style:({
        fontSize: _vm.contentConfig.fontSize + 'px',
        fontWeight: _vm.contentConfig.fontWeight,
        color: _vm.contentConfig.fontColor,
      })},[_vm._v(_vm._s(_vm.contentConfig.titleConfig.isShow?_vm.contentConfig.titleConfig.name:'')),(_vm.contentNum)?_c('span',{staticStyle:{"margin-left":"12px"}},[_vm._v("("+_vm._s(_vm.contentNum)+")")]):_vm._e()]),(_vm.isShowBtn)?_c('div',{staticClass:"edit-btn"},[_c('div',{staticClass:"btn-text"},[_vm._v(_vm._s(_vm.headerBtnText))]),_c('img',{staticClass:"btn-img",attrs:{"src":require("../../assets/images/st-member-index/handle-icon.png")}})]):_vm._e()]):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }