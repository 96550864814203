<template>
  <div
    :style="[
      myRootStyle,
      {
        padding: ['template4', 'live', 'live-horizontal'].includes(myStyleType)
          ? `${myConfig.normal.colPadding}px 0 0`
          : `${myConfig.normal.colPadding}px ${myConfig.normal.rowPadding}px`,
        borderRadius: `${myConfig.normal.borderRadius}px`,
      },
    ]"
    class="activity-list"
  >
    <StListTitle
      :config="myConfig"
      :style="
        ['template4', 'live', 'live-horizontal'].includes(myStyleType)
          ? `padding: 0 ${myConfig.normal.rowPadding}px 16px`
          : ''
      "
    />
    <!-- template1 -->
    <div
      class="list-view"
      v-if="myStyleType === 'template1'"
      v-loading="loading"
    >
      <div
        :class="[
          myConfig.listStyle.shadow ? 'shadow' : '',
          myConfig.listStyle.border ? 'border' : '',
        ]"
        :key="index"
        :style="{
          marginTop: index !== 0 ? myTemplateData.dataSpacing + 'px' : '0',
          borderRadius: myTemplateData.borderRadius + 'px',
          backgroundColor: myConfig.listStyle.bgColor,
        }"
        class="list-item template1"
        v-for="(item, index) in activityList"
      >
        <div class="item-image-box">
          <st-load-image
            :border-radius="8 || myTemplateData.borderRadius / 2"
            :size="500"
            :src="item.cover"
            class="item-image"
          />
          <div class="icon-recommend">
            <activity-status-btn
              :activity="item"
              :radius="8 || myTemplateData.borderRadius / 2"
              role="tag"
            />
          </div>
        </div>
        <div class="item-content template1">
          <p class="card-title">{{ item.name }}</p>

          <!-- 活动时间 -->
          <div
            class="time flex-align-center"
            v-if="myConfig.messageConfig.showTime"
          >
            <img :src="timeIcon" class="time-icon" />
            <span class="clamp flex-1 min-w-0">{{
              item.activity_time_text_three
            }}</span>
          </div>
          <div
            class="card-author"
            v-if="
              item.organization_name &&
              myConfig.messageConfig.showCreator &&
              myConfig.dataConfig.dataShowType &&
              myConfig.dataConfig.dataShowType.length === 1 &&
              myConfig.dataConfig.dataShowType[0] === 'self_data'
            "
          >
            活动发起：{{ item.organization_name }}
          </div>
          <div class="card-author" v-else-if="item.project_name">
            活动发起：{{ item.project_name }}
          </div>
          <!-- 参加人数 -->
          <div
            class="card-bottom flex-align-center"
            v-if="myConfig.messageConfig.showUsers"
          >
            <div class="card-bottom-left">
              <!-- 报名人 -->
              <div class="join-avatars">
                <st-avatars
                  :arr="item.activityUsers"
                  :border-width="0.5"
                  :indent="16"
                  :width="32"
                />
              </div>
              <!-- 报名人数 -->
              <sub-activity-item-desc :activity="item" />
            </div>
            <div class="card-bottom-right">
              <!-- 报名中状态 -->
              <div
                class="join-btn"
                v-if="
                  [5].includes(item.activity_status_text_two) ||
                  (item['status_text'] ||
                    item['activity_status_text_three']) === '报名中'
                "
              >
                立即报名
              </div>
              <!-- 其他状态-->
              <div class="enter-btn flex-center" v-else>
                <i class="el-icon-arrow-right" style="color: white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- template2 -->
    <div
      class="list-view"
      v-if="myStyleType === 'template2'"
      v-loading="loading"
    >
      <div
        :class="[
          myConfig.listStyle.shadow ? 'shadow' : '',
          myConfig.listStyle.border ? 'border' : '',
        ]"
        :key="index"
        :style="{
          marginTop: index !== 0 ? myTemplateData.dataSpacing + 'px' : '0',
          borderRadius: myTemplateData.borderRadius + 'px',
          backgroundColor: myConfig.listStyle.bgColor,
        }"
        class="list-item template2"
        v-for="(item, index) in activityList"
      >
        <!-- 大图样式 -->
        <div
          class="list-item-normal not-4"
          v-if="item.activity_status_text_two !== 4"
        >
          <div class="item-image-box">
            <st-load-image
              :border-radius="8 || myTemplateData.borderRadius"
              :size="500"
              :src="item.cover"
              class="item-image"
            />
            <!-- 报名中、进行中等 -->
            <!--
            item.activity_status_text_two !== 3 &&
            -->
            <!--
                     v-if="
                (!myConfig.messageConfig.showUsers &&
                item.activity_users_count) || (
                !myConfig.messageConfig.showTime &&
                item.activity_status_text_two !== 3 &&
                !item.activity_users_count
                )
              "
            -->
            <div class="icon-recommend">
              <activity-status-btn
                :activity="item"
                :radius="8 || myTemplateData.borderRadius / 2"
                role="tag"
              />
            </div>
          </div>
          <div class="item-content template2">
            <p class="item-title">{{ item.name }}</p>
            <!-- 模板2活动不在进行中状态 -->
            <!--
              && item.activity_status_text_two !== 3
             -->
            <div class="item-text" v-if="myConfig.messageConfig.showTime">
              <div class="item-left">
                <!-- 活动时间 -->
                <div class="time flex-align-center">
                  <img :src="timeIcon" class="time-icon" />
                  <span class="clamp flex-1 min-w-0">{{
                    item.activity_time_text_three
                  }}</span>
                </div>
              </div>

              <!-- <div
                v-if="!item.activity_users_count"
                :class="[
                  'bottom-right',
                  'template2',
                  item.activity_status_text_two === 5
                    ? 'blue'
                    : item.activity_status_text_two === 4
                    ? 'grey'
                    : 'light',
                ]"
              >
                {{ item.activity_status_text_three }}
              </div> -->
              <activity-status-btn
                :activity="item"
                v-if="false && !item.activity_users_count"
              />
            </div>
            <!-- 模板2活动进行中样式 -->
            <div
              class="activity-go-on"
              v-if="false && item.activity_status_text_two === 3"
            >
              <div class="go-on-text">
                <img
                  :src="
                    require(`../../assets/images/st-activity-list/${
                      item.is_live ? 'live-go-on' : 'activity-going-icon'
                    }.png`)
                  "
                  class="text-icon"
                />
                <!-- <span
                  :style="`color: ${item.is_live ? '#ff1b1b' : '#ff7b1b'}`"
                  >{{ item.activity_status_text_three }}</span
                > -->
                <activity-status-btn :activity="item" />
              </div>
              <!-- 活动图文信息 -->
              <!-- <activity-list-img-text
                v-if="item.timeline"
                :timelineData="item.timeline"
                :borderRadius="myTemplateData.borderRadius"
              >
              </activity-list-img-text> -->
            </div>

            <div
              class="card-author"
              v-if="
                item.organization_name &&
                myConfig.messageConfig.showCreator &&
                myConfig.dataConfig.dataShowType &&
                myConfig.dataConfig.dataShowType.length === 1 &&
                myConfig.dataConfig.dataShowType[0] === 'self_data'
              "
            >
              活动发起：{{ item.organization_name }}
            </div>
            <div class="card-author" v-else-if="item.project_name">
              活动发起：{{ item.project_name }}
            </div>

            <!--
             &&
                item.activity_status_text_two !== 3
            -->
            <!--
              &&
                item.activity_users_count
             -->
            <div
              class="item-bottom template2 flex-between"
              v-if="myConfig.messageConfig.showUsers"
            >
              <div class="bottom-left">
                <!-- 报名人 -->
                <div class="join-avatars">
                  <st-avatars
                    :arr="item.activityUsers"
                    :border-width="0.5"
                    :indent="16"
                    :width="32"
                  />
                </div>
                <!-- 报名人数 -->
                <sub-activity-item-desc :activity="item" />
              </div>
              <div class="bottom-left-old" v-if="false">
                <div
                  class="collect-avatar"
                  v-if="myConfig.messageConfig.showUsers"
                >
                  <st-avatars :arr="item.activityUsers"></st-avatars>
                </div>
                <div
                  class="collect-content"
                  v-if="myConfig.messageConfig.showUsers"
                >
                  <span style="margin-right: 6px">{{
                    item.activity_users_count > 99
                      ? '99+'
                      : item.activity_users_count
                  }}</span
                  >人报名
                  <!-- <span v-else>暂无</span>人报名 -->
                </div>
              </div>

              <!--              <activity-status-btn :activity="item" />-->

              <div class="card-bottom-right">
                <!-- 报名中状态 -->
                <div
                  class="join-btn"
                  v-if="
                    [5].includes(item.activity_status_text_two) ||
                    (item['status_text'] ||
                      item['activity_status_text_three']) === '报名中'
                  "
                >
                  立即报名
                </div>
                <!-- 其他状态-->
                <div class="enter-btn flex-center" v-else>
                  <i class="el-icon-arrow-right" style="color: white"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 小图样式 -->
        <div
          :class="[myConfig.listStyle.imageText]"
          class="list-item-special"
          v-else
        >
          <div
            :style="{
              '--line-clamp':
                myConfig.messageConfig.showTime &&
                myConfig.messageConfig.showUsers
                  ? 2
                  : myConfig.messageConfig.showTime ||
                    myConfig.messageConfig.showUsers
                  ? '3'
                  : 4,
            }"
            class="special-item-content flex-1 flex-col flex-between"
            style="align-items: flex-start"
          >
            <div class="item-name">{{ item.name }}</div>
            <div class="w-full flex-col">
              <!-- 参加人员 -->
              <div
                class="time flex-align-center"
                style="min-height: 18px"
                v-if="myConfig.messageConfig.showUsers"
              >
                <img
                  :src="countIcon"
                  class="time-icon"
                  v-if="item.is_show_text"
                />
                <div
                  class="clamp flex-1 min-w-0"
                  v-show="item.is_show_text !== false"
                >
                  <sub-activity-item-desc
                    :activity="item"
                    @text="(t) => (item.is_show_text = !!t)"
                    v-if="item"
                  />
                </div>
              </div>
              <!-- 活动时间 -->
              <div
                class="time flex-align-center"
                v-if="myConfig.messageConfig.showTime"
              >
                <img :src="timeIcon" class="time-icon" />
                <span class="clamp flex-1 min-w-0">{{
                  item.activity_time_text_three
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="gap"
            style="width: 12px; height: 12px; flex-shrink: 0"
          ></div>
          <div class="special-item-cover item-image-box">
            <st-load-image
              :border-radius="myTemplateData.borderRadius"
              :size="300"
              :src="item.cover"
              class="cover-image"
            />
            <div class="icon-recommend">
              <activity-status-btn
                :activity="item"
                :radius="8 || myTemplateData.borderRadius / 2"
                role="tag"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- template3 -->
    <div
      :style="{
        rowGap: myTemplateData.dataSpacing + 'px',
        columnGap: myTemplateData.dataSpacing + 'px',
      }"
      class="list-view grid-row-column"
      v-if="myStyleType === 'template3'"
      v-loading="loading"
    >
      <!--
                height: myConfig.messageConfig.showTime && myConfig.messageConfig.showUsers ? '234px' : (
            myConfig.messageConfig.showTime || myConfig.messageConfig.showUsers ? 'calc(234px - 22px)' : ''
          )
      -->
      <div
        :class="[
          myConfig.listStyle.shadow ? 'shadow' : '',
          myConfig.listStyle.border ? 'border' : '',
        ]"
        :key="index"
        :style="{
          borderRadius: myTemplateData.borderRadius + 'px',
          backgroundColor: myConfig.listStyle.bgColor,
        }"
        class="list-item template3 flex-col"
        v-for="(item, index) in activityList"
      >
        <div class="item-cover-box">
          <st-load-image :size="300" :src="item.cover" class="item-cover" />
          <div class="status-tag">
            <activity-status-btn
              :activity="item"
              :radius="myTemplateData.borderRadius"
              role="tag"
            />
          </div>
        </div>
        <div
          class="item-content template3 flex-1 flex-col flex-between"
          style="align-items: flex-start"
        >
          <div class="item-title">{{ item.name }}</div>

          <div class="flex-col">
            <!-- 参加人员 -->
            <div
              class="time flex-align-center"
              v-if="myConfig.messageConfig.showUsers"
            >
              <img
                :src="countIcon"
                class="time-icon"
                v-if="item.is_show_text"
              />
              <div
                class="clamp flex-1 min-w-0"
                v-show="item.is_show_text !== false"
              >
                <sub-activity-item-desc
                  :activity="item"
                  @text="(t) => (item.is_show_text = !!t)"
                />
              </div>
            </div>
            <!-- 活动时间 -->
            <div
              class="time flex-align-center"
              v-if="myConfig.messageConfig.showTime"
            >
              <img :src="timeIcon" class="time-icon" />
              <span class="clamp flex-1 min-w-0">{{
                item.activity_time_text_three
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- template4 -->
    <div
      class="list-view scroll"
      v-if="myStyleType === 'template4'"
      v-loading="loading"
      :style="{
        paddingBottom: `${myConfig.normal.colPadding}px`,
        paddingLeft: `${myConfig.normal.rowPadding}px`,
      }"
    >
      <div
        :class="[
          myConfig.listStyle.imageText,
          myConfig.listStyle.shadow ? 'shadow' : '',
          myConfig.listStyle.border ? 'border' : '',
        ]"
        :key="index"
        :style="{
          marginRight: myTemplateData.dataSpacing + 'px',
          borderRadius: myTemplateData.borderRadius + 'px',
          backgroundColor: myConfig.listStyle.bgColor,
        }"
        class="list-item flex-align-center template4"
        v-for="(item, index) in activityList"
      >
        <div class="item-cover-box">
          <st-load-image
            :border-radius="8 || myTemplateData.borderRadius"
            :size="300"
            :src="item.cover"
            class="item-cover"
          />
          <div class="status-tag">
            <activity-status-btn :activity="item" :radius="8" role="tag" />
          </div>
          <!-- <div
            class="status-tag"
            :style="{
              borderRadius: `0 0 0 ${myTemplateData.borderRadius}px`,
            }"
            v-if="item.activity_status_text_two != 3"
            :class="item.activity_status_text_two === 5 ? 'blue' : 'grey'"
          >
            {{ item.activity_status_text_three }}
          </div>
          <div
            class="status-tag"
            :style="{
              borderRadius: `0 0 0 ${myTemplateData.borderRadius}px`,
            }"
            :class="item.is_live ? 'red' : 'yellow'"
            v-else
          >
            {{ item.activity_status_text_three }}
          </div> -->
        </div>
        <div class="item-content template4">
          <div
            :style="{
              '--line-clamp':
                myConfig.messageConfig.showTime &&
                myConfig.messageConfig.showUsers
                  ? 2
                  : myConfig.messageConfig.showTime ||
                    myConfig.messageConfig.showUsers
                  ? '3'
                  : 4,
            }"
            class="content-float-right flex-col flex-between"
            style="align-items: flex-start"
          >
            <div class="item-title">{{ item.name }}</div>
            <div class="flex-col">
              <!-- 参加人员 -->
              <div
                class="time flex-align-center"
                style="min-height: 18px"
                v-if="myConfig.messageConfig.showUsers"
              >
                <img
                  :src="countIcon"
                  class="time-icon"
                  v-if="item.is_show_text"
                />
                <div
                  class="clamp flex-1 min-w-0"
                  v-show="item.is_show_text !== false"
                >
                  <sub-activity-item-desc
                    :activity="item"
                    @text="(t) => (item.is_show_text = !!t)"
                    v-if="item"
                  />
                </div>
              </div>
              <!-- 活动时间 -->
              <div
                class="time flex-align-center"
                v-if="myConfig.messageConfig.showTime"
              >
                <img :src="timeIcon" class="time-icon" />
                <span class="clamp flex-1 min-w-0">{{
                  item.activity_time_text_three
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- live -->
    <div
      class="list-view scroll"
      v-if="myStyleType === 'live'"
      v-loading="loading"
      :style="{
        paddingBottom: `${myConfig.normal.colPadding}px`,
        paddingLeft: `${myConfig.normal.rowPadding}px`,
      }"
    >
      <div
        :class="[
          myStyleType,
          myConfig.listStyle.imageText,
          myConfig.listStyle.shadow ? 'shadow' : '',
          myConfig.listStyle.border ? 'border' : '',
        ]"
        :key="index"
        :style="{
          marginRight: myTemplateData.dataSpacing + 'px',
          borderRadius: myTemplateData.borderRadius + 'px',
          backgroundColor: myConfig.listStyle.bgColor,
          '--bg-filter': `url(${item.cover})`,
        }"
        class="list-item flex-col live"
        v-for="(item, index) in activityList"
      >
        <div class="item-cover-box" style="overflow: hidden">
          <st-load-image :size="300" :src="item.cover" class="item-cover" />
          <div :class="[`status${item.activity_status_text_two}`]" class="status-tag flex-center">
            <template v-if="myStyleType === 'live'">
              <img :src="liveIcon" class="status-icon" v-if="item.activity_status_text_two === 3" />
              <img :src="endIcon" class="status-icon" v-else-if="item.activity_status_text_two === 4" />
              <img :src="notStartIcon" class="status-icon" v-else />
            </template>
           <template v-else>
             <color-custom-icon :height="12" :icon="liveIcon" :width="12" color="#FF325E" v-if="item.activity_status_text_two === 3"></color-custom-icon>
             <img :src="endIcon2" class="status-icon" v-else-if="item.activity_status_text_two === 4" />
             <img :src="beforeIcon" class="status-icon" v-else />
           </template>
            {{item.activity_status_text_two === 3 ? '直播中' : item.activity_status_text_two === 4 ? '已结束' : '未开始'}}
          </div>
        </div>
        <div class="item-content live">
          <div
            :style="{
              '--line-clamp':
                myConfig.messageConfig.showTime &&
                myConfig.messageConfig.showUsers
                  ? 2
                  : myConfig.messageConfig.showTime ||
                    myConfig.messageConfig.showUsers
                  ? '3'
                  : 4,
            }"
            class="content-float-right flex-col flex-between"
            style="align-items: flex-start"
          >
            <div class="item-title">{{ item.name }}</div>
            <div class="item-desc" v-if="myStyleType === 'live-horizontal'">{{ item.description }}</div>
            <div class="item-footer flex-align-center flex-between">
              <!-- 参加人员 -->
              <div
                class="time flex-align-center"
                style="min-height: 18px"
                v-if="myConfig.messageConfig.showUsers"
              >
                <!--                <img :src="eyeIcon" class="time-icon" />-->
                <color-custom-icon
                  :icon="eyeIcon"
                  class="time-icon"
                  color="white"
                  height="12"
                  width="12"
                />
                <div class="clamp flex-1 min-w-0">
                  {{ item.activity_users_count || item.view_num }}人观看
                </div>
              </div>
              <div v-else><!-- 为了不影响左右布局 --></div>
              <div class="live-btn">观看</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- live-horizontal -->
    <div
      :style="{
        paddingBottom: `${myConfig.normal.colPadding}px`,
        paddingLeft: `${myConfig.normal.rowPadding}px`,
      }"
      class="list-view scroll"
      v-if=" myStyleType === 'live-horizontal'"
      v-loading="loading"
    >
      <div
        :class="[
          myStyleType,
          myConfig.listStyle.imageText,
          myConfig.listStyle.shadow ? 'shadow' : '',
          myConfig.listStyle.border ? 'border' : '',
        ]"
        :key="index"
        :style="{
          marginRight: myTemplateData.dataSpacing + 'px',
          borderRadius: myTemplateData.borderRadius + 'px',
          backgroundColor: myConfig.listStyle.bgColor,
          '--bg-filter': `url(${item.cover})`,
        }"
        class="list-item flex-col live"
        v-for="(item, index) in activityList"
      >
        <div class="item-cover-box" style="overflow: hidden">
          <st-load-image :border-radius="6" :size="300" :src="item.cover" class="item-cover" />
          <div :class="[`status${item.activity_status_text_two}`]" class="status-tag flex-center">

             <color-custom-icon :height="12" :icon="liveIcon" :width="12" color="#FF325E" v-if="item.activity_status_text_two === 3"></color-custom-icon>
             <img :src="endIcon2" class="status-icon" v-else-if="item.activity_status_text_two === 4" />
             <img :src="beforeIcon" class="status-icon" v-else />

            {{item.activity_status_text_two === 3 ? '直播中' : item.activity_status_text_two === 4 ? '已结束' : '未开始'}}
          </div>
        </div>
        <div class="item-content live">
          <div
            :style="{
              '--line-clamp':
                myConfig.messageConfig.showTime &&
                myConfig.messageConfig.showUsers
                  ? 2
                  : myConfig.messageConfig.showTime ||
                    myConfig.messageConfig.showUsers
                  ? '3'
                  : 4,
            }"
            class="content-float-right flex-col flex-between"
            style="align-items: flex-start"
          >
            <div class="item-title">{{ item.name }}</div>
            <div class="item-desc" v-if="myStyleType === 'live-horizontal'">{{ item.description }}</div>
            <div class="item-footer flex-align-center flex-between">
              <!-- 参加人员 -->
              <div
                class="time flex-align-center"
                style="min-height: 18px"
                v-if="myConfig.messageConfig.showUsers"
              >
                <!--                <img :src="eyeIcon" class="time-icon" />-->
                <color-custom-icon
                  :icon="eyeIcon"
                  class="time-icon"
                  color="white"
                  height="12"
                  width="12"
                />
                <div class="clamp flex-1 min-w-0">
                  {{ item.activity_users_count || item.view_num }}人观看
                </div>
              </div>
              <div v-else><!-- 为了不影响左右布局 --></div>
              <div class="live-btn">观看</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 手动加载 -->
    <div class="load-more" v-if="myLoadingConfig.loadingType === 'handle'">
      <div :class="[myLoadingConfig.handleType]">
        {{ myLoadingConfig.handleText }}
      </div>
    </div>
  </div>
</template>

<script>
import { activityList } from '../../../api/components'
import StAvatars from '@/modules/app-design/components/Preview/Common/StAvatars'
// import ActivityListImgText from './st-activity/activity-list-img-text.vue'
import StLoadImage from '@/modules/app-design/components/Preview/Common/StLoadImage'
import componentMixin from '@/modules/app-design/mixins/component-mixin'
import SubActivityItemDesc from '../activity-item-desc/activity-item-desc'
import ActivityStatusBtn from '../activity-status-btn/activity-status-btn'
import ColorCustomIcon from '../../Normal/ColorCustomIcon'

export default {
  name: 'StActivityList',
  mixins: [componentMixin],
  components: {
    ColorCustomIcon,
    ActivityStatusBtn,
    SubActivityItemDesc,
    StLoadImage,
    StAvatars,
    // ActivityListImgText,
  },
  data() {
    const resUrl = window.serverConfig.VUE_APP_IMG_URL

    return {
      loading: false,
      defaultList: require('../../../assets/jsons/default-list.json')
        .activityList,
      // 定义 config 格式和默认数据
      model: require('./props.json'),
      activityList: [],
      activityStatusIcon: [
        require('../../../assets/images/st-activity-list/status-over-icon.png'),
        require('../../../assets/images/st-activity-list/join-icon.png'),
        require('../../../assets/images/st-activity-list/activity-going-icon.png'),
        require('../../../assets/images/st-activity-list/live-go-on.png'),
      ],

      countIcon: `${resUrl}/activity/count.png`,
      timeIcon: `${resUrl}/activity/time.png`,

      beforeIcon: `${resUrl}/activity/before-blue.png`,
      endIcon2: `${resUrl}/activity/end-black.png`,

      liveIcon: `${resUrl}/activity/living.png`,
      notStartIcon: `${resUrl}/activity/live-not-start.png`,
      endIcon: `${resUrl}/activity/live-end.png`,
      eyeIcon: `${resUrl}/activity/watch.png`,
    }
  },
  created() {
    this.getActivityList()
  },
  watch: {
    myStyleType(val) {
      this.getActivityList(false)
    },
    'myLoadingConfig.limit'(val) {
      this.getActivityList(false)
    },
    'myConfig.dataConfig.dataShowType'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityList(false)
      }
    },
    'myConfig.dataConfig.dataType'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityList(false)
      }
    },
    'myConfig.dataConfig.dataAll'(val, oldVal) {
      this.getActivityList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityList(false)
      }
    },
    'myConfig.dataConfig.category_names'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityList(false)
      }
    },
    'myConfig.dataConfig.dataFilter'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityList(false)
      }
    },
    'myConfig.dataConfig.activity_type'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getActivityList(false)
      }
    },
  },
  methods: {
    getActivityList(notCancel) {
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        page: 1,
        limit: this.myLoadingConfig.limit,
        statuses: this.myConfig.dataConfig.dataFilter,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        template: this.myStyleType,
        data_sources: this.myConfig.dataConfig.dataShowType,
        category_names: this.myConfig.dataConfig.dataAll
          ? []
          : this.myConfig.dataConfig.category_names,
        category_all: this.myConfig.dataConfig.dataAll,
        ids,
        activity_type: this.myConfig.dataConfig.activity_type,
      }
      this.loading = true
      activityList(requestData, notCancel)
        .then((res) => {
          const { data } = res
          this.activityList = (data.length ? data : this.defaultList).map(
            (el) => ({
              ...el,
              is_show_text: undefined,
              // activity_status_text_two: 3,
              // activity_users_count: 34,
            })
          )
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/component';

.activity-list {
  background-size: 100% auto;
  background-repeat: repeat-y;
  overflow: hidden;

  .list-title {
    padding-bottom: 16px;
  }

  .list-view {
    min-height: 100px;

    &.grid-row-column {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .template3 {
        .item-cover-box {
          width: 100%;
          position: relative;

          &:before {
            content: '';
            display: block;
            padding-top: 56.25%;
          }

          .status-tag {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
          }
        }
      }
    }
    &.scroll {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      padding-bottom: 12px;
      padding-left: 16px;

      .list-item {
        position: relative;
        flex-shrink: 0;
      }

      .list-item.live {
        width: 150px;
        height: calc(150px + 84px);
        overflow: hidden;

        &.live-horizontal {
          width: 306px;
          height: calc(264px);

          &:before {
            width: 306px;
            height: 278px;
            /*background: #77787399;*/
            backdrop-filter: blur(24px);

            background: var(--bg-filter);
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            filter: blur(24px);
          }

          .item-cover-box {
            position: relative;
            padding: 2px;
          }

          .item-cover {
            border-radius: 6px;
          }

          .status-tag {
            position: absolute;
            top: unset;
            bottom: 6px;
            right: 6px;
            left: unset;
            background-color: #C8E8FF;
            color: #3DAEFF;
            width: fit-content;
            font-size: 12px;
            padding: 2px 4px;
            align-items: center;
            border-radius: 4px;

            &.status3 {
              background-color: #FFEAEE;
              color: #FF325E;
            }

            &.status4 {
              background-color: #E1E5EC;
              color: #6E7278;
            }

            .status-icon {
              width: 12px;
              height: 12px;
            }
          }

          .item-title {
            @include clamp(1);
            height: 21px;
          }

          .item-desc {
            @include clamp(1);
            color: #ffffffa6;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            margin-top: 3px;
          }

          .item-content {
            height: 94px;
            background-color: unset;
          }

          .item-footer {
            margin-top: 8px;
          }
        }

        /* 头图背景+毛玻璃 */
        &:before {
          content: '';
          display: block;
          width: 233px;
          height: 233px;
          flex-shrink: 0;
          background: var(--bg-filter);
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          top: 74px;
          left: 50%;
          transform: translateX(-50%);
          filter: blur(24px);
        }

        .status-tag {
          color: #ffffff;
          font-size: 10px;
          font-weight: bold;
          line-height: 15px;
          position: absolute;
          top: 7px;
          left: 7px;
          z-index: 1;
          padding: 4px 6px;
          border-radius: 17px;
          background-color: #3DAEFF;

          &.status3 {
            background-color: #ff325e;
          }

          &.status4 {
            background-color: #6E7278;
          }

          .status-icon {
            width: 12px;
            height: 11.5px;
            margin-right: 2px;
          }
        }

        .item-cover-box {
          width: inherit;
          aspect-ratio: 1 / 1;

          .item-cover {
            width: 100%;
            height: 100%;
            position: relative;
          }
        }

        .item-content {
          position: relative;
          padding: 8px;
          height: 84px;
          background-color: rgba(0, 0, 0, 0.1);
        }

        .item-title {
          color: #ffffff;
          font-size: 13px;
          font-weight: bold;
          line-height: 19.5px;
          height: 40px;
          @include clamp(2);
        }

        .item-footer {
          width: 100%;
          margin-top: 4px;
        }

        .live-btn {
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
          line-height: 18px;
          display: flex;
          width: 44px;
          padding: 3px 8px;
          justify-content: center;
          align-items: center;
          border-radius: 18px;
          background: #ffffff33;
        }

        .time {
          margin-top: 0;
          color: white;
          font-size: 11px;
          opacity: 0.5;
          line-height: 12px;

          .time-icon {
            margin-right: 4px;
          }
        }
      }

      .list-item.template4 {
        width: 312px;
        height: 114px;
        padding: 12px;

        &.right {
          flex-direction: row-reverse;
        }

        .item-cover-box {
          position: relative;
          /*position: absolute;*/
          /*left: 12px;*/
          /*bottom: 16px;*/
          width: 120px;
          /*z-index: 2;*/
          overflow: hidden;

          &:before {
            content: '';
            display: block;
            padding-top: #{90 * 100 / 120 + '%'};
          }

          .status-tag {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
          }
        }
      }
    }
    .list-item {
      width: 100%;

      &.template1,
      &.template2,
      &.template3,
      &.template4 {
        overflow: hidden;

        &.shadow {
          box-shadow: 0px 6px 10px 0px #f2f2f2;
        }

        &.border {
          border: 1px solid #cccccc;
        }
      }

      &.template1 {
        padding: 12px;

        .item-image-box {
          aspect-ratio: 16 / 9;
        }

        .card-title {
          font-weight: bold;
          color: #000000;
          font-size: 16px;
          line-height: 24px;
        }

        .card-bottom {
          justify-content: space-between;

          height: 48px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #f7f8fa;
          padding: 0 8px;
          margin-top: 10px;

          .card-bottom-left {
            display: flex;
            align-items: center;

            .join-avatars {
              margin-right: 8px;
            }

            ::v-deep.join-num {
              color: #808080;
              font-size: 14px;
              line-height: 21px;
              @include nowrap();
            }
          }

          .card-bottom-right {
            display: flex;

            .join-btn {
              display: inline-flex;
              padding: 6px 12px;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              background-color: #ff3b30;

              color: var(--text-color);
              font-size: 13px;
              font-weight: bold;
              line-height: 19.5px;
            }

            .enter-btn {
              width: 24px;
              height: 24px;
              flex-shrink: 0;
              border-radius: 32px;
              background-color: #d6d7d9;
            }
          }
        }
      }

      &.template2 {
        padding: 12px;

        .item-image-box {
          aspect-ratio: 319 / 240;
        }

        .item-title {
        }
      }

      &.template3 {
        .item-image-box {
          aspect-ratio: 319 / 240;
        }

        .time {
          color: #808080;
          font-size: 12px;
          font-weight: normal;
          line-height: 1.5;
          margin-top: 8px;

          .time-icon {
            width: 12px;
            height: 12px;
            margin-right: 5px;
            flex-shrink: 0;
          }

          .clamp {
            word-break: break-all;
            line-height: 1;
            @include nowrap();
          }
        }
      }

      &.template4 {
        .time {
          color: #808080;
          font-size: 12px;
          font-weight: normal;
          line-height: 1.5;
          margin-top: 8px;

          .time-icon {
            width: 12px;
            height: 12px;
            margin-right: 5px;
            flex-shrink: 0;
          }

          .clamp {
            word-break: break-all;
            line-height: 1.5;
            @include nowrap();
          }

          & + .time {
            margin-top: 4px;
          }
        }
      }

      .list-item-special {
        display: flex;
        /*justify-content: space-between;*/

        &.left {
          flex-direction: row-reverse;
        }

        .special-item-content {
          min-width: 0;

          .item-name {
            @include clamp(var(--line-clamp));
            width: auto;
            min-height: 42px;
            font-size: 14px;
            font-weight: bold;
            color: #1a1a1a;
            line-height: 1.5;
          }
          .content-bottom {
            display: flex;
            align-items: center;
            margin-top: 22px;
            font-size: 12px;
            color: #b3b3b3;
            line-height: 1.5;
          }
        }
        .special-item-cover {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 90px;
          overflow: hidden;
          position: relative;
        }

        .time {
          font-size: 12px;
          color: #808080;
          line-height: 1.5;
          font-weight: normal;

          .time-icon {
            width: 12px;
            height: 12px;
            margin-right: 5px;
            flex-shrink: 0;
          }
        }
      }

      .item-image-box {
        width: 100%;
        position: relative;

        .icon-recommend {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 5;
        }
      }

      .item-content {
        width: 100%;

        &.template1 {
          padding: 12px 0 0;
        }
        &.template2 {
          padding: 8px 4px 4px;

          .item-text {
            margin-top: 0;
          }

          .item-title {
            color: black;
            font-size: 16px;
            line-height: 1.5;
            font-weight: bold;
          }
        }

        &.template3 {
          position: relative;
          padding: 8px 8px 12px;
          /*height: 110px;*/

          .activity-status {
            display: flex;
            align-items: center;
            .status-icon {
              width: 12px;
              height: 12px;
              margin-right: 4px;
            }
            .status-text {
              font-size: 12px;
              line-height: 1.5;
            }
          }

          .item-title {
            color: #1a1a1a;
            font-size: 14px;
            font-weight: bold;
            line-height: 21px;
            @include clamp(2);
            height: 42px;
          }

          .item-bottom {
            position: absolute;
            bottom: 12px;
            width: 100%;
            font-size: 12px;
            color: #808080;
            line-height: 1.5;
          }
        }

        &.template4 {
          flex: 1;
          min-width: 0;
          width: unset;
          /*position: absolute;*/
          /*left: 0;*/
          /*bottom: 0px;*/
          height: 90px;
          display: flex;
          justify-content: flex-end;
          z-index: 1;
          /*box-shadow: 0px 6px 10px 0px #f2f2f2;*/
          .content-float-right {
            width: 160px;
          }
          .item-title {
            min-height: 42px;
            @include clamp(var(--line-clamp));
          }
          .item-bottom {
            margin-top: 12px;
            font-size: 12px;
            color: #808080;
            line-height: 1.5;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include nowrap();
            .time-icon {
              width: 10px;
              height: 10px;
              margin-right: 9px;
            }
          }
        }

        .item-title {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 1.5;
          @include clamp(2);
        }
        .card-author {
          font-size: 14px;
          color: #333;
          margin-top: 8px;
        }
        .item-text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;

          .item-left {
            display: flex;
            align-items: center;

            .status-text {
              font-size: 12px;
              color: #000000;
              line-height: 12px;
            }
          }
        }

        .activity-go-on {
          margin-top: 8px;
          .go-on-text {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .text-icon {
              width: 12px;
              height: 12px;
              margin-right: 4px;
            }
          }
        }

        .item-bottom {
          &.template1 {
            padding: 16px 0;
            margin-top: 15px;
            border-top: 2px dashed #e7e7e7;
          }
          &.template2 {
            /*border-top: 2px dashed #e7e7e7;*/
            height: 48px;
            flex-shrink: 0;
            border-radius: 8px;
            background: #f7f8fa;
            padding: 0 8px;
            margin-top: 10px;

            .bottom-left {
              display: flex;
              align-items: center;

              .join-avatars {
                margin-right: 8px;
              }

              ::v-deep.join-num {
                color: #808080;
                font-size: 14px;
                line-height: 21px;
                @include nowrap();
              }
            }

            .card-bottom-right {
              display: flex;

              .join-btn {
                display: inline-flex;
                padding: 6px 12px;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                background-color: #ff3b30;

                color: var(--text-color);
                font-size: 13px;
                font-weight: bold;
                line-height: 19.5px;
              }

              .enter-btn {
                width: 24px;
                height: 24px;
                flex-shrink: 0;
                border-radius: 32px;
                background-color: #d6d7d9;
              }
            }
          }

          display: flex;
          justify-content: space-between;
          align-items: center;

          .bottom-left {
            display: flex;
            align-items: center;

            .collect-avatar {
              display: flex;
              position: relative;
              // width: 74px;
              margin-right: 8px;
            }

            .collect-content {
              font-size: 12px;
              color: #666666;
              line-height: 12px;
            }
          }
        }
      }
      .item-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .time {
    color: #808080;
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    margin-top: 6px;

    .time-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      flex-shrink: 0;
    }

    .clamp {
      word-break: break-all;
      line-height: 1;
      @include nowrap();
    }
  }
}
// 活动状态样式
.bottom-right {
  &.template1 {
    font-size: 12px;
    padding: 6px;
    line-height: 1;
    border-radius: 2px;

    &.blue {
      background-color: var(--help-color);
      color: var(--main-color);
    }

    &.red {
      background-color: rgba(255, 0, 0, 0.1);
      color: #ff0000;
    }

    &.yellow {
      background-color: rgba(255, 123, 27, 0.1);
      color: rgba(255, 123, 27, 1);
    }

    &.grey {
      color: rgb(128, 128, 128);
      background-color: rgba(128, 128, 128, 0.1);
    }
  }

  &.template2 {
    border-radius: 4px;
    padding: 3px 15px;
    font-size: 12px;
    line-height: 1.5;
  }

  &.blue {
    background-color: var(--help-color);
    color: var(--main-color);
  }

  &.grey {
    background-color: #ececec;
    color: #979da6;
  }

  &.light {
    background-color: var(--help-color);
    color: var(--main-color);
  }
}

// 加载更多样式
.load-more {
  padding-top: 12px;
}

.w-full {
  width: 100%;
}
</style>
