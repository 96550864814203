<template>
  <div class="drag-list st-drag-contact-info">
    <div>
      <p class="list-title">添加信息</p>
      <p class="form-tip">鼠标拖拽调整顺序</p>
    </div>
    <draggable v-if="dataList" v-model="dataList" class="nav-list" animation="500" draggable=".nav-item">
      <template v-if="dataList.length > 0">
        <div class="nav-item" v-for="(data, index) in dataList" :key="index">
          <div class="item-header">
            <p class="header-name">信息{{index+1}}</p>
            <div v-if="!data.is_system" class="del-btn cursor-pointer" @click="dataList.splice(index,1)">删除</div>
          </div>
          <div v-if="data.is_system" class="item-content-box">
            <div class="item-content-right w-100">
              <div class="item-content flex-between w-100" style="align-items: flex-start;"
                   v-for="(dd, dI) in data.contents" :key="dd.label">
                <div class="content-title">{{dd.label}}</div>
                <single-media-wall v-model="dd.value" :width="90" :height="90" ratio="60:60" add-text="">
                  <div class="st-img-info" slot="info">{{`建议尺寸 ${60 * 3} * ${60 * 3}`}}</div>
                </single-media-wall>
              </div>
            </div>
          </div>
          <div v-else class="item-content-box">
            <div class="item-content-right">
              <div class="item-content">
                <div class="content-title">标题</div>
                <div class="content-handler" style="width: 100%;">
                  <el-input style="width: 64%;" size="small" v-model="data.label" placeholder="请输入" />
                </div>
              </div>
              <div class="item-content" style="align-items: flex-start;">
                <div class="content-title">内容</div>
                <div class="content-handler flex-col">
                  <div v-for="(d, dI) in data.contents" class="flex content-line-wrapper">
                    <el-input style="width: 64%;margin-right: 5px;" size="small"
                              v-model="d.value" placeholder="请输入" clearable
                              v-focus="dI + 1 === data.contents.length && index === current_parent_index && current_index === dI" />
                    <!-- 删除 -->
                    <el-button :disabled="!(data.contents.length > 1)" type="text"
                               style="padding:0;color: red;"
                               :style="{
                      color: !(data.contents.length > 1) ? 'lightgray' : 'red'
                               }"
                               @click="data.contents.splice(dI, 1)">
                      <i class="el-icon el-icon-remove-outline" style="font-size: 26px;" />
                    </el-button>
                    <!-- 添加 -->
                    <el-button :disabled="dI + 1 !== data.contents.length" type="text"
                               style="padding: 0;"
                               :style="{
                      visibility: `${dI + 1 !== data.contents.length || data.contents.length >= 3 ? 'hidden' : 'visible'}`
                               }"
                               @click="addContent(data.contents, index)">
                      <i class="el-icon el-icon-circle-plus-outline" style="font-size: 26px;" />
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="item-content">
                <div class="content-title">状态</div>
                <div class="content-handler">
                  <el-radio-group style="margin-left: auto" v-model="data.isShow" size="small">
                    <el-radio-button :label="1">显示</el-radio-button>
                    <el-radio-button :label="0">隐藏</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="nav-item-add" :style="{marginTop: dataList.length>0?'24px':'0'}">
        <el-button style="width: 317px" size="mini" type="primary" plain @click="handleAdd">
          {{addButtonText}}
        </el-button>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";

export default {
  name: "StDragContactInfo",
  components: {
    draggable,
    SingleMediaWall,
  },
  model: {
    prop: "custom_infos",
    event: "listChange",
  },
  props: {
    custom_infos: {
      type: Array,
      default: () => [],
    },
    default_infos: {
      type: Array,
      default: () => [],
    },
    addButtonText: {
      type: String,
      default: "添加信息",
    },
  },
  data() {
    const model = require('../../assets/jsons/component-library.json').system.contactUs.props
    return {
      current_parent_index: -1,
      current_index: -1,
      infoModel: model.contactsConfig.custom_infos[0],
      contentModel: model.contactsConfig.custom_infos[0].contents[0],
    };
  },
  computed: {
    dataList: {
      get() {
        const customArr = this.custom_infos
        return customArr.length ? customArr : this.default_infos;
      },
      set(val) {
        this.$emit("listChange", val);
      },
    },
  },
  watch: {
    dataList: {
      handler(val) {
        this.$emit("listChange", val);
      },
      deep: true,
    },
  },
  methods: {
    addContent (list, pIndex) {
      list.push({
        ...JSON.parse(JSON.stringify(this.contentModel))
      })
      this.current_index = list.length - 1
      this.current_parent_index = pIndex
    },
    // 添加数据
    handleAdd() {
      this.dataList.push({
        ...JSON.parse(JSON.stringify(this.infoModel))
      })
    },
  },
};
</script>

<style lang='scss' scoped>
.drag-list {
  padding: 0 $space;
}

  .list-title {
    font-size: 14px;
    color: #3a3a3a;
    line-height: 14px;
  }
  .form-tip {
    font-size: 12px;
    color: #c0c4cc;
    padding-top: 8px;
    padding-bottom: 11px;
  }
  .nav-list {
    background-color: #f6f9ff;
    margin: 0 -19px 0 -24px;
    padding: 12px;
    margin-bottom: 20px;
  }
  .nav-item-add {
    width: 100%;
    text-align: center;
  }
  .nav-item {
    width: 100%;
    padding: 15px;
    background: #ffffff;
    border-radius: 4px;

    .item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .header-name {
        font-size: 16px;
        color: #3a3a3a;
        line-height: 16px;
        @include nowrap();
      }
      .del-btn {
        font-size: 14px;
        color: #3576ff;
        line-height: 14px;
      }
    }
    .item-content-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-content-right {
        margin-left: 8px;
        .item-content + .item-content {
          margin-top: 8px;
        }
        .content-title {
          width: 70px;
        }
        .handler-checkbox {
          flex-shrink: 0;
          margin-left: 10px;
        }
      }
    }

    .item-content + .item-content {
      margin-top: 12px;
    }

    .item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.flex-between {
        .content-title {
          width: 50%;
        }
      }

      .content-title {
        width: 120px;
        flex-shrink: 0;
        font-size: 14px;
        color: #3a3a3a;
        line-height: 2;
        @include nowrap();
      }

      .content-handler {
        display: flex;
        align-items: center;

        .change-btn {
          flex-shrink: 0;
          font-size: 14px;
          color: #3576ff;
          line-height: 14px;
          margin-left: 14px;
        }
      }

      .content-line-wrapper + .content-line-wrapper {
        margin-top: 8px;
      }
    }
  }

  .nav-item + .nav-item {
    margin-top: 12px;
  }

  .drag-list {
    ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: rgba(53, 118, 255, 0.15);
      border: 1px solid #3576ff;
      color: #3576ff;
      box-shadow: none;
    }
  }
</style>

