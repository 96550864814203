<template>
  <div class="st-text">
  </div>
</template>

<script>
export default {
  name: "StText",
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      model: {
        normal: {
          width: 100,
          height: 50,
          top: 10,
          left: 10,
          text: "哈哈哈",
          borderConfig: {
            borderColor: "#ffffff",
            borderType: "solid",
            borderSize: 0,
          },
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.st-text {
  background-color: white;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
