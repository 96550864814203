<!--
 * @Author: dongjia
 * @Date: 2021-08-09 10:26:56
 * @LastEditTime: 2021-08-27 10:05:56
 * @LastEditors: aleaner
 * @Description: 业务组件选择业务数据列表设置
 * @FilePath: \app-design\components\Form\StDragDataList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="drag-list st-drag-follow-list">
    <div style="padding: 0 20px;">
      <p class="list-title">添加数据</p>
      <p class="form-tip">鼠标拖拽调整顺序</p>
    </div>
    <draggable v-if="dataList" v-model="dataList" class="nav-list" animation="500" draggable=".nav-item">
      <template v-if="dataList.length > 0">
        <div class="nav-item flex-col" v-for="(data, index) in dataList" :key="index">
          <div class="item-header flex-align-center flex-between">
            <p class="header-name">信息{{index+1}}</p>
            <el-button type="text" @click="dataList.splice(index,1)">删除</el-button>
          </div>
          <div class="item-content-box">
            <div class="item-content-right w-100">
              <div class="item-content flex-between w-100" style="align-items: flex-start;">
                <div class="content-title">标题</div>
                <el-input v-model="data.label" size="small" style="width: 70%" placeholder="请输入" clearable />
              </div>
              <div class="item-content flex-between w-100" style="align-items: flex-start;">
                <div class="content-title">上传图标</div>
                <single-media-wall v-model="data.icon" :width="90" :height="90" ratio="60:60" add-text="">
                  <div class="st-img-info" slot="info">{{`建议尺寸 ${60 * 3} * ${60 * 3}`}}</div>
                </single-media-wall>
              </div>
              <div class="item-content flex-between w-100" style="align-items: flex-start;">
                <div class="content-title">上传二维码</div>
                <single-media-wall v-model="data.value" :width="90" :height="90" ratio="60:60" add-text="">
                  <div class="st-img-info" slot="info">{{`建议尺寸 ${60 * 3} * ${60 * 3}`}}</div>
                </single-media-wall>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="dataList.length < 5" class="nav-item-add" :style="{marginTop: dataList.length>0?'24px':'0'}">
        <el-button style="width: 317px" size="mini" type="primary" plain @click="add">
          {{addButtonText}}
        </el-button>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";

export default {
  name: "StDragFollowList",
  components: {
    SingleMediaWall,
    draggable
  },
  model: {
    prop: "list",
    event: "listChange",
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    addButtonText: {
      type: String,
      default: "添加信息"
    },
  },
  data() {
    const libModel = require('../../assets/jsons/component-library.json').custom_components
      .find(el => el.index === 'base').components.find(el => el.component === 'st-follow-us').props
    return {
      model: JSON.parse(JSON.stringify(libModel)),
    };
  },
  computed: {
    dataList: {
      get() {
        let list = this.list
        return list.length ? list: this.model.contentConfig.default_list
      },
      set(val) {
        this.$emit("listChange", val);
      },
    },
  },
  watch: {
    dataList: {
      handler(val) {
        this.$emit("listChange", val);
      },
      deep: true,
    },
  },
  methods: {
    add() {
      this.dataList.push({
        ...JSON.parse(JSON.stringify(this.model.contentConfig.list[0]))
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.form-tip {
  font-size: 12px;
  color: #c0c4cc;
  padding-top: 8px;
  padding-bottom: 11px;
}
.nav-list {
  background-color: #f6f9ff;
  // margin: 0 -19px 0 -24px;
  padding: 12px;
  margin-bottom: 20px;
}
.nav-item-add {
  width: 100%;
  text-align: center;
}
.nav-item {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 2;

  .data-name {
    width: 224px;
    font-size: 14px;
    color: #3a3a3a;
    line-height: 1.3;
    @include nowrap();
  }
  .data-btn {
    .el-button {
      padding: 0;
    }
  }
}

.nav-item + .nav-item {
  margin-top: 12px;
}

  .item-content + .item-content {
    margin-top: 8px;
  }
</style>
