var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-container",style:(_vm.myRootStyle)},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"brand-list",style:([
      {
        minHeight: _vm.loading ? '100px' : '0px',
        '--data-spacing': '8px',
        '--swiper-color': _vm.myConfig.templateData.swiperColor,
        borderRadius: _vm.myConfig.normal.borderRadius + 'px',
        marginLeft: _vm.myConfig.normal.rowMargin + 'px',
        marginRight: _vm.myConfig.normal.rowMargin + 'px',
      } ])},[_c('div',{staticClass:"list-title",style:({
        backgroundImage:
          _vm.myConfig.listTitle.isTitleDecoration && _vm.myConfig.listTitle.isAddIcon
            ? ("url(" + (require('@/modules/app-design/assets/images/st-circle-group/bg-icon.png')) + ")")
            : ("url(" + (require('@/modules/app-design/assets/images/st-circle-group/bg.png')) + ")"),
      })},[(_vm.myConfig.listTitle.titleFamily === 'template2')?_c('div',{staticClass:"text",style:({
          'font-size': _vm.myConfig.listTitle.fontSize + 'px',
          fontWeight: _vm.myConfig.listTitle.fontWeight,
          color: _vm.myConfig.listTitle.titleColor,
        })},[_vm._v(" "+_vm._s(_vm.myConfig.listTitle.title)+" ")]):_vm._e(),(
          _vm.myConfig.listTitle.titleFamily === 'template1' &&
          _vm.myConfig.listTitle.artFontPhoto
        )?_c('div',{staticClass:"art-text"},[_c('img',{attrs:{"src":_vm.myConfig.listTitle.artFontPhoto}})]):_vm._e()]),(_vm.myStyleType === 'template1')?_c('div',{staticClass:"template1 content-box",style:({})},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item-box",style:({
          borderRadius: '4px',
          border:
            _vm.myConfig.normal.interfaceStyle == 'card'
              ? 'rgba(26, 117, 255, 0.1)'
              : 'none',
          backgroundColor:
            _vm.myConfig.normal.interfaceStyle == 'card' ? '#fff' : 'transparent',
        })},[_c('div',{staticClass:"list-item",style:({
            padding:
              _vm.myConfig.normal.interfaceStyle == 'card' ? '12px' : '0px',
            background:
              _vm.myConfig.normal.interfaceStyle == 'card'
                ? "linear-gradient(180deg, rgba(26, 117, 255,.03) 0%, rgba(26, 117, 255, 0) 100%)"
                : 'none',
          })},[_c('div',{staticClass:"cover"},[_c('img',{attrs:{"src":item.icon}})]),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_vm._m(0,true)]),(_vm.myConfig.templateData.groupDataShow)?[(_vm.myConfig.templateData.dataShowType === 'member')?_c('div',{staticClass:"avatar"},[(item.members.length)?_c('StAvatars',{staticClass:"st-avatar",attrs:{"arr":item.members,"max":3,"showDefaultAvatar":false}}):_vm._e(),_c('div',[_vm._v(_vm._s(item.members_count)+"名会员")])],1):_vm._e(),(_vm.myConfig.templateData.dataShowType === 'topic')?[_c('div',{staticClass:"avatar"},[_vm._v(_vm._s(item.posts_count)+"条帖子")])]:_vm._e()]:_vm._e()],2)])])}),0):_vm._e(),(_vm.myStyleType === 'template2')?_c('div',{staticClass:"template2 content-box",style:({
        padding:
          _vm.myConfig.normal.interfaceStyle == 'card' ? '10px 12px' : '10px 0px',
      })},[_c('div',{staticClass:"item-content"},_vm._l((_vm.list.slice(0, 2)),function(item,index){return _c('div',{key:index,staticClass:"item-box"},[_c('div',{staticClass:"list-item",style:({
              borderRadius: '6px',
            })},[_c('img',{attrs:{"src":item.icon}}),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),(_vm.myConfig.templateData.groupDataShow)?[(_vm.myConfig.templateData.dataShowType === 'member')?[_c('div',{staticClass:"member"},[_vm._v(_vm._s(item.members_count)+" 名会员")])]:_vm._e(),(_vm.myConfig.templateData.dataShowType === 'topic')?[_c('div',{staticClass:"member"},[_vm._v(_vm._s(item.posts_count)+" 条帖子")])]:_vm._e()]:_vm._e()],2)])])}),0),_vm._m(1)]):_vm._e(),(_vm.myStyleType === 'template3')?_c('div',{staticClass:"template3 content-box",style:({})},[_c('div',{staticClass:"item-content"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item-box",style:({
            border:
              _vm.myConfig.normal.interfaceStyle == 'card'
                ? '0.5px solid rgba(26, 117, 255, 0.1)'
                : 'none',
            background:
              _vm.myConfig.normal.interfaceStyle == 'card'
                ? "linear-gradient(180deg,rgba(26, 117, 255, 0.03) 0%,rgba(26, 117, 255, 0) 100%)"
                : 'transparent',
            minWidth:
              _vm.myConfig.normal.interfaceStyle == 'card' ? '140px' : '108px',
          })},[_c('div',{staticClass:"list-item",style:({
              padding:
                _vm.myConfig.normal.interfaceStyle == 'card' ? '12px 0px' : '0px',
            })},[_c('img',{attrs:{"src":item.icon}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),(_vm.myConfig.templateData.groupDataShow)?[(_vm.myConfig.templateData.dataShowType === 'member')?[_c('div',{staticClass:"member"},[_vm._v(_vm._s(item.members_count)+" 名会员")])]:_vm._e(),(_vm.myConfig.templateData.dataShowType === 'topic')?[_c('div',{staticClass:"member"},[_vm._v(_vm._s(item.posts_count)+" 条帖子")])]:_vm._e()]:_vm._e()],2)])}),0)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"more"},[_c('i',{staticClass:"el-icon-arrow-right"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"doi"},[_c('div',{staticClass:"item active"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"})])}]

export { render, staticRenderFns }