var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"st-form-editor"},[(_vm.rulesArr && _vm.rulesArr.componentExplain)?_c('div',{staticClass:"component-explain"},[(_vm.rulesArr.componentExplain.title)?_c('p',{staticClass:"form-title"},[_c('span',[_vm._v(_vm._s(_vm.rulesArr.componentExplain.title))])]):_vm._e(),_c('p',{staticClass:"explain-content"},[_vm._v(_vm._s(_vm.rulesArr.componentExplain.content))])]):_vm._e(),(_vm.type === 'st-activity-join')?_c('div',{staticClass:"activity-status-bar flex-center"},[_c('div',{staticClass:"activity-status",class:{ active: _vm.activityStatus === 0 },on:{"click":function($event){_vm.activityStatus = 0}}},[_vm._v(" 报名未开始 ")]),_c('div',{staticClass:"activity-status",class:{ active: _vm.activityStatus === 1 },on:{"click":function($event){_vm.activityStatus = 1}}},[_vm._v(" 报名开始后 ")])]):_vm._e(),(_vm.hasIsShow)?_c('div',{staticClass:"el-collapse-box flex-col"},[_c('el-collapse',{on:{"change":_vm.activeChange},model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_vm._l((Object.keys(_vm.myValue).sort(
          function (a, b) { return _vm.keyDict.indexOf(a) - _vm.keyDict.indexOf(b); }
        )),function(key,index){return [(
            _vm.rulesArr[key] &&
            _vm.rulesArr[key].isShow !== 0 &&
            _vm.isH5FilterShare(key)
          )?_c('el-collapse-item',{key:_vm.type + key,attrs:{"name":key}},[_c('template',{slot:"title"},[_c('div',{staticClass:"component-title-name",attrs:{"data-key":key},on:{"click":function($event){return _vm.activeChange(_vm.rulesArr[key], key, _vm.rulesArr)}}},[_vm._v(" "+_vm._s(_vm.rulesArr[key].title || _vm.keyTitle[key])+" "),(_vm.rulesArr[key].subtitle)?[_c('br'),_c('span',{staticClass:"component-subtitle-name",style:({
                    opacity: _vm.activeNames.includes(key) ? 1 : 0,
                  })},[_c('i',{staticClass:"el-icon-warning",staticStyle:{"color":"rgb(192, 196, 204)"}}),_vm._v(" "+_vm._s(_vm.rulesArr[key].subtitle))])]:_vm._e()],2)]),_c('div',{staticClass:"editor-item",style:({
              paddingTop: _vm.rulesArr[key].subtitle ? '24px' : 0,
              order: _vm.keyDict.indexOf(key) > -1 ? _vm.keyDict.indexOf(key) : 99,
            })},[(_vm.rulesArr && _vm.rulesArr[key])?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.rulesArr[key].isShow === undefined ||
                  _vm.rulesArr[key].isShow === 1
                ),expression:"\n                  rulesArr[key].isShow === undefined ||\n                  rulesArr[key].isShow === 1\n                "}]},[_c('form-create',{attrs:{"rule":_vm.rulesArr[key].rules,"option":_vm.options,"value":_vm.myValue[key]},on:{"update:value":function($event){return _vm.$set(_vm.myValue, key, $event)}},model:{value:(_vm.fApiArr[index]),callback:function ($$v) {_vm.$set(_vm.fApiArr, index, $$v)},expression:"fApiArr[index]"}})],1)]:_vm._e()],2)],2):_vm._e()]})],2)],1):(_vm.type && JSON.stringify(_vm.rulesArr) !== '[]')?_c('div',{staticClass:"st-form-editor-disable"},[_c('div',{staticClass:"disable-tip"},[_c('el-image',{staticClass:"tip-icon",attrs:{"src":require('../../assets/images/right/disable-edit-icon.png')}}),_c('p',{staticClass:"tip-text"},[_vm._v(" 该"+_vm._s(typeof _vm.selected === 'number' ? '组件' : '页面')+"不支持设置 ")])],1)]):_vm._e(),(_vm.rulesArr && _vm.rulesArr.pageJump && _vm.rulesArr.pageJump.isShow)?_c('div',{staticClass:"jump-btn"},[_c('el-button',{attrs:{"size":"mini","plain":""},on:{"click":_vm.handleJumpPage}},[_vm._v(_vm._s(_vm.rulesArr.pageJump.title)+" ")])],1):_vm._e(),(_vm.rulesArr && _vm.rulesArr.componentTips && _vm.rulesArr.componentTips.isShow)?_c('div',{staticClass:"component-tips"},[_c('p',{staticClass:"component-tips-title"},[_vm._v("提示：")]),_c('p',{staticStyle:{"line-height":"20px","margin-top":"8px"}},[_vm._v(" 1、使用该组件前，需"),_c('a',{staticClass:"link-text-type cursor-pointer",on:{"click":_vm.jumpWechatPlate}},[_vm._v("前往微信小程序后台")]),_vm._v("，"+_vm._s(_vm.rulesArr.componentTips.TipsArr[0])+" ")]),_vm._l((_vm.tipList),function(text,index){return _c('div',{key:index,staticStyle:{"line-height":"20px","margin-top":"8px"}},[(typeof text === 'string')?_c('p',[_vm._v(_vm._s(text))]):_c('div',[_c('p',[_vm._v(_vm._s(text[0]))]),_vm._l((text.filter(
            function (childEl, childElIndex) { return childElIndex > 0; }
          )),function(childText,i){return _c('p',{key:i,staticStyle:{"line-height":"20px","margin-top":"8px","margin-left":"15px"}},[_vm._v(" "+_vm._s(childText)+" ")])})],2)])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }