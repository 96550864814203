<!--
 * @Author: dongjia
 * @Date: 2021-07-19 16:55:32
 * @LastEditTime: 2021-07-20 13:48:32
 * @LastEditors: aleaner
 * @Description: 联系我插件
 * @FilePath: \app-design\components\Preview\StContactMe.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="contact-me" :style="{
      marginTop: myConfig.normal.marginTop + 'px'
    }">
    <div class="main template1" :style="{
      margin: `0 ${myConfig.templateData.rowMargin}px`
    }" v-if="myConfig.templateData.styleType === 1">
      <div class="main-title" :style="{
        color: myConfig.templateData.fontColor,
        fontWeight: myConfig.templateData.fontWeight,
        fontSize: myConfig.templateData.fontSize + 'px'
      }" v-if="myConfig.templateData.titleConfig.isShow">
        {{myConfig.templateData.titleConfig.name}}</div>
      <div class="main-content">
        <div class="content-left">
          <img class="content-image" src="../../assets/images/st-contact-me/avatar-icon.png" />
          <div class="content-name">
            {{myConfig.templateData.isMask?'真实姓名':contactText[myConfig.templateData.contactText]}}</div>
        </div>
        <div :class="['content-right', myConfig.templateData.btnColor1]">
          {{buttonText[myConfig.templateData.buttonText]}}</div>
      </div>
    </div>
    <div class="main template2" v-if="myConfig.templateData.styleType === 2" :style="{
      justifyContent: flexType[myConfig.templateData.showPosition]
    }">
      <div class="main-content">
        <div class="main-title" v-if="myConfig.templateData.titleConfig.isShow" :style="{
        color: myConfig.templateData.fontColor,
        fontWeight: myConfig.templateData.fontWeight,
        fontSize: myConfig.templateData.fontSize + 'px'
      }">
          {{myConfig.templateData.titleConfig.name}}</div>
        <img class="content-image" src="../../assets/images/st-contact-me/chat-icon.png" />
      </div>
    </div>
    <div class="main template3" v-if="myConfig.templateData.styleType === 3" :style="{
      margin: `0 ${myConfig.templateData.rowMargin}px`
    }">
      <div class="content-left">
        <img class="content-image" src="../../assets/images/st-contact-me/people-icon.png" />
      </div>
      <div class="content-right">
        <div class="right-top">
          <img class="mini-icon" src="../../assets/images/st-contact-me/chat-icon-mini.png" />
          <p class="top-title">{{contactText[myConfig.templateData.contactText]}}</p>
        </div>
        <p class="right-bottom">{{serviceText[myConfig.templateData.serviceText]}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StContactMe",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        normal: {
          marginTop: 1,
          configId: {
            ID: "",
            configType: 0,
          },
        },
        templateData: {
          styleType: 0,
          titleConfig: {
            name: "联系我",
            isShow: 1,
          },
          contactText: 0,
          serviceText: 0,
          fontWeight: "normal",
          fontSize: 18,
          fontColor: "#C2C2C2",
          isMask: 0,
          buttonText: 0,
          showPosition: "left",
          btnColor1: "primary",
          btnColor2: "#4A8FEC",
          rowMargin: 0,
        },
      },
      contactText: ["咨询服务", "在线咨询", "联系客服"],
      buttonText: ["联系我", "立即联系", "咨询服务", "在线咨询", "联系客服"],
      serviceText: [
        "有什么问题可以联系我咨询",
        "联系服务人员咨询",
        "获取服务人员联系方式",
        "联系客服咨询更多问题",
      ],
      flexType: {
        left: "flex-start",
        center: "center",
        right: "flex-end",
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-me {
  padding: 12px 25px;
  .main {
    &.template1 {
      .main-title {
        font-size: 18px;
        color: #c2c2c2;
        line-height: 25px;
        margin-bottom: 14px;
      }

      .main-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content-left {
          display: flex;
          align-items: center;
          .content-image {
            width: 45px;
            height: 45px;
            margin-right: 12px;
          }

          .content-name {
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            line-height: 25px;
          }
        }

        .content-right {
          border-radius: 2px;
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          padding: 4px 5px;

          &.primary {
            background-color: #4a8fec;
            color: #ffffff;
          }

          &.light {
            border: 1px solid #4a8fec;
            color: #4a8fec;
          }
        }
      }
    }
    &.template2 {
      display: flex;
      align-items: center;
      .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .main-title {
          font-size: 18px;
          color: #c2c2c2;
          line-height: 25px;
          margin-bottom: 13px;
        }
        .content-image {
          width: 45px;
          height: 45px;
        }
      }
    }

    &.template3 {
      display: flex;
      align-items: center;

      .content-left {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 71px;
        height: 71px;
        background-color: #4a92ea;
        .content-image {
          width: 30px;
          height: 34px;
        }
      }

      .content-right {
        width: 100%;
        padding: 8px 12px;
        border: 1px solid #ececee;
        .right-top {
          display: flex;
          align-items: center;

          .mini-icon {
            width: 22px;
            height: 21px;
            margin-right: 8px;
          }

          .top-title {
            font-size: 18px;
            font-weight: bold;
            color: #454545;
            line-height: 25px;
          }
        }

        .right-bottom {
          margin-top: 4px;
          font-size: 14px;
          color: #c2c2c2;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
