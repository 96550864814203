import SingleMediaWall from "../../../common/components/SingleMediaWall";
import StTipsSelector from "../../components/Form/StTipsSelector";
import StBusinessListSelector from "../../components/Form/StBusinessListSelector";
import StGovPlatformSelector from "../../components/Form/StGovPlatformSelector";
import StInfoSelector from "../../components/Form/StInfoSelector";
import DefaultImageSelector from "../../components/Form/DefaultImageSelector";
import MediaWall from "../../../common/components/MediaWall";
import StNumberSlider from "../../components/Form/StNumberSlider";
import StColorPicker from "../../components/Form/StColorPicker";
import StRadioButtonGroup from "../../components/Form/StRadioButtonGroup";
import StTemplateRadio from "../../components/Form/StTemplateRadio";
import StSingleMedia from "../../components/Form/StSingleMedia";
import StTemplateNewRadio from "../../components/Form/StTemplateNewRadio";
import StDragImageSelector from "../../components/Form/StDragImageSelector";
import StMailSortDialog from "../../components/Form/StMailSortDialog";
import StDragNavList from "../../components/Form/StDragNavList";
import StDragMemberNavList from "../../components/Form/StDragMemberNavList";
import StIsShowTitle from "../../components/Form/StIsShowTitle";
import StLinkConfig from "../../components/Form/StLinkConfig";
import StCheckBoxGroup from "../../components/Form/StCheckBoxGroup";
import StUnCheckableBox from "../../components/Form/StUnCheckableBox";
import StDragWaterMark from "../../components/Form/StDragWaterMark";
import StPayStatus from "../../components/Form/StPayStatus";
import StThemeSelector from "../../components/Form/StThemeSelector";
import StSpecialInput from "../../components/Form/StSpecialInput";
import StNineBoxBtn from "../../components/Form/StNineBoxBtn";
import StBorderConfigForm from "../../components/Form/StBorderConfigForm";
import StSetWidthHeight from "../../components/Form/StSetWidthHeight";
import StFontStyleForm from "../../components/Form/StFontStyleForm";
import StDisableTip from "../../components/Form/StDisableTip";
import StAddressForm from "../../components/Form/StAddressForm";
import StDragDataList from "../../components/Form/StDragDataList";
import StDragList from "@/modules/app-design/components/Form/StDragList";

import StElSelect from "../../components/Form/StElSelect";
import StElCascader from "../../components/Form/StElCascader";
import btnStyleSelector from "../../components/Form/btnStyleSelector";
import StNativeInput from "../../components/Form/StNativeInput";
import StObjSelector from "../../components/Form/StObjSelector";
import StBorderRadiusSetter from "@/modules/app-design/components/Form/StBorderRadiusSetter";
import StCheckBox from "../../components/Form/StCheckBox"
import StDragMemberMsg from "../../components/Form/StDragMemberMsg"
import StAboutMeDragList from "../../components/Form/StAboutMeDragList"
import StTimeRange from "../../components/Form/StTimeRange"
import StColumnNavList from "../../components/Form/StColumnNavList"
import StChannelsCategoryFilter from "@/modules/app-design/components/Form/StChannelsCategoryFilter";
import StGradientsSetter from "@/modules/app-design/components/Form/StGradientsSetter";
import StDragContactInfo from "@/modules/app-design/components/Form/StDragContactInfo";
import StDragFollowList from "@/modules/app-design/components/Form/StDragFollowList";
import StMemberCardPositionOrderRadio from "@/modules/app-design/components/Form/StMemberCardPositionOrderRadio";
// import StDragECardDataList from "@/modules/app-design/components/Form/StDragECardDataList";
import LoginMethodSelector from '@/modules/app-design/components/Form/LoginMethodSelector';
import StCollegeWeekLearning
  from '@/modules/app-design/components/Preview/st-college-week-learning/StCollegeWeekLearning'
/**
 * 组件自身需要声明 name 属性哦，应该是动态组件的原因
 */
const formComponents = {
  StCollegeWeekLearning,
  LoginMethodSelector,
  // StDragECardDataList,
  StColumnNavList,
  StMemberCardPositionOrderRadio,
  StDragFollowList,
  StDragContactInfo,
  StAboutMeDragList,
  StDragMemberMsg,
  StCheckBox,
  StTipsSelector,
  DefaultImageSelector,
  SingleMediaWall,
  MediaWall,
  StNumberSlider,
  StColorPicker,
  StRadioButtonGroup,
  StTemplateRadio,
  StSingleMedia,
  StTemplateNewRadio,
  StDragImageSelector,
  StDragNavList,
  StDragMemberNavList,
  StIsShowTitle,
  StLinkConfig,
  StCheckBoxGroup,
  StUnCheckableBox,
  StDragWaterMark,
  StPayStatus,
  StThemeSelector,
  StSpecialInput,
  StNineBoxBtn,
  StBorderConfigForm,
  StSetWidthHeight,
  StFontStyleForm,
  StDisableTip,
  StAddressForm,
  StDragDataList,
  StDragList,
  StElSelect,
  StElCascader,
  btnStyleSelector,
  StNativeInput,
  StObjSelector,
  StBorderRadiusSetter,
  StTimeRange,
  StChannelsCategoryFilter,
  StGradientsSetter,
  StInfoSelector,
  StMailSortDialog,
  StBusinessListSelector,
  StGovPlatformSelector
}

// console.log(formComponents,'formComponents')
console.log('~新增加的 form-create 自定义组件需要在这边导入')
export default formComponents
