<template>
    <div class="feedback">
      <img style="width: 100%;" :src="`${baseImgUrl}/app_design/st-chat-msg-list/chat-list-bg.jpg`"/>
    </div>
  </template>
  
  <script>
  import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
  export default {
    name: "StChatMsgList",
    components: { StPageSet },
    props: {
      config: {
        type: Object,
        required: true,
      },
      headerJump: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        baseImgUrl:'',
        platform: this.$route.params.platform,
        model: {
       
        },
        // 状态栏图片
        statusBar: {
          black: require("../../assets/images/phone-statusbar1@2x.png"),
          white: require("../../assets/images/phone-statusbar2@2x.png"),
        },
        // 头部右上角胶囊图标
        handleIcon: {
          black: require("../../assets/images/wechat-handle1@2x.png"),
          white: require("../../assets/images/wechat-handle2@2x.png"),
        },
        // 头部左上角图标链接
        backIcon: {
          black: {
            template1: require("../../assets/images/middle/Back1@2x.png"),
            template2: require("../../assets/images/middle/homeBack1@2x.png"),
            template3: require("../../assets/images/middle/moreBack1@2x.png"),
            template4: require("../../assets/images/middle/moreBack1@2x.png"),
          },
          white: {
            template1: require("../../assets/images/middle/Back2@2x.png"),
            template2: require("../../assets/images/middle/homeBack2@2x.png"),
            template3: require("../../assets/images/middle/moreBack2@2x.png"),
            template4: require("../../assets/images/middle/moreBack2@2x.png"),
          },
        },
      };
    },
    computed: {
      myConfig() {
        return this.$mergeModel(this.model, this.config);
      },
    },
    created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
  };
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  