<!--
 * @Author: dongjia
 * @Date: 2021-09-16 17:22:41
 * @LastEditTime: 2022-01-07 17:32:42
 * @LastEditors: aleaner
 * @Description: 会员主页详情
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StMemberIndex.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="member-index">
    <div class="header">
      <template v-if="platform !== 'h5'">
        <img class="start-top-img" :src="statusBar[myConfig.normal.statusBarColor]">
        <div class="start-top" style="text-align: end"> 
          <img v-if="myConfig.otherConfig.isShowReport" style="height: 32px;width: 32px;margin-right: 10px;" src="../../assets/images/st-me-page/report-icon2.png">
          <img v-if="myConfig.normal.showHeaderJump" class="header-jump"
            :class="[headerJump.showConfig.showType !== 'template1'?'template-back-icon':'default-back-icon']"
            :src="backIcon[myConfig.normal.statusBarColor][headerJump.showConfig.showType]" />
          <img class="start-top-handler" :src="handleIcon[myConfig.normal.statusBarColor]">
        </div>
      </template>
      <st-page-set v-else :config="myConfig"></st-page-set>
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div class="type-item" v-if="myConfig.normal.titleConfig.isShow" :style="{
          color: myConfig.normal.fontColor,
          fontWeight: myConfig.normal.fontWeight
        }">{{myConfig.normal.readMemberName?'xxx的主页': myConfig.normal.titleConfig.name}}
        </div>
      </div>
    </div>
    <div class="me-content">
      <div class="me-content-bg" :style="{
        backgroundColor: myConfig.contentConfig.bgType === 'color'?myConfig.contentConfig.bgColor:''
      }">
        <el-image v-if="myConfig.contentConfig.bgType === 'image'" class="bg-image"
          :src="fixImageUrl(myConfig.contentConfig.bgImage,{h:400}, randomId)" fit="cover"></el-image>
      </div>
      <div class="me-card-list">
        <div class="me-card template1" :style="{
          borderRadius: firstCompConfig.normal && firstCompConfig.normal.marginTop===0?'4px 4px 0 0':'4px'
        }" v-if="myConfig.templateData.template === 'template1'">
          <div class="show-btn" v-if="myConfig.contentConfig.showElectroCard">电子名片</div>
          <div class="me-avatar">
            <img class="me-avatar-image" src="../../assets/images/st-me-page/avatar1.png" />
          </div>
          <div class="me-nickname">社团云演示</div>
          <div class="me-intro" v-if="myConfig.contentConfig.isShowTitleName">组织架构 | 会内职务</div>
          <div class="me-intro" v-if="myConfig.contentConfig.isShowTitleName && myConfig.contentConfig.positionMultiple">组织架构 | 会内职务2</div>
          <div v-show="myConfig.contentConfig.showQuickEnter && !noQuickEnters" class="me-count flex-center">
            <div v-for="(item, index) in myList" :key="index" class="count-item flex-col"
              v-if="myConfig.contentConfig[item].value">
              <span class="count-num">99</span>
              <span class="count-title">{{myConfig.contentConfig[item].jump_link.jump_config.name}}</span>
            </div>
          </div>
          <!-- 勋章 -->
          <div class="medal-box"  v-if="myConfig.contentConfig.showMedal">
            <div
              class="medal"
              :style="{
                backgroundImage: `url(${require('../../assets/images/st-me-page/medal-box.png')})`,
              }"
            >
              <img
                class="medal-text"
                src="../../assets/images/st-me-page/medal-text.png"
              />

              <div class="medal-list">
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-1.png"
                />
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-2.png"
                />
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-3.png"
                />
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-4.png"
                />
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-5.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from "@/modules/app-design/components/Preview/StPageSet";
import {randomString} from "@/base/utils/tool";
const JUMP_LINK_MODEL = require("@/modules/app-design/assets/jsons/jump-link.json");
export default {
  name: "StMemberIndex",
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
    firstCompConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    const baseUrl =
      window.serverConfig.VUE_APP_ADMINURL || "https://saasapi.shetuan.pro";

    return {
      randomId: randomString(8),
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: "会员主页",
            isShow: 1,
          },
          readMemberName: 0,
          fontWeight: "normal",
          fontSize: 18,
          fontColor: "#000000",
          statusBarColor: "black",
          showHeaderJump: 1,
          paddingBottom: 0,
        },
        templateData: {
          template: "template1",
        },
        contentConfig: {
          positionMultiple: 1,
          bgType: "image",
          bgColor: "#FFFFFF",
          bgImage:
            baseUrl + "/cyc/images/wxapps/images/userbackground5.png",
          changeBgImage: 1,
          showElectroCard: 1,
          isShowTitleName: 1,
          showQuickEnter: 1,
          showMedal:1,
          quickEnterOne: {
            jump_link: JUMP_LINK_MODEL,
            label: "",
            value: "",
          },
          quickEnterTwo: {
            jump_link: JUMP_LINK_MODEL,
            label: "",
            value: "",
          },
          quickEnterThree: {
            jump_link: JUMP_LINK_MODEL,
            label: "",
            value: "",
          },
          quickEnterFour: {
            jump_link: JUMP_LINK_MODEL,
            label: "",
            value: "",
          },
        },
        otherConfig: {
          isShowReport: 1,
          isShowPrivateChat: 1,
          isShowFollow: 1,
        },
        shareConfig: {
          shareTitle: "",
          image: "",
          showShare: 1,
          showAppMessageShare: 1,
          showMomentShare: 1,
          showPosterShare: 1,
        },
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
      myList: [
        "quickEnterOne",
        "quickEnterTwo",
        "quickEnterThree",
        // "quickEnterFour",
      ],
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    noQuickEnters() {
      let emptyCounter = 0
      this.myList.forEach(l => {
        if (!this.myConfig.contentConfig[l].value) emptyCounter++
      })
      return emptyCounter >= 3
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  background: #ffffff;
  .type-select {
    position: absolute;
    top: 23px;
    width: 100%;
    padding: 0 18px;
    display: flex;
    justify-content: center;

    &.simple {
      position: static;
      display: none;
    }

    .type-item + .type-item {
      margin-left: 18px;
    }
    .type-item {
      display: flex;
      align-items: center;
      height: 34px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      line-height: 34px;
      .active {
        border-bottom: 2px #fff solid;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        line-height: 34px;
      }
      .select-icon {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
  }

  .start-top-img {
    width: 375px;
    height: 20px;
  }
  .start-top {
    padding-right: 7px;
    .header-jump {
      position: absolute;
      &.report{
        width: 32px;
        height: 32px;
      }
      &.default-back-icon {
        top: 32px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      &.template-back-icon {
        top: 25px;
        left: 5px;
        width: 78px;
        height: 32px;
      }
    }
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }
}

.me-content {
  padding: 147px 20px 0;
  position: relative;
  z-index: 0;

  .me-content-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 260px;
    .bg-image {
      height: 100%;
      width: 100%;
    }
  }

  .me-card {
    background-color: white;
    position: relative;
    padding-bottom: 17px;

    & + .me-card {
      margin-top: 8px;
    }

    .signin-icon {
      position: absolute;
      width: 72px;
      height: 25px;
      top: 40px;
      right: 0px;
    }
  }

  .template1 {
    text-align: center;
    .show-btn {
      position: absolute;
      top: -49px;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 25px;
      border-radius: 13px;
      background-color: rgba(255, 255, 255, 0.11);
      font-size: 12px;
      color: #ffffff;
      line-height: 17px;
    }
    .me-avatar {
      height: 34px;
      position: relative;

      .me-avatar-image {
        border-radius: 50%;
        background-color: $image-placeholder;
        width: 80px;
        height: 80px;
        position: absolute;
        bottom: 0;
        left: calc(50% - 40px);
      }
    }

    .me-nickname {
      margin-top: 8px;
      font-size: 16px;
      font-weight: bold;
      color: #191e29;
      line-height: 20px;
    }

    .me-intro {
      margin-top: 8px;
      font-size: 12px;
      color: #979da6;
      line-height: 12px;
    }
    .medal-box { 
        padding: 12px 12px 0;
        .medal {
          width: 100%;
          padding: 16px 15px;
          background-size: 100% 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .medal-text {
            width: 87px;
            height: 21px;
          }
          .medal-list {
            display: flex;
            align-items: center;

            .medal-item {
              width: 32px;
              height: 32px;
              // background-color: pink;
            }
            .medal-item + .medal-item {
              margin-left: 5px;
            }
          }
        }
      }
    .me-count {
      display: flex;
      padding: 17px 0 0;
      .count-item + .count-item {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 10px;
          bottom: 10px;
          border-left: 1px solid #e9e9e9;
        }
      }

      .count-item {
        position: relative;
        font-size: 14px;
        color: #888;
        width: 79px;
      }

      .count-num {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        line-height: 22px;
        margin-bottom: 3px;
      }

      .count-title {
        font-size: 12px;
        color: #999999;
        line-height: 17px;
      }
    }
  }
}


</style>
