<!--
 * @Author: dongjia
 * @Date: 2021-09-28 09:42:10
 * @LastEditTime: 2021-10-11 10:41:49
 * @LastEditors: aleaner
 * @Description: 会员主页模块组件
 * @FilePath: \app-design\components\Preview\StMemberContainer.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="msg-box" :style="{
    borderBottomLeftRadius: borderBottomRadius + 'px',
    borderBottomRightRadius: borderBottomRadius + 'px'
  }">
    <div class="box-header" v-if="contentConfig.titleConfig.isShow || isShowBtn">
      <div class="box-title" :style="{
          fontSize: contentConfig.fontSize + 'px',
          fontWeight: contentConfig.fontWeight,
          color: contentConfig.fontColor,
        }">{{contentConfig.titleConfig.isShow?contentConfig.titleConfig.name:''}}<span
          style="margin-left: 12px" v-if="contentNum">({{contentNum}})</span></div>
      <div class="edit-btn" v-if="isShowBtn">
        <div class="btn-text">{{headerBtnText}}</div><img class="btn-img"
          src="../../assets/images/st-member-index/handle-icon.png" />
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    contentConfig: {
      type: Object,
    },
    headerBtnText: {
      type: String,
      default: "编辑",
    },
    borderBottomRadius: {
      type: Number,
      default: 4,
    },
    contentNum: {
      type: Number,
      default: 0,
    },
    isShowBtn: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-box {
  width: 100%;
  background-color: #fff;
  .box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 0px 20px;
    .edit-btn {
      display: flex;
      align-items: center;
      .btn-text {
        font-size: 12px;
        color: #999999;
        line-height: 14px;
      }
      .btn-img {
        margin-left: 3px;
        width: 10px;
        height: 10px;
      }
    }
  }
}
</style>