<!--
 * @Author: dongjia
 * @Date: 2022-03-02 14:26:04
 * @LastEditTime: 2022-03-02 17:25:05
 * @LastEditors: aleaner
 * @Description: 社区导航我的话题列表
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\st-community-nav\NavMyTopic.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="my-topic-list" :style="{
    backgroundColor: bgColor
  }">
    <div class="list-item" v-for="(item, index) in defaultList" :key="index">
      <div class="item-top">
        <div class="top-icon">#</div>
        <div class="topic-name">{{item.name}}</div>
        <span class="user-count">{{item.user_count}} 人讨论</span>
        <span class="el-icon-arrow-right" style="font-size: 12px;color: #808080;margin-left: 4px;"></span>
      </div>
      <div class="item-post" v-if="item.posts.length">
        <el-image class="post-avatar" :src="require('../../../assets/images/st-me-page/avatar1.png')"
          fit="cover">
        </el-image>
        <div class="post-right">
          <div class="post-content">
            <span class="content-text" v-if="item.posts[0].content">{{item.posts[0].content}}</span>
            <span class="sub-content" v-if="item.posts[0].type !== 3">{{subContent(item.posts[0])}}</span>
          </div>
          <div class="comment-count">共 {{item.posts[0].comments_count}} 条回复</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const defaultList =
  require("../../../assets/jsons/default-list.json").stMyTopicList;
export default {
  props: {
    bgColor: {
      type: String,
      default: "#ffffff",
    },
  },
  data() {
    return {
      defaultList,
    };
  },
  methods: {
    subContent(data) {
      let content = "";
      switch (data.type) {
        case 0:
          content = `[${data.image_count}张图片]`;
          break;
        case 1:
          content = "[视频]";
          break;
        case 2:
          content = "[推荐帖子]";
          break;
      }
      return content;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-topic-list {
  padding: 16px;
  min-height: 551px;

  .list-item + .list-item {
    margin-top: 12px;
  }

  .list-item {
    padding: 12px;
    background-color: #fff;
    border-radius: 12px;

    .item-top {
      display: flex;
      align-items: center;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .top-icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 1;
        color: #fff;
        background-color: #ff495f;
        border-radius: 50%;
      }

      .topic-name {
        margin-left: 8px;
        width: 206px;
        font-size: 16px;
        font-weight: bold;
        color: #1a1a1a;
        line-height: 1.5;
        @include nowrap();
      }

      .user-count {
        margin-left: auto;
        font-size: 12px;
        color: #808080;
        line-height: 1;
      }

      .right-icon {
        margin-left: 8px;
        margin-left: auto;
        width: 6px;
        height: 10px;
      }
    }

    .item-middle {
      margin-top: 8px;
      margin-left: 28px;
      font-size: 12px;
      color: #1a1a1a;
      line-height: 1.5;
    }

    .item-bottom {
      display: flex;
      align-items: center;
      margin-top: 12px;
      margin-left: 28px;
      background-color: #f8f8f8;
      border-radius: 6px;
      padding: 11px 12px;

      text {
        font-size: 12px;
        color: #b3b3b3;
        line-height: 1.5;
        margin-left: 8px;
      }
    }

    .item-post {
      margin-top: 12px;
      display: flex;

      .post-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        flex-shrink: 0;
      }

      .post-right {
        flex: 1;
        margin-left: 8px;

        .post-content {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #1a1a1a;
          line-height: 1.5;

          .content-text {
            margin-right: 6px;
            @include clamp(1);
          }

          .sub-content {
            flex-shrink: 0;
          }
        }

        .comment-count {
          margin-top: 4px;
          font-size: 12px;
          color: #808080;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>