<template>
  <div class="st-gradients-setter">
    <st-radio-button-group v-model="value.enable" label="渐变效果" :options="options" />
    <st-color-picker v-if="!double" v-model="value.color" label="渐变颜色" :default-color="defaultColor || 'rgba(255, 112, 81, 1)'" />
    <template v-else>
      <st-color-picker v-model="value.colorTop" label="渐变颜色（上）" :default-color="defaultColorTop || 'rgba(255, 112, 81, 1)'" />
      <st-color-picker v-model="value.colorBottom" label="渐变颜色（下）" :default-color="defaultColorBottom || 'rgba(255, 112, 81, 1)'" />
    </template>
  </div>
</template>

<script>
  import StColorPicker from "@/modules/app-design/components/Form/StColorPicker";
  import StRadioButtonGroup from "@/modules/app-design/components/Form/StRadioButtonGroup";
  export default {
    name: "StGradientsSetter",
    components: {StRadioButtonGroup, StColorPicker},
    model: {
      prop: "inputValue",
      event: "change",
    },
    props: {
      inputValue: {
        type: [Object],
        default: null,
      },
      defaultColor: String,
      double: Boolean,
      defaultColorTop: String,
      defaultColorBottom: String
    },
    data() {
      return {
        options: [
          {value: 1, label: '开启'},
          {value: 0, label: '关闭'}
        ]
      }
    },
    computed: {
      value: {
        get() {
          return this.inputValue;
        },
        set(val) {
          this.$emit("change", val);
        },
      },
    },
    watch: {
      value: {
        deep: true,
        handler(val) {
          this.$emit("change", val);
        },
      },
    },
  }
</script>

<style scoped>

</style>
