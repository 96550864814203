<!--
 * @Author: dongjia
 * @Date: 2021-07-06 17:11:32
 * @LastEditTime: 2021-09-09 16:22:49
 * @LastEditors: aleaner
 * @Description: 个人中心-功能入口配置组件
 * @FilePath: \app-design\components\Preview\StUserCenterNav.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="center-nav" :style="{
      marginTop: 11 + myConfig.normal.marginTop + 'px',
      backgroundColor: myConfig.normal.bgType === 'color'?myConfig.normal.bgColor:'',
      backgroundImage: myConfig.normal.bgType === 'image'?`url(${fixImageUrl(myConfig.normal.bgImage,{h:500}, true)})`:''
    }">
    <div class="nav-list normal" v-if="myConfig.normal.showType === 'normal'" :style="{
      color: myConfig.normal.textColor
    }">
      <div class="nav-item" v-for="(item,index) in myConfig.tabs.list" :key="index">
        <p>{{item.name}}</p>
        <img class="nav-img" src="../../assets/images/st-me-page/handle-next.png" />
      </div>
    </div>
    <div class="nav-list special" v-if="myConfig.normal.showType === 'special'">
      <div class="nav-item" v-for="(item,index) in specialList" :key="index">
        <div class="nav-division" v-if="index > 0"></div>
        <div class="special-list">
          <div class="item-list" :style="{
        flex: `0 0 ${100/myConfig.normal.numLimit}%`,
      }" v-for="(nav,i) in item" :key="i">
            <el-image class="nav-icon" :src="nav.icon || require('../../assets/images/default-icon.png')">
            </el-image>
            <p class="nav-name" :style="{
              color: myConfig.normal.textColor
            }">{{nav.name}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const JUMP_LINK_MODEL = require('@/modules/app-design/assets/jsons/jump-link.json')
export default {
  name: "StUserCenterNav",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // defaultList: require("../../assets/jsons/default-list.json").StCustomNav,
      ch_sort: require("../../assets/jsons/ch-sort.json"),
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          showType: "normal",
          numLimit: 1,
          textColor: "#000000",
        },
        tabs: {
          list: [
            {
              name: "",
              icon: "",
              jump_link: null,
            },
          ],
        },
      },
      specialList: [],
      currentIndex: 0,
    };
  },
  watch: {
    "myConfig.normal.showType"(val) {
      if (val === "special") {
        this.handleSpecialList();
      }
    },
    "myConfig.normal.numLimit"(val) {
      this.handleSpecialList();
    },
    "myConfig.tabs.list"(val) {
      if (this.myConfig.normal.showType === "special") {
        this.handleSpecialList();
      }
    },
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  created() {
    this.handleSpecialList();
  },
  methods: {
    // 展示样式为九宫格时的导航数据重组
    handleSpecialList() {
      this.specialList = [];
      const row = Math.ceil(
        this.myConfig.tabs.list.length / this.myConfig.normal.numLimit
      );
      for (let i = 1; i <= row; i++) {
        const listItem = [];
        listItem = this.myConfig.tabs.list.slice(
          this.myConfig.normal.numLimit * (i - 1),
          this.myConfig.normal.numLimit * i
        );
        this.specialList.push(listItem);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.center-nav {
  margin: 0 12px;
  /*width: calc(100% - 12px * 2);*/
  min-height: 64px;
  background: #ffffff;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  .nav-list {
    &.normal {
      .nav-item + .nav-item {
        border-top: 1px solid #f1f1f1;
      }
      .nav-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 12%);
        margin: 0 auto;
        min-height: 64px;
        .nav-img {
          width: 24px;
          height: 24px;
        }
      }
    }

    &.special {
      padding: 13px 0;
      .nav-item {
        width: 100%;

        .nav-division {
          height: 1px;
          width: 90%;
          margin: 0 auto;
          background-color: #f1f1f1;
        }

        .special-list {
          display: flex;
          align-items: center;
          padding: 12px 0 15px 0;
          .item-list {
            width: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .nav-icon {
              width: 36px;
              height: 36px;
            }

            .nav-name {
              height: 14px;
              margin-top: 4px;
              font-size: 12px;
              color: #353535;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
