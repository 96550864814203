<!--
 * @Author: dongjia
 * @Date: 2021-09-28 14:52:45
 * @LastEditTime: 2021-10-13 13:57:26
 * @LastEditors: aleaner
 * @Description: 会员主页-个人信息编辑表单
 * @FilePath: \saas-admin-vue\src\modules\app-design\components\Form\StDragMemberMsg.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="drag-list st-drag-member-msg">
    <div>
      <p class="list-title">添加信息</p>
      <p class="form-tip">鼠标拖拽调整顺序，图标建议尺寸 {{`${100 * 3}*${100 * 3}`}}</p>
    </div>
    <draggable v-if="dataList" v-model="dataList" class="nav-list" animation="500" draggable=".nav-item">
      <template v-if="dataList.length > 0">
        <div class="nav-item" v-for="(data, index) in dataList" :key="index">
          <div class="item-header">
            <p class="header-name">信息内容{{index+1}}</p>
            <div class="del-btn cursor-pointer" @click="dataList.splice(index,1)">删除</div>
          </div>
          <div v-if="data.is_system">
            <div class="item-content">
              <div class="content-title">信息选择</div>
              <div class="content-handler">
                <el-input style="width: 100%" size="small" v-model="data.label" disabled></el-input>
                <div class="change-btn cursor-pointer" @click="handleChange(data, index)">修改</div>
              </div>
            </div>
            <div class="item-content">
              <div class="content-title">信息状态</div>
              <div class="content-handler">
                <el-radio-group style="margin-left: auto" v-model="data.isShow" size="small">
                  <el-radio-button :label="1">显示</el-radio-button>
                  <el-radio-button :label="0">隐藏</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div v-else class="item-content-box">
            <SingleMediaWall style="flex-shrink: 0" v-model="data.icon" :width="90" :height="90" ratio="100:100"
              addText="100*100">
            </SingleMediaWall>
            <div class="item-content-right">
              <div class="item-content">
                <div class="content-title">信息名称</div>
                <div class="content-handler">
                  <el-input style="width: 100%" size="small" v-model="data.label" disabled></el-input>
                  <el-checkbox class="handler-checkbox" v-model="data.isShowLabel" :true-label="0"
                    :false-label="1">隐藏</el-checkbox>
                </div>
              </div>
              <div class="item-content">
                <div class="content-title">信息选择</div>
                <div class="content-handler">
                  <el-input style="width: 100%" size="small" v-model="data.label" disabled></el-input>
                  <div class="change-btn cursor-pointer" @click="handleChange(data, index)">修改</div>
                </div>
              </div>
              <div class="item-content">
                <div class="content-title">信息状态</div>
                <div class="content-handler">
                  <el-radio-group style="margin-left: auto" v-model="data.isShow" size="small">
                    <el-radio-button :label="1">显示</el-radio-button>
                    <el-radio-button :label="0">隐藏</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="nav-item-add" :style="{marginTop: dataList.length>0?'24px':'0'}">
        <el-button style="width: 317px" size="mini" type="primary" plain @click="handleAdd">
          {{addButtonText}}
        </el-button>
      </div>
    </draggable>
    <memberMsgRadio v-model="dataSelectDialog" :selected="dataList" :currentLabel="currentLabel"
      @change="dataChange" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import memberMsgRadio from "../Normal/memberMsgRadio.vue";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
export default {
  name: "StDragMemberMsg",
  components: {
    draggable,
    memberMsgRadio,
    SingleMediaWall,
  },
  model: {
    prop: "list",
    event: "listChange",
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    formType: {
      type: String,
      default: "st-member-index",
    },
    addButtonText: {
      type: String,
      default: "添加信息",
    },
  },
  data() {
    return {
      dataSelectDialog: false,
      unDraggable: false,
      visible: false,
      routerName: {
        "st-brand-list": "editBrand",
        "st-banner": "edit-advert",
        "st-spirit-list": "AddSpirit",
        "st-article-list": "EditArticle",
        "st-special-article": "ArticleSubjectAddEdit",
        "st-activity-list": "ActivityDetail",
        "st-shop-goods": "WxStoreGoodsDetail",
      },
      currentLabel: "",
      current_index: -1,
    };
  },
  computed: {
    dataList: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit("listChange", val);
      },
    },
  },
  watch: {
    dataList: {
      handler(val) {
        this.$emit("listChange", val);
      },
      deep: true,
    },
  },
  methods: {
    // 点击选择数据
    dataChange(val) {
      if (this.current_index === -1) {
        this.dataList.push(val);
      } else {
        this.dataList.splice(this.current_index, 1, val);
      }
    },
    // 添加数据
    handleAdd() {
      this.current_index = -1;
      this.currentLabel = "";
      this.dataSelectDialog = true;
    },
    // 修改数据
    handleChange(data, i) {
      this.current_index = i;
      this.currentLabel = data.label;
      this.dataSelectDialog = true;
    },
  },
};
</script>

<style lang='scss' scoped>
.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.form-tip {
  font-size: 12px;
  color: #c0c4cc;
  padding-top: 8px;
  padding-bottom: 11px;
}
.nav-list {
  background-color: #f6f9ff;
  margin: 0 -19px 0 -24px;
  padding: 12px;
  margin-bottom: 20px;
}
.nav-item-add {
  width: 100%;
  text-align: center;
}
.nav-item {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;

  .item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .header-name {
      font-size: 16px;
      color: #3a3a3a;
      line-height: 16px;
      @include nowrap();
    }
    .del-btn {
      font-size: 14px;
      color: #3576ff;
      line-height: 14px;
    }
  }
  .item-content-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item-content-right {
      margin-left: 8px;
      .item-content + .item-content {
        margin-top: 8px;
      }
      .content-title {
        width: 70px;
      }
      .handler-checkbox {
        flex-shrink: 0;
        margin-left: 10px;
      }
    }
  }

  .item-content + .item-content {
    margin-top: 12px;
  }
  .item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content-title {
      width: 120px;
      flex-shrink: 0;
      font-size: 14px;
      color: #3a3a3a;
      line-height: 14px;
      @include nowrap();
    }
    .content-handler {
      display: flex;
      align-items: center;
      .change-btn {
        flex-shrink: 0;
        font-size: 14px;
        color: #3576ff;
        line-height: 14px;
        margin-left: 14px;
      }
    }
  }
}

.nav-item + .nav-item {
  margin-top: 12px;
}

.drag-list {
  ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(53, 118, 255, 0.15);
    border: 1px solid #3576ff;
    color: #3576ff;
    box-shadow: none;
  }
}
</style>
