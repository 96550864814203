<!--
 * @Author: dongjia
 * @Date: 2021-08-03 16:22:56
 * @LastEditTime: 2021-08-05 18:24:10
 * @LastEditors: aleaner
 * @Description: 地图组件地址搜索表单
 * @FilePath: \app-design\components\Form\StAddressForm.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="address-form" v-loading="searching">
    <el-input v-model="value.city" placeholder="城市"></el-input>
    <el-form
      :model="value"
      ref="addressForm"
      size="small"
      @submit.native.prevent="() => {}"
    >
      <el-form-item
        :show-message="!value.lat"
        :error="!value.lat ? '请输入地址' : ''"
        prop="lat"
      >
        <!-- <el-input style="margin-top: 12px;" :disabled="!value.city" v-model="value.detail" placeholder="具体地址"> -->
        <el-select
          style="margin-top: 12px; width: 100%"
          :value="value.detail"
          filterable
          remote
          reserve-keyword
          placeholder="具体地址"
          @change="handleChange"
          :remote-method="remoteSearching"
          :disabled="!value.city"
          clearable
          @blur="handleDetailAddress"
        >
          <el-option
            v-for="item in addressOptions"
            :key="item.uid"
            :label="
              item.title ? `${item.title}（${item.address}）` : item.address
            "
            :value="item.address"
          >
          </el-option>
        </el-select>
        <!-- </el-input> -->
        <!-- <div class="form-box">style="width: 165px"
          <el-input :disabled="!value.city" v-model="value.detail" placeholder="具体地址"></el-input>
          <el-button class="search-btn" type="text" @click="search">搜索</el-button>
        </div> -->
      </el-form-item>
    </el-form>
    <!--    <el-input style="margin-top: 12px;width: 100%" :disabled="true" v-model="value.lat" placeholder="经度"></el-input>-->
    <!--    <el-input style="margin-top: 12px;width: 100%" :disabled="true" v-model="value.lng" placeholder="纬度"></el-input>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'StAddressForm',
  model: {
    prop: 'addressData',
    event: 'addressChange',
  },
  props: {
    addressData: {
      type: Object,
      default: () => {
        return {
          city: '',
          detail: '',
          lat: 0,
          lng: 0,
          address_name: '',
        }
      },
    },
  },
  computed: {
    value: {
      get() {
        return this.addressData
      },
      set(val) {
        this.$emit('addressChange', val)
      },
    },
    ...mapState('appDesign', ['addressOptions', 'searching']),
  },
  watch: {
    searching(newValue, oldValue) {
      if (!newValue && oldValue) {
        // 搜索结束
        this.handleChange(this.value.detail)
      }
    },
  },
  methods: {
    /**
     * 远程搜索，根据关键词检索下拉选项地址
     */
    remoteSearching(e) {
      this.value.detail = e
      this.$store.commit('appDesign/setSearching', true)
    },
    handleChange(e) {
      this.value.detail = e
      const addressData = this.addressOptions.find((el) => el.address === e)
      if (addressData) {
        this.value.lat = addressData.point.lat
        this.value.lng = addressData.point.lng
        this.value.address_name = addressData.title || ''
      } else {
        this.handleDetailAddress()
      }
    },
    // search() {
    //   if (this.value.city && this.value.detail) {
    //     this.$emit('addressChange', this.value)
    //   } else if (!this.value.city) {
    //     this.$message.error('请输入城市')
    //   } else if (!this.value.detail) {
    //     this.$message.error('请输入具体地址')
    //   }
    // },
    handleDetailAddress() {
      if (this.searching) return // 粘贴的地址可能还在检索可能的结果
      if (!this.value.detail) {
        this.value.lat = ''
        this.value.lng = ''
        this.value.detail = ''
        this.value.address_name = ''
        return
      }
      if (
        this.addressOptions.map((a) => a.address).includes(this.value.detail)
      ) {
        // 地址携带的经纬度是有效的
        this.$refs.addressForm.clearValidate()

        this.value.address_name = this.addressOptions.find(
          (a) => a.address
        )?.title
      } else {
        // console.log('所填地址无效')
        if (this.addressOptions.length) {
          const { point, title, address } = this.addressOptions[0] // 默认帮选第一个备选地址的经纬度

          // const {lng, lat} = this.bdMapToTxMap(point.lat, point.lng)
          // this.value.lat = lat
          // this.value.lng = lng
          // console.log('腾讯地图坐标准备好了', lat, lng)

          this.value.lat = point.lat
          this.value.lng = point.lng
          this.value.address_name = ''
        } else {
          this.value.lat = ''
          this.value.lng = ''
          this.value.detail = ''
          this.value.address_name = ''
        }
      }
    },
    /**
     * 百度地图经纬度转为腾讯地图经纬度
     * @param {Number} lat
     * @param {Number} lng
     * @returns
     */
    bdMapToTxMap(lat, lng) {
      let pi = (3.14159265358979324 * 3000.0) / 180.0
      let x = lng - 0.0065
      let y = lat - 0.006
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * pi)
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * pi)
      lng = z * Math.cos(theta)
      lat = z * Math.sin(theta)
      return { lng: lng, lat: lat }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-box {
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
  .search-btn {
    margin-left: 11px;
  }
}
</style>
