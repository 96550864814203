<template>
  <div class="backup-page">
    <el-tabs v-model="activeName" :tabs="tabArr">
      <el-tab-pane v-for="(tab) in tabArr" :key="tab.name" :label="tab.label" :name="tab.name" />
    </el-tabs>
    <template v-if="activeName === 'backup'">
      <div class="table-btn">
        <el-button type="primary" size="small" @click="showAddBackup">添加备份</el-button>
      </div>
      <list-layout ref="listLayout" :thead="thead" :filter-form="{}"
                   table-height="calc(100vh - 54px - 20px * 2 - 40px - 20px - 32px - 40px - 32px - 20px)"
                   :on-fetch="getBackupList" v-loading="loading">
        <template #item_name="{row}">
          <p v-if="row.editable">
            {{row.name}}（{{row.code}}<el-button type="text" @click="copyText(row.code)">复制</el-button>）
          </p>
          <span v-else>{{row.name}}</span>
        </template>
        <template #operation="{row}">
          <!-- TODO: 编辑备份，跳转到编辑器，页面接口带上 pageset 参数 -->
          <el-button v-if="row.editable === 1" type="text" @click="toEditBackup(row)">编辑</el-button>
          <el-button type="text" @click="previewBackup(row)">预览</el-button>
          <el-button type="text" @click="rollbackBackup(row)">还原</el-button>
          <el-button type="text" @click="delBackup(row)">删除</el-button>
        </template>
        <el-dialog title="备份" class="dialog-vertical" :visible="addDialog.show" close-on-click-modal
                   width="500px" append-to-body v-if="addDialog.show" @close="addDialog.show = false">
          <el-form class="small-form" size="medium" label-width="85px" @submit.native.prevent>
            <el-form-item label="备份名称：" required>
              <el-input v-model="addDialog.data.name" v-focus @keyup.enter.native="addBackup" placeholder="请输入内容" />
              <div class="info">
                <i class="el-icon-info" />备份将保留您当前全部页面的布局以及数据设置
              </div>
            </el-form-item>
          </el-form>
          <template #footer>
            <el-button size="small" @click="addDialog.show = false">取消</el-button>
            <el-button size="small" type="primary" :loading="addDialog.saveLoading" @click="addBackup">确定</el-button>
          </template>
        </el-dialog>
        <el-dialog title="提示" class="dialog-vertical" :visible="rollbackDialog.show"
                   width="450px" append-to-body close-on-click-modal @close="rollbackDialog.show = false">
          <p>还原后，将不保留原<span style="color: red;">设计样式和所有数据</span>。确定还原吗？</p>
          <template #footer>
            <el-button size="small"
                       :loading="rollbackDialog.directLoading" :disabled="rollbackDialog.backupLoading"
                       @click="rollback(true)">直接还原</el-button>
            <el-button size="small" type="primary"
                       :loading="rollbackDialog.backupLoading" :disabled="rollbackDialog.directLoading"
                       @click="rollback()">备份并还原</el-button>
          </template>
        </el-dialog>
        <!-- 预览二维码弹窗 -->
        <preview-code-dialog :postData="{ ...postData, ...previewDialog.data }"
                             v-model="previewDialog.show" :title="previewDialog.title" tips="扫码预览" />
      </list-layout>
    </template>
    <template v-if="activeName === 'rollback'">
      <list-layout ref="listLayout2" :thead="thead2" :on-fetch="getRecords" />
    </template>
  </div>
</template>

<script>
  import ListLayout from '@/base/layout/ListLayout'
  import {backup, backupList, deleteBackup, records, restoreBackup} from '../../api/page'
  import PreviewCodeDialog from './PreviewCodeDialog'
  import {copyText} from '@/base/utils/tool'
  export default {
    name: 'BackupPage',
    components: {PreviewCodeDialog, ListLayout},
    inject: ['postData'],
    data() {
      return {
        loading: false,
        activeName: "backup",
        tabArr: [
          {label: "备份", name: "backup"},
          {label: "还原", name: "rollback"}
        ],
        thead: [
          {label: "备份名称", prop: "name", type: 'slot', minWidth: 250, tooltip: true},
          {label: "备份时间", prop: "create_time", width: 220},
          {label: "操作", type: "operation", width: 200}
        ],
        addDialog: {
          show: false,
          data: {
            name: ""
          },
          saveLoading: false,
        },
        previewDialog: {
          show: false,
          loading: false,
          title: "",
          data: {
            project_id: "",
            pageset: ""
          }
        },
        rollbackDialog: {
          show: false,
          data: {
            project_id: "",
            code: "",
            is_backup: 1,
          },
          directLoading: false,
          backupLoading: false,
        },
        thead2: [
          {label: '说明', prop: 'content'},
          {label: '操作时间', prop: 'create_time', width: 200}
        ]
      }
    },
    watch: {
      activeName(newValue) {
        this.$nextTick(() => {
          switch (newValue) {
            case 'backup':
              this.$refs.listLayout.getList()
              break;
            case 'rollback':
              this.$refs.listLayout2.getList()
              break;
          }
        })
      }
    },
    methods: {
      getRecords(query) {
        return records({
          ...query,
          project_id: this.postData.project_id
        })
      },
      toEditBackup(row) {
        let routerData = this.$router.resolve({
          name: "AppDesignPage",
          params: {
            platform: this.$route.params.platform,
            id: row.project_id
          },
          query: {
            pageset: row.code
          }
        });
        window.open(routerData.href, "_blank");
      },
      copyText(txt) {
        copyText(txt).then(res => {
          this.$message.success('已复制')
        }).catch(err => {
          console.log(err)
        })
      },
      rollback(directly = false) {
        if (directly) {
          this.rollbackDialog.directLoading = true
        } else {
          this.rollbackDialog.backupLoading = true
        }
        this.rollbackDialog.data.is_backup = !directly ? 1 : 0
        restoreBackup({
          ...this.rollbackDialog.data
        }).then(res => {
          if (directly) {
            this.rollbackDialog.directLoading = false
          } else {
            this.rollbackDialog.backupLoading = false
          }
          this.rollbackDialog.show = false
          this.$message.success(res.msg)

          this.$store.commit('appDesign/setStateAttr', [
            {key: 'pageDetail', val: null},
            {key: 'backupJson', val: ''},
            {key: 'bottomNavBackupJson', val: ''}
          ])

          // 不刷新页面修改地址栏参数
          this.$emit('update-location-search', {})

          /** 它们俩的区别是，
           * 触发调用 getPages() 时 init 参数是否为 true，
           * 后者为 true；
           * 如果为 true，刷新页面数组后，
           * 假如地址栏参数有 id 或者 system_page，会自动切换到相应页面，
           * 否则，将自动切换至页面数组的第一个页面。
           */
          // this.$store.commit("appDesign/setIsRefreshPages", true)
          this.$bus.emit("allowGetPageDetail");

          this.$emit('close')
        }).catch(err => {
          console.log(err)
          if (directly) {
            this.rollbackDialog.directLoading = false
          } else {
            this.rollbackDialog.backupLoading = false
          }
        })
      },
      previewBackup({name, project_id, code}) {
        this.previewDialog.data.project_id = project_id
        this.previewDialog.data.pageset = code
        this.previewDialog.title = `“${name}”预览`
        this.previewDialog.show = true
      },
      delBackup({project_id, code}) {
        this.$confirm('将永久删除该备份文件，是否继续', '提示', {
          type: 'danger'
        }).then(res => {
          this.loading = true
          deleteBackup({
            project_id,
            code
          }).then(res => {
            this.loading = false
            this.$message.success(res.msg)
            this.$refs.listLayout.getList()
          }).catch(err => {
            console.log(err)
            this.loading = false
          })
        }).catch(err => {
          console.log(err)
        })
      },
      rollbackBackup({project_id, code}) {
        this.rollbackDialog.data.project_id = project_id
        this.rollbackDialog.data.code = code
        this.rollbackDialog.show = true;

        // const h = this.$createElement;
        // this.$msgbox({
        //   title: '提示',
        //   type: 'info',
        //   message: h('p', null, [
        //     '还原后，将不保留原',
        //     h('span', {style: 'color: red;'}, '设计样式和所有数据'),
        //     '。确定还原吗？'
        //   ]),
        //   distinguishCancelAndClose: true,
        //   showCancelButton: true,
        //   confirmButtonText: '备份并还原',
        //   cancelButtonText: '直接还原',
        //   beforeClose: (action, instance, done) => {
        //     console.log(action, 'beforeClose')
        //     if (action === 'close') {
        //       done()
        //     } else {
        //       this.loading = true
        //       restoreBackup({
        //         project_id,
        //         code,
        //         is_backup: action === 'confirm' ? 1 : 0
        //       }).then(res => {
        //         done()
        //         this.loading = false
        //         this.$message.success(res.msg)
        //         this.$refs.listLayout.getList()
        //       }).catch(err => {
        //         console.log(err)
        //         this.loading = false
        //       })
        //     }
        //   }
        // }).then(res => {
        //   console.log(res, 'then')
        // }).catch(err => {
        //   console.log(err, 'error')
        //   /* 关闭和取消都走catch */
        // })
      },
      getBackupList(query) {
        return backupList({
          ...query,
          project_id: this.postData.project_id
        })
      },
      showAddBackup() {
        this.addDialog.data.name = ''
        this.addDialog.show = true
      },
      addBackup() {
        this.addDialog.saveLoading = true
        backup({
          project_id: this.postData.project_id,
          ...this.addDialog.data
        }).then(res => {
          this.addDialog.saveLoading = false
          this.addDialog.show = false
          this.$message.success(res.msg)
          this.$refs.listLayout.getList()
        }).catch(err => {
          console.log(err)
          this.addDialog.saveLoading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.backup-page {
  width: 100%;
  padding: $space 66px;

  .el-tabs {
    width: 100%;
  }

  .table-btn {
    margin-top: $space;
  }
}
</style>
