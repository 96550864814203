var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-broadcast",style:({
    borderRadius: _vm.myConfig.normal.borderRadius + 'px',
    marginTop: ((_vm.myConfig.normal.marginTop) + "px"),
    marginLeft: ((_vm.myConfig.normal.padding) + "px"),
    marginRight: ((_vm.myConfig.normal.padding) + "px"),
    padding: '0 12px',
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? ("" + (_vm.myConfig.normal.bgColor)) : '',
    backgroundImage:
      _vm.myConfig.normal.bgType === 'image'
        ? ("url(" + (_vm.fixImageUrl(_vm.myConfig.normal.bgImage, {}, true)) + ")")
        : '',
  })},[(_vm.myConfig.contentConfig.styleType === 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"messageBroadcast",style:({
      borderRadius: _vm.myConfig.contentConfig.borderRadius + 'px',
      // paddingRight: `${myConfig.normal.padding}px`,
      // paddingLeft: `${myConfig.normal.padding}px`,
      padding: "0 16px",
      height: ((_vm.myConfig.normal.boxHeight) + "px"),
      boxShadow: _vm.myConfig.normal.boxShadow
        ? ("0 2px " + (_vm.myConfig.normal.boxShadow) + "px 0 rgba(0,0,0,0.06)")
        : 'none',
      backgroundColor: ("" + (_vm.myConfig.contentConfig.bgColor)),
    })},[_vm._m(0),_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-content",style:({ fontSize: ((_vm.myConfig.contentConfig.fontSize) + "px") })},[_vm._v(" "+_vm._s(this.titleList[0].title)+" ")])]),_vm._m(1)]):_vm._e(),(_vm.myConfig.contentConfig.styleType === 'template2')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"messageBroadcast",style:({
      borderRadius: _vm.myConfig.contentConfig.borderRadius + 'px',
      // 模板二默认 12px
      padding: "0 12px",
      height: ((_vm.myConfig.normal.boxHeight) + "px"),
      boxShadow: _vm.myConfig.normal.boxShadow
        ? ("0 2px " + (_vm.myConfig.normal.boxShadow) + "px 0 rgba(0,0,0,0.06)")
        : 'none',
      backgroundColor: ("" + (_vm.myConfig.contentConfig.bgColor)),
    })},[_c('div',{staticClass:"broadcast",style:({
        marginLeft: '0px' || ((_vm.myConfig.normal.padding) + "px"),
      })},[(_vm.myConfig.contentConfig.iconColorType === 'custom')?_c('i',{staticClass:"el-icon-a-Slice172",style:({
          color: ("" + (_vm.myConfig.contentConfig.iconColor)),
        })}):_c('i',{staticClass:"el-icon-a-Slice172 theme-color"})]),_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-content",style:({ fontSize: ((_vm.myConfig.contentConfig.fontSize) + "px") })},[_vm._v(" "+_vm._s(this.titleList[0].title)+" ")])]),_c('div',{staticClass:"more-broadcast",style:({
        marginRight: '0px' || ((_vm.myConfig.normal.padding) + "px"),
      })},[_c('i',{staticClass:"el-icon-arrow-right"})])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"broadcast"},[_c('img',{attrs:{"src":require("../../assets/images/st-broadcast/broadcast-icon.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"more-broadcast"},[_c('i',{staticClass:"el-icon-arrow-right"})])}]

export { render, staticRenderFns }