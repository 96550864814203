<!--
 * @Author: dongjia
 * @Date: 2021-10-16 15:05:00
 * @LastEditTime: 2022-03-01 14:02:40
 * @LastEditors: aleaner
 * @Description: 社区导航组件（旧）
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StCommunityNavOld.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="custom-nav" :style="{
      marginTop: myConfig.normal.marginTop + 'px !important',
      marginLeft: `${myConfig.normal.rowMargin}px`,
      marginRight: `${myConfig.normal.rowMargin}px`,
      backgroundColor: myConfig.normal.bgColor,
    }">
    <div class="nav-list">
      <div class="nav-item" v-for="(item,index) in defaultList" :key="index">
        <el-image class="nav-image" v-if="item.leftIcon" :src="item.leftIcon" fit="cover"></el-image>
        <div :class="['nav-name',index === currentIndex?'active':'']" :style="{
        color: index === currentIndex?myConfig.normal.selectedColor:myConfig.normal.defaultColor
      }">
          {{item.name}}
          <div class="nav-active" :style="{
          backgroundColor:myConfig.normal.selectedColor
        }" v-if="index === currentIndex"></div>
        </div>
        <el-image class="nav-image" v-if="item.rightIcon" :src="item.rightIcon" fit="cover"></el-image>
      </div>
    </div>
    <StPostList class="nav-content" :style="{
      backgroundColor: myConfig.normal.contentBgColor,
    }" :list="contentList">
    </StPostList>
    <div class="publish-btn">
      <img class="publish-icon" src="../../assets/images/st-source/publish-icon.png" />
    </div>
  </div>
</template>

<script>
import StPostList from "./StPostList";
export default {
  name: "StCommunityNavOld",
  components: { StPostList },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultList: require("../../assets/jsons/default-list.json")
        .StCommunityNav,
      contentList: require("../../assets/jsons/default-list.json").StPostList,
      ch_sort: require("../../assets/jsons/ch-sort.json"),
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          defaultColor: "",
          selectedColor: "",
          bgColor: "",
          contentBgColor: "",
          rowMargin: 0,
        },
      },
      currentIndex: 0,
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-nav {
  position: relative;

  .nav-list {
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-around;
    overflow-x: scroll;
    overflow: hidden;
  }

  .nav-item {
    margin-left: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 12px;

    .nav-name {
      font-size: 14px;
      line-height: 42px;
      position: relative;
      &.active {
        font-size: 18px;
        font-weight: bold;
      }
      .nav-active {
        position: absolute;
        height: 3px;
        width: calc(100% - 12px);
        bottom: 0;
        left: 50%;
        margin-left: calc((100% - 10px) / -2);
      }
    }
  }

  .publish-btn {
    position: absolute;
    bottom: 65px;
    right: 17px;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-color);
    .publish-icon {
      width: 24px;
      height: 24px;
    }
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
