var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member-index"},[_c('div',{staticClass:"header"},[(_vm.platform !== 'h5')?[_c('img',{staticClass:"start-top-img",attrs:{"src":_vm.statusBar[_vm.myConfig.normal.statusBarColor]}}),_c('div',{staticClass:"start-top",staticStyle:{"text-align":"end"}},[(_vm.myConfig.otherConfig.isShowReport)?_c('img',{staticStyle:{"height":"32px","width":"32px","margin-right":"10px"},attrs:{"src":require("../../assets/images/st-me-page/report-icon2.png")}}):_vm._e(),(_vm.myConfig.normal.showHeaderJump)?_c('img',{staticClass:"header-jump",class:[_vm.headerJump.showConfig.showType !== 'template1'?'template-back-icon':'default-back-icon'],attrs:{"src":_vm.backIcon[_vm.myConfig.normal.statusBarColor][_vm.headerJump.showConfig.showType]}}):_vm._e(),_c('img',{staticClass:"start-top-handler",attrs:{"src":_vm.handleIcon[_vm.myConfig.normal.statusBarColor]}})])]:_c('st-page-set',{attrs:{"config":_vm.myConfig}}),_c('div',{staticClass:"type-select",class:[_vm.platform === 'h5' ? 'simple' : '']},[(_vm.myConfig.normal.titleConfig.isShow)?_c('div',{staticClass:"type-item",style:({
        color: _vm.myConfig.normal.fontColor,
        fontWeight: _vm.myConfig.normal.fontWeight
      })},[_vm._v(_vm._s(_vm.myConfig.normal.readMemberName?'xxx的主页': _vm.myConfig.normal.titleConfig.name)+" ")]):_vm._e()])],2),_c('div',{staticClass:"me-content"},[_c('div',{staticClass:"me-content-bg",style:({
      backgroundColor: _vm.myConfig.contentConfig.bgType === 'color'?_vm.myConfig.contentConfig.bgColor:''
    })},[(_vm.myConfig.contentConfig.bgType === 'image')?_c('el-image',{staticClass:"bg-image",attrs:{"src":_vm.fixImageUrl(_vm.myConfig.contentConfig.bgImage,{h:400}, _vm.randomId),"fit":"cover"}}):_vm._e()],1),_c('div',{staticClass:"me-card-list"},[(_vm.myConfig.templateData.template === 'template1')?_c('div',{staticClass:"me-card template1",style:({
        borderRadius: _vm.firstCompConfig.normal && _vm.firstCompConfig.normal.marginTop===0?'4px 4px 0 0':'4px'
      })},[(_vm.myConfig.contentConfig.showElectroCard)?_c('div',{staticClass:"show-btn"},[_vm._v("电子名片")]):_vm._e(),_vm._m(0),_c('div',{staticClass:"me-nickname"},[_vm._v("社团云演示")]),(_vm.myConfig.contentConfig.isShowTitleName)?_c('div',{staticClass:"me-intro"},[_vm._v("组织架构 | 会内职务")]):_vm._e(),(_vm.myConfig.contentConfig.isShowTitleName && _vm.myConfig.contentConfig.positionMultiple)?_c('div',{staticClass:"me-intro"},[_vm._v("组织架构 | 会内职务2")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.myConfig.contentConfig.showQuickEnter && !_vm.noQuickEnters),expression:"myConfig.contentConfig.showQuickEnter && !noQuickEnters"}],staticClass:"me-count flex-center"},_vm._l((_vm.myList),function(item,index){return (_vm.myConfig.contentConfig[item].value)?_c('div',{key:index,staticClass:"count-item flex-col"},[_c('span',{staticClass:"count-num"},[_vm._v("99")]),_c('span',{staticClass:"count-title"},[_vm._v(_vm._s(_vm.myConfig.contentConfig[item].jump_link.jump_config.name))])]):_vm._e()}),0),(_vm.myConfig.contentConfig.showMedal)?_c('div',{staticClass:"medal-box"},[_c('div',{staticClass:"medal",style:({
              backgroundImage: ("url(" + (require('../../assets/images/st-me-page/medal-box.png')) + ")"),
            })},[_c('img',{staticClass:"medal-text",attrs:{"src":require("../../assets/images/st-me-page/medal-text.png")}}),_vm._m(1)])]):_vm._e()]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"me-avatar"},[_c('img',{staticClass:"me-avatar-image",attrs:{"src":require("../../assets/images/st-me-page/avatar1.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medal-list"},[_c('img',{staticClass:"medal-item",attrs:{"src":require("../../assets/images/st-me-page/medal-1.png")}}),_c('img',{staticClass:"medal-item",attrs:{"src":require("../../assets/images/st-me-page/medal-2.png")}}),_c('img',{staticClass:"medal-item",attrs:{"src":require("../../assets/images/st-me-page/medal-3.png")}}),_c('img',{staticClass:"medal-item",attrs:{"src":require("../../assets/images/st-me-page/medal-4.png")}}),_c('img',{staticClass:"medal-item",attrs:{"src":require("../../assets/images/st-me-page/medal-5.png")}})])}]

export { render, staticRenderFns }