<template>
  <div class="gov-activity-item">
    <div class="card-cover">
      <st-load-image class="cover-image" :src="activity.cover" :size="500" />
    </div>
    <div class="timeline-card">
      <div class="card-title clamp2">{{activity.name}}</div>
      <div class="card-author">活动发起：{{activity.project_name}}</div>
      <div class="card-start-time">
        <div class="start-time-text">
          <div class="start-time-left">
            <span class="dot"></span>
            <span>{{activity.activity_time_text_three}}</span>
          </div>
          <span class="status-btn"
            :class="{'off-btn': activity.activity_status_text_three === '已结束',
            ['status-'+activity.activity_status_text_two]: true,
            }">{{activity.activity_status_text_three}}</span>
          <!--          <span v-if="true || noJoin" class="status-btn"-->
          <!--                :class="(activity.activity_status_text_two === 4 ? 'off-btn':'active-btn')">-->
          <!--            {{activity.activity_status_text_three}}-->
          <!--          </span>-->
        </div>
      </div>
      <div class="card-bottom" v-if="false && !noJoin" :style="{
				borderTop: !noJoin ? '1px dashed #E7E7E7' : ''
			}">
        <div class="card-bottom-left">
          <!-- 线下 -->
          <template v-if="activity.type">
            <!-- 活动报名未开始 -->
            <div class="join-num" v-if="activity.activity_status_text_two === 6">还没有开始报名</div>
            <!-- 没有人 -->
            <template v-if="activity.activity_users_count === 0">
              <!-- 活动未开始 -->
              <!-- <div class="join-num" v-if="activity.activity_status_text_two === 2">暂时没人报名</div> -->
              <!-- 活动进行中 -->
              <!-- <div class="join-num" v-if="activity.activity_status_text_two === 3">暂时没人观看</div> -->
              <!-- 活动已结束 -->
              <!-- <div class="join-num" v-if="activity.activity_status_text_two === 4">没人参加</div> -->
              <!-- 活动报名中 -->
              <!-- <div class="join-num" v-if="activity.activity_status_text_two === 5">暂时没人报名</div> -->
            </template>
            <!-- 有人 -->
            <template v-if="activity.activity_users_count">
              <div class="join-num" v-if="activity.activity_status_text_two === 2">
                {{activity.activity_users_count}} 人报名</div>
              <div class="join-num" v-if="activity.activity_status_text_two === 3">
                {{activity.activity_users_count}} 人看过</div>
              <div class="join-num" v-if="activity.activity_status_text_two === 4">
                {{activity.activity_users_count}} 人报名</div>
              <div class="join-num" v-if="activity.activity_status_text_two === 5">
                {{activity.activity_users_count}} 人报名</div>
            </template>
          </template>
          <!-- 线上 -->
          <template v-else>
            <!-- 直播未开始 -->
            <div class="join-num" v-if="activity.activity_status_text_two === 6">直播还未开始</div>
            <!-- 没人 -->
            <template v-if="activity.activity_users_count === 0">
              <!-- 未开始 -->
              <div class="join-num" v-if="activity.activity_status_text_two === 2">直播还未开始</div>
              <!-- 进行中 -->
              <div class="join-num" v-if="activity.activity_status_text_two === 3">直播已开始</div>
              <!-- 已结束 -->
              <!-- <div class="join-num" v-if="activity.activity_status_text_two === 4">没人观看</div> -->
            </template>
            <!-- 有人 -->
            <template v-if="activity.activity_users_count">
              <div class="join-num"
                v-if="activity.activity_status_text_two === 2 || activity.activity_status_text_two === 6">
                {{activity.activity_users_count}} 人想看</div>
              <div class="join-num" v-if="activity.activity_status_text_two === 3">
                {{activity.activity_users_count}} 人在看</div>
              <div class="join-num" v-if="activity.activity_status_text_two === 4">
                {{activity.activity_users_count}} 人看过</div>
            </template>
          </template>
        </div>
        <div class="card-bottom-right">
          <!--          <span class="status-btn"-->
          <!--                :class="activity.activity_status_text_two === 1 ? '':(activity.activity_status_text_two === 4 ? 'off-btn':'active-btn')">-->
          <!--            {{activity.activity_status_text_three}}-->
          <!--          </span>-->
          <span class="status-btn" :class="['status-'+activity.activity_status_text_two]">{{activity.activity_status_text_three}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StAvatars from "@/modules/app-design/components/Preview/Common/StAvatars";
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";
export default {
  name: "StActivityItemGov",
  components: {StLoadImage, StAvatars },
  props: {
    activity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultImage: require("../../assets/images/mini-default-icon@2x.png"),
    };
  },
  computed: {
    noJoin() {
      let activity = this.activity;
      let type = activity.type;
      let count = activity.activity_users_count;
      let status = activity.activity_status_text_two;
      /* 1、线上直播无人观看 */
      /* 2、线下活动无人 */
      return (
        (type == 0 && !count && status == 4) ||
        (type && !count && status >= 2 && status <= 5)
      );
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss">
.gov-activity-item {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

  background-color: #fff;
  overflow: hidden;

  .card-cover {
    width: 100%;
    height: 184px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    // border: 1rpx solid #c1c1c1;
    position: relative;

    .cover-image {
      width: 100%;
      height: 100%;
    }
  }

  .timeline-card {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 16px 0;

    .card-title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 22px;
    }

    .card-author {
      font-size: 12px;
      line-height: 12px;
      margin-top: 12px;
    }

    .status-btn {
      font-size: 12px;
      text-align: center;
      height: 24px;
      display: inline-block;

      border-radius: 2px;
      padding: 6px;
      line-height: 1;

      &.status-5 {
        background-color: rgba(255, 0,0,0.1);
        color: red;
      }

      &.status-3 {
        background-color: rgba(255, 123, 27, 0.1);
        color: rgba(255, 123, 27, 1);
      }
    }

    .active-btn {
      background-color: var(--help-color);
      color: var(--main-color);
    }

    .off-btn {
      background-color: #ececec;
      color: #979da6;
    }

    .card-start-time {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 15px;

      .start-time-text {
        flex: 1;
        font-size: 12px;
        line-height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .start-time-left {
          display: flex;
          align-items: center;

          .dot {
            width: 6px;
            height: 6px;
            background: #07c160;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }
    }

    .card-bottom {
      width: 100%;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px dashed #e7e7e7;

      .card-bottom-left {
        display: flex;
        align-items: center;

        .join-num {
          font-size: 12px;
          color: #666;
          line-height: 22px;
        }
      }

      .card-bottom-right {
        display: flex;
      }
    }
  }
}
</style>

