// import store from '@/base/store'
// const baseUrl = window.serverConfig.VUE_APP_BASEURL

export default {
  getRules(getVm, value, platform, others) {
    // getVm: 在 update 中调用

    return {
      normal: {
        isShow: 0,
        rules: []
      },
    }
  },
}
