var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-contacts"},[_c('div',{staticClass:"header"},[(_vm.platform !== 'h5')?[_c('img',{staticClass:"start-top-img",attrs:{"src":_vm.statusBar[_vm.myConfig.normal.statusBarColor]}}),_c('div',{staticClass:"start-top",staticStyle:{"text-align":"end"}},[(_vm.myConfig.normal.showHeaderJump)?_c('img',{staticClass:"header-jump",class:[
            _vm.headerJump.showConfig.showType !== 'template1'
              ? 'template-back-icon'
              : 'default-back-icon' ],attrs:{"src":_vm.backIcon[_vm.myConfig.normal.statusBarColor][
              _vm.headerJump.showConfig.showType
            ]}}):_vm._e(),_c('img',{staticClass:"start-top-handler",attrs:{"src":_vm.handleIcon[_vm.myConfig.normal.statusBarColor]}})])]:_c('st-page-set',{attrs:{"config":_vm.myConfig}}),_c('div',{staticClass:"type-select",class:[_vm.platform === 'h5' ? 'simple' : '']},[(_vm.myConfig.normal.titleConfig.isShow)?_c('div',{staticClass:"type-item",style:({
          color: _vm.myConfig.normal.fontColor,
          fontWeight: _vm.myConfig.normal.fontWeight,
        })},[_vm._v(" "+_vm._s(_vm.myConfig.normal.titleConfig.name)+" ")]):_vm._e()]),_vm._m(0)],2),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab-list",class:[_vm.platform === 'h5' ? 'simple' : '']},[_vm._m(1),_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"content-list"},[_c('div',{staticClass:"company-num"},[_vm._v(" 共 "),_c('span',{staticStyle:{"color":"#db1010"}},[_vm._v(_vm._s(_vm.defaultList.length))]),_vm._v(" 家企业 ")]),_vm._l((_vm.defaultList),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[_c('div',{staticClass:"item-top"},[_c('img',{staticClass:"item-img",attrs:{"src":require("../../assets/images/st-company-library/company-icon.png")}}),_c('div',{staticClass:"item-desc"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-status"},[(item.isList)?_c('img',{staticClass:"status-icon",attrs:{"src":require("../../assets/images/st-company-library/company-list.png")}}):_vm._e(),(item.isAuth)?_c('img',{staticClass:"status-icon",attrs:{"src":require("../../assets/images/st-company-library/company-auth.png")}}):_vm._e()])])]),(_vm.myConfig.contentConfig.showInformation.length)?_c('div',{staticClass:"item-middle"},[_c('div',{staticClass:"item-data"},[_vm._l((_vm.myConfig.contentConfig.showInformation),function(key){return [_c('div',{staticClass:"data-item"},[_vm._l((_vm.selectOpt),function(item){return [(item.value === key)?[_c('div',{staticClass:"data-top"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('div',{staticClass:"data-bottom"},[_vm._v(_vm._s(item.sample))])]:_vm._e()]})],2)]})],2)]):_vm._e()])})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-search"},[_c('p',{staticClass:"search-icon el-icon-search"}),_c('p',{staticClass:"search-tip"},[_vm._v("搜索企业")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active"},[_c('p',{staticClass:"item-name"},[_vm._v("地区 "),_c('i',{staticClass:"el-icon-caret-bottom"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-item"},[_c('p',{staticClass:"item-name"},[_vm._v("行业 "),_c('i',{staticClass:"el-icon-caret-bottom"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-item"},[_c('p',{staticClass:"item-name"},[_vm._v("更多筛选 "),_c('i',{staticClass:"el-icon-caret-bottom"})])])}]

export { render, staticRenderFns }