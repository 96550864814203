<template>
  <!--
		图片懒加载组件

		src 不需要处理裁剪再传进来
		这里会根据 size 进行裁剪，并且优化占位图的显示模式（contain、auto)
		占位图，来自 /uni_modules/app-framework/static/default/default-icon.png，尺寸 100*100
		slot 插槽可以自定义图片加载失败的替换内容
		mode 和 <image> 的 mode 用法一致，默认是 aspectFill
	 -->
  <div
    class="base-load-image image-wrapper lazyload lazypic"
    :class="{ loaded: loaded, proportion: proportion !== '' }"
    :style="myStyle"
  >
    <div v-if="!src || error" class="error">
      <slot />
    </div>
    <img
      v-else
      class="image"
      :style="{ 'object-fit': objectFit }"
      :src="fixSpecialImageUrl(src, size)"
      @load="imageOnLoad"
      @error="imageOnError"
    />
  </div>
</template>

<script>
import { randomString } from "@/base/utils/tool";

export default {
  name: "st-load-image",
  data() {
    return {
      loaded: false,
      error: false,
      randomId: randomString(16),
    };
  },
  props: {
    // TODO:
    // randomId: {
    // 	type: [Number, String],
    // 	default: randomString(16)
    // },
    src: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "aspectFill",
    },
    size: {
      type: Number,
      default: 900,
    },
    // 按比例显示图片,单位：%
    proportion: {
      type: String,
      default: "",
    },
    borderRadius: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    myStyle() {
      let bgSize = this.size <= 200 ? "contain" : "unset";
      return `--bg-size: ${bgSize}; --bg-radius: ${this.borderRadius}px; --image-propor: ${this.proportion}`;
    },
    objectFit() {
      switch (this.mode) {
        case "aspectFill":
          return "cover";
        case "aspectFit":
          return "contain";
        default:
          return "unset";
      }
    },
  },
  watch: {
    src(newValue) {
      if (!newValue) {
        this.loaded = false;
      }
    },
  },
  methods: {
    imageOnLoad() {
      setTimeout(() => {
        this.loaded = true;
        this.$emit("imgLoad");
      }, 100);
    },
    imageOnError() {
      this.error = true;
      this.$emit("imgError");
    },
    fixSpecialImageUrl(src, sizeStr, ...others) {
      let str = others ? others.join("_") : "";
      const isPageset = this.$route.query.pageset;
      return this.fixImageUrl(
        src,
        {
          w:
            sizeStr === "small"
              ? 200
              : sizeStr === "medium"
              ? 300
              : sizeStr === "large"
              ? 500
              : sizeStr || 200,
        },
        isPageset ? `${this.randomId}_${str}_s` : ""
      );
    },
  },
};
</script>

<style lang="scss">
.error {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}

.base-load-image {
  width: 100%;
  height: 100%;

  .image {
    width: 100%;
    height: 100%;
    border-radius: var(--bg-radius);
  }
}

.proportion {
  width: 100%;
  height: auto !important;
  position: relative;
  &::before {
    content: "";
    padding-top: var(--image-propor);
    display: block;
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--bg-radius);
  }
}

.lazyload {
  position: relative;
  overflow: hidden;

  &.lazypic {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(../../../assets/images/default-icon.png);
      background-color: rgb(243, 245, 247);
      background-size: var(--bg-size);
      background-position: center;
      background-repeat: no-repeat;
      // opacity: 0.3;

      border-radius: var(--bg-radius);
    }
  }

  img {
    position: relative;
    z-index: 1;
    opacity: 0;
  }

  &.loaded img {
    transition: opacity 0.7s;
    opacity: 1 !important;
  }

  &.loaded {
    &:after {
      opacity: 0;
    }
  }
}
</style>
