<template>
  <div class="source">
    <div class="header">
      <template v-if="platform !== 'h5'">
        <img class="start-top-img" :src="statusBar[myConfig.normal.statusBarColor]">
        <div class="start-top" style="text-align: end">
          <img v-if="myConfig.normal.showHeaderJump" class="header-jump"
            :class="[headerJump.showConfig.showType !== 'template1'?'template-back-icon':'default-back-icon']"
            :src="backIcon[myConfig.normal.statusBarColor][headerJump.showConfig.showType]" />
          <img class="start-top-handler" :src="handleIcon[myConfig.normal.statusBarColor]">
        </div>
      </template>
      <st-page-set v-else :config="myConfig" />
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div class="type-item" v-if="myConfig.normal.titleConfig.isShow" :style="{
          color: myConfig.normal.fontColor,
          fontWeight: myConfig.normal.fontWeight
        }">{{myConfig.normal.titleConfig.name}}
        </div>
      </div>
    </div>
    <div class="source-content">
      <div class="content-top">
        <div class="category-scroll">
          <div :class="['scroll-item',index === active?'active':'']"
            v-for="(categoryName, index) in categoryArr" :key="index">
            {{categoryName}}
          </div>
        </div>
        <div class="category-filter">
          <el-image class="filter-icon"
            :src="require('../../assets/images/st-activity-images/filter-icon.png')" fit="contain"></el-image>
          <p>更多</p>
        </div>
      </div>
      <stActivityPhoto :config="myConfig" from="systemPage" />
    </div>
  </div>
  </div>
</template>

<script>
import StPageSet from "../Preview/StPageSet";
import stActivityPhoto from "../Preview/StActivityPhoto";
export default {
  name: "StActivityImages",
  components: { StPageSet, stActivityPhoto },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categoryArr: ["全部", "分类一", "分类二", "分类三", "分类四"],
      activityImages: require("../../assets/jsons/default-list.json")
        .activityImages,
      active: 0,
      platform: this.$route.params.platform,
      model: {
        normal: {
          titleConfig: {
            name: "云相册",
            isShow: 1,
          },
          fontWeight: "normal",
          fontColor: "#000000",
          bgColor: "",
          statusBarColor: "black",
          showHeaderJump: 1,
        },
        templateData: {
          styleType: "template1",
        },
      },
      // 状态栏图片
      statusBar: {
        black: require("../../assets/images/phone-statusbar1@2x.png"),
        white: require("../../assets/images/phone-statusbar2@2x.png"),
      },
      // 头部右上角胶囊图标
      handleIcon: {
        black: require("../../assets/images/wechat-handle1@2x.png"),
        white: require("../../assets/images/wechat-handle2@2x.png"),
      },
      // 头部左上角图标链接
      backIcon: {
        black: {
          template1: require("../../assets/images/middle/Back1@2x.png"),
          template2: require("../../assets/images/middle/homeBack1@2x.png"),
          template3: require("../../assets/images/middle/moreBack1@2x.png"),
          template4: require("../../assets/images/middle/moreBack1@2x.png"),
        },
        white: {
          template1: require("../../assets/images/middle/Back2@2x.png"),
          template2: require("../../assets/images/middle/homeBack2@2x.png"),
          template3: require("../../assets/images/middle/moreBack2@2x.png"),
          template4: require("../../assets/images/middle/moreBack2@2x.png"),
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.source {
  width: 375px;
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
}

.start-top-img {
  width: 375px;
  height: 20px;
}
.start-top {
  padding-right: 7px;
  .header-jump {
    position: absolute;

    &.default-back-icon {
      top: 32px;
      left: 11px;
      width: 20px;
      height: 20px;
    }

    &.template-back-icon {
      top: 25px;
      left: 5px;
      width: 78px;
      height: 32px;
    }
  }
}
.start-top-handler {
  width: 87px;
  height: 34px;
}

.header {
  width: 100%;
  .type-select {
    position: absolute;
    top: 23px;
    width: 100%;
    padding: 0 18px;
    display: flex;
    justify-content: center;

    &.simple {
      position: static;
      display: none;
    }

    .type-item + .type-item {
      margin-left: 18px;
    }
    .type-item {
      display: flex;
      align-items: center;
      height: 34px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      line-height: 34px;
      .active {
        border-bottom: 2px #fff solid;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        line-height: 34px;
      }
      .select-icon {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
  }
}

.source-content {
  .content-top {
    padding: 10px 0 0 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    .category-scroll {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      height: 32px;
      .scroll-item + .scroll-item {
        margin-left: 24px;
      }
      .scroll-item {
        position: relative;
        flex-shrink: 0;
        font-size: 14px;
        color: #b3b3b3;
        line-height: 1.5;
        &.active {
          font-weight: bold;
          color: var(--main-color);
          &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: -5px;
            right: 4px;
            left: 4px;
            height: 2px;
            background-color: var(--main-color);
          }
        }
      }
    }
    .category-filter {
      padding: 5px 10px;
      position: absolute;
      right: 0;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 32px;
      font-size: 12px;
      color: #666666;
      line-height: 12px;
      z-index: 2;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        box-shadow: 0px 0px 9px 12px #fff;
      }
      .filter-icon {
        margin-right: 4px;
        width: 12px;
        height: 12px;
      }
    }
  }
  .content-list {
    padding: 15px 20px 0;
    width: 100%;
    min-height: calc(667px - 122px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f7f8f9;
    align-content: flex-start;
    .list-item {
      height: 100%;
      background-color: #fff;
      flex-shrink: 0;
      margin-bottom: 11px;
      overflow: hidden;
      border-radius: 4px;
      .item-top {
        width: 162px;
        height: 84px;
        &.default {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #dcdfe6;
          .default-icon {
            width: 28px;
            height: 28px;
            z-index: 2;
          }
        }
      }
      .item-bottom {
        width: 142px;
        margin: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #191e29;
        line-height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
  ::v-deep.brand-list {
    background-color: #f9f9f9 !important;
  }
}
</style>
