<template>
  <div class="st-login-page start-left flex-col">
    <st-page-set title="登录" :jump-config="jumpConfig"></st-page-set>
    <div :class="['img-swiper', !myConfig.normal.logo ? 'none' : '']">
      <el-image
        v-if="myConfig.normal.logo"
        class="start-top-img"
        :src="myConfig.normal.logo"
        fit="cover"
      >
      </el-image>
      <div v-else class="start-top-img"></div>
      <div class="login-tip flex-center" v-if="myConfig.normal.loginTip">
        <div class="login-tip-text">{{ myConfig.normal.loginTip }}</div>
      </div>
    </div>
    <div class="edition-number">
      <div v-if="currentLoginMethod === 'rapid-login'" class="login-btn">
        快速登录
      </div>
      <div v-else class="login-btn">验证码登录</div>
      <!-- 登录代表您已同意<span>{{myConfig.normal.agreementTitle || '《用户使用条款》'}}</span> -->
      <div class="login-tip">
        <div class="check-box"></div>
        <div class="protocol-text">
          我已阅读并同意 <span>《用户服务协议》</span>及<span>《隐私政策》</span
          ><template v-if="myConfig.agreementConfig.titleConfig.isShow"
            >,<span
              >《{{ myConfig.agreementConfig.titleConfig.name }}》</span
            ></template
          >
        </div>
      </div>
    </div>
    <div class="flex-1"></div>
    <div
      v-if="myConfig.showConfig.loginMethod.length === 2"
      class="switch-method flex-center"
    >
      <div
        v-if="currentLoginMethod === 'code-login'"
        class="method flex-col flex-align-center"
        @click="currentLoginMethod = 'rapid-login'"
      >
        <img
          class="icon"
          :src="
            require('@/modules/app-design/assets/images/st-login-page/rapid.png')
          "
        />
        <span class="name">快速登录</span>
      </div>
      <div
        v-if="currentLoginMethod === 'rapid-login'"
        class="method flex-col flex-align-center"
        @click="currentLoginMethod = 'code-login'"
      >
        <img
          class="icon"
          :src="
            require('@/modules/app-design/assets/images/st-login-page/code.png')
          "
        />
        <span class="name">验证码登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'

export default {
  name: 'st-login-page',
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
    jumpConfig: Object,
  },
  data() {
    const libModel = require('../../assets/jsons/component-library.json').system
      .loginPage.props
    return {
      model: JSON.parse(JSON.stringify(libModel)),
      platform: this.$route.params.platform,
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
    currentLoginMethod() {
      if (this.myConfig.showConfig.loginMethod.includes('rapid-login'))
        return 'rapid-login'
      return 'code-login'
    },
  },
}
</script>

<style lang="scss" scoped>
.start-left {
  width: 375px;
  height: 661px;
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;

  ::v-deep .st-nav {
    overflow: visible;
  }

  .start-top-img {
    width: 256px;
    height: 76px;
    margin: 0 auto;
    display: block;
  }

  .img-swiper {
    height: 300px;
    padding-top: 160px;

    &.none {
      .start-top-img {
        background: #f5f7fa;
        // border: 1px solid #dcdfe6;
      }
    }
    .login-tip {
      height: calc(100% - 76px);
      width: 256px;
      margin: 0 auto;
      .login-tip-text {
        height: fit-content;
        white-space: pre-wrap;
      }
    }
  }
  .edition-number {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-btn {
      width: 307px;
      height: 48px;
      background: var(--main-color);
      text-align: center;
      border-radius: 66px;
      line-height: 50px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      margin: 0 auto;
    }
    .login-tip {
      margin-top: 16px;
      display: flex;
      .check-box {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #efefef;
      }
      .protocol-text {
        font-size: 12px;
        display: flex;
        align-items: center;
        width: 270px;
        flex-wrap: wrap;

        span {
          color: var(--main-color);
        }
      }
    }
  }
  .switch-method {
    padding: 17px 0;

    .icon {
      width: 36px;
      height: 36px;
    }

    .name {
      color: #808080;
      font-size: 12px;
      margin-top: 16px;
    }
  }
}
</style>
