<!--
 * @Author: dongjia
 * @Date: 2021-09-07 14:58:51
 * @LastEditTime: 2021-09-15 11:14:16
 * @LastEditors: aleaner
 * @Description: 活动详情页
 * @FilePath: \app-design\components\Preview\StActivityDetail.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="source st-activity-detail"
    :style="{
      backgroundColor: myConfig.normal.bgColor,
    }"
  >
    <st-page-set :config="myConfig" :jump-config="headerJump" />

    <div
      class="source-content"
      :style="platform !== 'h5' ? 'margin-top: -64px' : ''"
    >
      <div class="head-bg-wrapper">
        <img
          class="bg"
          :src="`${baseImgUrl}/app_design/st-activity-album-detail/cover.png`"
        />
      </div>
      <div class="bg-mask"></div>
      <div class="half-rect"></div>
      <div class="title-wrapper flex-between">
        <div class="flex flex-align-center">
          <img :src="defaultGroupIcon" class="logo" />
          <span class="black">社团云</span>
          <span class="gray">举办 ：</span>
        </div>
        <activity-status-btn :activity="{activity_status_text_two: 3}" />
      </div>
      <div class="title">商会课程之商学院六月课程 - 低成本运作品牌</div>
      <div class="tags">
        <span class="tag">商业知识</span>
        <span class="tag">干货分享</span>
        <span class="tag">会员福利</span>
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'
import Activity from '../../../invite-members/views/CycIntroducePage/activity'
import ActivityStatusBtn from './activity-status-btn/activity-status-btn'

export default {
  name: 'StActivityDetail',
  components: {
    ActivityStatusBtn,
    Activity,
    StPageSet,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseImgUrl: '',
      platform: this.$route.params.platform,
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        '/cyc/images/wxapps/icons/avatar1.png',
      defaultGroupIcon:
        window.serverConfig.VUE_APP_ADMINURL +
        '/app_design/images/favicon-48x48.jpg',
      model: {
        normal: {
          titleConfig: {
            name: '活动详情',
            isShow: 1,
          },
          fontWeight: 'normal',
          fontColor: '#fff',
          bgColor: '#F6F6F6',
          statusBarColor: 'white',
          showHeaderJump: 1,
          timeType: 0,
        },
        serviceBtn: {
          isShow: 0,
          fixBottom: 92,
          template: 'template1',
          showPosition: 'right',
          payStatus: 1,
        },
      },
    }
  },
  created() {
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss" scoped>
.source {
  width: 375px;
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
}

.source-content {
}

.head-bg-wrapper {
  width: 375px;
  height: 211px;

  .bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.half-rect {
  width: 375px;
  height: 35px;
  border-radius: 16px 16px 0 0;
  background-color: rgba(246, 246, 246, 1);
  //margin-top: -35px;
  margin-top: -17px;
  position: relative;
}

.bg-mask {
  width: 375px;
  /*height: 60px;*/
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(248, 248, 248, 1) 100%
  );
  //margin-top: -60px;
  position: relative;
}

.logo {
  width: 24px;
  height: 24px;
  background-color: $image-placeholder;
  border-radius: 50%;
}

.black,
.gray {
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  line-height: 18px;
}

.black {
  margin: 0 8px;
}

.gray {
  color: #b3b3b3;
}

.title-wrapper {
  margin-top: -20px;
  position: relative;
  padding: 0 16px;
}

.status {
  width: 66px;
  height: 18px;
  border-radius: 2px;
  background: rgba(128, 128, 128, 0.1);

  color: rgba(128, 128, 128, 1);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.title {
  margin-top: 12px;
  color: rgba(26, 26, 26, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding: 0 16px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  margin-top: 8px;

  .tag {
    width: 56px;
    height: 18px;
    border-radius: 1px;
    border: 1px solid rgba(26, 117, 255, 1);

    color: rgba(26, 117, 255, 1);
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-right: 4px;
  }
}
</style>
