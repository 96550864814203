var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-index"},[_c('StPageSet',{staticClass:"header-fixed",attrs:{"config":_vm.myConfig,"jump-config":_vm.headerJump,"bg-color":"transparent"}}),_c('div',{staticClass:"page-bg",style:({
        backgroundColor:
          _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
      })},[(_vm.myConfig.normal.bgType === 'image')?_c('img',{staticClass:"page-bg-img",attrs:{"alt":"","src":_vm.fixImageUrl(
            _vm.myConfig.normal.bgImage || _vm.defaultBg,
            { w: 900 },
            _vm.randomId
          )}}):_vm._e(),_c('div',{staticClass:"bg-content"},[_c('el-image',{staticClass:"page-logo",attrs:{"src":_vm.myConfig.contentConfig.logo || _vm.defaultLogo}}),(_vm.myConfig.contentConfig.titleConfig.isShow)?_c('div',{staticClass:"page-name",style:({
            fontSize: _vm.myConfig.contentConfig.fontSize + 'px',
            fontWeight: _vm.myConfig.contentConfig.fontWeight,
            color: _vm.myConfig.contentConfig.fontColor,
          })},[_vm._v(" "+_vm._s(_vm.myConfig.contentConfig.titleConfig.name)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"contacts-content"},[_c('div',{staticClass:"mian-content"},[(_vm.myConfig.contactsConfig.titleConfig.isShow)?_c('div',{staticClass:"content-title",style:({
            fontSize: _vm.myConfig.contactsConfig.fontSize + 'px',
            fontWeight: _vm.myConfig.contactsConfig.fontWeight,
            color: _vm.myConfig.contactsConfig.fontColor,
          })},[_vm._v(" "+_vm._s(_vm.myConfig.contactsConfig.titleConfig.name)+" ")]):_vm._e(),(_vm.infoList.length)?_c('div',{staticClass:"content-item flex",staticStyle:{"flex-wrap":"wrap","align-items":"flex-start"}},[_vm._l((_vm.infoList),function(c){return [(c.isShow && c.contents.length)?_c('div',{staticClass:"content-name"},[_c('p',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(c.label || '标题'))]),_vm._l((c.contents),function(cc,cci){return [(c.is_system)?[(cc.value)?_c('div',{staticClass:"type-list"},_vm._l((_vm.contactTypeArrResult.filter(
                        function (r) { return r.label === cc.label; }
                      )),function(item,itemI){return _c('div',{key:itemI,staticClass:"type-item"},[_c('img',{attrs:{"src":item.icon}}),_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))])])}),0):(cci === 0)?_c('span',[_vm._v(_vm._s('' || '请输入'))]):_vm._e()]:(
                    /^1[3-9]\d{9}$/.test(cc.value) ||
                    /^0\d{2,3}-\d{7,8}|\(?0\d{2,3}[)-]?\d{7,8}|\(?0\d{2,3}[)-]*\d{7,8}$/.test(
                      cc.value
                    )
                  )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(cc.isShow),expression:"cc.isShow"}],key:cc.value,staticClass:"item-flex"},[_c('img',{staticStyle:{"width":"14px","height":"14px"},attrs:{"src":require("../../assets/images/st-contact-us/phone-icon.png")}}),_c('div',{staticClass:"item-name phone"},[_vm._v(_vm._s(cc.value || '请输入'))])]):_c('span',{directives:[{name:"show",rawName:"v-show",value:(cc.isShow),expression:"cc.isShow"}]},[_vm._v(_vm._s(cc.value || '请输入')+" "),(
                      /^(\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3})|([\d]{6})$/.test(
                        cc.value
                      )
                    )?_c('span',{staticClass:"copy-btn"},[_vm._v("复制")]):_vm._e()])]})],2):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"content-item"},[_vm._m(0),_c('div',{staticClass:"item-flex address"},[_c('div',{staticClass:"item-name"},[_vm._v(" "+_vm._s(_vm.myConfig.mapConfig.addressSign || _vm.fullAddress)+" ")]),_c('div',{staticClass:"address-btn"},[(_vm.myConfig.mapConfig.addressSign || _vm.fullAddress)?_c('img',{staticStyle:{"width":"12px","height":"12px","margin-right":"3px"},attrs:{"src":require("../../assets/images/st-contact-us/address-icon.png")}}):_vm._e(),_vm._v(" 到这里 ")])]),_c('baidu-map',{staticClass:"bmView",attrs:{"center":_vm.location,"zoom":_vm.zoom,"scroll-wheel-zoom":"","map-click":false},on:{"ready":_vm.loadMap}},[_c('bm-marker',{attrs:{"dragging":false,"position":{
                lng: _vm.location.lng || 116.404,
                lat: _vm.location.lat || 39.915,
              }}},[_c('bm-label',{attrs:{"content":_vm.myConfig.mapConfig.addressSign || _vm.fullAddress,"labelStyle":{ color: 'red', fontSize: '24px' },"offset":{ width: -35, height: 30 }}})],1),_c('bm-local-search',{staticStyle:{"display":"none"},attrs:{"keyword":_vm.fullAddress,"auto-viewport":false},on:{"searchcomplete":_vm.getLocation}}),_c('bm-view',{style:({
                width: '100%',
                marginTop: '12px',
                height:
                  _vm.myConfig.mapConfig.mapHeightType === 'normal'
                    ? '162px'
                    : _vm.myConfig.mapConfig.mapHeight + 'px',
                borderRadius: '4px',
              })}),_c('bm-info-window',{attrs:{"position":_vm.location,"show":_vm.loaded &&
                _vm.myConfig.mapConfig.address.city !== '' &&
                _vm.myConfig.mapConfig.address.detail !== '',"width":0,"height":0,"maxWidth":220,"closeOnClick":false,"autoPan":""}},[_c('p',{staticClass:"address-sign"},[_vm._v(" "+_vm._s(_vm.myConfig.mapConfig.addressSign || _vm.fullAddress)+" ")])])],1)],1)])]),(_vm.myConfig.shareConfig.showShare)?_c('div',{staticClass:"share-btn"},[_c('img',{staticClass:"share-icon",attrs:{"src":require("../../assets/images/st-contact-us/share-icon.png")}})]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-name"},[_c('p',[_vm._v("地址")])])}]

export { render, staticRenderFns }