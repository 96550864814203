import store from '@/base/store'
import { allCategories } from '@/modules/app-design/api/components'

export default {
  getRules(getVm, value, platform, others) {
    // getVm: 在 update 中调用

    return {
      pageJump: {
        title: '前往添加活动数据',
        routeName: 'ActivityList',
        isShow: 1,
      },
      normal: {
        title: '常规设置',
        rules: [
          {
            type: 'StNumberSlider',
            field: 'marginTop',
            native: true,
            display: true,
            props: {
              label: '组件间距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'bgType',
            native: true,
            props: {
              title: '背景设置',
              options: [
                {
                  label: '颜色',
                  value: 'color',
                },
                {
                  label: '图片',
                  value: 'image',
                },
              ],
            },
          },
          {
            type: 'StColorPicker',
            field: 'bgColor',
            title: '背景颜色',
            display: true,
            props: {
              showAlpha: true,
              defaultColor: 'rgba(255, 255, 255, 0)',
            },
            link: ['bgType'],
            update: (val, rule, fApi, init = false) => {
              rule.display = fApi.form.bgType === 'color'
            },
          },
          {
            type: 'SingleMediaWall',
            field: 'bgImage',
            title: '背景图片',
            display: true,
            props: {
              width: 60,
              height: 60,
              addText: '',
            },
            style: {
              float: 'right',
            },
            link: ['bgType'],
            update: (val, rule, fApi, init = false) => {
              rule.display = fApi.form.bgType === 'image'
            },
          },
          {
            type: 'StNumberSlider',
            field: 'rowMargin',
            native: true,
            display: true,
            props: {
              label: '两侧边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'rowPadding',
            native: true,
            props: {
              label: '内侧边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'colPadding',
            native: true,
            props: {
              label: '上下边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'borderRadius',
            native: true,
            props: {
              label: '圆角设置',
              min: 0,
              max: 48,
            },
          },
        ],
      },
      templateData: {
        title: '模板设置',
        rules: [
          {
            type: 'StTemplateRadio',
            field: 'styleType',
            native: true,
            display: true,
            props: {
              title: '选择模板',
              options: [
                {
                  name: '模板1',
                  value: 'template1',
                },
                {
                  name: '模板2',
                  value: 'template3',
                },
                {
                  name: '模板3',
                  value: 'template4',
                },
                {
                  name: '直播（竖版）',
                  value: 'live',
                },
                {
                  name: '直播（横版）',
                  value: 'live-horizontal',
                }
              ],
              suggestText: {
                template1: '319*240',
                template3: '168*95',
                template4: '120*90',
                live: '150*150',
              },
            },
            update: (val, rule, fApi, init = false) => {
              const _this = getVm()

              if (val === 'live' || val === 'live-horizontal') {
                _this.rulesArr.dataConfig.rules.find(
                  (r) => r.field === 'activity_type'
                ).props.disabled = true
                _this.myValue.dataConfig.activity_type = 0 // 只能是线上活动

                _this.rulesArr.messageConfig.rules.find(
                  (r) => r.field === 'showTime'
                ).display = false
              } else {
                if (!init) {
                  _this.rulesArr.dataConfig.rules.find(
                    (r) => r.field === 'activity_type'
                  ).props.disabled = false
                  _this.myValue.dataConfig.activity_type = -1
                  _this.rulesArr.messageConfig.rules.find(
                    (r) => r.field === 'showTime'
                  ).display = true
                }
              }

              if (val === 'template2') {
                _this.myValue.templateData.styleType =
                  'template1' /* 模板2已废弃，兼容至模板1 */
              }

              // _this.rulesArr.showConfig.isShow = val !== "template1" ? 1 : 0;
              _this.myValue.loadingConfig.loadingType =
                val !== 'template4' && val !== 'live'
                  ? _this.myValue.loadingConfig.loadingType
                  : 'none'
              let filterKey = ['loadingType', 'handleType', 'handleText']
              if (val === 'template4' || val === 'live' || val === 'live-horizontal') {
                _this.rulesArr.loadingConfig.rules.forEach((el) => {
                  if (filterKey.includes(el.field)) {
                    el.display = false
                  }
                })
              } else {
                _this.rulesArr.loadingConfig.rules.forEach((el) => {
                  if (el.field === 'loadingType') {
                    el.display = true
                  }
                })
              }

              if (val === 'template2' || val === 'template4') {
                if (!init) {
                  // 模板二默认图片在右边
                  _this.myValue.listStyle.imageText =
                    val === 'template2' ? 'right' : 'left'
                  _this.myValue.templateData.borderRadius = 16
                } else if (init) {
                  if (!_this.rulesArr.listStyle.rules[0].value) {
                    _this.myValue.listStyle.imageText =
                      val === 'template2' ? 'right' : 'left'
                  }
                }

                // 支持设置图片在左或者在右
                // _this.rulesArr.listStyle.isShow = 1
                _this.rulesArr.listStyle.rules[0].display = true
              } else {
                // _this.rulesArr.listStyle.isShow = 0
                _this.rulesArr.listStyle.rules[0].display = false
              }

              if (!init && val === 'template3') {
                _this.myValue.templateData.borderRadius = 12
              }

              if (!init && val === 'template1') {
                _this.myValue.templateData.borderRadius = 16
              }
            },
          },
          {
            type: 'StNumberSlider',
            field: 'borderRadius',
            native: true,
            display: true,
            props: {
              label: '圆角设置',
              min: 0,
              max: 16,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'dataSpacing',
            native: true,
            display: true,
            props: {
              label: '数据间距',
              min: 8,
              max: 40,
            },
          },
        ],
      },
      dataConfig: {
        title: '数据设置',
        rules: [
          {
            type: 'StRadioButtonGroup',
            field: 'dataType',
            display: true,
            native: true,
            props: {
              title: '数据筛选',
              options: [
                {
                  label: '选择数据',
                  value: 'select',
                },
                {
                  label: '过滤设置',
                  value: 'filter',
                },
              ],
            },
          },
          {
            type: 'StDragDataList',
            field: 'dataArr',
            display: true,
            native: true,
            props: {
              formType: 'st-activity-list',
              itemTitle: '活动名称',
              addButtonText: '添加活动数据',
              getFilterForm: () => ({
                activity_type: getVm().myValue.dataConfig.activity_type,
              }),
            },
            link: ['dataType'],
            update: (val, rule, fApi) => {
              rule.display = fApi.form.dataType === 'select'
            },
          },
          {
            type: 'select',
            field: 'dataShowType',
            title: '数据类型',
            display: true,
            options: [
              {
                label: '自有平台数据',
                value: 'self_data',
                disabled: false,
              },
              {
                label: '协会互联数据',
                value: 'gov_data',
                disabled: false,
              },
            ],
            props: {
              multiple: true,
              collapseTags: true,
            },
            link: ['dataType'],
            update: (val, rule, fApi) => {
              rule.display = fApi.form.dataType === 'filter'
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'dataAll',
            display: true,
            native: true,
            props: {
              title: '分类过滤',
              options: [
                {
                  label: '全部分类',
                  value: 1,
                },
                {
                  label: '自定义',
                  value: 0,
                },
              ],
            },
            link: ['dataType'],
            update: (val, rule, fApi) => {
              rule.display = fApi.form.dataType === 'filter'
            },
          },

          {
            type: 'select',
            field: 'category_names',
            title: '选择分类',
            display: true,
            options: [],
            props: {
              multiple: true,
              collapseTags: true,
            },
            link: ['dataType', 'dataAll'],
            update: (val, rule, fApi) => {
              rule.display =
                fApi.form.dataType === 'filter' && !fApi.form.dataAll

              allCategories({ data_sources: fApi.form.dataShowType })
                .then((res) => {
                  rule.options = res.data
                  if (!rule.value.length) {
                    rule.value = res.data.map((item) => item.value)
                  }
                })
                .catch((err) => {})
            },
          },
          {
            type: 'select',
            field: 'dataFilter',
            title: '活动状态',
            display: true,
            //活动类型选项数据
            options: [
              {
                label: '未开始',
                value: 0,
                disabled: false,
              },
              {
                label: '活动中',
                value: 1,
                disabled: false,
              },
              {
                label: '已结束',
                value: 2,
                disabled: false,
              },
            ],
            props: {
              multiple: true,
              collapseTags: true,
            },
            link: ['dataType'],
            update: (val, rule, fApi) => {
              rule.display = fApi.form.dataType === 'filter'
              if (val.length === 0) {
                fApi.form.dataFilter = [0, 1, 2]
              }
              if (val.length === 1) {
                rule.options.forEach((el) => {
                  if (el.value === val[0]) {
                    el.disabled = true
                  }
                })
              } else {
                rule.options.forEach((el) => {
                  el.disabled = false
                })
              }
            },
          },
          {
            type: 'select',
            field: 'activity_type',
            title: '活动类型',
            options: [
              { label: '全部', value: -1 },
              { label: '线上活动', value: 0 },
              { label: '线下活动', value: 1 },
            ],
            props: {
              disabled: false,
            },
            value: -1,
          },
        ],
      },
      loadingConfig: {
        title: '数据加载',
        rules: [
          {
            type: 'StRadioButtonGroup',
            field: 'loadingType',
            display: true,
            native: true,
            props: {
              labelWidth: '84px',
              title: '数据加载',
              options: [
                {
                  label: '固定条数',
                  value: 'none',
                },
                {
                  label: '手动加载',
                  value: 'handle',
                },
                {
                  label: '分页加载',
                  value: 'page',
                },
              ],
              formTip:
                '提示：选择“分页加载”时，组件下方不能放置其他组件；无法选择“分页加载”时，则代表组件下方已有其他组件',
            },
          },
          {
            type: 'input-number',
            field: 'limit',
            title: '数据限制',
            link: ['loadingType'],
            update: (val, rule, fApi) => {
              const _this = getVm()
              const titleName = {
                none: '数据条数',
                handle: '每次加载',
                page: '每页加载',
              }
              rule.title = titleName[fApi.form.loadingType]
            },
            props: {
              min: 1,
              max: 30,
            },
            style: {
              float: 'right',
            },
          },
          {
            type: 'StTemplateRadio',
            field: 'handleType',
            native: true,
            display: true,
            props: {
              title: '加载样式',
              options: [
                {
                  name: '模板1',
                  value: 'template1',
                },
                {
                  name: '模板2',
                  value: 'template2',
                },
              ],
            },
            link: ['loadingType'],
            update: (val, rule, fApi) => {
              const loadingType = fApi.form.loadingType
              rule.display = loadingType === 'handle'
            },
          },
          {
            type: 'ElInput',
            field: 'handleText',
            title: '文字内容',
            display: true,
            props: {
              placeholder: '请输入文字内容',
            },
            link: ['loadingType'],
            update: (val, rule, fApi) => {
              const loadingType = fApi.form.loadingType
              rule.display = loadingType === 'handle'
            },
          },
        ],
      },
      listStyle: {
        title: '列表样式',
        rules: [
          {
            type: 'StRadioButtonGroup',
            field: 'imageText',
            display: true,
            native: true,
            props: {
              title: '图文',
              options: [
                {
                  label: '小图左边',
                  value: 'left',
                },
                {
                  label: '小图右边',
                  value: 'right',
                },
              ],
            },
          },
          {
            type: 'StColorPicker',
            field: 'bgColor',
            title: '卡片背景色',
            display: true,
            props: {
              defaultColor: '#fff',
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'shadow',
            display: true,
            native: true,
            props: {
              title: '卡片阴影',
              options: [
                {
                  label: '有',
                  value: 1,
                },
                {
                  label: '无',
                  value: 0,
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'border',
            display: true,
            native: true,
            props: {
              title: '卡片边框',
              options: [
                {
                  label: '有',
                  value: 1,
                },
                {
                  label: '无',
                  value: 0,
                },
              ],
            },
          },
        ],
      },
      listTitle: {
        title: '列表标题设置',
        rules: [
          {
            type: 'StIsShowTitle',
            field: 'isShow',
            native: true,
            display: true,
            props: {
              title: '列表标题',
            },
          },
          {
            type: 'StTemplateRadio',
            field: 'showType',
            native: true,
            display: true,
            props: {
              options: [
                {
                  name: '模板1',
                  value: 'template1',
                },
                {
                  name: '模板2',
                  value: 'template2',
                },
              ],
            },
          },
          {
            type: 'ElInput',
            field: 'title',
            title: '标题名称',
            display: true,
            props: {
              placeholder: '请输入标题名称',
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'fontWeight',
            display: true,
            native: true,
            props: {
              title: '文本粗细',
              options: [
                {
                  label: '常规体',
                  value: 'normal',
                },
                {
                  label: '加粗体',
                  value: 'bold',
                },
              ],
            },
          },
          {
            type: 'StNumberSlider',
            field: 'fontSize',
            native: true,
            display: true,
            props: {
              label: '标题大小',
              min: 12,
              max: 24,
            },
          },
          {
            type: 'StColorPicker',
            field: 'titleColor',
            title: '文本颜色',
            display: true,
            props: {
              defaultColor: '#000000',
            },
          },
        ],
      },
      messageConfig: {
        title: '信息展示',
        rules: [
          {
            type: 'StRadioButtonGroup',
            field: 'showTime',
            native: true,
            display: true,
            props: {
              title: '活动时间',
              options: [
                {
                  label: '显示',
                  value: 1,
                },
                {
                  label: '隐藏',
                  value: 0,
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'showUsers',
            native: true,
            props: {
              title: '报名/观看人数',
              options: [
                {
                  label: '显示',
                  value: 1,
                },
                {
                  label: '隐藏',
                  value: 0,
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'showCreator',
            native: true,
            display: false,
            props: {
              title: '举办架构',
              options: [
                {
                  label: '显示',
                  value: 1,
                },
                {
                  label: '隐藏',
                  value: 0,
                },
              ],
            },
          },
        ],
      },
      showConfig: {
        title: '查看更多设置',
        isShow: 1,
        rules: [
          {
            type: 'StIsShowTitle',
            field: 'isShow',
            native: true,
            display: true,
            props: {
              title: '查看更多',
            },
          },
          {
            type: 'StTemplateRadio',
            field: 'showType',
            native: true,
            display: true,
            props: {
              options: [
                {
                  name: '模板1',
                  value: 'template1',
                },
                {
                  name: '模板2',
                  value: 'template2',
                },
                {
                  name: '模板3',
                  value: 'template3',
                },
              ],
            },
          },

          {
            type: 'ElInput',
            field: 'title',
            title: '文字内容',
            display: true,
            props: {
              placeholder: '请输入文字内容',
            },
          },
        ],
      },

      templateDataFake: {
        title: '模板设置',
        rules: [
          {
            type: 'StTemplateRadio',
            field: 'template',
            native: true,
            props: {
              title: '选择模板',
              options: [
                {
                  name: '模板1',
                  value: 'template1',
                },
              ],
            },
            update: (val, rule, fApi, init = false) => {
              const _this = getVm()

              // 模板6去除掉视频类型数据
              if (val === 'template6') {
                _this.rulesArr.dataConfig.rules[4].props.pageData.banner_types =
                  [0, 2]

                if (!init) {
                  _this.rulesArr.dataConfig.rules[4].value = []
                }
              } else {
                _this.rulesArr.dataConfig.rules[4].props.pageData.banner_types =
                  [0, 2, 3]
                _this.rulesArr.dataConfig.rules[3].value = [0, 2, 3]
                _this.rulesArr.dataConfig.rules[3].props.options[2].disabled = false
              }
              _this.rulesArr.contentConfig.isShow =
                val === 'template1' ||
                val === 'template5' ||
                val === 'template6'
                  ? 1
                  : 0
              // _this.rulesArr.upContentConfig.isShow = val == 'template1' ? 1 : 0;

              _this.rulesArr.pageJump.isShow = val === 'template1' ? 0 : 1

              _this.rulesArr.searchConfigTemplate1.isShow =
                val === 'template1' ||
                val === 'template5' ||
                val === 'template6'
                  ? 1
                  : 0
              if (!init) {
                /**
                 * 初始化时不要触发以下代码
                 */
                if (['template5', 'template6'].includes(val)) {
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'searchColor'
                  ).value = 'rgba(255, 255, 255, 1)'
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'placeholderColor'
                  ).value = '#b3b3b3'
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'placeholder'
                  ).value = ''
                } else {
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'searchColor'
                  ).value = 'rgba(243,244,245,1)'
                  _this.rulesArr.searchConfigTemplate1.rules.find(
                    (el) => el.field === 'placeholderColor'
                  ).value = '#979da6'
                }
              }

              _this.rulesArr.bannerSearchConfig.isShow = [
                'template2',
                'template4',
              ].includes(val)
                ? 1
                : 0
              _this.rulesArr.bannerSearchConfig.rules.find(
                (el) => el.field === 'template2_searchType'
              ).display = val === 'template2'
              _this.rulesArr.bannerSearchConfig.rules.find(
                (el) => el.field === 'template4_searchType'
              ).display = val === 'template4'

              let dataTemplateList1 = [
                'template2',
                'template3',
                'template4',
                'template6',
              ]
              let dataTemplateList2 = ['template2', 'template3', 'template4']

              _this.rulesArr.dataConfig.isShow = dataTemplateList1.includes(val)
                ? 1
                : 0
              _this.rulesArr.loadingConfig.isShow = dataTemplateList2.includes(
                val
              )
                ? 1
                : 0

              /* 只有模板一有政务相关配置 */
              _this.rulesArr.gov.isShow = val === 'template1' ? 1 : 0

              _this.rulesArr.contentConfig.rules.forEach((el) => {
                let bol = true
                if (
                  ['template5', 'template6'].includes(el.field) ||
                  ['template5', 'template6'].includes(val)
                ) {
                  if (val !== el.field) bol = false
                }
                if (el.field === 'showSearch') {
                  if (val !== 'template1') bol = false
                }
                el.display = bol
              })

              _this.rulesArr.contentConfigTemplate7.isShow =
                val === 'template7' ? 1 : 0
            },
          },
        ],
      },
    }
  },
}
