import api from "../../../base/utils/request";

export const getSystemPageDefault = (data) => {
  return api({
    url: '/admin/app_design/page/getSystemPageDefault',
    method: 'post',
    data: {
      system_page: data
    }
  })
}
