// import store from '@/base/store'
// const baseUrl = window.serverConfig.VUE_APP_BASEURL

export default {
  getRules(getVm, value, platform, others) {
    // getVm: 在 update 中调用

    return {
      messageConfig: {
        title: '信息展示',
        rules: [
          {
            type: 'StRadioButtonGroup',
            field: 'dynamic',
            native: true,
            props: {
              title: '我的动态',
              options: [
                {
                  label: '显示',
                  value: 1,
                },
                {
                  label: '隐藏',
                  value: 0,
                },
              ],
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'settings',
            native: true,
            props: {
              title: '设置',
              options: [
                {
                  label: '显示',
                  value: 1,
                },
                {
                  label: '隐藏',
                  value: 0,
                },
              ],
            },
          },
        ],
      },
    }
  },
}
