<template>
  <div
    class="brand-list"
    :style="{
      margin: `${myConfig.normal.marginTop}px ${myConfig.normal.rowMargin}px 0`,
      backgroundColor:
        myConfig.normal.bgType === 'color' ? myConfig.normal.bgColor : '',
      backgroundImage: myBgImage,
      padding: myStyleType !== 'template4' ? '12px 16px' : '12px 0 0 0',
    }"
  >
    <StListTitle
      :config="myConfig"
      :style="{
        padding: myStyleType === 'template4' ? '0 16px 16px' : '',
      }"
    />
    <div
      v-loading="loading"
      :style="{
        paddingBottom: `${
          Array.isArray(swiperList[0]) &&
          swiperList.length > 1 &&
          myConfig.loadingConfig.interactiveType === 'swiper'
            ? '20'
            : '0'
        }px`,
      }"
      class="swiper-view template1"
      v-if="myStyleType === 'template1'"
    >
      <div class="swiper-item" v-if="swiperList.length">
        <div
          class="item-list"
          v-for="(item, index) in Array.isArray(swiperList[0])
            ? swiperList[0]
            : swiperList"
          :key="index"
        >
          <el-image
            class="item-avatar"
            :src="
              item.user.avatar
                ? fixSpecialImageUrl(item.user.avatar, '', index)
                : defaultAvatar['template2'][index]
            "
            fit="cover"
          >
          </el-image>
          <div class="item-content">
            <div class="content-top">
              <div class="item-name">{{ item.user.nickname }}</div>
              <div class="item-title" v-if="item.user.position_name">
                {{ item.user.position_name }}
              </div>
            </div>
            <div
              class="content-middle"
              v-if="item.work_role && item.work_place"
            >
              <span class="item-place" v-if="item.work_place"
                >{{ item.work_place }}<span class="item-dot">·</span
                >{{ item.work_role }}</span
              >
            </div>
            <div
              class="content-bottom"
              v-if="
                myConfig.templateData.showMemberDescription ||
                (myConfig.templateData.showCompanyDescription &&
                  item.company_id)
              "
            >
              <div
                class="item-btn"
                v-if="myConfig.templateData.showMemberDescription"
              >
                <img
                  class="btn-icon"
                  src="../../assets/images/st-spirit-list/description-icon.png"
                />
                个人简介
              </div>
              <div
                class="item-btn"
                v-if="
                  myConfig.templateData.showCompanyDescription &&
                  item.company_id
                "
              >
                <img
                  class="btn-icon"
                  src="../../assets/images/st-spirit-list/company-description-icon.png"
                />
                企业简介
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-loading="loading"
      :style="{
        paddingBottom: `${
          Array.isArray(swiperList[0]) &&
          swiperList.length > 1 &&
          myConfig.loadingConfig.interactiveType === 'swiper'
            ? '32'
            : '0'
        }px !important`,
      }"
      class="swiper-view template2"
      v-if="myStyleType === 'template2'"
    >
      <div class="swiper-item" v-if="swiperList.length">
        <div
          class="item-list"
          v-for="(item, index) in Array.isArray(swiperList[0])
            ? swiperList[0]
            : swiperList"
          :key="index"
        >
          <el-image
            class="item-avatar"
            :src="
              item.user.avatar
                ? fixSpecialImageUrl(item.user.avatar, '', index)
                : defaultAvatar['template2'][index]
            "
            fit="cover"
          >
          </el-image>
          <div class="item-content">
            <div class="content-top">
              <div class="item-name">{{ item.user.nickname }}</div>
              <div class="item-title" v-if="item.user.position_name">
                {{ item.user.position_name }}
              </div>
            </div>
            <div
              class="content-middle"
              v-if="item.work_role && item.work_place"
            >
              <span class="item-place" v-if="item.work_place"
                >{{ item.work_place }}<span class="item-dot">·</span
                >{{ item.work_role }}</span
              >
            </div>
            <div
              class="content-bottom"
              v-if="
                myConfig.templateData.showMemberDescription ||
                (myConfig.templateData.showCompanyDescription &&
                  item.company_id)
              "
            >
              <div
                class="item-btn"
                v-if="myConfig.templateData.showMemberDescription"
              >
                <img
                  class="btn-icon"
                  src="../../assets/images/st-spirit-list/description-icon.png"
                />
                个人简介
              </div>
              <div
                class="item-btn"
                v-if="
                  myConfig.templateData.showCompanyDescription &&
                  item.company_id
                "
              >
                <img
                  class="btn-icon"
                  src="../../assets/images/st-spirit-list/company-description-icon.png"
                />
                企业简介
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播样式 模版5-->
    <div
      v-loading="loading"
      class="list-content swiper"
      v-if="myStyleType === 'template5'"
    >
      <div class="swiper-list" v-if="swiperList.length">
        <div
          class="list-item"
          v-for="(item, index) in swiperList.slice(
            0,
            myConfig.loadingConfig.pageLimit
          )"
          :key="index"
        >
          <el-image
            class="item-avatar"
            :src="
              item.user.avatar
                ? fixSpecialImageUrl(item.user.avatar, '', index)
                : defaultAvatar['template2'][index]
            "
            fit="cover"
          ></el-image>
          <div class="item-right">
            <div class="right-top">
              <div class="item-name">{{ item.user.nickname }}</div>
              <div class="item-position">{{ item.user.position_name }}</div>
              <div class="item-follow-btn">关注</div>
            </div>
            <div class="right-bottom">
              <span class="item-place" v-if="item.work_place"
                >{{ item.work_place }}<span class="item-dot"> · </span
                >{{ item.work_role }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="indicator-dots"
          v-if="Math.ceil(swiperList.length / myConfig.loadingConfig.pageLimit) > 1"
        >
          <div class="dots-box">
            <div
              class="dot"
              :class="{ active: page === 1 }"
              v-for="page in Math.ceil(
                swiperList.length / myConfig.loadingConfig.pageLimit
              )"
              :key="page"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 普通列表样式 -->
    <div
      v-loading="loading"
      class="list-content normal"
      v-if="myStyleType === 'template3' && swiperList.length"
    >
      <div
        class="list-item"
        :style="{
          marginTop: index > 0 ? myConfig.templateData.dataSpacing + 'px' : '',
        }"
        v-for="(item, index) in swiperList"
        :key="index"
      >
        <el-image
          class="item-avatar"
          :style="{
            borderRadius: myConfig.templateData.borderRadius + 'px',
          }"
          :src="
            item.user.avatar
              ? fixSpecialImageUrl(item.user.avatar, '', index)
              : defaultAvatar['template2'][index % 6]
          "
          fit="cover"
        ></el-image>
        <div class="item-content">
          <div class="content-top">
            <div class="item-name">{{ item.user.nickname }}</div>
            <div class="item-title" v-if="item.user.position_name">
              {{ item.user.position_name }}
            </div>
          </div>
          <div class="content-middle" v-if="item.work_role && item.work_place">
            <span class="item-place" v-if="item.work_place"
              >{{ item.work_place }}<span class="item-dot">·</span
              >{{ item.work_role }}</span
            >
          </div>
        </div>
        <img class="right-icon" src="../../assets/images/after-page.png" />
      </div>
    </div>
    <!-- 水平滚动模式 -->
    <div
      v-loading="loading"
      class="list-content scroll"
      v-if="myStyleType === 'template4' && swiperList.length"
    >
      <div
        class="list-item"
        :style="{
          marginRight: myConfig.templateData.dataSpacing + 'px',
          borderRadius: myConfig.templateData.borderRadius + 'px',
        }"
        v-for="(item, index) in swiperList"
        :key="index"
      >
        <el-image
          class="item-avatar"
          :style="{
            borderRadius: myConfig.templateData.borderRadius + 'px',
          }"
          :src="
            item.user.avatar
              ? fixSpecialImageUrl(item.user.avatar, '', index)
              : defaultAvatar['template2'][index % 6]
          "
          fit="cover"
        ></el-image>
        <div class="item-content">
          <div class="item-name">{{ item.user.nickname }}</div>
          <div class="item-title" v-if="item.user.position_name">
            {{ item.user.position_name }}
          </div>
          <div class="content-middle" v-if="item.work_role && item.work_place">
            <span class="item-place" v-if="item.work_place"
              >{{ item.work_place }}<span class="item-dot">·</span
              >{{ item.work_role }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="swiper-dots"
      v-if="
        Array.isArray(swiperList[0]) &&
        swiperList.length > 1 &&
        myConfig.loadingConfig.interactiveType === 'swiper' &&
        ['template1', 'template2'].includes(myStyleType)
      "
    >
      <div class="dots-list">
        <div
          :class="['dot', i === 1 ? 'active' : '']"
          v-for="i in swiperList.length"
          :key="i"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { spiritList } from '../../api/components'
import componentMixin from '../../mixins/component-mixin'

export default {
  name: 'StSpiritList',
  mixins: [componentMixin],
  data() {
    return {
      loading: false,
      defaultList: require('../../assets/jsons/default-list.json').spiritList,
      defaultAvatar: {
        template1: [
          require('../../assets/images/st-spirit-list/circle-avatar1.png'),
          require('../../assets/images/st-spirit-list/circle-avatar2.png'),
          require('../../assets/images/st-spirit-list/circle-avatar3.png'),
          require('../../assets/images/st-spirit-list/circle-avatar4.png'),
          require('../../assets/images/st-spirit-list/circle-avatar5.png'),
          require('../../assets/images/st-spirit-list/circle-avatar6.png'),
        ],
        template2: [
          require('../../assets/images/st-spirit-list/square-avatar1.png'),
          require('../../assets/images/st-spirit-list/square-avatar2.png'),
          require('../../assets/images/st-spirit-list/square-avatar3.png'),
          require('../../assets/images/st-spirit-list/square-avatar4.png'),
          require('../../assets/images/st-spirit-list/square-avatar5.png'),
          require('../../assets/images/st-spirit-list/square-avatar6.png'),
        ],
      },
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
          rowMargin: 0,
        },
        templateData: {
          styleType: 'template1',
          dataSpacing: 16,
          borderRadius: 6,
          showMemberDescription: 1,
          showCompanyDescription: 1,
        },
        dataConfig: {
          dataType: 'filter',
          dataArr: [
            {
              id: '',
              name: '',
            },
          ],
          columnFilter: '',
          categoryFilter: '',
          dataShowType: ['gov_data', 'self_data'],
        },
        loadingConfig: {
          limit: 10,
          interactiveType: 'swiper',
          swiperTime: 5000,
          autoPlay: 1,
          pageLimit: 3,
        },
        listTitle: {
          isShow: 1,
          showType: 'template1',
          title: '会员风采',
          fontWeight: 'bold',
          fontSize: 18,
          titleColor: '#000000',
        },
        showConfig: {
          isShow: 1,
          showType: 'template1',
          detailType: 'template1',
          title: '查看更多',
          titleColor: '#999999',
        },
      },
      swiperList: [],
    }
  },
  watch: {
    myStyleType(val) {
      this.swiperList = []
      this.getSpiritList(false)
    },
    'myConfig.templateData.showCompanyDescription'(val) {
      this.getSpiritList(false)
    },
    'myConfig.loadingConfig.limit'(val) {
      this.getSpiritList(false)
    },
    'myConfig.dataConfig.dataType'(val) {
      this.getSpiritList(false)
    },
    'myConfig.dataConfig.dataArr'(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getSpiritList(false)
      }
    },
    'myConfig.loadingConfig.pageLimit'(val) {
      this.getSpiritList(false)
    },
    'myConfig.dataConfig.dataShowType'(val) {
      this.getSpiritList(false)
    },
    'myConfig.dataConfig.columnFilter'(val) {
      if (!val) {
        this.getSpiritList(false)
      }
    },
    'myConfig.dataConfig.categoryFilter'(val) {
      if (this.myConfig.dataConfig.columnFilter) {
        this.getSpiritList(false)
      }
    },
  },
  created() {
    this.getSpiritList()
  },
  methods: {
    getSpiritList(notCancel) {
      this.loading = true
      let ids = []
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id)
      })
      let requestData = {
        chunk_limit: ['template1', 'template2'].includes(this.myStyleType)
          ? this.myConfig.loadingConfig.pageLimit
          : 0,
        limit: this.myConfig.loadingConfig.limit,
        use_ids: this.myConfig.dataConfig.dataType === 'select' ? 1 : 0,
        spirit: this.myConfig.dataConfig.columnFilter,
        issue: this.myConfig.dataConfig.columnFilter
          ? this.myConfig.dataConfig.categoryFilter
          : '',

        show_company: this.myConfig.templateData.showCompanyDescription,
        // 数据类型
        data_sources: this.myConfig.dataConfig.dataShowType,
        ids,
      }
      spiritList(requestData, notCancel)
        .then((res) => {
          const { data } = res
          this.swiperList = data?.length
            ? data
            : this.myConfig.loadingConfig.interactiveType === 'swiper'
            ? this.defaultList.slice(
                0,
                this.myConfig.loadingConfig.pageLimit || 5
              )
            : this.defaultList.slice(0, this.myConfig.loadingConfig.limit || 5)
          console.log(this.swiperList, 'this.swiperList')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-list {
  position: relative;
  background-size: 100% auto;
  background-repeat: repeat-y;
  .list-title {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;

    .check-more {
      font-size: 12px;
      color: #999999;
    }
  }

  .swiper-view {
    display: flex;
    min-height: 50px;

    &.template1 {
      .item-avatar {
        flex-shrink: 0;
        position: relative;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        margin-right: 12px;
        .default-icon {
          position: absolute;
          width: 28px;
          height: 28px;
          top: 50%;
          left: 50%;
          margin-top: -14px;
          margin-left: -14px;
          z-index: 2;
        }
      }
    }
    &.template2 {
      .item-avatar {
        flex-shrink: 0;
        position: relative;
        width: 100px;
        height: 120px;
        border-radius: 4px;
        margin-right: 12px;
        .default-icon {
          position: absolute;
          width: 28px;
          height: 28px;
          top: 50%;
          left: 50%;
          margin-top: -14px;
          margin-left: -14px;
          z-index: 2;
        }
      }
    }
    .swiper-item {
      .item-list + .item-list {
        margin-top: 20px;
      }
      .item-list {
        display: flex;
        align-items: center;
      }
    }
  }

  .list-content {
    min-height: 50px;
    &.normal {
      width: 100%;
      .list-item {
        width: 100%;
        display: flex;
        align-items: center;
        .item-avatar {
          width: 62px;
          height: 62px;
          flex-shrink: 0;
        }
        .item-content {
          margin: 0 12px;
        }
        .right-icon {
          width: 5px;
          height: 10px;
          margin-left: auto;
          flex-shrink: 0;
        }
      }
    }
    &.scroll {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      overflow: hidden;
      padding: 0 0 12px 16px;
      .list-item {
        width: 298px;
        padding: 12px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        box-shadow: 0px 6px 10px 0px #f2f2f2;
        background-color: #fff;
        .item-avatar {
          width: 72px;
          height: 72px;
          flex-shrink: 0;
        }
        .item-content {
          width: 100%;
          margin-left: 8px;
          .item-name {
            font-size: 16px;
            font-weight: bold;
            color: #1a1a1a;
            line-height: 1.5;
          }
          .item-title {
            margin-top: 6px;
            padding: 4px 4px;
            background: var(--main-color);
            border-radius: 2px;
            font-size: 12px;
            font-weight: bold;
            color: #ffffff;
            line-height: 1;
            width: fit-content;
          }
          .content-middle {
            margin-top: 6px;
            .item-place {
              display: inline-block;
              width: 1px;
              flex-grow: 1;
              @include nowrap();
            }
          }
        }
      }
    }

    &.swiper {
      .swiper-list {
        width: 100%;
        padding: 16px 12px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 12px;
        .list-item + .list-item {
          margin-top: 16px;
        }
        .list-item {
          display: flex;
          .item-avatar {
            flex-shrink: 0;
            width: 52px;
            height: 52px;
            border-radius: 50%;
          }
          .item-right {
            width: 100%;
            margin-left: 8px;
            .right-top {
              margin-top: 3px;
              display: flex;
              align-items: center;
              .item-name {
                flex-shrink: 0;
                font-size: 16px;
                color: #1a1a1a;
                line-height: 1.5;
              }
              .item-position {
                margin-left: 8px;
                padding: 0 4px;
                font-size: 12px;
                color: #ffffff;
                line-height: 1.5;
                background: var(--main-color);
                border-radius: 2px;
                @include nowrap();
              }
              .item-follow-btn {
                border-radius: 23px;
                margin-left: auto;
                flex-shrink: 0;
                padding: 5px 10px;
                font-size: 12px;
                color: var(--main-color);
                line-height: 1.5;
                border: 1px solid var(--main-color);
              }
            }
            .right-bottom {
              width: 194px;
              margin-top: 2px;
              font-size: 12px;
              color: #808080;
              line-height: 1.5;
              .item-place {
                @include clamp(1);
              }
            }
          }
        }

        .indicator-dots {
          display: flex;
          justify-content: center;
          margin-top: 12px;
          .dots-box {
            display: flex;
            align-items: center;
            .dot + .dot {
              margin-left: 4px;
            }
            .dot {
              width: 4px;
              height: 4px;
              background: var(--main-color);
              border-radius: 2px;
              opacity: 0.19;

              &.active {
                width: 12px;
                height: 4px;
                border-radius: 2px;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .item-content {
    .content-top {
      display: flex;
      align-items: center;
      .item-name {
        font-size: 18px;
        font-weight: bold;
        color: #191e29;
        line-height: 18px;
        margin-right: 6px;
      }
      .item-title {
        padding: 3px 3px;
        background: var(--main-color);
        border-radius: 2px;
        font-size: 11px;
        font-weight: bold;
        color: #ffffff;
        line-height: 11px;
        margin-right: 6px;
      }
    }

    .content-middle {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .item-dot {
        font-size: 13px;
        color: #979da6;
        line-height: 15px;
        margin: 0 2px;
      }

      .item-place {
        font-size: 13px;
        color: #979da6;
        line-height: 13px;
        @include clamp(2);
      }
    }
    .content-bottom {
      margin-top: 6px;
      display: flex;
      align-items: center;
      .item-btn + .item-btn {
        margin-left: 8px;
      }
      .item-btn {
        padding: 4px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #535353;
        line-height: 12px;
        width: 80px;
        border-radius: 2px;
        border: 1px solid #e4e5e6;
        .btn-icon {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .swiper-dots {
    width: 100%;
    position: absolute;
    left: calc(50%);
    transform: translateX(-50%);
    bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dots-list {
      display: flex;
      .dot + .dot {
        margin-left: 4px;
      }
      .dot {
        width: 4px;
        height: 4px;
        background: var(--main-color);
        border-radius: 2px;
        opacity: 0.19;

        &.active {
          width: 12px;
          height: 4px;
          border-radius: 2px;
          opacity: 1;
        }
      }
    }
  }
}
</style>
