var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-list",style:({
    margin: ((_vm.myConfig.normal.marginTop) + "px " + (_vm.myConfig.normal.rowMargin) + "px 0"),
    backgroundColor:
      _vm.myConfig.normal.bgType === 'color' ? _vm.myConfig.normal.bgColor : '',
    backgroundImage: _vm.myBgImage,
    padding: _vm.myStyleType !== 'template4' ? '12px 16px' : '12px 0 0 0',
  })},[_c('StListTitle',{style:({
      padding: _vm.myStyleType === 'template4' ? '0 16px 16px' : '',
    }),attrs:{"config":_vm.myConfig}}),(_vm.myStyleType === 'template1')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template1",style:({
      paddingBottom: ((Array.isArray(_vm.swiperList[0]) &&
        _vm.swiperList.length > 1 &&
        _vm.myConfig.loadingConfig.interactiveType === 'swiper'
          ? '20'
          : '0') + "px"),
    })},[(_vm.swiperList.length)?_c('div',{staticClass:"swiper-item"},_vm._l((Array.isArray(_vm.swiperList[0])
          ? _vm.swiperList[0]
          : _vm.swiperList),function(item,index){return _c('div',{key:index,staticClass:"item-list"},[_c('el-image',{staticClass:"item-avatar",attrs:{"src":item.user.avatar
              ? _vm.fixSpecialImageUrl(item.user.avatar, '', index)
              : _vm.defaultAvatar['template2'][index],"fit":"cover"}}),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.user.nickname))]),(item.user.position_name)?_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.user.position_name)+" ")]):_vm._e()]),(item.work_role && item.work_place)?_c('div',{staticClass:"content-middle"},[(item.work_place)?_c('span',{staticClass:"item-place"},[_vm._v(_vm._s(item.work_place)),_c('span',{staticClass:"item-dot"},[_vm._v("·")]),_vm._v(_vm._s(item.work_role))]):_vm._e()]):_vm._e(),(
              _vm.myConfig.templateData.showMemberDescription ||
              (_vm.myConfig.templateData.showCompanyDescription &&
                item.company_id)
            )?_c('div',{staticClass:"content-bottom"},[(_vm.myConfig.templateData.showMemberDescription)?_c('div',{staticClass:"item-btn"},[_c('img',{staticClass:"btn-icon",attrs:{"src":require("../../assets/images/st-spirit-list/description-icon.png")}}),_vm._v(" 个人简介 ")]):_vm._e(),(
                _vm.myConfig.templateData.showCompanyDescription &&
                item.company_id
              )?_c('div',{staticClass:"item-btn"},[_c('img',{staticClass:"btn-icon",attrs:{"src":require("../../assets/images/st-spirit-list/company-description-icon.png")}}),_vm._v(" 企业简介 ")]):_vm._e()]):_vm._e()])],1)}),0):_vm._e()]):_vm._e(),(_vm.myStyleType === 'template2')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"swiper-view template2",style:({
      paddingBottom: ((Array.isArray(_vm.swiperList[0]) &&
        _vm.swiperList.length > 1 &&
        _vm.myConfig.loadingConfig.interactiveType === 'swiper'
          ? '32'
          : '0') + "px !important"),
    })},[(_vm.swiperList.length)?_c('div',{staticClass:"swiper-item"},_vm._l((Array.isArray(_vm.swiperList[0])
          ? _vm.swiperList[0]
          : _vm.swiperList),function(item,index){return _c('div',{key:index,staticClass:"item-list"},[_c('el-image',{staticClass:"item-avatar",attrs:{"src":item.user.avatar
              ? _vm.fixSpecialImageUrl(item.user.avatar, '', index)
              : _vm.defaultAvatar['template2'][index],"fit":"cover"}}),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.user.nickname))]),(item.user.position_name)?_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.user.position_name)+" ")]):_vm._e()]),(item.work_role && item.work_place)?_c('div',{staticClass:"content-middle"},[(item.work_place)?_c('span',{staticClass:"item-place"},[_vm._v(_vm._s(item.work_place)),_c('span',{staticClass:"item-dot"},[_vm._v("·")]),_vm._v(_vm._s(item.work_role))]):_vm._e()]):_vm._e(),(
              _vm.myConfig.templateData.showMemberDescription ||
              (_vm.myConfig.templateData.showCompanyDescription &&
                item.company_id)
            )?_c('div',{staticClass:"content-bottom"},[(_vm.myConfig.templateData.showMemberDescription)?_c('div',{staticClass:"item-btn"},[_c('img',{staticClass:"btn-icon",attrs:{"src":require("../../assets/images/st-spirit-list/description-icon.png")}}),_vm._v(" 个人简介 ")]):_vm._e(),(
                _vm.myConfig.templateData.showCompanyDescription &&
                item.company_id
              )?_c('div',{staticClass:"item-btn"},[_c('img',{staticClass:"btn-icon",attrs:{"src":require("../../assets/images/st-spirit-list/company-description-icon.png")}}),_vm._v(" 企业简介 ")]):_vm._e()]):_vm._e()])],1)}),0):_vm._e()]):_vm._e(),(_vm.myStyleType === 'template5')?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-content swiper"},[(_vm.swiperList.length)?_c('div',{staticClass:"swiper-list"},[_vm._l((_vm.swiperList.slice(
          0,
          _vm.myConfig.loadingConfig.pageLimit
        )),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[_c('el-image',{staticClass:"item-avatar",attrs:{"src":item.user.avatar
              ? _vm.fixSpecialImageUrl(item.user.avatar, '', index)
              : _vm.defaultAvatar['template2'][index],"fit":"cover"}}),_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"right-top"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.user.nickname))]),_c('div',{staticClass:"item-position"},[_vm._v(_vm._s(item.user.position_name))]),_c('div',{staticClass:"item-follow-btn"},[_vm._v("关注")])]),_c('div',{staticClass:"right-bottom"},[(item.work_place)?_c('span',{staticClass:"item-place"},[_vm._v(_vm._s(item.work_place)),_c('span',{staticClass:"item-dot"},[_vm._v(" · ")]),_vm._v(_vm._s(item.work_role))]):_vm._e()])])],1)}),(Math.ceil(_vm.swiperList.length / _vm.myConfig.loadingConfig.pageLimit) > 1)?_c('div',{staticClass:"indicator-dots"},[_c('div',{staticClass:"dots-box"},_vm._l((Math.ceil(
              _vm.swiperList.length / _vm.myConfig.loadingConfig.pageLimit
            )),function(page){return _c('div',{key:page,staticClass:"dot",class:{ active: page === 1 }})}),0)]):_vm._e()],2):_vm._e()]):_vm._e(),(_vm.myStyleType === 'template3' && _vm.swiperList.length)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-content normal"},_vm._l((_vm.swiperList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
        marginTop: index > 0 ? _vm.myConfig.templateData.dataSpacing + 'px' : '',
      })},[_c('el-image',{staticClass:"item-avatar",style:({
          borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
        }),attrs:{"src":item.user.avatar
            ? _vm.fixSpecialImageUrl(item.user.avatar, '', index)
            : _vm.defaultAvatar['template2'][index % 6],"fit":"cover"}}),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.user.nickname))]),(item.user.position_name)?_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.user.position_name)+" ")]):_vm._e()]),(item.work_role && item.work_place)?_c('div',{staticClass:"content-middle"},[(item.work_place)?_c('span',{staticClass:"item-place"},[_vm._v(_vm._s(item.work_place)),_c('span',{staticClass:"item-dot"},[_vm._v("·")]),_vm._v(_vm._s(item.work_role))]):_vm._e()]):_vm._e()]),_c('img',{staticClass:"right-icon",attrs:{"src":require("../../assets/images/after-page.png")}})],1)}),0):_vm._e(),(_vm.myStyleType === 'template4' && _vm.swiperList.length)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"list-content scroll"},_vm._l((_vm.swiperList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
        marginRight: _vm.myConfig.templateData.dataSpacing + 'px',
        borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
      })},[_c('el-image',{staticClass:"item-avatar",style:({
          borderRadius: _vm.myConfig.templateData.borderRadius + 'px',
        }),attrs:{"src":item.user.avatar
            ? _vm.fixSpecialImageUrl(item.user.avatar, '', index)
            : _vm.defaultAvatar['template2'][index % 6],"fit":"cover"}}),_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.user.nickname))]),(item.user.position_name)?_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.user.position_name)+" ")]):_vm._e(),(item.work_role && item.work_place)?_c('div',{staticClass:"content-middle"},[(item.work_place)?_c('span',{staticClass:"item-place"},[_vm._v(_vm._s(item.work_place)),_c('span',{staticClass:"item-dot"},[_vm._v("·")]),_vm._v(_vm._s(item.work_role))]):_vm._e()]):_vm._e()])],1)}),0):_vm._e(),(
      Array.isArray(_vm.swiperList[0]) &&
      _vm.swiperList.length > 1 &&
      _vm.myConfig.loadingConfig.interactiveType === 'swiper' &&
      ['template1', 'template2'].includes(_vm.myStyleType)
    )?_c('div',{staticClass:"swiper-dots"},[_c('div',{staticClass:"dots-list"},_vm._l((_vm.swiperList.length),function(i){return _c('div',{key:i,class:['dot', i === 1 ? 'active' : '']})}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }