import { render, staticRenderFns } from "./StArticleLiked.vue?vue&type=template&id=fae2d6be&scoped=true&"
import script from "./StArticleLiked.vue?vue&type=script&lang=js&"
export * from "./StArticleLiked.vue?vue&type=script&lang=js&"
import style0 from "./StArticleLiked.vue?vue&type=style&index=0&id=fae2d6be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fae2d6be",
  null
  
)

export default component.exports