<!--
 * @Author: dongjia
 * @Date: 2021-06-17 17:53:38
 * @LastEditTime: 2021-06-21 15:10:22
 * @LastEditors: aleaner
 * @Description: 文章详情点赞组件
 * @FilePath: \app-design\components\Preview\StArticleLiked.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="liked-area" :style="{
      marginTop: myConfig.normal.marginTop + 'px',
      backgroundColor: myConfig.normal.bgColor
    }">
    <div class="liked-content template1" v-if="myConfig.templateData.styleType === 'template1'">
      <p class="liked-tip">- {{10}} 人点赞 -</p>
      <div class="liked-avatar">
        <div class="avatar-box" v-for="(avatar,index) in [...defaultAvatars,...defaultAvatars]" :key="index">
          <el-image class="avatar-item" :style="{
            borderRadius: myConfig.avatarConfig.borderRadius + 'px',
            border: `1px ${myConfig.avatarConfig.borderType} ${myConfig.avatarConfig.borderColor}`
          }" :src="avatar"></el-image>
        </div>
      </div>
    </div>
    <div class="liked-content template2" v-if="myConfig.templateData.styleType === 'template2'">
      <div class="liked-avatar">
        <div class="avatar-box" v-for="(avatar,index) in [...defaultAvatars,...defaultAvatars]" :key="index">
          <el-image class="avatar-item" :style="{
            borderRadius: myConfig.avatarConfig.borderRadius + 'px',
            border: `1px ${myConfig.avatarConfig.borderType} ${myConfig.avatarConfig.borderColor}`,
            left: index * 20 + 'px',
            zIndex: index + 1
          }" :src="avatar"></el-image>
        </div>
      </div>
      <p class="liked-tip">{{10}} 人点赞</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StArticleLiked",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          isShow: 0,
          marginTop: 0,
          opacity: 0,
          bgColor: "",
        },
        templateData: {
          styleType: "",
        },
        avatarConfig: {
          borderRadius: 0,
          borderType: "",
          borderColor: "",
        },
      },
      // 默认点赞用户头像
      defaultAvatars: [
        require("../../assets/images/st-spirit-list/circle-avatar1.png"),
        require("../../assets/images/st-spirit-list/circle-avatar2.png"),
        require("../../assets/images/st-spirit-list/circle-avatar3.png"),
        require("../../assets/images/st-spirit-list/circle-avatar4.png"),
        require("../../assets/images/st-spirit-list/circle-avatar5.png"),
      ],
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.liked-area {
  display: flex;
  justify-content: center;
  align-items: center;
  .liked-content {
    &.template1 {
      padding: 12px 0;
      .liked-tip {
        font-size: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .liked-avatar {
        width: 375px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        .avatar-box {
          margin-top: 10px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 11%;
          .avatar-item {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    &.template2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      width: 375px;
      .liked-tip {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #aaaaaa;
      }
      .liked-avatar {
        position: relative;
        display: flex;
        align-items: center;
        .avatar-box {
          position: absolute;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .avatar-item {
            border: 1px solid #ffffff;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
</style>
