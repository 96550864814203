var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-nav st-page-set",style:({
    backgroundColor: _vm.platform === 'h5' ? '#eaeaea' : (_vm.bgColor || 'transparent')
  })},[_c('div',{staticClass:"header",style:({
			opacity: _vm.isShowFixedNav ? 0 : 1,
			backgroundImage: ("url(" + (_vm.platform === 'h5' ? _vm.statusBar.black : _vm.statusBar[_vm.myConfig.normal.statusBarColor]) + ")")
		})},[_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"left"},[(_vm.platform === 'h5')?_c('i',{staticClass:"el-icon-close",staticStyle:{"font-size":"24px"}}):(_vm.showLeft && _vm.myConfig.normal.showHeaderJump)?_c('img',{class:[(_vm.myJumpConfig.showConfig.showType !== 'template1')?'template-back-icon':'default-back-icon'],attrs:{"src":_vm.backIcon[_vm.myConfig.normal.statusBarColor][_vm.myJumpConfig.showConfig.showType]}}):_vm._e()]),(_vm.title || _vm.myConfig.normal.titleConfig.isShow)?_c('div',{staticClass:"title",style:({
          color: _vm.platform === 'h5'? 'black' : _vm.myConfig.normal.fontColor,
          fontWeight: _vm.myConfig.normal.fontWeight,
          fontSize: _vm.myConfig.normal.fontSize + 'px'
        })},[_c('span',[_vm._v(_vm._s(_vm.myConfig.normal.statusBarColor === 'black' || _vm.platform === 'h5' ? _vm.myTitle : ' '))])]):_vm._e(),_c('div',{staticClass:"right"},[(_vm.platform !== 'h5')?_c('img',{staticClass:"handle-icon",attrs:{"src":_vm.handleIcon[_vm.myConfig.normal.statusBarColor] || require('../../assets/images/wechat-handle1@2x.png')}}):_c('i',{staticClass:"el-icon-more more-icon"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }