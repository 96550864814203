<!--
 * @Author: dongjia
 * @Date: 2021-10-09 14:15:04
 * @LastEditTime: 2021-10-09 15:45:37
 * @LastEditors: aleaner
 * @Description: 最新动态内容布局
 * @FilePath: \app-design\components\Preview\StTrendsContent.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="trend-content">
    <div class="content-one" v-if="contentType === 0">
      <div class="content-left">
        <img class="content-icon" src="../../assets/images/default-icon.png" />
      </div>
      <div class="content-right">
        <div class="right-top">
          <img class="name-icon" src="../../assets/images/st-member-index/group-icon.png" />
          <div class="content-name">{{content.name}}</div>
        </div>
        <div class="right-bottom">{{content.join_num}} 人已加入 · {{content.post_num}} 篇帖子</div>
      </div>
    </div>
    <div class="content-two" v-if="contentType === 1">
      <div class="content-left">#</div>
      <div class="content-right">
        <div class="right-top">{{content.name}}</div>
        <div class="right-center">{{content.description}}</div>
        <div class="right-bottom">
          <div class="bottom-left">{{content.content_num}} 篇内容</div>
          <div class="bottom-right">{{content.view_num_text}} 次浏览</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentType: {
      type: Number,
      default: 0,
    },
    content: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.trend-content {
  margin-top: 6px;
  width: 100%;
  background: #f7f8f9;
  border-radius: 4px;

  .content-one {
    padding: 10px;
    display: flex;
    align-items: center;
    .content-left {
      height: 52px;
      .content-icon {
        width: 52px;
        height: 100%;
        border-radius: 4px;
      }
    }
    .content-right {
      margin-left: 7px;
      .right-top {
        display: flex;
        align-items: center;
        .name-icon {
          width: 16px;
          height: 16px;
        }
        .content-name {
          margin-left: 6px;
          @include nowrap();
          font-size: 16px;
          color: #000000;
          line-height: 16px;
        }
      }
      .right-bottom {
        margin-top: 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #969799;
        line-height: 12px;
      }
    }
  }

  .content-two {
    padding: 9px 14px 14px;
    display: flex;
    .content-left {
      font-size: 18px;
      color: #3a7aff;
      line-height: 22px;
    }
    .content-right {
      margin-left: 7px;
      .right-top {
        margin-top: 4px;
        font-size: 16px;
        color: #000000;
        line-height: 16px;
      }
      .right-center {
        margin-top: 6px;
        font-size: 12px;
        color: #969799;
        line-height: 16px;
      }
      .right-bottom {
        margin-top: 10px;
        display: flex;
        align-items: center;
        .bottom-left {
          font-size: 12px;
          color: #333333;
          line-height: 12px;
        }
        .bottom-right {
          margin-left: 19px;
          font-size: 12px;
          color: #333333;
          line-height: 12px;
        }
      }
    }
  }
}
</style>