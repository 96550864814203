import store from '@/base/store'

export default {
  getRules(getVm, value, platform, others) {
    // getVm: 在 update 中调用

    return {
      normal: {
        title: '常规设置',
        rules: [
          {
            type: 'StNumberSlider',
            field: 'marginTop',
            native: true,
            display: true,
            props: {
              label: '组件间距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StRadioButtonGroup',
            field: 'bgType',
            native: true,
            props: {
              title: '背景设置',
              options: [
                {
                  label: '颜色',
                  value: 'color',
                },
                {
                  label: '图片',
                  value: 'image',
                },
              ],
            },
          },
          {
            type: 'StColorPicker',
            field: 'bgColor',
            title: '背景颜色',
            display: true,
            props: {
              showAlpha: true,
              defaultColor: 'rgba(255, 255, 255, 1)',
            },
            link: ['bgType'],
            update: (val, rule, fApi, init = false) => {
              rule.display = fApi.form.bgType === 'color'
            },
          },
          {
            type: 'SingleMediaWall',
            field: 'bgImage',
            title: '背景图片',
            display: false,
            props: {
              width: 60,
              height: 60,
              addText: '',
            },
            style: {
              float: 'right',
            },
            link: ['bgType'],
            update: (val, rule, fApi, init = false) => {
              rule.display = fApi.form.bgType === 'image'
            },
          },
          {
            type: 'StNumberSlider',
            field: 'rowMargin',
            native: true,
            display: true,
            props: {
              label: '两侧边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'rowPadding',
            native: true,
            props: {
              label: '内侧边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'colPadding',
            native: true,
            props: {
              label: '上下边距',
              min: 0,
              max: 30,
            },
          },
          {
            type: 'StNumberSlider',
            field: 'borderRadius',
            native: true,
            props: {
              label: '圆角设置',
              min: 0,
              max: 48,
            },
          },
        ],
      },
      dataConfig: {
        title: '数据设置',
        isShow: 0,
        rules: [
          {
            type: 'ElInputNumber',
            field: 'rowNum',
            title: '显示行数',
            props: {
              style: 'float: right;',
              min: 1,
            },
          },
          {
            type: 'ElInputNumber',
            field: 'colNum',
            title: '每行个数',
            props: {
              style: 'float: right;',
              min: 1,
            },
          },
        ],
      },
      "listTitle": {
        "title": "列表标题",
        "rules": [
          {
            "type": "StIsShowTitle",
            "field": "isShow",
            "native": true,
            "display": true,
            "props": {
              "title": "列表标题"
            }
          },
          {
            "type": "StTemplateRadio",
            "field": "showType",
            "native": true,
            "display": true,
            "props": {
              "options": [
                {
                  "name": "模板1",
                  "value": "template1"
                },
                {
                  "name": "模板2",
                  "value": "template2"
                }
              ]
            }
          },
          {
            "type": "ElInput",
            "field": "title",
            "title": "标题名称",
            "display": true,
            "props": {
              "placeholder": "请输入标题名称"
            }
          },
          {
            "type": "StRadioButtonGroup",
            "field": "fontWeight",
            "display": true,
            "native": true,
            "props": {
              "title": "文本粗细",
              "options": [
                {
                  "label": "常规体",
                  "value": "normal"
                },
                {
                  "label": "加粗体",
                  "value": "bold"
                }
              ]
            }
          },
          {
            "type": "StNumberSlider",
            "field": "fontSize",
            "native": true,
            "display": true,
            "props": {
              "label": "标题大小",
              "min": 12,
              "max": 24
            }
          },
          {
            "type": "StColorPicker",
            "field": "titleColor",
            "title": "文本颜色",
            "display": true,
            "props": {
              "defaultColor": "#000000"
            }
          }
        ]
      },
      "showConfig": {
        "title": "查看更多设置",
        "isShow": 1,
        "rules": [
          {
            "type": "StIsShowTitle",
            "field": "isShow",
            "native": true,
            "display": true,
            "props": {
              "title": "查看更多"
            }
          },
          {
            "type": "StTemplateRadio",
            "field": "showType",
            "native": true,
            "display": true,
            "props": {
              "options": [
                {
                  "name": "模板1",
                  "value": "template1"
                },
                {
                  "name": "模板2",
                  "value": "template2"
                },
                {
                  "name": "模板3",
                  "value": "template3"
                }
              ]
            }
          },
          {
            "type": "ElInput",
            "field": "title",
            "title": "文字内容",
            "display": true,
            "props": {
              "placeholder": "请输入文字内容"
            }
          }
        ]
      }
    }
  },
}
