<template>
  <div class="st-analysis-page flex-col">
    <st-nav style="z-index: 1;position: relative;" />
    <img v-if="true || !(myConfig.normal.bgType === 'image' && myConfig.normal.bgImage)" class="bg" :class="{theme: myConfig.header.bgType === 'theme'}" :style="{
      backgroundColor: myConfig.header.bgType === 'color' ? myConfig.header.bgColor: ''
    }" :src="false && myConfig.normal.bgType === 'image' && myConfig.normal.bgImage ? '': require('../../../assets/images/st-analysis-page/page-bg.png')" />
    <div class="org flex-align-center">
      <img v-if="myConfig.contentConfig.logo" class="logo" :src="fixImageUrl(myConfig.contentConfig.logo, 300)" />
      <div v-else class="logo empty" />
      <span>{{myConfig.contentConfig.title}}</span>
    </div>
  </div>
</template>

<script>
import StNav from "@/modules/app-design/components/Preview/StNav";
import model from './props.json'

export default {
  name: "StAnalysisPage",
  components: {StNav},
  provide() {
    return {
      headerJump: (() => this.$mergeModel({
        "showConfig": {
          "showType": "template1"
        },
        "tabs": {
          "list": [
            {
              "icon": "",
              "name": "导航一",
              "is_show": 1,
              "jump_link": null
            }
          ]
        }
      }, this.headerJump))(),
      config: () => this.myConfig
    }
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    headerJump: {
      type: Object,
      required: true,
    },
  },
  computed: {
    myConfig() {
      return this.$mergeModel({
        ...model
      }, this.config);
    },
  },
}
</script>

<style lang="scss" scoped>
  .st-analysis-page {
    .bg {
      position: absolute;
      width: 100%;
      top: 0;

      &.theme {
        background-color: var(--main-color);
      }
    }

    .org {
      width: 343px;
      background-color: white;
      border-radius: 6px;
      margin: 12px auto 8px;
      padding: 16px 12px;
      position: relative;

      .logo {
        /*width: 32px;*/
        height: 32px;
        width: auto;
        margin-right: 6px;
        object-fit: contain;

        &.empty {
          background-color: $image-placeholder;
        }
      }

      .title {
        color: rgba(26,26,26,1);
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
      }
    }
  }
</style>
