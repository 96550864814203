<template>
  <div class="st-me-page">
    <template v-if="platform !== 'h5'">
      <img
        class="start-top-img"
        :src="
          require(`../../assets/images/${
            statusBar[myConfig.header.statusBarColor]
          }.png`)
        "
      />
    </template>
    <st-page-set v-else title="我的"></st-page-set>
    <div class="me-content" :class="[$route.params.platform]">
      <div class="me-card-list">
        <div
          class="me-card template1"
          v-if="myConfig.messageConfig.styleType === 'template1'"
        >
          <img
            v-if="myConfig.messageConfig.showSignin"
            class="signin-icon"
            src="../../assets/images/st-me-page/sign-in.png"
          />
          <div class="me-avatar">
            <img
              class="me-avatar-image"
              src="../../assets/images/st-me-page/avatar1.png"
            />
          </div>
          <div class="me-nickname">社团云演示</div>
          <div class="me-intro" v-if="myConfig.messageConfig.showPosition">
            组织架构 | 会内职务
          </div>
          <div
            class="me-intro"
            v-if="
              myConfig.messageConfig.showPosition &&
              myConfig.messageConfig.positionMultiple
            "
          >
            组织架构 | 会内职务2
          </div>
          <div
            class="me-level-point"
            v-if="myConfig.messageConfig.showLevelPoint"
          >
            <div class="me-level">
              <div class="level-content">等级名称</div>
            </div>
            <div class="me-point">
              <div class="point-content">
                <img
                  class="icon"
                  src="../../assets/images/st-me-page/point-icon.png"
                />
                <p>999积分</p>
                <img
                  class="right-icon"
                  src="../../assets/images/st-me-page/right-icon.png"
                />
              </div>
            </div>
          </div>
          <!-- 资料完善进度 -->
          <div v-if="myConfig.messageConfig.showSchedule" class="schedule-box">
            <div class="schedule-content flex">
              <div class="flex-1">
                <div class="schedule-text">你的个人信息已完善 70%</div>
                <div class="schedule-progress"></div>
              </div>
              <div class="schedule-btn">去完善</div>
            </div>
          </div>
          <div class="me-count flex-center">
            <div
              v-for="(item, index) in myList"
              :key="index"
              class="count-item flex-col"
              v-if="myConfig.messageConfig[item].value"
            >
              <span class="count-num">99</span>
              <span>{{
                myConfig.messageConfig[item].jump_link.jump_config.name
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="me-card template2"
          v-if="myConfig.messageConfig.styleType === 'template2'"
        >
          <img
            v-if="myConfig.messageConfig.showSignin"
            class="signin-icon"
            src="../../assets/images/st-me-page/sign-in.png"
          />
          <div class="card-main">
            <div class="me-avatar">
              <img
                class="me-avatar-image"
                src="../../assets/images/st-me-page/avatar1.png"
              />
            </div>
            <div class="me-card-right">
              <div class="me-nickname">社团云演示</div>
              <div class="me-intro" v-if="myConfig.messageConfig.showPosition">
                组织架构 | 会内职务
              </div>
              <div
                class="me-intro"
                v-if="
                  myConfig.messageConfig.showPosition &&
                  myConfig.messageConfig.positionMultiple
                "
              >
                组织架构 | 会内职务2
              </div>
              <div
                class="me-level-point"
                v-if="myConfig.messageConfig.showLevelPoint"
              >
                <div class="level-content">等级名称</div>
                <div class="point-content">
                  <img
                    class="icon"
                    src="../../assets/images/st-me-page/point-icon.png"
                  />
                  <p>999积分</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 资料完善进度 -->
          <div v-if="myConfig.messageConfig.showSchedule" class="schedule-box">
            <div class="schedule-content flex">
              <div class="flex-1">
                <div class="schedule-text">你的个人信息已完善 70%</div>
                <div class="schedule-progress"></div>
              </div>
              <div class="schedule-btn">去完善</div>
            </div>
          </div>
          <div class="me-count flex-center">
            <div
              v-for="(item, index) in myList"
              :key="index"
              class="count-item flex-col"
              v-if="myConfig.messageConfig[item].value"
            >
              <span class="count-num">99</span>
              <span>{{
                myConfig.messageConfig[item].jump_link.jump_config.name
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="me-card template3"
          v-if="myConfig.messageConfig.styleType === 'template3'"
        >
          <img
            v-if="myConfig.messageConfig.showSignin"
            class="signin-icon2"
            src="../../assets/images/st-me-page/sign-in2.png"
          />
          <div class="card-main">
            <div class="me-avatar">
              <img
                class="me-avatar-image"
                src="../../assets/images/st-me-page/avatar1.png"
              />
            </div>

            <div class="me-card-right">
              <div class="me-nickname">社团云演示</div>
              <div
                class="me-level-point"
                v-if="myConfig.messageConfig.showLevelPoint"
              >
                <div class="level-content">等级名称</div>
                <!-- <div class="point-content">
                  <img
                    class="icon"
                    src="../../assets/images/st-me-page/point-icon.png"
                  />
                  <p>999积分</p>
                </div> -->
              </div>
              <div class="me-intro" v-if="myConfig.messageConfig.showPosition">
                组织架构 | 会内职务
              </div>
              <div
                class="me-intro"
                v-if="
                  myConfig.messageConfig.showPosition &&
                  myConfig.messageConfig.positionMultiple
                "
              >
                组织架构 | 会内职务2
              </div>
            </div>
          </div>
          <!-- 资料完善进度 -->
          <div v-if="myConfig.messageConfig.showSchedule" class="schedule-box">
            <div class="schedule-content flex">
              <div class="flex-1">
                <div class="schedule-text">你的个人信息已完善 70%</div>
                <div class="schedule-progress"></div>
              </div>
              <div class="schedule-btn">去完善</div>
            </div>
          </div>
          <div class="me-count flex-center">
            <div
              v-for="(item, index) in myList"
              :key="index"
              class="count-item flex-col"
              v-if="myConfig.messageConfig[item].value"
            >
              <span class="count-num">99</span>
              <span>{{
                myConfig.messageConfig[item].jump_link.jump_config.name
              }}</span>
            </div>
          </div>
          <!-- 勋章 -->
          <div class="medal-box" v-if="myConfig.messageConfig.showMedal">
            <div
              class="medal"
              :style="{
                backgroundImage: `url(${require('../../assets/images/st-me-page/medal-box.png')})`,
              }"
            >
              <img
                class="medal-text"
                src="../../assets/images/st-me-page/medal-text.png"
              />

              <div class="medal-list">
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-1.png"
                />
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-2.png"
                />
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-3.png"
                />
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-4.png"
                />
                <img
                  class="medal-item"
                  src="../../assets/images/st-me-page/medal-5.png"
                />
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="template3-bg"-->
        <!--        v-if="myConfig.messageConfig.styleType === 'template3'"-->
        <!--        :style="{-->
        <!--          top: `${myConfig.header.height + 80}px`-->
        <!--        }">-->
        <!--          <img src="../../assets/images/st-me-page/template3-bg.png" />-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import StPageSet from '@/modules/app-design/components/Preview/StPageSet'

const JUMP_LINK_MODEL = require('@/modules/app-design/assets/jsons/jump-link.json')

export default {
  name: 'StMePage',
  components: { StPageSet },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        header: {
          statusBarColor: 'black',
          template: 'square',
          height: 0,
          radian: 0,
          opacity: 0,
          bgType: 'color',
          bgColor: '#ffffff',
          bgImage: '',
        },
        messageConfig: {
          styleType: 'template1',
          showPosition: 1,
          showSchedule: 0,
          positionMultiple: 0,
          showLevelPoint: 1,
          showSignin: 1,
          showMedal: 1,
          quickEnterOne: {
            jump_link: JUMP_LINK_MODEL,
            label: '',
            value: '',
          },
          quickEnterTwo: {
            jump_link: JUMP_LINK_MODEL,
            label: '',
            value: '',
          },
          quickEnterThree: {
            jump_link: JUMP_LINK_MODEL,
            label: '',
            value: '',
          },
          quickEnterFour: {
            jump_link: JUMP_LINK_MODEL,
            label: '',
            value: '',
          },
          showPayDialog: 1,
        },
        shareConfig: {
          title: '',
          description: '',
          image: '',
        },
      },
      myList: [
        'quickEnterOne',
        'quickEnterTwo',
        'quickEnterThree',
        'quickEnterFour',
      ],
      showList: [
        { label: '消息', num: 99, value: 'my_message' },
        { label: '收藏', num: 99, value: 'my_collect' },
        { label: '资讯', num: 99, value: 'my_article' },
      ],
      // 状态栏颜色
      statusBar: {
        white: 'white-status-bar',
        black: 'start-top',
      },
      lineNum: 4,
      baseUrl:
        window.serverConfig.VUE_APP_ADMINURL || 'https://saasapi.shetuan.pro',
      platform: this.$route.params.platform,
    }
  },
  watch: {
    'myConfig.messageConfig.quickEnterOne'(val) {
      const data = this.myList.find((el) => el.value == val)
      this.showList.splice(0, 1, data)
    },
    'myConfig.messageConfig.quickEnterTwo'(val) {
      const data = this.myList.find((el) => el.value == val)
      this.showList.splice(1, 1, data)
    },
    'myConfig.messageConfig.quickEnterThree'(val) {
      const data = this.myList.find((el) => el.value == val)
      this.showList.splice(2, 1, data)
    },
    'myConfig.messageConfig.quickEnterFour'(val) {
      const data = this.myList.find((el) => el.value == val)
      this.showList.splice(3, 1, data)
    },
  },
  computed: {
    myConfig() {
      // console.log("this.config", this.config);
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss" scoped>
.st-me-page {
  width: 100%;
  position: relative;
  // overflow: hidden;

  .start-top-img {
    width: 100%;
    height: 65px;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .me-bg {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .me-bg-image {
      position: absolute;
      height: 100%;
      width: 375px;
    }
  }

  .me-content {
    /* padding-top 计算方式（状态栏，导航栏，固定的 80）： 20 + 44 + 80 */
    padding: 144px 12px 0;
    position: relative;
    z-index: 2;

    /*&.h5 {*/
    /*  padding: 80px 20px 0;*/
    /*}*/

    .template3-bg {
      position: absolute;
      left: 0;
      width: 100%;
      top: 180px;
      z-index: -1;

      img {
        width: 100%;
        height: 67px;
      }
    }
    .me-card {
      border-radius: 8px;
      background-color: white;
      position: relative;

      & + .me-card {
        margin-top: 8px;
      }

      .signin-icon {
        position: absolute;
        width: 72px;
        height: 25px;
        top: 40px;
        right: 0px;
      }
      .signin-icon2 {
        position: absolute;
        width: 47px;
        height: 31px;
        top: 14px;
        right: -6px;
      }
    }

    .template1 {
      text-align: center;
      .me-avatar {
        height: 34px;
        position: relative;

        .me-avatar-image {
          border-radius: 50%;
          background-color: $image-placeholder;
          width: 68px;
          height: 68px;
          position: absolute;
          bottom: 0;
          left: calc(50% - 34px);
        }
      }

      .me-nickname {
        margin-top: 10px;
        font-size: 18px;
        color: #303030;
        padding: 5px 0;
      }

      .me-intro {
        font-size: 12px;
        color: #888;
        line-height: 17px;
        padding: 5px 0;
      }

      .me-level-point {
        display: flex;
        margin-top: 3px;
        margin-bottom: 12px;
        .me-level {
          flex: 1;
          .level-content {
            border-radius: 18px;
            margin-right: 5px;
            float: right;
            padding: 2px 9px;
            font-size: 12px;
            color: #8a8e9c;
            background: #edeef3;
            line-height: 14px;
          }
        }

        .me-point {
          flex: 1;
          text-align: start;
          .point-content {
            float: left;
            margin-left: 5px;
            display: flex;
            align-items: center;
            padding: 2px 8px 2px 5px;
            border-radius: 18px;
            background: #edeef3;
            .icon {
              width: 14px;
              height: 14px;
            }
            .right-icon {
              width: 4px;
              height: 7px;
              margin-left: 2px;
            }
            p {
              font-size: 12px;
              color: #777c8b;
              line-height: 14px;
            }
          }
        }
      }

      .me-count {
        display: flex;
        padding: 8px 0 28px;

        .count-item {
          font-size: 14px;
          color: #888;
          width: 101px;
        }

        .count-num {
          font-weight: bold;
          size: 20px;
          color: #353535;
          margin-bottom: 10px;
        }
      }
    }

    .template2 {
      .card-main {
        padding: 24px 22px 0;
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .me-avatar {
          margin-right: 25px;
          .me-avatar-image {
            border-radius: 50%;
            background-color: $image-placeholder;
            width: 68px;
            height: 68px;
          }
        }

        .me-card-right {
          .me-nickname {
            font-size: 18px;
            color: #303030;
            line-height: 25px;
          }

          .me-intro {
            font-size: 12px;
            color: #888;
            line-height: 17px;
            margin-top: 1px;
          }

          .me-level-point {
            display: flex;
            margin-top: 7px;
            .level-content {
              border-radius: 18px;
              padding: 2px 9px;
              font-size: 12px;
              color: #8a8e9c;
              background: #edeef3;
              line-height: 14px;
            }

            .point-content {
              margin-left: 10px;
              display: flex;
              align-items: center;
              padding: 2px 8px 2px 5px;
              border-radius: 18px;
              background: #edeef3;
              .icon {
                width: 14px;
                height: 14px;
              }
              p {
                font-size: 12px;
                color: #777c8b;
                line-height: 14px;
              }
            }
          }
        }
      }
      .me-count {
        text-align: center;
        display: flex;
        padding: 8px 0 28px;

        .count-item {
          font-size: 14px;
          color: #888;
          width: 101px;
        }

        .count-num {
          font-weight: bold;
          size: 20px;
          color: #353535;
          margin-bottom: 10px;
        }
      }
    }
    .template3 {
      .card-main {
        padding: 12px 12px 0;
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .me-avatar {
          margin-top: -60px;
          margin-right: 12px;
          .me-avatar-image {
            border-radius: 50%;
            background-color: $image-placeholder;
            width: 72px;
            height: 72px;
            border: 5px solid #fff;
          }
        }

        .me-card-right {
          .me-nickname {
            color: #303030;
            font-size: 16px;
            font-weight: 500;
            line-height: 25px;
          }

          .me-intro {
            font-size: 12px;
            color: #888;
            line-height: 17px;
            margin-top: 1px;
          }

          .me-level-point {
            display: flex;
            margin: 4px 0;
            .level-content {
              border-radius: 18px;
              padding: 2px 9px;
              color: #808080;
              font-size: 10px;
              font-weight: 400;
              background: #edeef3;
              line-height: 14px;
            }

            .point-content {
              margin-left: 10px;
              display: flex;
              align-items: center;
              padding: 2px 8px 2px 5px;
              border-radius: 18px;
              background: #edeef3;
              .icon {
                width: 14px;
                height: 14px;
              }
              p {
                font-size: 12px;
                color: #777c8b;
                line-height: 14px;
              }
            }
          }
        }
      }
      .me-count {
        text-align: center;
        display: flex;
        padding: 8px 0 12px;

        .count-item {
          font-size: 14px;
          color: #888;
          width: 101px;
        }

        .count-num {
          font-weight: bold;
          size: 20px;
          color: #353535;
          margin-bottom: 10px;
        }
      }
      .medal-box {
        padding: 0 12px 12px;
        .medal {
          width: 100%;
          padding: 16px 15px;
          background-size: 100% 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .medal-text {
            width: 87px;
            height: 21px;
          }
          .medal-list {
            display: flex;
            align-items: center;

            .medal-item {
              width: 32px;
              height: 32px;
              // background-color: pink;
            }
            .medal-item + .medal-item {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .func-list {
    padding: 10px 0;

    .func-group {
      display: flex;
      justify-content: flex-start;
      position: relative;

      & + .func-group {
        &:before {
          content: '';
          position: absolute;
          width: 90%;
          height: 1px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: #f1f1f1;
        }
      }
    }

    .func-item {
      width: 84px;
      padding: 10px 0;
    }

    .func-icon {
      width: 36px;
      height: 36px;
    }

    .func-text {
      font-size: 12px;
      color: #353535;
      margin-top: 4px;
    }
  }

  .me-item {
    width: 90%;
    height: 65px;
    margin: 0 auto;
  }
  .schedule-box {
    padding: 8px 20px;
    .schedule-content {
      background: rgba(0, 170, 255, 0.05);
      height: 50px;
      padding: 12px;
      border-radius: 8px;
      .schedule-text {
        font-size: 12px;
        color: rgba(0, 170, 255, 1);
        height: 18px;
        line-height: 18px;
        margin-bottom: 4px;
      }
      .schedule-progress {
        width: 200px;
        height: 4px;
        border-radius: 999em;
        background: rgba(0, 170, 255, 0.1);
        position: relative;
        &::after {
          position: absolute;
          content: '';
          width: 70%;
          height: 100%;
          background-color: rgba(0, 170, 255, 1);
          border-radius: 999em;
          left: 0;
          top: 0;
        }
      }
      .schedule-btn {
        width: 52px;
        height: 28px;
        background-color: rgba(0, 170, 255, 1);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 4px;
      }
    }
  }
}
</style>
