<!--
 * @Author: dongjia
 * @Date: 2022-03-02 10:23:52
 * @LastEditTime: 2022-03-02 10:46:51
 * @LastEditors: aleaner
 * @Description: 我关注的人列表
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\st-community-nav\FollowMemberList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="follow-members">
    <StListTitle :config="listTitleConfig" style="padding: 16px 16px 0" />
    <div class="member-list">
      <div class="member-item" v-for="num in 6">
        <el-image class="member-avatar" :src="avatars[num%6]" fit="cover"></el-image>
        <div class="member-name">会员名称</div>
      </div>
    </div>
  </div>
</template>

<script>
import StListTitle from "../Common/StListTitle";
export default {
  components: { StListTitle },
  data() {
    return {
      listTitleConfig: {
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "我关注的人",
          fontWeight: "bold",
          fontSize: 14,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template2",
          title: "查看更多",
        },
      },
      avatars: [
        require("../../../assets/images/st-spirit-list/circle-avatar1.png"),
        require("../../../assets/images/st-spirit-list/circle-avatar2.png"),
        require("../../../assets/images/st-spirit-list/circle-avatar3.png"),
        require("../../../assets/images/st-spirit-list/circle-avatar4.png"),
        require("../../../assets/images/st-spirit-list/circle-avatar5.png"),
        require("../../../assets/images/st-spirit-list/circle-avatar6.png"),
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.follow-members {
  .member-list {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-left: 16px;
    margin-top: 12px;
    .member-item + .member-item {
      margin-left: 12px;
    }
    .member-item {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .member-avatar {
        width: 62px;
        height: 62px;
        border-radius: 50%;
      }
      .member-name {
        margin-top: 8px;
        font-size: 14px;
        color: #1a1a1a;
        line-height: 1.5;
      }
    }
  }
}
</style>