<template>
  <div class="el-form-item" style="margin-bottom: 24px;">
    <p class="title">{{label}}</p>
    <div class="handle-area">
      <div class="handle-item">
        <label>左上角</label>
        <el-slider class="slider" v-model="num.borderRadiusTopLeft" :min="min" :max="max" :step="step" show-input :show-tooltip="false"></el-slider>
      </div>
      <div class="handle-item">
        <label>右上角</label>
        <el-slider class="slider" v-model="num.borderRadiusTopRight" :min="min" :max="max" :step="step" show-input :show-tooltip="false"></el-slider>
      </div>
      <div class="handle-item">
        <label>左下角</label>
        <el-slider class="slider" v-model="num.borderRadiusBottomLeft" :min="min" :max="max" :step="step" show-input :show-tooltip="false"></el-slider>
      </div>
      <div class="handle-item">
        <label>右下角</label>
        <el-slider class="slider" v-model="num.borderRadiusBottomRight" :min="min" :max="max" :step="step" show-input :show-tooltip="false"></el-slider>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "StBorderRadiusSetter",
    model: {
      prop: "num",
      event: "numChange",
    },
    props: {
      label: {
        type: String,
        default: "圆角设置"
      },
      num: {
        type: Object,
        required: true
      },
      min: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 100,
      },
      step: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {};
    },
    computed: {
      value: {
        get() {
          return this.num;
        },
        set(val) {
          this.$emit("numChange", val);
        },
      },
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .title {
    line-height: 14px;
    margin-bottom: 18px;
    font-size: 14px;
    color: #606266;
  }
  .handle-area {
    width: 100%;
    display: flex;
    flex-direction: column;

    .handle-item {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #606266;

      label {
        margin-bottom: 18px;
      }
    }

    .slider {
      flex: 1;
      /*padding-left: 10px;*/
    }
  }
</style>
