<template>
  <div class="drag-list st-drag-water-mark">
    <draggable v-if="navList" v-model="navList" class="nav-list" :disabled="unDraggable" animation="500"
      draggable=".nav-item">
      <div class="nav-item" v-for="(nav, index) in navList" :key="index">
        <div class="item-title">
          <p class="title-text">{{navTitle[index]}}</p>
          <div class="show-config" v-if="nav.is_show !== undefined">
            <p class="show-text">隐藏</p>
            <el-checkbox v-model="nav.is_show" :true-label="0" :false-label="1"></el-checkbox>
          </div>
        </div>
        <div class="item-content" v-if="nav.is_show">
          <div class="nav-content" v-if="nav.type !== undefined">
            <p class="nav-title">展现类型</p>
            <el-radio-group v-model="nav.type" class="radio-group" size="small">
              <el-radio-button v-for="(option, index) in typeOptions" :key="option.value"
                :label="option.value || option">
                {{option.label || option}}</el-radio-button>
            </el-radio-group>
          </div>
          <div class="nav-content" v-if="nav.icon !== undefined && nav.type === 'icon'">
            <p class="nav-title">图片内容</p>
            <SingleMediaWall v-model="nav.icon" :width="60" :height="60" :showInline="true" addText="" ratio="750:40">
              <p slot="info" class="img-info">建议图片尺寸750*40图片格式为PNG</p>
            </SingleMediaWall>
          </div>
          <div class="nav-content" v-if="nav.content !== undefined && nav.type === 'content'">
            <p class="nav-title">文字内容</p>
            <el-input style="width: 100%" v-model="nav.content" placeholder="请输入文字内容"
              @focus="unDraggable = true" @blur="unDraggable = false"></el-input>
          </div>
          <div class="nav-content">
            <p class="nav-title">跳转设置</p>
            <st-link-config v-model="nav.jump_link" size="small" style="width: 100%;"
              :selectable_type="['h5_link', 'phone_link']" placeholder="设置链接">
            </st-link-config>
          </div>
          <!--          <div class="nav-content" v-if="nav.status !== undefined">-->
          <!--            <p class="nav-title">联系电话</p>-->
          <!--            <el-radio-group v-model="nav.status" class="radio-group" size="small">-->
          <!--              <el-radio-button v-for="(option, index) in phoneOptions" :key="option.value"-->
          <!--                :label="option.value">-->
          <!--                {{option.label || option}}</el-radio-button>-->
          <!--            </el-radio-group>-->
          <!--          </div>-->
          <!--          <div class="nav-content" v-if="nav.jump_link !== undefined">-->
          <!--            <p class="nav-title">电话号码</p>-->
          <!--            <el-input style="width: 100%" v-model="nav.jump_link.jump_config.phone" placeholder="请输入电话号码"-->
          <!--              @focus="unDraggable = true" @blur="unDraggable = false">-->
          <!--            </el-input>-->
          <!--          </div>-->
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
import StLinkConfig from "@/modules/app-design/components/Form/StLinkConfig";
export default {
  name: "StDragWaterMark",
  components: {
    StLinkConfig,
    draggable,
    SingleMediaWall,
  },
  model: {
    prop: "list",
    event: "listChange",
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentIndex: -1,
      typeOptions: [
        { value: "content", label: "文字" },
        { value: "icon", label: "图片" },
      ],
      phoneOptions: [
        { value: 1, label: "开启" },
        { value: 0, label: "关闭" },
      ],
      navTitle: ["第一行", "第二行", "第三行"],
      unDraggable: false,
    };
  },
  computed: {
    navList: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit("listChange", val);
      },
    },
  },
  watch: {
    navList: {
      handler(val) {
        this.$emit("listChange", val);
      },
      deep: true,
    },
  },
};
</script>

<style lang='scss' scoped>
.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.nav-list {
  background-color: #f6f9ff;
  margin: -20px -24px 0;
  padding: 16px 12px;
}
.nav-item {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;

  .item-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 22px;
    border-bottom: 1px #eeeeee solid;

    .title-text {
      flex-shrink: 0;
      font-size: 16px;
      font-weight: bold;
      color: #3a3a3a;
      line-height: 16px;
    }

    .show-config {
      padding-left: 20px;
      display: flex;
      align-items: center;

      .show-text {
        margin-right: 10px;
        font-size: 13px;
        color: #3a3a3a;
        line-height: 13px;
      }
    }
  }
  .item-content {
    .nav-content {
      margin-top: 20px;
      display: flex;
      position: relative;
      .nav-title {
        line-height: 30px;
        font-size: 14px;
        color: #3a3a3a;
        margin-right: 18px;
        min-width: 56px;
      }

      .radio-group {
        margin-left: auto;
      }
      .nav-btn {
        width: 100%;
        height: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .img-info {
        color: #c0c4cc;
        position: absolute;
        bottom: 0;
        left: 150px;
        font-size: 12px;
        line-height: 17px;
      }

      .link-selected {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .link-name {
          width: 175px;
          ::v-deep.el-input__inner {
            text-overflow: ellipsis;
            white-space: nowrap;
            background-color: #fff;
            color: #3a3a3a;
            cursor: text;
          }
        }
        ::v-deep.el-button--text {
          padding: 0;
        }
      }
    }
  }
}
.el-input,
.el-select {
  ::v-deep.el-input__inner {
    height: 30px;
  }
  ::v-deep.el-input__icon {
    line-height: 30px;
  }
}

.nav-item + .nav-item {
  margin-top: 24px;
}

.drag-list {
  ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(53, 118, 255, 0.15);
    border: 1px solid #3576ff;
    color: #3576ff;
    box-shadow: none;
  }
}

.st-drag-water-mark .nav-list {
  padding-left: $space * 2;
  padding-right: $space * 2;
  padding-top: $space * 2;
}
</style>
