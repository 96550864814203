<!--
 * @Author: dongjia
 * @Date: 2022-01-12 16:58:46
 * @LastEditTime: 2022-01-12 17:41:06
 * @LastEditors: aleaner
 * @Description: 订阅消息系统页
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StSubscribeMsg.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="page-index">
    <div style="background-color: #fff;">
      <img class=" status-bar" src="../../assets/images/phone-statusbar1@2x.png" />
      <div class="header">
        <img class="header-left" src="../../assets/images/middle/Back1@2x.png" />
        <div class="header-center">订阅消息设置</div>
        <img class="header-right" src="../../assets/images/wechat-handle1@2x.png" />
      </div>
    </div>
    <div class="page-content">
      <img class="content-img1" src="../../assets/images/st-subscribe-msg/image1.png" />
      <div class="content-img2-box">
        <img class="content-img2" src="../../assets/images/st-subscribe-msg/image2.png" />
        <div class="config-btn">去设置开启</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StSubscribeMsg",
};
</script>

<style lang="scss" scoped>
.page-index {
  min-height: 667px;
  background-color: #fafafa;
  .status-bar {
    width: 100%;
    height: 20px;
  }
  .header {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .header-left {
      margin-left: 11px;
      width: 20px;
      height: 20px;
    }
    .header-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 1.5;
    }
    .header-right {
      width: 87px;
      height: 32px;
    }
  }
  .page-content {
    padding: 16px;
    .content-img1 {
      width: 100%;
      height: 490px;
    }
    .content-img2-box {
      margin-top: 24px;
      width: 100%;
      height: 426px;
      position: relative;
      .content-img2 {
        width: 100%;
        height: 100%;
      }
      .config-btn {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 250px;
        height: 48px;
        background: var(--main-color);
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 1;
      }
    }
  }
}
</style>