<!--
 * @Author: dongjia
 * @Date: 2021-09-26 15:09:33
 * @LastEditTime: 2021-09-26 16:07:47
 * @LastEditors: aleaner
 * @Description: 信息反馈内容组件
 * @FilePath: \app-design\components\Preview\StMessageFeedbackContent.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="feedback-content">
    <div class="feedback-select" v-if="myConfig.selectConfig.isShowCategory">
      <p class="select-name" v-if="myConfig.selectConfig.categoryRequired"><span>*</span>反馈类别</p>
      <p class="select-name" v-else><span></span>反馈类别</p>
      <div class="right">
        请选择
        <p class="el-icon-arrow-right select-icon"></p>
      </div>
    </div>
    <div class="feedback-select" v-if="myConfig.selectConfig.isShowSelect">
      <p class="select-name"><span>*</span>反馈对象</p>
      <div class="right">
        请选择
        <p class="el-icon-arrow-right select-icon"></p>
      </div>
    </div>
    <div
      class="input-title"
      :style="{
        height: myConfig.contentConfig.inputTitleHeight + 'px',
      }"
    >
      {{ myConfig.contentConfig.inputTitlePlaceholder }}
    </div>
    <div
      class="input-content"
      :style="{
        height: myConfig.contentConfig.inputContentHeight + 'px',
      }"
    >
      <p class="input-tip">
        {{ myConfig.contentConfig.inputContentPlaceholder }}
      </p>
      <p class="input-limit">
        {{
          myConfig.contentConfig.inputContentLimitType === "custom"
            ? `0/${myConfig.contentConfig.inputContentLimit}`
            : ""
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StMessageFeedbackContent",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        contentConfig: {
          titleRequire: 1,
          inputTitlePlaceholder: "请输入标题",
          inputTitleLimitType: "custom",
          inputTitleLimit: 50,
          inputTitleHeight: 38,
          contentRequire: 1,
          inputContentPlaceholder: "请输入内容",
          inputContentLimitType: "custom",
          inputContentLimit: 1000,
          inputContentHeight: 256,
        },
        selectConfig: {
          isShowSelect: 1,
          isShowCategory: 1,
          selectData: 0,
          categoryRequired: 1,
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-content {
  padding: 20px;
}
.feedback-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-text {
    width: 170px;
    flex-shrink: 0;
    .top-text {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 24px;
    }
    .bottom-text {
      font-size: 14px;
      color: #353535;
      line-height: 18px;
      margin-top: 10px;
    }
  }
  .title-icon {
    width: 140px;
    height: 77px;
  }
}

.input-title {
  width: 100%;
  // background: #f7f8fa;
  border-radius: 4px;
  // padding: 0 12px;
  font-size: 18px;
  font-weight: 500;
  color: #b7bec8;
  margin-top: 20px;
  color: #b3b3b3;
}

.input-content {
  margin-top: 16px;
  // padding: 12px;
  position: relative;
  width: 100%;
  // background: #f7f8fa;
  border-radius: 4px;
  .input-tip {
    font-size: 14px;
    color: #b3b3b3;
    line-height: 21px;
  }
  .input-limit {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-size: 12px;
    color: #a1a7af;
    line-height: 17px;
  }
}

.feedback-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  background-color: #f6f7f8;
  margin-bottom: 8px;
  .select-name {
    font-size: 14px;
    color: #808080;
    line-height: 16px;
    span {
      display:inline-block;
      color: red;
      width: 10px;
    }
  }
  .right {
    font-size: 14px;
    color: #979da6;

    display: flex;

    align-items: center;
    .select-icon {
      margin-left: 5px;
      font-size: 16px;
      color: #979da6;
      line-height: 16px;
    }
  }
}
</style>
