<template>
  <!-- :class="
    activity.activity_status_text_two === 1 ? '' : activity.activity_status_text_two === 4 ? 'off-btn' : 'active-btn',
  " -->
  <span
    :class="{
      'button': role === 'button',
      'tag': role === 'tag',
      'streaming-btn': activity[statusKey] === '直播中',
      'off-btn':
        [4].includes(activity.activity_status_text_two) ||
        activity[statusKey] === '已结束' ||
        activity[statusKey] === '报名未开始' ||
        activity[statusKey] === '活动未开始' ||
        activity[statusKey] === '直播已取消' ||
        activity[statusKey] === '禁播' ||
        activity[statusKey] === '已过期' ||
        activity[statusKey] === '异常',
      'active-btn':
        [3].includes(activity.activity_status_text_two) ||
        activity[statusKey] === '进行中' ||
        activity[statusKey] === '直播未开始' ||
        activity[statusKey] === '未开始',
      'ready-btn':
        [5].includes(activity.activity_status_text_two) ||
        activity[statusKey] === '报名中'
    }"
    :style="{
      'border-radius': role === 'tag' ? `0 ${(radius || 0)}px 0 ${(radius || 0)}px` : ''
    }"
    class="activity-status-btn status-btn"
    >{{ activity[statusKey] }}</span
  >
</template>

<script>
export default {
  name: 'activity-status-btn',
  props: {
    activity: Object,
    isGov: [Boolean, Number],
    role: String, // button or 右上角 tag
    radius: Number, // 圆角要读配置时传
  },
  data() {
    return {}
  },
  computed: {
    statusKey() {
      return this.isGov ? 'status_text' : 'activity_status_text_three'
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.status-btn {
  font-size: 12px;
  border-radius: 2px;
  text-align: center;
  padding: 6px;
  display: inline-block;
  line-height: 1;

  &.tag {
    min-width: 51px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    font-weight: bold; // 700
    box-sizing: border-box;
    padding: 0 6px;

    &.active-btn {
      background-color: #FF7B1B;
      color: white;
    }

    &.ready-btn {
      background-color: #FFA551;
      color: white;
    }

    &.off-btn {
      background-color: #808080;
      color: white;
    }
  }
}

.active-btn {
  background-color: #FF7B1B1a;
  color: #FF7B1B;
}

.ready-btn {
  /*background-color: var(--secondary-color);*/
  /*color: var(--theme-color);*/
  /*background-color: #ff26471a;*/
  /*color: #FF2647;*/

  background-color: #FFA5511a;
  color: #FFA551;
}

.streaming-btn {
  color: rgba(255, 27, 27, 1);
  background: rgba(255, 27, 27, 0.1);
}

.off-btn {
  background-color: rgba(128, 128, 128, 0.1);
  color: rgba(128, 128, 128, 1);
}
</style>
