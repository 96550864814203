<template>
  <div class="brand-list" :style="[myRootStyle, {
        padding: `12px ${myConfig.templateData.styleType === 'template3'?'16':'0'}px`
    }]">
    <StListTitle :style="{
      padding: `0 ${myConfig.templateData.styleType !== 'template3'?'16':'0'}px 16px`
    }" :config="myConfig" />
      <div v-loading="loading" class="swiper-view template1"
           :class="[myConfig.dataConfig.interactiveType]"
           v-if="myConfig.templateData.styleType === 'template1'"
           :style="{
        'grid-gap': (myConfig.templateData.dataSpacing + 'px'),
    }">
        <div class="list-item" v-for="(item,index) in brand_list" :key="index">
          <st-load-image class="item-image" :src="item.page_cover" :size="300" />
          <div class="item-content">
            <p class="item-title">{{item.name}}</p>
            <div>
              <div class="item-news" v-if="item.articles && item.articles.length && item.articles[0].title||myConfig.dataConfig.interactiveType==='vertical'">
                <div class="news-tip">资讯</div>
                <div class="news-content">
                  {{item.articles && item.articles.length && item.articles[0].title?item.articles[0].title:'暂无内容'}}</div>
              </div>
              <div class="item-news" v-if="item.activity && item.activity.length && item.activity[0].name||myConfig.dataConfig.interactiveType==='vertical'">
                <div class="news-tip">活动</div>
                <div class="news-content">
                  {{item.activity && item.activity.length && item.activity[0].name?item.activity[0].name:'暂无内容'}}</div>
              </div>
            </div>
            <div class="item-collect">
              <div class="collect-avatar">
                <st-avatars :arr="item.collectedUsers"></st-avatars>
              </div>
              <div class="collect-content"><span style="margin-right: 6px" v-if="item.collects_count">共
                {{item.collects_count > 999?'999+':item.collects_count}} 名会员</span>
                <span v-else>暂无会员</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div v-loading="loading" class="swiper-view template2"
      v-if="myConfig.templateData.styleType === 'template2'">
      <div class="list-item" :style="{
        marginRight: myConfig.templateData.dataSpacing + 'px',
      }" v-for="(item,index) in brand_list" :key="index">
        <st-load-image class="item-image" :src="item.page_cover" :size="200" />
        <div class="item-content">
          <p class="item-title">{{item.name}}</p>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="swiper-view template3"
      v-if="myConfig.templateData.styleType === 'template3'" style="padding-left: 0px">
      <div class="list-item" :style="{
        marginTop: index === 0?'0':myConfig.templateData.dataSpacing + 'px',
      }" v-for="(item, index) in brand_list" :key="index">
        <st-load-image class="item-image" :src="item.page_cover" :size="200" />
        <div class="item-content">
          <p class="item-title">{{item.name}}</p>
          <p class="item-desc">{{item.description}}</p>
        </div>
      </div>

      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{myConfig.loadingConfig.handleText}}</div>
      </div>
    </div>
    <div v-loading="loading" class="swiper-view template4"
      v-if="myConfig.templateData.styleType === 'template4'" style="padding: 0 16px">
      <!-- 循环播放 -->
      <div ref="mySwiper" class="banner-swiper" :style="{
       justifyContent: brand_list.length > 2?'center':''
      }">
        <!-- 通过添加swiper-no-swiping类名实现禁止拖动 -->
        <div class="swiper-no-swiping banner" v-for="(item, index) in brandSwiperList" :key="index">
          <div class="banner-image-box" :style="{
              borderRadius: myConfig.templateData.borderRadius + 'px'
            }">
            <st-load-image class="banner-image" :src="item.page_cover" :border-radius="myConfig.templateData.borderRadius" :size="400" />
          </div>
          <div class="banner-content" :data-index="index">
            <div class="content-tag">
              <el-image class="tag-img" fit="cover"
                :src="require('../../assets/images/st-brand-list/circle-star.png')"></el-image>
              <p class="tag-content">{{myConfig.listTitle.title?myConfig.listTitle.title:'标题名称'}}</p>
            </div>
            <div class="content-mask">
              <div class="content-title">{{item.name}}</div>
              <div class="item-collect">
                <div class="collect-avatar">
                  <st-avatars :arr="item.collectedUsers"></st-avatars>
                </div>
                <div class="show-detail">查看详情</div>
              </div>
            </div>
          </div>
          <!-- <div v-show="false" :class="['swiper-pagination',myConfig.normal.indicatorType]">
          </div> -->
        </div>
      </div>
    </div>

    <div v-if="myConfig.templateData.styleType === 'template5'" v-loading="loading"
      class="swiper-view template5">
      <div class="list-item" :style="{
        marginTop: index === 0?'0':myConfig.templateData.dataSpacing + 'px'
      }" v-for="(item, index) in brand_list" :key="index">
        <StBrandItemBig :item="item" :borderRadius="myConfig.templateData.borderRadius"></StBrandItemBig>
      </div>
      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{myConfig.loadingConfig.handleText}}</div>
      </div>
    </div>

    <div v-if="myConfig.templateData.styleType === 'template6'" v-loading="loading"
      class="swiper-view template6">
      <div class="list-item" :class="((!item.activity || !item.activity.length) && (!item.articles||!item.articles.length))?'style1':'style2'" :style="{
        marginTop: index === 0?'0':myConfig.templateData.dataSpacing + 'px',
        borderRadius: myConfig.templateData.borderRadius + 'px'
      }" v-for="(item, index) in brand_list" :key="index">
        <div class="item-cover-box" :style="{
          backgroundColor: item.page_cover?'':'#F3F5F7'
        }">
          <StGroundGlass v-if="!((!item.activity||!item.activity.length) && (!item.articles||!item.articles.length)) && item.page_cover"
            :bgImage="item.page_cover" />
          <el-image class="item-cover"
            v-if="((!item.activity||!item.activity.length) && (!item.articles||!item.articles.length)) && item.page_cover"
            :src="fixSpecialImageUrl(item.page_cover, 'large', index)" fit="cover" />
        </div>
        <!-- 无资讯和活动数据内容 -->
        <div class="main-content1" v-if="(!item.activity||!item.activity.length) && (!item.articles||!item.articles.length)">
          <div class="item-name">{{item.name}}</div>
          <div class="item-desc">{{item.description}}</div>
        </div>
        <!-- 有资讯或者活动数据内容 -->
        <div class="main-content2" v-else>
          <div class="item-name">{{item.name}}</div>
          <div class="content-list">
            <!-- 资讯数据 -->
            <div class="list-data" v-if="item.articles.length" :style="{
              borderRadius: myConfig.templateData.borderRadius + 'px'
            }">
              <!-- 有封面图 或 视频样式 -->
              <template v-if="item.articles[0].video || item.articles[0].image">
                <div class="data-cover-box" :style="{
                backgroundColor: item.page_cover?'':'#F3F5F7',
                borderRadius: myConfig.templateData.borderRadius + 'px'
              }">
                  <!-- 视频 -->
                  <template v-if="item.articles[0].video">
                    <el-image class="data-cover" v-if="item.articles[0].video.thumbnail"
                      :src="fixSpecialImageUrl(item.articles[0].video.thumbnail, '', index, 'article', 'video')" fit="cover"></el-image>
                    <img class="default-icon video-play" src="../../assets/images/video-play.png" />
                  </template>
                  <!-- 封面 -->
                  <template v-else-if="item.articles[0].image">
                    <el-image class="data-cover" v-if="item.articles[0].image.url"
                      :src="fixSpecialImageUrl(item.articles[0].image.url, '', index, 'article', 'image')" fit="cover"></el-image>
                  </template>
                  <div class="data-tag blue" :style="{
                    borderRadius: `0 0 0 ${myConfig.templateData.borderRadius}px`
                  }">资讯</div>
                </div>
                <div class="data-right">
                  <div class="data-title">{{item.articles[0].title}}</div>
                  <div class="right-bottom">
                    <div class="bottom-item">{{item.articles[0].source_name}}</div>
                    <div class="bottom-item">{{item.articles[0].post_time}}</div>
                  </div>
                </div>
              </template>
              <!-- 无封面图视频样式 -->
              <template v-else>
                <div class="data-name">{{item.articles[0].title}}</div>
              </template>
            </div>
            <!-- 活动数据 -->
            <div class="list-data" v-if="item.activity.length" :style="{
              borderRadius: myConfig.templateData.borderRadius + 'px'
            }">
              <div class="data-cover-box" :style="{
                backgroundColor: item.page_cover?'':'#F3F5F7',
                borderRadius: myConfig.templateData.borderRadius + 'px'
              }">
                <el-image class="data-cover"
                  :src="fixSpecialImageUrl(item.activity[0].cover, '', index, 'activity', 'cover')"
                          :fit="item.activity[0].cover ? 'cover' : 'contain'" />
                <div class="data-tag yellow" :style="{
                  borderRadius: `0 0 0 ${myConfig.templateData.borderRadius}px`
                }">活动</div>
              </div>
              <div class="data-right">
                <div class="data-title">{{item.activity[0].name}}</div>
                <div class="right-bottom">
                  <div class="bottom-item">{{item.activity[0].activity_start_time}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="load-more" v-if="myConfig.loadingConfig.loadingType === 'handle'">
        <div :class="[myConfig.loadingConfig.handleType]">
          {{myConfig.loadingConfig.handleText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { brandList } from "../../api/components";
import StBrandItemBig from "@/modules/app-design/components/Preview/StBrandItemBig";
import StAvatars from "@/modules/app-design/components/Preview/Common/StAvatars";
import StGroundGlass from "@/modules/app-design/components/Preview/Common/StGroundGlass";
import componentMixin from '../../mixins/component-mixin'
import StLoadImage from "@/modules/app-design/components/Preview/Common/StLoadImage";

export default {
  name: "StBrandList",
  mixins: [componentMixin],
  components: {StLoadImage, StBrandItemBig, StAvatars, StGroundGlass },
  data() {
    return {
      loading: false,
      defaultList: require("../../assets/jsons/default-list.json").brandList,
      defaultAvatars: [
        require("../../assets/images/st-spirit-list/circle-avatar1.png"),
        require("../../assets/images/st-spirit-list/circle-avatar2.png"),
        require("../../assets/images/st-spirit-list/circle-avatar3.png"),
        require("../../assets/images/st-spirit-list/circle-avatar4.png"),
        require("../../assets/images/st-spirit-list/circle-avatar5.png"),
        require("../../assets/images/st-spirit-list/circle-avatar6.png"),
      ],
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          bgType: "color",
          bgColor: "#ffffff",
          bgImage: "",
          rowMargin: 0,
        },
        templateData: {
          styleType: "template1",
          dataSpacing: 15,
          borderRadius: 8,
        },
        dataConfig: {
          dataType: "select",
          dataArr: [
            {
              id: "",
              name: "",
            },
          ],
          dataAll: 1,
          dataFilter: [""],
          interactiveType: "horizontal"
        },
        loadingConfig: {
          loadingType: "none",
          limit: 10,
          handleType: "template1",
          handleText: "加载更多",
        },
        listTitle: {
          isShow: 1,
          showType: "template1",
          title: "专题栏目",
          fontWeight: "bold",
          fontSize: 18,
          titleColor: "#000000",
        },
        showConfig: {
          isShow: 1,
          showType: "template1",
          title: "查看更多",
        },
      },
      brand_list: [],
    };
  },
  watch: {
    "myConfig.templateData.styleType"(val) {
      this.getBrandList(false);
    },
    "myConfig.loadingConfig.limit"(val) {
      this.getBrandList(false);
    },
    "myConfig.dataConfig.dataType"(val) {
      this.getBrandList(false);
    },
    "myConfig.dataConfig.dataArr"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getBrandList(false);
      }
    },
    "myConfig.dataConfig.dataAll"(val) {
      this.getBrandList(false);
    },
    "myConfig.dataConfig.dataFilter"(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getBrandList(false);
      }
    },
  },
  computed: {
    // brandFilterList() {
    //   return this.brand_list.filter(
    //     (el, elIndex) =>
    //       elIndex <
    //       (this.myConfig.loadingConfig.limit
    //         ? this.myConfig.loadingConfig.limit
    //         : 5)
    //   );
    // },
    brandSwiperList() {
      return this.brand_list.length > 2
        ? [
            this.brand_list[this.brand_list.length - 1],
            this.brand_list[0],
            this.brand_list[1],
          ]
        : this.brand_list.length
        ? [this.brand_list[0], this.brand_list[1]]
        : [];
    },
  },
  created() {
    this.getBrandList();
  },
  methods: {
    getBrandList(notCancel) {
      let ids = [];
      this.myConfig.dataConfig.dataArr.forEach((el) => {
        ids.push(el.id);
      });
      let requestData = {
        page: 1,
        limit: this.myConfig.loadingConfig.limit,
        category_all: this.myConfig.dataConfig.dataAll,
        category_ids: this.myConfig.dataConfig.dataFilter,
        use_ids: this.myConfig.dataConfig.dataType === "select" ? 1 : 0,
        template: this.myConfig.templateData.styleType,
        ids,
      };
      this.loading = true;
      brandList(requestData, notCancel)
        .then((res) => {
          const { data } = res;
          this.brand_list = data.length ? data : this.defaultList;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/component";

  .brand-list {
  background-size: 100% auto;
  background-repeat: repeat-y;

  .swiper-view {
    display: flex;
    overflow: hidden;
    padding-left: 16px;
    min-height: 100px;

    &.template5 {
      display: flex;
      flex-direction: column;
      padding-right: 16px;
    }

    &.template1 {
      width: 100%;

      &.vertical {
        padding: 0 16px;
        overflow: visible;
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 12px;

        .list-item {
          height: unset;
          margin-bottom: 0;
          min-width: 0;

          .item-image {
            height: 93px;
          }
        }
      }

      .list-item {
        flex-shrink: 0;
        width: 100%;
        max-width: 192px;
        height: 100%;
        background-color: #fff;
        box-shadow: 0px 6px 10px 0px #f2f2f2;
        border-radius: 4px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;

        .item-image {
          position: relative;
          width: 100%;
          height: 108px;
          border-radius: 4px 4px 0 0;

          .default-icon {
            position: absolute;
            width: 28px;
            height: 28px;
            top: 50%;
            left: 50%;
            margin-top: -14px;
            margin-left: -14px;
            z-index: 2;
          }
        }

        .item-content {
          width: 100%;
          padding: 16px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;

          .item-title {
            font-size: 14px;
            font-weight: bold;
            color: #191e29;
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
          }

          .item-news {
            display: flex;
            align-items: center;
            margin-top: 12px;

            &+.item-news {
              margin-top: 8px;
            }

            .news-tip {
              margin-right: 4px;
              padding: 0 3px;
              flex-shrink: 0;
              font-size: 8px;
              color: #333c50;
              border-radius: 2px 2px 0px 2px;
              border: 1px solid #333c50;
            }
            .news-content {
              font-size: 12px;
              color: #333c50;
              line-height: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 100%;
            }
          }

          .item-collect {
            display: flex;
            align-items: center;
            margin-top: 12px;

            .collect-avatar {
              display: flex;
              position: relative;
              // width: 74px;
              margin-right: 8px;

              .avatar-item {
                background-color: #ccc;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid #ffffff;
              }
            }

            .collect-content {
              font-size: 12px;
              color: #b7bec8;
              line-height: 18px;
            }
          }
        }
      }
    }
    &.template2 {
      width: 100%;
      .list-item {
        flex-shrink: 0;
        width: 143px;
        // background-color: #fff;
        // box-shadow: 0px 6px 10px 0px #f2f2f2;
        border-radius: 4px;
        margin-bottom: 12px;

        .item-image {
          position: relative;
          width: 100%;
          height: 80px;
          border-radius: 4px;

          .default-icon {
            position: absolute;
            width: 28px;
            height: 28px;
            top: 50%;
            left: 50%;
            margin-top: -14px;
            margin-left: -14px;
            z-index: 2;
          }
        }

        .item-content {
          width: 100%;
          margin-top: 4px;

          .item-title {
            font-size: 12px;
            font-weight: bold;
            color: #191e29;
            line-height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    &.template3 {
      flex-direction: column;
      .list-item {
        display: flex;
        width: 100%;
        // background-color: #fff;
        border-radius: 4px;

        .item-image {
          flex-shrink: 0;
          position: relative;
          width: 143px;
          height: 80px;
          border-radius: 4px;

          .default-icon {
            position: absolute;
            width: 28px;
            height: 28px;
            top: 50%;
            left: 50%;
            margin-top: -14px;
            margin-left: -14px;
            z-index: 2;
          }
        }

        .item-content {
          margin-left: 10px;
          .item-title {
            font-size: 16px;
            font-weight: bold;
            color: #191e29;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .item-desc {
            margin-top: 8px;
            font-size: 12px;
            color: #666666;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &.template4 {
      min-height: 100px;
      .banner-swiper {
        display: flex;
        // height: 188px;
        width: 100%;
      }

      .banner + .banner {
        margin-left: 10px;
      }

      .banner {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .banner-content {
          box-sizing: border-box;
          position: absolute;
          // padding: 16px;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          z-index: 1;

          .content-tag {
            margin: 16px 16px 0;
            margin-right: auto;
            display: flex;
            align-items: center;
            padding: 6px 8px 6px 6px;
            height: 28px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 14px;
            .tag-img {
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              margin-right: 4px;
            }
            .tag-content {
              font-size: 14px;
              color: #ffffff;
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .content-mask {
            width: 100%;
            position: absolute;
            bottom: 0;
            padding: 0px 16px 16px;
            // background: rgba(0, 0, 0, 0.3);
            // filter: blur(8px);
          }
          .content-title {
            padding-top: 7px;
            font-size: 20px;
            font-weight: bold;
            color: #ffffff;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .item-collect {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 14px;
            .collect-avatar {
              width: 74px;
              display: flex;
              position: relative;
              // width: 74px;
              margin-right: 8px;

              .avatar-item {
                background-color: #ccc;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid #ffffff;
              }
            }
            .show-detail {
              padding: 7px 14px;
              height: 28px;
              background: #ffffff;
              border-radius: 17px;
              font-size: 14px;
              color: var(--main-color);
              line-height: 14px;
            }
          }
        }

        .banner-image-box {
          width: 100%;
          position: relative;
          height: 188px;
          .banner-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          .default-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 28px;
            height: 28px;
            z-index: 2;
          }
        }

        .banner-name {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          line-height: 21px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }

      .swiper-two {
        .swiper-item {
          position: relative;
          width: 100%;
          height: 140px;
          overflow: hidden;
          .item-content {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 140px;
          }
        }
        .advert-img {
          width: 100%;
          height: 100%;
        }
        .title-cover {
          position: absolute;
          width: 100%;
          height: 64px;
          padding: 20px 17px 10px;
          bottom: 0;
          background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          line-height: 21px;
        }
      }
    }

    &.template6 {
      flex-direction: column;
      padding: 0 16px;
      .list-item {
        background-color: #fff;
        position: relative;
        width: 100%;
        overflow: hidden;
        &.style1 {
          &::before {
            content: "";
            display: block;
            padding-top: 65%;
          }
          .item-cover {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.9) 100%
              );
            }
          }
        }
        &.style2 {
          .item-cover-box {
            // &::before {
            //   content: "";
            //   position: absolute;
            //   top: -10px;
            //   right: -10px;
            //   bottom: -10px;
            //   left: -10px;
            //   background: inherit;
            //   filter: blur(3px);
            //   backdrop-filter: blur(3px);
            // }
            // &::after {
            //   content: "";
            //   position: absolute;
            //   top: 0;
            //   right: 0;
            //   bottom: 0;
            //   left: 0;
            //   background-color: rgba(53, 53, 53, 0.3);
            // }
          }
        }
        .item-cover-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          .item-cover {
            width: 100%;
            height: 100%;
          }
        }
        .main-content1 {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 12px;
          .item-name {
            @include nowrap();
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 1.5;
          }
          .item-desc {
            @include clamp(3);
            margin-top: 8px;
            font-size: 12px;
            color: #ffffff;
            line-height: 1.5;
          }
        }
        .main-content2 {
          position: relative;
          padding: 12px;
          .item-name {
            @include nowrap();
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 1.5;
          }
          .content-list {
            margin-top: 8px;
            .list-data + .list-data {
              margin-top: 8px;
            }
            .list-data {
              padding: 8px;
              display: flex;
              width: 100%;
              background-color: #fff;

              .data-cover-box {
                width: 139px;
                height: 78px;
                flex-shrink: 0;
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .data-cover {
                  width: 100%;
                  height: 100%;
                }
                .default-icon {
                  width: 32px;
                  height: 32px;

                  &.video-play {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
                .data-tag {
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 40px;
                  height: 18px;
                  text-align: center;
                  font-size: 12px;
                  color: #ffffff;
                  line-height: 1.5;

                  &.yellow {
                    background-color: #ffa41b;
                  }
                  &.blue {
                    background-color: #1b76ff;
                  }
                }
              }
              .data-right {
                margin-left: 8px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .data-title {
                  @include clamp(2);
                  font-size: 14px;
                  color: #1a1a1a;
                  line-height: 1.5;
                }

                .right-bottom {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .bottom-item {
                    font-size: 12px;
                    color: #b3b3b3;
                    line-height: 1.5;
                  }
                }
              }
              .data-name {
                @include clamp(2);
                font-size: 14px;
                color: #1a1a1a;
                line-height: 1.5;
                &::before {
                  content: "资讯";
                  display: inline-block;
                  margin-right: 4px;
                  width: 40px;
                  height: 18px;
                  text-align: center;
                  background: #1b76ff;
                  border-radius: 6px;
                  font-size: 12px;
                  color: #ffffff;
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
