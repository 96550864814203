import { render, staticRenderFns } from "./StElectronCard.vue?vue&type=template&id=3b11113f&scoped=true&"
import script from "./StElectronCard.vue?vue&type=script&lang=js&"
export * from "./StElectronCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b11113f",
  null
  
)

export default component.exports