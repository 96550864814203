<template>
  <div class="water-mark">
    <div class="mark-item" v-for="(item,index) in showList" :key="index">
      <el-image class="mark-icon" v-if="item.type === 'icon'" :src="item.icon" fit="contain"></el-image>
      <p class="mark-content" v-if="item.type === 'content'">{{item.content}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StBottomWatermark",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        tabs: {
          list: [
            {
              icon: "",
              type: "content",
              status: 0,
              content: "",
              is_show: 1,
              jump_link: require("@/modules/app-design/assets/jsons/jump-link.json"),
            },
          ],
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    showList() {
      return this.myConfig.tabs.list.filter((item) => item.is_show === 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.mark-item {
  width: 100%;
  height: 20px;
  /*background-color: #f3f5f7;*/
  display: flex;
  justify-content: center;
  align-items: center;
  .mark-icon {
    height: 100%;
  }
  .mark-content {
    font-size: 11px;
    color: #999999;
    line-height: 11px;
  }
}
.mark-item + .mark-item {
  margin-top: 5px;
}
</style>