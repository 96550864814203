<!--
 * @Author: dongjia
 * @Date: 2021-08-25 10:59:26
 * @LastEditTime: 2021-08-30 10:00:02
 * @LastEditors: aleaner
 * @Description: 入会表单系统页
 * @FilePath: \app-design\components\Preview\StJoinOrganizeForm.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="ele-card">
    <div class="header">
      <template v-if="platform !== 'h5'">
        <img class="start-top-img" src="../../assets/images/phone-statusbar1@2x.png">
        <div class="start-top" style="text-align: end">
          <img class="header-jump" src="../../assets/images/middle/Back1@2x.png" />
          <img class="start-top-handler" src="../../assets/images/wechat-handle1@2x.png">
        </div>
      </template>
      <st-page-set v-else :title="myConfig.normal.title"/>
      <div class="type-select" :class="[platform === 'h5' ? 'simple' : '']">
        <div class="type-item">{{myConfig.normal.title}}
        </div>
      </div>
    </div>
    <!-- 申请表单页面缺省页 -->
    <div class="none-list">
      <div class="none-list-content">
        <img class="none-list-img" src="../../assets/images/custom-page-none.png" />
        <p class="none-list-tip">如需设置入会表单信息，请点击前往设置</p>
        <el-button style="margin-top: 27px" type="primary" @click="handleAddForm">
          前往设置</el-button>
      </div>
    </div>
    <div class="bottom-btn">
      <div class="btn-text">{{myConfig.normal.btnText}}</div>
    </div>
  </div>
</template>

<script>
import StPageSet from '../Preview/StPageSet'
export default {
  name: "StJoinOrganizeForm",
  components: {StPageSet},
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      platform: this.$route.params.platform,
      model: {
        normal: {
          title: "入会表单",
          btnText: "提交申请",
          interactiveType: "normal",
        },
      },
      // currentIndex: 0,
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
  methods: {
    handleAddForm() {
      let routerData = this.$router.resolve({
        name: "FormPage",
        params: { id: 0 },
      });
      window.open(routerData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.ele-card {
  width: 375px;
  min-height: 667px;
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;

  .header-nav {
    background-color: white;
  }

  .start-top-img {
    width: 375px;
    height: 20px;
  }
  .start-top {
    padding-right: 7px;
    .header-jump {
      position: absolute;
      top: 32px;
      left: 11px;
      width: 20px;
      height: 20px;
    }
  }
  .start-top-handler {
    width: 87px;
    height: 32px;
  }
  .header {
    position: absolute;
    top: 0;
    width: 100%;
    background: #ffffff;
    .type-select {
      position: absolute;
      top: 23px;
      width: 100%;
      padding: 0 18px;
      display: flex;
      justify-content: center;

      &.simple {
        position: static;
        display: none;
      }

      .type-item + .type-item {
        margin-left: 18px;
      }
      .type-item {
        display: flex;
        align-items: center;
        height: 34px;
        text-align: center;
        font-size: 18px;
        line-height: 34px;
        .active {
          border-bottom: 2px #fff solid;
          font-size: 18px;
          font-weight: bold;
          line-height: 34px;
        }
        .select-icon {
          width: 16px;
          height: 16px;
          margin-left: 2px;
        }
      }
    }
    .header-search {
      margin: 16px auto 26px;
      width: 335px;
      height: 32px;
      background: #f8fbff;
      border-radius: 18px;
      opacity: 0.89;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      .search-icon {
        color: #94989f;
        font-size: 14px;
      }
      .search-tip {
        font-size: 14px;
        color: #94989f;
        line-height: 14px;
        margin-left: 4px;
      }
    }
  }
  .tabs {
    padding-top: 134px;
    height: 100%;
    .tab-list {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 44px;
      box-shadow: 0px 1px 0px 0px #dddddd;
      .tab-item {
        font-size: 14px;
        color: #979da6;
        line-height: 19px;

        &.active {
          font-weight: bold;
          color: #191e29;
        }
      }
    }
    .content-list {
      padding: 10px 18px 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .list-item + .list-item {
        margin-top: 10px;
      }
      .list-item {
        width: 100%;
        border-radius: 4px;
        background-color: #fff;
        padding: 12px 12px 24px;
        .item-top {
          display: flex;
          .item-img {
            width: 72px;
            height: 72px;
            margin-right: 10px;
          }
          .item-desc {
            .item-name {
              width: 225px;
              height: 40px;
              @include clamp(2);
              font-size: 15px;
              font-weight: bold;
              color: #191e29;
              line-height: 20px;
            }
            .item-status {
              display: flex;
              margin-top: 8px;
              .status-icon + .status-icon {
                margin-left: 6px;
              }
              .status-icon {
                width: 44px;
                height: 16px;
              }
            }
          }
        }
        .item-middle {
          padding: 0 31px;
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .item-data {
            width: 100%;
            display: flex;
            align-items: center;
            .data-item + .data-item {
              border-left: 1px #e9e9e9 solid;
            }
            .data-item {
              padding-left: 10px;
              flex: 1;
              flex-shrink: 0;
              .data-top {
                font-size: 13px;
                color: #000000;
                line-height: 11px;
              }
              .data-bottom {
                margin-top: 8px;
                font-size: 11px;
                color: #979da6;
                line-height: 11px;
              }
            }
          }
        }
        .item-btn {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 21px;
          .btn-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 72px;
            height: 28px;
            background: var(--main-color);
            border-radius: 16px;
            font-size: 12px;
            color: #ffffff;
            line-height: 12px;
          }
        }
      }
    }
  }
}

.bottom-btn {
  position: absolute;
  width: 375px;
  padding: 13px 18px;
  bottom: 0px;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(238, 238, 238, 0.5);
  .btn-text {
    width: 100%;
    line-height: 44px;
    background: var(--main-color);
    border-radius: 4px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
}
/* 自定义页面缺省页样式 */
.none-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .none-list-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .none-list-img {
      width: 188px;
      height: 201px;
    }

    .none-list-tip {
      margin-top: 22px;
      font-size: 14px;
      color: #7f7f7f;
      line-height: 20px;
    }
  }
}
</style>
