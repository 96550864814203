var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-middle",style:({
      '--main-color': _vm.mainColor,
      '--help-color': _vm.helpColor,
      '--text-color': _vm.textColor,
    })},[(_vm.isSystemActive)?[_c('div',{staticClass:"workspace-middle-box"},[_c('div',{staticClass:"workspace-middle-page no-hover",staticStyle:{"background-color":"#fff"}},[(_vm.page.props && _vm.page.props.header && _vm.value !== 'share')?_c('div',{class:['drag-item no-hover'],style:({
              height: _vm.page.props.header.height + 64 + 'px',
              position: 'absolute',
            })},[(
                _vm.setting.headerJump &&
                _vm.setting.headerJump.props &&
                _vm.setting.headerJump.props.showConfig
              )?_c('div',{staticClass:"header-fixed",style:({ height: '64px', backgroundColor: 'white' })},[_c('div',{class:[
                  _vm.setting.headerJump.props.showConfig.showType !== 'template1'
                    ? 'template-back-icon'
                    : 'default-back-icon' ]},[_c('div',{staticClass:"back-icon"}),(
                    _vm.setting.headerJump.props.showConfig.showType ===
                      'template3' && _vm.value === 'headerJump'
                  )?_c('div',{staticClass:"link-dialog1"},_vm._l((_vm.setting.headerJump.props.tabs.list
                      .length
                      ? _vm.headerJumpList
                      : require('../../assets/jsons/default-list.json')
                          .headerJump),function(item,index){return _c('div',{key:index,staticClass:"link-item1"},[_c('el-image',{staticClass:"link-image1",attrs:{"src":item.icon
                          ? _vm.fixImageUrl(item.icon, {}, true)
                          : require('../../assets/images/default-icon.png'),"fit":"cover"}}),_c('p',{staticClass:"link-name1"},[_vm._v(" "+_vm._s(item.name ? item.name : ("导航" + (_vm.ch_sort[index])))+" ")])],1)}),0):_vm._e(),(
                    _vm.setting.headerJump.props.showConfig.showType ===
                      'template4' && _vm.value === 'headerJump'
                  )?_c('div',{staticClass:"link-dialog2"},_vm._l((_vm.setting.headerJump.props.tabs.list
                      .length
                      ? _vm.headerJumpList
                      : require('../../assets/jsons/default-list.json')
                          .headerJump),function(item,index){return _c('div',{key:index,staticClass:"link-item2"},[_c('el-image',{staticClass:"link-image2",attrs:{"src":item.icon
                          ? _vm.fixImageUrl(item.icon, {}, true)
                          : require('../../assets/images/default-icon.png'),"fit":"cover"}}),_c('p',{staticClass:"link-name2"},[_vm._v(" "+_vm._s(item.name ? item.name : ("导航" + (_vm.ch_sort[index])))+" ")])],1)}),0):_vm._e()])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"workspace-middle-page-content"},[(
                !_vm.fullscreen &&
                _vm.value !== 'theme' &&
                _vm.value !== 'share' &&
                _vm.value !== 'eleCard'
              )?_c('div',{class:['drag-item no-hover']},[_c('st-page-set',{attrs:{"title":_vm.setting[_vm.value].title || _vm.setting[_vm.value].name,"jump-config":_vm.setting && _vm.setting.headerJump ? _vm.setting.headerJump.props : {}}})],1):_vm._e(),_c('draggable',{staticClass:"draggable-box",class:[
                _vm.value === 'bottomNav' || _vm.value === 'headerJump' ? 'system' : '' ],style:(_vm.value === 'share' ||
                _vm.value === 'posterShare' ||
                _vm.value === 'theme'
                  ? 'padding-bottom: 0px !important'
                  : ''),attrs:{"draggable":".drag-item","animation":"400","ghostClass":"ghost","options":_vm.listOptions,"move":_vm.onMove,"filter":".no-drag"},on:{"add":_vm.handleAdd},model:{value:(_vm.myList),callback:function ($$v) {_vm.myList=$$v},expression:"myList"}}),(_vm.value === 'theme')?_c('div',{staticClass:"drag-item no-hover theme"},[_c('st-theme',{attrs:{"config":_vm.setting[_vm.value].props}})],1):_vm._e(),(_vm.value === 'share')?_c('div',{staticClass:"drag-item no-hover share"},[_c('st-share',{attrs:{"config":_vm.setting[_vm.value].props}})],1):_vm._e(),(_vm.value === 'bottomWatermark')?_c('div',{staticClass:"drag-item no-hover bottomWatermark"},[_c('st-bottom-watermark',{attrs:{"config":_vm.setting[_vm.value].props}})],1):_vm._e(),(_vm.value === 'posterShare')?_c('div',{staticClass:"drag-item no-hover"},[_c('st-poster-share',{attrs:{"config":_vm.setting[_vm.value].props}})],1):_vm._e(),(_vm.value === 'eleCard')?_c('div',{staticClass:"drag-item no-hover"},[_c('st-electron-card',{attrs:{"config":_vm.setting[_vm.value].props}})],1):_vm._e(),(_vm.value === 'levels')?_c('div',{staticClass:"drag-item no-hover"},[_c('st-electron-card',{attrs:{"config":_vm.setting[_vm.value].props}})],1):(_vm.value === 'internet_content_provider')?_c('div',{staticClass:"drag-item no-hover"},[_c('st-internet-content-provider',{attrs:{"config":_vm.setting[_vm.value].props}})],1):_vm._e()],1),(
              ['internet_content_provider'].includes(_vm.value) ||
              _vm.value === 'bottomNav'
            )?_c('div',{staticClass:"drag-item fixed no-hover",style:({
              outline:
                (['internet_content_provider'].includes(_vm.value) ||
                  _vm.value === 'bottomNav') &&
                _vm.system['bottomNav'].props.templateData.styleType === 'template1'
                  ? ''
                  : 'none',
            })},[_c('st-bottom-nav',{attrs:{"config":_vm.system['bottomNav'].props}})],1):_vm._e()])])]:(_vm.pageDetail && _vm.pageDetail.id)?[_c('div',{staticClass:"workspace-middle-box",on:{"click":function($event){return _vm.updateSelected('page')}}},[_c('div',{staticClass:"workspace-middle-page",style:({
            transform: ("scale(" + _vm.ratio + ")"),
            backgroundImage:
              (_vm.topBannerCurrent && ("url(" + _vm.topBannerCurrent + ")")) ||
              _vm.page.props.normal.bgType === 'image'
                ? ("url(" + (_vm.page.props.normal.bgImage) + "),linear-gradient(to bottom, " + (_vm.page.props.normal.bgType === 'color'
                      ? _vm.page.props.normal.bgColor
                      : 'transparent') + ", " + (_vm.page.props.normal.bgType === 'color'
                      ? _vm.page.props.normal.bgColor
                      : 'transparent') + "), linear-gradient(to bottom, #f8f8f8, #f8f8f8)")
                : ("linear-gradient(to bottom, " + (_vm.page.props.normal.bgType === 'color'
                      ? _vm.page.props.normal.bgColor
                      : 'transparent') + ", " + (_vm.page.props.normal.bgType === 'color'
                      ? _vm.page.props.normal.bgColor
                      : 'transparent') + "), linear-gradient(to bottom, #f8f8f8, #f8f8f8)"),
            backgroundRepeat:
              _vm.topBannerCurrent || _vm.page.props.normal.bgType === 'image'
                ? 'no-repeat'
                : '',
            backgroundSize: _vm.topBannerCurrent ? '100%' : '',
          }),attrs:{"id":"capture"}},[(_vm.page.props && _vm.page.props.header)?_c('div',{staticClass:"page-top",class:['drag-item no-hover'],staticStyle:{"position":"absolute"},style:({
              height: _vm.page.props.header.height + (_vm.isH5 ? 0 : 64) + 'px',
              top: _vm.isH5 ? '64px' : 0,
            })},[_c('div',{staticClass:"header-fixed",style:({
                height: 'inherit',
                overflow: 'hidden',
              })},[_c('div',{staticClass:"page-bg",style:({
                  height: 'inherit',
                  backgroundColor:
                    _vm.page.props.header.bgType === 'image'
                      ? ''
                      : _vm.page.props.header.bgColor || '',
                  borderRadius:
                    _vm.page.props.header.radian > 0 ? '0 0 50% 50%' : '0',
                  width:
                    _vm.page.props.header.radian > 0
                      ? 200 - _vm.page.props.header.radian * 1 + '%'
                      : '100%',
                  marginLeft:
                    _vm.page.props.header.radian > 0
                      ? -(200 - _vm.page.props.header.radian * 1) / 2 + '%'
                      : '-50%',
                  opacity: 1 - _vm.page.props.header.opacity / 100,
                })},[(_vm.page.props.header.bgType === 'image')?_c('el-image',{style:({
                    height: 'fit-content' || 'inherit',
                  }),attrs:{"fit":"contain","src":_vm.page.props.header.bgImage}}):_vm._e()],1)])]):_vm._e(),(
              _vm.hasCustomHeader &&
              _vm.list[0] &&
              _vm.list[0].props &&
              _vm.list[0].props.templateData &&
              _vm.list[0].props.templateData.template === 'template7'
            )?_c('div',{staticClass:"header-page-top",staticStyle:{"width":"100%","top":"0","position":"absolute","background-size":"100% auto","background-position":"center top","background-repeat":"no-repeat"},style:({
              height: ((20 +
                44 +
                _vm.list[0].props.contentConfigTemplate7.coverHeight +
                167 +
                _vm.list[0].props.contentConfigTemplate7.colorHeight +
                105) + "px"),
              // 写在最后面的在最底层
              backgroundImage: ("\n        linear-gradient(to bottom, " + (_vm.list[0].props.contentConfigTemplate7.color) + "00 " + (((20 + 44 + _vm.list[0].props.contentConfigTemplate7.coverHeight) *
                  100) /
                (20 +
                  44 +
                  _vm.list[0].props.contentConfigTemplate7.coverHeight +
                  167 +
                  _vm.list[0].props.contentConfigTemplate7.colorHeight +
                  105)) + "%, " + (_vm.list[0].props.contentConfigTemplate7.color) + " " + (((20 +
                  44 +
                  _vm.list[0].props.contentConfigTemplate7.coverHeight +
                  167) *
                  100) /
                (20 +
                  44 +
                  _vm.list[0].props.contentConfigTemplate7.coverHeight +
                  167 +
                  _vm.list[0].props.contentConfigTemplate7.colorHeight +
                  105)) + "%, " + (_vm.list[0].props.contentConfigTemplate7.color) + " 82.87%, " + (_vm.list[0].props.contentConfigTemplate7.color) + "00 100%),\n        url(" + (_vm.list[0].props.contentConfigTemplate7.cover) + ")"),
            })}):_vm._e(),_c('div',{staticClass:"workspace-middle-page-content",style:({
              paddingBottom: _vm.isTabPage
                ? (((_vm.system['bottomNav'].props.templateData.styleType ===
                    'template1'
                      ? 50
                      : 0) + _vm.page.props.normal.paddingBottom) + "px")
                : _vm.page.props.normal.paddingBottom + 'px',
            })},[(!_vm.fullscreen && _vm.page && (!_vm.hasCustomHeader || _vm.isH5))?_c('div',{staticClass:"header",class:['drag-item no-hover']},[_c('st-page-set',{attrs:{"config":_vm.page.props,"jump-config":_vm.setting && _vm.setting.headerJump ? _vm.setting.headerJump.props : {}}})],1):_vm._e(),(
                _vm.page.props.serviceBtn &&
                _vm.page.props.serviceBtn.isShow &&
                _vm.page.props.serviceBtn.payStatus
              )?_c('div',{staticClass:"aside-fixed",class:[_vm.page.props.serviceBtn.showPosition],style:({
                bottom:
                  _vm.hasCommunityNav &&
                  _vm.communityNavConfig.props.issueConfig.issuePost
                    ? '243px'
                    : _vm.page.props.serviceBtn.fixBottom + 'px',
              })},[_c('el-image',{staticClass:"aside-icon",attrs:{"src":require('../../assets/images/middle/customer-service.png')}})],1):_vm._e(),(
                _vm.isFeatureConsult &&
                _vm.page.props.consultBtn &&
                _vm.page.props.consultBtn.isShow &&
                _vm.page.props.consultBtn.payStatus
              )?_c('div',{staticClass:"aside-fixed consult",class:[_vm.page.props.consultBtn.showPosition],style:({
                bottom:
                  _vm.hasCommunityNav &&
                  _vm.communityNavConfig.props.issueConfig.issuePost
                    ? '243px'
                    : _vm.page.props.consultBtn.fixBottom + 'px',
              })},[_c('el-image',{staticClass:"aside-icon",attrs:{"src":require('../../assets/images/middle/customer-service-icon.png')}})],1):_vm._e(),(
                _vm.joinFloat &&
                _vm.page.props.requestJoin.template === 'template2' &&
                _vm.page.props.requestJoin.isShow === 1
              )?_c('div',{staticStyle:{"width":"100%","position":"absolute","bottom":"0","padding":"8px","min-height":"48px","background":"#fff","z-index":"3","border-radius":"16px 16px 0 0","padding-top":"8px","padding-bottom":"12px","display":"flex","align-items":"center","box-shadow":"0px -12px 32px rgba(0, 0, 0, 0.08)"},style:({
                bottom: _vm.hasBottomNavComponent
                  ? '50px'
                  : _vm.isTabPage
                  ? '50px'
                  : '0px',
              })},[_c('div',{staticStyle:{"margin-left":"8px","margin-right":"12px"}},[_c('img',{staticStyle:{"width":"52px","height":"52px"},attrs:{"src":_vm.peopleData.icon}})]),_c('div',{staticStyle:{"flex":"1","color":"#1a1a1a","font-size":"14px","text-align":"center"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"24px","margin-right":"8px"},attrs:{"src":_vm.peopleData.project_icon}}),_c('div',{staticStyle:{"text-align":"left","color":"#1a1a1a","font-size":"16px","font-weight":"600","line-height":"32px"}},[_vm._v(" "+_vm._s(_vm.peopleData.auth_applet_name)+" ")])]),(_vm.peopleData.count !== 0)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._l((_vm.peopleData.list.slice(0, 3)),function(user){return _c('div',{staticStyle:{"background-size":"100% 100%","width":"18px","height":"18px","border-radius":"24px","border":"1px solid #fff","margin-right":"-6px"},style:({
                      backgroundImage: ("url(" + (user.avatar || _vm.peopleData.default_avatar) + ")"),
                    })})}),_c('span',{staticStyle:{"text-indent":"12px","color":"#808080","font-size":"12px"}},[_vm._v("已有 "+_vm._s(_vm.peopleData.count)+" 人入会")])],2):_vm._e()]),_c('div',{staticStyle:{"margin-left":"8px","height":"100%","color":"#fff","padding":"12px","padding-top":"6px","padding-bottom":"6px","border-radius":"32px","margin-right":"8px","font-size":"14px"},style:({
                  background:
                    _vm.page.props.requestJoin.bgType === 'theme'
                      ? 'var(--main-color)'
                      : _vm.page.props.requestJoin.bgColor,
                })},[_vm._v(" 申请入会 ")])]):_vm._e(),(
                _vm.joinFloat &&
                _vm.page.props.requestJoin.template === 'template1' &&
                _vm.page.props.requestJoin.isShow === 1
              )?_c('div',{staticStyle:{"width":"calc(100% - 42px)","left":"21px","min-height":"48px","position":"absolute","background":"#fff","z-index":"4","border-radius":"32px","bottom":"0","margin-top":"16px","margin-bottom":"16px","box-shadow":"1px 1px 16px rgba(0, 0, 0, 0.08)","display":"flex","align-items":"center"},style:({
                bottom: _vm.isTabPage ? '50px' : '0px',
                bottom: _vm.hasBottomNavComponent
                  ? '50px'
                  : _vm.isTabPage
                  ? '50px'
                  : '0px',
              })},[_vm._m(0),_c('div',{staticStyle:{"flex":"1","color":"#1a1a1a","font-size":"14px","text-align":"center","display":"flex","align-items":"center"}},[(_vm.peopleData.count > 0)?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-right":"20px"}},_vm._l((_vm.peopleData.list.slice(0, 3)),function(user){return _c('div',{staticStyle:{"background-size":"100% 100%","width":"24px","height":"24px","border-radius":"24px","border":"1px solid #fff","margin-right":"-12px"},style:({
                      backgroundImage: ("url(" + (user.avatar || _vm.peopleData.default_avatar) + ")"),
                    })})}),0):_vm._e(),(_vm.peopleData.count > 0)?_c('div',[_vm._v(" 已有 "+_vm._s(_vm.peopleData.count)+" 人入会 ")]):_vm._e(),(_vm.peopleData.count === 0)?_c('img',{staticStyle:{"width":"24px","margin-right":"8px"},attrs:{"src":_vm.peopleData.project_icon}}):_vm._e(),(_vm.peopleData.count === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.peopleData.auth_applet_name)+" ")]):_vm._e()]),_c('div',{staticStyle:{"margin-left":"8px","height":"100%","color":"#fff","padding":"12px","padding-top":"6px","padding-bottom":"6px","border-radius":"32px","margin-right":"8px","font-size":"14px","margin-top":"12px","margin-bottom":"12px"},style:({
                  background:
                    _vm.page.props.requestJoin.bgType === 'theme'
                      ? 'var(--main-color)'
                      : _vm.page.props.requestJoin.bgColor,
                })},[_vm._v(" 申请入会 ")])]):_vm._e(),(_vm.myList.length === 0)?_c('div',{staticClass:"none-list"},[_c('div',{staticClass:"none-list-content"},[_c('img',{staticClass:"none-list-img",attrs:{"src":require("../../assets/images/custom-page-none.png")}}),_c('p',{staticClass:"none-list-tip"},[_vm._v(" 添加组件，设计您的专属"+_vm._s(_vm.isH5 ? 'H5 微网站' : '小程序')+" ")]),_c('el-button',{staticStyle:{"margin-top":"27px"},attrs:{"type":"primary"},on:{"click":_vm.handleAddComp}},[_vm._v(" 添加组件")])],1)]):_vm._e(),(_vm.hasCustomHeader)?_c('div',{staticClass:"drag-item no-drag",class:_vm.value === 0 ? 'active' : '',attrs:{"id":_vm.myList[0].extra.timestamp},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected(0)}}},[_c("st-custom-header",{tag:"component",attrs:{"config":_vm.myList[0].props,"version":_vm.myList[0].version,"page":_vm.page}}),_c('page-middle-item-tool',{attrs:{"title":_vm.myList[0].title},on:{"delete":function($event){return _vm.deletePageItem(0)}}})],1):_vm._e(),_c('draggable',{staticClass:"draggable-box",style:({
                paddingBottom: _vm.isH5 ? 0 : '2px',
                minHeight:
                  !_vm.hasCustomNav && !_vm.hasCustomHeader && !_vm.hasCommunityNav
                    ? _vm.isH5
                      ? '603px'
                      : '553px'
                    : _vm.hasCustomHeader && !_vm.hasCustomNav && !_vm.hasCommunityNav
                    ? '491px'
                    : '1px',
              }),attrs:{"filter":".forbid","animation":"400","ghostClass":"ghost","options":_vm.listOptions,"move":_vm.onMove,"disabled":!_vm.pageDetail.id},on:{"sort":_vm.handleSort,"add":_vm.handleAdd},model:{value:(_vm.myList),callback:function ($$v) {_vm.myList=$$v},expression:"myList"}},_vm._l((_vm.myList),function(li,i){return (
                  li &&
                  li.component &&
                  ![
                    'st-custom-header',
                    'st-custom-nav',
                    'st-community-nav',
                    'st-membership-dynamics',
                    'st-community-square',
                    'st-community-nav-old',
                    'st-article-comment-tab' ].includes(li.component)
                )?_c('div',{key:li.extra.timestamp,class:[
                  'drag-item',
                  li.extra.version_different
                    ? 'is-different'
                    : _vm.value === i
                    ? 'active'
                    : '',
                  li.component,
                  _vm.havePageLoadingComponent.includes(li.component) &&
                  li.props.loadingConfig.loadingType === 'page'
                    ? 'forbid'
                    : '' ],attrs:{"id":li.extra.timestamp},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected(i)}}},[_c(li.component,{ref:"dynamicCom",refInFor:true,tag:"component",attrs:{"config":li.props,"version":li.version,"index":i,"is-active":i === _vm.value,"jump-config":_vm.setting && _vm.setting.headerJump
                      ? _vm.setting.headerJump.props
                      : {}}},[(false && ['st-bottom-nav'].includes(li.component))?_c('div',{staticClass:"none-list"},[_c('div',{staticClass:"none-list-content"},[_c('img',{staticClass:"none-list-img",attrs:{"src":require("../../assets/images/custom-page-none.png")}}),(li.component === 'st-bottom-nav')?[_c('p',{staticClass:"none-list-tip"},[_vm._v(" 该组件为底部导航设置，无法再添加其他组件 ")])]:_vm._e()],2)]):_vm._e()]),(li.extra.version_different)?_c('div',{staticClass:"different-version-tip"},[_vm._v(" 组件版本已更新，请留意设置内容 ")]):_vm._e(),_c('page-middle-item-tool',{attrs:{"title":li.title},on:{"delete":function($event){return _vm.deletePageItem(i)}}})],1):_vm._e()}),0),(_vm.joinFloat && _vm.page.props.requestJoin.isShow === 1)?_c('div',{style:({
                height: _vm.hasBottomNavComponent || _vm.hasCustomNav
                  ? '0px'
                  : _vm.page.props.requestJoin.template === 'template1'
                  ? '96px'
                  : '96px',
              })}):_vm._e(),(_vm.hasCustomNav || _vm.hasCommunityNav)?_c('div',{staticClass:"drag-item no-drag",class:_vm.value === _vm.myList.length - 1 ? 'active' : '',style:({
                width: '100%',
              }),attrs:{"id":_vm.myList[_vm.myList.length - 1].extra.timestamp},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected(_vm.myList.length - 1)}}},[_c(_vm.myList[_vm.myList.length - 1].component,{tag:"component",attrs:{"config":_vm.myList[_vm.myList.length - 1].props}}),_c('page-middle-item-tool',{attrs:{"title":_vm.myList[_vm.myList.length - 1].title},on:{"delete":function($event){return _vm.deletePageItem(_vm.myList.length - 1)}}})],1):_vm._e(),(_vm.myList.length > 0)?_c('div',{staticClass:"flex-center",staticStyle:{"min-height":"100px"}},[_c('st-bottom-watermark',{attrs:{"config":_vm.setting.bottomWatermark.props}})],1):_vm._e(),(_vm.isTabPage)?_c('div',{class:[
                'drag-item no-hover no-drag fixed',
                _vm.value === 'bottomNav' ? 'active' : '' ],style:({
                outline:
                  _vm.system['bottomNav'].props.templateData.styleType ===
                  'template1'
                    ? ''
                    : 'none',
              }),on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected('bottomNav')}}},[_c('div',{staticStyle:{"position":"relative","z-index":"3"}},[_c('st-bottom-nav',{attrs:{"config":_vm.system['bottomNav'].props,"value":_vm.bottomActive}}),(
                    _vm.system['bottomNav'].props.templateData.styleType ===
                    'template1'
                  )?_c('page-middle-item-tool',{attrs:{"hide-delete":true,"title":_vm.system['bottomNav'].title}}):_vm._e()],1)]):_vm._e()],1)])]),(_vm.pageDetail.id)?_c('div',{staticClass:"workspace-middle-buttons"},[(_vm.pageDetail.id)?_c('el-button',{attrs:{"type":_vm.value === 'page' ? 'primary' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected('page')}}},[_c('span',{staticClass:"middle-button-content"},[(_vm.value === 'page')?_c('img',{attrs:{"src":require("../../assets/images/middle/page-setting-white.png")}}):_c('img',{attrs:{"src":require("../../assets/images/middle/page-setting.png")}}),_c('span',{staticClass:"button-text"},[_vm._v("页面设置")])])]):_vm._e(),(_vm.pageDetail && !_vm.hasTabPage && !_vm.hasBottomNavComponent)?_c('el-button',{attrs:{"type":_vm.value === 'bottomNav' ? 'primary' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.addBottomNav.apply(null, arguments)}}},[_c('span',{staticClass:"middle-button-content"},[(_vm.value === 'bottomNav')?_c('img',{attrs:{"src":require("../../assets/images/middle/add-nav-white.png")}}):_c('img',{attrs:{"src":require("../../assets/images/middle/add-nav.png")}}),_c('span',{staticClass:"button-text"},[_vm._v("添加导航")])])]):_vm._e(),_c('el-button-group',{staticClass:"scale-control"},[_c('el-button',{attrs:{"icon":"el-icon-minus","type":""},on:{"click":function($event){_vm.ratio -= 0.05}}}),_c('el-button',{attrs:{"icon":"el-icon-c-scale-to-original","type":""},on:{"click":function($event){_vm.ratio = 1}}}),_c('el-button',{attrs:{"icon":"el-icon-plus","type":""},on:{"click":function($event){_vm.ratio += 0.05}}})],1)],1):_vm._e()]:(_vm.pageDetail && _vm.pageDetail.system_page)?[_c('div',{staticClass:"workspace-middle-box",on:{"click":function($event){return _vm.updateSelected()}}},[_c('div',{staticClass:"workspace-middle-page system-page",class:[_vm.pageDetail.system_page],style:({
            transform: ("scale(" + _vm.ratio + ")"),
            backgroundImage:
              _vm.systemConfig &&
              _vm.systemConfig.props &&
              _vm.systemConfig.props.normal &&
              _vm.systemConfig.props.normal.bgType === 'image' &&
              !['contactUs'].includes(_vm.pageDetail.system_page)
                ? ("url(" + (_vm.systemConfig.props.normal.bgImage) + ")")
                : '',
            backgroundColor:
              (_vm.systemConfig &&
                _vm.systemConfig.props &&
                (_vm.systemConfig.props.normal &&
                _vm.systemConfig.props.normal.bgType === 'color'
                  ? _vm.systemConfig.props.normal.bgColor
                  : '')) ||
              (['me_new', 'memberIndex', 'contactUs'].includes(
                _vm.pageDetail.system_page
              )
                ? '#F4F5F6'
                : '#fff'),
          })},[(
              _vm.systemConfig && _vm.systemConfig.props && _vm.systemConfig.props.header
            )?_c('div',{staticClass:"header-box",class:['drag-item no-hover'],staticStyle:{"position":"absolute"},style:({
              height:
                _vm.systemConfig.props.header.height +
                (_vm.isH5
                  ? ['st-analysis-page', 'st-analysis-page-for-user'].includes(
                      _vm.systemConfig.component
                    )
                    ? 0
                    : 44
                  : 64) +
                'px',
              top: _vm.isH5 ? '64px' : 0,
            })},[_c('div',{staticClass:"header-fixed",style:({
                height: '100%',
                overflow: 'hidden',
              })},[_c('div',{staticClass:"page-bg",style:({
                  height:
                    ['analysis', 'analysis_user'].includes(
                      _vm.pageDetail.system_page
                    ) &&
                    _vm.systemConfig &&
                    _vm.systemConfig.props &&
                    _vm.systemConfig.props.normal &&
                    _vm.systemConfig.props.normal.bgType === 'image' &&
                    _vm.systemConfig.props.normal.bgImage
                      ? 0
                      : _vm.systemConfig.props.header.height + 64 + 'px',
                  backgroundColor:
                    _vm.systemConfig.props.header.bgType === 'image'
                      ? ''
                      : _vm.systemConfig.props.header.bgColor || '',
                  borderRadius:
                    _vm.systemConfig.props.header.radian > 0 ? '0 0 50% 50%' : '0',
                  width:
                    _vm.systemConfig.props.header.radian > 0
                      ? 200 - _vm.systemConfig.props.header.radian * 1 + '%'
                      : '100%',
                  marginLeft:
                    _vm.systemConfig.props.header.radian > 0
                      ? -(200 - _vm.systemConfig.props.header.radian * 1) / 2 + '%'
                      : '-50%',
                  opacity: 1 - _vm.systemConfig.props.header.opacity / 100,
                })},[(_vm.systemConfig.props.header.bgType === 'image')?_c('el-image',{style:({
                    height: _vm.systemConfig.props.header.height + 64 + 'px',
                  }),attrs:{"fit":"cover","src":_vm.systemConfig.props.header.bgImage}}):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"workspace-middle-page-content",style:({
              backgroundColor: _vm.systemPageBgColor,
              paddingBottom:
                _vm.systemConfig &&
                _vm.systemConfig.props &&
                _vm.systemConfig.props.normal &&
                _vm.systemConfig.props.normal.paddingBottom !== undefined
                  ? ((_vm.getPaddingBottomHeight(
                      _vm.systemConfig.props.normal.paddingBottom
                    )) + "px")
                  : ((_vm.getPaddingBottomHeight(0)) + "px"),
            })},[(_vm.pageDetail.system_page === 'me_new' && !_vm.hasBottomNavComponent)?_c('div',{staticClass:"me-bg-content",style:({
                height:
                  (_vm.isH5 ? 135 : 135) + _vm.systemConfig.props.header.height + 'px',
                marginTop: _vm.isH5 ? '64px' : 0,
              })},[_c('div',{staticClass:"me-bg",style:({
                  backgroundColor:
                    _vm.systemConfig.props.header.bgType === 'color'
                      ? _vm.systemConfig.props.header.bgColor
                      : '',
                  borderRadius:
                    _vm.systemConfig.props.header.radian > 0 ? '0 0 50% 50%' : '0',
                  width:
                    _vm.systemConfig.props.header.radian > 0
                      ? 300 - _vm.systemConfig.props.header.radian * 2 + '%'
                      : '100%',
                  marginLeft:
                    _vm.systemConfig.props.header.radian > 0
                      ? -(300 - _vm.systemConfig.props.header.radian * 2) / 2 + '%'
                      : '-50%',
                  opacity: 1 - _vm.systemConfig.props.header.opacity / 100,
                })},[(_vm.systemConfig.props.header.bgType === 'image')?_c('div',{staticClass:"me-bg-image-box",style:({
                    backgroundImage: ("url(" + (_vm.systemConfig.props.header.bgImage ||
                      _vm.baseUrl + '/cyc/images/wxapps/images/thumb_up.png') + ")"),
                  })}):_vm._e(),(
                    false &&
                    _vm.systemConfig.props.messageConfig.styleType === 'template3'
                  )?_c('div',{staticClass:"template3-bg flex",staticStyle:{"position":"absolute","width":"375px"},style:({
                    top: !_vm.isH5
                      ? ((_vm.systemConfig.props.header.height + 135 - 67) + "px")
                      : ((_vm.systemConfig.props.header.height + 135 - 67) + "px"),
                  })},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/st-me-page/template3-bg.png")}})]):_vm._e()])]):_vm._e(),(_vm.pageDetail.config.page && _vm.systemConfig)?[_c(_vm.systemConfig.component,{tag:"component",attrs:{"component-name":_vm.systemConfig.component,"config":_vm.systemConfig.props,"jump-config":_vm.setting && _vm.setting.headerJump ? _vm.setting.headerJump.props : {},"headerJump":_vm.setting && _vm.setting.headerJump ? _vm.setting.headerJump.props : {},"bottomWatermark":_vm.setting.bottomWatermark.props,"index":0,"firstCompConfig":_vm.myList[0] ? _vm.myList[0].props : {}}}),(
                  _vm.systemConfig.props.serviceBtn &&
                  _vm.systemConfig.props.serviceBtn.isShow &&
                  _vm.systemConfig.props.serviceBtn.payStatus
                )?[_c('div',{staticClass:"aside-fixed",class:[_vm.systemConfig.props.serviceBtn.showPosition],style:({
                    bottom:
                      _vm.hasCommunityNav &&
                      _vm.communityNavConfig.props.issueConfig.issuePost
                        ? '243px'
                        : _vm.systemConfig.props.serviceBtn.fixBottom + 'px',
                  })},[_c('el-image',{staticClass:"aside-icon",attrs:{"src":require('../../assets/images/middle/customer-service.png')}})],1)]:_vm._e(),(
                  _vm.isFeatureConsult &&
                  _vm.systemConfig.props.consultBtn &&
                  _vm.systemConfig.props.consultBtn.isShow &&
                  _vm.systemConfig.props.consultBtn.payStatus
                )?[_c('div',{staticClass:"aside-fixed consult",class:[_vm.systemConfig.props.consultBtn.showPosition],style:({
                    bottom:
                      _vm.hasCommunityNav &&
                      _vm.communityNavConfig.props.issueConfig.issuePost
                        ? '243px'
                        : _vm.systemConfig.props.consultBtn.fixBottom + 'px',
                  })},[_c('el-image',{staticClass:"aside-icon",attrs:{"src":require('../../assets/images/middle/customer-service-icon.png')}})],1)]:_vm._e()]:_vm._e(),(_vm.pageDetail.system_page && _vm.myList && _vm.myList.length)?[(_vm.hasCustomHeader)?_c('div',{class:['drag-item', 'no-drag', _vm.value === 0 ? 'active' : ''],attrs:{"id":_vm.myList[0].extra.timestamp},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected(0)}}},[_c("st-custom-header",{tag:"component",attrs:{"config":_vm.myList[0].props,"page":_vm.page}}),_c('page-middle-item-tool',{attrs:{"title":_vm.myList[0].title},on:{"delete":function($event){return _vm.deletePageItem(0)}}})],1):_vm._e(),(!['splash'].includes(_vm.pageDetail.system_page))?_c('draggable',{staticClass:"draggable-box",style:({
                  minHeight:
                    (['analysis', 'analysis_user'].includes(
                      _vm.pageDetail.system_page
                    )
                      ? 'calc(667px - 60px - 64px - 12px - 8px)'
                      : '') ||
                    (['activity_detail', 'analysis'].includes(
                      _vm.pageDetail.system_page
                    ) && _vm.myList.length === 0
                      ? '200px'
                      : ''),
                }),attrs:{"filter":".forbid","animation":"400","ghostClass":"ghost","options":_vm.listOptions,"move":_vm.onMove},on:{"sort":_vm.handleSort,"add":_vm.handleAdd},model:{value:(_vm.myList),callback:function ($$v) {_vm.myList=$$v},expression:"myList"}},_vm._l((_vm.myList),function(li,i){return (
                    li &&
                    li.component &&
                    ![
                      'st-custom-header',
                      'st-custom-nav',
                      'st-community-nav',
                      'st-community-square',
                      'st-article-comment-tab' ].includes(li.component)
                  )?_c('div',{key:li.extra.timestamp,class:[
                    'drag-item',
                    li.extra.version_different
                      ? 'is-different'
                      : _vm.value === i
                      ? 'active'
                      : '',
                    [
                      'st-member-message',
                      'st-new-trends',
                      'st-honor-certificate',
                      'st-about-me',
                      'st-broadcast',
                      'st-related-company' ].includes(li.component)
                      ? 'st-member-index'
                      : li.component,
                    li.is_system ? 'forbid' : '' ],attrs:{"data-last":li.props.normal && li.props.normal.marginTop
                      ? li.props.normal.marginTop > 0
                      : false,"id":li.extra.timestamp},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected(i)}}},[_c(li.component,{tag:"component",attrs:{"component":li.component,"config":li.props,"index":i,"jump-config":_vm.setting && _vm.setting.headerJump
                        ? _vm.setting.headerJump.props
                        : {},"page":_vm.systemConfig.props,"rules-arr":_vm.rulesArr}}),(li.extra.version_different)?_c('div',{staticClass:"different-version-tip"},[_vm._v(" 组件版本已更新，请留意设置内容 ")]):_vm._e(),_c('page-middle-item-tool',{attrs:{"hide-delete":!!li.is_system,"title":li.title},on:{"delete":function($event){return _vm.deletePageItem(i)}}})],1):_vm._e()}),0):_vm._e(),(
                  ['contactUs', 'activity_detail'].includes(
                    _vm.pageDetail.system_page
                  ) || _vm.pageDetail.system_page === 'contactUs'
                )?_c('st-bottom-watermark',{staticStyle:{"margin-top":"40px"},attrs:{"config":_vm.setting.bottomWatermark.props}}):_vm._e(),(['activity_detail'].includes(_vm.pageDetail.system_page))?_c('div',{staticClass:"activity-bottom"},[_c('img',{staticClass:"activity-bottom-img",attrs:{"src":require('../../assets/images/st-activity-detail/bottom-bar.png')}})]):_vm._e(),(_vm.articleCommentTab)?_c('div',{class:[
                  'drag-item no-drag',
                  'article-commemt',
                  _vm.value === _vm.myList.length - 1 ? 'active' : '' ],style:({
                  backgroundColor: _vm.articleCommentTab.props.normal.bgColor,
                  flexDirection:
                    _vm.articleCommentTab.props.templateData.styleType ===
                    'template1'
                      ? 'row'
                      : 'row-reverse',
                }),attrs:{"id":_vm.articleCommentTab.extra.timestamp},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected(_vm.myList.length - 1)}}},[_c('page-middle-item-tool',{attrs:{"hide-delete":true,"title":_vm.articleCommentTab.title}}),_c('div',{staticClass:"handle-left",style:({
                    color:
                      _vm.articleCommentTab.props.templateData.placeholderColor,
                    backgroundColor:
                      _vm.articleCommentTab.props.templateData.searchColor,
                    borderRadius:
                      _vm.articleCommentTab.props.templateData.searchType ===
                      'circle'
                        ? '15px'
                        : '0',
                    justifyContent:
                      _vm.articleCommentTab.props.templateData.textPosition ===
                      'center'
                        ? 'center'
                        : '',
                  })},[_c('div',{staticClass:"left-content"},[_c('p',{staticClass:"el-icon-edit input-icon"}),_c('p',{staticClass:"input-tips"},[_vm._v(" "+_vm._s(_vm.articleCommentTab.props.templateData.placeholder)+" ")])])]),_c('div',{staticClass:"handle-right"},[_c('div',{staticClass:"handle-item"},[_c('img',{staticClass:"handle-icon",attrs:{"src":require("../../assets/images/st-article-detail/liked.png")}}),_c('p',{staticClass:"handle-name"},[_vm._v(" "+_vm._s(_vm.articleCommentTab.props.templateData.btnType === 'text' ? '点赞' : '999')+" ")])]),_c('div',{staticClass:"handle-item"},[_c('img',{staticClass:"handle-icon",attrs:{"src":require("../../assets/images/st-article-detail/star.png")}}),_c('p',{staticClass:"handle-name"},[_vm._v(" "+_vm._s(_vm.articleCommentTab.props.templateData.btnType === 'text' ? '收藏' : '999')+" ")])]),_c('div',{staticClass:"handle-item"},[_c('img',{staticClass:"handle-icon",attrs:{"src":require("../../assets/images/st-article-detail/forward.png")}}),_c('p',{staticClass:"handle-name"},[_vm._v(" "+_vm._s(_vm.articleCommentTab.props.templateData.btnType === 'text' ? '转发' : '999')+" ")])])])],1):_vm._e(),(_vm.pageDetail.system_page === 'ask' && _vm.systemConfig)?_c('div',{staticClass:"submit-btn",style:({
                  boxShadow:
                    _vm.systemConfig.props.buttonConfig.interactiveType === 'fixed'
                      ? "0px -2px 4px 0px rgba(238, 238, 238, 0.5)"
                      : '',
                })},[_c('div',{staticClass:"submit-text"},[_vm._v(" "+_vm._s(_vm.systemConfig.props.buttonConfig.btnText)+" ")])]):_vm._e(),(
                  _vm.pageDetail.system_page === 'shop_index' ||
                  _vm.pageDetail.system_page === 'integral_shop_index'
                )?_c('div',{staticClass:"cart-ball"},[_c('ColorCustomIcon',{attrs:{"width":24,"height":24,"icon":require('../../assets/images/st-shop-goods/button-cart.png')}})],1):_vm._e(),(
                  _vm.pageDetail.system_page === 'memberIndex' &&
                  !_vm.hiddenMemberIndexBtn
                )?_c('div',{staticClass:"member-index-bottom"},[(_vm.systemConfig.props.shareConfig.showShare)?_c('div',{staticClass:"bottom-left"},[(_vm.systemConfig.props.shareConfig.showShare)?_c('div',{staticClass:"btn-item"},[_c('img',{staticClass:"item-icon",attrs:{"src":require("../../assets/images/st-member-index/share-icon2.png")}}),_c('div',{staticClass:"item-text"},[_vm._v("分享")])]):_vm._e()]):_vm._e(),(_vm.systemConfig.props.otherConfig.isShowFollow)?_c('div',{staticClass:"bottom-right",style:({
                    marginLeft: !_vm.systemConfig.props.shareConfig.showShare
                      ? '0'
                      : '34px',
                  })},[_vm._v(" +关注 ")]):_vm._e(),(_vm.systemConfig.props.otherConfig.isShowPrivateChat)?_c('div',{staticClass:"bottom-right",style:({
                    marginLeft: !(
                      _vm.systemConfig.props.shareConfig.showShare ||
                      _vm.systemConfig.props.otherConfig.isShowFollow
                    )
                      ? '0'
                      : '34px',
                  })},[_vm._v(" 私聊 ")]):_vm._e()]):_vm._e(),(
                  _vm.pageDetail.system_page === 'memberIndex' &&
                  _vm.hiddenMemberIndexBtn &&
                  _vm.systemConfig.props.shareConfig.showShare
                )?_c('div',{staticClass:"member-index-bottom-fixed"},[(_vm.systemConfig.props.shareConfig.showShare)?_c('img',{staticClass:"item-icon",attrs:{"src":require("../../assets/images/st-member-index/share-icon2.png")}}):_vm._e()]):_vm._e()]:_vm._e(),(_vm.pageDetail.system_page === 'me_new')?_c('div',{staticClass:"me-page-bottom"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"bottom-watermark"},_vm._l((_vm.bottomWatermarkList),function(item,index){return _c('div',{key:index,staticClass:"mark-item"},[(item.type === 'icon')?_c('el-image',{staticClass:"mark-icon",attrs:{"src":item.icon,"fit":"contain"}}):_vm._e(),(item.type === 'content')?_c('p',{staticClass:"mark-content"},[_vm._v(" "+_vm._s(item.content)+" ")]):_vm._e()],1)}),0)]):_vm._e(),(!_vm.pageDetail.config.list && !_vm.pageDetail.config.page)?_c('div',{staticClass:"flex-center",staticStyle:{"height":"613px"}},[_vm._v(" 页面开发中… ")]):_vm._e(),(_vm.isTabPage)?_c('div',{class:[
                'drag-item fixed no-drag no-hover',
                _vm.value === 'bottomNav' ? 'active' : '' ],staticStyle:{"position":"absolute","bottom":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected('bottomNav')}}},[_c('div',{staticStyle:{"position":"relative","z-index":"1"}},[_c('st-bottom-nav',{attrs:{"config":_vm.system['bottomNav'].props,"value":_vm.bottomActive}}),(
                    _vm.system['bottomNav'].props.templateData.styleType ===
                    'template1'
                  )?_c('page-middle-item-tool',{attrs:{"hide-delete":true,"title":_vm.system['bottomNav'].title}}):_vm._e()],1)]):_vm._e()],2)])]),(_vm.pageDetail.config.page && _vm.pageDetail.config.list)?_c('div',{staticClass:"workspace-middle-buttons"},[(_vm.pageDetail.system_page)?_c('el-button',{attrs:{"type":_vm.value ===
            (_vm.systemDict[_vm.pageDetail.system_page] || _vm.pageDetail.system_page)
              ? 'primary'
              : ''},on:{"click":function($event){$event.stopPropagation();return _vm.updateSelected(
              _vm.systemDict[_vm.pageDetail.system_page] || _vm.pageDetail.system_page
            )}}},[_c('span',{staticClass:"middle-button-content"},[(
                _vm.value ===
                (_vm.systemDict[_vm.pageDetail.system_page] || _vm.pageDetail.system_page)
              )?_c('img',{attrs:{"src":require("../../assets/images/middle/page-setting-white.png")}}):_c('img',{attrs:{"src":require("../../assets/images/middle/page-setting.png")}}),_vm._v(" 页面设置")])]):_vm._e(),(_vm.isDev)?_c('el-button',{attrs:{"icon":"el-icon-document-copy"},on:{"click":function($event){$event.stopPropagation();return _vm.copyJSONConfig()}}},[_vm._v("复制JSON")]):_vm._e(),_c('el-button-group',{staticClass:"scale-control"},[_c('el-button',{attrs:{"type":"","icon":"el-icon-minus"},on:{"click":function($event){_vm.ratio -= 0.05}}}),_c('el-button',{attrs:{"type":"","icon":"el-icon-c-scale-to-original"},on:{"click":function($event){_vm.ratio = 1}}}),_c('el-button',{attrs:{"type":"","icon":"el-icon-plus"},on:{"click":function($event){_vm.ratio += 0.05}}})],1)],1):_vm._e()]:[_c('div',{staticClass:"workspace-middle-box",staticStyle:{"padding-top":"20vh"}},[_c('el-empty',{attrs:{"description":"页面不存在"}})],1)],(_vm.pageDetail && (_vm.pageDetail.system_page || _vm.pageDetail.id))?_c('div',{staticClass:"workspace-middle-undo-manage"},[_c('el-tooltip',{staticClass:"item",attrs:{"content":"撤销","effect":"dark","placement":"top"}},[_c('img',{attrs:{"disabled":!_vm.undoManagerUI.canUndo,"src":require("../../assets/images/middle/undo.png")},on:{"click":function($event){$event.stopPropagation();return _vm.undo.apply(null, arguments)}}})]),_c('el-tooltip',{staticClass:"item",attrs:{"content":"恢复","effect":"dark","placement":"top"}},[_c('img',{attrs:{"disabled":!_vm.undoManagerUI.canRedo,"src":require("../../assets/images/middle/redo.png")},on:{"click":function($event){$event.stopPropagation();return _vm.redo.apply(null, arguments)}}})])],1):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-left":"8px","margin-right":"8px"}},[_c('img',{staticStyle:{"width":"38px","height":"38px"},attrs:{"src":require("../../assets/images/vip.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-outlog"},[_c('p',[_vm._v("清除缓存")]),_c('img',{staticClass:"handle-icon",attrs:{"src":require("../../assets/images/st-me-page/handle-next.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-outlog"},[_c('p',[_vm._v("退出账号")]),_c('img',{staticClass:"handle-icon",attrs:{"src":require("../../assets/images/st-me-page/handle-next.png")}})])}]

export { render, staticRenderFns }