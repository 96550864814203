import { render, staticRenderFns } from "./StPostList.vue?vue&type=template&id=6772d872&scoped=true&"
import script from "./StPostList.vue?vue&type=script&lang=js&"
export * from "./StPostList.vue?vue&type=script&lang=js&"
import style0 from "./StPostList.vue?vue&type=style&index=0&id=6772d872&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6772d872",
  null
  
)

export default component.exports