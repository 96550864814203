<!--
 * @Author: dongjia
 * @Date: 2021-06-18 11:27:10
 * @LastEditTime: 2021-06-18 13:55:11
 * @LastEditors: aleaner
 * @Description: 文章详情文章评论组件
 * @FilePath: \app-design\components\Preview\StArticleComment.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="comment-area" :style="{
      backgroundColor: myConfig.normal.bgColor,
      marginTop: myConfig.normal.marginTop + 'px',
    }">
    <div class="comment-top">
      <p class="top-left">热门评论 (999)</p>
      <p class="top-right"><span :style="{
        marginRight: '5px',
        color: myConfig.normal.sortType === 'popular'?'#000000':'#aaaaaa'
      }">按热度</span>|<span :style="{
        marginLeft: '5px',
        color: myConfig.normal.sortType === 'time'?'#000000':'#aaaaaa'
      }">按时间</span></p>
    </div>
    <div class="comment-content">暂无评论内容</div>
  </div>
</template>

<script>
export default {
  name: "StArticleComment",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          isShow: 0,
          marginTop: 0,
          bgColor: "",
          limit: 0,
          sortType: "",
          twoLevelComment: 0,
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-area {
  width: 100%;
  height: 212px;
  padding: 12px 20px;
  .comment-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-left {
      font-weight: bold;
    }
    .top-right {
      font-size: 12px;
    }
  }
  .comment-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 24px);
    width: 100%;
    color: #333333;
  }
}
</style>
