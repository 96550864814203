<!--
 * @Author: dongjia
 * @Date: 2021-10-09 17:47:54
 * @LastEditTime: 2021-10-09 18:10:05
 * @LastEditors: aleaner
 * @Description: 关于我组件信息排序表单
 * @FilePath: \app-design\components\Form\StAboutMeDragList.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="el-form-item drag-list st-about-me-drag-list">
    <div>
      <p class="list-title">信息排序</p>
      <p class="form-tip">鼠标拖拽调整顺序</p>
    </div>
    <draggable
      v-if="dataList"
      v-model="dataList"
      class="nav-list"
      animation="500"
      draggable=".nav-item"
    >
      <template v-if="dataList.length > 0">
        <div class="nav-item" v-for="(data, index) in dataList" :key="index">
          <!-- <div class="data-name">{{data.name}}</div> -->
          <el-input class="data-name" v-model="data.name"></el-input>
          <div class="data-btn">
            <el-checkbox v-model="data.is_show" :true-label="0" :false-label="1"
              >隐藏</el-checkbox
            >
            <el-button  size="small" type="primary" @click="restore(index)"
              >还原</el-button
            >
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'StAboutMeDragList',
  components: {
    draggable,
  },
  model: {
    prop: 'list',
    event: 'listChange',
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataSelectDialog: false,
      unDraggable: false,
      visible: false,
    }
  },
  computed: {
    dataList: {
      get() {
        return this.list
      },
      set(val) {
        this.$emit('listChange', val)
      },
    },
    jumpType() {
      return this.formType === 'st-bottom-nav'
        ? this.current_jumptype
        : this.jump_type
    },
  },
  watch: {
    dataList: {
      handler(val) {
        this.$emit('listChange', val)
      },
      deep: true,
    },
  },
  methods: {
    // 还原标题
    restore(index) {
      let defaultName = ''
      switch (index) {
        case 0:
          defaultName = '自我介绍'
          break
        case 1:
          defaultName = '喜爱书籍'
          break
        case 2:
          defaultName = '喜爱照片'
          break
        case 3:
          defaultName = '喜爱视频'
          break
      }
      this.dataList[index].name = defaultName
    },
  },
}
</script>

<style lang="scss" scoped>
.list-title {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
}
.form-tip {
  font-size: 12px;
  color: #c0c4cc;
  padding-top: 8px;
  padding-bottom: 11px;
}
.nav-list {
  background-color: #f6f9ff;
  margin: 0 -19px 0 -24px;
  padding: 12px;
  margin-bottom: 20px;
}
.nav-item-add {
  width: 100%;
  text-align: center;
}
.nav-item {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .data-name {
    width: 224px;
    font-size: 14px;
    color: #3a3a3a;
    line-height: 1.3;
    @include nowrap();
  }
  .data-btn {
    display: flex;
    align-items: center;
    margin-left: 8px;
    .el-button {
      margin-left: 12px;
      // padding: 0;
    }
  }
}

.nav-item + .nav-item {
  margin-top: 12px;
}
</style>
