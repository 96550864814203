<template>
  <div
    :style="{
      margin: '0 20px',
      backgroundColor: '#fff',
      overflow: 'hidden',
    }"
  >
    <div
      class="custom-nav"
      :style="{
        marginTop: myConfig.normal.marginTop + 'px !important',
        marginLeft: `${myConfig.normal.rowMargin}px`,
        marginRight: `${myConfig.normal.rowMargin}px`,
        backgroundColor: myConfig.normal.bgColor,
        borderRadius: `${myConfig.normal.topRadius}px ${myConfig.normal.topRadius}px 0 0`,
      }"
    >
      <div
        class="nav-list"
        :style="{
          paddingTop: myConfig.normal.navPaddingTop + 'px',
          marginLeft: `${myConfig.normal.navRowMargin}px`,
          marginRight: `${myConfig.normal.navRowMargin}px`,
        }"
      >
        <div
          class="nav-item"
          v-for="(item, index) in myConfig.tabs.list.length
            ? tabList
            : defaultList"
          :key="index"
        >
          <el-image
            class="nav-image"
            v-if="item.leftIcon"
            :src="item.leftIcon"
            fit="cover"
          ></el-image>
          <div
            :class="['nav-name', index === currentIndex ? 'active' : '']"
            :style="{
              color:
                index === currentIndex
                  ? myConfig.normal.selectedColor
                  : myConfig.normal.defaultColor,
            }"
          >
            {{ item.name ? item.name : `导航${ch_sort[index]}` }}
            <div class="nav-active" v-if="index === currentIndex"></div>
          </div>
          <el-image
            class="nav-image"
            v-if="item.rightIcon"
            :src="item.rightIcon"
            fit="cover"
          ></el-image>
        </div>
      </div>
      <div
        class="tip-area"
        :style="{
          backgroundColor: myConfig.normal.contentBgColor,
        }"
      >
        <div>
          <p>会员动态导航内容区域</p>
          <p>在右侧调整页面顺序</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "stCustomNav",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultList: require("../../assets/jsons/default-list.json").StCustomNav,
      ch_sort: require("../../assets/jsons/ch-sort.json"),
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginTop: 0,
          navType: "",
          interactiveType: "",
          defaultColor: "",
          selectedColor: "",
          bgColor: "",
          contentBgColor: "",
          rowMargin: 0,
          topRadius: 0,
          navRowMargin: 20,
          navPaddingTop: 0,
        },
        tabs: {
          list: [
            {  
              name: "帖子",
              is_show: 1,
              key: "topic",
            },
            {
              name: "课程",
              is_show: 1,
              key: "course",
            },
            {
              name: "活动",
              is_show: 1,
              key: "activity",
            },
            {
              name: "供求",
              is_show: 1,
              key: "resource",
            },
          ],
        },
      },
      currentIndex: 0,
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    tabList() {
      return this.myConfig.tabs.list.filter((el) => el.is_show === 1);
    },
  },
  mounted() {
    console.log(this.myConfig);
  },
};
</script>

<style lang="scss" scoped>
.custom-nav {
  position: relative;

  .nav-list {
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-around;
    overflow-x: scroll;
    overflow: hidden;
  }

  .nav-item {
    margin-left: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 12px;

    .nav-name {
      font-size: 14px;
      line-height: 42px;
      position: relative;
      &.active {
        font-size: 18px;
        font-weight: bold;
      }
      .nav-active {
        position: absolute;
        height: 3px;
        /*width: calc(100% - 12px);*/
        width: 32px;
        bottom: 0;
        left: 50%;
        /*margin-left: calc((100% - 10px) / -2);*/
        margin-left: -16px;
        background-color: var(--main-color);
      }
    }
  }

  .tip-area {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
