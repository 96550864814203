var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity-component"},[(_vm.component === 'st-activity-description')?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/introduction.png')}}):(_vm.component === 'st-activity-notices')?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/notices.png')}}):(_vm.component === 'st-activity-addresses')?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/addresses.png')}}):(_vm.component === 'st-activity-join')?[(_vm.activityStatus === 0)?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/join.png')}}):(
        _vm.activityStatus === 1 &&
        _vm.myConfig.messageConfig.progressBar === 0 &&
        _vm.myConfig.messageConfig.joinedMembers === 0
      )?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/join-number.png')}}):(
        _vm.activityStatus === 1 &&
        _vm.myConfig.messageConfig.progressBar === 1 &&
        _vm.myConfig.messageConfig.joinedMembers === 0
      )?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/join-number.png')}}):_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/报名情况.png')}})]:(_vm.component === 'st-activity-guests')?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/guests.png')}}):(_vm.component === 'st-activity-units')?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/hosts.png')}}):(_vm.component === 'st-activity-timeline')?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":(_vm.baseImgUrl + "/app_design/st-activity-detail/timeline.png")}}):(_vm.component === 'st-activity-posts')?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":(_vm.baseImgUrl + "/app_design/st-activity-detail/posts.png")}}):(_vm.component === 'st-activity-plans')?_c('img',{staticClass:"card",attrs:{"draggable":"false","src":require('../../assets/images/st-activity-detail/plans.png')}}):(_vm.component === 'st-activity-relatives')?_c('div',{staticClass:"bg-card",style:({
      backgroundImage: ("url(\n        '" + _vm.baseImgUrl + "/app_design/assets/images/st-activity-detail/relatives.png'\n      )"),
    })}):_vm._e(),(
      _vm.component === 'st-activity-join' &&
      _vm.rulesArr &&
      ((_vm.activityStatus === 0 && _vm.myConfig.showConfig.joinBtn === 0) ||
        (_vm.activityStatus === 1 && _vm.myConfig.messageConfig.progressBar === 0))
    )?_c('div',{staticStyle:{"position":"absolute","top":"0","right":"16px","background-color":"white","width":"calc(21px + 75px + 90px)","height":"calc(12px + 25px + 3px)"}}):_vm._e(),(
      _vm.activityStatus === 1 &&
      _vm.myConfig.messageConfig.progressBar === 1 &&
      _vm.myConfig.messageConfig.joinedMembers === 0
    )?_c('img',{staticStyle:{"position":"absolute","top":"0","left":"16px","width":"calc(100% - 16px * 2 + 1px)"},attrs:{"src":require('../../assets/images/st-activity-detail/显示进度.png')}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }