<!--
 * @Author: dongjia
 * @Date: 2021-07-21 14:36:19
 * @LastEditTime: 2022-01-27 10:20:44
 * @LastEditors: aleaner
 * @Description: 自由容器组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Preview\StFreeContainer.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="free-container" :style="{
    height: myConfig.normal.height + 'px',
    marginTop: `${myConfig.normal.marginTop}px`
  }">
    <div class="comp-bg" v-if="myConfig.normal.bgConfig === 'custom'" :style="{
      backgroundColor: myConfig.normal.bgColor,
      opacity: (100 - myConfig.normal.opacity)/100,
      backgroundImage: myConfig.normal.styleType === 'image'?`url(${myConfig.normal.bgImg}) !important`: '',
      ...bgShowType,
      borderRadius: borderRadiusString,
      margin: `0 ${myConfig.normal.rowMargin}px`,
      width: `calc(100% - ${2 * myConfig.normal.rowMargin}px)`,
      boxShadow: `${myConfig.shadow.horizontal}px ${myConfig.shadow.vertical}px ${myConfig.shadow.distance}px ${myConfig.shadow.size}px #ccc`,
    }">
    </div>
    <!-- :preventActiveBehavior="item.isPreventBehavior"-->
    <draggable class="comp" v-if="componentData" v-model="componentData.props.components" animation="400"
      ghostClass="ghost-free-container" :options="listOptions" @add="handleAdd" :sort="false">
      <!-- 原来的 width: 100%;height:100%; 或许导致有时候点击容器空白区域无法 cancelActive 有关 -->
      <div class="comp-container" style="position: absolute; left: 0; top: 0;"
        v-for="(item,index) in componentData.props.components" :key="item.extra.timestamp">
        <!-- 编辑某个元素时，层级调到最高（仅编辑状态，未改变实际层级）
             zIndex: item.isActive ? '99' : item.props.normal.index, -->
        <vue-drag-resize class="comp-item" :parentW="375" :parentH="myConfig.normal.height" :style="{
          paddingRight: item.props.gov && item.props.gov.isEnableOthers ? '13px' : 0,
          backgroundImage: item.props.gov && item.props.gov.isEnableOthers ? `url(${require('../../assets/images/st-custom-header/dropdown-icon.png')})`: 'none'
        }" :parentLimitation="true" :isActive="item.isActive"
          :preventActiveBehavior="componentListConfig.maintain_select"
          :aspectRatio="item.props.normal.widthHeight.aspectRatio" :w="item.props.normal.widthHeight.width"
          :minh="1" :minw="1" :x="item.props.normal.left" :y="item.props.normal.top"
          :h="item.props.normal.widthHeight.height" :z="item.props.normal.index"
          @clicked="handleActive(item,index)" @deactivated="cancelActive(item,index)"
          @resizestop="(newRect) => change(item.props.normal,newRect)"
          @dragstop="(newRect) => change(item.props.normal,newRect)">
          <div class="comp-box" v-if="item.component === 'st-text' || item.component === 'st-link'" :style="{
            fontSize: item.props.normal.fontSize + 'px',
            fontWeight: item.props.normal.textConfig.fontWeight,
            color: item.props.normal.textConfig.fontColor,
            fontStyle: item.props.normal.textConfig.fontStyle,
            textDecoration: item.props.normal.textConfig.textDecoration,
            textAlign: item.props.normal.textConfig.textAlign,
            border: `${item.props.normal.borderConfig.borderSize}px ${item.props.normal.borderConfig.borderType} ${item.props.normal.borderConfig.borderColor}`,
            borderRadius: item.props.normal.borderRadius + 'px',
            backgroundColor: item.props.normal.bgColor,
          }">
            <el-input v-if="item.isShowInput" style="width: 100%;height: 100%" class="input-text"
              type="textarea" v-model="item.props.normal.text" @blur="item.isShowInput = false"
              ref="inputarea">
            </el-input>
            <p v-if="!item.isShowInput">
              {{item.props.normal.text || "请输入文本内容"}}
            </p>
          </div>
          <!--            <img v-if="item.props.gov && item.props.gov.isEnableOthers" src="../../assets/images/st-custom-header/dropdown-icon.png" fit="contain"-->
          <!--                 style="width: 7px; height: 3px; margin-left: 6px;" />-->
          　<div class="comp-box imgbox" v-if="item.component === 'st-image'" :style="{
            border: `${item.props.normal.borderConfig.borderSize}px ${item.props.normal.borderConfig.borderType} ${item.props.normal.borderConfig.borderColor}`,
            borderRadius: item.props.normal.borderRadius + 'px',
            backgroundColor: item.props.normal.bgColor,
          }">
            　<img v-if="item.props.normal.image" class="img" :src="fixSpecialImageUrl(item.props.normal.image, 500, index)"
              :style="{objectFit: item.props.normal.imgFit}" />
            <div v-else class="none-img">请上传图片</div>
          </div>
          <div class="comp-box btnbox" v-if="item.component === 'st-button'" :style="{
            fontSize: item.props.normal.fontSize + 'px',
            color: item.props.normal.fontColor,
            border: `${item.props.normal.borderConfig.borderSize}px ${item.props.normal.borderConfig.borderType} ${item.props.normal.btnColor}`,
            borderRadius: item.props.normal.borderRadius + 'px',
            backgroundColor: ['template1','template3'].includes(item.props.normal.btnStyle)? item.props.normal.btnColor: '#ffffff',
          }">
            　<span>{{item.props.normal.text || '按钮'}}</span>
          </div>
          <div class="comp-handle flex-col">
            <!-- <div class="handle-btn" @click.stop="handleInput(item,index)">
              <img class="btn-icon" src="../../assets/images/st-free-container/edit-icon.png" />
            </div> -->
            <div class="handle-btn" @click.stop="handleDel(item,index)">
              <img class="btn-icon" src="../../assets/images/st-free-container/delect-icon.png" />
            </div>
            <!-- 上移 -->
            <div class="handle-btn" @click.stop="handleChangeLayer(item.props.normal, 1)">
              <i class="btn-icon el-icon el-icon-top" />
            </div>
            <!-- 下移 -->
            <div class="handle-btn" @click.stop="handleChangeLayer(item.props.normal, 2)">
              <i class="btn-icon el-icon el-icon-bottom" />
            </div>
          </div>
        </vue-drag-resize>
      </div>
      <div class="none-tip-box">
        <div class="none-tip" v-if="componentData.props.components.length === 0">
          <p class="none-text">可拖动文本、图片、按钮、链接组件至自由容器</p>
          <el-button class="none-btn" v-show="myConfig.normal.height > 90" size="medium" type="primary"
            @click="handleAddComp">
            {{isShowNormalComponents?'添加组件':'取消添加组件'}}</el-button>
        </div>
      </div>
    </draggable>

    <img v-if="false" class="handle-icon" src="../../assets/images/st-free-container/handle-height-icon.png"
      @mousedown="changeHeight($event)" @mouseup="stopHeightChange($event)" />
    <media-selector v-model="showImageUpload" type="image" @select="handleMedia"></media-selector>
  </div>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import draggable from "vuedraggable";
import { mapState } from "vuex";
import MediaSelector from "@/modules/common/components/MediaSelector.vue";
import { set } from "nprogress";
import bus from "../../assets/js/bus.js";
import componentMixin from "@/modules/app-design/mixins/component-mixin";

export default {
  name: "StFreeContainer",
  mixins: [componentMixin],
  components: { VueDragResize, draggable, MediaSelector },
  props: {
    index: {
      type: Number,
      default: -1,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentData: null,
      model: {
        normal: {
          marginTop: 0,
          rowMargin: 0,
          height: 200,
          bgConfig: "none",
          styleType: "color",
          bgImg: "",
          imgFit: "origin",
          tileType: "default",
          imgPosition: "center",
          bgColor: "rgba(255, 255, 255, 0)",
          opacity: 0,
          borderRadiusConfig: {
            borderRadiusTopLeft: 0,
            borderRadiusTopRight: 0,
            borderRadiusBottomLeft: 0,
            borderRadiusBottomRight: 0,
          },
        },
        shadow: {
          horizontal: 0,
          vertical: 0,
          distance: 0,
          size: 0,
        },
        components: [""],
      },
      listOptions: {
        group: {
          name: "container-drag",
        },
        dragClass: "chosen",
      },
      isHandleActive: false,
      // isPreventBehavior: false,
      showImageUpload: false,
      stImageIndex: -1,
      isShowNormalComponents: true,
    };
  },
  watch: {
    // componentData: {
    //   deep: true,
    //   handler(val) {
    //     console.log("componentData", val);
    //   },
    // },
    "componentData.props.components": {
      deep: true,
      handler(val) {
        if (val.length > 0) {
          bus.$emit("hideComponents", true);
          this.isShowNormalComponents = true;
        }
      },
    },
    "componentListConfig.free_container_select"(val) {
      if (val === -1) {
        this.componentData.props.components.forEach((item) => {
          item.isActive = false;
        });
      }
    },
    isActive(val) {
      // console.log(val, 'isActive')
      if (!val) {
        if (this.componentListConfig.free_container_select === -1) {
          this.componentData.props.components.forEach((item) => {
            if (item.isActive) item.isActive = false;
          });
        } else {
          this.componentListConfig.free_container_select = -1;
        }
      }
    },
  },
  computed: {
    borderRadiusString() {
      let conf = this.myConfig.normal.borderRadiusConfig || {};
      return `${conf.borderRadiusTopLeft || 0}px ${
        conf.borderRadiusTopRight || 0
      }px ${conf.borderRadiusBottomRight || 0}px ${
        conf.borderRadiusBottomLeft || 0
      }px`;
    },
    isShowHandleHeight() {
      return this.$store.getters["appDesign/isFreeContainer"];
    },
    // 背景设置图片显示相关设置
    bgShowType() {
      const imgFit = this.myConfig.normal.imgFit;
      const tileType = this.myConfig.normal.tileType;
      let styleConfig = {};
      if (imgFit === "repeat") {
        switch (tileType) {
          case "zoom":
            styleConfig = {
              backgroundPosition: "top",
              backgroundRepeat: "repeat-y",
              backgroundSize: "100% auto",
            };
            break;
          case "top":
            styleConfig = {
              backgroundPosition: "top",
              backgroundRepeat: "repeat-x",
            };
            break;
          case "center-cross":
            styleConfig = {
              backgroundPosition: "center",
              backgroundRepeat: "repeat-x",
            };
            break;
          case "bottom":
            styleConfig = {
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            };
            break;
          case "left":
            styleConfig = {
              backgroundPosition: "left",
              backgroundRepeat: "repeat-y",
            };
            break;
          case "center-vertical":
            styleConfig = {
              backgroundPosition: "center",
              backgroundRepeat: "repeat-y",
            };
            break;
          case "right":
            styleConfig = {
              backgroundPosition: "right",
              backgroundRepeat: "repeat-y",
            };
            break;
          default:
            styleConfig = {
              backgroundPosition: "center",
              backgroundRepeat: "repeat",
            };
            break;
        }
      } else if (imgFit === "origin") {
        styleConfig = {
          backgroundPosition: this.myConfig.normal.imgPosition,
          backgroundRepeat: "no-repeat",
        };
      } else {
        styleConfig = {
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: imgFit === "stretch" ? "100% 100%" : imgFit,
        };
      }
      return styleConfig;
    },
    ...mapState("appDesign", [
      "clone",
      "library",
      "componentListConfig",
      "paneName",
    ]),
    pane_name: {
      get() {
        return this.paneName;
      },
      set(val) {
        this.$store.commit("appDesign/setPaneName", val);
      },
    },
  },
  created() {
    // console.log(
    //   "this.componentListConfig",
    //   this.componentListConfig,
    //   this.index
    // );
    setTimeout(() => {
      if (
        this.componentListConfig.list[this.index].component ===
        "st-free-container"
      ) {
        this.componentData = this.componentListConfig.list[this.index];
      } else {
        this.componentData = null;
      }
    }, 200);
  },
  methods: {
    /**
     * 简单处理元素上移、下移，不涉及容器元素相交判断
     * @param {object} normal
     * @param {number} type    1：上移，2：下移
     */
    handleChangeLayer(normal, type) {
      if (type === 1 && normal.index < 30) normal.index++
      if (type === 2 && normal.index > 0) normal.index--
    },
    change(item, newRect) {
      item.widthHeight.width = newRect.width;
      item.widthHeight.height = newRect.height;
      item.top = newRect.top;
      item.left = newRect.left;
    },
    handleActive(item, index) {
      // console.log(item, index, 'handleActive', item.isShowInput)
      if (item.isShowInput) return;
      item.isActive = true;
      // item.isPreventBehavior = true;
      this.componentData.props.components.forEach((el, i) => {
        if (i !== index) {
          el.isActive = false;
          // el.isPreventBehavior = false;
          console.log(el.isActive);
        }
      });
      this.componentListConfig.free_container_select = index;
      this.isHandleActive = true;
      setTimeout(() => {
        this.isHandleActive = false;
      }, 100);
    },
    cancelActive(item, index) {
      // console.log('cancel active', item, index, this.isHandleActive)
      if (this.isHandleActive) return;
      item.isActive = false;
      item.isShowInput = false;
      this.componentListConfig.free_container_select = -1;
    },
    // 激活输入框
    handleInput(item, index) {
      item.isShowInput = true;
      this.$nextTick(() => {
        console.log(this.$refs.inputarea, item.isShowInput, "this.$refs.input");
        this.$refs.inputarea[0].focus();
      });
    },
    // 确认图片
    handleMedia(mediaList) {
      const url = mediaList[0].url;
      if (this.stImageIndex != -1) {
        this.componentData.props.components[
          this.stImageIndex
        ].props.normal.image = url;
      }
    },
    // 删除组件
    handleDel(item, index) {
      item.isActive = false;
      item.isShowInput = false;
      this.componentListConfig.free_container_select = -1;
      this.componentData.props.components.splice(index, 1);
    },
    changeHeight(val) {
      console.log("changeHeight", val);
    },
    stopHeightChange(val) {
      console.log("stopHeightChange", val);
    },
    /**
     * 从左侧列表添加了一个组件到"页面"
     */
    handleAdd(ev) {
      // 使目标对象更准确，防止混乱的旧数据影响位置顺序
      let nowIndex = this.componentData.props.components.findIndex(
        (li) => li.extra.timestamp === this.clone.data.extra.timestamp
      );
      let target = JSON.parse(
        JSON.stringify(
          this.componentData.props.components.splice(nowIndex, 1)[0]
        )
      );

      const { component, extra, props, version, title, isActive } = target;

      if (
        ["st-text", "st-image", "st-button", "st-link"].includes(this.clone.data.component)
      ) {
        this.componentData.props.components.push({
          component,
          extra,
          props,
          version,
          isActive,
          isShowInput: false,
          title,
        });
        if (this.clone.data.component === "st-image") {
          this.showImageUpload = true;
          this.stImageIndex = this.componentData.props.components.length - 1;
        }
      } else {
        this.$message.error("自由容器仅支持图片添加文本、图片、按钮、链接组件");
      }
    },
    // 点击添加组件
    handleAddComp() {
      this.isShowNormalComponents = !this.isShowNormalComponents;
      bus.$emit("hideComponents", this.isShowNormalComponents);
      // if ((this.pane_name = "component-library")) {
      //   bus.$emit("activeComponentLib");
      // } else {
      //   this.pane_name = "component-library";
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.free-container {
  width: 100%;
  // overflow: hidden;

  .comp-box {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .input-text {
      width: 100%;
      height: 100%;

      ::v-deeptextarea {
        height: 100%;
        font-size: inherit;
      }
    }

    .placeholder {
      color: #dcdfe6;
    }

    &.imgbox {
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        width: 100%;
        height: 100%;
      }
      .none-img {
        color: #dadada;
        line-height: 1;
      }
    }

    &.btnbox {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.comp {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  // ::v-deep.no-placement,
  // ::v-deep.placement {
  //   display: none !important;
  // }

  .comp-handle {
    position: absolute;
    display: none;
    top: 0;
    /*right: 0;*/
    right: -20px;
    padding-left: 6px;

    .handle-btn + .handle-btn {
    /*  margin-left: 1px;*/
      margin-top: 4px;
    }

    .handle-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      background: #000000;
      opacity: 0.39;
      cursor: pointer;

      &:hover {
        opacity: 0.29;
      }

      .btn-icon {
        width: 8px;
        height: 8px;

        &.el-icon {
          width: unset;
          height: unset;
          color: white;
          font-size: 8px;
        }
      }
    }
  }

  .none-tip-box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .none-tip {
      display: flex;
      flex-direction: column;
      align-items: center;

      .none-text {
        font-size: 14px;
        color: #7f7f7f;
        line-height: 20px;
      }

      .none-btn {
        margin-top: 17px;
      }
    }
  }
}
.handle-icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -16px;
  width: 32px;
  height: 13px;
  cursor: n-resize;
}

.comp-item {
  background-position: right;
  background-repeat: no-repeat;
  background-size: 7px 3px;

  &.active {
    .comp-handle {
      display: flex;
    }
  }
}

.comp-item:hover {
  .comp-handle {
    display: flex;
  }
}
</style>
