<!--
 * @Author: dongjia
 * @Date: 2021-09-27 18:24:30
 * @LastEditTime: 2021-10-28 18:30:54
 * @LastEditors: aleaner
 * @Description: 毁会员主页个人信息组件
 * @FilePath: \app-design\components\Preview\StMemberMessage.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div
    class="member-msg"
    :style="{
      marginTop: myConfig.normal.marginTop + 'px',
      position: 'relative',
    }"
  >
    <StMemberContainer
      :contentConfig="myConfig.contentConfig"
      :borderBottomRadius="borderRadius"
    >
      <div class="msg-content">
        <div
          class="default-content"
          v-if="list.length === 0 && myConfig.normal.isShowDefaultPage"
        >
          <img
            class="default-img"
            src="../../assets/images/st-member-index/default-content-icon.png"
          />
          <div class="default-text">亲～该页面暂时没有内容哦</div>
        </div>
        <div class="content-list" v-if="list.length">
          <template v-for="(item, index) in list">
            <div class="list-item" :key="index">
              <el-image
                class="item-icon"
                :src="item.icon"
                fit="cover"
              ></el-image>
              <p>{{ item.content }}</p>
            </div>
          </template>
        </div>
      </div>
    </StMemberContainer>
  </div>
</template>

<script>
import StMemberContainer from './StMemberContainer'
import { infoComponentSelect } from '../../api/components'

export default {
  name: 'StMemberMessage',
  components: { StMemberContainer },
  props: {
    config: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      model: {
        normal: {
          marginTop: 0,
          isShowDefaultPage: 1,
        },
        contentConfig: {
          titleConfig: {
            name: '页面标题',
            isShow: 1,
          },
          fontWeight: 'normal',
          fontSize: 14,
          fontColor: '#000000',
          msgList: [''],
          msgListUnit: [''],
          msgListDrag: [''],
          msgListUnitDrag: [''],
        },
      },
      list: [
        // {
        //   icon: require("../../assets/images/st-electron-card/address.png"),
        //   content: "广东省，广州市，天河区",
        // },
        // {
        //   icon: require("../../assets/images/st-electron-card/political_status.png"),
        //   content: "共青团员",
        // },
        // {
        //   icon: require("../../assets/images/st-electron-card/position.png"),
        //   content: "企业单位",
        // },
        // {
        //   icon: require("../../assets/images/st-electron-card/position.png"),
        //   content: "广州猛牛网络科技有限公司 董事长",
        // },
        // {
        //   icon: require("../../assets/images/st-electron-card/now_address.png"),
        //   content: "广州市天河区体育中心广场",
        // },
      ],
      borderRadius: 0,
      rawListRes: [],
    }
  },
  methods: {
    contentFilter(field) {
      switch (field) {
        case 'home_address': {
          return [
            '广东省，广州市，天河区',
            require('../../assets/images/st-electron-card/address.png'),
          ]
        }
        case 'gender': {
          return [
            '女',
            require('../../assets/images/st-electron-card/gender.png'),
          ]
        }
        case 'phone': {
          return [
            '132 0000 0000',
            require('../../assets/images/st-electron-card/phone.png'),
          ]
        }
        case 'base-input': {
          return [
            '填空',
            require('../../assets/images/st-electron-card/base-input.png'),
          ]
        }
        case 'base-select': {
          return [
            '选择',
            require('../../assets/images/st-electron-card/base-select.png'),
          ]
        }
        case 'base-time-picker': {
          return [
            '时间',
            require('../../assets/images/st-electron-card/weight.png'),
          ]
        }
        case 'base-area-picker': {
          return [
            '地区',
            require('../../assets/images/st-electron-card/people_type.png'),
          ]
        }
        case 'tall': {
          return [
            '190 CM',
            require('../../assets/images/st-electron-card/tall-2.png'),
          ]
        }
        case 'weight': {
          return [
            '70 KG',
            require('../../assets/images/st-electron-card/weight.png'),
          ]
        }
        case 'birthday': {
          return [
            '1993-08-01',
            require('../../assets/images/st-electron-card/birthday.png'),
          ]
        }
        case 'national': {
          return [
            '汉族',
            require('../../assets/images/st-electron-card/national.png'),
          ]
        }
        case 'hometown_address': {
          return [
            '家乡地址',
            require('../../assets/images/st-electron-card/home.png'),
          ]
        }
        case 'native_place': {
          return [
            '出生地',
            require('../../assets/images/st-electron-card/home.png'),
          ]
        }
        case 'political_status': {
          return [
            '政治身份',
            require('../../assets/images/st-electron-card/political_status.png'),
          ]
        }
        case 'email': {
          return [
            '电子邮箱',
            require('../../assets/images/st-electron-card/email.png'),
          ]
        }
        case 'school_degree': {
          return [
            '学校',
            require('../../assets/images/st-electron-card/school_degree.png'),
          ]
        }
        case 'school_history': {
          return [
            '就读过',
            require('../../assets/images/st-electron-card/school_history.png'),
          ]
        }
        case 'school_graduate': {
          return [
            '毕业于',
            require('../../assets/images/st-electron-card/school.png'),
          ]
        }
        case 'social_role': {
          return [
            '社会身份',
            require('../../assets/images/st-electron-card/social_role.png'),
          ]
        }
        case 'HongKong_and_Macao_identity': {
          return [
            '港澳身份',
            require('../../assets/images/st-electron-card/position.png'),
          ]
        }
        case 'people_type': {
          return [
            '人员分类',
            require('../../assets/images/st-electron-card/position.png'),
          ]
        }
        case 'referee': {
          return [
            '推荐人',
            require('../../assets/images/st-electron-card/national.png'),
          ]
        }
        case 'member_type': {
          return [
            '会员类型',
            require('../../assets/images/st-electron-card/position.png'),
          ]
        }
      }

      return ['-', require('../../assets/images/st-electron-card/paper.png')] // TODO: default icon
    },
  },
  watch: {
    'myConfig.normal.marginTop'(val) {
      this.$bus.emit('marginTopChange', { marginTop: val, index: this.index })
    },
    'myConfig.contentConfig.msgListDrag'(val) {
      const fresh = () => {
        this.list = []

        for (let index in val) {
          const info = val[index]
          for (let i in this.rawListRes) {
            const field = this.rawListRes[i].value
            if (field === info) {
              this.list.push({
                icon: this.contentFilter(info.split('.')[0])[1],
                content: `${
                  info.split('.')[1] ||
                  this.contentFilter(info.split('.')[0])[0]
                }`,
              })

              if (info.split('.')[0] === 'social_role') {
                this.list.push({
                  icon: require('../../assets/images/st-electron-card/position.png'),
                  content: `单位职务·单位名称`,
                })
              }
            }
          }
        }
      }
      if (this.rawListRes.length === 0) {
        infoComponentSelect().then((res) => {
          this.rawListRes = res.data
          fresh()
        })
      }
      console.log(this.list, val)
      fresh()
    },
  },
  created() {
    if (this.rawListRes.length === 0) {
      infoComponentSelect().then((res) => {
        this.rawListRes = res.data
      })
    }

    this.$bus.on('marginTopChange', (res) => {
      console.log(res, this.index, 'res')
      if (res.index - 1 === this.index) {
        this.borderRadius = res.marginTop > 0 ? 4 : 0
      }
    })

    this.list = []
    for (let index in this.myConfig.contentConfig.msgListDrag) {
      const info = this.myConfig.contentConfig.msgListDrag[index]
      const field = info.split('.')[0]
      this.list.push({
        icon: this.contentFilter(field)[1],
        content: info.split('.')[1]
          ? `${info.split('.')[1] || ''}（${this.contentFilter(field)[0]}）`
          : this.contentFilter(field)[0],
      })

      if (field === 'social_role') {
        this.list.push({
          icon: require('../../assets/images/st-electron-card/position.png'),
          content: `单位职务·单位名称`,
        })
      }
    }
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config)
    },
  },
}
</script>

<style lang="scss" scoped>
.msg-content {
  .default-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
    .default-img {
      width: 210px;
      height: 100px;
    }
    .default-text {
      margin-top: 24px;
      font-size: 14px;
      color: #979da6;
      line-height: 14px;
    }
  }
  .content-list {
    padding: 17px 19px;
    .list-item + .list-item {
      margin-top: 12px;
    }
    .list-item {
      display: flex;
      font-size: 12px;
      color: #191e29;
      line-height: 1.5;

      .item-icon {
        width: 19px;
        height: 19px;
        margin-right: 9px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
