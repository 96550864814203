var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mail-content",style:({
  backgroundColor: _vm.myConfig.normal.bgColor
})},[(_vm.realTabArr.length)?_c('div',{staticClass:"mail-tabs",style:({
    backgroundColor: _vm.myConfig.tabs.bgColor
  })},[_vm._l((_vm.realTabArr),function(item,index){return _c('div',{key:index,class:['tab-item', _vm.active === item.value ? 'active' : ''],style:({
        '--active-color': _vm.myConfig.tabs.fontActiveColor,
        '--inactive-color': _vm.myConfig.tabs.fontInactiveColor
      }),on:{"click":function($event){_vm.active = item.value}}},[_c('p',{staticClass:"item-name"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"item-active"})])}),_c('div',{class:['tab-item', 'filter-box']},[_vm._m(0),_c('div',{staticClass:"item-active"})])],2):_c('div',{staticClass:"filter-bar"},[_vm._m(1)]),(
      (_vm.active === 'allConfig' && _vm.myConfig[_vm.active].showFans) ||
      (_vm.active !== 'allConfig' && _vm.myConfig[_vm.active].showFilter)
    )?_c('div',{staticClass:"mail-filter",style:({
      marginTop: _vm.realTabArr.length ? '18px' : '0px',
    })},[(_vm.active !== 'allConfig')?_c('div',{staticClass:"filter-item",class:_vm.childrenTabActive === 0 ? 'active' : ''},[_vm._v(" 全部 ")]):_vm._e(),_vm._l((_vm.myConfig[_vm.active].filterData),function(item,index){return (_vm.active !== 'allConfig')?_c('div',{key:index,staticClass:"filter-item",class:_vm.childrenTabActive === item ? 'active' : ''},[_vm._v(" "+_vm._s(_vm.filterArr[item - 1])+" ")]):_vm._e()})],2):_vm._e(),(
      _vm.memberNumTop &&
      _vm.myConfig.allConfig.showMemberNum &&
      _vm.active === 'allConfig'
    )?_c('div',{staticClass:"member-num-top",style:({
      marginTop:
        _vm.myConfig[_vm.active].showExpand ||
        !(
          (_vm.active === 'allConfig' && _vm.myConfig[_vm.active].showFans) ||
          (_vm.active !== 'allConfig' && _vm.myConfig[_vm.active].showFilter)
        )
          ? '12px'
          : '',
    })},[_vm._v(" 共 "),_c('span',{staticStyle:{"color":"#3a3a3a","font-size":"14px"}},[_vm._v(_vm._s(_vm.memberNum))]),_vm._v(" 名会员 ")]):_vm._e(),(_vm.memberList.length)?_c('div',{staticClass:"member-content"},[(_vm.active === 'allConfig' && _vm.page.sortConfig.orderBy === 'char_sort')?_c('div',{staticClass:"member-list",style:({
        width: _vm.active === 'allConfig' ? '320px' : '341px',
      })},_vm._l((_vm.memberList),function(item,index){return _c('div',{key:index,staticClass:"list-item char_sort"},[_c('div',{staticClass:"item-initial"},[_vm._v(_vm._s(item.Initials))]),_c('div',{staticClass:"item-list"},_vm._l((item.list),function(member,i){return _c('div',{key:i,staticClass:"member-item"},[_c('div',{staticClass:"member-left"},[_c('el-image',{staticClass:"member-avatar",attrs:{"src":member.avatar || _vm.defaultAvatar,"fit":"cover"}}),_c('div',{staticClass:"member-msg"},[_c('div',{staticClass:"msg-top"},[_vm._v(" "+_vm._s(member.nickname)+" "),(
                      _vm.myConfig.allConfig.showMemberSign &&
                      _vm.memberForm.unit_user_enable &&
                      _vm.memberForm.personal_user_enable
                    )?_c('img',{staticClass:"member-sign",attrs:{"src":member.sign === 1 ? _vm.yellowIcon : _vm.blueIcon}}):_vm._e()]),_c('div',{staticClass:"msg-bottom"},[_vm._v(_vm._s(member.title_name))])])],1),(_vm.active !== 'allConfig')?_c('div',{staticClass:"member-right"},[(
                  _vm.active === 'nearbyConfig' &&
                  _vm.myConfig.nearbyConfig.showDistance
                )?_c('div',{staticClass:"member-info"},[_vm._v(" "+_vm._s(member.distance)+" ")]):_vm._e(),(
                  _vm.active === 'nearbyConfig' && _vm.myConfig.nearbyConfig.showArea
                )?_c('div',{staticClass:"member-info"},[_vm._v(" "+_vm._s(member.area)+" ")]):_vm._e(),(
                  _vm.active === 'sameCityConfig' &&
                  _vm.myConfig.sameCityConfig.showCity
                )?_c('div',{staticClass:"member-info"},[_vm._v(" "+_vm._s(member.city)+" ")]):_vm._e()]):_vm._e()])}),0)])}),0):_c('div',{staticClass:"item-list",style:({
        width: '341px',
      })},_vm._l((_vm.memberListNormal),function(member,i){return _c('div',{key:i,staticClass:"member-item"},[_c('div',{staticClass:"member-left"},[_c('el-image',{staticClass:"member-avatar",attrs:{"src":member.avatar || _vm.defaultAvatar,"fit":"cover"}}),_c('div',{staticClass:"member-msg"},[_c('div',{staticClass:"msg-top"},[_vm._v(" "+_vm._s(member.nickname)+" "),(
                  _vm.myConfig[_vm.active].showMemberSign &&
                  _vm.memberForm.unit_user_enable &&
                  _vm.memberForm.personal_user_enable
                )?_c('img',{staticClass:"member-sign",attrs:{"src":member.sign === 1 ? _vm.yellowIcon : _vm.blueIcon}}):_vm._e()]),_c('div',{staticClass:"msg-bottom"},[_vm._v(_vm._s(member.title_name))])])],1),(_vm.active !== 'allConfig')?_c('div',{staticClass:"member-right"},[(
              _vm.active === 'nearbyConfig' && _vm.myConfig.nearbyConfig.showDistance
            )?_c('div',{staticClass:"member-info"},[_vm._v(" "+_vm._s(member.distance)+" ")]):_vm._e(),(_vm.active === 'nearbyConfig' && _vm.myConfig.nearbyConfig.showArea)?_c('div',{staticClass:"member-info"},[_vm._v(" "+_vm._s(member.area)+" ")]):_vm._e(),(
              _vm.active === 'sameCityConfig' && _vm.myConfig.sameCityConfig.showCity
            )?_c('div',{staticClass:"member-info"},[_vm._v(" "+_vm._s(member.city)+" ")]):_vm._e()]):_vm._e()])}),0),(_vm.active === 'allConfig' && _vm.page.sortConfig.orderBy === 'char_sort')?_c('div',{staticClass:"member-Initials"},[_vm._l((26),function(i){return _c('div',{staticClass:"Initial-item"},[_vm._v(" "+_vm._s(String.fromCharCode(64 + i))+" ")])}),_c('div',{staticClass:"Initial-item"},[_vm._v("#")])],2):_vm._e()]):_vm._e(),(
      !_vm.memberNumTop &&
      _vm.myConfig.allConfig.showMemberNum &&
      _vm.active === 'allConfig'
    )?_c('div',{staticClass:"member-num-bottom"},[_vm._v(" 共 "),_c('span',{staticStyle:{"color":"#3a3a3a","font-size":"14px"}},[_vm._v(_vm._s(_vm.memberNum))]),_vm._v(" 名会员 ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-name"},[_c('img',{attrs:{"src":require("../../assets/images/st-mail-list/filter.png"),"alt":""}}),_vm._v(" 筛选 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box"},[_c('img',{staticClass:"image filter-icon",attrs:{"src":require("../../assets/images/st-mail-list/filter.png")}}),_c('p',[_vm._v(" 筛选")])])}]

export { render, staticRenderFns }